import React, { useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle } from "react";
import "./searchFilter.scss";
import {
  SearchFilterComp,
  SearchFilterContainer,
  SearchFilterFooter,
  SearchForm,
} from "../../ui/SearchFilter";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { useSelector } from "react-redux";
import LoadMask from "../../../utils/LoadMask";
import {
  getCoverageScreen,
  postCoverageScreen,
} from "../../../store/actions/workOrder/coverageScreen";
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
  FormMultiSelect,
  FormTimePicker,
  FormCheckbox,
} from "../../ui/form/form-component";
import { useTranslation } from "react-i18next";
import { MessageBox, showToast } from "../../../utils/Notification";
import { inputValidator } from "../../ui/form/validator";
import { t } from "i18next";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";
import { selectAuditorList } from "../../../store/reducer/commonReducer";
import { capsuleToParams } from "../../../utils/Utility";

const SearchFilterWindow = forwardRef(({
  setSearchTerm,
  setResult,
  pagerSkip,
  setPagerSkip,
  dataState,
  shouldRefresh,
  setShouldRefresh,
  searchTerm,
  sort,
  screen,
  roleWorkOrderPage

}, ref) => {
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const dateStamp = useSelector((store) => store?.reports?.dateStamp)
  const auditorDetails = useSelector(selectAuditorList);
  const { t } = useTranslation();
  const { handleAuthContext } = useLogout();
  const [capsulesFilter, setCapsulesFilter] = useState([]);
  const formRef = React.useRef();

  const initialFormValue = useMemo(() => {
    return {
      Region: locationData?.Region?.RegionID,
      Country: locationData?.Country || [],
    };
  }, [locationData]);
  const [selectedData, setSelectedData] = useState(initialFormValue);
  const [showLoader, setShowLoader] = useState(null);
  const [autoPlanGenList, setAutoPlanGenList] = useState([]);
  const [weekNumber, setWeekNumber] = useState([]);

  useEffect(() => {
    let weekNumberData = [];
    dateStamp?.weekData?.map(elm => (
      weekNumberData.push(elm.DisplayValue)
    ))
    setWeekNumber([...new Set(weekNumberData)])
  }, [dateStamp?.weekData])

  const fetchCoverageScreen = async (params) => {
    setShowLoader({
      className: ".workOrders_grid_cs",
      msg: "loading",
    });

    const res = await getCoverageScreen(params, authContext);

    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.root) {
      let id = 0;
      let data =
        res?.root?.StoreData?.map((item) => {
          const curPrimaryAud = auditorDetails?.find(
            (aud) => aud?.AuditorId === item?.PRIMARY_RESOURCE_ID
          );
          const curReplacementAud = auditorDetails?.find(
            (aud) => aud?.AuditorId === item?.REPLACEMENT_RESOURCE_ID
          );
          let saList = [];
          const isSecondaryExits =
            item["SecondaryAuditors"] !== null &&
            item["SecondaryAuditors"] !== undefined;
          if (isSecondaryExits) {
            saList = item.SecondaryAuditors.split("|");
          }
          return {
            ...item,
            SECONDARY_RESOURCE_IDS: saList,
            OriginalSecondaryAuditorList: saList,
            PRIMARY_RESOURCE_NAME: curPrimaryAud?.AuditorName,
            REPLACEMENT_RESOURCE_NAME: curReplacementAud?.AuditorName,
          };
        }).sort(function (a, b) {
          return a.StoreId - b.StoreId;
        }) || [];
      data = data?.map((item) => {
        return {
          ...item,
          ID: ++id,
        };
      });

      const newResult = {
        data: data,
        total: res.root?.StoreData?.length || 0,
        totalDataCount: res?.root?.TotalCount
      };
      setAutoPlanGenList(res.root?.AutomaticPlanGen || []);
      setResult(newResult);
    } else {
      console.log("search plan api error", res);
    }
    setShowLoader(null);
  }

  useImperativeHandle(ref, () => ({
    fetchCoverageScreen: (params) => {
      const newSearchParams = getUpdatedSearchParams(capsulesFilter);
      const newParams = { ...newSearchParams, ...params };
      fetchCoverageScreen(newParams);
    },
    newSearchParams: getUpdatedSearchParams(capsulesFilter)
  }));

  ///REMOVE SEARCH FILTER BY CAPSULE
  const removeCapsuleFilter = (index) => {
    const { name: removeFieldName, value: removeFieldValue } = capsulesFilter[index];
    const newSelectedData = { ...selectedData };

    if (removeFieldName === "Country") {
      newSelectedData.Country = newSelectedData.Country.filter(
        (c) => c.CountryID !== removeFieldValue
      );
      if (newSelectedData.Country.length === 0) {
        showToast("error", `${t("countrymandatory_msglbl")}`);
        return false
      }
    } else if (removeFieldName === 'CollectionType') {
      showToast("error", `${t("collectiontyperequired")}`);
      return false
    } else {
      delete newSelectedData[removeFieldName];
    }

    setSelectedData(newSelectedData);
    const newCapsuleFilter = [...capsulesFilter];
    newCapsuleFilter.splice(index, 1);
    setCapsulesFilter(newCapsuleFilter);
    const newSearchParams = getUpdatedSearchParams(newCapsuleFilter);
    const params = {
      ...newSearchParams,
      Companyid: authContext?.CompanyID,
      page: 1,
      StartCount: 0,
      MaxCount: dataState.take,
      sortProperty: sort[0].field,
      sortDirection: sort[0].dir,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    fetchCoverageScreen(params);
  };

  const onSearchClick = async (e) => {
        const newSearchParams = getUpdatedSearchParams(capsulesFilter);
        const params = {
      _dc: Date.now(),
      ...newSearchParams,
      Companyid: authContext?.CompanyID,
      page: "1",
      StartCount: "0",
      MaxCount: dataState.take,
      sortProperty: sort[0].field,
      sortDirection: sort[0].dir,
    };
    const searchTerm = e.target?.searchWorkOrder?.value;
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setSearchTerm(searchTerm);
    setPagerSkip(0);
    fetchCoverageScreen(params);
  };

  const getUpdatedSearchParams = (capsulesFilter) => {
    const newSearchParams = capsuleToParams(capsulesFilter);
    newSearchParams.Countryid = newSearchParams.Country;
    newSearchParams.NavigatorAuditorID = newSearchParams.primaryResourceId;
    newSearchParams.NavigatorReplacementAuditorID = newSearchParams.replacementResourceId;
    newSearchParams.StartDate = newSearchParams.startdate;
    newSearchParams.EndDate = newSearchParams.enddate;
    newSearchParams.CoverageStoretype = newSearchParams.storeType;
    newSearchParams.WeekNo = newSearchParams.WeekNumber;
    delete newSearchParams.Country;
    delete newSearchParams.primaryResourceId;
    delete newSearchParams.replacementResourceId;
    delete newSearchParams.startdate;
    delete newSearchParams.enddate;
    delete newSearchParams.storeType;
    delete newSearchParams.WeekNumber;

    if (!newSearchParams.Countryid) {
      delete newSearchParams.Countryid;
    }
    if (!newSearchParams.NavigatorAuditorID) {
      delete newSearchParams.NavigatorAuditorID;
    }
    if (!newSearchParams.NavigatorReplacementAuditorID) {
      delete newSearchParams.NavigatorReplacementAuditorID;
    }
    if (!newSearchParams.StartDate) {
      delete newSearchParams.StartDate;
    }
    if (!newSearchParams.EndDate) {
      delete newSearchParams.EndDate;
    }
    if (!newSearchParams.CoverageStoretype) {
      delete newSearchParams.CoverageStoretype;
    }
    if (!newSearchParams.WeekNo) {
      delete newSearchParams.WeekNo;
    }
    return newSearchParams;
  }

  const onFilterSubmitClick = async ({ values }) => {
    if (values?.startdate && !values?.enddate) {
      showToast("error", `${t('emptyenddate_msglbl')}`);
      return;
    } else if (!values?.startdate && values?.enddate) {
      showToast("error", `${t('emptystartdate_msglbl')}`);
      return;
    }
    formRef.current.setFilterWindow(false);
    const newCapsuleFilter = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (key === "Region") {
          return acc;
        }
        if (key === "Country") {
          const countryData = value?.map((c) => ({
            name: key,
            text: c.CountryName,
            value: c.CountryID,
          }));
          return [...acc, ...countryData];
        }

        if (key === "startdate" || key === "enddate") {
          value = moment(value).format("YYYY-MM-DD");
        }
        return [
          ...acc,
          {
            name: key,
            text: value,
            value,
          },
        ];
      },
      []
    );
    setCapsulesFilter(newCapsuleFilter);
    setSelectedData(values);
    const newSearchParams = getUpdatedSearchParams(newCapsuleFilter);

    const params = {
      ...newSearchParams,
      Companyid: authContext?.CompanyID,
      _dc: Date.now(),
      page: "1",
      StartCount: "0",
      MaxCount: dataState.take,
      sortProperty: sort[0].field,
      sortDirection: sort[0].dir,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    fetchCoverageScreen(params)
  };

  useEffect(() => {
    if (shouldRefresh) {
      const newSearchParams = getUpdatedSearchParams(capsulesFilter);
      const params = {
        ...newSearchParams,
        _dc: Date.now(),
        Companyid: authContext?.CompanyID,
        page: Math.floor(pagerSkip / dataState.take) + 1,
        StartCount: pagerSkip,
        MaxCount: dataState.take,
        sortProperty: sort[0].field,
        sortDirection: sort[0].dir,
      };
      if (searchTerm) {
        params.keyword = searchTerm;
      }
      setPagerSkip(0);
      fetchCoverageScreen(params);
      setShouldRefresh(false);
    }
  }, [shouldRefresh, capsulesFilter]);

  useEffect(() => {
    const params = {
      _dc: Date.now(),
      Companyid: authContext?.CompanyID,
      page: Math.floor(dataState.skip / dataState.take) + 1,
      StartCount: dataState.skip,
      MaxCount: dataState.take,
      sortProperty: sort[0].field,
      sortDirection: sort[0].dir,
    };
    fetchCoverageScreen(params);
  }, [authContext]);

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <SearchFilterContainer>
        <SearchForm
          placeholder={
            `${t("searchby")} ${t("SMS ID")}, ${t("StoreName")}, ${t("Address")}, ${t("city_lbl")}, ${t("State")}, ${t("ZIPCODE")}, ${t("updatedon")}, ${t("ResourceId")}, ${t("REPLACEMENT_AUDITORID_LBL")}, ${t("updatedby")}`
          }
          onSubmitClick={onSearchClick}
        />
        <AutomaticPlanGeneration
          autoPlanGenList={autoPlanGenList}
          setShouldRefresh={setShouldRefresh}
          screen={screen}
          roleWorkOrderPage={roleWorkOrderPage}
        />
        <SearchFilterComp
          ref={formRef}
          onSubmitClick={onFilterSubmitClick}
          selectedData={selectedData}
          capsulesFilter={capsulesFilter}
          removeCapsuleFilter={removeCapsuleFilter}
          windowProps={{
            initialTop: 70,
            initialWidth: 1165,
            initialHeight: 366,
          }}
        >
          {(formRenderProps) => (
            <FormElement>
              <div style={{ display: "flex", gap: 15, flexWrap: "wrap" }}>
                <Field
                  name="Region"
                  label={t("Region")}
                  component={FormComboBox}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("Region")}
                  data={[locationData?.Region]}
                  textField="RegionName"
                  valueField="RegionID"
                  disabled
                />
                <Field
                  name="Country"
                  label={t("COUNTRY")}
                  component={FormMultiSelect}
                  placeholder={t("COUNTRY")}
                  required
                  validator={inputValidator}
                  wrapperStyle={{
                    width: "calc(25% - 15px)",
                    position: "relative",
                  }}
                  data={locationData?.Country}
                  textField="CountryName"
                  valueField="CountryID"
                  onChange={(e) => {
                    setSelectedData({
                      ...selectedData,
                      Country: e?.value || [],
                    });
                  }}
                />
                <Field
                  name="CollectionType"
                  label={t("COLLECTION_LBL")}
                  component={FormComboBox}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  validator={inputValidator}
                  data={["DCT", "ECOLLECTION", "GNIC", "NSO"]}
                  placeholder={t("COLLECTION_LBL")}
                  onChange={(e) => {
                    setSelectedData({
                      ...selectedData,
                      CollectionType: e.value,
                    });
                  }}
                  required
                />
                <Field
                  name="WeekNumber"
                  label={t("WeekNumber")}
                  component={FormComboBox}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("WEEK")}
                  data={weekNumber}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, week: e.value });
                  }}
                />
                <Field
                  name="startdate"
                  label={t("START_DATE_LBL")}
                  component={FormDatePicker}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("START_DATE_LBL")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, startdate: e.value });
                  }}
                />
                <Field
                  name="enddate"
                  label={t("END_DATE_LBL")}
                  component={FormDatePicker}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("END_DATE_LBL")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, enddate: e.value });
                  }}
                />
                <Field
                  name="storeType"
                  label={t("storetype")}
                  component={FormComboBox}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("Status")}
                  textField="DisplayValue"
                  valueField="ActualValue"
                  data={[
                    {
                      DisplayValue: "Assigned",
                      ActualValue: "Assigned",
                    },
                    {
                      DisplayValue: "Unassigned",
                      ActualValue: "Unassigned",
                    },
                  ]}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, storeType: e.value });
                  }}
                />
                <Field
                  name="primaryResourceId"
                  label={t("ResourceId")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("ResourceId")}
                  onChange={(e) => {
                    setSelectedData({
                      ...selectedData,
                      primaryResourceId: e.value,
                    });
                  }}
                />
                <Field
                  name="replacementResourceId"
                  label={t("REPLACEMENT_AUDITORID_LBL")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("REPLACEMENT_AUDITORID_LBL")}
                  onChange={(e) => {
                    setSelectedData({
                      ...selectedData,
                      replacementResourceId: e.value,
                    });
                  }}
                />
              </div>
              <SearchFilterFooter handleReset={() => {
                setSelectedData({ ...initialFormValue, Country: [] });
                setTimeout(() => {
                  formRenderProps.onFormReset();
                }, 0);
              }} />
            </FormElement>
          )}
        </SearchFilterComp>
      </SearchFilterContainer>
    </>
  );
});

const AutomaticPlanGeneration = ({ autoPlanGenList, setShouldRefresh,  screen,
  roleWorkOrderPage }) => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [showLoader, setShowLoader] = useState(null);
  const [messageProps, setMessageProps] = useState(null);
  const formRef = useRef(null);

  const [isShow, setShow] = useState(false);

  const onSaveClick = async (e) => {
    const { Collectiontype, Time, Date } = e.values;
    const params = JSON.stringify({
      Collectiontype,
      Countryid: authContext?.CountryCode,
      Companyid: authContext?.CompanyID,
      AutomaticPlanGen: "Y",
      Time: moment(Time).format("HH:mm:ss"),
      Date: moment(Date).format("MM/DD/YYYY"),
    });

    setShowLoader({
      className: ".coverageScreenForm",
      msg: "loading",
    });

    const res = await postCoverageScreen(params, authContext);
    if (res?.root?.MsgCode === "UPDATE_SUCCESS_LBL") {
      showToast("success", res?.root?.Msg);
      formRef.current.onReset();
    } else {
      showToast("error", res?.root?.Msg);
    }
    setShouldRefresh(true);
    setShowLoader(null);
  };
  const handleShow = () => {
    setShow(!isShow);
  };

  const handleDeleteAutoPlan = (plan) => {
    const callBackFn = async (msg) => {
      setMessageProps(null);
      if (msg === "YES") {
        const { COLLECTIONTYPE, COMPANY_ID, COUNTRY_ID } = plan;
        const params = JSON.stringify({
          Collectiontype: COLLECTIONTYPE,
          Countryid: COUNTRY_ID,
          Companyid: COMPANY_ID,
          AutomaticPlanGen: "N",
        });
        setShowLoader({
          className: ".coverageScreenForm",
          msg: "loading",
        });
        const res = await postCoverageScreen(params, authContext);
        if (res?.root?.MsgCode === "UPDATE_SUCCESS_LBL") {
          showToast("success", res?.root?.Msg);
          setShouldRefresh(true);
        } else {
          showToast("error", res?.root?.Msg || "error");
        }
        setShowLoader(null);
      }
    };
    messagePopup(
      "Saved date and time will be deleted permanently. Do you want to continue?",
      "YESNO",
      "WARNING",
      callBackFn
    );
  };

  const messagePopup = (msg, button, icon, callBackFn) => {
    setMessageProps({
      title: "Warning",
      buttons: button,
      icon: icon,
      message: msg,
      fn: callBackFn,
    });
  };
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}
      <div style={{ position: "relative" }}>
        <div style={{ whiteSpace: "nowrap", display: "flex" }}>
          <FormCheckbox
            id="automatic"
            type="checkbox"
            value={isShow}
            onChange={handleShow}
            style={{ marginInline: ".5em" }}
            disabled={roleWorkOrderPage?.screensInfo?.screenId == screen ? ( roleWorkOrderPage?.createFlag == 'N' ? true : false ) : true }
          />
          <label style={{ cursor: "pointer" }} htmlFor="automatic">
            {t("automaticplangeneration")}
          </label>
        </div>
        <div
          className="shadow coverageScreenForm"
          style={{
            display: isShow ? "flex" : "none",
            marginTop: ".8em",
            background: "#fff",
            position: "absolute",
            width: 600,
            padding: "1em",
            zIndex: 99,
            overflow: "hidden",
            height: 150,
          }}
        >
          <Form
            ref={formRef}
            onSubmitClick={onSaveClick}
            render={(formRenderProps) => (
              <FormElement
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1em",
                  alignItems: "end",
                  height: 0,
                }}
              >
                <Field
                  name="Collectiontype"
                  label="Collection Type"
                  placeholder="Collection Type"
                  component={FormComboBox}
                  data={["DCT", "ECOLLECTION", "GNIC", "NSO"]}
                  required={true}
                  wrapperStyle={{ width: "45%" }}
                />
                <Field
                  name="Time"
                  label="Collection Start Time"
                  component={FormTimePicker}
                  required={true}
                  wrapperStyle={{ width: "45%", marginTop: 0 }}
                />
                <Field
                  name="Date"
                  label="Collection Start Date"
                  component={FormDatePicker}
                  required={true}
                  wrapperStyle={{ width: "45%", marginTop: 0 }}
                />
                <Button
                  primary
                  style={{
                    marginTop: "1.4em",
                    paddingInline: "2em",
                    width: "45%",
                  }}
                  disabled={roleWorkOrderPage?.screensInfo?.screenId == screen ? ( roleWorkOrderPage?.createFlag == 'N' ? true : false ) : true }
                >
                  Save
                </Button>
              </FormElement>
            )}
          />
          <div style={{ padding: ".4em", width: "70%", overflowY: "auto" }}>
            {autoPlanGenList.length
              ? autoPlanGenList?.map((plan, i) => {
                return (
                  plan.AUTOMATIC_PLANGEN === "Y" && (
                    <div
                      key={i}
                      className="AutoPlanGeneration"
                      onClick={() => handleDeleteAutoPlan(plan)}
                    >
                      <div>
                        <span
                          className="k-icon k-i-delete k-icon-64"
                          style={{ fontSize: 24 }}
                        ></span>
                      </div>
                      <div>
                        <b>{plan?.COLLECTIONTYPE}</b>
                        <div>{plan?.COUNTRYNAME}</div>
                        <div>Start Date: {plan?.STARTCOLLECTION_DAY}</div>
                        <div>RecurringDay: {plan?.DAYOFWEEK}</div>
                        <div>Start Time: {plan?.STARTCOLLECTION_TIME}</div>
                      </div>
                    </div>
                  )
                );
              })
              : "No Summary Available"}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchFilterWindow;
