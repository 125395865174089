import { getter } from '@progress/kendo-data-query';
import { getSelectedState, Grid, GridColumn } from '@progress/kendo-react-grid';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { CellRender, RowRender } from '../../../../ui/grid/renders';
import planColumns from './planColumns';


const EDIT_FIELD = 'inEdit';
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "smsId";
const idGetter = getter(DATA_ITEM_KEY);

const initialDataState = {
    skip: 0,
    take: 50,
};

const PlanGrid = ({height, callbackStoreId}) => {  

    const nrpsReportFiltersSearch = useSelector((store)=> store.reports?.nrpsReportFiltersSearch); 
    const planData = useSelector((store)=> store.reports?.nrpsReportPlanClick);  

    const [page, setPage] = React.useState(initialDataState);
    const [response, setResponse] = React.useState();
    const [selectedState, setSelectedState] = React.useState({});
    const [statePlanColumns, setStatePlanColumns] = React.useState(planColumns);  
    
    useEffect(() => {     
      setResponse((nrpsReportFiltersSearch?.data) ? nrpsReportFiltersSearch?.data : planData?.data)
    },[nrpsReportFiltersSearch, planData])
    
    const enterEdit = (dataItem, field) => {
        const newData = nrpsReportFiltersSearch?.data?.map(item => ({
          ...item,
          [EDIT_FIELD]: item.smsId === dataItem.smsId ? field : undefined
        }));
        setResponse(newData);
    };
     
    const exitEdit = () => {
        const newData = nrpsReportFiltersSearch?.data?.map(item => ({
          ...item,
          [EDIT_FIELD]: undefined
        }));
        setResponse(newData);
    };

    const itemChange = event => {
        let field = event.field || '';
        event.dataItem[field] = event.value;
        let newData = nrpsReportFiltersSearch?.data?.map(item => {
          if (item.smsId === event.dataItem.smsId) {
            item[field] = event.value;
          }
          return item;
        });
        setResponse(newData);
        // setChanges(true);
    };

    const pageChange = (event) => {
        setPage(event.page);
    };

    const onSelectionChange = React.useCallback((event) => {
      console.log("eventsel",event)
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        console.log("plannewSelectedState",newSelectedState)
          setSelectedState(newSelectedState);
          callbackStoreId(newSelectedState); 
        },[selectedState]);

        console.log("selection",selectedState)

    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });        
        setSelectedState(newSelectedState);                
    }, []);
    // callbackStoreId(selectedState);   
    
    

    const customCellRender = (td, props) => <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;
    const customRowRender = (tr, props) => <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />;

  return (
    <React.Fragment>
        <Grid        
        resizable={true}
        data={response
          ?.slice(page.skip, page.take + page.skip)
          .map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
        style={height}
        skip={page.skip}
        take={page.take}
        total={response?.length}
        pageable={true}
        onPageChange={pageChange}
        // pageable={{
        //     pageSizes: [5, 20, 50, 100, 200, 500, 1000],
        //   }}
        dataItemKey={DATA_ITEM_KEY}   
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        editField={EDIT_FIELD}
        onItemChange={itemChange}
        cellRender={customCellRender}
        rowRender={customRowRender}
      >
        <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="40px"
        headerSelectionValue={
            response?.findIndex((item) => !selectedState[idGetter(item)]) === -1
        } />
        {statePlanColumns.map((column, idx) => {
          return column.show && (                
            <GridColumn key={idx} field={column.field} title={column.title} width={column.width} editable={column.editable}/>
          );
        })}
      </Grid>      
    </React.Fragment>
  )
}
export default PlanGrid;
