import * as types from "../../actions/types";

const initialState = {
  alertsCustomData: null,
  selectedAlert: null,
  loading: false,
  error: null,
};

const AlertsCustomReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.GET_ALERTS_CUSTOMIZATION + "_FETCH":
      newState = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case types.GET_ALERTS_CUSTOMIZATION:
      newState = {
        ...state,
        alertsCustomData: action.payload,
        loading: false,
      };
      break;
    case types.GET_ALERTS_CUSTOMIZATION + "_ERROR":
      newState = {
        ...state,
        loading: false,
        error: action.payload,
        alertsCustomData: null,
      };
      break;

    // case types.SET_SELECTED_ALERT:
    // newState = {
    //     ...state,
    //     selectedAlert: action.payload,
    // };
    // break;

    default:
      newState = state;
      break;
  }
  return newState;
};

export default AlertsCustomReducer;
