import React, { useState } from "react";
import BasicInfo from "./BasicInfo";
import Skills from "./Skills";
import UserDevice from "./UserDevice";
import ReassignWork from "./ReassignWork";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadMask from "../../../../../utils/LoadMask";
import userIcon from "../../../../../assets/img/Component_233_2.png";
import { postResetRegistration, putAuditorStatus } from "../../../../../store/actions/people/resources";
import { MessageBox, showToast } from "../../../../../utils/Notification";
import { Switch } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import useLogout, { checkInvalidSession } from "../../../../../utils/useLogout";

export const resourceTabs = {
  basicInfo: {
    title: "Basic Information",
    comp: BasicInfo,
  },
  skills: {
    title: "Skills",
    comp: Skills,
  },
  userDevice: {
    title: "User Device",
    comp: UserDevice,
  },
  reassignWork: {
    title: "Reassign Work",
    comp: ReassignWork,
  },
};
const ViewResource = ({ selectedResourceDetails, setShouldRefresh, handleEditMode, resourceStatus, toggleResourceStatus,  screen,
  rolePepplePage }) => {
  const { handleAuthContext } = useLogout();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [showLoader, setShowLoader] = useState(null);
  const [activeTab, setActiveTab] = useState("basicInfo");
  const Comp = resourceTabs?.[activeTab]?.comp;
  const { t, i18n } = useTranslation();

  const [messageProps, setMessageProps] = useState(null);

  const messagePopup = (msg, button, icon) => {
    const callBackFn = async (msg) => {
      console.log(msg);
      setMessageProps(null);
      switch (msg) {
        case "YES":
          {
            // const params = `jsonString=${JSON.stringify({
            //   root: {
            //     UserName: selectedResourceDetails?.UNIQUE_ID,
            //     SupervisorEmail: authContext?.SpocMail,
            //   },
            // })}`;

            const params = {
              root: {
                UserName: selectedResourceDetails?.UNIQUE_ID,
                SupervisorEmail: authContext?.SpocMail
              },
            }
            console.log({ params });
            setShowLoader({
              className: ".resourceDetails_container",
              msg: "loading",
            });
            const res = await postResetRegistration(JSON.stringify(params), authContext);
            setShowLoader(null);
            if (checkInvalidSession(res)) {
              handleAuthContext();
              return;
            }
            const message = res?.ResetRegistrationResponse?.Message;
            if (message === "Success") {
              showToast("success", "Password Reset Successful");
            } else {
              showToast("error", message);
            }
            console.log({ res });
          }
          break;
        default:
          break;
      }
      // showToast("User click on yes");
    };
    setMessageProps({
      title: t("status"),
      buttons: button,
      icon: icon,
      message: t(`_${msg}`, `${msg}`),
      fn: callBackFn,
    });
  };
  const handlePasswordReset = async () => {
    messagePopup(
      "Are You Sure You Want To Reset The Auditor Password",
      "YESNO",
      "WARNING"
    );
  };

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}

      <div style={{ display: "flex", marginBottom: "1.5em" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Switch
            // checked={dataItem?.ActiveFlag === "1"}
            checked={resourceStatus}
            defaultChecked={resourceStatus}
            onChange={toggleResourceStatus}
            disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
          />

          <span
            style={{
              marginLeft: "10px",
              color: resourceStatus ? "#008A28" : "#AA213F",
            }}
          >
            {resourceStatus ? "ACTIVE" : "INACTIVE"}
          </span>
        </div>
        <div style={{ marginLeft: "auto", display: "flex", gap: ".3em" }}>
          <>
            <Button
              className="k-button k-secondary"
              type="button"
              icon="reset"
              style={{ border: "1px solid" }}
              onClick={handlePasswordReset}
              disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
            >
              Password Reset
            </Button>
            <Button
              className="k-button k-secondary"
              type="button"
              icon="edit"
              style={{ border: "1px solid" }}
              onClick={handleEditMode}
              disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
            >
              Edit
            </Button>
          </>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 700,
          marginBottom: ".5em",
        }}
      >
        <img src={userIcon} style={{ marginRight: "1em" }} />
        {selectedResourceDetails?.FIRST_NAME}{" "}
        {selectedResourceDetails?.LAST_NAME}
      </div>
      <div
        style={{
          height: "calc(100vh - 315px)",
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 0,
        }}
      >
        <Comp
          resourceTabs={resourceTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          selectedResourceDetails={selectedResourceDetails}
          screen={screen}
          rolePepplePage={rolePepplePage}
        />
      </div>
    </>
  );
};

export default ViewResource;
