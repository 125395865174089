import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column, getSelectedState, GridToolbar } from "@progress/kendo-react-grid";
import { getUpcCountsData } from '../getUpcCountsWindow';
import { useSelector } from 'react-redux';
import { getter } from '@progress/kendo-react-common';
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import useLogout, { checkInvalidSession } from '../../../../../../utils/useLogout';
const DATA_ITEM_KEY = 'uniqueId';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);


const deleteCell = (props) => <td style={{ textAlign: "center", color: "red" }}><Button look="flat" icon="delete" /></td>

const DetailGrid = (props) => {
    const { selectAllCategories } = props;

    const [result, setResult] = useState(props.dataItem?.Category || []);
    const [selectedState, setSelectedState] = React.useState({});
    const onSelectionChange = React.useCallback(event => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY
        });
        setSelectedState(newSelectedState);
    }, [selectedState]);
    const onHeaderSelectionChange = React.useCallback(event => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach(item => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    useEffect(() => {
        if (selectAllCategories) {
            const newSelectedState = {};
            result.forEach(item => {
                newSelectedState[idGetter(item)] = true;
            });
            setSelectedState(newSelectedState);
        }
    }, [selectAllCategories])

    return (
        <section>
            <div>
                <Grid
                    data={result.map(item => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                    }))}
                    sortable={true}
                    dataItemKey={DATA_ITEM_KEY} selectedField={SELECTED_FIELD} selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple'
                    }} onSelectionChange={onSelectionChange} onHeaderSelectionChange={onHeaderSelectionChange}
                >
                    <Column
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={
                            result.findIndex((item) => !selectedState[idGetter(item)]) === -1
                        }
                    />
                    <Column field="Name" title="Name" />
                    <Column field="Count" title="Assigned Count" />
                    <Column field="delete" title="" cell={deleteCell} />

                </Grid>
            </div>
        </section>
    );
};



const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            total: dataState.take,
        },
        dataState: dataState,
    };
};

let initialState = createDataState([], {
    take: 50,
    skip: 0,
});

function UpcAuditor({ primary_resource_name, status, upcsToCollect, upcWindow }) {
    const { handleAuthContext } = useLogout();
    const [showLoader, setShowLoader] = useState(null);
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [result, setResult] = React.useState(initialState.result);
    const [upcCountsData, setUpcCountsData] = useState([]);
    const [selectAllCategories, setSelectAllCategories] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [collapse, setCollapse] = useState("Expand All");

    console.log("rrr", upcCountsData, result)
    const authContext = useSelector((state) => state.commonReducer.authContext);

    function handleExpand() {
        const newData = result.data.map((d) => {
            d.expanded = !isExpanded
            return d
        })
        setIsExpanded((b) => !b);
        // setIsExpanded(!isExpanded? "");
        setResult({ ...result, data: newData })
        setCollapse(!isExpanded ? "Collpase All" : "Expand All")
    }

    useEffect(() => {

        const upcCounts = async () => {

            let params = {
                _dc: 1676617565097,
                jobid: upcWindow.JobId.toString(),
                storeid: upcWindow.StoreId?.toString()
            }

            setShowLoader({
                className: ".workOrders_grid",
                msg: "loading",
            });
            const res = await getUpcCountsData(params, authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            setUpcCountsData(res.Response?.Store?.Auditor?.[0]?.Category)
            res.Response?.Store?.Auditor?.[0]?.Category.map((d, i) => {
                d.uniqueId = i
                return d;
            })
            setResult((prev) => {
                const newData = prev.data.slice();
                if (newData.length > 0) {
                    newData[0].Category = res.Response?.Store?.Auditor?.[0]?.Category;
                }
                console.log({ newData })
                return {
                    ...prev, data: newData
                }
            })
            setShowLoader(null);
        }
        upcCounts();
    }, [])

    useEffect(() => {
        setResult({ data: [{ primary_resource_name, status, upcsToCollect }, ...result.data], total: result.total })
    }, [])

    const expandChange = (event) => {
        let newData = result.data.map((item) => {
            if (item.ProductID === event.dataItem.ProductID) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setResult({ data: newData, total: result.total });
    };

    return (
        <Grid
            data={result}
            {...dataState}
            sortable={true}
            detail={(props) => <DetailGrid {...props} selectAllCategories={selectAllCategories} />}
            expandField="expanded"
            onExpandChange={expandChange}
            style={{ height: "270px" }}
        >
            <GridToolbar>
                <div className='upc-counts-header'>
                    <p>Auditor UPC Counts</p>
                    <div>
                        <Checkbox onChange={() => { setSelectAllCategories((b) => !b) }} label={"Select All Categories"} style={{}} />
                        <Button className='k-secondary' onClick={handleExpand} style={{ marginLeft: "10px" }}>{collapse}</Button>
                    </div>
                </div>
            </GridToolbar>

            <Column field="primary_resource_name" title="Auditor Name" />
            <Column field="status" title="Status" />
            <Column field="upcsToCollect" title="# UPSC To Collect" />
            <Column field="CategoryName" title="Total Assigned" />
        </Grid>
    )

}

export default UpcAuditor
