import { useState, useCallback, useRef } from "react";
import {
  SearchFilterComp,
  SearchFilterContainer,
  SearchFilterFooter,
  SearchForm,
} from "../../ui/SearchFilter";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../../form/form-component";
import moment from "moment";
import LoadMask from "../../../utils/LoadMask";
import { useSelector } from "react-redux";
import { getAdminDetails } from "../../../store/actions/people/administrators";
import { useTranslation } from "react-i18next";

const initialSelectedData = {};
const SearchFilterWindow = ({
  setResult,
  dataState,
  setDataState,
  setSearthTerm,
  countryData
}) => {
  console.log('countryData', countryData);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [capsulesFilter, setCapsulesFilter] = useState([]);
  const [selectedData, setSelectedData] = useState(initialSelectedData);
  const [showLoader, setShowLoader] = useState(null);
  const { t } = useTranslation();
  const formRef = useRef();

  const removeCapsuleFilter = (index) => {
    const { name: removeFieldName, value: removeFieldValue } =
      capsulesFilter[index];
    const newSelectedData = { ...selectedData };
    if (removeFieldName === "Country") {
      newSelectedData.Country = newSelectedData.Country.filter(
        (c) => c.CountryID !== removeFieldValue
      );
    } else {
      delete newSelectedData[removeFieldName];
    }
    setSelectedData(newSelectedData);
    const newCapsuleFilter = [...capsulesFilter];
    newCapsuleFilter.splice(index, 1);
    setCapsulesFilter(newCapsuleFilter);
    const fetchAdminData = async () => {
      const params = {
        ...newSelectedData,
        CompanyId: authContext?.CompanyID,
        page: 1,
        StartCount: 0,
        MaxCount: 50,
      };
      setShowLoader({
        className: ".people_grid",
        msg: "loading",
      });
      const res = await getAdminDetails(params, authContext)
      if (res?.root?.Status === "Success") {
        res?.root?.Spocs.map((cn) => {
          cn.Country = countryData?.[cn.Country] ?? "";
          return cn;
        });
        const newResult = {
          data: res?.root?.Spocs || [],
          total:
            res?.root.TotalCount || res?.root?.Spocs?.length || dataState.take,
        };
        setResult(newResult);
      } else {
        console.log("fetchAdmin api error", res);
      }
      setShowLoader(null);
    }
    fetchAdminData()
  };

  const onSearchClick = useCallback(async (e) => {
    const params = {
      CompanyId: authContext?.CompanyID,
      _dc: Date.now(),
      page: 1,
      StartCount: 0,
      MaxCount: 50,
    };
    const searchTerm = e.target?.searchWorkOrder?.value
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setSearthTerm(searchTerm)
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await getAdminDetails(params, authContext)
    setSearthTerm(searchTerm);
    setDataState((state) => {
      return { ...state, skip: 0 }
    })
    if (res?.root?.Status === "Success") {
      res?.root?.Spocs.map((cn) => {
        cn.Country = countryData?.[cn.Country] ?? "";
        return cn;
      });
      const newResult = {
        data: res?.root?.Spocs || [],
        total:
          res?.root.TotalCount || res?.root?.Spocs?.length || dataState.take,
      };
      setResult(newResult);
    } else {
      console.log("fetchAdmin api error", res);
    }
    setShowLoader(null);
  }, [countryData]);

  const onFilterSubmitClick = async ({ values }) => {
    formRef.current.setFilterWindow(false);
    const newCapsuleFilter = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (key === "Region") {
          return acc;
        }
        if (key === "Country") {
          const countryData = value.map((c) => ({
            name: key,
            text: c.CountryName,
            value: c.CountryID,
          }));
          return [...acc, ...countryData];
        }
        if (key === "startdate" || key === "enddate") {
          value = moment(value).format("MM/DD/YYYY");
        }
        return [
          ...acc,
          {
            name: key,
            text: value,
            value,
          },
        ];
      },
      []
    );
    setSelectedData(values);
    setCapsulesFilter(newCapsuleFilter);
    Object.keys(values).forEach(key => {
      if (values[key] === null || values[key] === '') {
        delete values[key];
      }
    });

    const searchFilterParams = {
      ...values,
      CompanyId: authContext?.CompanyID,
      _dc: Date.now(),
      page: 1,
      StartCount: 0,
      MaxCount: 50,
    };
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });

    const res = await getAdminDetails(searchFilterParams, authContext);
    if (res?.root?.Status === "Success") {
      res?.root?.Spocs.map((cn) => {
        cn.Country = countryData?.[cn.Country] ?? "";
        return cn;
      });
      const newResult = {
        data: res?.root?.Spocs || [],
        total:
          res?.root.TotalCount || res?.root?.Spocs?.length || dataState.take,
      };
      setResult(newResult);
    } else {
      console.log("fetchAdmin api error", res);
      setResult({ data: [], total: 0 })
    }
    setShowLoader(null);
  };

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <SearchFilterContainer>
        <SearchForm
          placeholder={
            `${t("SEARCH")} ${t("firstname")} ${t("lastname")} ${t("emailid")} ${t("contactnumber")}`
          }
          onSubmitClick={onSearchClick}
        />

        <SearchFilterComp
          ref={formRef}
          onSubmitClick={onFilterSubmitClick}
          selectedData={selectedData}
          capsulesFilter={capsulesFilter}
          removeCapsuleFilter={removeCapsuleFilter}
          windowProps={{
            initialTop: 170,
            initialWidth: 1165,
            initialHeight: 200,
          }}
        >
          {(formRenderProps) => (
            <FormElement>
              <div style={{ display: "flex", gap: 15, flexWrap: "wrap" }}>
                <Field
                  name="FirstName"
                  label={t("firstname")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(33% - 15px)" }}
                  placeholder={t("firstname")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, FirstName: e.value });
                  }}
                />
                <Field
                  name="LastName"
                  label={t("lastname")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(33% - 15px)" }}
                  placeholder={t("lastname")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, LastName: e.value });
                  }}
                />
                <Field
                  name="Email"
                  label={t("Email")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(33% - 15px)" }}
                  placeholder={t("Email")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, Email: e.value });
                  }}
                />
              </div>
              <SearchFilterFooter handleReset={() => {
                setSelectedData(initialSelectedData);
                setTimeout(() => {
                  formRenderProps.onFormReset();
                }, 0);
              }} />
            </FormElement>
          )}
        </SearchFilterComp>
      </SearchFilterContainer>
    </>
  );
};

export default SearchFilterWindow;
