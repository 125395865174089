const auditorColumns = [
   
    {
      title: "WEEK ID",
      field: "weekId",
      show: true,
      width: "100px",
      editable: false,
    },
    {
      title: "STORE ID",
      field: "smsId",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "STORE NAME",
      field: "storeName",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "REGION",
      field: "region",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "PROVINCE",
      field: "province",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "CITY",
      field: "city",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "BANNER",
      field: "banner",
      show: true,
      width: 100,
      editable: false,
    },
    // {
    //   title: "VENDOR",
    //   field: "vendor",
    //   show: true,
    //   width: 100,
    // },
    {
      title: "AUDITOR ID",
      field: "auditorId",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "COLL TYPE",
      field: "collType",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "LIMIT DATE",
      field: "limitDate",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "STORE STATUS",
      field: "storeStatus",
      show: true,
      width: 100,
      // editable: false,
    },
    {
      title: "STORE RATING",
      field: "storeRating",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "Nr OF VISITS",
      field: "noOfVisits",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "TOTAL CATEGORIES",
      field: "totalCategories",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "TOTAL EANS",
      field: "totalEans",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "WITH PROMO",
      field: "withPromo",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "NO PRICE",
      field: "noPrice",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "UNKNOWN",
      field: "unknown",
      show: true,
      width: 100,
      editable: false,
    },
    {
      title: "NOTES(qty in the file)",
      field: "modeType",
      show: true,
      width: 100,
      editable: false,
    },
        

  ];
  export default auditorColumns;
  