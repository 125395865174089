import React, { useState, useEffect, useRef, useMemo } from "react";
import Header from "../header";
// import profilePicture from '../../assets/img/Profile_Picture.png';
import profilePicture from '../../assets/img/profile_pic2.png';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/logout";
import { useHistory } from "react-router-dom";
import changePasswordImg from '../../assets/images/Password_Reset.png';
import editPenImg from '../../assets/images/Edit_Pen.png';
import { Window } from "@progress/kendo-react-dialogs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { LoginPasswordInput } from "../ui/form/fields/input";
import { PasswordInput } from "../form/fields/input";
// import { FormInput, FormPasswordInput } from "../form/form-component";
import { forgotPasswordValidator } from "../form/validator";
import { changePassword, fetchuserprofile, updateUserProfile } from "../../store/actions/userProfile";
// import { MessageBox } from "../../utils/Notification";
import { useLocalization } from "@progress/kendo-react-intl";
import { MessageBox, showToast } from "../../utils/Notification";
import LoadMask from "../../utils/LoadMask";
import * as types from "../../store/actions/types";
import {
  FormCheckbox,
  FormComboBox,
  FormInput,
  FormPasswordInput,
} from "../ui/form/form-component";
import timezone from "./timezone.json"
import { useTranslation } from "react-i18next";

const UserProfile = ({ activeMenu }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const languages = useSelector((state) => state.commonReducer.languages);
  console.log({ languages })
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const userProfileResponse = useSelector((state) => state.commonReducer.userProfileResponse);
  const changePasswordResponse = useSelector((state) => state.commonReducer.changePasswordResponse);
  const updateUserProfileResponse = useSelector((state) => state.commonReducer.updateUserProfileResponse);
  const [userProfileData, setUserProfileData] = useState();
  const [showChangePasswordWindow, setShowChangePasswordWindow] = useState(false);
  const [newPasswordType, setNewPasswordType] = useState();
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [messageProps, setMessageProps] = useState(null);
  const [showLoader, setShowLoader] = useState(null);
  const [showEdit, setShowEdit] = useState(true);
  const history = useHistory();
  const localizationService = useLocalization();

  const saveUserForm = useRef(null);

  const timezoneData = useMemo(() => timezone.map((d) => t(d.zone)), [])
  console.log({ timezoneData },);
  const screen = 7;
  const userroleDetails = useSelector((state)=> state.dashboardReducer.userrolepermssioninfo)
  const [roleUserProfilePage,setRoleUserProfilePage]=useState({})
 
  function getObjectByScreenId(screenId) {
    for (let i = 0; i < userroleDetails?.rolePermissionsInfo.length; i++) {
        if (userroleDetails?.rolePermissionsInfo[i].screensInfo.screenId === screenId) {
            return userroleDetails?.rolePermissionsInfo[i];
        }
    }
    return null; // Return null if not found
  }

  useEffect(()=>{
    setRoleUserProfilePage(getObjectByScreenId(screen))
  },[])

  console.log(roleUserProfilePage,screen,"US1234")

  // console.log(userProfileResponse);

  const logoutClickHandler = () => {
    let spocDetails = JSON.parse(localStorage.getItem(`spocDetails`));
    let CompanyId = spocDetails?.CompanyID,
      UserId = spocDetails?.SpocMail
    console.log(CompanyId, UserId);
    //logout api
    dispatch(logout({ CompanyId, UserId }));
    // dispatch(logout);
    dispatch({
      type: types.SET_AUTH_CONTEXT,
      payload: null
    });
    localStorage.removeItem("spocDetails");
    history.push('/login');
  }

  useEffect(() => {
    console.log(userProfileResponse?.SpocInfo);
    if (userProfileResponse) {
      setShowLoader(null);
      setUserProfileData(userProfileResponse);

    }
  }, [userProfileResponse]);

  useEffect(() => {
    console.log(updateUserProfileResponse);
    if (updateUserProfileResponse) {
      if (updateUserProfileResponse?.Status === 'Profle update successful') {
        showToast(
          "success",
          localizationService.toLanguageString(
            "pleaseEnterMandatoryFields",// to be updated
            `${updateUserProfileResponse?.Status}`
          )
        );
        dispatch(fetchuserprofile({ SpocMailId: authContext?.SpocMail, CompanyId: authContext?.CompanyID }, authContext));
        setShowLoader({
          // className: ".k-grid-content",
          msg: "Loading",
        });
        setShowEdit(prevState => !prevState);
        dispatch({
          type: types.UPDATE_USER_PROFILE_RESPONSE,
          payload: null,
        });
      } else {
        showToast(
          "error",
          localizationService.toLanguageString(
            "pleaseEnterMandatoryFields",// to be updated
            `${updateUserProfileResponse?.Status}`
          )
        );
      }
    }
  },[updateUserProfileResponse]);


  useEffect(() => {
    console.log(changePasswordResponse);
    if (changePasswordResponse) {
      if (changePasswordResponse?.Status === 'FAILURE') {
        showToast(
          "error",
          localizationService.toLanguageString(
            "pleaseEnterMandatoryFields",// to be updated
            `${changePasswordResponse?.Message}`
          )
        );
      }
      if (changePasswordResponse?.Status === 'SUCCESS') {
        showToast(
          "success",
          localizationService.toLanguageString(
            "pleaseEnterMandatoryFields",// to be updated
            `${changePasswordResponse?.Message}`
          )
        );
        logoutClickHandler();
      }
      dispatch({ type: types.CHANGE_PASSWORD_RESPONSE, payload: null })
    }


  }, [changePasswordResponse]);

  // const languages = [
  //   "English",
  //   "Spanish",
  //   "German",
  //   "French",
  //   "Dutch",
  //   "Portuguese",
  // ];

  let companyProductDetails = '';

  const root = {
    SpocInfo: {
      CompanyID: "292",
      Country_Code: null,
      Date: "mm\\dd\\yyyy",
      Language: "English",
      Name: "vamsi",
      PhoneNumber: "12345",
      Role: "Administrator",
      Timezone: "CST (UTC−06:00)"
    },
    VendorInfo: {
      Status: {
        StatusCode: "100",
        StatusMessage: "Success"
      },
      TempAuditorId: "123456",
      TempAuditorName: "vamsid",
      Vendor: {
        // CompanyProductDetails:[{}],
        VendorDetails: {
          CompanyAddress: "US",
          CompanyAlias: "Navigator Test",
          CompanyContactNumberPOC: "9298392893",
          CompanyId: "292",
          CompanyName: "NAV_NA_TEST",
          CompanyPrimaryContactName: "Vamsi",
          CompanyRegisterationNumber: "110930391",
          CompanyType: "Corporation",
          CompanyWebsite: "www.navnatest.com",
          CompanyZipCode: "532-02",
          Region: "1",
          ResourceLimit: null,
          SecCmpyAddrs: "123",
          SecCmpyPhno: "5555555555213",
          SecondaryVendorID: "99",
          companyskills: "abcd",
        }
      }
    }
  }

  const showNewPasswordClick = () => {
    setNewPasswordType(() => {
      if (newPasswordType === "password") {
        return "text";
      } else {
        return "password";
      }
    });
  };
  const showConfirmPasswordClick = () => {
    setConfirmPasswordType(() => {
      if (confirmPasswordType === "password") {
        return "text";
      } else {
        return "password";
      }
    });
  };
  const newPasswordChange = (e, formRenderProps) => {
    setNewPassword(formRenderProps.valueGetter('newPassword'));
  }
  const confirmPasswordChange = (e, formRenderProps) => {
    setConfirmPassword(formRenderProps.valueGetter('confirmPassword'));
    console.log(formRenderProps);
  }
  const confirmPasswordValidator = (value) => !value ?
    "This Field Is Required" :
    (newPassword === value ? "" : `The minimum length for this field is 8
Your Passwords Do Not Match.`);




  const changePasswordClicked = () => {
    // console.log(`changePasswordClicked`);
    setShowChangePasswordWindow(prevState => !prevState);
  }

  const editButtonClicked = () => {
    setShowEdit(prevState => !prevState);
  }

  const onFormSubmit = (e) => {
    let spocDetails = JSON.parse(localStorage.getItem(`spocDetails`));
    const Email = spocDetails?.SpocMail;
    const OldPassword = e?.values?.oldPassword;
    const NewPassword = e?.values?.newPassword;
    const ConfirmPassword = e?.values?.confirmPassword;
    console.log(Email, OldPassword, NewPassword, ConfirmPassword);
    if (Email && OldPassword.length && NewPassword.length && ConfirmPassword.length) {
      dispatch(
        changePassword(JSON.stringify({
          Registration: {
            ConfirmPassword,
            Email,
            NewPassword,
            OldPassword,
            Type: "VENDOR",
          }
        }))
      )
    }
    console.log();
    console.log(e.values);
  }
  const onSaveChanges = (form) => {
    console.log(form.values);
    dispatch(
      updateUserProfile({
        // SpocMailId: UserId,
        // CompanyId:CompanyId,
        Vendor: {
          VendorDetails: {
            CompanySkills: form.values.companySkills,
            SecCompanyPhone: form.values.secCmpyPhno,
            SecCompanyAddress: form.values.secCmpyAddrs,
            TempAuditorId: form.values.tempAuditorId,
            TempAuditorName: form.values.tempAuditorName,

          },
          SpocInfo: {
            PhoneNumber: form.values.contactNumber,
            Timezone: form.values?.timezone || "",
            Language: form.values.selectedLanguage,
          }
        }
      }, authContext)
    );
  }
  let contactNumber = userProfileResponse?.SpocInfo?.PhoneNumber,
    companySkills = userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.companyskills,
    tempAuditorId = userProfileResponse?.VendorInfo?.TempAuditorId,
    secCmpyAddrs = userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.SecCmpyAddrs,
    tempAuditorName = userProfileResponse?.VendorInfo?.TempAuditorName,
    secCmpyPhno = userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.SecCmpyPhno

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}
      {showChangePasswordWindow &&
        // <Window
        <Dialog
          // title={`${localizationService.toLanguageString(
          //   "alertChat",
          //   "AlertChat"
          // )} - ${selectedAlert?.ID}`}
          title={() => null}
          onClose={changePasswordClicked}
          // initialWidth={420}
          // initialHeight={600}
          height={600}
          width={420}
          modal={true}
          //   modal={true}
          style={{ display: "flex" }}
        >
          <div style={{
            display: 'flex',
            // justifyContent: 'center',
            // fontWeight:'bold',
            // backgroundColor: 'greenyellow',
            height: '100%',
            flexDirection: 'column',
          }}>
            <div style={{
              display: 'flex',

              height: '20%',
              // backgroundColor: 'pink',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',

            }}>{t("changepassword")}
            </div>

            <div styles={{
              // width: "50%",
              // height: '80%',
              display: 'flex',
              margin: '50px',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Form
                //   initialValues={initialFormData}
                // initialValues={{ emailId: emailId?.length ? emailId : '' }}
                onSubmitClick={onFormSubmit}

                render={(formRenderProps) => (
                  <FormElement
                  >
                    <div style={{
                      width: "100%",
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '8%'
                    }}>
                      <Field
                        // data={emailId ? emailId : ''}
                        name="oldPassword"
                        component={FormInput}
                        wrapperStyle={{ width: "80%" }}
                        // validationMessage='This field should be an e-mail address in the format "user@example.com"'
                        // validator={()=>emailValidator(emailValidatorText)}
                        // validator={loginEmailValidator}
                        placeholder={t('oldpassword')}
                        label={t('oldpassword')}
                        required={true}
                      // onChange={(e) => onEmailChange(e, formRenderProps)}
                      />
                    </div>


                    <div style={{
                      width: "100%",
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '10%'
                    }}>
                      <div style={{
                        width: '80%',
                        color: 'blue'
                      }}>{`${t('newpassword')}*`}</div>
                      <div style={{ width: '80%' }}>
                        <Field
                          // data={emailId ? emailId : ''}
                          name="newPassword"
                          type={newPasswordType}
                          onClick={showNewPasswordClick}
                          component={LoginPasswordInput}
                          // wrapperStyle={{ width: "100%" }}
                          // validationMessage='This field should be an e-mail address in the format "user@example.com"'
                          // validator={()=>emailValidator(emailValidatorText)}
                          // validator={loginEmailValidator}
                          validator={forgotPasswordValidator}
                          placeholder={t('newpassword')}
                          required={true}
                          onChange={(e) => newPasswordChange(e, formRenderProps)}
                        // onChange={(e) => onEmailChange(e, formRenderProps)}
                        />
                      </div>
                    </div>
                    <div style={{
                      width: "100%",
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '10%'
                    }}>
                      <div style={{
                        width: '80%',
                        color: 'blue'
                      }}>{`${t('confirmpassword')}*`}</div>
                      <div style={{ width: '80%' }}>
                        <Field
                          // data={emailId ? emailId : ''}
                          name="confirmPassword"
                          type={confirmPasswordType}
                          onClick={showConfirmPasswordClick}
                          component={LoginPasswordInput}
                          wrapperStyle={{ width: "100%" }}
                          // validationMessage='This field should be an e-mail address in the format "user@example.com"'
                          // validator={()=>emailValidator(emailValidatorText)}
                          validator={confirmPasswordValidator}
                          placeholder={t('confirmpassword')}
                          required={true}
                          onChange={(e) => confirmPasswordChange(e, formRenderProps)}
                        // onChange={(e) => onEmailChange(e, formRenderProps)}
                        />
                      </div>
                    </div>
                    <div style={{
                      // display:'flex', gap: 150
                      marginTop: '10px'
                    }}
                    >
                    </div>

                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "15px",
                      }}
                    >
                    </div>
                    <div>
                      <Button
                        id="forgot"
                        type={"submit"}
                        style={{
                          width: "25%",
                          height: "40px",
                          marginLeft: '35%'
                        }}
                        className="k-primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            </div>
          </div>
          {/* </Window> */}
        </Dialog>
      }

      <Form
        ref={saveUserForm}
        initialValues={{
          contactNumber: contactNumber,
          companySkills: companySkills,
          tempAuditorId: tempAuditorId,
          secCmpyAddrs: secCmpyAddrs,
          tempAuditorName: tempAuditorName,
          secCmpyPhno: secCmpyPhno,
          selectedLanguage: userProfileResponse?.SpocInfo?.Language,
          timezone: userProfileResponse?.SpocInfo?.Timezone
          // contactNumber :userProfileResponse?.SpocInfo?.PhoneNumber,
          // companySkills: userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.companyskills,
          // tempAuditorId: userProfileResponse?.VendorInfo?.TempAuditorId,
          // secCmpyAddrs: userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.SecCmpyAddrs,
          // tempAuditorName: userProfileResponse?.VendorInfo?.TempAuditorName,
          // secCmpyPhno: userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.SecCmpyPhno,
          // selectedLanguage: languages[0],
        }}
        onSubmitClick={onSaveChanges}
        render={(formRenderProps) => (
          <FormElement
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Header activeMenu={t("userProfile")} wrapperStyle={{ alignItems: 'center' }} >
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "end",
                  gap: ".8rem",
                  marginRight: "2rem"
                }}
                className="languageInput"
              >
                <Button
                  className="k-primary"
                  type="button"
                  onClick={changePasswordClicked}
                >
                  {t("changepassword")}
                </Button>
                {showEdit && <Button
                  className="k-secondary"
                  icon="k-icon k-i-pencil k-icon-sm left-arrow"
                  type="button"
                  onClick={editButtonClicked}
                  disabled={roleUserProfilePage?.screensInfo?.screenId == screen ? ( roleUserProfilePage?.createFlag == 'N' ? true : false ) : true }
                >
                  {t("Edit")}

                </Button>}
                {!showEdit &&
                  <Button
                    primary
                    type={"submit"}
                  >
                    {localizationService.toLanguageString(
                      "saveChanges",
                      "Save Changes"
                    )}
                  </Button>
                }
                {!showEdit && <Button
                  className="k-button k-secondary"
                  onClick={() => {
                    saveUserForm.current.onReset();
                    setShowEdit(true)
                  }}
                >
                  Cancel
                </Button>}

                <Button
                  className="k-button k-ghost"
                  type="button"
                  onClick={logoutClickHandler}>
                  {t("Logout")}
                </Button>
              </div>
            </Header>


            <div style={{
              backgroundColor: '#F2F2F2',
              padding: '30px',
              display: "flex",
              alignItems: 'center',
            }}>
              <img style={{
                width: '96px', height: '96px',

              }} src={profilePicture} alt="" />
              <div style={{
                display: 'flex',
                width: '52.5%',
                height: '100%',
                fontSize: '24px',
                alignItems: 'center',
                fontWeight: 'bold',
                color: '#000',
                padding: '0 0 0 20px'
              }}>
                <label>{userProfileResponse?.SpocInfo?.Name}</label>
              </div>

              <div style={{
                width: '19.5%',
                height: '100%',
                fontSize: '13px',
                color: '#000',
              }}>
                <div style={{
                  height: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <label style={{ marginBottom: '10px' }}>
                      <b>{t("role")}</b>
                    </label>
                    <label>
                      <span>{userProfileResponse?.SpocInfo?.Role}</span>
                    </label>
                  </div>
                </div>
                <div style={{
                  height: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                  <label><b>{t("Language")}</b></label>
                  {showEdit ? <span style={{ marginTop: 5 }}>{userProfileResponse?.SpocInfo?.Language}</span>
                    :
                    <Field
                      name='selectedLanguage'
                      component={FormComboBox}
                      // textField="LanguageName"
                      // valueField="LanguageName"
                      data={languages?.map((d) => d?.LanguageName) || []}
                      wrapperStyle={{ margin: 0 }}
                      // defaultValue={languages?.find((d)=>d?.LanguageName===userProfileResponse?.SpocInfo?.Language) || ""}
                      style={{
                        width: 150,
                        backgroundColor: 'rgba(217, 217, 217)',
                      }}
                    />
                  }
                </div>
              </div>
              <div style={{
                width: '19.5%',
                height: '100%',
                fontSize: '13px',
                color: '#000',
              }}>
                <div style={{
                  height: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <label style={{ marginBottom: '10px' }}>
                      <b>{t("contactnumber")}</b>
                    </label>
                    {showEdit && <label>
                      <span>{userProfileResponse?.SpocInfo?.PhoneNumber}</span>
                    </label>}
                    {!showEdit &&
                      <Field
                        component={FormInput}
                        name='contactNumber'
                        // label="Contact Number"
                        wrapperStyle={{ marginTop: 5 }}
                        style={{
                          padding: 0,
                          border: 'none',
                          borderBottom: '1px solid black',
                          width: 150,
                          backgroundColor: '#F2F2F2',
                          height: 15
                        }}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.toString().length >= 15
                          ) {
                            event.preventDefault();
                          }
                        }}
                        value={userProfileResponse?.SpocInfo?.PhoneNumber}
                      />
                    }
                  </div>
                </div>
                <div style={{
                  height: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <label><b>{t("timezone")}</b></label>
                    {showEdit && <span style={{ marginTop: 5 }}>{userProfileResponse?.SpocInfo?.Timezone}</span>}
                    {!showEdit &&
                      <Field
                        name="timezone"
                        component={FormComboBox}
                        wrapperStyle={{ marginTop: 0 }}
                        data={timezoneData}
                        style={{
                          width: 150,
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>

            <div style={{
              padding: '24px 40px',
              backgroundColor: '#fff',
              flexGrow: 1,
              overflow: 'auto'
            }}>
              <div style={{
                paddingBottom: 15,
                borderBottom: '1px solid lightgrey',
                display: 'flex',

              }}>
                <div style={{
                  width: '65%',
                  fontSize: '13px'
                }}>
                  <div>
                    <span><b>{t("CompanyName")}</b></span>
                    <div style={{ marginTop: 5 }}>{
                      userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.CompanyName ?
                        userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.CompanyName : ""
                    }</div>
                  </div>
                </div>
                <div style={{
                  width: '35%',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'end',
                  paddingBottom: '20px'
                }}>{
                    userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.CompanyWebsite ?
                      userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.CompanyWebsite : ""
                  }</div>
              </div>

              <div style={{
                display: 'flex'
              }}>
                <div style={{
                  width: '50%',
                  borderRight: '1px solid lightgrey',
                  display: 'flex',
                  fontSize: '13px'
                }}>
                  <div style={{
                    width: '45%',
                    display: "flex",
                    flexDirection: "column",
                    gap: 15
                  }}>
                    <div style={{
                      marginTop: '20px',
                    }}>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("companyid")}</b></span>
                      </div>

                      <div>{userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails.CompanyId ?
                        userProfileResponse?.VendorInfo.Vendor?.[0].VendorDetails.CompanyId : ''}</div>
                    </div>
                    <div>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("CompanyType")}</b></span>
                      </div>
                      <div>{userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.CompanyType
                        ? userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.CompanyType : ""}</div>
                    </div>
                    <div >
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("CompanyRegistrationNumber")}</b></span>
                      </div>
                      <div style={{ height: 15 }}>{userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.CompanyType?.CompanyRegisterationNumber ?
                        userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.CompanyType?.CompanyRegisterationNumber : ''}</div>
                    </div>
                    <div>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("CompanyAddress")}</b></span>
                      </div>
                      <div style={{ height: 15 }}>{userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.CompanyType?.CompanyAddress ?
                        userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.CompanyType?.CompanyAddress : ''}</div>
                    </div>
                    <div >
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("REGION")}</b></span>
                      </div>
                      <div>{locationData?.Region?.RegionName}</div>
                    </div>
                  </div>


                  <div style={{
                    width: '45%',
                    display: "flex",
                    flexDirection: "column",
                    gap: 15
                  }}>
                    <div style={{
                      marginTop: '20px',
                    }}>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("aliasforcompany")}</b></span>
                      </div>

                      <div>{userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.CompanyAlias ?
                        userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.CompanyAlias : ''}</div>
                    </div>
                    <div>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("Products")}</b></span>
                      </div>
                      <div>{userProfileResponse?.VendorInfo?.Vendor?.[0]?.CompanyProductDetails?.map((c) => c?.ProductName).join(", ")}</div>
                    </div>
                    <div>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("CompanySkills")}</b></span>
                      </div>
                      <div
                        style={{ height: 15 }}
                      >
                        {showEdit ? <div>{userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.companyskills}</div> : <Field
                          component={FormInput}
                          name="companySkills"
                          wrapperStyle={{ margin: 0 }}
                          style={{
                            border: 'none',
                            borderBottom: '1px solid black',
                            height: 15,
                            padding: 0
                          }}
                        />}
                      </div>

                    </div>
                    <div >
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("postal_code")}</b></span>
                      </div>
                      <div style={{ height: 15 }}>{userProfileResponse?.VendorInfo?.Vendor?.VendorDetails?.CompanyZipCode}</div>
                    </div>
                    <div >
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("COUNTRY")}</b></span>
                      </div>
                      <div>{locationData?.Country?.map((d) => d.CountryName).join(", ")}</div>
                    </div>
                  </div>
                </div>

                <div style={{
                  width: '50%',
                  display: 'flex',
                  fontSize: '13px'
                }}>
                  <div style={{
                    width: '10%'
                  }}>

                  </div>
                  <div style={{
                    width: '45%',
                    display: "flex",
                    flexDirection: "column",
                    gap: 15
                  }}>
                    <div style={{
                      marginTop: '20px',
                    }}>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("CompanyPrimaryContactName")}</b></span>
                      </div>

                      <div>{
                        userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.CompanyPrimaryContactName ?
                          userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.CompanyPrimaryContactName : ""
                      }</div>
                    </div>
                    <div>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("TMP_AUDIT_ID_LBL")}</b></span>
                      </div>

                      <div style={{ height: 25, marginTop: 5 }}>
                        {showEdit ? <div>{userProfileResponse?.VendorInfo?.TempAuditorId}</div> :
                          <Field
                            component={FormInput}
                            name="tempAuditorId"
                            wrapperStyle={{ margin: 0 }}
                            onKeyPress={(event) => {
                              if (
                                !/[0-9]/.test(event.key) ||
                                event.target.value.toString().length >= 6
                              ) {
                                event.preventDefault();
                              }
                            }}
                            style={{
                              border: 'none',
                              borderBottom: '1px solid black',
                              width: '80%',
                              height: 15,
                              padding: 0
                            }}
                          />
                        }
                      </div>
                    </div>

                    <div>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("SecondaryCompanyAddress")}</b></span>
                      </div>

                      <div style={{ height: 25, marginTop: 5 }}>
                        {showEdit ?
                          <div>{userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.SecCmpyAddrs}</div> :
                          <Field
                            component={FormInput}
                            name="secCmpyAddrs"
                            wrapperStyle={{ margin: 0 }}
                            style={{
                              border: 'none',
                              borderBottom: '1px solid black',
                              width: '80%',
                              height: 15,
                              padding: 0
                            }}
                          />
                        }
                      </div>
                    </div>

                  </div>


                  <div style={{
                    width: '45%',
                    display: "flex",
                    flexDirection: "column",
                    gap: 15
                  }}>
                    <div style={{
                      marginTop: '20px',
                    }} >
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("primaryContactNumber")}</b></span>
                      </div>

                      <div>{
                        userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.CompanyContactNo ?
                          userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.CompanyContactNo : ""
                      }</div>
                    </div>
                    <div>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("TMP_AUDIT_NAME_LBL")}</b></span>
                      </div>
                      <div style={{ height: 25, marginTop: 5 }}>
                        {showEdit && <div>{
                          userProfileResponse?.VendorInfo?.TempAuditorName ?
                            userProfileResponse?.VendorInfo?.TempAuditorName : ""
                        }</div>}
                        {!showEdit &&
                          <Field
                            component={FormInput}
                            name="tempAuditorName"
                            wrapperStyle={{ margin: 0 }}
                            style={{
                              border: 'none',
                              borderBottom: '1px solid black',
                              width: '80%',
                              height: 15,
                              padding: 0
                            }}
                          />
                        }
                      </div>
                    </div>
                    <div>
                      <div style={{ paddingBottom: '3px' }}>
                        <span><b>{t("SecondaryCompanyContactNumber")}</b></span>
                      </div>
                      <div style={{ height: 25, marginTop: 5 }}>
                        {showEdit && <div>{
                          userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.SecCmpyPhno ?
                            userProfileResponse?.VendorInfo?.Vendor?.[0].VendorDetails?.SecCmpyPhno : ""
                        }</div>}
                        {!showEdit &&
                          <Field
                            component={FormInput}
                            name="secCmpyPhno"
                            wrapperStyle={{ margin: 0 }}
                            onKeyPress={(event) => {
                              if (
                                !/[0-9]/.test(event.key) ||
                                event.target.value.toString().length >= 15
                              ) {
                                event.preventDefault();
                              }
                            }}
                            style={{
                              border: 'none',
                              borderBottom: '1px solid black',
                              width: '80%',
                              height: 15,
                              padding: 0
                            }}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    </>
  );
};

export default UserProfile;
