import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid:authContext?.CompanyID || ""
  // companyid: "381",
});

export const getAuditorDetails = async (params, authContext) => {
  const res = await Get(
    AppURL.fetchauditordetails,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("fetchAdminDetails", { res });
  return res;
};

export const downloadFile = async (params, authContext) => {
    const headers = {
      OrganizationUnit: authContext?.organizationUnit || "",
      userid: authContext?.SpocMail || "",
      "Content-Type": "application/x-www-form-urlencoded; application/json",
      countryid:authContext?.CountryCode,
    };
    const res = await Get(AppURL.exportRecords, params, headers, true);
    console.log("file download in people=>bulk upload", { res });
    return res;
  };
export const getExportTemplate = async (params, authContext) => {
    const headers = {
      OrganizationUnit: authContext?.organizationUnit || "",
      userid: authContext?.SpocMail || "",
      "Content-Type": "application/x-www-form-urlencoded; application/json",
      countryid:authContext?.CountryCode,
    };
    const res = await Get(AppURL.exportTemplate, params, headers, true);
    console.log("template download in people=>bulk upload", { res });
    return res;
  };
  export const uploadAuditor = async (queryString,body, authContext) => {
    const headers = {
      "Content-Type": "application/json",
    };
    let url = AppURL.loadAuditors;
    if(queryString){
      url += queryString;
    }
    const res = await Post(url, body, headers, true).then((res) => res.json());
    console.log("uploadAuditor","res")
    return res;
  }
