import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    // {
    //     title: "REGION",
    //     field: "Region",
    //     show: true,
    //     columnMenu: "CustomColumnMenuFilter",
    //     filter: "numeric",
    //     width: 130
    // },
    {
        title: "COUNTRY",
        field: "Country",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "collectiontype",
        field: "CollectionType",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        
        width: 180
    },
    {
        title: "YEAR",
        field: "Year",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 100
    },
    {
        title: "WEEK",
        field: "Week",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 100
    },
    {
        title: "WORKORDERID",
        field: "WorkOrderID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 150
    },
    {
        title: "AUDIT_ID",
        field: "AuditId",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:"AUDITOR ID",
        field: "AuditorId",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "auditortype",
        field: "AuditorType",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        
        width: 130
    },
    {
        title:"AUDIT DATE",
        field: "AuditDate",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "date",
        // format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title:"Status",
        field: "Status",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        
        width: 130
    },
    {
        title: "exportedfrombb",
        field: "ExportedFromBB",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
       
        width: 130
    },
    {
        title: "tasktype",
        field: "TaskType",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        
        width: 130
    },
    {
        title: "CITY",
        field: "CITY",
        show: true,
        // cell:UploadCell
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "PROVINCE",
        field: "PROVINCE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "SMS ID",
        field: "StoreNumber",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        
        width: 130
    },
    {
        title: "StoreName",
        field: "STORENAME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        
        width: 130
    },
    {
        title: "storeaddress",
        field: "STORE_ADDRESS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        
        width: 130
    },
    {
        title: "RETAILER NUMBER",
        field: "RETAILER_NUMBER",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "store_open_time",
        field: "STORE_OPEN_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 150
    },
    {
        title: "store_collect_time",
        field: "STORE_COLLECT_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 150
    },
    {
        title: "time_before_collect_(minutes)",
        field: "TIME_BEFORE_COLLECT_",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 180
    },
    {
        title: "store_close_datetime",
        field: "STORE_CLOSE_DATETIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 180
    },
    {
        title: "first_display_start_time",
        field: "FIRST_DISPLAY_START_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 180
    },
    {  
        title: "last_display_end_time",
        field: "LAST_DISPLAY_END_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 180
    },
    {
        title: "totalstoretime(minutes)",
        field: "TOTALSTORETIMEMINUTES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 200
    },
    {
        title: "image-onlytime(minutes)",
        field: "IMAGE_ONLY_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 200
    },
    {
        title: "scan-onlytime(minutes)",
        field: "SCAN_ONLY_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 200
    },
    {
        title: "scanplusimagetime(minutes)",
        field: "SCAN_PLUS_IMAGE_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 200
    },
    {
        title: "Non-collect Time (Minutes)",
        field: "NON_COLLECT_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 200
    },
    {
        title: "total#ofacceptedimages",
        field: "TOTAL_ACCEPTED_IMAGES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 250
    },
    {
        title: "total#ofdeclinedimages",
        field: "TOTAL_DECLINED_IMAGES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 230
    },
    {
        title: "total#ofdisplays",
        field: "TOTAL_DISPLAYS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 200
    },
    {
        title: "total#ofimages",
        field: "TOTAL_IMAGES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 200
    },
    {
        title: "#ofuniquescannedbarcodes",
        field: "UNIQUE_SCANNEDBARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 230
    },
    {
        title: "#ofuniqueimagedbarcodes",
        field: "UNIQUE_IMAGEDBARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 230
    },
    {
        title: "total#ofimagedbarcodes",
        field: "TOTAL_IMAGEDBARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 230
    },
    {
        title: "total#ofscannedbarcodes",
        field: "TOTAL_SCANNEDBARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 230
    },
    {
        title: "totalworkingtime(minutes)",
        field: "TOTAL_WORKINGTIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 230
    },
    {
        title:  "total#ofbarcodescollected",
        field: "TOTAL_BARCODESCOLLECTED",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 260
    },
    {
        title: "total#ofuniquebarcodescollected",
        field: "TOTAL_UNIQUEBARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 280
    },
    {
        title: "PREV_WEEK_SCANNED_BARCODES",
        field: "PREV_WEEK_SCANNED_BARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 250
    },
    {
        title: "STORE CLOSE COMMENTS",
        field: "STORE_CLOSE_COMMENTS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 230
    },
    {
        title: "Non-Collect Time Displays Min",
        field: "NON_COLLECT_TIME_DISPLAY_MIN",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 250
    },
    {
        title: "Teamstore",
        field: "TeamStore",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        
        width: 100
    },
    {
        title: "PrimReceiveMergeTmStpmpLbl",
        field: "PRIMARY_RECEIVE_MERGE_TIMESTAMP",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 250
    },
    {
        title: "ReceiveMergeForPrimAuditorLbl",
        field: "RECEIVE_MERGE_STATUS_FOR_PRIMARY",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 280
    },
    {
        title: "SendMergeStatusForSecAudLbl",
        field: "SEND_MERGE_STATUS_FOR_SECONDARY",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 280
    },
    {
        title:  "SecondSendMergeTmStmpLbl",
        field: "SENCONDARY_SEND_MERGE_TIMESTAMP",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 250
    },
    {
        title: "NewItemsCollectedCount",
        field: "NEW_ITEM_COUNT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 230
    },
    {
        title: "New Items Scanned Over Max Limit",
        field: "NEW_ITEMS_SCANNED_OVER_MAXLIMIT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 280
    },
    {
        title: "NewItemsTimeSpent",
        field: "NEW_ITEMS_TIME_SPENT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 200
    },
    
    
];
export default columns;