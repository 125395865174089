
import { Get, Post, PostwithTimeoutHandler } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";
import MOBILEVERSIONRESOSPONSE from "./mobileVersionRes.json";

export const getMobileVersionReport = async (params, authContext) => {

    const header = {
        OrganizationUnit: authContext?.organizationUnit || "",
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "content-type": "application/json",
        // "content-type": "application/x-www-form-urlencoded; application/json",
    }


    // const res = await Get(AppURL.getMobileVersionReport , params, header, true).then((res) =>
    const res = await Post(AppURL.getMobileVersionReport , params, header, true).then((res) =>
        res.json()
    );
    return res;
};

export const getMobileSummaryReport = async (params, authContext) => {
    const header = {
        OrganizationUnit: authContext?.organizationUnit || "",
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "content-type": "application/json",
    }

    const res = await PostwithTimeoutHandler(AppURL.reportsAPI , params, header, true,10000).then((res) =>
        res.json()
    );

    return res;
};

export const getApplicationName = async (params, authContext, url) => {
    const header = {
        OrganizationUnit: authContext?.organizationUnit || "",
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "content-type": "application/json",
    }


    const res = await PostwithTimeoutHandler(url, params, header, true,10000).then((res) =>
        res.json()
    );
    return res;
}

export const getApplicationData = async (params, authContext, url) => {
    const header = {
        OrganizationUnit: authContext?.organizationUnit || "",
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "content-type": "application/json",
    }


    const res = await PostwithTimeoutHandler(url, params, header, true,10000).then((res) =>
        res.json()
    );
    return res;
}



export const getMobileVersionExportData = async (params, authContext) => {

    const header = {
        "Content-Type": "application/json"    
    }
  
    // const exportFile = await Get(AppURL.getMobileVersionExportData,params,null,true);    
    const exportFile = await Post(AppURL.getMobileSummaryExportReport, params, header);    
      console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
      const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);    
      // const FILE_NAME = Date.now();
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl; 
      anchor.download = disposition?.[1].replace(/['"]+/g, '');
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor); 
  
  
  
  };
