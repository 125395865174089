import { useLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import NielsenIQ_Loader from '../assets/images/niq_loader.svg';

const LoadMask = props => {
    const localizationService = useLocalization();
    const component = props.className ? document && document.querySelector(props.className) : null;
    const mask = (
        <div className="k-loading-mask" style={props.style ? props.style : { zIndex: 10007 }}>
            <div className="k-loading-color" />
            <center style={{ top: '40%', left: '40%', position: 'absolute' }}>
                <img src={NielsenIQ_Loader} height={80} alt="Spinner" />
            </center>
        </div>
    );
    return component ? ReactDOM.createPortal(mask, component) : mask;
};

export default LoadMask;