import React, { useState, useEffect,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuditors } from "../../store/actions/commonActions";
import { getCollectionTypes } from "../../store/actions/people";
import Administrators from "./administrators";
import BulkUploads from "./bulkUploads";
import Resources from "./resources";
import { useTranslation } from "react-i18next";
import { setScreenIdHandler } from "../../store/actions/userProfile";


const People = ({ activeMenu },props) => {
  const screen = 2;
  const { t, i18n } = useTranslation();
  
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const userroleDetails = useSelector((state)=> state.dashboardReducer.userrolepermssioninfo)
 
  const [rolePepplePage,setRolePepplePage]=useState({})
 

  function getObjectByScreenId(screenId) {
    for (let i = 0; i < userroleDetails?.rolePermissionsInfo.length; i++) {
        if (userroleDetails?.rolePermissionsInfo[i].screensInfo.screenId === screenId) {
            return userroleDetails?.rolePermissionsInfo[i];
        }
    }
    return null; // Return null if not found
  }

  useEffect(()=>{
    setRolePepplePage(getObjectByScreenId(screen))
  },[])

  console.log(rolePepplePage,screen,"1234")


  const tabsObj = useMemo(()=>( {
    resources: {
      title: t("resource"),
      comp: Resources,
    },
    bulkUploads: {
      title:t("bulkUploads"),
      comp: BulkUploads,
    },
    administrator: {
      title: t("resourceadmin"),
      comp: Administrators,
    },
  }),[]);
  const [activeScreen, setActiveScreen] = useState("resources");
  // console.log("components", {activeMenu});
  const Comp = tabsObj?.[activeScreen]?.comp;
  const dispatch = useDispatch();
  useEffect(() => {
    if (authContext) {
      const { CountryCode, CompanyID } = authContext;
      const AudParams = {
        Countryid: CountryCode,
        Companyid: CompanyID,
        page: 1,
        start: 0,
        limit: 25,
      };
      dispatch(getAuditors(AudParams, authContext));
      const ColParams = {
        CountryId: CountryCode,
        CompanyId: CompanyID,
        page: 1,
        start: 0,
        limit: 25,
      };
      dispatch(getCollectionTypes(ColParams, authContext));
    }
  }, [authContext]);
  return (
    <>
      <Comp
        activeScreen={activeScreen}
        setActiveScreen={setActiveScreen}
        tabsObj={tabsObj}
        screen={screen}
        rolePepplePage={rolePepplePage}
      />
    </>
  );
};

export default People;
