const EcollectionRow = ({ eCollVersion }) => {

    return (
        <div
            className='nielsen-card'
            style={{
                padding: '10px',
                display: "flex",
                alignItems: "center"
            }}
        >
            <h3 style={{ margin: 0 }}>
                ECOLLECTION VERSION&nbsp;:&nbsp;
            </h3>
            <div>{eCollVersion ?? ''}</div>
        </div>
    )
}

export default EcollectionRow