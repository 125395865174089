import { CustomColumnMenuFilter } from "../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    {
        title:  "countryname",
        field: "Country",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Year",
        field: "Year",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "Week",
        field: "Week",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:  "Auditor Id",
        field: "AuditorID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        // format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "#Coded",
        field: "Coded",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "ScannedCount",
        field: "ScannedCount",
        show: true,
        columnMenu: "CustomColumnMenuFilter", 
        filter: "numeric",
        width: 130
        
    },
    {
        title: "%scantofindratio",
        field: "ScantoFindRatio",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
        
    },
    {
        title: "#newitemsscanned",
        field: "NewItemsScanned",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
        
    },
    {
        title: "#storesassigned",
        field: "StoresAssigned",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "#AssignedStoresNotVisited",
        field: "AssignedStoresNotVisited",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "%notvisited",
        field: "NotVisitedPer",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "#OfStoresCollected",
        field: "StoresCollected",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "%OfStoresReceived",
        field: "NoStoresReceivedPer",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    

];
export default columns;
