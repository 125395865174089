import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useState, useCallback } from "react";
import SearchFilterWindow from "./SearchFilterWindow";
import bulkApi from "./mockRes.json";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  isColumnActive,
} from "../../ui/grid/CustomColumnMenu";
import { columnMenuConfig, columns } from "./column";
import { Pager } from "@progress/kendo-react-data-tools";
import {
  getBulkPlanUpload,
  planDownloadFile,
} from "../../../store/actions/workOrder/bulkUpload";
import LoadMask from "../../../utils/LoadMask";
import { useSelector, useDispatch } from "react-redux";
import { updateUserPreferenceAction } from "../../../store/actions/commonActions";
import { showToast } from "../../../utils/Notification";
import { useLocalization } from "@progress/kendo-react-intl";
import UploadWindow from "./UploadWindow";
import AppURL from "../../../utils/AppURL";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { searchUserPrefrence } from "../../../store/actions/dashboard";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";
import { MyPager } from "../../generalHooks";
import { capsuleToParams } from "../../../utils/Utility";

const GRID_NAME = "WO_UPLOAD_GRID";
const BatchDetails = bulkApi.Response.BatchDetails;
const createDataState = (data, dataState) => {
  return {
    result: {
      data,
      total: dataState.take,
    },
    dataState: dataState,
  };
};
const initialState = createDataState([], {
  take: 25,
  skip: 0,
});

const setOrderIndex = (columns) => {
  return columns.map((column, i) => ({ ...column, orderIndex: i }))
}

const initialColumnsState = setOrderIndex(columns);

const BulkUploads = ({ setActiveScreen, activeScreen, tabsObj, visible , screen, roleWorkOrderPage }) => {

  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [stateColumns, setStateColumns] = useState(initialColumnsState);
  const [data, setData] = useState({ data: [], total: 0 });
  const serverColumns = useSelector(
    (state) => state.commonReducer.gridUserPrefrence
  );
  const dispatch = useDispatch();
  const { handleAuthContext } = useLogout();
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [showLoader, setShowLoader] = useState(null);
  const [searchTerm, setSearthTerm] = useState("");

  const [uploadWindow, setUploadWindow] = useState(false);
  const [selectedDataUpload, setSelectedDataUpload] = useState(null);
  const { t } = useTranslation();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [filter, setFilter] = useState(null);
  const [capsulesFilter, setCapsulesFilter] = useState([]);
  const [sort, setSort] = useState([
    {
      field: "BatchId",
      dir: "asc",
    },
  ]);

  const localizationService = useLocalization();

  useEffect(() => {
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStateColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);

  const dataStateChange = (event) => {
    setDataState(event.dataState);
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || initialColumnsState);
  };

  const downloadFile = async (dataItem) => {
    const params = { BatchId: dataItem?.BatchId };
    setShowLoader({
      className: ".workOrders_grid_bu",
      msg: "loading",
    });
    const res = await planDownloadFile(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.headers.get("content-type") === "application/json") {
      const jsonRes = await res.json();
      showToast("error", `${jsonRes?.Output?.Status}`);
    } else if (res?.status === 200) {
      const url = `${AppURL.planDownloaderfile}?BatchId=${params?.["BatchId"]}`;
      window.location.assign(url);
    }
    setShowLoader(null);
  };

  const GridCell = useCallback(({ dataItem, field }) => {
    const dataValue = dataItem?.[field];
    let style = {};
    if (["BatchId", "WorkOrderId", "TotalCount", "SuccessCount"].includes(field)) {
      style = {
        textAlign: 'right'
      }
    } else {
      style = {
        textAlign: 'left'
      }
    }
    switch (field) {
      case "FailureCount":
        return (
          <td style={{ textAlign: "center" }} title="Upload File">
            {dataValue > 0 ? (
              <>
                <Button
                  title={t("downloadfile")}
                  onClick={() => downloadFile(dataItem)}
                  style={{ border: "1px solid #000" }}
                >
                  {dataValue}
                </Button>
              </>
            ) : (
              dataValue
            )}
          </td>
        );

      case "uploadFile":
        return (
          <td style={{ textAlign: "center" }} title="Upload File">
            <Button
              className="k-icon k-i-upload k-ghost"
              icon="upload"
              style={{
                background: "transparent",
                border: "none"
              }}
              onClick={() => handleUploadWindow(dataItem)}
              disabled={roleWorkOrderPage?.screensInfo?.screenId == screen ? ( roleWorkOrderPage?.createFlag == 'N' ? true : false ) : true }
            />
          </td>
        );
      case "UploadedOn": {
        return (
          <td
            className="col-xs-12 col-sm-7 example-col alertGridRow"
            style={{ textAlign: "left" }}
            title={dataValue}
          >
            {moment(dataValue).format("MM/DD/YYYY HH:mm:ss")}
          </td>
        );
      }
      default:
        return (
          <td
            className="col-xs-12 col-sm-7 example-col alertGridRow"
            style={style}
          >
            {dataValue?.toString()}
          </td>
        );
    }
  }, []);

  const handleRefresh = () => {
    if (refreshGrid) {
      refreshGrid();
    }
  };

  const handleSaveViewClick = async () => {
    const Json_Object = JSON.stringify(stateColumns);
    const params = {
      UserPrefReq: {
        Email: authContext?.SpocMail,
        Company_ID: authContext?.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: authContext?.CountryCode,
        Json_Object: Json_Object,
      },
    };
    setShowLoader({
      className: ".workOrders_grid_bu",
      msg: "loading",
    });
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast(
        "success",
        localizationService.toLanguageString(
          "success",
          `${res?.Response?.Message}`
        )
      );
    } else {
      showToast(
        "error",
        localizationService.toLanguageString(
          "dataNotFound",
          `${res?.Response?.Message}`
        )
      );
    }
    setShowLoader(null);
  };

  const handleDefaultViewClick = () => {
    onColumnsSubmit();
  };

  const refreshGrid = () => {
    setShouldRefresh(true);
  };

  const fetchPlanUpload = async (params) => {
    setShowLoader({
      className: ".workOrders_grid_bu",
      msg: "loading",
    });

    const res = await getBulkPlanUpload(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response) {
      const newResult = {
        data: res.Response?.BatchDetails || [],
        total:
          res?.Response.TotalRecordCount ||
          res.Response?.BatchDetails?.length ||
          dataState.take,
      };
      setData(newResult);
    } else {
      console.log("search plan api error", res);
    }
    setShowLoader(null);
  };

  useEffect(() => {
    const params = {
      page: Math.floor(dataState.skip / dataState.take) + 1,
      StartCount: dataState.skip,
      MaxCount: dataState.take,
    };
    fetchPlanUpload(params);
  }, [authContext]);

  const onPageChange = async (e) => {
    const dataState = e.page;
    setDataState(dataState);
    setFilter(null);
    const newSearchParams = capsuleToParams(capsulesFilter);
    const params = {
      ...newSearchParams,
      page: Math.floor(dataState.skip / dataState.take) + 1,
      StartCount: dataState.skip,
      MaxCount: dataState.take,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    fetchPlanUpload(params)
  };

  const handleUploadWindow = (dataItem) => {
    setSelectedDataUpload(dataItem);
    setUploadWindow(!uploadWindow);
  };

  useEffect(() => {
    if (data) {
      setResult({
        data: data?.data.map((d) => {
          if (!isNaN(d?.BatchId)) {
            d.BatchId = parseInt(d.BatchId);
          }
          if (!isNaN(d?.WorkOrderId)) {
            d.WorkOrderId = parseInt(d.WorkOrderId);
          }
          if (!isNaN(d?.TotalCount)) {
            d.TotalCount = parseInt(d.TotalCount);
          }
          if (!isNaN(d?.SuccessCount)) {
            d.SuccessCount = parseInt(d.SuccessCount);
          }
          if (!isNaN(d?.FailureCount)) {
            d.FailureCount = parseInt(d.FailureCount);
          }
          const UploadedOn = new Date(d?.UploadedOn);
          if (UploadedOn !== "Invalid Date") {
            d.UploadedOn = UploadedOn;
          }
          return d;
        }),
        ...data,
      });
    }
  }, [data]);

  const CreatePager = useCallback((props) => (
    <MyPager
      {...props}
      onSaveViewClick={handleSaveViewClick}
      onDefaultViewClick={handleDefaultViewClick}
    >
      <div
        className="niq-refresh-button"
        onClick={handleRefresh}
      >
        {/* REFRESH ICON.SVG */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="rgba(0,0,0,.9)"
        >
          <path d="M463.5 224h8.5c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2S461.9 48.1 455 55l-41.6 41.6c-87.6-86.5-228.7-86.2-315.8 1-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2S334.3 224 344 224h119.5z"></path>
        </svg>
      </div>
      <Pager style={{ flex: 1 }} {...props} />
    </MyPager>
  ), [stateColumns]);

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {uploadWindow && (
        <UploadWindow
          selectedDataUpload={selectedDataUpload}
          setUploadWindow={setUploadWindow}
        />
      )}
      <SearchFilterWindow
        shouldRefresh={shouldRefresh}
        setShouldRefresh={setShouldRefresh}
        dataState={dataState}
        setDataState={setDataState}
        searchTerm={searchTerm}
        setSearthTerm={setSearthTerm}
        capsulesFilter={capsulesFilter}
        setCapsulesFilter={setCapsulesFilter}
        fetchPlanUpload={fetchPlanUpload}
      />
      <div className="catalog" style={{ padding: 15, flexGrow: 1, minHeight: '60%' }}>
        <Grid
          className="workOrders_grid_bu"
          {...result}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            console.log("sort", { e });
            setResult((result) => {
              const { data, total } = result;
              return {
                data: orderBy(data || [], e.sort),
                total,
              };
            });
            setSort(e.sort);
          }}
          filter={filter}
          onFilterChange={(e) => {
            setResult((result) => {
              const { total } = result;
              return {
                data: filterBy(data?.data || [], e.filter),
                total,
              };
            });
            setFilter(e.filter);
          }}
          resizable
          reorderable
          onColumnReorder={({ columns }) => {
            const columnOrder = columns.reduce((columnsObj, column) => {
              columnsObj[column.field] = column.orderIndex;
              return columnsObj;
            }, {})
            const newColumns = stateColumns.map((column) => {
              return { ...column, orderIndex: columnOrder[column.field] }
            });
            setStateColumns(newColumns);
          }}

          pageable={{
            pageSizes: [10, 25, 50, 100, 250, 500, 1000],
          }}
          onPageChange={onPageChange}
          // total={parseInt(bulkUploadList?.TotalRecordCount)}
          navigatable={true}
          pager={CreatePager}
        >
          {stateColumns.map((column, idx) => {
            const menu = columnMenuConfig[column?.columnMenu];
            return (
              column.show && (
                <GridColumn
                  key={column?.orderIndex}
                  field={column.field}
                  // title={column.title}
                  title={t(column.title)}
                  cell={GridCell}
                  format={column?.format}
                  filter={column?.filter}
                  orderIndex={column?.orderIndex}
                  {...(menu && {
                    columnMenu: (props) => {
                      return menu?.({
                        ...props,
                        columns: stateColumns,
                        onColumnsSubmit,
                        data: data?.data,
                      });
                    },
                  })}
                  headerClassName={
                    isColumnActive(column.field, { ...dataState, filter })
                      ? "activeFiltered"
                      : ""
                  }
                />
              )
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default BulkUploads;
