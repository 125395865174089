import * as types from "../../../actions/types";

const initialState = {
  fetchProgramSearchDetails: null,
  fetchProgramDetails: null,
  programReasonData : null,
  associateData: [],
  freelancerData: [],
  evaluatedData: null,
  fetchAssociateData : null,
  fetchFreelanceData : null,
  searchProgramData :null,
  assgnPogramData : null,
  id: ""
};

const programSearchDetails = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.GET_PROGRAM_SEARCH_DATA:
      newState = {
        ...state,
        fetchProgramSearchDetails: action.payload,
      };
      break;
    case types.GET_PROGRAM_REASON_ASSIGN_DATA:
       newState = {
         ...state,
         programReasonData: action.payload,
       };
      break;
    case types.GET_PROGRAM_ASSOCIATE_DATA:
          newState = {
            ...state,
            fetchAssociateData : action.payload
          }
        break;
      case types.GET_PROGRAM_FREELANCERS_DATA:
           newState = {
             ...state,
             fetchFreelanceData : action.payload
          }
        break;
    case types.GET_PROGRAM_ASSOCIATE_ASSIGN_DATA: 
          newState = {
            ...state,
            associateData: action.payload
          }
          break;
      case types.GET_PROGRAM_FREELANCE_ASSIGN_DATA: 
          newState = {
            ...state,
            freelancerData: action.payload
          }
          break;
       case types.GET_PROGRAM_EVALUATED_DATA:
          newState = {
            ...state,
            evaluatedData : action.payload
          }
          break;
      case types.GET_PROGRAM_EVALUVATED_ID:
          newState = {
            ...state,
            id : action.payload
          }
          break;
      case types.SET_PROGRAM_SEARCH_DATA:
          newState = {
            ...state,
            searchProgramData : action.payload
            }
            break;
      case types.GET_ASSIGN_PROGRAM_DATA:
            newState = {
              ...state,
              assgnPogramData : action.payload
            }
        break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export default programSearchDetails;
