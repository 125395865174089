import React from "react";
import ApiRegister from "./ApiRegister";
import { apiData } from "./data";
import { useTranslation } from "react-i18next";
import Button from "@progress/kendo-react-buttons/dist/npm/Button";
import { useSelector } from "react-redux";
import { getApiRegistrationClick } from "../../store/actions/api";
import LoadMask from "../../utils/LoadMask";
import useLogout, { checkInvalidSession } from "../../utils/useLogout";

const Api = () => {
  const { t, i18n } = useTranslation();
  const {handleAuthContext} = useLogout();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [showLoader, setShowLoader] = React.useState(null);
  const [buttonText, setButtonText] = React.useState(false);

  const callApi = async () => {
    const params = {
      CompanyId: authContext?.CompanyID,
      User: authContext?.SpocMail,
    };
    setShowLoader({
      className: "",
      msg: t("_loading", "Loading"),
    });
    const res = await getApiRegistrationClick(params, authContext);
    console.log("apires", res);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    setShowLoader(null);
    if (res?.Response?.StatusCode === "200") {
      setButtonText(true);
    }
  };

  return (
    <React.Fragment>
      <p
        
          style={{
            marginLeft: 15,
            marginBottom:10,
            fontFamily: "knockout",
            fontSize: 31,                      
          }}
        >
          API(s)
        
      </p>
      {showLoader && <LoadMask {...showLoader} />}
      <div className="reports-scroll">
        {buttonText ? (
          <p
            style={{
              fontSize: 20,
              fontFamily: "knockout",              
              marginBottom: 30,
              marginLeft: 10,
              marginTop:0
            }}
          >
            Registration in progress
          </p>
        ) : (
          <Button
            primary
            style={{ marginBottom: 30, marginLeft: 12 }}
            onClick={callApi}
          >
            Register
          </Button>
        )}
        <ul className="accordion" style={{ flexWrap: "wrap" }}>
          {apiData.map(({ heading, content }) => {
            return <ApiRegister heading={t(heading)} content={content} />;
          })}
        </ul>
      </div>
    </React.Fragment>
  );
};
export default Api;
