import React, { useCallback, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

import BasicInfo from "./BasicInfo";
import Skills from "./Skills";
import UserDevice from "./UserDevice";
import { updateAuditor } from "../../../../../store/actions/people/resources";
import { useSelector } from "react-redux";
import LoadMask from "../../../../../utils/LoadMask";
import { showToast } from "../../../../../utils/Notification";
import moment from "moment";
import { optionalEmailValidator } from "../../../../form/validator";
import { useTranslation } from "react-i18next";

const resourceTabs = {
  basicInfo: {
    title: "Basic Information",
    comp: BasicInfo,
  },
  skills: {
    title: "Skills",
    comp: Skills,
  },
  userDevice: {
    title: "User Device",
    comp: UserDevice,
  },
};
const nullStringify = (obj) => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      return value === null ? "" : value;
    })
  );
};

const getInitialResourceDetails = (initialResourceDetails, languages) => {
  const langArr = initialResourceDetails?.LANGUAGE?.split("|");
  const LANGUAGE = languages.filter((lang) => {
    return langArr?.some((l) => l === lang?.LanguageName);
  });
  const res = {
    ...initialResourceDetails,
    LANGUAGE,
  };
  return nullStringify(res);
};

const EditResource = ({
  selectedResourceDetails: initialResourceDetails,
  setShouldRefresh,
  handleEditMode,
  resourceStatus,
  handleFetchResourceDetails,
  newAuditorList,
  screen,
  rolePepplePage
}) => {
  const { t, i18n } = useTranslation();
  const languages = useSelector((state) => state.commonReducer.languages);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [showLoader, setShowLoader] = useState(null);

  const [activeTab, setActiveTab] = useState("basicInfo");
  // const Comp = resourceTabs?.[activeTab]?.comp;
  const [initialFormState, setFormState] = useState(
    getInitialResourceDetails(initialResourceDetails, languages)
  ); 

  const handleEditSubmit = async (form) => {
    
  
    const checkResourceStatus = newAuditorList?.SearchResponse?.find((el) => el.RESOURCE_ID === initialResourceDetails?.RESOURCE_ID)
    
    if(initialResourceDetails?.RESOURCE_STATUS === 'INACTIVE' || checkResourceStatus?.RESOURCE_STATUS === 'INACTIVE'){
      showToast('error', t("resourceinactive_msglbl"));
      return false;
    }

    let DATE_OF_BIRTH
    if(form.values?.DATE_OF_BIRTH){
      DATE_OF_BIRTH = moment(form.values?.DATE_OF_BIRTH).format('MM/DD/YYYY') || "";
    }

    if (form.isValid) {
      // const oldparams = {
      //   AuditorEditReq: {
      //     COUNTRY: "484",
      //     SPOC_MAILID: authContext?.SpocMail, // "smaddi1234@mailinator.com",
      //     RESOURCE_ID: "1",
      //     RESOURCE_STATUS: "ACTIVE",
      //     FIRST_NAME: "shivani",
      //     LAST_NAME: "abc",
      //     MIDDLE_NAME: "sdads",
      //     GENDER: "FEMALE",
      //     STATE: "",
      //     CITY: "",
      //     CONTACT_NUMBER: "",
      //     ADDRESS: "",
      //     ZIP_CODE: "",
      //     EMAIL: "",
      //     LANGUAGE: "English",
      //     SKILLS: "NSO|ecollection",
      //     DEVICES: [
      //       {
      //         DEVICE_ID: "1",
      //         DEVICE_TYPE: "handheld",
      //         DEVICE_STATUS: "active",
      //         DEVICE_COMMENTS:
      //           "it is in working condition and used for uploading plan.",
      //       },
      //     ],
      //     CUSTOM1: "",
      //     CUSTOM2: "",
      //     CUSTOM3: "",
      //     CUSTOM4: "",
      //     CUSTOM5: "",
      //   },
      // };
      const {
        FIRST_NAME,
        LAST_NAME,
        MIDDLE_NAME,
        CONTACT_NUMBER,
        GENDER,
        STATE,
        CITY,
        ADDRESS,
        ZIP_CODE,
        EMAIL,
        CUSTOM1,
        CUSTOM2,
        CUSTOM3,
        CUSTOM4,
        CUSTOM5,
        LANGUAGE,
        OtherLang,
      } = form.values;
      const { COUNTRY, RESOURCE_ID, RESOURCE_STATUS, SKILLS, DEVICES } =
        initialFormState;
      console.log("handleSubmit", { initialFormState });
      if (DEVICES?.some((d) => !d.DEVICE_ID)) {
        showToast("error", "Please enter a device details");
        return;
      }
      let newLanguage = LANGUAGE?.reduce((acc, cur, i) => {
        const { LanguageName } = cur;
        if (LanguageName === "Other") {
          return acc;
        }
        const seperator = i ? "|" : "";
        return `${acc}${seperator}${LanguageName}`;
      }, "");
      if (OtherLang) {
        newLanguage = `${newLanguage}|${OtherLang}`;
      }

      const SPOC_MAILID = authContext?.SpocMail;
      const params = {
        AuditorEditReq: {
          COUNTRY,
          SPOC_MAILID,
          RESOURCE_ID,
          RESOURCE_STATUS,
          FIRST_NAME,
          LAST_NAME,
          MIDDLE_NAME,
          DATE_OF_BIRTH: DATE_OF_BIRTH,
          GENDER,
          STATE,
          CITY,
          CONTACT_NUMBER,
          ADDRESS,
          ZIP_CODE,
          EMAIL,
          LANGUAGE: newLanguage,
          SKILLS,
          DEVICES,
          CUSTOM1,
          CUSTOM2,
          CUSTOM3,
          CUSTOM4,
          CUSTOM5,
        },
      };
      console.log("handleEdit", { params });
      // return;
      setShowLoader({
        className: ".resourceDetails_container",
        msg: "loading",
      });

      const res = await updateAuditor(params, authContext);

      setShowLoader(null);
      if (res?.root?.status === "Success") {
        showToast("success", res?.root?.msg);
        setShouldRefresh(true);
        handleEditMode();
        handleFetchResourceDetails(initialFormState);
      } else {
        showToast("error", res?.root?.msg);
      }
    }
  };

  const childProp = {
    resourceTabs: resourceTabs,
    activeTab: activeTab,
    setActiveTab: setActiveTab,
    selectedResourceDetails: initialResourceDetails,
    initialFormState,
    setFormState,
  };
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}

      <Form
        initialValues={{
          ...initialFormState,
          DATE_OF_BIRTH: (initialFormState.DATE_OF_BIRTH) ? new Date(initialFormState.DATE_OF_BIRTH) : ''
        }}
        onSubmitClick={handleEditSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex", marginBottom: "1.5em" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    marginLeft: "1em",
                    color: resourceStatus ? "#008A28" : "#AA213F",
                  }}
                >
                  {resourceStatus ? "ACTIVE" : "INACTIVE"}
                </span>
              </div>
              <div style={{ marginLeft: "auto", display: "flex", gap: ".3em" }}>
                <>
                  <Button
                    className="k-button k-secondary"
                    type="submit"
                    icon="save"
                    style={{ border: "1px solid" }}
                    disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
                  >
                    Save Changes
                  </Button>
                  <Button
                    type="button"
                    primary
                    icon="clear"
                    style={{ border: "1px solid" }}
                    onClick={handleEditMode}
                  />
                </>
              </div>
            </div>
            <div
              style={{
                height: "calc(100vh - 250px)",
                padding: "1em",
                display: "flex",
                flexDirection: "column",
                paddingBottom: 0,
              }}
            >
              <BasicInfo {...childProp} />
              <Skills {...childProp} />
              <UserDevice {...childProp} />
              {/* <Comp
            resourceTabs={resourceTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            selectedResourceDetails={selectedResourceDetails}
          /> */}
            </div>
          </FormElement>
        )}
      />
    </>
  );
};

export default EditResource;
