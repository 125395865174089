import { SvgIcon } from '@progress/kendo-react-common';

const WorkOrdersIcon = (props) => {
    return (
        <SvgIcon style={props?.style} width={props?.width} viewBox="2 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.61667 23.282H6.33333C6.23167 23.282 6.13416 23.3224 6.06228 23.3943C5.99039 23.4662 5.95 23.5637 5.95 23.6654V24.9987C5.95 25.2772 6.06062 25.5442 6.25754 25.7412C6.45445 25.9381 6.72152 26.0487 7 26.0487H25C25.2785 26.0487 25.5455 25.9381 25.7425 25.7412C25.9394 25.5442 26.05 25.2772 26.05 24.9987V23.6654C26.05 23.5637 26.0096 23.4662 25.9377 23.3943C25.8658 23.3224 25.7683 23.282 25.6667 23.282H25.3833V12.332C25.3833 12.0536 25.2727 11.7865 25.0758 11.5896C24.8789 11.3927 24.6118 11.282 24.3333 11.282H23H22.95V11.332V11.9987V12.0487H23H24.3333C24.4085 12.0487 24.4805 12.0785 24.5337 12.1317C24.5868 12.1848 24.6167 12.2569 24.6167 12.332V23.282H7.38333V12.332C7.38333 12.2569 7.41318 12.1848 7.46632 12.1317C7.51946 12.0785 7.59152 12.0487 7.66667 12.0487H9H9.05V11.9987V11.332V11.282H9H7.66667C7.38819 11.282 7.12112 11.3927 6.9242 11.5896C6.72729 11.7865 6.61667 12.0536 6.61667 12.332V23.282ZM25.2833 24.9987C25.2833 25.0738 25.2535 25.1459 25.2003 25.199C25.1472 25.2522 25.0751 25.282 25 25.282H7C6.92486 25.282 6.85279 25.2522 6.79965 25.199C6.74652 25.1459 6.71667 25.0738 6.71667 24.9987V24.0487H14.2833V24.332C14.2833 24.4337 14.3237 24.5312 14.3956 24.6031C14.4675 24.675 14.565 24.7154 14.6667 24.7154H17.3333C17.435 24.7154 17.5325 24.675 17.6044 24.6031C17.6763 24.5312 17.7167 24.4337 17.7167 24.332V24.0487H25.2833V24.9987Z" fill={props?.color ?? "#4F4F4F"} stroke={props?.color ?? "#4F4F4F"} strokeWidth="0.1" />
            <path d="M10.05 6.71667V24V24.05H10H9.33337H9.28337V24V6.33333C9.28337 6.23167 9.32376 6.13416 9.39565 6.06228C9.46754 5.99039 9.56504 5.95 9.66671 5.95L19.6627 5.95L19.6627 5.95V6C19.7096 5.99996 19.756 6.00982 19.7989 6.02895C19.8417 6.04808 19.8801 6.07604 19.9114 6.111L10.05 6.71667ZM10.05 6.71667H18.95V9.66667C18.95 9.76833 18.9904 9.86584 19.0623 9.93772C19.1342 10.0096 19.2317 10.05 19.3334 10.05H21.95V24V24.05H22H22.6667H22.7167V24V9.32239V9.32233H22.6667L10.05 6.71667ZM19.7167 6.96901L21.7843 9.28333H19.7167V6.96901Z" fill={props?.color ?? "#4F4F4F"} stroke={props?.color ?? "#4F4F4F"} strokeWidth="0.1" />
            <path d="M10.618 21.332C10.618 21.4337 10.6584 21.5312 10.7302 21.6031C10.8021 21.675 10.8996 21.7154 11.0013 21.7154H21.0013C21.103 21.7154 21.2005 21.675 21.2724 21.6031C21.3442 21.5312 21.3846 21.4337 21.3846 21.332V12.6654C21.3846 12.5637 21.3442 12.4662 21.2724 12.3943C21.2005 12.3224 21.103 12.282 21.0013 12.282H11.0013C10.8996 12.282 10.8021 12.3224 10.7302 12.3943C10.6584 12.4662 10.618 12.5637 10.618 12.6654V21.332ZM11.3846 20.9487V14.382H12.618V20.9487H11.3846ZM13.3846 20.9487V14.382H20.618V20.9487H13.3846ZM20.618 13.6154H13.3846V13.0487H20.618V13.6154ZM11.3846 13.0487H12.618V13.6154H11.3846V13.0487Z" fill={props?.color ?? "#4F4F4F"} stroke={props?.color ?? "#4F4F4F"} strokeWidth="0.1" />
            <path d="M16.0513 9.33334V9.28334H16.0013H10.668H10.618V9.33334V10V10.05H10.668H16.0013H16.0513V10V9.33334Z" fill={props?.color ?? "#4F4F4F"} stroke={props?.color ?? "#4F4F4F"} strokeWidth="0.1" />
            <path d="M20.0513 15.3327V15.2827H20.0013H14.0013H13.9513V15.3327V15.9993V16.0493H14.0013H20.0013H20.0513V15.9993V15.3327Z" fill={props?.color ?? "#4F4F4F"} stroke={props?.color ?? "#4F4F4F"} strokeWidth="0.1" />
            <path d="M20.0513 16.6673V16.6173H20.0013H14.0013H13.9513V16.6673V17.334V17.384H14.0013H20.0013H20.0513V17.334V16.6673Z" fill={props?.color ?? "#4F4F4F"} stroke={props?.color ?? "#4F4F4F"} strokeWidth="0.1" />
            <path d="M20.0513 17.9987V17.9487H20.0013H14.0013H13.9513V17.9987V18.6654V18.7154H14.0013H20.0013H20.0513V18.6654V17.9987Z" fill={props?.color ?? "#4F4F4F"} stroke={props?.color ?? "#4F4F4F"} strokeWidth="0.1" />
        </SvgIcon>
    )
}

export default WorkOrdersIcon