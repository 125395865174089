import * as types from "../../../actions/types";

const initialState = {
    fetchCourseSearchDetails : null,
    fetchAssociateData : null,
    fetchFreelanceData: null,
    fetchOjtData : null,
    associateData : [],
    freelancerData: [],
    finalAssignMentData: null,
    getEvaluatedData : null,
    reasonData: null,
    id: "",
    tabSet : 0
}

const courseSearchDetails = (state = initialState, action) => {
    let newState;
    switch (action.type) {
      case types.GET_COURSE_SEARCH_DATA:
        newState = {
          ...state,
          fetchCourseSearchDetails: action.payload,
        };
        break;
      case types.GET_ASSOCIATE_DATA:
          newState = {
            ...state,
            fetchAssociateData : action.payload
          }
        break;
      case types.GET_FREELANCERS_DATA:
           newState = {
             ...state,
             fetchFreelanceData : action.payload
          }
        break;
      case types.GET_OJTDATA_DATA: 
          newState = {
            ...state,
            fetchOjtData: action.payload
          }
          break;
      case types.GET_ASSOCIATE_ASSIGN_DATA: 
          newState = {
            ...state,
            associateData: action.payload
          }
          break;
      case types.GET_FREELANCE_ASSIGN_DATA: 
          newState = {
            ...state,
            freelancerData: action.payload
          }
          break;
      case types.GET_ASSIGN_SKILL_DATA:
        newState = {
          ...state,
          finalAssignMentData : action.payload
        }
        break;
      case types.GET_EVALUATED_DATA:
          newState = {
            ...state,
            getEvaluatedData : action.payload
          }
          break;
      case types.GET_REASON_ASSIGN_DATA:
          newState = {
            ...state,
            reasonData : action.payload
          }
          break;
       case types.GET_EVALUVATED_ID:
          newState = {
            ...state,
            id : action.payload
          }
          break;
        case types.SET_TAB:
            newState = {
              ...state,
              tabSet : action.payload
            }
          break;
      default:
        newState = state;
        break;
    }
    return newState;
}

export default courseSearchDetails;