import * as types from "../../actions/types";

const initialState = {
  chatBoxData: [],
  newMessage: null,
  chatBoxMessages: null,
};

const ChatReudcer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.SET_AUDITOR_FOR_CHAT:
      const isExist = state.chatBoxData?.findIndex(
        (au) => au.data.AuditorID === action.payload?.data?.AuditorID
      );
      if (isExist > -1) {
        newState = {
          ...state,
          chatBoxData: [...state.chatBoxData],
        };
      } else {
        newState = {
          ...state,
          chatBoxData: [action.payload, ...state.chatBoxData],
        };
      }
      break;

    case types.REMOVE_AUDITOR_FOR_CHAT:
      newState = {
        ...state,
        chatBoxData: state.chatBoxData.filter((ch) => {
          return ch.data.AuditorID !== action.payload;
        }),
      };
      break;
    case types.SET_CHAT_INSTANCE:
      newState = {
        ...state,
        conn: action.payload,
      };
      break;
    case types.SET_CHAT_MESSAGE:
      const { AuditorID, message } = action.payload;
      let oldChatBoxMessage = { ...state.chatBoxMessages };
      if (oldChatBoxMessage?.[AuditorID]) {
        oldChatBoxMessage[AuditorID] = [
          ...oldChatBoxMessage[AuditorID],
          message,
        ];
      } else {
        oldChatBoxMessage = {
          ...oldChatBoxMessage,
          [AuditorID]: [message],
        };
      }
      newState = {
        ...state,
        chatBoxMessages: oldChatBoxMessage,
      };
      break;
    case types.INITIATE_CHAT_MESSAGE:
      newState = {
        ...state,
        newMessage: action.payload,
      };
      break;

    default:
      newState = state;
      break;
  }
  return newState;
};

export default ChatReudcer;
