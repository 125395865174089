import React from "react";
import TabHeader from "../TabHeader";
import "./basicInfo.scss";
import { useTranslation } from "react-i18next";

const BasicInfo = ({ selectedResourceDetails, ...props }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div>
        <TabHeader {...props} />
      </div>
      <div className="basicInfo_container">
        <div className="row">
          <div>
            Navigator Id
            <div >
              {selectedResourceDetails?.UNIQUE_ID}
            </div>
          </div>
          <div>
          {t("ResourceId")}
            <div >
              {selectedResourceDetails?.RESOURCE_ID}
            </div>
          </div>
          <div>
            Personal Id
            <div >
              {selectedResourceDetails?.PERSONAL_IDENTIFICATION_TYPE}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            First Name
            <div >
              {selectedResourceDetails?.FIRST_NAME}
            </div>
          </div>
          <div>
            Last Name
            <div >
              {selectedResourceDetails?.LAST_NAME}
            </div>
          </div>
          <div>
            Middle Name
            <div >
              {selectedResourceDetails?.MIDDLE_NAME}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            Date Of Birth
            <div >
              {selectedResourceDetails?.DATE_OF_BIRTH}
            </div>
          </div>
          <div>
            Gender
            <div >
              {selectedResourceDetails?.GENDER}
            </div>
          </div>
          <div>
            Language
            <div>
              {selectedResourceDetails?.LANGUAGE}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            Other
            <div>
              {selectedResourceDetails?.Other}
            </div>
          </div>
          <div>
            Country
            <div>
              {selectedResourceDetails?.COUNTRY_NAME}
            </div>
          </div>
          <div>
            State
            <div>
              {selectedResourceDetails?.STATE}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            City
            <div>
              {selectedResourceDetails?.CITY}
            </div>
          </div>
          <div>
            Address
            <div>
              {selectedResourceDetails?.ADDRESS}
            </div>
          </div>
          <div>
            Postal Code
            <div>
              {selectedResourceDetails?.ZIP_CODE}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
          {t('Email')}
            <div>
              {selectedResourceDetails?.EMAIL}
            </div>
          </div>
          <div>
            Contact Number
            <div>
              {selectedResourceDetails?.CONTACT_NUMBER}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            Custom1
            <div>
              {selectedResourceDetails?.CUSTOM1}
            </div>
          </div>
          <div>
            Custom2
            <div>
              {selectedResourceDetails?.CUSTOM2}
            </div>
          </div>
          <div>
            Custom3
            <div>
              {selectedResourceDetails?.CUSTOM3}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            Custom4
            <div>
              {selectedResourceDetails?.CUSTOM4}
            </div>
          </div>
          <div>
            Custom5
            <div >
              {selectedResourceDetails?.CUSTOM5}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
