import React, { useState, useCallback, useEffect } from "react";
import "./NrpsReport.css";
import { useHistory } from "react-router-dom";
import { Window } from "@progress/kendo-react-dialogs";
import { useLocalization } from "@progress/kendo-react-intl";
import LoadMask from "../../../../utils/LoadMask";
// import { ComboBox } from "@progress/kendo-react-dropdowns";
import TabPanel from "./TabPanel";
// import commonFilter from './common/commonFilter.json'
import { FormComboBox, FormMultiSelect } from "../../../ui/form/form-component";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
// import { inputValidator } from "../../../form/validator";
// import CommonFilters from "./common/CommonFilters";
import { useDispatch, useSelector } from "react-redux";
import { getNrpsReportFilters, postNrpsReportAuditorClick, postNrpsReportFiltersSearch, postNrpsReportKpiClick, postNrpsReportProgressClick } from "../../../../store/actions/reports"
import { inputValidator } from "../../../form/validator";
import { MessageBox } from "../../../../utils/Notification";



const NRPS_Report = ({ showReport }) => {
  const localizationService = useLocalization();
  const history = useHistory();
  const [messageProps, setMessageProps] = useState(null);
  const [commonTabFilterData, setCommonTabFilterData] = useState(tabFilterData)
  const [companyId, setCompanyId] = useState([]);
  const [countaryCode, setCountaryCode] = useState([]);
  const [cbCommSearch, setCbCommSearch] = useState()
  const [searchCommFiltData, setSearchCommFiltData] = useState(false)
  const [yearState, setYearState] = useState("");
  const [weekState, setWeekState] = useState("");
  const [storeIdState, setStoreIdState] = useState("");
  const [storeNameState, setStoreNameState] = useState("");
  const [cityState, setCityState] = useState("");
  const [provinceState, setProvinceState] = useState("");
  // const [selectedStoreId, setSelectedStoreId] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);
  // const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState([]);
  const [selectedStoreName, setSelectedStoreName] = useState([]);
  const [selectedWeekData, setSelectedWeekData] = useState([]);
  const [selectedStoreData, setSelectedStoreData] = useState([]);
  // const[commonFilters, setCommonFilters] = useState(commonFilter)
  const [tabPanelSize, setTabPanelSize] = useState('84.50%')
  const [showSidebar, setShowSidebar] = useState(true);
  const [tabFilterResponse, setTabFilterResponse] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [showLoader, setShowLoader] = useState(null);
  // const [success, setSuccess] = useState(false);
  const [formState, setFormState] = useState();

  let spocDetails = JSON.parse(localStorage.getItem(`spocDetails`));
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const dispatch = useDispatch();

  const tabFilterData = useSelector((store) => store?.reports?.nrpsReportFilters)
  const nrpsReportLoading = useSelector((store) => store?.reports?.nrpsReportLoading)

  const planReportFilters = useSelector((store) => store.reports.nrpsReportFiltersSearch)
  console.log("commonTabFilterData", commonTabFilterData)
  console.log("tabFilterData", tabFilterData)

  React.useEffect(() => {
    if (nrpsReportLoading) {
      setShowLoader({
        className: ".alertCustomization",
        msg: "Loading",
      });
    } else {
      setShowLoader(null)
    }
  }, [nrpsReportLoading])

  const callbackfunc = (payload) => {
    console.log("wwww", payload)
    setTabIndex(payload)
    // console.log("tabFilterParamsNRPS",tabFilterParams)
    // debugger;   
  }

  const cb = (payload) => {
    setCbCommSearch(payload)
  }

  console.log("cbCommSearch", cbCommSearch);

  const messagePopup = (msg, button, icon) => {
    const callBackFn = (msg) => {
      setMessageProps(null);
      if (msg === "YES") {
        setShowLoader({
          msg: localizationService.toLanguageString("loading", "Loading"),
        });
        // dispatch({ type: "FORCE_LOGIN", payload: "Y" });
        // dispatch(login(createLoginParams({ ForceLogin: "Y" })));

      }
    };
    setMessageProps({
      title: ("_status", "Status"),
      buttons: button,
      icon: icon,
      message: (`_${msg}`, `${msg}`),
      fn: callBackFn,
    });
  };

  useEffect(() => {
    if (authContext && spocDetails) {
      let CompanyId = spocDetails?.CompanyID
      let countryCode = spocDetails?.CountryCode
      setCompanyId(CompanyId);
      setCountaryCode(countryCode)
    }
  }, [authContext]);

  console.log("companyId", companyId);
  console.log("Countary", countaryCode);

  useEffect(() => {
    setShowLoader(null)
    if (authContext) {
      const params = {
        "vendorId": 282
        // Countryid: "170,188,484,76",
        // Companyid: 377,
        // page: 1,
        // start: 0,
        // limit: 25,
      };
      const params2 = {
        "CollectionType": "ECOLLECTION",
        "ReportName": "NRPS_REPORT",
        "APIName": "NRPSReportFilters",
        "Payload": {
          "vendorId": authContext.CompanyID,
          "countryId": authContext.CountryCode
        }
      }
      dispatch(getNrpsReportFilters(JSON.stringify(params2), authContext))
      // setShowLoader({
      //   className: ".workOrders_grid",
      //   msg: "loading",
      // });
      // dispatch(getNrpsReportFilters(authContext))
    }
  }, [authContext])

  const yearSelect = (event) => {
    setYearState(event.value);
    setFormState(event.value);
    if (event.value) {
      const temp = tabFilterData?.find((obj) => {
        return obj.year === event.value
      })
      setSelectedWeekData(temp?.nrpsweekFilterList)
    }
  };
  console.log("setSelectedWeekData", selectedWeekData)

  // const weekSelect = (event) => {     
  //   setWeekState(event.value); 
  //   setFormState(event.value);      
  //   let selectedWeek = []
  //   if(event.value){    
  //        const temp = selectedWeekData?.find((obj)=>{
  //         if( obj.week == event.value){
  //           selectedWeek.push(obj)
  //         }
  //         return  obj.week === event.value
  //     }) 
  //     console.log("selectedweek",selectedWeek)  
  //     setTabFilterResponse(selectedWeek);          
  //     setSelectedStoreData(temp?.storeFilter);
  //   } 
  // }

  const weekSelect = (event) => {
    setWeekState(event.value.map((item) => {
      return item.week;
    }).toString());
    setFormState(event.value);
    console.log("weekSelect", event)
    const newStoreFilter = [];
    event.value?.forEach((item) => {
      newStoreFilter.push(...item.storeFilter);
    });
    setSelectedStoreData(newStoreFilter);
    setTabFilterResponse(event.value);
  };
  console.log("tabFilterResponse", tabFilterResponse)

  // const selectStoreId = (event) => { 
  //   setStoreIdState(event.value);    
  //    if(event.value){
  //       console.log("storeId eventvalue", event.value)
  //       const temp = selectedStoreData?.find((obj)=>{
  //           return obj.smsId === event.value
  //       })  
  //       console.log("StoreIDTEMP",temp?.storeName)
  //       setSelectedStoreName([temp?.storeName]);
  //       setSelectedCity([temp?.city]);
  //       setSelectedProvince([temp?.province])    
  //     }    
  //   }

  const selectStoreId = (event) => {
    setStoreIdState(event.value.map((item) => {
      return item.smsId;
    }).toString());
    console.log("storeidevent", event)
    const temp = [];
    event.value.forEach((item) => {
      temp.push(item);
    })
    console.log("tempstore", temp)
    setSelectedStoreName(temp);
    setSelectedCity(temp);
    setSelectedProvince(temp);
    // setStoreNameData(temp); 
    // setStoreCityData(temp);
    // setStoreProvinceData(temp);        

  };
  console.log("setSelectedStoreName", selectedStoreName)

  const selectStoreName = (event) => {
    console.log("storeevent", event)
    setStoreNameState(event.value.storeName);
  }
  console.log("setStoreNameState", storeNameState)

  const selectCity = (event) => {
    setCityState(event.value)
  }

  const selectProvince = (event) => {
    setProvinceState(event.value)
  }

  const commonFiltersParams = {
    "vendorId": 282,
    // "vendorId": companyId,
    "country": 380,
    "year": yearState,
    "week": weekState,
    "storeId": storeIdState,
    // "storeId":
    "storeName": storeNameState,
    "city": cityState,
    "province": provinceState,
    "auditorId": "",
    "reportType": "plan",
    "status": "",
    "pageSize": 200,
    "pageNo": 0
  };

  // console.log("formStateeeeee",params)
  // debugger;
  // console.log("tabIndex",tabIndex)  

  const onSearchClick = (form) => {
    console.log("formvalues", form.values)
    if (form.values?.year && form.values?.week) {
      switch (tabIndex) {
        case 0:
          setSearchCommFiltData(!searchCommFiltData)
          if (authContext) {
            const params = {
              "vendorId": 282,
              // "vendorId": companyId,
              "country": 380,
              "year": (form.values?.year) ? (form.values?.year) : "",
              "week": (form.values?.week) ? (form.values?.week.map((item) => item.week).toString()) : "",
              "storeId": (form.values?.storeId) ? (form.values?.storeId.map((e) => e.smsId).toString()) : "",
              // "storeName": (form.values?.storeName) ? (form.values?.storeName) : "",
              "storeName": "",
              // "storeType": (form.values?.storeType) ? (form.values?.storeType) : "",
              "storeType": "",
              // "city": (form.values?.city) ? (form.values?.city) : "",
              "city": "",
              // "province": (form.values?.province) ? (form.values?.province) : "",
              "province": "",
              "auditorId": "",
              "banner": "",
              "collectionType": "",
              "limitDate": "",
              "reportType": "plan",
              "status": "",
              "pageSize": 200,
              "pageNo": 0
            };

            const params2 = {
              "CollectionType": "ECOLLECTION",
              "ReportName": "NRPS_REPORT",
              "APIName": "NRPSStorePlanReport",
              "Payload": {
                "vendorId": 282,
                // "vendorId": companyId,
                "country": 380,
                "year": (form.values?.year) ? (form.values?.year) : "",
                "week": (form.values?.week) ? (form.values?.week.map((item) => item.week).toString()) : "",
                "storeId": (form.values?.storeId) ? (form.values?.storeId.map((e) => e.smsId).toString()) : "",
                // "storeName": (form.values?.storeName) ? (form.values?.storeName) : "",
                "storeName": "",
                // "storeType": (form.values?.storeType) ? (form.values?.storeType) : "",
                "storeType": "",
                // "city": (form.values?.city) ? (form.values?.city) : "",
                "city": "",
                // "province": (form.values?.province) ? (form.values?.province) : "",
                "province": "",
                "auditorId": "",
                "banner": "",
                "collectionType": "",
                "limitDate": "",
                "reportType": "plan",
                "status": "",
                "pageSize": 200,
                "pageNo": 0,
                ...form,
              }
            }


            // dispatch(postNrpsReportFiltersSearch(JSON.stringify(params),authContext))
            // setShowLoader({
            //   msg: localizationService.toLanguageString("loading", "Loading"),
            // });
            // setShowLoader(null) 
            dispatch(postNrpsReportFiltersSearch(JSON.stringify(params2), authContext))
          }
          return
        case 1:
          setSearchCommFiltData(!searchCommFiltData)
          if (authContext) {
            const params = {
              "vendorId": 282,
              // "vendorId": companyId,
              "country": 380,
              "year": (form.values?.year) ? (form.values?.year) : "",
              // "week": (form.values?.week) ? (form.values?.week) : "", 
              "week": (form.values?.week) ? (form.values?.week.map((item) => item.week).toString()) : "",
              "storeId": (form.values?.storeId) ? (form.values?.storeId.map((e) => e.smsId).toString()) : "",
              // "storeId": (form.values?.storeId) ? (form.values?.storeId) : "",
              // "storeId": (form.values?.storeId) ? (form.values?.week.map((item)=>item.storeFilter.map((e)=>e.smsId)).toString()) : "",
              "storeName": (form.values?.storeName) ? (form.values?.storeName) : "",
              "storeType": (form.values?.storeType) ? (form.values?.storeType) : "",
              "city": (form.values?.city) ? (form.values?.city) : "",
              "province": (form.values?.province) ? (form.values?.province) : "",
              "auditorId": "",
              "banner": "",
              "collectionType": "",
              "limitDate": "",
              "reportType": "plan",
              "status": "",
              "pageSize": 200,
              "pageNo": 0
            };
            const params2 = {
              "CollectionType": "ECOLLECTION",
              "ReportName": "NRPS_REPORT",
              "APIName": "NRPSStorePlanReport",
              "Payload": {
                "vendorId": 282,
                // "vendorId": companyId,
                "country": 380,
                "year": (form.values?.year) ? (form.values?.year) : "",
                "week": (form.values?.week) ? (form.values?.week.map((item) => item.week).toString()) : "",
                "storeId": (form.values?.storeId) ? (form.values?.storeId.map((e) => e.smsId).toString()) : "",
                // "storeName": (form.values?.storeName) ? (form.values?.storeName) : "",
                "storeName": "",
                // "storeType": (form.values?.storeType) ? (form.values?.storeType) : "",
                "storeType": "",
                // "city": (form.values?.city) ? (form.values?.city) : "",
                "city": "",
                // "province": (form.values?.province) ? (form.values?.province) : "",
                "province": "",
                "auditorId": "",
                "banner": "",
                "collectionType": "",
                "limitDate": "",
                "reportType": "plan",
                "status": "",
                "pageSize": 200,
                "pageNo": 0,
                ...form,
              }
            }
            // dispatch(postNrpsReportFiltersSearch(JSON.stringify(params),authContext))
            // setShowLoader({
            //   msg: localizationService.toLanguageString("loading", "Loading"),
            // });
            // setShowLoader(null) 
            dispatch(postNrpsReportFiltersSearch(JSON.stringify(params2), authContext))
          }
          return
        case 2:
          if (authContext) {
            const params = {
              "vendorId": 282,
              // "vendorId": companyId,
              "country": 380,
              "year": (form.values?.year) ? (form.values?.year) : "",
              // "week": (form.values?.week) ? (form.values?.week) : "",  
              "week": (form.values?.week) ? (form.values?.week.map((item) => item.week).toString()) : "",
              "storeId": (form.values?.storeId) ? (form.values?.storeId.map((e) => e.smsId).toString()) : "",
              // "storeId": (form.values?.storeId) ? (form.values?.storeId) : "",
              // "storeId": (form.values?.storeId) ? (form.values?.week.map((item)=>item.storeFilter.map((e)=>e.smsId)).toString()) : "",
              "storeName": (form.values?.storeName) ? (form.values?.storeName) : "",
              "storeType": (form.values?.storeType) ? (form.values?.storeType) : "",
              "city": (form.values?.city) ? (form.values?.city) : "",
              "province": (form.values?.province) ? (form.values?.province) : "",
              "auditorId": "",
              "banner": "",
              "collectionType": "",
              "limitDate": "",
              "reportType": "progress",
              "status": "",
              "pageSize": 200,
              "pageNo": 0
            };
            dispatch(postNrpsReportProgressClick(params, authContext))
          }
          return
        case 3:
          if (authContext) {
            const params = {
              "vendorId": 282,
              // "vendorId": companyId,
              "country": 380,
              "year": (form.values?.year) ? (form.values?.year) : "",
              // "week": (form.values?.week) ? (form.values?.week) : "",   
              "week": (form.values?.week) ? (form.values?.week.map((item) => item.week).toString()) : "",
              "storeId": (form.values?.storeId) ? (form.values?.storeId.map((e) => e.smsId).toString()) : "",
              // "storeId": (form.values?.storeId) ? (form.values?.storeId) : "",
              // "storeId": (form.values?.storeId) ? (form.values?.week.map((item)=>item.storeFilter.map((e)=>e.smsId)).toString()) : "",
              "storeName": (form.values?.storeName) ? (form.values?.storeName) : "",
              "storeType": (form.values?.storeType) ? (form.values?.storeType) : "",
              "city": (form.values?.city) ? (form.values?.city) : "",
              "province": (form.values?.province) ? (form.values?.province) : "",
              "auditorId": "",
              "banner": "",
              "collectionType": "",
              "limitDate": "",
              "reportType": "kpi",
              "status": "",
              "pageSize": 200,
              "pageNo": 0
            };
            dispatch(postNrpsReportKpiClick(params, authContext))
          }
          return
        case 4:
          if (authContext) {
            const params = {
              "vendorId": 282,
              // "vendorId": companyId,
              "country": 380,
              "year": (form.values?.year) ? (form.values?.year) : "",
              // "week": (form.values?.week) ? (form.values?.week) : "",    
              "week": (form.values?.week) ? (form.values?.week.map((item) => item.week).toString()) : "",
              "storeId": (form.values?.storeId) ? (form.values?.storeId.map((e) => e.smsId).toString()) : "",
              // "storeId": (form.values?.storeId) ? (form.values?.storeId) : "",
              "storeName": (form.values?.storeName) ? (form.values?.storeName) : "",
              "storeType": (form.values?.storeType) ? (form.values?.storeType) : "",
              "city": (form.values?.city) ? (form.values?.city) : "",
              "province": (form.values?.province) ? (form.values?.province) : "",
              "auditorId": "",
              "banner": "",
              "collectionType": "",
              "limitDate": "",
              "reportType": "auditor",
              "status": "",
              "pageSize": 200,
              "pageNo": 0
            };
            dispatch(postNrpsReportAuditorClick(params, authContext))
          }
          return
        default:
          return null
      }
    } else {
      messagePopup("Please select the value of Year and Week", "OK", "WARNING",);
    }
  }


  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setTabPanelSize(showSidebar ? '100%' : '84.50%')
  };

  const backToReports = () => {
    history.push("/reports");
  }

  const ToggleSideBar = () => {
    return (
      <React.Fragment>
        <div>
          <button className="toggle-btn" onClick={toggleSidebar}>{showSidebar ? <span className="k-icon k-i-close"></span> : <span className="k-icon k-i-menu"></span>}</button>&nbsp;&nbsp;NRPS REPORT
          <button className="back-to-reports-btn" onClick={backToReports}><span className="k-icon k-i-arrow-left"></span>&nbsp;REPORTS</button>
        </div>
      </React.Fragment>
    )
  }



  return (
    <React.Fragment>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}
      <div className="title-nrps">
        <Window title={<ToggleSideBar />} stage={"FULLSCREEN"} onClose={showReport}>
          <div className="sidebar">
            <div>
              <div className="row example-wrapper main-page" >
                <div className="col-xs-12 col-sm-6 offset-sm-3 example-col" style={{ maxWidth: '15.5%' }}>

                  {
                    (showSidebar) && <div className="card-sidebar">

                      <div className="card-block">
                        <Form
                          //   initialValues={initialData}
                          //   ref={searchFormRef}
                          onSubmitClick={onSearchClick}
                          render={(formRenderProps) => (
                            <React.Fragment>
                              <FormElement>
                                <div style={{ display: "flex", flexDirection: 'column', padding: 5 }}>
                                  <Field
                                    component={FormComboBox}
                                    name={"year"}
                                    // value={selectedYear}                     
                                    // textField="year"
                                    // valueField="year"
                                    // dataItemKey="year"
                                    required={true}
                                    validator={inputValidator}
                                    label="Year"
                                    placeholder="Year"
                                    style={{ width: '100%' }}
                                    data={tabFilterData.map((item) => {
                                      return item.year
                                    })}
                                    onChange={yearSelect}
                                  />
                                  <Field
                                    // component={FormComboBox}
                                    component={FormMultiSelect}
                                    name={"week"}
                                    required={true}
                                    validator={inputValidator}
                                    // value={selectedWeek}
                                    textField="week"
                                    valueField="week"
                                    label="Week"
                                    placeholder="Week"
                                    style={{ width: '100%' }}
                                    // data={selectedWeekData?.map((item)=>{
                                    //     return item.week
                                    // })}
                                    data={selectedWeekData}
                                    onChange={weekSelect}
                                  />
                                  <Field
                                    // component={FormComboBox}
                                    component={FormMultiSelect}
                                    textField="smsId"
                                    valueField="smsId"
                                    name={"storeId"}
                                    label="Store ID"
                                    placeholder="Store ID"
                                    style={{ width: '100%' }}
                                    // data={selectedStoreData?.map((item)=>{
                                    //     return item.smsId
                                    // })}
                                    data={selectedStoreData}
                                    onChange={selectStoreId}
                                  />
                                  <Field
                                    // component={FormComboBox}
                                    component={FormMultiSelect}
                                    textField="storeName"
                                    valueField="storeName"
                                    name={"storeName"}
                                    label="Store Name"
                                    placeholder="Store Name"
                                    style={{ width: '100%' }}
                                    data={selectedStoreName}
                                    onChange={selectStoreName}
                                  />
                                  <Field
                                    // component={FormComboBox}
                                    component={FormMultiSelect}
                                    textField="city"
                                    valueField="city"
                                    name={"city"}
                                    label="City"
                                    placeholder="City"
                                    style={{ width: '100%' }}
                                    data={selectedCity}
                                    onChange={selectCity}
                                  />
                                  <Field
                                    // component={FormComboBox}
                                    component={FormMultiSelect}
                                    textField="province"
                                    valueField="province"
                                    name={"province"}
                                    label="Province"
                                    placeholder="Province"
                                    style={{ width: '100%' }}
                                    data={selectedProvince}
                                    onChange={selectProvince}
                                  />
                                  <input
                                    type={"submit"}
                                    // style={{ background: "#00f000", marginTop:'20px' }}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-btn-search"
                                    value="Search"
                                  />
                                </div>
                              </FormElement>
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </div>
                  }
                </div>
                {
                  <div style={{ width: tabPanelSize }}>
                    <TabPanel tabFilterResponse={tabFilterResponse} commonFiltersParams={commonFiltersParams} searchCommFiltData={searchCommFiltData} callbackfunc={callbackfunc} cb={cb} />
                  </div>
                }
              </div>
            </div>
          </div>
        </Window>
      </div>
    </React.Fragment>
  );
};

export default NRPS_Report;
