import { SvgIcon } from '@progress/kendo-react-common';

const MessagesIcon = (props) => {
    return (
        <SvgIcon style={{...props?.style}} width={props?.width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.8534 7H7.1491C6.57975 7.00064 6.03387 7.22398 5.63104 7.62109C5.22822 8.0182 5.0013 8.55671 5 9.11863V21.8814C5.00065 22.4435 5.22736 22.9824 5.63033 23.3796C6.03329 23.7769 6.57955 24 7.1491 24H24.8534C25.4225 23.9994 25.9681 23.7759 26.3705 23.3788C26.773 22.9816 26.9993 22.4431 27 21.8814V9.10892C26.9968 8.54891 26.7692 8.01289 26.3671 7.6178C25.9649 7.22272 25.4208 7.00063 24.8534 7ZM24.069 8.4561L16.1512 14.3727C16.1082 14.4054 16.0555 14.4231 16.0012 14.4231C15.947 14.4231 15.8942 14.4054 15.8512 14.3727L7.9335 8.4561H24.069ZM24.8534 22.5439H7.1491C6.97084 22.5439 6.79984 22.4742 6.67356 22.35C6.54728 22.2258 6.47601 22.0573 6.47535 21.8814V9.18415L14.9586 15.5328C15.2581 15.7573 15.6239 15.8789 16 15.8789C16.3761 15.8789 16.7419 15.7573 17.0414 15.5328L25.5246 9.18415V21.8814C25.5246 21.9684 25.5073 22.0545 25.4735 22.1349C25.4398 22.2153 25.3904 22.2883 25.328 22.3498C25.2657 22.4114 25.1917 22.4602 25.1102 22.4935C25.0288 22.5268 24.9415 22.5439 24.8534 22.5439Z" fill={props?.color ?? "#4F4F4F"} />
        </SvgIcon>
    )
}

export default MessagesIcon