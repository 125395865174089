import React from "react";
import { useSelector } from "react-redux";
import { selectAuditorList } from "../../../../store/reducer/commonReducer";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import { PopupPropsContext } from "@progress/kendo-react-popup";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { filterBy } from "@progress/kendo-data-query";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import "./index.css";
import { MessageBox, showToast } from "../../../../utils/Notification";
import { FillHandleDiv } from "./fillHandleDiv";

export const CustomCellDropDown = (props) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const dataItem = props.originalProps.dataItem;
  const dataIndex = props.originalProps.dataIndex;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  const selectedStateKeys = Object.keys(props.selectedState);
  const selectedItemsCount = selectedStateKeys.length;
  const selectedLastItemKey = selectedStateKeys[
    selectedItemsCount - 1
  ];
  const currentRowSelected = selectedStateKeys.includes(dataItem?.customID);
  const currentCellSelected = currentRowSelected && props.selectedState[dataItem?.customID]?.includes(props.originalProps.columnIndex);

  const additionalProps = {
    onDoubleClick: () => {
      if (dataItem.CollectionType === "RA") {
        if (cellField !== 'SECONDARY_RESOURCE_IDS' || ["NEW", "AUDITOR_ASSIGNED"].includes(dataItem?.Status)) {
          props.enterEdit(dataItem, cellField, dataIndex);
        }
        return;
      }

      if (props?.jobAction === "collected" || props?.jobAction === "replan") {
        if (cellField === 'AuditorId' && props?.jobAction === "collected") {
          if (dataItem.Status === "COLLECTED") {
            props.enterEdit(dataItem, cellField, dataIndex);
          }
        } else if (cellField !== 'StartDate' && cellField !== 'EndDate') {
          props.enterEdit(dataItem, cellField, dataIndex);
        }
      } else if (props?.jobAction !== "all") {
        props.enterEdit(dataItem, cellField, dataIndex);
      }
    },
  };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };
  const isEdited = dataItem?.edited?.includes(cellField) ?? false;
  const value =
    cellField === inEditField ? props?.cell : props.td.props.children;
  let className = props.originalProps.isSelected ? "k-state-selected" : "";
  className += isEdited ? " editedCell" : "";


  return (
    <td
      data-grid-col-index={props.originalProps.columnIndex}
      data-grid-dataitem-id={dataItem.customID}
      colSpan={props.originalProps.colSpan}
      role={"gridcell"}
      aria-colindex={props.originalProps.ariaColumnIndex}
      aria-selected={props.originalProps.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
      {...clonedProps}
      className={className.trim()}
      style={{
        textAlign: "center",
        position: "relative",
      }}
    >
      {value}
      {selectedItemsCount === 1 &&
        value !== null &&
        currentRowSelected &&
        currentCellSelected ? (
        <FillHandleDiv
          className={[props.originalProps.isSelected ? "fillHandle" : ""]}
          handleDrag={props?.handleDrag}
          handleDragStart={props?.handleDragStart}
        ></FillHandleDiv>
      ) : (selectedItemsCount > 1 &&
        value !== null &&
        dataItem["customID"] === selectedLastItemKey &&
        currentRowSelected &&
        currentCellSelected ? (
        <FillHandleDiv
          className={[props.originalProps.isSelected ? "fillHandle" : ""]}
          handleDrag={props?.handleDrag}
          handleDragStart={props?.handleDragStart}
        ></FillHandleDiv>
      ) : (
        <div></div>
      ))}
    </td>
  );
};

export const SecondaryResourceIDCell = (props) => {
  const field = "SECONDARY_RESOURCE_IDS";
  const auditorDetails = useSelector(selectAuditorList);
  const dependentField = "SECONDARY_RESOURCE_NAME";
  const auditorIdList = auditorDetails.map((aud) => aud.AuditorId);
  const dataItem = props.dataItem;
  const dataValue = dataItem[field] === null ? [] : dataItem[field];
  const [value, setValue] = React.useState(dataValue);
  const [opened, setOpened] = React.useState(false);
  const handleChange = (e) => {
    if (e.value.includes(dataItem.AuditorId)) {
      showToast("warning", "Can't select same primary and secondary resource id");
      return;
    }
    if (props.onChange) {
      let dependentValueArr = [];
      e.value?.forEach((item) => {
        dependentValueArr.push(auditorDetails?.find((c) => c?.AuditorId === item)?.AuditorName);
      })
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: dependentValueArr,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
    }
    setValue([...e.value]);
  };
  let wrapper = null;
  const onOpen = () => setOpened(true);
  const onClose = () => setOpened(false);
  const toggleDropdown = () => setOpened(!opened);

  const [data, setData] = React.useState(auditorIdList.slice());
  const filterData = (filter) => {
    const data = auditorIdList.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return (
    <>
      {dataItem.inEdit ? (
        <div>
          <div ref={(e) => (wrapper = e)}>
            <MultiSelect
              style={{ width: 225 }}
              data={data}
              // data={auditorIdList}
              onChange={handleChange}
              value={value}
              clearButton={true}
              autoClose={false}
              filterable={true}
              onFilterChange={filterChange}
              popupSettings={{
                appendTo: wrapper,
              }}
              onOpen={onOpen}
              onClose={onClose}
              opened={opened}
            />
            <span
              className="k-icon k-i-arrow-60-down"
              onClick={toggleDropdown}
            />
          </div>
          {/* <PopupPropsContext.Provider
            value={(props) => ({ ...props, anchor: wrapper })}
          >
            <div ref={(e) => (wrapper = e)}>
              <MultiSelect
                id="customCellComboBox"
                style={{ width: 225 }}
                data={data}
                // data={auditorIdList}
                onChange={handleChange}
                value={value}
                clearButton={true}
                autoClose={false}
                filterable={true}
                onFilterChange={filterChange}
                popupSettings={{
                  appendTo: wrapper,
                }}
                onOpen={onOpen}
                onClose={onClose}
                opened={opened}
              />
              <span
                className="k-icon k-i-arrow-60-down"
                onClick={toggleDropdown}
              />
            </div>
          </PopupPropsContext.Provider> */}
        </div>
      ) : (
        dataValue.toString()
      )}
    </>
  );
};
export const SecondaryResourceNameCell = (props) => {
  const field = "SECONDARY_RESOURCE_NAME";
  const dependentField = "SECONDARY_RESOURCE_IDS";
  const dataField = "AuditorName";
  const dependentDataField = "AuditorId";
  const auditorDetails = useSelector(selectAuditorList);
  const dataItem = props.dataItem;
  let dependentValueArr = [];
  if (dataItem[dependentField].length) {
    dataItem[dependentField]?.forEach((audId) => {
      dependentValueArr.push(auditorDetails?.find((c) => c?.[dependentDataField] === audId))
    })
  }
  const dataValue = dataItem[field] === null ? [] : dataItem[field];
  const [value, setValue] = React.useState(dependentValueArr);
  const [opened, setOpened] = React.useState(false);
  const handleChange = (e) => {
    let valueArr = [];
    let dependentValueArr = [];
    e.value?.forEach((item) => {
      valueArr.push(item?.[dataField]);
      dependentValueArr.push(item?.[dependentDataField]);
    })
    if (dependentValueArr.includes(dataItem.AuditorId)) {
      showToast("warning", "Can't select same primary and secondary resource id");
      return;
    }
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: valueArr,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: dependentValueArr,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
    }
    setValue([...e.value]);
  };
  let wrapper = null;
  const onOpen = () => setOpened(true);
  const onClose = () => setOpened(false);
  const toggleDropdown = () => setOpened(!opened);

  const [data, setData] = React.useState(auditorDetails.slice());
  const filterData = (filter) => {
    const data = auditorDetails.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return (
    <>
      {dataItem.inEdit ? (
        <div>
          <div ref={(e) => (wrapper = e)}>
            <MultiSelect
              style={{ width: 225 }}
              data={data}
              onChange={handleChange}
              value={value}
              clearButton={true}
              autoClose={false}
              textField={dataField}
              dataItemKey={dependentDataField}
              filterable={true}
              onFilterChange={filterChange}
              popupSettings={{
                appendTo: wrapper,
              }}
              onOpen={onOpen}
              onClose={onClose}
              opened={opened}
            />
            <span
              className="k-icon k-i-arrow-60-down"
              onClick={toggleDropdown}
            />
          </div>
        </div>
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const CollType = (props) => {
  const dataItem = props.dataItem;
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const [messageProps, setMessageProps] = React.useState(null);
  const collTypeRef = React.useRef();

  const messagePopup = (msg, button, icon) => {
    const callBackFn = (msg) => {
      setMessageProps(null);
      if (msg === "OK") {
        return false;
      }
    };
    setMessageProps({
      title: "Warning",
      buttons: button,
      icon: icon,
      message: msg,
      fn: callBackFn,
    });
  };

  function lettersOnlyCheck(e) {
    var regEx = /^[A-Za-z]+$/;
    if (e.target.value.match(regEx) || e.target.value === "") {
      return true;
    }
    return false;
  }

  const handleChange = (e) => {
    var isvalid = lettersOnlyCheck(e);
    if (isvalid) {
      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: cellField,
          syntheticEvent: e.syntheticEvent,
          value:
            collTypeRef.current.value === "" ? null : collTypeRef.current.value,
        });
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "needToSave",
          syntheticEvent: e.syntheticEvent,
          value: true,
        });
        setValue(e.target.value);
      }
    } else {
      messagePopup("Please enter letters only.", "OK", "WARNING");
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: null,
      });
    }
  };

  return (
    <>
      {dataItem.inEdit ? (
        <>
          {messageProps && <MessageBox {...messageProps} />}
          <Input
            type="text"
            ref={collTypeRef}
            value={value}
            placeholder={"Alphabetic Only"}
            maxLength={100}
            onChange={handleChange}
          />
        </>
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const LimitTime = (props) => {
  const dataItem = props.dataItem;
  const intervalList = getTimeIntervalList();
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const handleChange = (e) => {
    let selectedValue = e.target.value ?? "";
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: selectedValue,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
      setValue(selectedValue);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox onChange={handleChange} value={value} data={intervalList} />
      ) : (
        value.toString()
      )}
    </>
  );
};

export const CollectionDays = (props) => {
  const dataItem = props.dataItem;
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const collectionDaysInputRef = React.useRef();
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value:
          collectionDaysInputRef.current.value === ""
            ? null
            : collectionDaysInputRef.current.value,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
      setValue(e.target.value);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <NumericTextBox
          ref={collectionDaysInputRef}
          value={value}
          min={0}
          onChange={handleChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};
export const EstimatedEans = (props) => {
  const dataItem = props.dataItem;
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const estimatedEansInputRef = React.useRef();
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value:
          estimatedEansInputRef.current.value === ""
            ? null
            : estimatedEansInputRef.current.value,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
      setValue(e.target.value);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <NumericTextBox
          ref={estimatedEansInputRef}
          value={value}
          min={0}
          onChange={handleChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};
export const NoteCollection = (props) => {
  const dataItem = props.dataItem;
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const noteCollInputRef = React.useRef();
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value:
          noteCollInputRef.current.value === ""
            ? null
            : noteCollInputRef.current.value,
        // value: noteCollInputRef.current.value,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
      setValue(e.target.value);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <Input
          type="text"
          ref={noteCollInputRef}
          value={value}
          placeholder={"Max: 100 characters"}
          maxLength={100}
          onChange={handleChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const ResourceIDCell = (props) => {
  const dataItem = props.dataItem;
  const auditorDetails = useSelector(selectAuditorList);
  const field = "AuditorId";
  const dependentField = "PRIMARY_RESOURCE_NAME";
  const dataField = "AuditorId";
  const dependentDataField = "AuditorName";
  const handleChange = (e) => {
    if (dataItem?.SECONDARY_RESOURCE_IDS.includes(e.target.value?.[dataField])) {
      showToast("warning", "Can't select same primary and secondary resource id");
      return;
    }
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dataField] ?? '00000',
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dependentDataField] ?? '00000',
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
    }
  };

  const [data, setData] = React.useState(auditorDetails.slice());
  const filterData = (filter) => {
    const data = auditorDetails.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {

    setData(filterData(event.filter));
  };
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox
          id="customCellComboBox"
          onChange={handleChange}
          value={auditorDetails?.find((c) => c?.[dataField] === dataValue)}
          // data={auditorDetails}
          data={data}
          textField={dataField}
          filterable={true}
          onFilterChange={filterChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const ResourceNameCell = (props) => {
  const dataItem = props.dataItem;
  const auditorDetails = useSelector(selectAuditorList);
  const field = "PRIMARY_RESOURCE_NAME";
  const dependentField = "AuditorId";
  const dataField = "AuditorName";
  const dependentDataField = "AuditorId";

  const handleChange = (e) => {
    if (dataItem?.SECONDARY_RESOURCE_IDS.includes(e.target.value?.[dependentDataField])) {
      showToast("warning", "Can't select same primary and secondary resource id");
      return;
    }
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dataField],
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dependentDataField],
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
    }
  };

  const [data, setData] = React.useState(auditorDetails.slice());
  const filterData = (filter) => {
    const data = auditorDetails.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {

    setData(filterData(event.filter));
  };


  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox
          id="customCellComboBox"
          onChange={handleChange}
          value={auditorDetails?.find((c) => c?.[dataField] === dataValue)}
          // data={auditorDetails}
          data={data}
          textField={dataField}
          filterable={true}
          onFilterChange={filterChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const DatePickerCell = (props) => {
  const dataItem = props.dataItem;
  const cellField = props.field;
  let currentDate = new Date();
  let maxDate = dataItem["todate"] === null ? new Date() : dataItem["todate"];
  let minDate =
    dataItem["fromdate"] === null ? new Date() : dataItem["fromdate"];
  maxDate = new Date(maxDate);
  minDate = new Date(minDate);
  if (cellField === "Duedate") {
    currentDate =
      dataItem["Duedate"] === null
        ? new Date()
        : dataItem["Duedate"];
  } else if (cellField === "EndDate") {
    currentDate =
      dataItem["EndDate"] === null ? new Date() : dataItem["EndDate"];
    if (dataItem["StartDate"]) {
      minDate = new Date(dataItem["StartDate"]);
    }
  } else if (cellField === "StartDate") {
    currentDate =
      dataItem["StartDate"] === null ? new Date() : dataItem["StartDate"];
    if (dataItem["EndDate"]) {
      maxDate = new Date(dataItem["EndDate"]);
    }
  }
  const [value, setValue] = React.useState(new Date(currentDate));
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: formatDateToString(e.target.value),
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
      setValue(e.target.value);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <DatePicker
          min={minDate}
          max={["StartDate", "EndDate", "Duedate"].includes(cellField) ? maxDate : undefined}
          value={value}
          onChange={handleChange}
          format="MM/dd/yyyy"
        />
      ) : (
        formatDateToString(value)
      )}
    </>
  );
};

export const TimeIntervalCell = (props) => {
  const dataItem = props.dataItem;
  const intervalList = getTimeIntervalList();
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const handleChange = (e) => {
    let selectedValue = e.target.value ?? "";
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: selectedValue,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
      setValue(selectedValue);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox onChange={handleChange} value={value} data={intervalList} />
      ) : (
        value.toString()
      )}
    </>
  );
};

export const PlainInputCell = (props) => {
  const dataItem = props.dataItem;
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const remarksInputRef = React.useRef();
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value:
          remarksInputRef.current.value === ""
            ? null
            : remarksInputRef.current.value,
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: "needToSave",
        syntheticEvent: e.syntheticEvent,
        value: true,
      });
      setValue(e.target.value);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <Input
          type="text"
          ref={remarksInputRef}
          value={value}
          placeholder={"Max: 500 characters"}
          maxLength={500}
          onChange={handleChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const NonEditableCell = (props) => {
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  return (
    <td
      {...props.td.props}
      data-grid-dataitem-id={dataItem.customID}
      style={{
        textAlign: "center",
      }}
    >
      {cellField === inEditField ? props?.cell : props.td.props.children}
    </td>
  );
};

function formatDateToString(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
}

function getPreviousTueDay(date) {
  date.setDate(date.getDate() - ((date.getDay() + 5) % 7));
  return date;
}

function getNextSunday(date) {
  date.setDate(date.getDate() + ((7 - date.getDay()) % 7));
  return date;
}

function getTimeIntervalList() {
  let timeInterval = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      timeInterval.push(
        `${i.toString().length === 1 ? "0" + i : i}:${j === 0 ? `00` : 15 * j}`
      );
    }
  }
  return timeInterval;
}
