import * as types from "../../actions/types";

const initialState = {
  isRefreshCLicked: false,
  chatState: null,
  audStatusChangeData: []
};

const HeaderReudcer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.HEADER_REFRESH_CLICKED:
      newState = {
        ...state,
        isRefreshCLicked: true,
      };
      break;
    case types.HEADER_REFRESH_CLICKED_RESET:
      newState = {
        ...state,
        isRefreshCLicked: false,
      };
      break;
    case types.SET_CHAT_SERVER_STATE:
      newState = {
        ...state,
        chatState: action.payload,
      };
      break;
    case types.AUD_STATUS_CHANGE:
      newState = {
        ...state,
        audStatusChangeData: action.payload,
      };
      break;

    default:
      newState = state;
      break;
  }
  return newState;
};

export default HeaderReudcer;
