import { CustomColumnMenuFilter } from "../../ui/grid/CustomColumnMenu";

export const columnMenuConfig = {
  CustomColumnMenuFilter,
};

export const columns = [
  {
    title: "WORKORDERID",
    field: "JobId",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
    filter: "numeric",
  },
  {
    title: "YEAR",
    field: "year",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
    filter: "numeric",
  },
  {
    title: "WeekNumber",
    field: "week",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
    filter: "numeric",
  },
  {
    title: "startdate",
    field: "WeekStartDate",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
    filter: "date",
    format: "{0:MM-dd-yyyy}",
  },
  {
    title: "enddate",
    field: "WeekEndDate",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
    filter: "date",
    format: "{0:MM-dd-yyyy}",
  },
  {
    title: "collectiontype",
    field: "CollectionType",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
  },
  {
    title: "createdon",
    field: "CreatedOn",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
    filter: "date",
    format: "{0:MM-dd-yyyy}",
  },
  {
    title: "Status",
    field: "Status",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
  },
  {
    title: "updatedby",
    field: "UpdatedBy",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
  },
  {
    title: "updatedon",
    field: "UpdatedOn",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
    filter: "date",
    format: "{0:MM-dd-yyyy}",
  },
  {
    title: "Plan Type",
    field: "PlanType",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
  },
  {
    title: "Task Type",
    field: "TaskType",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 120,
  },
];
