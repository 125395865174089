import React from "react";
import { menusObj } from "../dashboard";
const Header = ({ activeMenu, children, wrapperStyle }) => {

  return (
    <>
      <div style={{ height: '45px' }}>
        <div className="nielsen-header"
          style={{
            alignItems: 'flex-end',
            gap: '75px',
            zIndex: 1,
            ...wrapperStyle
          }}
        >
          <div style={{
            display: 'flex',
            alignSelf: 'center'
          }}>
            <h2 style={{
              padding: "0 15px",
              textTransform: "capitalize",
              margin: '0',
              fontSize: '28px',
              fontWeight: 700,

            }}>
              {activeMenu}
            </h2>
          </div>
          <div style={{
            flex: 1,
          }}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
