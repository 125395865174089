import React, { useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";

import { Form, FormElement, Field } from "@progress/kendo-react-form";

import {
  FormComboBox,
  FormDatePicker,
  FormInput,
} from "../../../../ui/form/form-component";
import { useSelector } from "react-redux";
import { selectAuditorList } from "../../../../../store/reducer/commonReducer";
import moment from "moment";
import { getAuditorRemap } from "../../../../../store/actions/people/resources";
import { showToast } from "../../../../../utils/Notification";
import { useTranslation } from "react-i18next";
import LoadMask from "../../../../../utils/LoadMask";
import { inputValidator } from "../../../../ui/form/validator";
import TabHeader from "../TabHeader";
import useLogout, { checkInvalidSession } from "../../../../../utils/useLogout";

const ReassignWork = ({ selectedResourceDetails,screen,
  rolePepplePage, ...props }) => {
  const formRef = useRef(null);
  const {handleAuthContext} = useLogout();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const auditorList = useSelector(selectAuditorList);
  const { t, i18n } = useTranslation();

  const [showLoader, setShowLoader] = useState(null);

  console.log({ auditorList }, "ReassignWork");

  

  const handleAssignWork = async (form) => {
    // console.log("handleAssignWork", { form ,formRef});
    if (!form?.isValid) {
      return;
    }
    const { newauditor, startdate, enddate } = form.values;
    if (new Date(startdate).getTime() > new Date(enddate).getTime()) {
      showToast("warning", t("Invalid date range"));
      //showMessageDialog(translations['warning'], translations['invaliddaterange_msglbl']);
      return;
    }
    const tempNewauditor = auditorList.find((el)=> el.AuditorId === newauditor)    

    const params = {
      oldauditor: selectedResourceDetails?.UNIQUE_ID,
      newauditor : tempNewauditor?.AuditorUniqueId,
      companyid: authContext?.CompanyID,
      user: authContext?.SpocMail,
      startdate: moment(startdate).format("MM/DD/YYYY"), // "12/22/2022",
      enddate: moment(enddate).format("MM/DD/YYYY"), //"12/23/2022",
    };
    setShowLoader({
      className: ".reassign-work-container",
      msg: t("_loading", "Loading"),
    });
    const response = await getAuditorRemap(params, authContext);
    setShowLoader(null);
    if (checkInvalidSession(response)) {
      handleAuthContext();
      return;
    }
    if (response?.StatusCode === "200") {
      formRef?.current?.resetForm()
      showToast("success", response?.StatusMsg);
    } else {
      showToast("error", response?.StatusMsg);
    }
  };
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <TabHeader {...props} />
      <Form
        ref={formRef}
        onSubmitClick={handleAssignWork}
        render={(formRenderProps) => (
          <FormElement>
            <div
              className="reassign-work-container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".6em",
              }}
            >
              <div style={{ display: "flex" }}>
                <Field
                  name="newauditor"
                  label="Replacement Resource Id"
                  placeholder="Replacement Resource Id"
                  component={FormComboBox}
                  data={auditorList?.map((d) => d?.AuditorId)}
                  style={{ width: 206 }}
                  required={true}
                  validator={inputValidator}
                />
              </div>
              <div
                style={{ display: "flex", gap: "1em", marginBottom: "1.5em" }}
              >
                <Field
                  name="startdate"
                  placeholder="WO  Start Date"
                  component={FormDatePicker}
                  format="MM/dd/yyyy"
                  // required={true}
                  validator={inputValidator}
                />
                <Field
                  name="enddate"
                  component={FormDatePicker}
                  placeholder="WO  End Date"
                  format="MM/dd/yyyy"
                  // required={true}
                  validator={inputValidator}
                />
              </div>
              <div>
                <Button primary icon="save"   disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true } >
                  Save Changes
                </Button>
              </div>
            </div>
          </FormElement>
        )}
      />
    </>
  );
};

export default ReassignWork;
