import deleteIcon from "../../../assets/images/delete_icon.png";
import markAsUnreadIcon from "../../../assets/images/mark_as_unread_icon.png";
import markEmailReadIcon from "../../../assets/images/mark_email_read_icon.png";
import { Tooltip } from "@progress/kendo-react-tooltip";
import "./index.css";
import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

const ActionButtonList = ({
  activeScreen,
  markAsUnReadAction,
  markAsReadAction,
  deleteAction,
}) => {

  const screen = 6;

  const userroleDetails = useSelector((state) => state.dashboardReducer.userrolepermssioninfo)
  const [roleNotificationPage, setRoleNotificationPage] = useState({})


  function getObjectByScreenId(screenId) {
    for (let i = 0; i < userroleDetails?.rolePermissionsInfo.length; i++) {
      if (userroleDetails?.rolePermissionsInfo[i].screensInfo.screenId === screenId) {
        return userroleDetails?.rolePermissionsInfo[i];
      }
    }
    return null; // Return null if not found
  }

  useEffect(() => {
    setRoleNotificationPage(getObjectByScreenId(screen))
  }, [])



  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
      }}
    >
      {activeScreen === "inbox" ? (
        <>
          <Tooltip position="bottom" anchorElement="target">
            <div className="actionbutton-div" onClick={markAsReadAction}>
              <img title="Mark As Read" src={markEmailReadIcon}></img>
            </div>
          </Tooltip>
          <Tooltip position="bottom" anchorElement="target">
            <div className="actionbutton-div" onClick={markAsUnReadAction}>
              <img title="Mark As UnRead" src={markAsUnreadIcon}></img>
            </div>
          </Tooltip>
          <Tooltip position="bottom" anchorElement="target">
            {
              roleNotificationPage?.screensInfo?.screenId == screen ? (roleNotificationPage?.deleteFlag == 'N' ? <></> :
                <div className="actionbutton-div" onClick={deleteAction}>
                  <img title="Delete" src={deleteIcon}></img>
                </div>) : <></>}
          </Tooltip>
        </>
      ) : (
        <Tooltip position="bottom" anchorElement="target">
          <div className="actionbutton-div" onClick={markAsReadAction}>
            <img title="Move to Inbox" src={markEmailReadIcon}></img>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionButtonList;
