import React from 'react';
// import "./eCollection.css";

import layer from '../../../assets/images/reports/layer.png';
import layer1 from '../../../assets/images/reports/layer1.png';
import layer2 from '../../../assets/images/reports/layer2.png';
import layer3 from '../../../assets/images/reports/layer3.png';
import layer4 from '../../../assets/images/reports/layer4.png';

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

const GNIC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [displayReport, setDisplayReport] = React.useState(false);

    const openReport = () => {
        console.log("click before", displayReport)
        setDisplayReport(!displayReport)
    }

    const characters = [
        {
            id: 1,
            reportName: "NIC - Reactive Coding Report",
            reportImage: <img className='img-style' src={layer} alt="report" />,
            navigateIcon: <span
                className="k-icon k-i-chevron-right k-icon-32"
                style={{ fontSize: 29 }}
            />,
            path: "/reports/nic/reactive"
        },
        {
            id: 2,
            reportName: "NIC - Proactive Coding Report",
            reportImage: <img className='img-style' src={layer2} alt="report" />,
            navigateIcon: <span
                className="k-icon k-i-chevron-right k-icon-32"
                style={{ fontSize: 29 }}
            />,
            path: "/reports/nic/proactive"
        },
        {
            id: 3,
            reportName: "NIC - Item Details",
            reportImage: <img className='img-style' src={layer4} alt="report" />,
            navigateIcon: <span
                className="k-icon k-i-chevron-right k-icon-32"
                style={{ fontSize: 29 }}
            />,
            path: "/reports/nic/item-details"
        },
    ];


    const CardListItem = (props) => {
        return (
            <li className='card-container' onClick={() => history.push(props.character.path)}>
                <p className='all-reports-name'>
                    {props.character.reportName}
                </p>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p>{props.character.reportImage}</p>
                    <p>{props.character.navigateIcon}</p>
                </div>
            </li>
        );
    };

    const CardList = () => {
        return (
            <ul style={{ listStyleType: "none", padding: 0, display: 'flex', flexWrap: "wrap", gap: "30px" }}>
                {characters.map((character) => {
                    return (
                        <React.Fragment>
                            <CardListItem character={character} key={character.id} openReport={openReport} />
                        </React.Fragment>
                    );
                })}
            </ul>
        );
    };
    return (
        <React.Fragment>
            <CardList />
        </React.Fragment>
    )
}

export default GNIC
