import MessageList from "./messageList";
import { useLocalization } from "@progress/kendo-react-intl";
import * as ReactDOM from "react-dom";

import NielsenIQ_Loader from "../../assets/images/niq_loader.svg";
import { useMemo } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const columns = [
  
  {
    title: "FROM_LBL",
    field: "SentAddress",
    show: true,
  },
  {
    title:  "SUBJECT_LBL",
    field: "MailSubject",
    show: true,
  },
  {
    title: "RECEIVED_LBL",
    field: "SentDate",
    show: true,
  },
];

export const MAIL_STATUS_NEW = "NEW";
export const MAIL_STATUS_READ = "READ";
export const MAIL_STATUS_DELETED = "DELETED";

export const tabsObj = {
  inbox: {
    title: "INBOX_LBL",
    comp: MessageList,
  },
  trash: {
    title: "TRASH_LBL",
    comp: MessageList,
  },
};

// export const tabsObj = useMemo(()=>( {
//   inbox:{
//     title: t("INBOX_LBL"),
//     comp: MessageList,
//   },
//   trash: {
//     title: t("TRASH_LBL"),
//     comp: MessageList,
//   }
  
// }),[]);
export const NotificationsLoadingMask = (props) => {
  
  const { t, i18n } = useTranslation();
  // const Comp = tabsObj?.[activeScreen]?.comp;
  const localizationService = useLocalization();
  
  const mask = (
    <div
      className="k-loading-mask"
      style={props.style ? props.style : { zIndex: 10007 }}
    >
      <div className="k-loading-color" />
      <center style={{ top: "40%", left: "40%", position: "absolute" }}>
        <img src={NielsenIQ_Loader} height={50} alt="Spinner" />
        <h3 style={{ fontWeight: 600, color: "#000000" }}>
          {props?.msg
            ? props.msg
            : localizationService.toLanguageString("loading", "Loading")}
        </h3>
      </center>
    </div>
  );
  const gridContent = document && document.querySelector(".k-grid-container");
  return gridContent ? ReactDOM.createPortal(mask, gridContent) : mask;
  
};
