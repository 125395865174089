import * as types from "../../../actions/types";

const initialState = {
    fetchresourceperskill : null,
    nielsenSkills: null,
    nonnielsenSkill: null
}

const resourceSkillMapping = (state = initialState, action) => {
    let newState;
    switch (action.type) {
      case types.GET_RESOURCE_PER_SKILL:
        newState = {
          ...state,
          fetchresourceperskill: action.payload,
        };
        break;
        case types.SET_NIELSEN_SKILLS:
          newState = {
              ...state,
              nielsenSkills: action.payload
          }
          break;
        case types.SET_NON_NIELSEN_SKILLS:
           newState = {
               ...state,
               nonnielsenSkill: action.payload
            }
          break;
      default:
        newState = state;
        break;
    }
    return newState;
}

export default resourceSkillMapping;