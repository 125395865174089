import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
};

const columns = [
    {
        title: "Batch id",
        field: "productid",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
    },
    {
        title: "User id",
        field: "userid",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
    },
    {
        title: "Requested Timestamp",
        field: "request_ts",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
    },
    {
        title: "Result Timestamp",
        field: "result_ts",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
    },
    {
        title: "Status",
        field: "status",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
    },
    {
        title: "Action",
        field: "action",
        show: true,
    },
];
export default columns;
