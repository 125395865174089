import React, { useEffect, useRef, useState } from "react";

import { useKey, useMultiSelect } from "./hooks";
import { KEY } from "./utils";
import SelectPanel from "../select-panel";
import { Popup } from "@progress/kendo-react-popup";

const Dropdown = () => {
  const {
    value,
    loading,
    disabled,
    valid,
    onChange,
    style = undefined,
    className,
    textField = 'label',
    defaultIsOpen,
    data, placeholder, valueRenderer
  } = useMultiSelect();

  const [hasFocus, setHasFocus] = useState(false);
  const [expanded, setExpanded] = useState(defaultIsOpen);
  const [isInternalExpand, setIsInternalExpand] = useState(true);
  const wrapper: any = useRef();
  const noneSelected = value.length === 0;
  const customText = valueRenderer && valueRenderer(value, data);

  const getSelectedText = () => value.map((s) => s?.[textField]).join(", ");

  useEffect(() => {
    if (defaultIsOpen === undefined) {
      setIsInternalExpand(false);
    }
  }, [defaultIsOpen]);

  const handleKeyDown = (e: any) => {
    if (["text", "button"].includes(e.target.type) && [KEY.SPACE, KEY.ENTER].includes(e.code))
      return;

    if (isInternalExpand) {
      if (e.code === KEY.ESCAPE) {
        setExpanded(false);
        wrapper?.current?.focus();
      }
      else
        setExpanded(true);
    }
    e.preventDefault();
  };

  useKey([KEY.ENTER, KEY.ARROW_DOWN, KEY.SPACE, KEY.ESCAPE], handleKeyDown, {
    target: wrapper,
  });

  const handleFocus = () => !hasFocus && setHasFocus(true);

  const handleBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget) && isInternalExpand) {
      setHasFocus(false);
      setExpanded(false);
    }
  };

  const toggleExpanded = () => {
    isInternalExpand && setExpanded(loading || disabled ? false : !expanded);
  };

  const handleClearSelected = (e: any) => {
    e.stopPropagation();
    onChange && onChange({ value: [] });
    isInternalExpand && setExpanded(false);
  };

  return (
    <React.Fragment >
      <span className={`k-widget k-combobox k-header${className && (' ' + className)}`} style={style}>
        <div
          ref={wrapper}
          className={`k-dropdown-wrap k-state-default${!!!valid && (' k-state-invalid')}`}
        >
          {
            <span
              className='k-searchbar k-input'
              style={{ color: disabled ? '#898D91' : noneSelected ? '#6C757D' : undefined }}
            >
              {noneSelected ? placeholder : (customText || getSelectedText())}
            </span>
          }
          {
            value.length > 0 &&
            <span
              className="k-icon k-clear-value k-i-close"
              role="button" tabIndex={-1} onClick={disabled ? undefined : handleClearSelected} />
          }
          {
            loading && (
              <span className='k-select' style={{ width: '1rem', marginRight: "0.2rem", }}>
                <span className='k-i-loading' />
              </span>
            )
          }
          <span className="k-select" onClick={toggleExpanded}
            onMouseDown={e => e.preventDefault()}>
            <span className="k-icon k-i-arrow-s" />
          </span>
        </div>
      </span>
      {
        expanded &&
        <Popup
          anchor={wrapper.current} show={expanded}
          popupClass={"k-list-container k-reset"} style={{ width: wrapper.current ? wrapper.current.offsetWidth + 'px' : undefined }}
        >
          <SelectPanel
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Popup>
      }
    </React.Fragment >
  );
};

export default Dropdown;