import React from 'react';
import * as ReactDOM from 'react-dom';
import { toast } from "react-toastify";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';

export const MessageBox = props => {
    const localizationService = useLocalization();
    const buttons = props.buttons?.toString() || 'OKCANCEL';
    const component = props.className ? document?.querySelector(props.className) : null;
    const callBackFn = btn => (props?.fn(btn.currentTarget.getAttribute('text')));
    let icon = undefined,
        color = undefined;
    if (props.icon === 'WARNING') {
        icon = 'k-icon k-i-warning';
        color = 'orange';
    } else if (props.icon === 'INFO') {
        icon = 'k-icon k-i-info';
        color = 'blue';
    } else if (props.icon === 'QUESTION') {
        icon = 'k-icon k-i-question';
    } else if (props.icon === 'NOTIFICATION') {
        icon = 'k-icon k-i-notification';
        color = '#000080';
    }

    const msg = (
        <Dialog title={props.title} onClose={props.onClose || props.fn} closeIcon={props.closeIcon ?? true} {...props.dialogProps}>
            <div style={{ maxWidth: '40vw', display: 'flex', margin: '1.5rem', textAlign: 'center', alignItems: 'center' }}>
                <span className={icon} style={{ fontSize: '3rem', marginRight: '1rem', color }}></span>
                {
                    React.isValidElement(props.message) ? props.message :
                        <p style={{ flexWrap: 'wrap' }}>{props.message}</p>
                }
            </div>
            <DialogActionsBar layout={'end'} {...props.dialogActionProps}>
                {buttons.includes('YES') && <button className="k-button k-accent" onClick={callBackFn} text='YES' >{localizationService.toLanguageString('yes', 'Yes')}</button>}
                {buttons.includes('NO') && <button className="k-button k-secondary" onClick={callBackFn} text='NO' >{localizationService.toLanguageString('no', 'No')}</button>}
                {buttons.includes('OK') && <button className="k-button  k-accent" onClick={callBackFn} text='OK' >{localizationService.toLanguageString('ok', 'OK')}</button>}
                {buttons.includes('ACCEPT') && <button className="k-button k-accent" onClick={callBackFn} text='ACCEPT' >{localizationService.toLanguageString('accept', 'Accept')}</button>}
                {buttons.includes('CANCEL') && <button className="k-button k-secondary" onClick={callBackFn} text='CANCEL' >{localizationService.toLanguageString('cancel', 'Cancel')}</button>}
            </DialogActionsBar >
        </Dialog >
    );
    return component ? ReactDOM.createPortal(msg, component) : msg;
};

export const showToast = (type, message, options) => {
    toast[type]?.call(this, message, { ...options });
};