// import * as type from "../types";
import { Get, Post, PostwithTimeoutHandler } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";



export const getExecutionQualityTable = async (params, authContext) => {
  const header = {
    userid: authContext?.SpocMail || "",
    countryid: authContext?.CountryCode || "",
    companyid: authContext?.CompanyID || "",
    "content-type": "application/json",
    // "content-type": "application/x-www-form-urlencoded; application/json",
  };

  const res = await PostwithTimeoutHandler(
    AppURL.reportsAPI,
    params,
    header,
    true,10000
  ).then((res) => res.json());
  // const res = await Get(
  //   AppURL.getExecutionQualityTable,
  //   params,
  //   header,
  //   true
  // ).then((res) => res.json());

  // const response = res?.ReportResponse?.Response;
  if (res?.ReportResponse?.Response) {
    res.ReportResponse.Response.forEach((responseRec) => {
      let imagecount = 0;
      var images = [],
          imageobj = {}
      if (responseRec.EXCEPTIONS) {
        
        let exceptions = responseRec.EXCEPTIONS;
        exceptions.forEach((exceptionRec) => {
          if (exceptionRec.hasOwnProperty("IMAGEDETAILS")) {
            let imagedetails = exceptionRec.IMAGEDETAILS;
            imagedetails.forEach((image) => {
              imageobj = {};
              imageobj.FAIL_REASON = exceptionRec.FAIL_REASON;
              imageobj.COMMENTS = exceptionRec.COMMENTS;
              imageobj.IMAGE_ID = image.IMAGE_ID;
              imageobj.IMAGE_LINK = image.IMAGE_LINK;
              images.push(imageobj);
            });
          }
        });
       
        responseRec.images = images;
      }
      responseRec.imagecount = images.length;
    });
    // return response;
  }

  return res;

};



export const getExecutionQualityExportData = async (params, authContext) => {

  const header = {
    "content-type": "application/json", 
  
  }

  const exportFile = await Get(AppURL.getExecutionQualityExportData,params,null,true);    
    console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
    const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
    const exportFileBlob = await exportFile.blob();
    const exportFileUrl = URL.createObjectURL(exportFileBlob);    
    // const FILE_NAME = Date.now();
    const anchor = document.createElement("a");
    anchor.href = exportFileUrl; 
    anchor.download = disposition?.[1];
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor); 



};