import { useTranslation } from 'react-i18next';
import ReportsCard from "./ReportsCard";
import { Button } from "@progress/kendo-react-buttons";
import pieChart from "../../../assets/img/Component_225_1.png";
import barChart from "../../../assets/img/Component_226_1.png";
import summaryReportChart from "../../../assets/img/Component_224_1.png";
import { useHistory } from 'react-router-dom';

const ReportsRow = ({temp}) => {
    const history = useHistory();
    const { t } = useTranslation();
    
    const iconsObj = [summaryReportChart, pieChart, barChart];
    const reportObj = [];
    if (temp["ECOLLECTION"] === "Y") {
        reportObj.push({
            title: "ECOLLECTION",
            subtitle: "summaryReport",
            link: "/reports/eCollection/summaryreport"
        }, {
            title: "ECOLLECTION",
            subtitle: "flowcontrolreport",
            link: "/reports/ecollection/flowcontrolreport"
        }, {
            title: "ECOLLECTION",
            subtitle: "scanimagetimestamp",
            link: "/reports/ecollection/scanimagetimestamp"
        })
    } else if (temp["GNIC"] === "Y") {
        reportObj.push({
            title: "NIQPics",
            subtitle: "nicreactivecodingreport",
            link: "/reports/nic/reactive"
        }, {
            title: "NIQPics",
            subtitle: "nicproactivecodingreport",
            link: "/reports/nic/proactive"
        }, {
            title: "NIQPics",
            subtitle: "nicitemdetails",
            link: "/reports/nic/item-details"
        })
    } else if (temp["DCT"] === "Y") {
        reportObj.push({
            title: "dctreports",
            subtitle: "Status Report",
            link: "/reports/dct"
        })
    } else if (temp["NSO"] === "Y") {
        reportObj.push({
            title: "NSO Reports",
            subtitle: "Audit Summary Report",
            link: "/reports/NSO/auditSummary"
        }, {
            title: "NSO Reports",
            subtitle: "Timestamp Report",
            link: "/reports/NSO/timeStamp"
        })
    } else if (temp["RA"] === "Y") {
        reportObj.push({
            title: "RA Report",
            subtitle: "Audit Summary Report",
            link: "/reports/RA/auditSummary"
        })
    } else {
        reportObj.push({
            title: "MISCELLANEOUS",
            subtitle: "Mobile app version report",
            link: "/reports/mobileApp"
        })
    }

    return (
        <div
            className='nielsen-card'
            style={{
                height: "50%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    height: "15%",
                    marginLeft: "10px",
                    alignItems: "center",
                    padding: '5px 0',
                    fontSize: '16px',
                    fontWeight: '700'
                }}
            >
                <h3>{t("REPORTS")}</h3>
            </div>
            <div
                style={{
                    display: "flex",
                    height: "75%",
                    alignItems: "center",
                    marginLeft: '5px'
                }}
            >
                {reportObj?.map((obj, indx) => <ReportsCard
                    key={obj.title}
                    title={obj.title}
                    subtitle={obj.subtitle}
                    icon={iconsObj[indx]}
                    link={obj.link}
                />)}
                <Button
                    style={{
                        borderRadius: "50%",
                    }}
                    onClick={() => history.push("/reports")}
                >
                    <span className="k-icon k-i-chevron-right k-icon-32"></span>
                </Button>
            </div>
        </div>
    )
}

export default ReportsRow