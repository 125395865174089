import regionCountry from "../../utils/regionCountry";
import * as types from "../actions/types";
const spocDetails = localStorage.getItem("spocDetails")
  ? JSON.parse(localStorage.getItem("spocDetails"))
  : null;
// console.log(`spocDetails`,JSON.parse(spocDetails));
const initialState = {
  authContext: spocDetails,
  companyDetails: null,
  gridUserPrefrence:null,
  searchParametersOFPlan:null,
  loginError: null,
  validateSessionResponse: null,
  forgotPasswordQus: [],
  forgotPasswordError: null,
  forgotPasswordValidationResponse: null,
  resetPasswordResponse: null,
  forceLogin: "N",
  userProfileResponse: null,
  changePasswordResponse: null,
  updateUserProfileResponse: null,
  mailBodyResponse: null,
  permissions: null,
  loggedInUserPhoto: null,
  roles: [],
  allRCL: {},
  languages: [],
  hubDetails: [],
  ouModuleRoles: [],
  organizationUnits: [],
  countryDialingCodes: [],
  referenceDataSource: {},
  permissionsForLoggedInUser: [],
  unavailabilityReferenceDataSource: {},
  getCurrenry: [],
  fetchCourseRoleType: [],
  fetchLanguage: null,
  fetchLob: [],
  getSkill: [],
  countryList: [],
  auditorList: [],
  selectedCountry: {},
  selectedLob: {},
  getMailNewCount:{},
  workOrderDetail: {},
  actionWorkOrderDetail: {},
  locationData: {
    Region: regionCountry?.[0],
    Country: regionCountry?.[0]?.CountryDetails,
  },
};

const commonReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.SET_AUTH_CONTEXT:
      newState = {
        ...state,
        authContext: action.payload,
      };
      break;
    case types.SET_COMPANY_DETAILS:
      newState = {
        ...state,
        companyDetails: action.payload,
      };
      break;
    case types.SET_USER_PREFRENCE:
      newState = {
        ...state,
        gridUserPrefrence: action.payload,
      };
      break;
    case types.SET_SEARCH_PARAMETER_OF_PLAN:
      newState = {
        ...state,
        searchParametersOFPlan: action.payload,
      };
      break;
    case types.FORCE_LOGIN:
      newState = {
        ...state,
        forceLogin: action.payload,
      };
      break;
    case types.VALIDATE_SESSION_RESPONSE:
      newState = {
        ...state,
        validateSessionResponse: action.payload,
      };
      break;
    case types.LOGIN_ERROR:
      newState = {
        ...state,
        loginError: action.payload,
      };
      break;
    case types.FORGOT_PASSWORD_QUS:
      newState = {
        ...state,
        forgotPasswordQus: action.payload,
      };
      break;
    case types.FORGOT_PASSWORD_ERROR:
      newState = {
        ...state,
        forgotPasswordError: action.payload,
      };
      break;
    case types.FORGOT_PASSWORD_VALIDATION_RESPONSE:
      newState = {
        ...state,
        forgotPasswordValidationResponse: action.payload,
      };
      break;
    case types.RESET_PASSWORD:
      newState = {
        ...state,
        resetPasswordResponse: action.payload,
      };
      break;
    case types.GET_MAIL_BODY_RESPONSE:
      newState = {
        ...state,
        mailBodyResponse: action.payload,
      };
      break;
    case types.GET_MAIL_NEW_COUNT:
      newState = {
        ...state,
        getMailNewCount: action.payload,
      };
      break;
    case types.USER_PROFILE_RESPONSE:
      newState = {
        ...state,
        userProfileResponse: action.payload,
      };
      break;
    case types.CHANGE_PASSWORD_RESPONSE:
      newState = {
        ...state,
        changePasswordResponse: action.payload,
      };
      break;
    case types.UPDATE_USER_PROFILE_RESPONSE:
      newState = {
        ...state,
        updateUserProfileResponse: action.payload,
      };
      break;
    case types.SET_LOGGEDIN_USER_PHOTO:
      newState = {
        ...state,
        loggedInUserPhoto: action.payload,
      };
      break;
    case types.SET_PERMISSIONS:
      newState = {
        ...state,
        permissions: action.payload,
      };
      break;
    case types.SET_ROLES:
      newState = {
        ...state,
        roles: action.payload,
      };
      break;
    case types.SET_LANGUAGES:
      newState = {
        ...state,
        languages: action.payload,
      };
      break;
    case types.SET_CURRENCY:
      newState = {
        ...state,
        getCurrenry: action.payload,
      };
      break;
    case types.SET_COURSE_TYPE:
      newState = {
        ...state,
        fetchCourseRoleType: action.payload,
      };
      break;
    case types.SET_ORG_UNITS:
      newState = {
        ...state,
        organizationUnits: action.payload,
      };
      break;
    case types.SET_HUB_DETAILS:
      newState = {
        ...state,
        hubDetails: action.payload,
      };
      break;
    case types.SET_REFERENCE_DATA_SOURCE:
      let dataSource = {};
      action.payload.forEach((item) => {
        dataSource[item.Code] = item.Values;
      });
      newState = {
        ...state,
        referenceDataSource: dataSource,
      };
      break;
    case types.SET_UNAVAILABILITY_REFERENCE_DATA_SOURCE:
      let unavailabilityDataSource = {};
      action.payload.forEach((item) => {
        unavailabilityDataSource[item.Code] = item.Details;
      });
      newState = {
        ...state,
        unavailabilityReferenceDataSource: unavailabilityDataSource,
      };
      break;
    case types.SET_COUNTRY_DIALING_CODES:
      newState = {
        ...state,
        countryDialingCodes: action.payload,
      };
      break;
    case types.GET_PERMISSIONS_FOR_LOGGEDIN_USER:
      newState = {
        ...state,
        permissionsForLoggedInUser: action.payload,
      };
      break;
    case types.GET_OU_MODULE_ROLES:
      newState = {
        ...state,
        ouModuleRoles: action.payload,
      };
      break;
    case types.FETCH_LOB_DETAILS:
      newState = {
        ...state,
        fetchLob: action.payload,
      };
      break;
    case types.FETCH_LANGUAGE_DETAILS:
      newState = {
        ...state,
        fetchLanguage: action.payload,
      };
      break;
    case types.GET_ALL_RCL:
      let data = {};
      action.payload.forEach(
        (ou) => (data[ou.OU_ID] = ou.GeographicalHiearachy)
      );
      newState = {
        ...state,
        allRCL: data,
      };
      break;
    case types.GET_SKILL_DETAILS:
      newState = {
        ...state,
        getSkill: action.payload,
      };
      break;
    case types.SET_COUNTRY_LIST:
      newState = {
        ...state,
        countryList: action.payload,
      };
      break;
    case types.SET_LOCATION_DATA:
      newState = {
        ...state,
        locationData: action.payload,
      };
      break;
    case types.SET_AUDITOR_LIST:
      newState = {
        ...state,
        auditorList: action.payload,
      };
      break;
    case types.SET_SELECTED_COUNTRY:
      newState = {
        ...state,
        selectedCountry: action.payload,
      };
      break;
    case types.SET_LOB_DATA:
      newState = {
        ...state,
        selectedLob: action.payload,
      };
      break;
    case types.SET_WORKORDER_DETAIL:
      newState = {
        ...state,

        workOrderDetail: action.payload.dataItem,
      };
      break;
    case types.SET_ACTION_WORKORDER_DETAIL:
      newState = {
        ...state,
        actionWorkOrderDetail: action.payload,
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
};

export default commonReducer;
export const selectAuditorList = (state) => state.commonReducer.auditorList;