import React, { useState, useEffect } from 'react';
import './style.css';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormComboBox } from '../../../form/form-component';
import { useDispatch, useSelector } from 'react-redux';
import { postNrpsReportExport, postNrpsReportKpiClick, scrollingReport, sidebarOpenCloseAction, tabFilters } from '../../../../store/actions/reports';
import { showToast } from '../../../../utils/Notification';
import MultiRangeSlider from './MultiRangeSlider';

const KpiTabHeader = ({ activeIndex, tabFilterResponse }) => {
  const dispatch = useDispatch();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const nrpsReportKpiClick = useSelector((store) => store.reports.nrpsReportKpiClick);
  const nrpsReportStoresInfo = useSelector((store) => store.reports?.nrpsReportStoresInfo?.storesInfo);
  const nrpsCommonFiltersParams = useSelector((store) => store?.reports?.nrpsCommonFiltersParams);
  const activeTab = useSelector((store) => store?.reports?.activeTab);
  const activeTabFilters = useSelector((store) => store?.reports?.activeTabFilters);

  const [response, setResponse] = React.useState("");
  const [commonFiltersParams, setCommonFiltersParams] = useState(null)
  const [productFilterData, setProductFilterData] = useState([]);
  const [categoryFilterData, setCategoryFilterData] = useState([]);
  const [storeTypeFilterData, setStoreTypeFilterData] = useState([]);
  const [statusFilterData, setStatusFilterData] = useState([]);
  const [limitDateFilterData, setLimitDateFilterData] = useState([]);
  const [startRangeValue, setStartRangevalue] = useState(null);
  const [endRangeValue, setEndRangevalues] = useState(null);

  useEffect(() => {
    setResponse(nrpsReportKpiClick?.storesInfo);
  }, [nrpsReportKpiClick]);

  useEffect(() => {
    setCommonFiltersParams(nrpsCommonFiltersParams);
  }, [nrpsCommonFiltersParams]);

  useEffect(() => {
    setProductFilterData(nrpsReportKpiClick?.nrpskpiReportFilter?.productFilter);
    setStatusFilterData(nrpsReportKpiClick?.nrpskpiReportFilter?.statusFilter);
    setLimitDateFilterData(nrpsReportKpiClick?.nrpskpiReportFilter?.limitDateFilter);
    setStartRangevalue(nrpsReportKpiClick?.nrpskpiReportFilter?.pcRatingStartRange);
    setEndRangevalues(nrpsReportKpiClick?.nrpskpiReportFilter?.pcRatingEndRange);
  },[nrpsReportKpiClick]);

  useEffect(()=>{
    dispatch(tabFilters({ 
      banner: "",
      auditorId: "",
      collectionType: "",
      endRange: "",
      limitDate: "",
      product: "",
      startRange: "",
      status: "",
      storeType: ""      
    }))    
  },[])
  
  const handleCategory = (event) => {    
    dispatch(tabFilters({...activeTabFilters, category:event.value ? event.value : ""}))
  }
  const handleStoreType = (event) => {
    dispatch(tabFilters({ ...activeTabFilters, storeType: event.value ? event.value : "" }))
  }
  const handleProduct = (event) => {
    dispatch(tabFilters({ ...activeTabFilters, product: event.value ? event.value : "" }))
  }
  const handleStatus = (event) => {
    dispatch(tabFilters({ ...activeTabFilters, status: event.value ? event.value : "" }))
  }
  const handleLimitDate = (event) => {
    dispatch(tabFilters({ ...activeTabFilters, limitDate: event.value ? event.value : "" }))
  }
  const handleRange = (min, max) => {
    dispatch(tabFilters({ ...activeTabFilters, startRange: min, endRange: max }))
  }

  const handleSliderRange = (min, max) => {
    const rangeObj = {
      min,
      max,
    };
    localStorage.setItem("Range", JSON.stringify(rangeObj));
    dispatch(tabFilters({ ...activeTabFilters, startRange: min, endRange: max }))
  }

  const localStorageRange = JSON.parse(localStorage.getItem("Range"));

  const onSearchClick = () => {
    dispatch(scrollingReport("Kpi"))
    if (authContext) {
      const params = {
        ...commonFiltersParams,
        ...activeTabFilters,
        "vendorId": [parseInt(authContext.CompanyID)],
        "country": authContext.CountryCode,
      }
      console.log("after", { commonFiltersParams }, { activeTabFilters })
      if (activeTab === 3 || activeIndex === 3) {
        params.reportType = 'Kpi'
        dispatch(postNrpsReportKpiClick(params, authContext));
        // dispatch(getNRPSStoreStoresInfo(params, authContext)); 
        dispatch(sidebarOpenCloseAction(false));
      } else {
        return
      }
    }
  }

  const exportReport = () => {
    dispatch(scrollingReport("Kpi"))
    if (authContext) {
      const params = {
        ...commonFiltersParams,
        ...activeTabFilters,
        "vendorId": [parseInt(authContext.CompanyID)],
        "country": authContext.CountryCode,
      }

      let params2={
        "CollectionType":"ECOLLECTION",
        "ReportName":"NRPS_REPORT",
        "APIName":"NRPSReportExport",
        "Payload":{
            ...params
        }
    }


      if (activeTab === 3 || activeIndex === 3) {
        params.reportType = 'Kpi'
        dispatch(postNrpsReportExport(JSON.stringify(params2), authContext));
        dispatch(sidebarOpenCloseAction(false));
        showToast(
          "info",
          "File will be downloaded in some time."
        );
      } else {
        return
      }
    }
  }



  return (
    <React.Fragment>
      <div className='tab-navbar'>
        <div className='all-circles'>
          <div className='circle' style={{ border: '5px solid #b0b0f9' }}>
            <div className="inner-circle" style={{ border: '5px solid #b0b0f9' }}>
              <span className='store-circle-name'>
                Stores In Plan
              </span>
              <span>{nrpsReportStoresInfo?.storesInPlan}</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid #8ae88a' }}>
            <div className="inner-circle" style={{ border: '5px solid #8ae88a' }}>
              <span className='store-circle-name'>
                Stores Completed
              </span>
              <span>{nrpsReportStoresInfo?.storesCollected}</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid #dede5a' }}>
            <div className="inner-circle" style={{ border: '5px solid #dede5a' }}>
              <span className='store-circle-name'>
                Stores In progress
              </span>
              <span>{nrpsReportStoresInfo?.storesInProgress}</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid #e97171' }}>
            <div className="inner-circle" style={{ border: '5px solid #e97171' }}>
              <span className='store-circle-name'>
                Stores Incomplete
              </span>
              <span>{nrpsReportStoresInfo?.storesIncomplete}</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid #bcb8b8' }}>
            <div className="inner-circle" style={{ border: '5px solid #bcb8b8' }}>
              <span className='store-circle-name'>
                Collected EANS
              </span>
              <span>{nrpsReportStoresInfo?.collectedEans}</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid #dcb48b' }}>
            <div className="inner-circle" style={{ border: '5px solid #dcb48b' }}>
              <span className='store-circle-name'>
                Expected EANS
              </span>
              <span>{nrpsReportStoresInfo?.expectedEans}</span>
            </div>
          </div>
        </div>
        <div className='particain'></div>
        <div className='allTab-form'>
          <Form
            //   initialValues={initialData}
            //   ref={searchFormRef}
            onSubmitClick={onSearchClick}
            render={(formRenderProps) => (
              <React.Fragment>
                <FormElement>
                  <div className='form-container'>
                    <Field
                      component={FormComboBox}
                      name={"product"}
                      // required={true}                       
                      label="PRODUCT"
                      placeholder="Product"
                      style={{ width: '100%' }}
                      onChange={handleProduct}
                      data={productFilterData}
                    />
                    {/* <Field                        
                        component={FormComboBox}
                        name={"category"}                          
                        // required={true}                       
                        label="CATEGORY"                        
                        placeholder="Category"                        
                        style={{ width: '100%' }} 
                        onChange={handleCategory}
                        data={categoryFilterData}                                          
                      /> */}
                    {/* <Field                        
                        component={FormComboBox}
                        name={"storeType"}                       
                        label="STORE TYPE"                        
                        placeholder="Store Type"                        
                        style={{ width: '100%' }}  
                        onChange={handleStoreType}   
                        data={storeTypeFilterData}                                      
                      /> */}
                    <Field
                      component={FormComboBox}
                      name={"status"}
                      // required={true}                       
                      label="STATUS"
                      placeholder="Status"
                      style={{ width: '100%' }}
                      onChange={handleStatus}
                      data={statusFilterData}
                    />
                    <Field
                      component={FormComboBox}
                      name={"limitDate"}
                      // required={true}                       
                      label="LIMIT DATE"
                      placeholder="Limit Date"
                      style={{ width: '100%' }}
                      onChange={handleLimitDate}
                      data={limitDateFilterData}
                    />

                  </div>
                  <div style={{ display: 'flex', gap: 5, alignItems: "center", marginTop: "5px" }}>
                    <span className='slider_name'>PC RATING :</span> {startRangeValue || endRangeValue ? <MultiRangeSlider localStorageRange={localStorageRange} min={startRangeValue} max={endRangeValue} onChange={({ min, max }) => handleSliderRange(min, max)} /> : ""}
                    <Button
                      className='k-primary'
                      type="submit"
                      value="Search"
                      icon="search"
                      style={{ marginLeft: 'auto' }}
                    >
                      SEARCH
                    </Button>
                    <Button
                      className='k-secondary'
                      type="submit"
                      icon="download"
                      onClick={exportReport}
                      value="Export"
                    >
                      EXPORT
                    </Button>
                  </div>
                </FormElement>
              </React.Fragment>
            )}
          />
        </div>
      </div>

    </React.Fragment>
  )
}

export default KpiTabHeader;
