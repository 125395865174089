import { Upload } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { useState, useRef } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React from "react";
import { useSelector } from "react-redux";
import AppURL from "../../../utils/AppURL";
import { showToast } from "../../../utils/Notification";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";

const UploadWindow = ({ setUploadWindow, selectedDataUpload }) => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [isFormShow, setFormShow] = useState(true);
  const [apiURL, setApiURL] = useState(AppURL.ProcessUploadFile);
  const { handleAuthContext } = useLogout();

  const onBeforeUpload = async (e) => {
    e.additionalData.jsonString = ""
  };

  const onAdd = (event) => {
    console.log("onAdd: ", event.affectedFiles[0].name);
    setApiURL(
      AppURL.ProcessUploadFile +
        `?FileName=${event?.affectedFiles?.[0]?.name}&CompanyId=${authContext?.CompanyID}&UserId=${authContext.SpocMail}`
    );
  };
  const onStatusChange = (e) => {
    if (e.response) {
      if (checkInvalidSession(e.response?.response)) {
        handleAuthContext();
        return;
      }
      if (!e.response?.response?.success) {
        showToast("error", e.response?.response?.Response?.Message);
        return;
      }
      showToast("success", e.response?.response?.Message);
    }
  };
  return (
    <Window
      title={"Upload Stores File"}
      onClose={() => setUploadWindow(false)}
      modal={true}
      minimizeButton={() => (
        <Button
          icon="question"
          onClick={() => setFormShow(!isFormShow)}
          style={{ marginRight: ".5em" }}
        />
      )}
      maximizeButton={() => null}
      restoreButton={() => null}
      // initialTop={70}
      initialHeight={300}
      initialWidth={590}
      style={{ paddingBottom: "1em" }}
    >
      {isFormShow ? (
        <>
          <Form
            // initialValues={{
            //   lob: selectedLob,
            //   collectionType: selectedCollection,
            // }}
            // onSubmitClick={handleUpload}
            enctype="multipart/form-data"
            render={(formRenderProps) => (
              <FormElement style={{padding:"1em 0"}}>
                <label>File*</label>
                <Upload
                    accept={".xlsx"}
                    batch={false}
                    multiple={false}
                    defaultFiles={[]}
                    autoUpload={false}
                    withCredentials={false}
                    saveUrl={apiURL}
                    saveField={'BrowseFile'}
                    restrictions={{
                      allowedExtensions: [".xlsx"],
                    }}
                    actionsLayout={"stretched"}
                    onBeforeUpload={onBeforeUpload}
                    onAdd={onAdd}
                    onStatusChange={onStatusChange}
                  />
              </FormElement>
            )}
          />
        </>
      ) : (
        <div>
          <Button icon="arrow-left" onClick={() => setFormShow(true)}>
            Back To Upload
          </Button>
          <ul style={{ color: "#000", fontWeight: 500 }}>
            <li>All column fields are comma(,) separated</li>
            <li>Resource ID:Mandatory field, shows the Primary resource</li>
            <li>
              Resource Name:Optional field, filling resource id will also auto
              populate resource name and vice versa
            </li>
            <li>
              Secondary Resource ID:Optional field Multiple resources can be
              added
            </li>
            <li>
              Multiple resources should be seperated by a | symbol and each
              resource should be enclosed with single quotes
            </li>
            <li>
              <b>Start and End Dates:</b> Mandatory fields, these dates are
              bounded by the Nielsen Start and End Dates. For example, if the
              Nielsen Start Date is 04/28/2019 and the Nielsen End Date is
              05/04/2019, then you are &quot;bound&quot; to work on or between
              those dates when scheduling work for your resource.
            </li>
            <li>
              <b>Visit Date:</b> This will autopopulate with the End Date if not
              filled in. You can choose to add a Visit Date so that the resource
              knows that the store must be visited on a specific date
            </li>
            <li>
              <b>Start and End Time:</b> To be used if the store should be
              audited during a specific time of the day. For example, the shop
              keeper may want to be visited only in the afternoon hours. So you
              would specify the Start and End times accordingly
            </li>
            <li>
              <b>Remarks:</b> These are comments you can enter as instructions
              to your resource. For example, you could say &quot;Please do not
              visit this store from 12pm to 1pm&quot; or other important
              messages you want to pass along for that particular visit.
            </li>
          </ul>
        </div>
      )}
    </Window>
  );
};

export default UploadWindow;
