import * as types from "../actions/types";

const initialState = {

};

const exceptionHandlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case action.type === types.SERVICE_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
};

export default exceptionHandlerReducer;