import { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as types from "../../store/actions/types";
import "./index.scss";
import "./mainHeader.scss";
import { useDispatch, useSelector } from "react-redux";
import { GetSearchParametersOFPlan, getCountryDetails } from "../../store/actions/commonActions";
import { lang } from "../../store/actions/login";
import {
  fetchDashboardData,
  searchUserPrefrence,
} from "../../store/actions/dashboard";
import { fetchuserprofile, fetchuserprofileGetpermissionRoles } from "../../store/actions/userProfile";

const Main = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const permissions = useSelector((state) => state.commonReducer.permissions);
  const userroleDetails = useSelector((state)=> state.dashboardReducer.userrolepermssioninfo)

  console.log(userroleDetails,"Hellow 123");

  useEffect(() => {
    if (authContext) {
      const Region = permissions;
      if (Region && Array.isArray(Region)) {
        const countryIdArr = authContext?.CountryCode?.split(",");
        const countryId = countryIdArr?.[0];
        const selectedRegion = Region.find((regionData) => {
          const countryArr = regionData?.CountryDetails;
          return countryArr.some((ca) => ca?.CountryID == countryId);
        });
        const CountryArr = selectedRegion?.CountryDetails?.filter((ca) => {
          return countryIdArr?.includes(ca?.CountryID);
        });
        const locationData = {
          Region: selectedRegion,
          Country: CountryArr || [],
        };
        dispatch({
          type: types.SET_LOCATION_DATA,
          payload: locationData,
        });
      }
    }
  }, [permissions, authContext]);

  useEffect(() => {
    dispatch(
      lang({
        page: 1,
        start: 0,
        limit: 25,
      })
    );
  }, []);

  useEffect(() => {
    if (authContext?.isAuth) {
      dispatch(
        fetchDashboardData({
          CompanyId: authContext?.CompanyID,
          page: 1,
          start: 0,
          limit: 25,
        })
      );
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      dispatch(
        fetchuserprofile({
          SpocMailId: authContext?.SpocMail,
          CompanyId: authContext?.CompanyID,
        })
      );

      dispatch(fetchuserprofileGetpermissionRoles({ 
        company_id: authContext?.CompanyID, spoc_email: authContext?.SpocMail, }, authContext));

      dispatch(getCountryDetails({ companyId: authContext?.CompanyID }));
      dispatch(GetSearchParametersOFPlan({ companyid: authContext?.CompanyID }, authContext));
    } else {
      history.push("/login");
    }
  }, [authContext]);

  return (
    <>{children}</>
  );
};

export default withRouter(Main);
