import AppURL from "../../../../utils/AppURL";
import { Get, Post, PostwithTimeoutHandler } from "../../../methods";

export const getReactiveReport = async(params, authContext) => {
    const header = {   
         userid: authContext?.SpocMail || "",
         countryid: authContext?.CountryCode || "",
         companyid:authContext?.CompanyID || "",
        "content-type": "application/json",
        // "content-type": "application/x-www-form-urlencoded; application/json",
      }               
      const result = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header,true,10000).then((res) =>
      // const result = await Get(AppURL.getReactiveReport, params, header,true).then((res) =>
      res.json()
    );
    return result;
}
export const getReactiveGraphReport = async(params, authContext) => {
  const header = {   
       userid: authContext?.SpocMail || "",
       countryid: authContext?.CountryCode || "",
       companyid:authContext?.CompanyID || "",
      "content-type": "application/json",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }               
    const result = await Get(AppURL.getReactiveGraphReport, params, header,true).then((res) =>
    res.json()
  );
  return result;
}

// WAF 
export const exportReactiveReport = async (params, authContext) => {
  const header = {   
    // userid: authContext?.SpocMail || "",
    // countryid: authContext?.CountryCode || "",
    // companyid:authContext?.CompanyID || "",
   "content-type": "application/json",
 }
    const exportFile = await Post(AppURL.exportReactiveReoprt,params, header,null,true);    
    // const exportFile = await Get(AppURL.exportReactiveReoprt,params,null,true);    
      console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
      const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);    
      // const FILE_NAME = Date.now();
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl; 
      anchor.download = disposition?.[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor); 
};



// export const exportReactiveReport = async (params, authContext) => {
//     const exportFile = await Get(AppURL.exportReactiveReoprt,params,null,true);    
//       console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
//       const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
//       const exportFileBlob = await exportFile.blob();
//       const exportFileUrl = URL.createObjectURL(exportFileBlob);    
//       // const FILE_NAME = Date.now();
//       const anchor = document.createElement("a");
//       anchor.href = exportFileUrl; 
//       anchor.download = disposition?.[1];
//       document.body.appendChild(anchor);
//       anchor.click();
//       document.body.removeChild(anchor); 
//   };

