import React from 'react'
import Header from '../header';
import AllReports from './AllReports';
import { reportData } from './data';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as types from "../../store/actions/types";

const Reports = ({ activeMenu }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reportsAccordianActive = useSelector((store) => store?.reports?.reportsAccordianActive);
  const userProfileResponse = useSelector((state) => state.commonReducer.userProfileResponse);
  let temp = {
    "NSO": "N",
    "DCT": "N",
    "GNIC": "N",
    "ECOLLECTION": "N",
    "RA": "N",
    "MISCELLANEOUS": "Y"
  }
  userProfileResponse?.VendorInfo?.Vendor[0]?.CompanyProductDetails.forEach((x) => {
    temp[x.ProductName] = x.ActiveFlag;
  });

  const handleClick = (e, key) => {
    console.log('handleClick', key)
    dispatch({
      type: types.SET_REPORTS_ACCORDIAN_ACTIVE,
      payload: {
        ...reportsAccordianActive,
        [key]: !reportsAccordianActive[key],
      },
    })
  }


  return (
    <React.Fragment>
      <div style={{
        height: "100%",
        flexDirection: "column",
      }}>
        <div>
          <Header activeMenu={t("REPORTS")} />
        </div>
        <div className='reports-scroll'>
          <ul className="accordion" style={{ flexWrap: "wrap" }}>
            {
              reportData.map(({ heading, content, key }) => {
                console.log('reportsAccordianActive', key, reportsAccordianActive)
                return (
                  temp[key] === "Y" && (
                    <AllReports
                      heading={t(heading)}
                      content={content}
                      objectKey={key}
                      isActive={reportsAccordianActive[key]}
                      handleClick={handleClick} />
                  )
                )
              })
            }
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Reports




