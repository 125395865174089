import React from "react";
import "./index.css";
const Tabs = ({ tabs, selected, onSelect, children: Comp }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {Object.keys(tabs).map((key) => {
        return (
          <div
            key={key}
            className={`header-tab ${
              key === selected ? "header-active-tab" : ""
            }`}
            onClick={() => onSelect(key)}
          >
            <Comp {...tabs[key]} />
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
