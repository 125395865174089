import React from "react";
import { useSelector } from "react-redux";
import { selectAuditorList } from "../../../../store/reducer/commonReducer";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import { PopupPropsContext } from "@progress/kendo-react-popup";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { filterBy } from "@progress/kendo-data-query";
import { FillHandleDiv } from "../ActionWorkOrder/fillHandleDiv";

function getPreviousMonDay(date) {
  date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
  return date;
}

function getTimeIntervalList() {
  let timeInterval = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      timeInterval.push(
        `${i.toString().length === 1 ? "0" + i : i}:${j === 0 ? `00` : 15 * j}`
      );
    }
  }
  return timeInterval;
}
function formatDateToString(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
}

export const CustomCellDropDown = (props) => {
  const dataItemKey = props.dataItemKey;
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  const selectedStateKeys = Object.keys(props.selectedState);
  const selectedItemsCount = selectedStateKeys.length;
  const selectedLastItemKey = selectedStateKeys[
    selectedItemsCount - 1
  ];
  var selectedLastItem =
    props.originalProps.dataItem[dataItemKey].toString === selectedLastItemKey
      ? props.originalProps.dataItem[cellField]
      : "";
  const additionalProps = {
    onDoubleClick: () => {
      props.enterEdit(dataItem, cellField);
    },
  };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };
  const isEdited = dataItem?.edited?.includes(cellField) ?? false;
  const value =
    cellField === inEditField ? props?.cell : props.td.props.children;
  let className = props.originalProps.isSelected ? "k-state-selected" : "";
  className += isEdited ? " editedCell" : "";


  return (
    <td
      data-grid-col-index={props.originalProps.columnIndex}
      colSpan={props.originalProps.colSpan}
      role={"gridcell"}
      aria-colindex={props.originalProps.ariaColumnIndex}
      aria-selected={props.originalProps.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
      {...clonedProps}
      className={className.trim()}
      style={{
        textAlign: "center",
        position: "relative",
      }}
      title={value?.toString()}
    >
      {value}
      {selectedItemsCount === 1 && value !== null ? (
        <FillHandleDiv
          className={[props.originalProps.isSelected ? "fillHandle" : ""]}
          handleDrag={props?.handleDrag}
        ></FillHandleDiv>
      ) : (selectedItemsCount > 1 &&
        value &&
        value === selectedLastItem ? (
        <FillHandleDiv
          handleDrag={props?.handleDrag}
          className={[props.originalProps.isSelected ? "fillHandle" : ""]}
        ></FillHandleDiv>
      ) : (
        <div></div>
      ))}
    </td>
  );
};

export const PlainInputCell = (props) => {
  const dataItem = props.dataItem;
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const remarksInputRef = React.useRef();
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: remarksInputRef.current.value,
      });
      setValue(e.target.value);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <Input
          type="text"
          ref={remarksInputRef}
          value={value}
          {...props.inputProps}
          // placeholder={"Max: 500 characters"}
          maxLength={500}
          onChange={handleChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const ResourceIDCell = (props) => {
  const dataItem = props.dataItem;
  const auditorDetails = useSelector(selectAuditorList);
  const field = "AuditorId";
  const dependentField = "PRIMARY_RESOURCE_NAME";
  const dataField = "AuditorId";
  const dependentDataField = "AuditorName";
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dataField],
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dependentDataField],
      });
    }
  };
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  const [data, setData] = React.useState(auditorDetails.slice());
  const filterData = (filter) => {
    const data = auditorDetails.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {

    setData(filterData(event.filter));
  };

  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox
          id="customCellComboBox"
          onChange={handleChange}
          value={auditorDetails?.find((c) => c?.[dataField] === dataValue)}
          // data={auditorDetails}
          data={data}
          textField={dataField}
          filterable={true}
          onFilterChange={filterChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const DatePickerCell = (props) => {
  const dataItem = props.dataItem;
  const cellField = props.field;
  //   const endDate =dataItem["EndDate"] === null ? new Date() : dataItem["EndDate"];
  const [value, setValue] = React.useState(new Date());
  //   const [value, setValue] = React.useState(new Date(endDate));
  //   const maxDate = new Date(endDate);
  //   const minDate = getPreviousMonDay(new Date(endDate));
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: formatDateToString(e.target.value),
      });
      setValue(e.target.value);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <DatePicker
          //   min={minDate}
          //   max={maxDate}
          value={value}
          onChange={handleChange}
          format="MM/dd/yyyy"
        />
      ) : (
        formatDateToString(value)
      )}
    </>
  );
};
export const TimeIntervalCell = (props) => {
  const dataItem = props.dataItem;
  const intervalList = getTimeIntervalList();
  const cellField = props.field;
  const dataValue = dataItem[cellField] === null ? "" : dataItem[cellField];
  const [value, setValue] = React.useState(dataValue);
  const handleChange = (e) => {
    let selectedValue = e.target.value ?? "";
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: selectedValue,
      });
      setValue(selectedValue);
    }
  };
  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox onChange={handleChange} value={value} data={intervalList} />
      ) : (
        value.toString()
      )}
    </>
  );
};
