import { forwardRef, useState, useImperativeHandle } from "react";
import { Form } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Window } from "@progress/kendo-react-dialogs";
import regionCountry from "../../../utils/regionCountry";
import "./index.css";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../utils/Notification";


const Capsule = ({ value, name, text, removeCapsuleFilter, index }) => {
  return (
    <>
      {value && text ? (
        <div
          className="capsuleFilter"
          title={name}
        // style={{
        //   backgroundColor: "#d8f3fc",
        //   padding: "4px 12px",
        //   borderRadius: 50,
        //   display: "flex",
        // }}
        >
          <div>{text}</div>
          <div
            className="k-icon k-i-close"
            style={{ paddingLeft: 2, cursor: "pointer" }}
            onClick={() => removeCapsuleFilter(index)}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const CapsuleContainer = () => {
  // const [capsules, setCapsules] = useState([]);
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        padding: 5,
        // borderRadius: 2,
        display: "flex",
        gap: 8,
      }}
    >
      <Capsule />
      <div
        title="Open Filter Window"
        style={{
          backgroundColor: "#d8f3fc",
          padding: "3px 6px",
          cursor: "pointer",
          borderRadius: 50,
        }}
      >
        View More
      </div>
    </div>
  );
};

export const SearchForm = ({ placeholder, onSubmitClick, onChange, ...props }) => {
  const { t } = useTranslation();
  const handleOnSubmitClick = (e) => {
    e.preventDefault();
    if (typeof onSubmitClick !== "function") {
      console.error("search form component onSubmitClick func missing");
      return;
    }
    onSubmitClick(e);
  };

  return (
    <form
      style={{
        display: "flex",
        gap: 15,
        alignItems: "center",
        position: "relative",
        width: "45%",
      }}
      onSubmit={handleOnSubmitClick}
    >
      <span
        className="k-icon k-i-search"
        style={{
          padding: 7,
          position: "absolute",
          left: 0,
          zIndex: 1,
          fontSize: 22,
        }}
      ></span>
      <Input
        {...props}
        name="searchWorkOrder"
        placeholder={placeholder}
        style={{
          paddingLeft: 35,
          backgroundColor: "#fff",
          width: "100%",
          border: "none",
        }}
      />
      <Button
        type={"submit"}
        primary={true}
      >
        {t("SEARCH")}
      </Button>
    </form>
    // <Form
    //   render={(formRenderProps) => (
    //     <FormElement
    //       style={{
    //         display: "flex",
    //         gap: 10,
    //         alignItems: "center",
    //         width: "40%",
    //       }}
    //     >
    //       <div
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //           position: "relative",
    //         }}
    //       >
    //         <span
    //           className="k-icon k-i-search"
    //           style={{
    //             padding: 15,
    //             position: "absolute",
    //             left: 0,
    //             zIndex: 99,
    //           }}
    //         />
    //         <Field
    //           name="sendMsg"
    //           component={FormInput}
    //           wrapperStyle={{ width: "60%", border: "none" }}
    //           style={{ paddingLeft: "40px!important" }}
    //           // label={localizationService.toLanguageString(
    //           //   "sampleID",
    //           //   "Sample ID"
    //           // )}
    //           // value={selectedFormData?.SampleID || ""}
    //           // data={sampleData}
    //           // onChange={(e) => handleMsgChange(e, formRenderProps)}
    //           // required={true}
    //           // validator={inputValidator}
    //           placeholder={placeholder}
    //         />
    //       </div>
    //       <Button
    //         type={"submit"}
    //         primary={true}
    //       >
    //         Search
    //       </Button>
    //     </FormElement>
    //   )}
    // />
  );
};

const TitleComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 7,
      }}
    >
      <span className="k-icon k-i-filter" /> Filter
    </div>
  );
};

export const SearchFilterComp = forwardRef(({
  onSubmitClick,
  selectedData,
  capsulesFilter,
  removeCapsuleFilter,
  windowProps,
  children,
}, ref) => {
  const [isFilterWindow, setFilterWindow] = useState(false);
  const { t, i18n } = useTranslation();

  useImperativeHandle(ref, () => ({
    setFilterWindow: (val) => setFilterWindow(val),
  }));

  const handleOnSubmitClick = (e) => {
    if (e.isValid) {
      onSubmitClick(e);
    } else {
      showToast("error", `${t("pleasefillthemandatoryfields")}`);
    }
  };

  return (
    <>
      {isFilterWindow && (
        <Window
          title={<TitleComponent />}
          onClose={() => setFilterWindow(false)}
          modal={true}
          {...windowProps}
        // initialTop={70}
        // initialWidth={1165}
        // initialHeight={366}
        >
          <Form
            ref={ref}
            onSubmitClick={handleOnSubmitClick}
            initialValues={selectedData}
            render={children}
          />
        </Window>
      )}
      <div>
        <Button
          className="k-button k-secondary"
          iconClass="k-icon k-i-filter"
          onClick={() => setFilterWindow(true)}
        >
          {t("filters")}
        </Button>
      </div>
      {/* <div
        style={{
          backgroundColor: "#f0f0f0",
          padding: 5,
          // borderRadius: 2,
          display: "flex",
          gap: 8,
          width: "100%",
          alignItems: "start",
          flexWrap: "wrap",
        }}
      > */}
      {capsulesFilter?.map((cd, i) => {
        return (
          <Capsule
            key={i}
            index={i}
            removeCapsuleFilter={removeCapsuleFilter}
            {...cd}
          />
        );
      })}

      <div
        title="Open Filter Window"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setFilterWindow(true);
        }}
      >
        <a href="javascript:void(0);" className="k-anchor">
          {t("viewmore_msglbl")}
        </a>
      </div>
      {/* </div> */}
    </>
  );
});

export const SearchFilterContainer = ({ children }) => {
  return (
    <div
      className="search-container"
    >
      {children}
    </div>
  );
};

export const SearchFilterFooter = ({ handleReset }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: 15, display: "flex", gap: 15 }}>
      <Button
        type={"submit"}
        primary={true}
        style={{ marginLeft: "auto", padding: "8px 30px" }}
      >
        {t("SEARCH")}
      </Button>
      <Button
        className="k-secondary"
        type={"reset"}
        onClick={handleReset}
        style={{ padding: "8px 30px" }}
      >
        {t("RESET")}
      </Button>
    </div>
  );
};
