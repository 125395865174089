import React from 'react';
import './Reports.css';
import outlineArrow from '../../assets/images/reports/outline-arrow.png';

const AllReports = ({ heading, content, objectKey, isActive, handleClick }) => { 
  return (
    <React.Fragment>
      <li className='accordian-item'>
        <div className="accordion-toggle" onClick={(e) => handleClick(e, objectKey)}>
          <h3>{heading}</h3>
          <span>{isActive ? <img style={{ transform: "rotate(180deg)" }} src={outlineArrow} alt="up" /> : <img src={outlineArrow} alt="down" />}</span>
        </div>
        {isActive && <div className="accordion-content">{content}</div>}
      </li>
    </React.Fragment>
  )
}

export default AllReports
