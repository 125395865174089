import * as types from "../types";
import { Get, Post, PostwithTimeoutHandler } from "../../methods";
import AppURL from "../../../utils/AppURL";
import searchPlan from "./searchPlan.json";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
});

const getHeadersPost = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  "Content-Type": "application/json"
});

export const getSearchPlan = async (params, authContext) => {
  const res = await Get(
    AppURL.searchPlan,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("search Plan", { res });
  // return searchPlan;

  return res;
};

export const getJobId = async (params, authContext) => {
  const res = await PostwithTimeoutHandler(
    AppURL.reportsAPI,
    params,
    getHeadersPost(authContext),
    true, 10000
  ).then((res) => res.json());

  return res;
};

export const getAuditorDetails = (params, authContext) => async (dispatch) => {
  try {
    const res = await Get(
      AppURL.fetchauditors,
      params,
      getHeaders(authContext),
      true
    ).then((res) => res.json());

    if (res?.AuditorDetails) {
      dispatch({
        type: types.GET_AUDITOR_DETAILS,
        payload: res.AuditorDetails,
      });
    }

    return res;
  } catch (e) {
    console.log("error", e);
  }
};

export const getSosDetailsApi = async (params, authContext) => {
  const res = await Post(
    AppURL.getSosDetails,
    params,
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());

  return res;
};

export const updateAutomaticUPCFlagApi = async (params, authContext) => {
  // export const updateAutomaticUPCFlagApi = async (query,params, authContext) => {
  const res = await Post(
    AppURL.updateautomaticupcflag,
    // AppURL.updateautomaticupcflag+query,
    params,
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());

  return res;
};
