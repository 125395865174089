import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";

const getHeaders = (authContext) => ({
  "Content-Type": "application/json",
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  // companyid:authContext?.CompanyID || ""
  // companyid: "381"
});
const getHeadersPost = (authContext) => ({
  "Content-Type": "application/json",
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  // companyid:authContext?.CompanyID || ""
  // companyid: "381"
});
export const getMails = (params, authContext) => (dispatch) => {
  Get(AppURL.getMailsUrl, params, getHeaders(authContext), true)
    .then((res) => res.json())
    .then((res) => {
      console.log(`getMails`, res);
      if (res) {
        dispatch({
          type: types.GET_MAILS_RESPONSE,
          payload: res?.GetMails,
        });
      }
    });
};

export const getMailsList = async (params, authContext) => {
  const res = await Get(
    AppURL.getMailsUrl,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};

export const getMailBodyContent = async (params, authContext) => {
  const res = await Get(
    AppURL.getMailBodyUrl,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};

export const updateMailStatus = async (params, authContext) => {
  const res = await Post(
    AppURL.updateMailStatusUrl,
    JSON.stringify(params),
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());
  return res;
};

export const getMailBody = (params, authContext) => async (dispatch) => {
  try {
    const res = await Get(
      AppURL.getMailBodyUrl,
      params,
      getHeaders(authContext),
      true
    ).then((res) => res.json());
    if (res?.root) {
      dispatch({
        type: types.GET_MAIL_BODY_RESPONSE,
        payload: res?.root,
      });
      return Promise.resolve(res?.root);
    } else {
      return Promise.reject(res);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getNewCountMessages = (params) => (dispatch) => {            
  dispatch({
    type: types.GET_MAIL_NEW_COUNT,
    payload: params,
  });
}
