import * as React from 'react';
import { useDraggable } from '@progress/kendo-react-common';

export const FillHandleDiv = ({handleDrag, handleDragStart, ...props}) => {
  const button = React.useRef(null);

  const handleDragEnd = React.useCallback(() => {
    console.log('handleDragEnd');
    setTimeout(() => {
      handleDrag();
    }, 100)
  }, []);

  useDraggable(button, {
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd,
  });

  return <div {...props} style={{
    zIndex: 10,
    position: 'absolute'
  }} ref={button}></div>;
};
