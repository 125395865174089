import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const validateTimeCheck = (timeData) => {
    const regEx = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
    if (timeData.match(regEx) || timeData === "") {
        return true;
    }
    return false;
}

export const MyPager = (props) => {
    const anchor = useRef(null);
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const toggleShow = () => {
        setShow(!show);
    };

    return (
        <div className="k-grid-pager" style={{ display: "flex", borderColor: '#CCCCCC', backgroundColor: '#F2F2F2' }}>
            {props.children}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderTop: "1px solid #CCCCCC",
                }}
            >
                <button
                    className="k-button k-secondary k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    style={{ margin: '5px' }}
                    onClick={toggleShow}
                    ref={anchor}
                >
                    {t("customizeviewlbl")}
                </button>

                <Popup
                    style={{ padding: "0px!important" }}
                    anchor={anchor.current}
                    show={show}
                >
                    <Button
                        onClick={() => {
                            props?.onSaveViewClick();
                            toggleShow();
                        }}
                    >
                        Save View
                    </Button>
                    <Button
                        onClick={() => {
                            props?.onDefaultViewClick();
                            toggleShow();
                        }}
                    >
                        Switch To Default View
                    </Button>
                </Popup>
            </div>
        </div>
    )
}