let regionCountry = [
  {
    RegionID: "2",
    RegionName: "EUROPE",
    CountryDetails: [
      {
        CountryID: "100",
        CountryName: "BULGARIA",
        LOBDetails: [
          {
            LOBID: "20",
            LOBName: "CI And I",
          },
          {
            LOBID: "22",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "112",
        CountryName: "BELARUS",
        LOBDetails: [
          {
            LOBID: "252",
            LOBName: "CI And I",
          },
          {
            LOBID: "253",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "191",
        CountryName: "CROATIA",
        LOBDetails: [
          {
            LOBID: "44",
            LOBName: "CI And I",
          },
          {
            LOBID: "48",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "196",
        CountryName: "CYPRUS",
        LOBDetails: [
          {
            LOBID: "321",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "203",
        CountryName: "CZECH REPUBLIC",
        LOBDetails: [
          {
            LOBID: "49",
            LOBName: "CI And I",
          },
          {
            LOBID: "53",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "208",
        CountryName: "DENMARK",
        LOBDetails: [
          {
            LOBID: "322",
            LOBName: "CI And I",
          },
          {
            LOBID: "326",
            LOBName: "RMS",
          },
          {
            LOBID: "1067",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "233",
        CountryName: "ESTONIA",
        LOBDetails: [
          {
            LOBID: "66",
            LOBName: "CI And I",
          },
          {
            LOBID: "68",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "246",
        CountryName: "FINLAND",
        LOBDetails: [
          {
            LOBID: "44745",
            LOBName: "RMS",
          },
          {
            LOBID: "44746",
            LOBName: "CPS",
          },
          {
            LOBID: "44747",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "250",
        CountryName: "FRANCE",
        LOBDetails: [
          {
            LOBID: "437",
            LOBName: "CI And I",
          },
          {
            LOBID: "438",
            LOBName: "RMS",
          },
          {
            LOBID: "439",
            LOBName: "CPS",
          },
          {
            LOBID: "1064",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "276",
        CountryName: "GERMANY",
        LOBDetails: [
          {
            LOBID: "445",
            LOBName: "CI And I",
          },
          {
            LOBID: "446",
            LOBName: "RMS",
          },
          {
            LOBID: "447",
            LOBName: "CPS",
          },
          {
            LOBID: "1071",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "300",
        CountryName: "GREECE",
        LOBDetails: [
          {
            LOBID: "72",
            LOBName: "RMS",
          },
          {
            LOBID: "73",
            LOBName: "CPS",
          },
          {
            LOBID: "69",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "31",
        CountryName: "AZERBAIJAN",
        LOBDetails: [
          {
            LOBID: "3568",
            LOBName: "RAM",
          },
          {
            LOBID: "3567",
            LOBName: "CPS",
          },
          {
            LOBID: "3566",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "3565",
            LOBName: "TAM",
          },
          {
            LOBID: "3564",
            LOBName: "iRAM",
          },
          {
            LOBID: "3563",
            LOBName: "CI And I",
          },
          {
            LOBID: "3562",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "348",
        CountryName: "HUNGARY",
        LOBDetails: [
          {
            LOBID: "84",
            LOBName: "CI And I",
          },
          {
            LOBID: "88",
            LOBName: "RMS",
          },
          {
            LOBID: "1422",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "372",
        CountryName: "IRELAND",
        LOBDetails: [
          {
            LOBID: "317",
            LOBName: "CI And I",
          },
          {
            LOBID: "320",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "380",
        CountryName: "ITALY",
        LOBDetails: [
          {
            LOBID: "455",
            LOBName: "CI And I",
          },
          {
            LOBID: "456",
            LOBName: "RMS",
          },
          {
            LOBID: "457",
            LOBName: "CPS",
          },
          {
            LOBID: "1070",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "398",
        CountryName: "KAZAKHSTAN",
        LOBDetails: [
          {
            LOBID: "102",
            LOBName: "CI And I",
          },
          {
            LOBID: "103",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "40",
        CountryName: "AUSTRIA",
        LOBDetails: [
          {
            LOBID: "10",
            LOBName: "RMS",
          },
          {
            LOBID: "6",
            LOBName: "CI And I",
          },
          {
            LOBID: "1068",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "428",
        CountryName: "LATVIA",
        LOBDetails: [
          {
            LOBID: "104",
            LOBName: "CI And I",
          },
          {
            LOBID: "106",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "440",
        CountryName: "LITHUANIA",
        LOBDetails: [
          {
            LOBID: "107",
            LOBName: "CI And I",
          },
          {
            LOBID: "109",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "499",
        CountryName: "MONTENEGRO",
        LOBDetails: [
          {
            LOBID: "119",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "528",
        CountryName: "NETHERLANDS",
        LOBDetails: [
          {
            LOBID: "452",
            LOBName: "CI And I",
          },
          {
            LOBID: "453",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "56",
        CountryName: "BELGIUM",
        LOBDetails: [
          {
            LOBID: "327",
            LOBName: "CI And I",
          },
          {
            LOBID: "330",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "578",
        CountryName: "NORWAY",
        LOBDetails: [
          {
            LOBID: "44743",
            LOBName: "RMS",
          },
          {
            LOBID: "1069",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "616",
        CountryName: "POLAND",
        LOBDetails: [
          {
            LOBID: "134",
            LOBName: "CI And I",
          },
          {
            LOBID: "138",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "620",
        CountryName: "PORTUGAL",
        LOBDetails: [
          {
            LOBID: "139",
            LOBName: "CI And I",
          },
          {
            LOBID: "143",
            LOBName: "RMS",
          },
          {
            LOBID: "144",
            LOBName: "CPS",
          },
          {
            LOBID: "1066",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "642",
        CountryName: "ROMANIA",
        LOBDetails: [
          {
            LOBID: "149",
            LOBName: "CI And I",
          },
          {
            LOBID: "153",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "643",
        CountryName: "RUSSIAN FEDERATION",
        LOBDetails: [
          {
            LOBID: "229",
            LOBName: "CI And I",
          },
          {
            LOBID: "233",
            LOBName: "RMS",
          },
          {
            LOBID: "44744",
            LOBName: "CPS",
          },
        ],
      },
      {
        CountryID: "688",
        CountryName: "SERBIA",
        LOBDetails: [
          {
            LOBID: "155",
            LOBName: "CI And I",
          },
          {
            LOBID: "157",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "70",
        CountryName: "BOSNIA AND HERZEGOVINA",
        LOBDetails: [
          {
            LOBID: "3642",
            LOBName: "RMS",
          },
          {
            LOBID: "3643",
            LOBName: "CI And I",
          },
          {
            LOBID: "3644",
            LOBName: "iRAM",
          },
          {
            LOBID: "3645",
            LOBName: "TAM",
          },
          {
            LOBID: "3646",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "3647",
            LOBName: "CPS",
          },
          {
            LOBID: "3648",
            LOBName: "RAM",
          },
        ],
      },
      {
        CountryID: "703",
        CountryName: "SLOVAKIA",
        LOBDetails: [
          {
            LOBID: "164",
            LOBName: "CI And I",
          },
          {
            LOBID: "168",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "705",
        CountryName: "SLOVENIA",
        LOBDetails: [
          {
            LOBID: "234",
            LOBName: "CI And I",
          },
          {
            LOBID: "236",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "724",
        CountryName: "SPAIN",
        LOBDetails: [
          {
            LOBID: "180",
            LOBName: "CI And I",
          },
          {
            LOBID: "184",
            LOBName: "RMS",
          },
          {
            LOBID: "185",
            LOBName: "CPS",
          },
          {
            LOBID: "1065",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "752",
        CountryName: "SWEDEN",
        LOBDetails: [
          {
            LOBID: "449",
            LOBName: "CI And I",
          },
          {
            LOBID: "450",
            LOBName: "RMS",
          },
          {
            LOBID: "1063",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "756",
        CountryName: "SWITZERLAND",
        LOBDetails: [
          {
            LOBID: "441",
            LOBName: "CI And I",
          },
          {
            LOBID: "442",
            LOBName: "RMS",
          },
          {
            LOBID: "443",
            LOBName: "CPS",
          },
          {
            LOBID: "1079",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "792",
        CountryName: "TURKEY",
        LOBDetails: [
          {
            LOBID: "200",
            LOBName: "CI And I",
          },
          {
            LOBID: "204",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "804",
        CountryName: "UKRAINE",
        LOBDetails: [
          {
            LOBID: "205",
            LOBName: "CI And I",
          },
          {
            LOBID: "208",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "826",
        CountryName: "UNITED KINGDOM",
        LOBDetails: [
          {
            LOBID: "245",
            LOBName: "CI And I",
          },
          {
            LOBID: "249",
            LOBName: "RMS",
          },
          {
            LOBID: "250",
            LOBName: "CPS",
          },
          {
            LOBID: "1073",
            LOBName: "Ecollection",
          },
        ],
      },
    ],
  },
  {
    RegionID: "8",
    RegionName: "GREATER INDIA",
    CountryDetails: [
      {
        CountryID: "1000",
        CountryName: "DUMMY_INDIA",
        LOBDetails: [
          {
            LOBID: "312",
            LOBName: "CI And I",
          },
          {
            LOBID: "316",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "144",
        CountryName: "SRI LANKA",
        LOBDetails: [
          {
            LOBID: "186",
            LOBName: "CI And I",
          },
          {
            LOBID: "188",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "356",
        CountryName: "INDIA",
        LOBDetails: [
          {
            LOBID: "89",
            LOBName: "CI And I",
          },
          {
            LOBID: "1413",
            LOBName: "RMS",
          },
          {
            LOBID: "1423",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "50",
        CountryName: "BANGLADESH",
        LOBDetails: [
          {
            LOBID: "11",
            LOBName: "CI And I",
          },
          {
            LOBID: "13",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "524",
        CountryName: "NEPAL",
        LOBDetails: [
          {
            LOBID: "331",
            LOBName: "CI And I",
          },
          {
            LOBID: "335",
            LOBName: "RMS",
          },
        ],
      },
    ],
  },
  {
    RegionID: "5",
    RegionName: "MENAP",
    CountryDetails: [
      {
        CountryID: "1003",
        CountryName: "IRAQ",
        LOBDetails: [
          {
            LOBID: "44755",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "12",
        CountryName: "ALGERIA",
        LOBDetails: [
          {
            LOBID: "276",
            LOBName: "CI And I",
          },
          {
            LOBID: "278",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "275",
        CountryName: "PALESTINE",
        LOBDetails: [
          {
            LOBID: "895",
            LOBName: "RAM",
          },
          {
            LOBID: "899",
            LOBName: "DAE",
          },
          {
            LOBID: "903",
            LOBName: "TAM",
          },
          {
            LOBID: "904",
            LOBName: "RMS",
          },
          {
            LOBID: "906",
            LOBName: "CPS",
          },
          {
            LOBID: "910",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "911",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "4",
        CountryName: "AFGHANISTAN",
        LOBDetails: [
          {
            LOBID: "874",
            LOBName: "RAM",
          },
          {
            LOBID: "878",
            LOBName: "DAE",
          },
          {
            LOBID: "882",
            LOBName: "TAM",
          },
          {
            LOBID: "883",
            LOBName: "RMS",
          },
          {
            LOBID: "885",
            LOBName: "CPS",
          },
          {
            LOBID: "889",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "890",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "400",
        CountryName: "JORDAN",
        LOBDetails: [
          {
            LOBID: "282",
            LOBName: "CI And I",
          },
          {
            LOBID: "284",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "414",
        CountryName: "KUWAIT",
        LOBDetails: [
          {
            LOBID: "306",
            LOBName: "CI And I",
          },
          {
            LOBID: "308",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "422",
        CountryName: "LEBANON",
        LOBDetails: [
          {
            LOBID: "279",
            LOBName: "CI And I",
          },
          {
            LOBID: "281",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "434",
        CountryName: "LIBYA",
        LOBDetails: [
          {
            LOBID: "916",
            LOBName: "RAM",
          },
          {
            LOBID: "920",
            LOBName: "DAE",
          },
          {
            LOBID: "924",
            LOBName: "TAM",
          },
          {
            LOBID: "925",
            LOBName: "RMS",
          },
          {
            LOBID: "927",
            LOBName: "CPS",
          },
          {
            LOBID: "931",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "932",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "48",
        CountryName: "BAHRAIN",
        LOBDetails: [
          {
            LOBID: "273",
            LOBName: "CI And I",
          },
          {
            LOBID: "275",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "504",
        CountryName: "MOROCCO",
        LOBDetails: [
          {
            LOBID: "294",
            LOBName: "CI And I",
          },
          {
            LOBID: "297",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "512",
        CountryName: "OMAN",
        LOBDetails: [
          {
            LOBID: "285",
            LOBName: "CI And I",
          },
          {
            LOBID: "287",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "586",
        CountryName: "PAKISTAN",
        LOBDetails: [
          {
            LOBID: "263",
            LOBName: "CI And I",
          },
          {
            LOBID: "267",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "634",
        CountryName: "QATAR",
        LOBDetails: [
          {
            LOBID: "288",
            LOBName: "CI And I",
          },
          {
            LOBID: "290",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "682",
        CountryName: "SAUDI ARABIA",
        LOBDetails: [
          {
            LOBID: "268",
            LOBName: "CI And I",
          },
          {
            LOBID: "272",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "784",
        CountryName: "UNITED ARAB EMIRATES",
        LOBDetails: [
          {
            LOBID: "237",
            LOBName: "CI And I",
          },
          {
            LOBID: "239",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "788",
        CountryName: "TUNISIA",
        LOBDetails: [
          {
            LOBID: "291",
            LOBName: "CI And I",
          },
          {
            LOBID: "293",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "818",
        CountryName: "EGYPT",
        LOBDetails: [
          {
            LOBID: "60",
            LOBName: "CI And I",
          },
          {
            LOBID: "64",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "887",
        CountryName: "YEMEN",
        LOBDetails: [
          {
            LOBID: "937",
            LOBName: "RAM",
          },
          {
            LOBID: "941",
            LOBName: "DAE",
          },
          {
            LOBID: "945",
            LOBName: "TAM",
          },
          {
            LOBID: "946",
            LOBName: "RMS",
          },
          {
            LOBID: "948",
            LOBName: "CPS",
          },
          {
            LOBID: "952",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "953",
            LOBName: "CI And I",
          },
        ],
      },
    ],
  },
  {
    RegionID: "6",
    RegionName: "SA & SSA",
    CountryDetails: [
      {
        CountryID: "1004",
        CountryName: "BURKINA FASO",
        LOBDetails: [
          {
            LOBID: "44756",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "1005",
        CountryName: "CONGO REPUBLIC",
        LOBDetails: [
          {
            LOBID: "44760",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "1006",
        CountryName: "CHAD",
        LOBDetails: [
          {
            LOBID: "44761",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "1007",
        CountryName: "GUINEA",
        LOBDetails: [
          {
            LOBID: "44762",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "108",
        CountryName: "BURUNDI",
        LOBDetails: [
          {
            LOBID: "44757",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "120",
        CountryName: "CAMEROON",
        LOBDetails: [
          {
            LOBID: "304",
            LOBName: "CI And I",
          },
          {
            LOBID: "305",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "140",
        CountryName: "CENTRAL AFRICAN REPUBLIC",
        LOBDetails: [
          {
            LOBID: "44758",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "288",
        CountryName: "GHANA",
        LOBDetails: [
          {
            LOBID: "260",
            LOBName: "CI And I",
          },
          {
            LOBID: "261",
            LOBName: "RMS",
          },
          {
            LOBID: "262",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "384",
        CountryName: "IVORY COAST",
        LOBDetails: [
          {
            LOBID: "309",
            LOBName: "CI And I",
          },
          {
            LOBID: "311",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "404",
        CountryName: "KENYA",
        LOBDetails: [
          {
            LOBID: "301",
            LOBName: "CI And I",
          },
          {
            LOBID: "303",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "566",
        CountryName: "NIGERIA",
        LOBDetails: [
          {
            LOBID: "255",
            LOBName: "RMS",
          },
          {
            LOBID: "256",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "710",
        CountryName: "SOUTH AFRICA",
        LOBDetails: [
          {
            LOBID: "169",
            LOBName: "CI And I",
          },
          {
            LOBID: "172",
            LOBName: "RMS",
          },
          {
            LOBID: "173",
            LOBName: "CPS",
          },
        ],
      },
      {
        CountryID: "800",
        CountryName: "UGANDA",
        LOBDetails: [
          {
            LOBID: "298",
            LOBName: "CI And I",
          },
          {
            LOBID: "300",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "834",
        CountryName: "TANZANIA",
        LOBDetails: [
          {
            LOBID: "258",
            LOBName: "RMS",
          },
          {
            LOBID: "259",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "895",
        CountryName: "GABON",
        LOBDetails: [
          {
            LOBID: "3664",
            LOBName: "RMS",
          },
        ],
      },
    ],
  },
  {
    RegionID: "7",
    RegionName: "SEANAP",
    CountryDetails: [
      {
        CountryID: "104",
        CountryName: "MYANMAR",
        LOBDetails: [
          {
            LOBID: "3350",
            LOBName: "RMS",
          },
          {
            LOBID: "781",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "36",
        CountryName: "AUSTRALIA",
        LOBDetails: [
          {
            LOBID: "44754",
            LOBName: "CPS",
          },
          {
            LOBID: "336",
            LOBName: "RMS",
          },
          {
            LOBID: "340",
            LOBName: "CI And I",
          },
          {
            LOBID: "1072",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "360",
        CountryName: "INDONESIA",
        LOBDetails: [
          {
            LOBID: "96",
            LOBName: "RMS",
          },
          {
            LOBID: "97",
            LOBName: "CPS",
          },
          {
            LOBID: "240",
            LOBName: "CI And I",
          },
          {
            LOBID: "241",
            LOBName: "DAE",
          },
          {
            LOBID: "99",
            LOBName: "Ecollection",
          },
          {
            LOBID: "1424",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "392",
        CountryName: "JAPAN",
        LOBDetails: [
          {
            LOBID: "101",
            LOBName: "RMS",
          },
          {
            LOBID: "242",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "410",
        CountryName: "SOUTH KOREA",
        LOBDetails: [
          {
            LOBID: "174",
            LOBName: "CI And I",
          },
          {
            LOBID: "178",
            LOBName: "RMS",
          },
          {
            LOBID: "179",
            LOBName: "CPS",
          },
          {
            LOBID: "44753",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "458",
        CountryName: "MALAYSIA",
        LOBDetails: [
          {
            LOBID: "113",
            LOBName: "RMS",
          },
          {
            LOBID: "295",
            LOBName: "CI And I",
          },
        ],
      },
      {
        CountryID: "554",
        CountryName: "NEW ZEALAND",
        LOBDetails: [
          {
            LOBID: "120",
            LOBName: "CI And I",
          },
          {
            LOBID: "124",
            LOBName: "RMS",
          },
          {
            LOBID: "125",
            LOBName: "CPS",
          },
        ],
      },
      {
        CountryID: "608",
        CountryName: "PHILIPPINES",
        LOBDetails: [
          {
            LOBID: "128",
            LOBName: "CI And I",
          },
          {
            LOBID: "132",
            LOBName: "RMS",
          },
          {
            LOBID: "133",
            LOBName: "CPS",
          },
        ],
      },
      {
        CountryID: "702",
        CountryName: "SINGAPORE",
        LOBDetails: [
          {
            LOBID: "158",
            LOBName: "CI And I",
          },
          {
            LOBID: "162",
            LOBName: "RMS",
          },
          {
            LOBID: "163",
            LOBName: "CPS",
          },
        ],
      },
      {
        CountryID: "704",
        CountryName: "VIETNAM",
        LOBDetails: [
          {
            LOBID: "224",
            LOBName: "CI And I",
          },
          {
            LOBID: "228",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "764",
        CountryName: "THAILAND",
        LOBDetails: [
          {
            LOBID: "194",
            LOBName: "CI And I",
          },
          {
            LOBID: "198",
            LOBName: "RMS",
          },
          {
            LOBID: "199",
            LOBName: "CPS",
          },
          {
            LOBID: "98",
            LOBName: "Ecollection",
          },
        ],
      },
    ],
  },
  {
    RegionID: "1",
    RegionName: "NORTH AMERICA",
    CountryDetails: [
      {
        CountryID: "124",
        CountryName: "CANADA",
        LOBDetails: [
          {
            LOBID: "754",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "433",
            LOBName: "CI And I",
          },
          {
            LOBID: "434",
            LOBName: "RMS",
          },
          {
            LOBID: "435",
            LOBName: "CPS",
          },
          {
            LOBID: "44750",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "840",
        CountryName: "UNITED STATES",
        LOBDetails: [
          {
            LOBID: "752",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "44728",
            LOBName: "TAM",
          },
          {
            LOBID: "44729",
            LOBName: "RAM",
          },
          {
            LOBID: "209",
            LOBName: "CI And I",
          },
          {
            LOBID: "212",
            LOBName: "RMS",
          },
          {
            LOBID: "214",
            LOBName: "CPS",
          },
          {
            LOBID: "44751",
            LOBName: "Ecollection",
          },
        ],
      },
    ],
  },
  {
    RegionID: "4",
    RegionName: "LATAM",
    CountryDetails: [
      {
        CountryID: "152",
        CountryName: "CHILE",
        LOBDetails: [
          {
            LOBID: "23",
            LOBName: "CI And I",
          },
          {
            LOBID: "27",
            LOBName: "RMS",
          },
          {
            LOBID: "28",
            LOBName: "CPS",
          },
          {
            LOBID: "44752",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "170",
        CountryName: "COLOMBIA",
        LOBDetails: [
          {
            LOBID: "35",
            LOBName: "CI And I",
          },
          {
            LOBID: "39",
            LOBName: "RMS",
          },
          {
            LOBID: "40",
            LOBName: "CPS",
          },
          {
            LOBID: "1075",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "188",
        CountryName: "COSTA RICA",
        LOBDetails: [
          {
            LOBID: "41",
            LOBName: "CI And I",
          },
          {
            LOBID: "43",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "214",
        CountryName: "DOMINICAN REPUBLIC",
        LOBDetails: [
          {
            LOBID: "54",
            LOBName: "CI And I",
          },
          {
            LOBID: "56",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "218",
        CountryName: "ECUADOR",
        LOBDetails: [
          {
            LOBID: "57",
            LOBName: "CI And I",
          },
          {
            LOBID: "59",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "222",
        CountryName: "EL SALVADOR",
        LOBDetails: [
          {
            LOBID: "65",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "32",
        CountryName: "ARGENTINA",
        LOBDetails: [
          {
            LOBID: "1",
            LOBName: "CI And I",
          },
          {
            LOBID: "5",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "320",
        CountryName: "GUATEMALA",
        LOBDetails: [
          {
            LOBID: "74",
            LOBName: "CI And I",
          },
          {
            LOBID: "76",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "340",
        CountryName: "HONDURAS",
        LOBDetails: [
          {
            LOBID: "77",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "484",
        CountryName: "MEXICO",
        LOBDetails: [
          {
            LOBID: "753",
            LOBName: "CAUSAL",
          },
          {
            LOBID: "1966",
            LOBName: "RMS",
          },
          {
            LOBID: "114",
            LOBName: "CI And I",
          },
          {
            LOBID: "118",
            LOBName: "CPS",
          },
        ],
      },
      {
        CountryID: "558",
        CountryName: "NICARAGUA",
        LOBDetails: [
          {
            LOBID: "126",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "591",
        CountryName: "PANAMA",
        LOBDetails: [
          {
            LOBID: "127",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "600",
        CountryName: "PARAGUAY",
        LOBDetails: [
          {
            LOBID: "346",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "604",
        CountryName: "PERU",
        LOBDetails: [
          {
            LOBID: "243",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "630",
        CountryName: "PUERTO RICO",
        LOBDetails: [
          {
            LOBID: "145",
            LOBName: "CI And I",
          },
          {
            LOBID: "147",
            LOBName: "RMS",
          },
          {
            LOBID: "148",
            LOBName: "CPS",
          },
        ],
      },
      {
        CountryID: "68",
        CountryName: "BOLIVIA",
        LOBDetails: [
          {
            LOBID: "345",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "76",
        CountryName: "BRAZIL",
        LOBDetails: [
          {
            LOBID: "19",
            LOBName: "RMS",
          },
          {
            LOBID: "14",
            LOBName: "CI And I",
          },
          {
            LOBID: "18",
            LOBName: "CPS",
          },
          {
            LOBID: "1076",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "858",
        CountryName: "URUGUAY",
        LOBDetails: [
          {
            LOBID: "216",
            LOBName: "CI And I",
          },
          {
            LOBID: "218",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "862",
        CountryName: "VENEZUELA",
        LOBDetails: [
          {
            LOBID: "219",
            LOBName: "CI And I",
          },
          {
            LOBID: "223",
            LOBName: "RMS",
          },
          {
            LOBID: "1078",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "999",
        CountryName: "Nielsen Country",
        LOBDetails: [
          {
            LOBID: "44730",
            LOBName: "RMS",
          },
        ],
      },
    ],
  },
  {
    RegionID: "3",
    RegionName: "GREATER CHINA",
    CountryDetails: [
      {
        CountryID: "156",
        CountryName: "CHINA",
        LOBDetails: [
          {
            LOBID: "29",
            LOBName: "CI And I",
          },
          {
            LOBID: "33",
            LOBName: "RMS",
          },
          {
            LOBID: "34",
            LOBName: "CPS",
          },
          {
            LOBID: "1077",
            LOBName: "Ecollection",
          },
        ],
      },
      {
        CountryID: "158",
        CountryName: "TAIWAN",
        LOBDetails: [
          {
            LOBID: "189",
            LOBName: "CI And I",
          },
          {
            LOBID: "193",
            LOBName: "RMS",
          },
        ],
      },
      {
        CountryID: "344",
        CountryName: "HONG KONG",
        LOBDetails: [
          {
            LOBID: "78",
            LOBName: "CI And I",
          },
          {
            LOBID: "82",
            LOBName: "RMS",
          },
          {
            LOBID: "83",
            LOBName: "CPS",
          },
          {
            LOBID: "3470",
            LOBName: "Ecollection",
          },
        ],
      },
    ],
  },
];

export default regionCountry;
