import React from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
} from "@progress/kendo-react-charts";
import { ComboBox } from "@progress/kendo-react-dropdowns";

const BarGraphData = () => {
  const perfTrendsData = [
    "%Found",
    "%Returned Not Found",
    "%Returned Not Product",
    "%Not Returned",
  ];

  const categories = [2002, 2003, 2004];

  const series = [
    {
      name: "Found Percentage",
      data: [3.907, 7.943, 7.848],
    },
    {
      name: "Returned Not Found Percentage",
      data: [4.743, 7.295, 7.175],
    },
    {
      name: "Returned Non Product Percentage",
      data: [0.21, 0.375, 1.161],
    },
    {
      name: "Not Returned Percentage",
      data: [1.988, 2.733, 3.994],
    },
  ];
  const [firstSeries, secondSeries] = [[1, 2, 3, 5]];
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#000",
          padding: "0px 5px",
        }}
      >
        <div style={{ height: 50 }}>
          <h3 style={{ color: "#fff" }}>Performance Trends</h3>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ComboBox
            style={{
              width: "300px",
              zIndex: 1,
            }}
            data={perfTrendsData}
            defaultValue="%Found"
          />
        </div>
      </div>
      <Chart
        style={{
          height: 350,
        }}
      >
        <ChartTitle text="Performance Trends" />
        <ChartLegend position="right" orientation="vertical" />
        <ChartValueAxis>
          <ChartValueAxisItem
            title={{
              text: "Performance Trends",
            }}
            min={0}
            max={100}
          />
        </ChartValueAxis>

        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} startAngle={45} />
        </ChartCategoryAxis>
        <ChartSeries>
          {series.map((item, idx) => (
            <ChartSeriesItem
              key={idx}
              type="column"
              tooltip={{
                visible: true,
              }}
              data={item.data}
              name={item.name}
            />
          ))}
        </ChartSeries>
      </Chart>
    </React.Fragment>
  );
};

export default BarGraphData;
