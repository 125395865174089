import { Get, Post, PostwithTimeoutHandler } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";


export const getAuditSummaryReport = async (params, authContext) => {

    const header = {
        OrganizationUnit: authContext?.organizationUnit || "",
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "Content-Type": "application/json",
        // "content-type": "application/x-www-form-urlencoded; application/json",
    }
 

    const res = await PostwithTimeoutHandler(AppURL.reportsAPI , params, header, true,10000).then((res) =>
        res.json()
    );
    return res;
    // return MOBILEVERSIONRESOSPONSE;
};