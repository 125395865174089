
import { App } from "../../../App";
import AppURL from "../../../utils/AppURL";
import { Get, Post, Put } from "../../methods";
import * as type from "../types";


const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  CompanyID: authContext?.CompanyID,
  // companyid:authContext?.CompanyID || ""
  // companyid: "381",
});
export const getApiRegistrationClick = async (params, authContext) => {
  const res = await Get(
    AppURL.onRegistrationClick,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("getApi", { res });  
  return res;
};




 
