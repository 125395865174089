import { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../header";
import ActionButtonList from "./actionButtonList";
import MessageDetail from "./messageDetail";
import Tabs from "../ui/tabs";
import "./index.css";
import { updateMailStatus } from "../../store/actions/messages";
import { showToast } from "../../utils/Notification";
import {
  MAIL_STATUS_NEW,
  MAIL_STATUS_READ,
  MAIL_STATUS_DELETED,
  tabsObj,
} from "./notificationsUtils";
import { useTranslation } from "react-i18next";
import useLogout, { checkInvalidSession } from "../../utils/useLogout";

const Notifications = ({ activeMenu }) => {
  const { t, i18n } = useTranslation();
  const {handleAuthContext} = useLogout();
  const authContext = useSelector((state) => state.commonReducer.authContext);

  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [activeScreen, setActiveScreen] = useState("inbox");
  const [mailDetail, setMailDetail] = useState(null);
  const Comp = tabsObj[activeScreen]?.comp;

  const updateMailFlag = async (uniqueIdList, status) => {
    var updateMailParams = {
      root: { Mails: [] },
    };
    uniqueIdList.map((element) => {
      updateMailParams.root.Mails.push({
        CompanyId: authContext.CompanyID,
        UniqueId: element,
        Status: status,
        CountryId: "",
      });
    });
    const res = await updateMailStatus(updateMailParams, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.root?.Status !== "Success") {
      console.log("search plan api error", res);
    }
    setShouldRefresh(true);
  };

  const messageSelectedCheck = () => {
    if (Object.keys(selectedState).length > 0) return true;
    else {
      showToast("error", "Please select a message");
      return false;
    }
  };

  const markAsReadAction = () => {
    if (messageSelectedCheck())
      updateMailFlag(Object.keys(selectedState), MAIL_STATUS_READ);
  };
  const markAsUnReadAction = () => {
    if (messageSelectedCheck())
      updateMailFlag(Object.keys(selectedState), MAIL_STATUS_NEW);
  };
  const deleteAction = () => {
    if (messageSelectedCheck())
      updateMailFlag(Object.keys(selectedState), MAIL_STATUS_DELETED);
  };

  return (
    <div style={{ flex: "1 1 auto" }}>
      <Header activeMenu={t("messages")} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "lightgray",
          gap: 3,
        }}
      >
        <div style={{ maxWidth: "45%", backgroundColor: "#F2F2F2" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              tabs={tabsObj}
              selected={activeScreen}
              onSelect={(key) => setActiveScreen(key)}
            >
              {({ title }) => {
                return <div>{t(title)}</div>;
              }}
            </Tabs>
            <ActionButtonList
              activeScreen={activeScreen}
              markAsReadAction={markAsReadAction}
              markAsUnReadAction={markAsUnReadAction}
              deleteAction={deleteAction}
            />
          </div>
          {
            <Comp
              setActiveScreen={setActiveScreen}
              activeScreen={activeScreen}
              setMailDetail={setMailDetail}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              shouldRefresh={shouldRefresh}
              setShouldRefresh={setShouldRefresh}
              updateMailFlag={updateMailFlag}
            />
          }
        </div>
        <div
          className="message-content"
          style={{ backgroundColor: "#fff", width: "55%", position: 'relative' }}
        >
          <MessageDetail mailDetail={mailDetail} />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
