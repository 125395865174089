import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";
import { checkInvalidSession } from "../../../utils/useLogout";
import { showToast } from "../../../utils/Notification";
import { toast } from "react-toastify";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.email || "",
});

// export const login = (params) => (dispatch) => {
//   let headers = {
//     "X-Requested-With": "XMLHttpRequest",
//   };
//   Post(AppURL.login, JSON.stringify(params), headers, true)
//     .then((res) => res.json())
//     .then((res) => {
//       if (res?.Response?.Status === 'SUCCESS' && res?.Response?.Message === 'Login successful') {
//         localStorage.setItem('coveragescreen_required', res?.Response?.CompanyDetails?.coveragescreen_required);
//         dispatch({
//           type: types.SET_AUTH_CONTEXT,
//           payload: res.Response["Spoc-Details"],
//         })
//       } else {
//         dispatch({
//           type: types.LOGIN_ERROR,
//           payload: res.Response.Message,
//         });
//       }
//     });
// };
export const loginAsync = async (params) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  };
  const res = await Post(
    AppURL.login,
    JSON.stringify(params),
    headers,
    true
  ).then((res) => res.json());
  return res;
};

export const forgotPassword = (params) => (dispatch) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  };
  Post(AppURL.forgotPasswordURL, JSON.stringify(params), headers, true)
    .then((res) => res.json())
    .then((res) => {
      if (res?.Response?.Status === "SUCCESS") {
        dispatch({
          type: types.FORGOT_PASSWORD_QUS,
          payload: res.Response.SecQus,
        });
      } else {
        dispatch({
          type: types.FORGOT_PASSWORD_ERROR,
          payload: res.Response.Message,
        });
      }
      // console.log(res);
    });
};
export const forgotPasswordValidation = (params) => (dispatch) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  };
  Post(
    AppURL.forgotPasswordValidationURL,
    JSON.stringify(params),
    headers,
    true
  )
    .then((res) => res.json())
    .then((res) => {
      // if (res?.Response?.Status === 'SUCCESS') {
      dispatch({
        type: types.FORGOT_PASSWORD_VALIDATION_RESPONSE,
        payload: res?.Response,
      });
      // }
      // if(res?.Response?.Status === 'FAILURE') {
      //   dispatch({
      //     type: types.FORGOT_PASSWORD_VALIDATION_ERROR,
      //     payload: res.Response.Message,
      //   });
      // }
      console.log(res);
    });
};
export const resetPassword = (params) => (dispatch) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  };
  Post(AppURL.resetPasswordURL, JSON.stringify(params), headers, true)
    .then((res) => res.json())
    .then((res) => {
      // if (res?.Response?.Status === 'SUCCESS') {
      dispatch({
        type: types.RESET_PASSWORD,
        payload: res?.Response,
      });
      // }
      // if(res?.Response?.Status === 'FAILURE') {
      //   dispatch({
      //     type: types.FORGOT_PASSWORD_VALIDATION_ERROR,
      //     payload: res.Response.Message,
      //   });
      // }
      console.log(res);
    });
};
// export const validateSession = (params) => (dispatch) => {
//   let headers = {
//     "X-Requested-With": "XMLHttpRequest",
//   };
//   Get(AppURL.validateSessionURL, params, headers, true)
//     .then((res) => res.json())
//     .then((res) => {
//       dispatch({
//         type: types.VALIDATE_SESSION_RESPONSE,
//         payload: res
//       })
//       console.log(res);
//     });
// };
export const validateSessionAsync = async (params) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
  };
  const res = await Get(AppURL.validateSessionURL, params, headers, true).then(
    (res) => res.json()
  );
  return res;
};
export const getcaptchaAsync = async (params) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
  };
  const res = await Get(AppURL.getcaptcha, params, headers, true).then((res) =>
    res.json()
  );
  return res;
};
export const lang = (params) => async (dispatch) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
  };
  const res = await Get(AppURL.languageURL, params, headers, true).then((res) =>
    res.json()
  );
  if (checkInvalidSession(res)) {
    dispatch({
      type: types.SET_AUTH_CONTEXT,
      payload: null,
    });
    localStorage.removeItem("spocDetails");
    showToast("error", "Session expired", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  if (
    res?.SearchLanguageOp?.Status?.StatusMsg === "Success" &&
    res.SearchLanguageOp?.LanguageDetails
  ) {
    dispatch({
      type: types.SET_LANGUAGES,
      payload: res?.SearchLanguageOp.LanguageDetails,
    });
  } else {
    console.log("lang error", res);
  }
};