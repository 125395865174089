import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";
import bulkUpload from "./bulkUpload.json";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  CompanyID: authContext?.CompanyID,
  // companyid:authContext?.CompanyID || ""
  // companyid: "381",
});
export const getBulkPlanUpload = async (params, authContext) => {
  const res = await Get(
    AppURL.planUpload,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("search Plan", { res });
  // return bulkUpload;
  return res;
};
export const postPlanUpload = async (params, authContext) => {
  const headers = {
    OrganizationUnit: authContext?.organizationUnit || "",
    userid: authContext?.SpocMail || "",
    CompanyID: authContext?.CompanyID,
    // companyid:authContext?.CompanyID || ""
    // companyid: "381",
    "Content-Type": "multipart/form-data",
    Accept:
      "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
  };
  // const res = await fetch(AppURL.planUpload+"?"+params, {
  //   method: "POST",
  //   // body: formData,
  // })
  //   .then((response) => response.json())
  //   .catch((error) => {
  //     console.error(error);
  //   });

  const res = await Post(
    AppURL.planUpload+"?"+params,
    null,
    headers,
    true
  ).then((res) => res.json());
  console.log("search Plan", { res });
  // return bulkUpload;
  return res;
};
export const planDownloadFile = async (params, authContext) => {
  const headers = {
    OrganizationUnit: authContext?.organizationUnit || "",
    userid: authContext?.SpocMail || "",
    "Content-Type": "application/x-www-form-urlencoded; application/json",
  };
  const res = await Get(AppURL.planDownloaderfile, params, headers, true);
  console.log("file download in planupload", { res });
  // return searchPlan;
  return res;
};
