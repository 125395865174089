import { SvgIcon } from '@progress/kendo-react-common';

const IqctIcon = (props) => {
    return (
        <SvgIcon  style={props?.style} width={props?.width} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M81.6261 33.336L82.1503 15.1359L77.4703 15.0011L77.0996 27.8723L63.1509 27.4705L62.997 32.8128L81.1972 33.337L81.1976 33.3237L81.6261 33.336Z" fill={props?.color ?? "#4F4F4F"}/>
        <path d="M83.7542 52.4626C82.3616 61.4781 77.8153 69.707 70.9245 75.6849C64.0337 81.6628 55.2453 85.0017 46.1236 85.1076C37.0018 85.2135 28.1383 82.0794 21.1106 76.263C14.0829 70.4467 9.34685 62.3255 7.74537 53.3448C6.14389 44.3641 7.78085 35.1064 12.3649 27.2194C16.949 19.3325 24.1828 13.3278 32.7789 10.2741C41.3751 7.22048 50.7758 7.3159 59.3082 10.5434C67.8406 13.7709 74.951 19.9212 79.374 27.8996L75.6752 29.9501C71.7377 22.8475 65.4077 17.3723 57.8119 14.499C50.2161 11.6258 41.8472 11.5408 34.1946 14.2593C26.542 16.9778 20.1022 22.3234 16.0213 29.3446C11.9404 36.3659 10.4831 44.6074 11.9088 52.6024C13.3345 60.5973 17.5507 67.8271 23.807 73.005C30.0634 78.1829 37.9539 80.973 46.0745 80.8787C54.195 80.7845 62.0187 77.812 68.1532 72.4903C74.2876 67.1686 78.3349 59.8429 79.5746 51.817L83.7542 52.4626Z" fill={props?.color ?? "#4F4F4F"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.4794 47.9621C54.5127 45.9008 57.2739 41.7053 57.2739 36.8649C57.2739 29.9863 51.6977 24.4102 44.8191 24.4102C37.9406 24.4102 32.3644 29.9863 32.3644 36.8649C32.3644 41.7053 35.1256 45.9008 39.1589 47.9621C38.7296 48.1033 38.3052 48.2607 37.8864 48.4342C35.6885 49.3446 33.6914 50.679 32.0092 52.3612C30.327 54.0435 28.9925 56.0406 28.0821 58.2385C27.1717 60.4364 26.7031 62.7922 26.7031 65.1712H30.8115C30.8115 63.3317 31.1738 61.5102 31.8778 59.8107C32.5817 58.1112 33.6135 56.567 34.9142 55.2663C36.215 53.9656 37.7592 52.9338 39.4587 52.2298C41.1581 51.5259 42.9796 51.1636 44.8191 51.1636C46.6587 51.1636 48.4802 51.5259 50.1796 52.2298C51.8791 52.9338 53.4233 53.9656 54.724 55.2663C56.0248 56.567 57.0566 58.1112 57.7605 59.8107C58.4645 61.5102 58.8268 63.3317 58.8268 65.1712H62.9352C62.9352 62.7922 62.4666 60.4364 61.5562 58.2385C60.6458 56.0406 59.3113 54.0435 57.6291 52.3612C55.9469 50.679 53.9498 49.3446 51.7518 48.4342C51.3331 48.2607 50.9087 48.1033 50.4794 47.9621ZM44.8191 45.5647C40.0144 45.5647 36.1194 41.6697 36.1194 36.8649C36.1194 32.0602 40.0144 28.1652 44.8191 28.1652C49.6239 28.1652 53.5189 32.0602 53.5189 36.8649C53.5189 41.6697 49.6239 45.5647 44.8191 45.5647Z" fill={props?.color ?? "#4F4F4F"}/>
        </SvgIcon>
        
    )
}

export default IqctIcon