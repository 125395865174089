import Ecollection from "./eCollection";
import DCT from "./DCT";
import Miscellaneous from "./Miscellaneous";
import NSO from "./NSO";
import GNIC from "./GNIC";
import RA from "./RA";

export const reportData = [
  {
    heading: "ecollectionsreports",
    content: <Ecollection />,
    key: "ECOLLECTION"
  },
  {
    heading: 'NIQPics Reports',
    content: <GNIC />,
    key: "GNIC"
  },
  {
    heading: 'dctreports',
    content: <DCT />,
    key: "DCT"
  },
  {
    heading: 'NSO Reports',
    content: <NSO />,
    key: "NSO"
  },
  {
    heading: 'RA Reports',
    content: <RA />,
    key: "RA"
  },
  {
    heading: 'MISC_LBL',
    content: <Miscellaneous />,
    key: "MISCELLANEOUS"
  },

];