import React from 'react';
// import './Reports.css';
import '../reports/Reports.css'
import outlineArrow from '../../assets/images/reports/outline-arrow.png';

const ApiRegister = ({ heading, content }) => {
    const [isActive, setIsActive] = React.useState(false);
  return (
    <React.Fragment>
        
            <li className='accordian-item'>
                <div className="accordion-toggle" onClick={() => setIsActive(!isActive)}>
                <h3>{heading}</h3>
                <span>{isActive ? <img style={{transform: "rotate(90deg)"}} src={outlineArrow} alt="down" /> : <img style={{transform: "rotate(-90deg)"}} src={outlineArrow} alt="up"/>}</span>               

                </div>
                {isActive && <div className="accordion-content">{content}</div>}
            </li>
        
      
    </React.Fragment>
  )
}

export default ApiRegister
