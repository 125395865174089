import { useEffect, useState, useMemo, useRef } from "react";
import {
  SearchFilterComp,
  SearchFilterContainer,
  SearchFilterFooter,
  SearchForm,
} from "../../ui/SearchFilter";
import { Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  FormComboBox,
  FormInput,
  FormMultiSelect,
} from "../../ui/form/form-component";
import { useSelector } from "react-redux";
import LoadMask from "../../../utils/LoadMask";
import { showToast } from "../../../utils/Notification";
import { useLocalization } from "@progress/kendo-react-intl";
import { toast } from "react-toastify";
import { getAuditorNew } from "../../../store/actions/people/resources";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";
import { useTranslation } from "react-i18next";
import { inputValidator } from "../../form/validator";
import { capsuleToParams } from "../../../utils/Utility";

const getInitialSelectedData = (authContext, store) => {
  const countryIdArr = authContext?.CountryCode?.split(",");
  const countryId = countryIdArr?.[0];
  const selectedRegion = store.find((regionData) => {
    const countryArr = regionData?.CountryDetails;
    return countryArr.some((ca) => ca?.CountryID == countryId);
  });
  console.log({ selectedRegion });
  // locationData
  const CountryArr = selectedRegion?.CountryDetails?.filter((ca) => {
    return countryIdArr?.includes(ca?.CountryID);
  });
  console.log({ CountryArr });
  return {
    Region: selectedRegion,
    Country: CountryArr || [],
  };
};
const initialFilter = [];

const SearchFilterWindow = ({
  setResult,
  shouldRefresh,
  setShouldRefresh,
  dataState,
  setDataState,
  setSearthTerm,
  getOneResourceId
}) => {
  const { t, i18n } = useTranslation();
  const { handleAuthContext } = useLogout()
  const localizationService = useLocalization();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [showLoader, setShowLoader] = useState(null);
  const [capsulesFilter, setCapsulesFilter] = useState(initialFilter);
  const locationData = useSelector((state) => state.commonReducer.locationData);

  const initialFormValue = useMemo(() => {
    // const result = locationData;
    return {
      Region: locationData?.Region?.RegionID,
      Country: locationData?.Country,
    };
  }, [locationData]);
  const [selectedData, setSelectedData] = useState({
    ...initialFormValue,
    // ...locationData,
    ...capsuleToParams(initialFilter),
  });

  const formRef = useRef();

  const onSearchClick = async (e) => {

    const params = {
      COMPANYID: authContext?.CompanyID,
    };
    const searchTerm = e.target?.searchWorkOrder?.value;
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setSearthTerm(searchTerm);
    setShowLoader({
      className: ".newAuditorContainer",
      msg: "loading",
    });
    const res = await getAuditorNew(params, authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext()
      return;
    }
    console.log("auditor list", { res });
    if (res?.group?.status === "Success") {
      setResult(res?.group);
      if (res?.group.rowCount === "1") {
        let data = {
          RESOURCE_ID: res?.group?.SearchResponse[0]?.RESOURCE_ID
        }
        getOneResourceId(data)
      } else {
        getOneResourceId(false)
      }
    } else {
      console.log("new auditor list api error", { res });
    }
  };
  console.log("SearchFilterWindow", { selectedData });

  const onFilterSubmitClick = async ({ values }) => {
    formRef.current.setFilterWindow(false);
    const newCapsuleFilter = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (!value) {
          return acc;
        }
        if (key === "Region") {
          return acc;
        }
        if (key === "Country") {
          const countryData = value.map((c) => ({
            name: key,
            text: c.CountryName,
            value: c.CountryID,
          }));
          return [...acc, ...countryData];
        }
        return [
          ...acc,
          {
            name: key,
            text: value,
            value,
          },
        ];
      },
      []
    );
    const newSearchParams = capsuleToParams(newCapsuleFilter);
    setCapsulesFilter(newCapsuleFilter);
    setSelectedData(values);


    const searchFilterParams = {
      ...newSearchParams,
      // COUNTRY: 170,
      // RESOURCEID: 1010,
      // FIRSTNAME: "sansa",
      // LASTNAME: "amell",
      // STATUS: "Active",
      COMPANYID: authContext?.CompanyID,
    };

    setShowLoader({
      className: ".newAuditorContainer",
      msg: "loading",
    });

    const res = await getAuditorNew(searchFilterParams, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    console.log("auditor list", { res });
    if (res?.group?.status === "Success") {
      setResult(res?.group);
    } else {
      console.log("new auditor list api error", { res });
    }
    setShowLoader(null);
  };

  const removeCapsuleFilter = (index) => {
    const { name: removeFieldName, value: removeFieldValue } =
      capsulesFilter[index];
    const newSelectedData = { ...selectedData };
    if (removeFieldName === "Country") {
      newSelectedData.Country = newSelectedData.Country.filter(
        (c) => c.CountryID !== removeFieldValue
      );
      if (newSelectedData.Country.length === 0) {
        showToast("error", `${t("countrymandatory_msglbl")}`);
        return false
      }
    } else {
      delete newSelectedData[removeFieldName];
    }
    setSelectedData(newSelectedData);

    const newCapsuleFilter = [...capsulesFilter];
    newCapsuleFilter.splice(index, 1);
    setCapsulesFilter(newCapsuleFilter);
    const fetchSearchPlan = async () => {
      const newSearchParams = {
        ...capsuleToParams(newCapsuleFilter),
        COMPANYID: authContext?.CompanyID,
      };
      // const params = {
      //   ...newSearchParams,
      // };
      setShowLoader({
        className: ".newAuditorContainer",
        msg: "loading",
      });
      const res = await getAuditorNew(newSearchParams, authContext);
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }
      console.log("auditor list", { res });
      if (res?.group?.status === "Success") {
        setResult(res?.group);
      } else {
        console.log("new auditor list api error", { res });
        setResult(null);
        showToast(
          "error",
          localizationService.toLanguageString(
            "dataNotFound",
            `${res?.Status}`
          ),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
      setShowLoader(null);
    };
    fetchSearchPlan();
  };
  console.log("country", selectedData);
  console.log("filter", capsulesFilter);
  useEffect(() => {
    if (shouldRefresh) {
      const fetchSearchPlan = async () => {
        const newSearchParams = capsuleToParams(capsulesFilter);
        const params = {
          COUNTRY: authContext?.CountryCode,
          COMPANYID: authContext?.CompanyID,
          ...newSearchParams,
        };
        setShowLoader({
          className: ".newAuditorContainer",
          msg: "loading",
        });
        const res = await getAuditorNew(params, authContext);
        if (checkInvalidSession(res)) {
          handleAuthContext();
          return;
        }
        console.log("auditor list", { res });
        if (res?.group?.status === "Success") {
          setResult(res?.group);
        } else {
          console.log("new auditor list api error", { res });
          setResult(null);
          showToast(
            "error",
            localizationService.toLanguageString(
              "dataNotFound",
              `${res?.Status}`
            ),
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
        setShowLoader(null);
      };
      fetchSearchPlan();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (authContext) {
      const fetchAuditorList = async () => {
        const newSearchParams = capsuleToParams(capsulesFilter);
        const params = {
          COUNTRY: authContext?.CountryCode,
          COMPANYID: authContext?.CompanyID,
          ...newSearchParams,
        };
        setShowLoader({
          className: ".newAuditorContainer",
          msg: "loading",
        });
        const res = await getAuditorNew(params, authContext);
        setShowLoader(null);
        if (checkInvalidSession(res)) {
          handleAuthContext()
          return;
        }
        console.log("auditor list", { res });
        if (res?.group?.status === "Success") {
          // res.group?.SearchResponse?.sort(sortList?.[sort]?.sortFunc);
          setResult(res.group);
        }
      };
      fetchAuditorList();
    }
  }, [authContext]);

  const onCountryChange = (e) => {
    setSelectedData({ ...selectedData, Country: e.value });
  }


  const onInputChange = (e) => {
    setSelectedData((prev) => ({
      ...prev,
      [e.target.name]: e.value,
    }));
  };
  const customInputChange = (e, name) => {
    console.log("change", { name });
    setSelectedData((prev) => ({
      ...prev,
      [name]: e.value,
    }));
  };

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <SearchFilterContainer>
        <SearchForm
          placeholder={`${t("searchby")} ${t("CDAR RESOURCE ID")},${t("firstname")},${t("lastname")}`}
          onSubmitClick={onSearchClick}
        />
        <SearchFilterComp
          ref={formRef}
          onSubmitClick={onFilterSubmitClick}
          selectedData={selectedData}
          capsulesFilter={capsulesFilter}
          removeCapsuleFilter={removeCapsuleFilter}
          windowProps={{
            initialTop: 70,
            initialWidth: 1165,
            initialHeight: 366,
          }}
        >
          {(formRenderProps) => (
            <FormElement>
              <div style={{ display: "flex", gap: 15, flexWrap: "wrap" }}>
                <Field
                  name="Region"
                  label={t("REGION")}
                  component={FormComboBox}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("REGION")}
                  data={[locationData?.Region]}
                  textField="RegionName"
                  valueField="RegionID"
                  disabled
                />
                <Field
                  name="Country"
                  label={t("COUNTRY")}
                  component={FormMultiSelect}
                  placeholder={"Country"}
                  validator={inputValidator}
                  required
                  wrapperStyle={{
                    width: "calc(25% - 15px)",
                    position: "relative",
                  }}
                  data={locationData?.Country}
                  textField="CountryName"
                  valueField="CountryID"
                  onChange={onCountryChange}
                />
                <Field
                  name="RESOURCEID"
                  label={t("ResourceId")}
                  placeholder={"Primary Resource Id"}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  onChange={onInputChange}
                />
                <Field
                  name="FIRSTNAME"
                  label={t("firstname")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("firstname")}
                  onChange={onInputChange}
                />
                <Field
                  name="LASTNAME"
                  label={t("lastname")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("lastname")}
                  onChange={onInputChange}
                />
                <Field
                  name="SKILLS"
                  label={t("Skills")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("Skills")}
                  onChange={onInputChange}
                />
                <Field
                  name="STATUS"
                  label={t("Status")}
                  placeholder={t("Status")}
                  component={FormComboBox}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  data={["Active", "Inactive"]}
                  onChange={(e) => customInputChange(e, "STATUS")}
                />

                <Field
                  name="Custom1"
                  label={t("CUSTOM") + "1"}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("CUSTOM") + "1"}
                  onChange={onInputChange}
                />
                <Field
                  name="Custom2"
                  label={t("CUSTOM") + "2"}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("CUSTOM") + "2"}
                  onChange={onInputChange}
                />
                <Field
                  name="Custom3"
                  label={t("CUSTOM") + "3"}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("CUSTOM") + "3"}
                  onChange={onInputChange}
                />
                <Field
                  name="Custom4"
                  label={t("CUSTOM") + "4"}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("CUSTOM") + "4"}
                  onChange={onInputChange}
                />
                <Field
                  name="Custom5"
                  label={t("CUSTOM") + "5"}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(25% - 15px)" }}
                  placeholder={t("CUSTOM") + "5"}
                  onChange={onInputChange}
                />
              </div>
              <div></div>
              <SearchFilterFooter handleReset={() => {
                setSelectedData({ ...initialFormValue, Country: '' });
                setTimeout(() => {
                  formRenderProps.onFormReset();
                }, 0);
              }} />
            </FormElement>
          )}
        </SearchFilterComp>
      </SearchFilterContainer>
    </>
  );
};

export default SearchFilterWindow;
