import * as types from "../../actions/types";

const initialState = {
    countryEnablementData: null,
};

const countryEnablementReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case types.GET_COUNTRY_ENABLEMENT_DATA:
            newState = {
                ...state,
                countryEnablementData: action.payload
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}

export default countryEnablementReducer;
