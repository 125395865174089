import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useKey, useMultiSelect } from "../dropdown/hooks";
import { KEY, filterData } from "../dropdown/utils";
import SelectItem from "./select-item";
import SelectList from "./select-list";

enum FocusType {
  SEARCH = 0,
  NONE = -1,
}

const SelectPanel = (props: { onFocus: any, onBlur: any }) => {
  const {
    onChange,
    data,
    value,
    textField = 'label',
    valueField = 'value',
    filterData: customFilterData,
    ItemRenderer,
    disabled,
    searchField,
    selectAll,
  } = useMultiSelect();

  const listRef = useRef<any>();
  const searchInputRef = useRef<any>();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [searchTextForFilter, setSearchTextForFilter] = useState("");
  const [focusIndex, setFocusIndex] = useState(0);

  const skipIndex = useMemo(() => {
    let start = 0;

    if (searchField) start += 1; // if search is enabled then +1 to skipIndex
    if (selectAll) start += 1; // if select-all is enabled then +1 to skipIndex

    return start;
  }, [searchField, selectAll]);

  const selectAllOption = {
    [textField]: "Select All",
    value: "",
  };

  const selectAllValues = (checked: boolean) => {
    const filteredValues = filteredData
      .filter((o) => !o.disabled)
      .map((o) => o[valueField]);

    if (checked) {
      const selectedValues = value.map((o) => o?.[valueField]);
      const finalSelectedValues = [...selectedValues, ...filteredValues];

      return filteredData.filter((o) =>
        finalSelectedValues.includes(o[valueField])
      );
    }

    return value.filter((o) => !filteredValues.includes(o?.[valueField]));
  };

  const selectAllChanged = (checked: boolean) => {
    const newOptions = selectAllValues(checked);
    onChange && onChange({ value: newOptions });
  };

  const handleSearchChange = (e: any) => {
  console.log("seaissu",e.target.value)

    setSearchText(e.target.value);
    setFocusIndex(FocusType.SEARCH);
    setSearchTextForFilter(e.target.value)
  };
  console.log("seaissue",searchText,{searchTextForFilter})


  const handleClear = () => {
    setSearchTextForFilter("");
    setSearchText("");
    searchInputRef?.current?.focus();
  };

  const handleItemClicked = (index: number) => setFocusIndex(index);

  // Arrow Key Navigation
  const handleKeyDown = (e: any) => {
    switch (e.code) {
      case KEY.ARROW_UP:
        updateFocus(-1);
        break;
      case KEY.ARROW_DOWN:
        updateFocus(1);
        break;
      default:
        return;
    }
    e.stopPropagation();
    e.preventDefault();
  };

  useKey([KEY.ARROW_DOWN, KEY.ARROW_UP], handleKeyDown, {
    target: listRef,
  });

  const handleSearchFocus = () => {
    setFocusIndex(FocusType.SEARCH);
  };

  const getFilteredOptions = async () =>
    customFilterData
      ? await customFilterData(data, searchTextForFilter, textField, valueField)
      : filterData(data, searchTextForFilter, textField, valueField);

  const updateFocus = (offset: number) => {
    let newFocus = focusIndex + offset;
    newFocus = Math.max(0, newFocus);
    newFocus = Math.min(newFocus, data.length + Math.max(skipIndex - 1, 0));
    setFocusIndex(newFocus);
  };

  useEffect(() => {
    listRef?.current?.querySelector(`[tabIndex='${focusIndex}']`)?.focus();
  }, [focusIndex]);

  const [isAllOptionSelected, hasSelectableOptions] = useMemo(() => {
    const filteredDataList = filteredData.filter((o) => !o.disabled);
    return [
      filteredDataList.every(
        (o) => value.findIndex((v) => v?.[valueField] === o?.[valueField]) !== -1
      ),
      filteredDataList.length !== 0,
    ];
    // eslint-disable-next-line
  }, [filteredData, value]);

  useEffect(() => {
    getFilteredOptions().then(setFilteredData);
    // eslint-disable-next-line
  }, [searchTextForFilter, data]);

  return (
    <div
      tabIndex={0}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      className="k-popup k-child-animation-container k-slide-down-enter k-slide-down-enter-active" role="listbox" ref={listRef}
    >
      {searchField && (
        <span className="k-searchbar">
          <input
            placeholder={"Search"}
            type="text"
            className="k-input"
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            value={searchText}
            ref={searchInputRef}
            tabIndex={0}
          />
          <span
            className="k-icon k-clear-value k-i-close" style={{ display: searchText ? undefined : 'none' }}
            role="button" tabIndex={-1} onClick={handleClear} />
        </span>
      )}
      <ul className="k-list k-reset k-list-scroller" style={{ maxHeight: 180 }}>
        {selectAll && hasSelectableOptions && (
          <SelectItem
            textField={textField}
            tabIndex={skipIndex === 1 ? 0 : 1}
            checked={isAllOptionSelected}
            option={selectAllOption}
            onSelectionChanged={selectAllChanged}
            onClick={() => handleItemClicked(1)}
            itemRenderer={ItemRenderer}
            disabled={disabled}
          />
        )}
        {filteredData.length ? (
          <SelectList
            textField={textField}
            skipIndex={skipIndex}
            data={filteredData}
            onClick={(_e: any, index: any) => handleItemClicked(index)}
          />
        ) : (
          <li className="k-nodata">{"No Data found"}</li>
        )}
      </ul>
    </div>
  );
};

export default SelectPanel;
