import React, { useState } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { updateAutomaticUPCFlagApi } from "../../../../store/actions/workOrder";
import { useSelector } from "react-redux";
import { showToast } from "../../../../utils/Notification";
import LoadMask from "../../../../utils/LoadMask";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";

const UpcBtn = ({ workOrderDetail }) => {
  const {handleAuthContext} = useLogout();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [showLoader, setShowLoader] = useState(null);

  const toggleUPCStatus = async ({ value }) => {
    // const Jobid = workOrderDetail?.JobId;
    // const UPCAssignment = value ? "Y" : "N";
    // const Collectiontype = workOrderDetail?.CollectionType;

    // const query = `?Jobid=${Jobid}&Collectiontype=${Collectiontype}&UPCAssignment=${UPCAssignment}`;

    const params = {
      Jobid : workOrderDetail?.JobId,
      UPCAssignment : value ? "Y" : "N" ,
      Collectiontype : workOrderDetail?.CollectionType    
    }

    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await updateAutomaticUPCFlagApi(JSON.stringify(params), authContext);
    // const res = await updateAutomaticUPCFlagApi(query, null, authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.Response?.StatusCode === "200") {
      showToast("success", res.Response?.StatusMsg);
    } else {
      showToast("error", res.Response?.StatusMsg);
    }
  };
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <div title="Automatic UPC Redistribution">
        <Switch onChange={toggleUPCStatus} />
      </div>
    </>
  );
};

export default UpcBtn;
