

// import * as type from "../types";
import { Get, PostwithTimeoutHandler } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";

// const getHeaders = (authContext) => ({
//   OrganizationUnit: authContext?.organizationUnit || "",
//   userid: authContext?.SpocMail || "",
// });


export const getAuditStatus = async (params, authContext) => {

  const header = {
    
    userid: authContext?.SpocMail || "",
    countryid: authContext?.CountryCode || "",
    companyid:authContext?.CompanyID || "",
    "content-type": "application/json",
    "sessionid": "cDTqCPepeaJ5g3Xs",
    // "content-type": "application/x-www-form-urlencoded; application/json",
  }

  const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header,true,10000).then((res) =>
    res.json()
  );
  return res;


  // return DCTRESOSPONSE;
};

// export const getNavigatorReportBasic=()=>{
//     return DCTRESOSPONSE;
// }
