import AppURL from "../../../utils/AppURL";
import { Get } from "../../methods";
export const getSummaryReport = async(params, authContext) => {

    const header = {
      
         userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
         companyid:authContext?.CompanyID || "",
        "content-type": "application/x-www-form-urlencoded; application/json",
      }                 
      const result = await Get(AppURL.getEcollectionSummary, params, header,true).then((res) =>
      res.json()
    );
    return result;
}
export const getSummary = async(params,authContext)=>{
    const header1 = {
      
        userid: authContext?.SpocMail || "",
       countryid: authContext?.CountryCode || "",
        companyid:authContext?.CompanyID || "",
       "content-type": "application/x-www-form-urlencoded; application/json",
     } 
     const result1 = await Get(AppURL.searchPlan,params,header1,true).then((res1)=>
    res1.json()
    );
    return result1;
}