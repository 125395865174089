import AppURL from "../../utils/AppURL";
import { Get, Post } from "../methods";
import * as types from "./types";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
});

// export const getPermissions = (userDetails) => (dispatch) => {
//   console.log("getPermissions",userDetails);
//   // let params = new URLSearchParams(),
//   //   headers = {
//   //     "Content-Type": "application/x-www-form-urlencoded",
//   //   };

//   // params.append("ResourceID", userDetails.ResourceID);
//   // params.append("RoleID", userDetails.RoleID);
//   // params.append(
//   //   "FunctionGroup",
//   //   JSON.stringify({ FunctionGroup: { Name: ["FMS Controller"] }})
//   // );
//   // params.append("OUID", userDetails.OUID);



//   let headers = {
//     "Content-Type": "application/json"    
//   }

//   let params = JSON.stringify(userDetails)

//   Post(AppURL.getpermissions, params, headers, true)
//     .then((res) => res.json())
//     .then((res) => {
//       userDetails.isAuth = true;
//       console.log("getPermissionss",userDetails,{res});
//       // dispatch(getLanguages());
//       //   dispatch(getHubDetails());
//       //   dispatch(getOrganizationUnits());
//       //   dispatch(getCountryDialingCodes());
//       //   dispatch(downloadPhotograph(userDetails));
//       /*Traning Management Calls*/
//       //   dispatch(getCurrenry(userDetails));
//       //   dispatch(getRoles(userDetails));
//       //   dispatch(fetchCourseRoleType(userDetails));

//       dispatch({
//         type: types.SET_PERMISSIONS,
//         payload: res,
//       });
//       // dispatch({
//       //   type: types.SET_AUTH_CONTEXT,
//       //   payload: userDetails,
//       // });
//     });
// };

export const getCountryDetails = (userDetails) => (dispatch) => {

  let headers = {
    "Content-Type": "application/json"
  }

  Post(AppURL.getCountryDetails, JSON.stringify(userDetails), headers, true)
    .then((res) => res.json())
    .then((res) => {
      const RegionInfo = [{
        RegionID: res.RegionId,
        RegionName: res.RegionName,
        CountryDetails: res?.Countries.map((obj) => {
          return {
            CountryID: obj.CountryId,
            CountryName: obj.CountryName,
          }
        }) || []
      }]

      dispatch({
        type: types.SET_PERMISSIONS,
        payload: RegionInfo,
      });
    });
};

export const getAuditors = (params, authContext) => async (dispatch) => {
  try {
    const res = await Get(
      AppURL.fetchauditors,
      params,
      getHeaders(authContext),
      true
    ).then((res) => res.json());
    console.log("search Plan", { res });
    if (res?.AuditorDetails) {
      dispatch({
        type: types.SET_AUDITOR_LIST,
        payload: res.AuditorDetails,
      });
    }
    return res;
  } catch (e) {
    console.log("error", e);
  }
};

// export const downloadPhotograph = (userDetails) => (dispatch) => {
//   let params = new URLSearchParams(),
//     headers = {
//       "Content-Type": "application/x-www-form-urlencoded",
//     };

//   params.append(
//     "jsonString",
//     JSON.stringify({
//       Image: {
//         ResourceID: userDetails.resourceId,
//         ResourceType: "RESOURCE",
//       },
//     })
//   );

//   Post(AppURL.downloadphotograph, params, headers, true)
//     .then((res) => res.json())
//     .then((res) => {
//       if (res?.root?.binaryContent) {
//         dispatch({
//           type: types.SET_LOGGEDIN_USER_PHOTO,
//           payload: res.root.binaryContent,
//         });
//       } else {
//         //error
//       }
//     });
// };

// export const getCurrenry = (userDetails) => (dispatch) => {
//   let headers = {
//     OrganizationUnit: userDetails.OrganizationUnit,
//     userid: userDetails.email,
//   };
//   Get(AppURL.getCurrency, headers)
//     .then((res) => res.json())
//     .then((res) => {
//       if (Array.isArray(res?.SearchCurrencyOp?.CurrencyDetails)) {
//         dispatch({
//           type: types.SET_CURRENCY,
//           payload: res.SearchCurrencyOp.CurrencyDetails,
//         });
//       } else {
//         //error
//       }
//     });
// };

// export const fetchCourseRoleType = (userDetails) => (dispatch) => {
//   let headers = {
//     OrganizationUnit: userDetails.organizationUnit,
//     userid: userDetails.email,
//     Viewflag: 1,
//   };
//   let params = {
//     page: 1,
//     start: 0,
//     limit: 25,
//   };
//   Get(AppURL.trainingfetchcoursetyperole, params, headers)
//     .then((res) => res.json())
//     .then((res) => {
//       if (Array.isArray(res?.FetchAllOp?.FetchAll?.CourseTypes)) {
//         dispatch({
//           type: types.SET_COURSE_TYPE,
//           payload: res.FetchAllOp.FetchAll.CourseTypes,
//         });
//       } else {
//         //error
//       }
//     });
// };

// export const getRoles = (userDetails) => (dispatch) => {
//   let headers = {
//     "Content-Type": "application/x-www-form-urlencoded",
//   };
//   Post(AppURL.getroles, null, headers, true)
//     .then((res) => res.json())
//     .then((res) => {
//       if (Array.isArray(res?.RolesDetailsOp?.Roles)) {
//         const roleHierarchyLevel = res.RolesDetailsOp.Roles.find(
//           (role) => role.RoleID === userDetails.roleID
//         )?.RoleHiearchyLevel;

//         dispatch({
//           type: types.SET_ROLES,
//           payload: res.RolesDetailsOp.Roles,
//         });
//         dispatch({
//           type: types.SET_AUTH_CONTEXT,
//           payload: { ...userDetails, roleHierarchyLevel },
//         });
//       } else {
//         //error
//       }
//     });
// };

// export const getOUModuleRoles = () => (dispatch) => {
//   let headers = {
//     "Content-Type": "application/x-www-form-urlencoded",
//   };
//   Post(AppURL.getoumodulerole, null, headers, true)
//     .then((res) => res.json())
//     .then((res) => {
//       if (Array.isArray(res?.OU_Info)) {
//         dispatch({
//           type: types.GET_OU_MODULE_ROLES,
//           payload: res.OU_Info,
//         });
//       } else {
//         //error
//       }
//     });
// };

export const getLanguages = () => (dispatch) => {
  Get(AppURL.lang)
    .then((res) => res.json())
    .then((res) => {
      if (Array.isArray(res?.SearchLanguageOp?.LanguageDetails)) {
        dispatch({
          type: types.SET_LANGUAGES,
          payload: res.SearchLanguageOp.LanguageDetails,
        });
      } else {
        //error
      }
    });
};

// export const getOrganizationUnits = () => (dispatch) => {
//   let headers = {
//     "Content-Type": "application/x-www-form-urlencoded",
//   };
//   Post(AppURL.getorgunit, null, headers, true)
//     .then((res) => res.json())
//     .then((res) => {
//       if (Array.isArray(res?.OrganizationalUnitOp?.OrganizationalUnit)) {
//         dispatch({
//           type: types.SET_ORG_UNITS,
//           payload: res.OrganizationalUnitOp.OrganizationalUnit,
//         });
//       } else {
//         //error
//       }
//     });
// };


// export const getCountryDialingCodes = () => (dispatch) => {
//   Get(AppURL.countrydialingcode)
//     .then((res) => res.json())
//     .then((res) => {
//       if (Array.isArray(res?.SearchDialingCodeOp?.CountryCodeDetails)) {
//         let data = res.SearchDialingCodeOp.CountryCodeDetails.map((item) => {
//           if (!item.DialingCountryCode) item.DialingCountryCode = "";
//           if (!item.NATIONALITY) item.NATIONALITY = "";
//           return item;
//         });
//         dispatch({
//           type: types.SET_COUNTRY_DIALING_CODES,
//           payload: data,
//         });
//       } else {
//         //error
//       }
//     });
// };


export const updateUserPreferenceAction = async (params, authContext) => {
  const headers = {
    OrganizationUnit: authContext?.organizationUnit || "",
    userid: authContext?.SpocMail || "",
    "Content-Type": "application/json"
  }
  // const formParams =  new FormData();
  const res = await Post(
    AppURL.updateUserPreference,
    params,
    headers,
    true
  ).then((res) => res.json());
  return res;
};

export const GetSearchParametersOFPlan = (params, authContext) => async (dispatch) => {
  try {
    const res = await Get(
      AppURL.GetSearchParametersOFPlan,
      params,
      getHeaders(authContext),
      true
    ).then((res) => res.json());
    console.log("GetSearchParametersOFPlan", { res });
    if (res?.fetchDetailsDetails) {
      dispatch({
        type: types.SET_SEARCH_PARAMETER_OF_PLAN,
        payload: res?.fetchDetailsDetails,
      });
    }
    return res;
  } catch (e) {
    console.log("error", e);
  }
};
