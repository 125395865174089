import * as types from "../../actions/types";

// format
// {
//   CollectionTypes: [
//     {
//       ProductName: "NSO",
//     },
//   ];
// }
const initialState = {
  CollectionTypes: [],
};

const PeopleReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.GET_COLLECTION_TYPES:
      newState = {
        ...state,
        CollectionTypes: action.payload,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
export const selectCollectionTypes = (state) =>
  state.people.CollectionTypes;

export default PeopleReducer;
