import { Button } from '@progress/kendo-react-buttons';
import { Pager } from '@progress/kendo-react-data-tools';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Grid, GridCell, GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormComboBox, FormInput } from '../../../form/form-component';
import { inputValidator } from '../../../form/validator';
import columns, { columnMenuConfig } from './common/columnFlowControl';
import { getExecutionQualityReport } from '../execution-qualityReport/common/executionqualityReport';
import { getAuditStatus } from './common/getAuditStatus';
import { getFlowControlTable } from './common/flowControlTable';
import { showToast } from '../../../../utils/Notification';
import { toast } from "react-toastify";
import { isColumnActive } from "../../../grid/CustomColumnMenu";
import { updateUserPreferenceAction } from '../../../../store/actions/commonActions';
import LoadMask from '../../../../utils/LoadMask';
import { useHistory } from 'react-router-dom';
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { useTranslation } from "react-i18next";
import { getExportData, getExportDataWithPostTimeoutHandler } from '../summaryReport/common/getSummaryReport';
import { searchUserPrefrence } from '../../../../store/actions/dashboard';
import useLogout, { checkInvalidSession } from '../../../../utils/useLogout';
import { SentToFactoryRender } from '../../../ui/grid/renders';
import { CellRender, RowRender } from '../../../people/administrators/column';
import { MyPager } from '../../../generalHooks';
import Header from '../../../header';

const GRID_NAME = "ECOL_FLOW_CONTROL_GRID"

const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            take: dataState.take,
        },
        dataState: dataState,
    };
};



let initialState = createDataState([], {
    take: 50,
    skip: 0,
});

const setOrderIndex = (columns) => {
    return columns.map((column, i) => ({ ...column, orderIndex: i }))
}

const initialColumnsState = setOrderIndex(columns);

function FlowControlReport() {
    const { handleAuthContext } = useLogout();
    const [showForm, setShowForm] = useState(true);
    const [stateColumns, setStateColumns] = useState(initialColumnsState);
    const [data, setData] = useState({ data: [], total: 0 });
    const [showLoader, setShowLoader] = useState(null);
    const [formFC, setFormFC] = useState([]);
    const [formWorkOrderId, setFormWorkOrderId] = useState([]);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const { t } = useTranslation();
    const [auditStatus, setAuditStatus] = useState([]);
    const dispatch = useDispatch();

    const [result, setResult] = React.useState(initialState.result);
    const [dataState, setDataState] = React.useState(initialState.dataState);

    const [weekNumber, setWeekNumber] = useState([]);
    const [year, setYear] = useState([]);

    const locationData = useSelector((state) => state.commonReducer.locationData);
    const dateStamp = useSelector((store) => store?.reports?.dateStamp)
    const authContext = useSelector((state) => state.commonReducer.authContext);
    const serverColumns = useSelector(
        (state) => state.commonReducer.gridUserPrefrence
    );

    const [expandIcon, setExpandIcon] = useState("fullscreen");

    const history = useHistory();

    const initialFormValue = useMemo(() => {
        return {
            Region: locationData?.Region?.RegionID,
            Country: locationData?.Country?.[0]?.CountryID,
            year: dateStamp?.selectedYear?.DisplayValue.toString(),
            week: dateStamp?.selectedWeek?.DisplayValue.toString(),
            plantype: "LIVE"
        };
    }, [locationData]);
    const [selectedData, setSelectedData] = useState(initialFormValue);

    const onExport = async () => {

        if (selectedData.Region && selectedData.Country) {
            // let params = {
            //     "flowControlReportReq": JSON.stringify({
            //         "Request": {
            //             "Region": selectedData.Region,
            //             "Country": selectedData.Country,
            //             "CollectionType": "ECOLLECTION",
            //             "Cycle": null,
            //             "Year": selectedData.year,
            //             "Week": selectedData.week,
            //             "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
            //             "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
            //             "AuditStatus": selectedData.auditstatus ? selectedData.auditstatus : "",
            //             "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
            //             "FromDate": "",
            //             "ToDate": "",
            //             "CompanyId": authContext.CompanyID,
            //             "StoreType": selectedData.plantype ? selectedData.plantype : ""
            //         }
            //     })
            // }
            // let params = {
            //     flowControlReportReq: JSON.stringify({ "Request": { "Region": selectedData.Region, "Country": selectedData.Country, "CollectionType": "ECOLLECTION", "Cycle": null, "Year": selectedData.year, "Week": selectedData.week, "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "FromDate": "", "ToDate": "", "CompanyId": authContext.CompanyID, "StoreType": selectedData.plantype ? selectedData.plantype : "" } })
            // }
            let params = {
                "ReportType": "flow",
                "Region": selectedData.Region,
                "Country": selectedData.Country,
                "CollectionType": "ECOLLECTION",
                "Cycle": null,
                "Year": selectedData.year,
                "Week": selectedData.week,
                "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
                "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
                "AuditStatus": selectedData.auditstatus ? selectedData.auditstatus : "",
                "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
                "FromDate": "",
                "ToDate": "",
                "CompanyId": authContext.CompanyID,
                "StoreType": selectedData.plantype ? selectedData.plantype : ""
            }

            let params2={
                "CollectionType":"ECOLLECTION",
                "ReportName":"FLOW_CONTROL_REPORT",
                "APIName":"Reports",
                "Payload":{
                    "ReportType": "flow",
                    "Region": selectedData.Region,
                    "Country": selectedData.Country,
                    "CollectionType": "ECOLLECTION",
                    "Cycle": null,
                    "Year": selectedData.year,
                    "Week": selectedData.week,
                    "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
                    "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
                    "AuditStatus": selectedData.auditstatus ? selectedData.auditstatus : "",
                    "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
                    "FromDate": "",
                    "ToDate": "",
                    "CompanyId": authContext.CompanyID,
                    "StoreType": selectedData.plantype ? selectedData.plantype : ""
                }
            }

            setShowLoader({
                // className: ".workOrders_grid",
                msg: "loading",
            });

            showToast(
                "info",
                t('filedownload_msglbl')
            );
            // no need to json stringify
            // const res = await getExportData(params, authContext)
            const res = await getExportDataWithPostTimeoutHandler(params2, authContext)

            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            setShowLoader(null);
        } else {
            showToast(
                "error",
                t("pleasefillthemandatoryfields"),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }

    }

    const expand = () => {
        setShowForm(!showForm);
        setExpandIcon(!showForm ? "fullscreen" : "fullscreen-exit");
    }

    const [filter, setFilter] = useState(null);
    const [sort, setSort] = useState([
        {
            field: "BatchId",
            dir: "asc",
        },
    ]);

    const dataStateChange = (event) => {
        console.log(event);
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    useEffect(() => {
        if (serverColumns?.length > 0) {
            const currentDataColumns = serverColumns.find(
                (c) => c?.Grid_Name === GRID_NAME
            );
            try {
                const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
                if (currentStateColumns.every((column) => column.field)) {
                    setStateColumns(currentStateColumns);
                }
            } catch (e) {
                console.log("error in state columns", currentDataColumns?.Json_object);
                return;
            }
        }
    }, [serverColumns]);


    useEffect(() => {

        setSelectedData({
            ...selectedData, Region: locationData?.Region?.RegionID,
            Country: locationData?.Country?.[0]?.CountryID,
            year: dateStamp?.selectedYear?.DisplayValue.toString(),
            week: dateStamp?.selectedWeek?.DisplayValue.toString(),
            plantype: "LIVE",
        })
    }, [locationData?.Country, dateStamp])



    useEffect(() => {

        const auditStatusFunction = async () => {

            const params2 = {
                "CollectionType": "ECOLLECTION",
                "ReportName": "FLOW_CONTROL_REPORT",
                "APIName": "getAuditStatus",
                "Payload": {
                    "Region": selectedData.Region,
                    "Country_id": locationData?.Country?.[0]?.CountryID,     
                }
            }

            // let params = {
            //     Region: selectedData.Region,
            //     Country_id: locationData?.Country?.[0]?.CountryID,
            // }

            setShowLoader({
                className: ".workOrders_grid",
                msg: "loading",
            });
           
            const res = await getAuditStatus(JSON.stringify(params2), authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            setAuditStatus(res?.AuditStatusResponse?.Response);
            console.log("lll", res);

            setShowLoader(null);
        }

        auditStatusFunction();

    }, [])

    const onChange = (e) => {

        if (e.target?.component?.name === "year" || e.target?.component?.name === "plantype" || e.target?.component?.name === "week" || e.target?.component?.name === "Country" || e.target?.component?.name === "JobId" || e.target?.component?.name === "auditstatus") {
            setSelectedData({
                ...selectedData, [e.target.component.name]: e.value
            })
        } else {
            setSelectedData({
                ...selectedData, [e.target.name]: e.value
            })
        }
    }

    useEffect(() => {

        const searchPlan = async () => {

            let params = {
                Region: selectedData.Region, Country: selectedData.Country, CollectionType: "ECOLLECTION", year: selectedData.year, week: selectedData.week,
                CompanyID: authContext.CompanyID,
                // CompanyID: "358",
                _dc: "1673938014148"
            }

            // if (formData.countryID && formData.regionID && formData.year && formData.startdate && formData.surveyId && formData.planId && formData.storeId && formData.status) {

            setShowLoader({
                // className: ".workOrders_grid",
                msg: "loading",
            });
            const res = await getExecutionQualityReport(params, authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            // setDctTable(res?.Response?.DctReportSearchResultResMsg?.DctReportSearchResultRes);
            setFormFC(res?.SearchPlan);
            setShowLoader(null);
            // }
        }

        searchPlan();

    }, [])

    // On change Week Number
    const onChangeWeekNumber = async (e) => {

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region,
            // Region: "4",
            Country: selectedData.Country,
            // Country: "76", 
            CollectionType: "ECOLLECTION",
            year: selectedData.year,
            // year: "2022",
            week: e.value,
            // week: "35",
            CompanyID: authContext.CompanyID,
            // CompanyID: "377",
            _dc: "1673938014148"
        }

        setShowLoader({
            // className: ".workOrders_grid",
            msg: "loading",
        });

        // Search Plan
        const res = await getExecutionQualityReport(params, authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        setShowLoader(null);
        if (res?.SearchPlan) {
            setFormFC(res?.SearchPlan);
        } else {
            showToast("error", `${t("workorderisnotavailableforsearchedweek")}`);
        }
    }

    const onChangeYear = async (e) => {

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region,
            // Region: "4",
            Country: selectedData.Country,
            // Country: "76", 
            CollectionType: "ECOLLECTION",
            year: e.value,
            // year: "2022",
            week: selectedData.week,
            // week: "35",
            CompanyID: authContext.CompanyID,
            // CompanyID: "377",
            _dc: "1673938014148"
        }


        setShowLoader({
            // className: ".workOrders_grid",
            msg: "loading",
        });

        // Search Plan
        const res = await getExecutionQualityReport(params, authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        setFormFC(res?.SearchPlan);

        // console.log("ddd", res1.ReportResponse?.Response);

        setShowLoader(null);

    }

    const onChangeCountry = async (e) => {

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region,
            // Region: "4",
            Country: e.value,
            // Country: "76", 
            CollectionType: "ECOLLECTION",
            year: selectedData.year,
            // year: "2022",
            week: selectedData.week,
            // week: "35",
            CompanyID: authContext.CompanyID,
            // CompanyID: "377",
            _dc: "1673938014148"
        }


        setShowLoader({
            // className: ".workOrders_grid",
            msg: "loading",
        });

        // Search Plan
        const res = await getExecutionQualityReport(params, authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        setFormFC(res?.SearchPlan);

        // console.log("ddd", res1.ReportResponse?.Response);

        setShowLoader(null);

    }


    useEffect(() => {
        let workOrder_id = [];
        let weekNumberData = [];
        let yearData = [];

        formFC?.map(elm => (
            workOrder_id.push(elm.JobId)

        ))
        setFormWorkOrderId([...new Set(workOrder_id)])

        dateStamp?.weekData?.map(elm => (
            weekNumberData.push(elm.DisplayValue)
        ))
        setWeekNumber([...new Set(weekNumberData)])

        dateStamp?.yearData?.map(elm => (
            yearData.push(elm.DisplayValue)
        ))
        setYear([...new Set(yearData)])


    }, [formFC, dateStamp?.weekData, dateStamp?.yearData])

    // On Search

    const onSearchClick = async (e) => {
        if (!e.isValid) {
            return
        }
        fetchFlowControlReport()
    };

    const handlePageChange = async (e) => {
        const dataState = e.page;
        setDataState(dataState);
        fetchFlowControlReport({
            page: Math.floor(dataState.skip / dataState.take) + 1,
            startCount: dataState.skip,
            MaxCount: dataState.take,
        });
    };

    const fetchFlowControlReport = async (newParams = {}) => {

        const params2 = {
            "CollectionType": "ECOLLECTION",
            "ReportName": "FLOW_CONTROL_REPORT",
            "APIName": "flowControlReport",
            "Payload": {
                "Region": selectedData.Region,
                "Country": selectedData.Country,
                "CollectionType": "ECOLLECTION",
                "Cycle": null,
                "Year": selectedData.year?.toString(),
                "Week": selectedData.week?.toString(),
                "FromDate": "",
                "ToDate": "",
                "WorkOrderID":  selectedData.JobId ? selectedData.JobId : "",
                "CompanyId": authContext.CompanyID,
                "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
                "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
                "AuditStatus": selectedData.auditstatus ? selectedData.auditstatus : "",
                "StoreType": selectedData.plantype,
                "page": 1,
                "startCount": 0,
                "MaxCount": 50,
                ...newParams,
            }
        }

        // const params = {
        //     "Region": selectedData.Region,
        //     "Country": selectedData.Country,
        //     "CollectionType": "ECOLLECTION",
        //     "Cycle": null,
        //     "Year": selectedData.year,
        //     "Week": selectedData.week,
        //     "FromDate": "",
        //     "ToDate": "",
        //     "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
        //     "CompanyId": authContext.CompanyID,
        //     "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
        //     "AuditStatus": selectedData.auditstatus ? selectedData.auditstatus : "",
        //     "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
        //     "StoreType": selectedData.plantype,
        //     "page": 1,
        //     "startCount": 0,
        //     "MaxCount": 50,
        //     ...newParams,
        //     //   CompanyID: authContext?.CompanyID,
        //     // "page": 1,
        //     // "StartCount": 0,
        //     // "MaxCount": 50,
        //     // ...newParams
        // };



        // const params = {
        //     flowControlReportReq:
        //         JSON.stringify({ "Request": { "Region": selectedData.Region, "Country": selectedData.Country, "CollectionType": "ECOLLECTION", "Cycle": null, "Year": selectedData.year, "Week": selectedData.week, "FromDate": "", "ToDate": "", "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "CompanyId": authContext.CompanyID, "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "AuditStatus": selectedData.auditstatus ? selectedData.auditstatus : "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreType": selectedData.plantype } }),
        //     //   CompanyID: authContext?.CompanyID,
        //     page: 1,
        //     StartCount: 0,
        //     MaxCount: 50,
        //     ...newParams
        // };


        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });

        const res = await getFlowControlTable(JSON.stringify(params2), authContext);
        console.log("www", res);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.ReportResponse?.Status?.StatusMessage === "Success" && Array.isArray(res?.ReportResponse?.Response)) {
            res?.ReportResponse?.Response.map((cn) => {
                const realCountry = locationData?.Country.filter(
                    ({ CountryID }) => CountryID === cn.Country
                );
                cn.Country = realCountry?.[0]?.CountryName;
            });

            const result = {
                data: res?.ReportResponse?.Response,
                total: res?.ReportResponse?.Count,
            };
            setData(result);
        } else {
            console.log("search plan api error", res);
            const result = {
                data: [],
                total: 0,
            };
            setData(result);
            showToast(
                "error",
                t("dataNotFound", `${res?.ReportResponse?.Status?.StatusMessage ? res?.ReportResponse?.Status?.StatusMessage : "No Data Found"}`),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
        setShowLoader(null);

    }

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState || initialColumnsState);
    };

    // const customCellRender = (td, props) => {
    //     const { dataItem, field } = props;
    //     const dataValue = dataItem[field];
    //     return <td style={{ textAlign: "center" }}>{dataValue?.toString()}</td>
    // };

    const customCellRender = (td, props) => {
        console.log("customCellRender", props)
        // return <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;
        switch (props.field) {
            case "SENT_TO_FACTORY":
                const data = []
                return (
                    <SentToFactoryRender
                        originalProps={props}
                        td={td}
                        // enterEdit={enterEdit}
                        // editField={EDIT_FIELD}
                        cellProps={{ data }}
                    // cell={<ComboBoxGrid {...props}/>}
                    />
                );
            default:
                return (
                    <CellRender
                        originalProps={props}
                        td={td}
                    // enterEdit={enterEdit}
                    // editField={EDIT_FIELD}
                    />
                );
        }
    }
    const customRowRender = (tr, props) => {
        return <RowRender originalProps={props} tr={tr} />
    };

    const CreatePager = useCallback((props) => (
        <MyPager
            {...props}
            onSaveViewClick={handleSaveViewClick}
            onDefaultViewClick={handleDefaultViewClick}
        >
            <Pager style={{ flex: 1 }} {...props} />
        </MyPager>
    ), [stateColumns]);

    const handleSaveViewClick = async () => {
        const params = {
            UserPrefReq: {
                Email: authContext.SpocMail,
                Company_ID: authContext.CompanyID,
                Grid_Name: GRID_NAME,
                Country_ID: selectedData.Country,
                Json_Object: JSON.stringify(stateColumns),
            },
        };
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await updateUserPreferenceAction(
            JSON.stringify(params),
            authContext
        );
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.Response?.Status === "Success") {
            dispatch(
                searchUserPrefrence(
                    JSON.stringify({
                        UserPrefReq: {
                            Email: authContext?.SpocMail,
                            Company_ID: authContext?.CompanyID,
                        },
                    })
                )
            );
            showToast(
                "success",
                t(res?.Response?.Message || "Success")
            );
        } else {
            showToast(
                "error",
                t(res?.Response?.Message || "dataNotFound")
            );
        }
        setShowLoader(null);
    };

    const handleDefaultViewClick = () => {
        onColumnsSubmit();
    };

    const handleRefreshGrid = () => {
        console.log("refresh grid");
        setShouldRefresh(true);
    };

    const backToReports = () => {
        history.goBack();
    }

    useEffect(() => {
        if (data) {
            setResult({
                data: data?.data.map((d) => {
                    if (!isNaN(d?.StoreNumber) && d?.StoreNumber) {
                        d.StoreNumber = parseInt(d.StoreNumber);
                    }
                    if (!isNaN(d?.Country) && d?.Country) {
                        d.Country = parseInt(d.Country);
                    }
                    if (!isNaN(d?.Year) && d?.Year) {
                        d.Year = parseInt(d.Year);
                    }
                    if (!isNaN(d?.Week) && d?.Week) {
                        d.Week = parseInt(d.Week);
                    }
                    if (!isNaN(d?.WorkOrderID) && d?.WorkOrderID) {
                        d.WorkOrderID = parseInt(d.WorkOrderID);
                    }
                    if (!isNaN(d?.AuditId) && d?.AuditId) {
                        d.AuditId = parseInt(d.AuditId);
                    }
                    if (!isNaN(d?.AuditorId) && d?.AuditorId) {
                        d.AuditorId = parseInt(d.AuditorId);
                    }
                    if (!isNaN(d?.NO_OF_IMAGES_SENT) && d?.NO_OF_IMAGES_SENT) {
                        d.NO_OF_IMAGES_SENT = parseInt(d.NO_OF_IMAGES_SENT);
                    }
                    if (!isNaN(d?.NO_OF_IMAGES_RECEIVED) && d?.NO_OF_IMAGES_RECEIVED) {
                        d.NO_OF_IMAGES_RECEIVED = parseInt(d.NO_OF_IMAGES_RECEIVED);
                    }
                    return d
                }), ...data
            })
        }
    }, [data]);

    const initialValues = {
        regionID: locationData?.Region?.RegionID,
        countryID: locationData?.Country?.[0]?.CountryID,
        year: dateStamp?.selectedYear,
        week: dateStamp?.selectedWeek,
    }

    return (
        <>
            {showLoader && <LoadMask {...showLoader} />}

            <div className="eq">
                <Header activeMenu={t("nielsennavigator-flowcontrolreport")}>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginInline: "auto 1em",
                            padding: '6px'
                        }}
                    >
                        <Button
                            className='k-secondary'
                            onClick={backToReports}
                        >
                            Back
                        </Button>
                    </div>
                </Header>
                {showForm ?
                    <div className="eqform">
                        <Form
                            initialValues={initialValues}
                            onSubmitClick={onSearchClick}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "0px 25px",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <Field
                                            name={"Region"}
                                            component={FormComboBox}
                                            label={t("REGION")}
                                            required={true}
                                            onChange={onChange}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="RegionName"

                                            valueField="RegionID"

                                            data={[locationData?.Region]}

                                            defaultValue={locationData?.Region?.RegionID}

                                        />


                                        <Field
                                            name={"Country"}
                                            component={FormComboBox}
                                            label={t("COUNTRY")}
                                            required={true}
                                            onChange={onChangeCountry}
                                            // onChange={onChange}

                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="CountryName"

                                            valueField="CountryID"

                                            data={locationData?.Country}

                                            defaultValue={locationData?.Country?.[0]?.CountryID}
                                        />

                                        <Field
                                            name="year"
                                            label={t("Year")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={"Year"}
                                            // data={["2017", "2018", "2019", "2020", "2021", "2022", "2023"]}
                                            data={year}
                                            defaultValue={dateStamp?.selectedYear?.DisplayValue}
                                            required={true}

                                            validator={inputValidator}
                                            onChange={onChangeYear}
                                        // onChange={onChange}
                                        />

                                        <Field
                                            name="week"
                                            label={t("WeekNumber")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("WeekNumber")}
                                            required={true}
                                            data={weekNumber}
                                            defaultValue={dateStamp?.selectedWeek?.DisplayValue}
                                            validator={inputValidator}
                                            onChange={onChangeWeekNumber}
                                        />

                                        <Field
                                            name="auditstatus"
                                            label={t("AuditStatus")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("AuditStatus")}
                                            //data={formAuditStatus}
                                            //value={formAuditStatusId}
                                            //placeholder={"Audit Status"}
                                            textField="Status_Name"
                                            valueField={"Status_ID"}
                                            data={auditStatus}
                                            onChange={onChange}
                                        />


                                        <Field
                                            name="JobId"
                                            label={t("Work Order Id")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={"Work Order Id"}
                                            data={formWorkOrderId}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="auditorId"
                                            label={t("AUDITOR ID")}
                                            component={FormInput}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("AUDITOR ID")}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="smsId"
                                            label={t("SMS ID")}
                                            component={FormInput}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("SMS ID")}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="plantype"
                                            label={t("PLAN_TYPE_LBL")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("PLAN_TYPE_LBL")}
                                            data={["ALL", "TEST", "LIVE"]}
                                            defaultValue="LIVE"
                                            onChange={onChange}
                                        />

                                        <div className={'k-actions k-hstack k-justify-content-stretch'} style={{ marginTop: "29px", gap: "0px 8px", marginLeft: "-7px" }}>
                                            <Button
                                                className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'}
                                                primary
                                            >
                                                {t("SEARCH")}
                                            </Button>
                                            <Button
                                                type={'reset'}
                                                className={'k-secondary'}
                                                onClick={() => {
                                                    setSelectedData(initialFormValue);
                                                    setTimeout(() => {
                                                        formRenderProps.onFormReset();
                                                    }, 0);
                                                }}
                                            >
                                                {t("RESET")}
                                            </Button>
                                        </div>
                                    </div>
                                </FormElement>
                            )}
                        />
                    </div>
                    :
                    <div></div>
                }

                <div
                    className="nielsen-card"
                    style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
                >
                    <div className="eq_data_heading" style={{ margin: "0px -6px" }}>
                        <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
                        <div>
                            <Button
                                onClick={onExport}
                                style={{ margin: "10px", border: "none" }}
                                primary
                            >{t("export")}</Button>

                            <Button icon={expandIcon}
                                onClick={expand}
                                className={'k-secondary'}
                                style={{ margin: "10px", marginRight: '20px' }}
                            ></Button>
                        </div>

                    </div>
                    <div className="catalog" style={{
                        display: "flex",
                        padding: "1rem",
                        flexGrow: 1,
                        overflow: "auto",
                    }}
                    >
                        <Grid
                            className="workOrders_grid"
                            data={result}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                console.log("sort", { e });
                                setResult((result) => {
                                    const { data, total } = result;
                                    return {
                                        data: orderBy(data || [], e.sort),
                                        total,
                                    };
                                });
                                setSort(e.sort);
                            }}

                            filter={filter}
                            onFilterChange={(e) => {
                                setResult((result) => {
                                    const { total } = result;
                                    return {
                                        data: filterBy(data?.data || [], e.filter),
                                        total,
                                    };
                                });
                                setFilter(e.filter)
                            }}

                            onPageChange={handlePageChange}
                            cellRender={customCellRender}
                            rowRender={customRowRender}

                            pageable={{
                                pageSizes: false,
                            }}

                            pager={CreatePager}
                            resizable
                            reorderable
                            onColumnReorder={({ columns }) => {
                                const columnOrder = columns.reduce((columnsObj, column) => {
                                    columnsObj[column.field] = column.orderIndex;
                                    return columnsObj;
                                }, {})
                                const newColumns = stateColumns.map((column) => {
                                    return { ...column, orderIndex: columnOrder[column.field] }
                                });
                                setStateColumns(newColumns);
                            }}

                        >
                            {stateColumns.map(
                                (column, idx) => {
                                    const menu = columnMenuConfig[column?.columnMenu];
                                    return column.show &&
                                        (

                                            <GridColumn
                                                key={column?.orderIndex}
                                                field={column?.field}
                                                title={t(column.title)}
                                                width={column?.width}
                                                minResizableWidth={30}
                                                style={{ textAlign: "center" }}
                                                cell={GridCell}
                                                format={column?.format}
                                                filter={column?.filter}
                                                orderIndex={column?.orderIndex}
                                                {...(menu && {
                                                    columnMenu: (props) => {
                                                        return menu?.({
                                                            ...props,
                                                            columns: stateColumns,
                                                            onColumnsSubmit,
                                                            data: data?.data,
                                                        });
                                                    },
                                                })}
                                                headerClassName={
                                                    isColumnActive(column.field, { ...dataState, filter })
                                                        ? "activeFiltered"
                                                        : ""
                                                }
                                            />
                                        )
                                })}
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    )
}


export default FlowControlReport
