import React, { useState, useEffect, useMemo } from "react";
import BulkUploads from "./bulkUploads";
import CoverageScreen from "./coverageScreen";
import ConverageScreenUpload from "./coverageScreenUpload";
import WorkOrderComp from "./workOrderComp";
import { useDispatch, useSelector } from "react-redux";
import { getAuditors } from "../../store/actions/commonActions";
import { useTranslation } from "react-i18next";
import Header from "../header";
import Tabs from "../ui/tabs";

export default function WorkOrder() {
  const { t } = useTranslation();
  const [activeScreen, setActiveScreen] = useState("workOrders");
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const dispatch = useDispatch();
  const screen = 3;
  
  const userroleDetails = useSelector((state)=> state.dashboardReducer.userrolepermssioninfo)
  const [roleWorkOrderPage,setRoleWorkOrderPage]=useState({})
  
 
   function getObjectByScreenId(screenId) {
     for (let i = 0; i < userroleDetails?.rolePermissionsInfo.length; i++) {
         if (userroleDetails?.rolePermissionsInfo[i].screensInfo.screenId === screenId) {
             return userroleDetails?.rolePermissionsInfo[i];
         }
     }
     return null; // Return null if not found
   }
 
   useEffect(()=>{
     setRoleWorkOrderPage(getObjectByScreenId(screen))
   },[])
 
   console.log(roleWorkOrderPage,screen,"WO-1234")

  const coveragescreen_required = JSON.parse(
    localStorage.getItem("coveragescreen_required")
  ) ?? false;

  const tabsObj = useMemo(() => {
    const tabs = {
      workOrders: {
        title: t("WORKORDERS"),
        comp: WorkOrderComp,
      },
      bulkUploads: {
        title: t("bulkUploads"),
        comp: BulkUploads,
      },
    };

    if (coveragescreen_required) {
      tabs.coverageScreen = {
        title: t("COVERAGE_SCREEN_TITLE_LBL"),
        comp: CoverageScreen,
      };
      tabs.coverageScreenUpload = {
        title: "Coverage Screen Uploads",
        comp: ConverageScreenUpload,
      };
    }
    return tabs;
  }, []);

  const Comp = tabsObj[activeScreen]?.comp;

  useEffect(() => {
    if (authContext) {
      const params = {
        Countryid: authContext?.CountryCode,
        Companyid: authContext?.CompanyID,
        page: 1,
        start: 0,
        limit: 25,
      };
      dispatch(getAuditors(params, authContext));
    }
  }, [authContext]);

  return (
    <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>

      <Header activeMenu={t("WorkOrder")}>
        <Tabs
          tabs={tabsObj}
          selected={activeScreen}
          onSelect={(key) => setActiveScreen(key)}
        >
          {({ title }) => {
            return <div>{title}</div>;
          }}
        </Tabs>
      </Header>
      <Lazy visible={activeScreen === 'workOrders'}>
        <WorkOrderComp setActiveScreen={setActiveScreen} activeScreen={activeScreen} tabsObj={tabsObj} visible={activeScreen === 'workOrders'} screen={screen} roleWorkOrderPage={roleWorkOrderPage}  />
      </Lazy>
      <Lazy visible={activeScreen === 'bulkUploads'}>
        <BulkUploads setActiveScreen={setActiveScreen} activeScreen={activeScreen} tabsObj={tabsObj} visible={activeScreen === 'bulkUploads'} screen={screen} roleWorkOrderPage={roleWorkOrderPage}  />
      </Lazy>
      {coveragescreen_required && (
        <>
          <Lazy visible={activeScreen === 'coverageScreen'}>
            <CoverageScreen setActiveScreen={setActiveScreen} activeScreen={activeScreen} tabsObj={tabsObj} visible={activeScreen === 'coverageScreen'} screen={screen} roleWorkOrderPage={roleWorkOrderPage}  />
          </Lazy>
          <Lazy visible={activeScreen === 'coverageScreenUpload'}>
            <ConverageScreenUpload setActiveScreen={setActiveScreen} activeScreen={activeScreen} tabsObj={tabsObj} visible={activeScreen === 'coverageScreenUpload'} screen={screen} roleWorkOrderPage={roleWorkOrderPage}  />
          </Lazy>
        </>
      )}
    </div>
  );
}

const Lazy = ({ visible, children }) => {
  const rendered = React.useRef(visible);

  console.log('children', children)

  if (visible && !rendered.current) {
    rendered.current = true;
  }

  if (!rendered.current)
    return null;

  return <div style={{
      display: visible ? 'flex' : 'none',
      flexDirection: 'column',
      minHeight: '93%',
      overflow: 'auto'
      }}
    >
    {children}
  </div>;
}
