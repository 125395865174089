import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";

const ViewMore = ({ workOrderDetail }) => {
  const [isView, setView] = useState(false);

  return (
    <>
      <a
        className="k-anchor"
        href="javascript:void(0);"
        onClick={() => {
          setView((b) => !b);
        }}
      >
        View {isView ? "Less" : "More"}
      </a>
      {isView && (
        <div
          className="shadow"
          style={{
            position: "absolute",
            backgroundColor: "rgba(102, 102, 102,.98)",
            width: "100%",
            top: "45px",
            left: "0",
            zIndex: 99,
            color: "#fff",
            display: "flex",
            padding: 30,
            flexWrap: "wrap",
          }}
        >
          <div style={{ flex: " 0 1 calc(16%)", padding: 15 }}>
            <div><b>Country</b></div>
            <div>{workOrderDetail?.CountryName}</div>
          </div>
          <div style={{ flex: " 0 1 calc(16%)", padding: 15 }}>
            <div><b>Updated On </b></div>
            <div>
              {moment(workOrderDetail?.UpdatedOn).format("ddd MMM d YYYY")}
            </div>
          </div>
          <div style={{ flex: " 0 1 calc(16%)", padding: 15 }}>
            <div><b>Collection Type</b></div>
            <div>{workOrderDetail?.CollectionType}</div>
          </div>
          <div style={{ flex: " 0 1 calc(16%)", padding: 15 }}>
            <div><b>Year </b></div>
            <div>{workOrderDetail?.year}</div>
          </div>
          <div style={{ flex: " 0 1 calc(16%)", padding: 15 }}>
            <div><b>Week Number </b></div>
            <div>{workOrderDetail?.week}</div>
          </div>
          <div style={{ flex: " 0 1 calc(16%)", padding: 15 }}>
            <div><b>Week Ending Date </b></div>
            <div>{moment(workOrderDetail?.WeekEndDate).format("ddd MMM d YYYY")}</div>
          </div>
          <div style={{ flex: " 0 1 calc(16%)", padding: 15 }}>
            <div><b>Updated By </b></div>
            <div>{workOrderDetail?.UpdatedBy}</div>
          </div>
          <div style={{ flex: " 0 1 calc(16%)", padding: 15 }}>
            <div><b>Created On </b></div>
            <div>
              {moment(workOrderDetail?.CreatedOn).format("ddd MMM d YYYY")}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewMore;
