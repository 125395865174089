import { CustomColumnMenuFilter } from "../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };


const columns = [
    {
        title:  "jobid",
        field: "WorkOrderID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title:  "Country Name",
        field: "Country",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "Year",
        field: "Year",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "Week",
        field: "Week",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:  "Auditor Id",
        field: "AuditorID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",        
        width: 130
    },
    {
        title: "# Generated",
        field: "Generated",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "# Found",
        field: "Found",
        show: true,
        columnMenu: "CustomColumnMenuFilter", 
        width: 130
        
    },
    {
        title: "# Not Found",
        field: "NotFound",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
        
    },
    {
        title: "Not Returned - Expired",
        field: "NotReturnedExpired",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
        
    },
    {
        title: "# Found - Proactive Coding",
        field: "FoundProactiveCoding",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "% Found",
        field: "FoundPercetage",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "# Out Of Stock",
        field: "OutOfStockUPCs",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "% Returned Non Product",
        field: "ReturnedNonProductPercentage",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "% Returned Not Found",
        field: "ReturnedNotFoundPercentage",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "% Not Returned",
        field: "NotReturnedPercentage",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:160
    },
    

];
export default columns;
