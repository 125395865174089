import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarOpenCloseAction } from '../../../../store/actions/reports';
import CommonFilters from './CommonFilters';
import './style.css';
import listSearch from '../../../../assets/img/list_search.png';
import listRemove from '../../../../assets/img/list_remove.png'
import TabHeader from './TabHeader';

export default function SideMenu({ activeIndex, sideMenuClose }) {
  const sidebarOpenClose = useSelector((store) => store?.reports?.sidebarOpenClose);
  const dispatch = useDispatch();
  const [tabFilterResponse, setTabFilterResponse] = useState("");

  const sidebarIconStyle = {
    width: 56,
    padding: 1,
    cursor: "pointer",
  };

  const callbackTabFilters = (payload) => {
    setTabFilterResponse(payload)
  };


  const open = (sidebarOpenClose) => {
    dispatch(sidebarOpenCloseAction(!sidebarOpenClose))
  }
  const domeNode = useRef()
  const updateState = (event) => {
    if (domeNode.current.contains(event.target)) {
      return
    }
    // setSideMenu(false)
  }
  useEffect(() => {
    document.addEventListener('mousedown', updateState)
    return () => {
      document.removeEventListener('mousedown', updateState)
    }
  }, [])
  return (
    <React.Fragment>
      <header className="topBar">
        <TabHeader activeIndex={activeIndex} tabFilterResponse={tabFilterResponse} />
        <div className="menuBar">
          <span
            ref={domeNode}
            className="navIcon"
            onClick={() => {
              open(sidebarOpenClose)
            }}
          >
            {sidebarOpenClose ?
              <img style={sidebarIconStyle} src={listRemove} alt="" /> :
              <img style={sidebarIconStyle} src={listSearch} alt="" />
            }
          </span>
        </div>
        <div className="sideMenu" style={{ left: sidebarOpenClose ? '0' : '-265px' }}>
          <CommonFilters activeIndex={activeIndex} callbackTabFilters={callbackTabFilters} />
        </div>
      </header>
    </React.Fragment>
  )
}
