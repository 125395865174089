import React from "react";
import Tabs from "../../../ui/tabs";

const TabHeader = ({resourceTabs, activeTab, setActiveTab, children }) => {
  return (
    <div style={{ display: "flex" }}>
      <Tabs
        tabs={resourceTabs}
        selected={activeTab}
        onSelect={(key) => setActiveTab?.(key)}
      >
        {({ title }) => {
          return <div>{title}</div>;
        }}
      </Tabs>
      {children}
    </div>
  );
};

export default TabHeader;
