import { getter } from '@progress/kendo-data-query';
import { getSelectedState, Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react'
import { useSelector } from 'react-redux';
import { putNRPSStoreStatusUpdate } from '../../../../../store/actions/reports';
import { CellDropDown, CellRender, RowRender } from '../../../../ui/grid/renders';
import auditorColumns from './auditorColumns';
import kpiColumns from './kpiColumns';
import useLogout, { checkInvalidSession } from '../../../../../utils/useLogout';



const initialDataState = {
    skip: 0,
    take: 50,
  };

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "smsId";
const idGetter = getter(DATA_ITEM_KEY);
const EDIT_FIELD = 'inEdit';

const AuditorGrid = ({height, commonFiltersParams, callbackStoreId}) => {
  const {handleAuthContext} = useLogout();
  const auditorData = useSelector((store) => store.reports?.nrpsReportAuditorClick);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  let spocDetails = JSON.parse(localStorage.getItem(`spocDetails`));

    const [page, setPage] = React.useState(initialDataState);
    const [response, setResponse] = React.useState([])
    const [selectedState, setSelectedState] = React.useState({});
    const [stateAuditorColumns, setStateAuditorColumns] = React.useState(auditorColumns);
    const [companyId, setCompanyId] = React.useState([]);
    const [countaryCode, setCountaryCode] = React.useState([]);
     

    const pageChange = (event) => {
        setPage(event.page);
    };

    React.useEffect(()=>{
      setResponse(auditorData?.data)
    },[auditorData]);
    console.log("auditorDatarespons", auditorData)

    React.useEffect(() => {
      if (authContext && spocDetails) {      
        let CompanyId = spocDetails?.CompanyID
        let countryCode = spocDetails?.CountryCode
        setCompanyId(CompanyId);
        setCountaryCode(countryCode)               
     }
    }, [authContext]);

    const itemChange = (event) => {
      console.log("eventItem", event)
      let field = event.field || "";
      // const newValue = event.value;
      const { value, dataItem } = event;
      // event.dataItem[field] = value;
      let newData = response.map((item) => {
        if (item?.smsId === dataItem?.smsId) {
          const { edited = [] } = item;
          if (value !== dataItem?.[field]) {
            item.edited = [...edited, field];
          } else {
            item.edited = edited?.filter((fieldName) => fieldName !== field);
          }
          item[field] = value;
        }
        return item;
      });
      setResponse(newData);
      // setChanges(true);
    };

    const onSelectionChange = React.useCallback((event) => {
          const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
          });
          setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    },[]);
    callbackStoreId(selectedState);  

    const enterEdit = (dataItem, field) => {
      console.log("audREsponse", response)
      const newData = response?.map(item => ({
        ...item,
        [EDIT_FIELD]: item.smsId === dataItem.smsId ? field : undefined
      }));
      setResponse(newData);
    };

    const exitEdit = async () => {
      // console.log("progressDaTA",progressData?.data)
      const data = response.find((e)=>{
        return  e?.[EDIT_FIELD]
      })
      if(authContext){
        const params = {
          "vendorId": 282,
          // "vendorId": companyId,
          // "country": countaryCode,
          "country": 380,
          "year": commonFiltersParams?.year,
          "week": commonFiltersParams?.week,        
          "storeId": data?.smsId,
          "status": data?.storeStatus,
          "vendorNotes": data?.vendorNotes,
          "niqNotes": data?.niqNotes    
        }      
        const res = await putNRPSStoreStatusUpdate(params,authContext);
        console.log("exitres", res)
        if (checkInvalidSession(res)) {
          handleAuthContext();
          return;
        }
        if(res.status == "Success") {
          // showToast("error", data?.Response?.Message, {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });
          // response.map((item)=>{
          //   if(item?.smsId === data?.smsId){
          //     item.storeStatus = res.data
          //   }
          //   return item;
          // })
            
          }else {
            console.log("error ");
          //   if(data?.Response?.sessionstatus === "400")
          //   showToast("error", data?.Response?.Message, {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });
          }  
                 
    };
      // console.log("exitData", data)
      const newData = response?.map(item => ({
        ...item,
        [EDIT_FIELD]: undefined
      }));
      setResponse(newData);
    };

    const customCellRender = (td, props) => {
      console.log("customCellRender", props)
      // return <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;
      switch (props.field) {
        case "storeStatus":
          const data =  ["IN PROGRESS","COMPLETE","INCOMPLETE - STORE ","INCOMPLETE - VENDOR","INCOMPLETE-OTHER"]  
          return (
            <CellDropDown
              originalProps={props}
              td={td}
              enterEdit={enterEdit}
              editField={EDIT_FIELD}
              cellProps = {{data}}
              // cell={<ComboBoxGrid {...props}/>}
            />
          );          
        default:
          return (
            <CellRender
              originalProps={props}
              td={td}
              enterEdit={enterEdit}
              editField={EDIT_FIELD}
            />
          );
      }
    }
    const customRowRender = (tr, props) => {
      return <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />
    };

  return (
    <React.Fragment>
      <Grid        
        resizable={true}
        data={response
          ?.slice(page.skip, page.take + page.skip)
          .map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
        style={height}
        skip={page.skip}
        take={page.take}
        total={response?.length}
        pageable={true}
        onPageChange={pageChange}
        // pageable={{
        //     pageSizes: [5, 20, 50, 100, 200, 500, 1000],
        //   }}
        dataItemKey={DATA_ITEM_KEY}   
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        editField={EDIT_FIELD}
        onItemChange={itemChange}
        cellRender={customCellRender}
        rowRender={customRowRender}
      >
        <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="40px"
        headerSelectionValue={
          response?.findIndex((item) => !selectedState[idGetter(item)]) === -1
        } />
        {stateAuditorColumns.map((column, idx) => {
          return column.show && (                
            <GridColumn key={idx} field={column.field} title={column.title} width={column.width} editable={column.editable}/>
          );
        })}
      </Grid>
      
    </React.Fragment>
  )
}

export default AuditorGrid
