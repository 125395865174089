import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    {
        title: "COUNTRY",
        field: "Country",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "collectiontype",
        field: "CollectionType",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "YEAR",
        field: "Year",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "WEEK",
        field: "Week",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "WORKORDERID",
        field: "WorkOrderID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "AUDIT ID",
        field: "AuditId",
        show: true,
        // cell:UploadCell
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "AUDITOR ID",
        field: "AuditorId",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "auditortype",
        field: "AuditorType",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "SMS ID",
        field: "StoreNumber",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "startdate",
        field: "STARTDATE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "date",
        // format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "enddate",
        field: "ENDDATE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "date",
        // format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "aigenerated",
        field: "AI_GENERATED",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "receivedcollecteddata",
        field: "RECEIVED_COLLECTED_DATA",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "irprequired",
        field: "IRP_REQUIRED",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "noofimagessent",
        field: "NO_OF_IMAGES_SENT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "senttoirp",
        field: "SENT_TO_IRP",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title:"noofimagesreceived",
        field: "NO_OF_IMAGES_RECEIVED",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "irpconsumed",
        field: "IRP_CONSUMED",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "irpcompleted",
        field: "IRP_COMPLETED",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "reportgenerated",
        field: "REPORT_GENERATED",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "senttofactory",
        field: "SENT_TO_FACTORY",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "PRIMARY_RECEIVE_MERGE_TIMESTAMP",
        field: "PRIMARY_RECEIVE_MERGE_TIMESTAMP",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title:  "RECEIVE_MERGE_STATUS_FOR_PRIMARY_AUDITOR",
        field: "RECEIVE_MERGE_STATUS_FOR_PRIMARY_AUDITOR",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "SEND_MERGE_STATUS_FOR_SECONDARY_AUDITOR",
        field: "SEND_MERGE_STATUS_FOR_SECONDARY_AUDITOR",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "SECONDARY_SEND_MERGE_TIMESTAMP",
        field: "SECONDARY_SEND_MERGE_TIMESTAMP",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title:  "TeamStore",
        field: "TeamStore",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    
    
    
];
export default columns;
