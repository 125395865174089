import { Field, Form, FormElement } from '@progress/kendo-react-form';
import React, { useState, useEffect } from 'react';
import { FormComboBox, FormDatePicker, FormInput } from '../../form/form-component';
import { inputValidator } from '../../form/validator';
import { useHistory } from "react-router-dom";
import "./eCollection.css";
import { Grid, GridCell, GridColumn } from "@progress/kendo-react-grid";
// import columns from "./common/columnSummaryData";
import columns from "./nrps/common/columnSummaryData"
// import {getSummaryReport} from "./nrps/common/EcollectionSummary1"
// import { useState } from 'react';
// import columns from "./common/columnMiscellaneous";
import { EcollectionSummary1 } from "./nrps/common/EcollectionSummary1";
import { useSelector } from 'react-redux';
import { getSummaryReport } from '../../../store/actions/reports/EcollectionSummary12';
import { getSummary } from '../../../store/actions/reports/EcollectionSummary12';
import { useTranslation } from "react-i18next";


const EcollectionSummary = () => {
    const authContext = useSelector((state) => state.commonReducer.authContext);
    // const [inputData, setinputData] = useState();
    const history = useHistory();
    const [stateColumns, setStateColumns] = useState(columns);
    const { t, i18n } = useTranslation();
    // const [region, setRegion] = useState();
    // const [country, setCountry] = useState();
    const locationData = useSelector((state) => state.commonReducer.locationData);
    const [weekValue, setweekValue] = useState();
    // let weekValue1;


    const onWeekChange = (e) => {
     setweekValue(e.target.value);
    //    weekValue = setweekValue(e.target.value);
        //  console.log("shivi" + weekValue);
        // weekValue=e.target.value;
        
        
    }
    
    useEffect(() => {
       const  fetchSummary=async()=>{
            let params = {
                "Region": "1",
                "Country": "124",
                "CollectionType": "ECOLLECTION",
                "year": "2023",
                "week": weekValue,
                "CompanyID": "406"
            }
            const result1 = await getSummary(params);
            console.log(result1);
        }
        
       fetchSummary()
    },[weekValue])

    
    // const onWeekinit =  () => {
    //     let d = new Date();
    //     let date = d.getDate();
    //     let day = d.getDay();
    //     let currentWeek = Math.ceil((date + 6 - day) / 7);
    //     console.log("shivi init" + currentWeek);
    //     // setweekValue(currentWeek);
    //     weekValue1 = currentWeek;
    // }

    // useEffect(() => {
    //     onWeekinit();
    // })


    // const onWeekChange =  (e) => {
    //      setweekValue(e.target.value);
    // }

    const backToReports = () => {
        history.goBack();
    }

    const onSearchClick = async () => {


        let params = {
            "SummaryReportReq": JSON.stringify({
                "Request": {
                    "Region": "1",
                    "Country": "124",
                    "CollectionType": "ECOLLECTION",
                    "Cycle": null,
                    "Year": "2023",
                    "Week": "2",
                    "FromDate": "",
                    "ToDate": "",
                    "WorkOrderID": "",
                    "CompanyId": "406",
                    "StoreNumber": "",
                    "AuditorId": "",
                    "StoreType": "ACTUAL"
                }
            }), page: 1, StartCount: 0, MaxCount: 50
        }
        const res = await getSummaryReport(params, authContext);
        // console.log("shivani" + res);

    }

    return (

        <div className='EcollectionSummary'>
            <span className="k-icon k-i-arrow-left" style={{ fontSize: "50px" }} id="back" onClick={backToReports}></span>  <h2 className='heading'> {t("nielsennavigator-summaryreport")}+ "shivani"</h2>
            <hr></hr>
            <div className="dctform">
                <Form
                    initialValues={{
                        regionID: locationData?.Region?.RegionID,
                        countryID: locationData?.Country?.[0]?.CountryID,


                    }}

                    render={(formRenderProps) => (
                        <FormElement>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "0px 25px",
                                    flexDirection: "row",
                                    flexWrap: "wrap",

                                }}
                            >
                                <Field
                                    name={"regionID"}
                                    component={FormInput}
                                    label={t("REGION")}
                                    required={true}
                                    valueField="RegionID"
                                    data={[locationData?.Region]}
                                    defaultValue={locationData?.Region?.RegionID}
                                    disabled

                                    wrapperStyle={{ width: "calc(25% - 15px)", fontSize: "15px", fontWeight: "500" }}
                                />


                                <Field
                                    name={"Country"}
                                    component={FormComboBox}
                                    label={t("COUNTRY")}
                                    required={true}
                                    // data={["CANDA", "U.S."]}
                                    validator={inputValidator}
                                    textField="CountryName"
                                    valueField="CountryID"
                                    data={locationData?.Country || []}
                                    wrapperStyle={{ flex: 1 }}
                                    defaultValue={locationData?.Country?.[0]?.CountryID}
                                />
                                <Field
                                    name="year"
                                    label={t("YEAR")}
                                    component={FormComboBox}
                                    wrapperStyle={{ width: "calc(25% - 15px)", fontSize: "15px", fontWeight: "500" }}
                                    placeholder={t("YEAR")}
                                    data={["2023", "2022", "2021", "2020", "2019", "2018"]}
                                    defaultValue={2023}
                                    required={true}
                                    validator={inputValidator}
                                />
                                <Field
                                    name="weak number"
                                    label={t("WeekNumber")}
                                    component={FormComboBox}
                                    placeholder={t("WeekNumber")}
                                    wrapperStyle={{ width: "calc(25% - 15px)", fontSize: "15px", fontWeight: "500", marginLeft: "-18px" }}
                                    // placeholder={"Weak Number"}
                                    data={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47"]}
                                    required={true}
                                    onChange={onWeekChange}
                                    // defaultValue={2}
                                    validator={inputValidator}
                                />
                                <Field
                                    name="work order id"
                                    label={t("WORKORDERID")}
                                    component={FormInput}
                                    wrapperStyle={{ width: "calc(25% - 15px)", fontSize: "15px", fontWeight: "500" }}
                                    placeholder={"End Date"}
                                    required={true}
                                    validator={inputValidator} />

                                <Field
                                    name="auditor id"
                                    label={t("AUDITOR ID")}
                                    component={FormInput}
                                    wrapperStyle={{ width: "calc(25% - 15px)", fontSize: "15px", fontWeight: "500" }}
                                    placeholder={t("AUDITOR ID")}
                                    required={true}
                                    validator={inputValidator}

                                />

                                <Field
                                    name="smsId"
                                    label={t("SMS ID")}
                                    component={FormComboBox}
                                    wrapperStyle={{ width: "calc(25% - 15px)", fontSize: "15px", fontWeight: "500" }}
                                    placeholder={t("SMS ID")}
                                    required={true}
                                    validator={inputValidator}
                                />
                                <Field
                                    name="planType"
                                    label={t("PLAN_TYPE_LBL")}
                                    component={FormComboBox}
                                    wrapperStyle={{ width: "calc(25% - 15px)", fontSize: "15px", fontWeight: "500", marginLeft: "-18px" }}
                                    placeholder={t("PLAN_TYPE_LBL")}
                                    data={["ALL", "TEST", "LIVE"]}
                                    required={true}
                                    validator={inputValidator}
                                />

                            </div>
                        </FormElement>
                    )}
                />
            </div>
            <div className={'k-actions k-hstack k-justify-content-stretch'} style={{ marginTop: "27px", gap: "0px 8px" }} id={'prebtn'}>
                <button
                    onClick={onSearchClick}
                    className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'}
                    style={{ background: "black", color: "white", padding: "12px 12px 12px 12px", fontWeight: "bold" }}
                >{t("SEARCH")}</button>
                <button type={'reset'} className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'} style={{ background: "#666", color: "white", padding: "12px 12px 12px 12px", fontWeight: "bold" }}> {t("RESET")}</button>
            </div>

            <div className={'k-actions k-hstack k-justify-content-stretch 12'} style={{ marginTop: "27px", gap: "0px 8px" }} id={'btn1'}>
                <button type={'reset'} className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base 1'} style={{ background: "#666", color: "white", padding: "12px 12px 12px 12px", fontWeight: "bold", marginLeft: "670px", fontSize: "18px" }}><span className="k-icon k-i-sort-asc-sm desc-sm"></span>{t("customizeviewlbl")}</button>
                <button type={'reset'} className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base 1'} style={{ background: "#666", color: "white", padding: "12px 12px 12px 12px", fontWeight: "bold", fontSize: "18px" }}>{t("export")}</button>
                <button type={'reset'} className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base 1'} style={{ background: "#666", color: "white", padding: "17px 17px 17px 17px", fontWeight: "bold", fontSize: "18px" }}><span className="k-icon k-i-pan"></span></button>
            </div>



            <div className="catalog" style={{ paddingTop: "30px", paddingBottom: "30px", height: "30%" }}>
                <Grid
                    className="workOrders_grid"
                    sortable={true}
                >
                    {stateColumns.map(
                        (column, idx) => {

                            return (


                                <GridColumn

                                    key={idx}
                                    field={column?.field}
                                    //title={column?.title}
                                    title={t(column.title)}
                                    width={200}
                                    minResizableWidth={30}

                                />
                            )
                        })}
                </Grid>
            </div>

            <div className='pagination'>
                <a >❮</a>
                <a >❯</a>
            </div>
            <div className='pagination2'>
                <p><b>Total Records:</b>0</p>
            </div>
        </div>

    )
}

export default EcollectionSummary;