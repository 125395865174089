
const sortByAZ = "Sort By A - Z";
const sortByZA = "Sort By Z - A";
const sortByAudIdASC = "Sort By Auditor ID ASC";
const audSortType = {
    sortByAZ,
    sortByZA,
    sortByAudIdASC
}
export const sortList = {
    sortByAZ: {
      title: audSortType?.sortByAZ,
      sortFunc: (f, s) => {
        const firstName = `${f?.FIRST_NAME} ${f?.LAST_NAME}`?.toUpperCase();
        const secondName = `${s?.FIRST_NAME} ${s?.LAST_NAME}`?.toUpperCase();
        return firstName.localeCompare(secondName);
      },
    },
    sortByZA: {
      title: audSortType?.sortByZA,
      sortFunc: (f, s) => {
        const firstName = `${f?.FIRST_NAME} ${f?.LAST_NAME}`?.toUpperCase();
        const secondName = `${s?.FIRST_NAME} ${s?.LAST_NAME}`?.toUpperCase();
        return secondName.localeCompare(firstName);
      },
    },
    sortByAudIdASC: {
      title: audSortType?.sortByAudIdASC,
      sortFunc: (f, s) => {
        return f.RESOURCE_ID - s?.RESOURCE_ID;
      },
    },
  };