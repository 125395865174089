import * as types from "../../actions/types";

const initialState = {
    fetchAssignedCourseResponse: null,
    fetchAssignedProgramResponse: null,
    fetchCompletedCourseResponse: null,
    fetchCompletedProgramResponse: null,
}

const learningReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case types.GET_ASSIGNED_COURSE:
            newState = {
                ...state,
                fetchAssignedCourseResponse: action.payload
            }
            break;
        case types.GET_ASSIGNED_PROGRAM:
            newState = {
                ...state,
                fetchAssignedProgramResponse: action.payload
            }
            break;
        case types.GET_COMPLETED_COURSE:
            newState = {
                ...state,
                fetchCompletedCourseResponse: action.payload
            }
            break;
        case types.GET_COMPLETED_PROGRAM:
            newState = {
                ...state,
                fetchCompletedProgramResponse: action.payload
            }
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}

export default learningReducer;