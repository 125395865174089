import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getExportFile, getExportHistoryData } from './common/raSummaryRequests';
import useLogout, { checkInvalidSession } from '../../../../utils/useLogout';
import { setOrderIndex } from '../../../../utils/Utility';
import columns from './common/columnExportHistroy';
import LoadMask from '../../../../utils/LoadMask';
import { showToast } from '../../../../utils/Notification';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from '@progress/kendo-react-data-tools';
import { columnMenuConfig } from './common/columnRA';
import { isColumnActive } from '../../../grid/CustomColumnMenu';
import { MyPager } from '../../../generalHooks';
import { updateUserPreferenceAction } from '../../../../store/actions/commonActions';
import { searchUserPrefrence } from '../../../../store/actions/dashboard';
import { filterBy } from '@progress/kendo-data-query';
import AppURL from '../../../../utils/AppURL';
import { PostwithTimeoutHandler } from '../../../../store/methods';
import { getExportDataWithPostTimeoutHandler, getExportDataWithPostTimeoutHandlerZip } from '../../eCollection/summaryReport/common/getSummaryReport';

const initialColumnsState = setOrderIndex(columns);

const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            take: dataState.take,
        },
        dataState: dataState,
    };
};

let initialState = createDataState([], {
    take: 20,
    skip: 0,
});

const GRID_NAME = "RA_SUMMARY_GRID_EXPORT_HISTORY"

const ExportHistory = ({
    exportHistory,
    setExportHistory
}) => {

    const { t } = useTranslation();
    const { handleAuthContext } = useLogout();
    const locationData = useSelector((state) => state.commonReducer.locationData);
    const authContext = useSelector((state) => state.commonReducer.authContext);
    const [stateColumns, setStateColumns] = useState(initialColumnsState);
    const [showLoader, setShowLoader] = useState(null);
    const [shouldRefresh, setShouldRefresh] = useState(true);
    const [exportHistoryData, setExportHistoryData] = useState({
        result: { data: [], total: 0 },
        initialResult: { data: [], total: 0 }
    });
    const [dataState, setDataState] = useState(initialState.dataState);
    const [filter, setFilter] = useState(null);
    const [sort, setSort] = useState([
        {
            field: "productid",
            dir: "desc",
        },
    ]);
    const serverColumns = useSelector(
        (state) => state.commonReducer.gridUserPrefrence
    );
    const dispatch = useDispatch();


    const handleExportHistory = () => {
        setExportHistory(!exportHistory);
    }

    const getExportHistory = async () => {
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        let params = {
            "countryID": locationData?.Country?.[0]?.CountryID,
            "reportType": "NAV_AUDIT_SUMMARY_REPORT",
        }

        const header = {
            userid: authContext?.SpocMail || "",
            countryid: authContext?.CountryCode || "",
            companyid:authContext?.CompanyID || "",
            "content-type": "application/json",
            // "sessionid": "cDTqCPepeaJ5g3Xs",
            // "content-type": "application/x-www-form-urlencoded; application/json",
          }

        const params2 = {
            "CollectionType": "RA",
            "ReportName": "SUMMARY_REPORT",
            "APIName": "common",
            "Payload": {  
             
                    "countryID": locationData?.Country?.[0]?.CountryID,
                    "reportType": "NAV_AUDIT_SUMMARY_REPORT",
                
            }
        }

        // const res = await getExportHistoryData(params)
        const res = await PostwithTimeoutHandler(AppURL.reportsAPI, JSON.stringify(params2), header,true,100000).then((res) =>
            // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
              res.json()
            );
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        const responseObj = {
            data: res?.GeneratedReportList?.record || [],
            total: res?.GeneratedReportList?.record?.length || 0
        }

        setExportHistoryData({
            result: responseObj,
            initialResult: responseObj
        });

        setShowLoader(null);
    }

    useEffect(() => {
        if (shouldRefresh) {
            getExportHistory();
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    useEffect(() => {
        if (serverColumns?.length > 0) {
            const currentDataColumns = serverColumns.find(
                (c) => c?.Grid_Name === GRID_NAME
            );
            try {
                const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
                if (currentStateColumns.every((column) => column.field)) {
                    setStateColumns(currentStateColumns);
                }
            } catch (e) {
                console.log("error in state columns", currentDataColumns?.Json_object);
                return;
            }
        }
    }, [serverColumns]);

    const handleDownload = async (filename) => {
        const params = {
            filename
        }
        setShowLoader({
            // className: ".workOrders_grid",
            msg: "loading",
        });
        showToast(
            "info",
            t('filedownload_msglbl')
        );
        // no need to json stringify

        let params2={
            "CollectionType":"RA",
            "ReportName":"SUMMARY_REPORT",
            "APIName":"file",
            "Payload":{
               ...params 
            }
        }


        const res = await getExportDataWithPostTimeoutHandlerZip(params2,authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        setShowLoader(null);
    }

    const customCellRender = (td, props) => {
        const { dataItem, field } = props;
        if (field === 'action') {
            return (<td {...td.props} style={{ textAlign: "center" }}>
                {dataItem.status === 'SUCCESS' && (
                    <Button
                        className='k-button k-secondary'
                        onClick={() => handleDownload(dataItem.report_name)}
                    >
                        {t("Download report")}
                    </Button>
                )}
            </td>)
        } else {
            const style = field === 'productid' ? { textAlign: "right" } : { textAlign: "left" };
            const dataValue = dataItem[field];
            const value = ["request_ts", "result_ts"].includes(field) ? moment(new Date(dataValue?.toString())).format('DD-MMM-YY HH:mm:ss') : dataValue?.toString();
            return <td {...td.props} style={style}>{value}</td>
        }
    };

    const handleRefreshClick = () => {
        setShouldRefresh(true);
    }

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState || initialColumnsState);
    };

    const handleSaveViewClick = async () => {
        const params = {
            UserPrefReq: {
                Email: authContext.SpocMail,
                Company_ID: authContext.CompanyID,
                Grid_Name: GRID_NAME,
                Country_ID: locationData?.Country?.[0]?.CountryID,
                Json_Object: JSON.stringify(stateColumns),
            },
        };
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await updateUserPreferenceAction(
            JSON.stringify(params),
            authContext
        );
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.Response?.Status === "Success") {
            dispatch(
                searchUserPrefrence(
                    JSON.stringify({
                        UserPrefReq: {
                            Email: authContext?.SpocMail,
                            Company_ID: authContext?.CompanyID,
                        },
                    })
                )
            );
            showToast(
                "success",
                t(res?.Response?.Message || "Success")
            );
        } else {
            showToast(
                "error",
                t(res?.Response?.Message || "dataNotFound")
            );
        }
        setShowLoader(null);
    };

    const handleDefaultViewClick = () => {
        onColumnsSubmit();
    };

    const CreatePager = useCallback((props) => (
        <MyPager
            {...props}
            onSaveViewClick={handleSaveViewClick}
            onDefaultViewClick={handleDefaultViewClick}
        >
            <div style={{ flex: 1 }}></div>
        </MyPager>
    ), [stateColumns]);

    return (
        <>
            {showLoader && <LoadMask {...showLoader} />}
            <div
                className="nielsen-card"
                style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
            >
                <div className="eq_data_heading">
                    <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
                    <div className='export-history-btn-group'>
                        <div
                            className="niq-refresh-button"
                            onClick={handleRefreshClick}
                        >
                            {/* REFRESH ICON.SVG */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                fill="rgba(0,0,0,.9)"
                            >
                                <path d="M463.5 224h8.5c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2S461.9 48.1 455 55l-41.6 41.6c-87.6-86.5-228.7-86.2-315.8 1-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2S334.3 224 344 224h119.5z"></path>
                            </svg>
                        </div>
                        <Button
                            primary
                            onClick={handleExportHistory}
                            style={{ margin: "10px 15px" }}
                        >{t("Search list")}</Button>
                    </div>

                </div>
                <div className="catalog" style={{
                    display: "flex",
                    padding: "1rem",
                    flexGrow: 1,
                    overflow: "auto",
                }}
                >
                    <Grid
                        className="workOrders_grid"
                        data={exportHistoryData.result}
                        cellRender={customCellRender}
                        pageable={{
                            pageSizes: false,
                        }}
                        pager={CreatePager}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            const sortedData = {
                                data: orderBy(exportHistoryData.result.data || [], e.sort),
                                total: exportHistoryData.result.total,
                            };
                            setExportHistoryData({
                                ...exportHistoryData,
                                result: sortedData
                            });
                            setSort(e.sort);
                        }}
                        filter={filter}
                        onFilterChange={(e) => {
                            if (e.filter) {
                                const filterData = filterBy(exportHistoryData.initialResult.data || [], e.filter)
                                setExportHistoryData({
                                    ...exportHistoryData,
                                    result: { data: filterData, total: filterData.length }
                                });
                            } else {
                                setExportHistoryData({
                                    ...exportHistoryData,
                                    result: exportHistoryData.initialResult
                                })
                            }
                            setFilter(e.filter)
                        }}
                        resizable
                        reorderable
                        onColumnReorder={({ columns }) => {
                            const columnOrder = columns.reduce((columnsObj, column) => {
                                columnsObj[column.field] = column.orderIndex;
                                return columnsObj;
                            }, {})
                            const newColumns = stateColumns.map((column) => {
                                return { ...column, orderIndex: columnOrder[column.field] }
                            });
                            setStateColumns(newColumns);
                        }}
                    >
                        {stateColumns.map(
                            (column) => {
                                const menu = columnMenuConfig[column?.columnMenu];
                                return column.show &&
                                    (
                                        <GridColumn
                                            key={column?.orderIndex}
                                            field={column?.field}
                                            title={t(column.title)}
                                            width={column?.width}
                                            minResizableWidth={30}
                                            format={column?.format}
                                            filter={column?.filter}
                                            orderIndex={column?.orderIndex}
                                            {...(menu && {
                                                columnMenu: (props) => {
                                                    return menu?.({
                                                        ...props,
                                                        columns: stateColumns,
                                                        onColumnsSubmit,
                                                        data: exportHistoryData.result?.data,
                                                    });
                                                },
                                            })}
                                            headerClassName={
                                                isColumnActive(column.field, { ...dataState, filter })
                                                    ? "activeFiltered"
                                                    : ""
                                            }
                                        />
                                    )
                            })}
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default ExportHistory