import React from 'react';
import layer1 from '../../../assets/images/reports/layer1.png';
import { useHistory } from "react-router-dom";

const RA = () => {
    const history = useHistory();
    const [displayReport, setDisplayReport] = React.useState(false)


    const openReport = () => {
        console.log("click before", displayReport)
        setDisplayReport(!displayReport)
    }

    const characters = [
        {
            id: 1,
            reportName: "Audit Summary Report",
            reportImage: <img className='img-style' src={layer1} alt="report" />,
            navigateIcon: <span
                className="k-icon k-i-chevron-right k-icon-32"
                style={{ fontSize: 29 }}
            />,
            path: "/reports/RA/auditSummary",
        },
    ];

    const CardListItem = (props) => {
        return (
            <li className='card-container' onClick={() => history.push(props.character.path)} >

                <p className='all-reports-name'>
                    {props.character.reportName}
                </p>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p>{props.character.reportImage}</p>
                    <p>{props.character.navigateIcon}</p>
                </div>
            </li>
        );
    };
    const CardList = () => {
        return (
            <ul style={{ listStyleType: "none", padding: 0, display: 'flex', flexWrap: "wrap", gap: "30px" }}>
                {characters.map((character) => {
                    return (
                        <React.Fragment>
                            <CardListItem character={character} key={character.id} openReport={openReport} />
                        </React.Fragment>
                    );
                })}
            </ul>
        );
    };
    return (
        <React.Fragment>
            <CardList />
        </React.Fragment>
    )
}

export default RA
