import React, { useState } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";

const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            take: dataState.take,
        },
        dataState: dataState,
    };
};

let initialState = createDataState([], {
    take: 50,
    skip: 0,
});

function UpcCategory() {
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [result, setResult] = React.useState(initialState.result);

    return (
        <Grid
            data={result}
            {...dataState}
            sortable={true}
            style={{ height: "270px" }}
        >
            <GridToolbar>
                <div className='upc-counts-header'>
                    <p>Category UPC Counts</p>
                </div>
            </GridToolbar>
            <Column field="CategoryName" title="Category Name" />
            <Column title="UPC Count">
                <Column field="UnitPrice" title="Assigned" />
                <Column field="UnitsInStock" title="Available" />
                <Column field="UnitsInStock" title="Default" />
            </Column>
        </Grid>
    )
}

export default UpcCategory
