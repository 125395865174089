import React, { useState, useEffect, useCallback, useRef } from "react";
import './ReportDCT.css';
// import { Post } from "./store/methods";
import { Grid, GridCell, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { ColumnMenuFilterForm, Pager } from "@progress/kendo-react-data-tools";
import { Popup } from "@progress/kendo-react-popup";
import { useLocalization } from "@progress/kendo-react-intl";
import { inputValidator } from "../../../form/validator";
import { useTranslation } from "react-i18next";
import { FormComboBox, FormDatePicker, FormInput } from "../../../form/form-component";
import columns, { columnMenuConfig } from "./common/column";

import { getNavigatorReportBasic } from "./common/dctReport";

import { getDCTExportData, getDCTTableBasic } from "./common/dctTable";
import { toast } from "react-toastify";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import regionCountry from "../../../../utils/regionCountry";
import { CustomColumnMenu } from "../../../ui/grid/CustomColumnMenu";
import { showToast } from "../../../../utils/Notification";
import { updateUserPreferenceAction } from "../../../../store/actions/commonActions";
import LoadMask from "../../../../utils/LoadMask";
import { useHistory } from "react-router-dom";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { isColumnActive } from "../../../grid/CustomColumnMenu";
import { searchUserPrefrence } from '../../../../store/actions/dashboard';
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";
import { MyPager } from "../../../generalHooks";
import Header from "../../../header";
import { getExportDataWithPostTimeoutHandler } from "../../eCollection/summaryReport/common/getSummaryReport";

const GRID_NAME = "DCT_STATUS_GRID"

const today = new Date();
const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

const formattedToday = dd + '/' + mm + '/' + yyyy;

var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
var currentDateTime = formattedToday + ' ' + time;

const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            take: dataState.take,
        },
        dataState: dataState,
    };
};

let initialState = createDataState([], {
    take: 20,
    skip: 0,
});

const setOrderIndex = (columns) => {
    return columns.map((column, i) => ({ ...column, orderIndex: i }))
}

const initialColumnsState = setOrderIndex(columns);

function ReportDCT(props) {

    //DctForm

    const authContext = useSelector((state) => state.commonReducer.authContext);
    const { handleAuthContext } = useLogout();
    const { t } = useTranslation();
    const [formdct, setFormdct] = useState([]);
    const [data, setData] = useState({ data: [], total: 0 });
    const [region, setRegion] = useState([])
    const [country, setCountry] = useState([]);
    const initialFormComboBoxData = {
        formsurveyId: [],
        formplanId: [],
        formstoreId: [],
        formstatus: [],
    }
    const [formComboBoxData, setFormComboBoxData] = useState(initialFormComboBoxData);
    const [selectedData, setSelectedData] = useState({});
    const [showForm, setShowForm] = useState(true)

    const [year, setYear] = useState([]);

    const [result, setResult] = React.useState(initialState.result);
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [stateColumns, setStateColumns] = useState(initialColumnsState);

    const [showLoader, setShowLoader] = useState(null);
    const [shouldRefresh, setShouldRefresh] = useState(false);


    const locationData = useSelector((state) => state.commonReducer.locationData);

    const dateStamp = useSelector((store) => store?.reports)

    const history = useHistory();
    const dispatch = useDispatch();

    const [expandIcon, setExpandIcon] = useState("fullscreen");

    const serverColumns = useSelector(
        (state) => state.commonReducer.gridUserPrefrence
    );

    //DctTable

    const [filter, setFilter] = useState(null);
    const [sort, setSort] = useState([
        {
            field: "BatchId",
            dir: "asc",
        },
    ]);

    const dataStateChange = (event) => {
        console.log(event);
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
        // setData(updatedState.result.data);
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState || initialColumnsState);
    };

    const customCellRender = (td, props) => {
        const { dataItem, field } = props;
        const dataValue = dataItem[field];
        return <td style={{ textAlign: "center" }}>{dataValue?.toString()}</td>
    };

    //country
    useEffect(() => {
        let country_list = [];
        locationData?.Region?.CountryDetails?.map(elm => (
            country_list.push(elm?.CountryID)
        ))
        setCountry([...country_list])
    }, [locationData])

    //region
    useEffect(() => {
        setRegion(locationData?.Region?.RegionID)
    })

    const onEndDateChange = async (e) => {
        const endDate = moment(e.value).format("YYYY-MM-DD");
        setSelectedData({
            ...selectedData, endDate
        });
    }

    useEffect(() => {
        fetchNavigatorReportBasicData();
    }, [selectedData.countryID, selectedData.year, selectedData.startdate, selectedData.endDate])

    const fetchNavigatorReportBasicData = async () => {




        let jsonString = {
            "DctReportBasicFilterReqMsg":
            {
                "header": { "serviceAction": "Query", "reqTimeStamp": currentDateTime, "username": authContext.SpocMail },
                "DctReportBasicFilterReq": { "countryID": selectedData.countryID, "regionID": selectedData.regionID, "Year": selectedData.year, "periodStartDate": selectedData.startdate, "periodEndDate": selectedData.endDate },
            }
        }

        const params2 = {
            "CollectionType": "DCT",
            "ReportName": "STATUS_REPORT",
            "APIName": "getNavigatorReportBasic",
            "Payload": {
                "DctReportBasicFilterReqMsg":
                {
                    "header": { "serviceAction": "Query", "reqTimeStamp": currentDateTime, "username": authContext.SpocMail },
                    "DctReportBasicFilterReq": { "countryID": selectedData.countryID, "regionID": selectedData.regionID, "Year": selectedData.year, "periodStartDate": selectedData.startdate, "periodEndDate": selectedData.endDate },
                }
            }
        }

        let params = JSON.stringify(params2)

        if (selectedData.countryID && selectedData.regionID && selectedData.year && selectedData.startdate && selectedData.endDate) {

            setShowLoader({
                // className: ".workOrders_grid",
                msg: "loading",
            });
            const res = await getNavigatorReportBasic(params, authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            setFormdct(res?.DctReportBasicFilterReqResMsg?.DctReportBasicFilterReqRes);
            setShowLoader(null);
        }
    }


    //On Search

    const onSearchClick = async (e) => {
        if (!e.isValid) {
            return
        }
        fetchDCTstatusReport()
    }

    const handlePageChange = async (e) => {
        const dataState = e.page;
        setDataState(dataState);
        fetchDCTstatusReport({
            page: Math.floor(dataState.skip / dataState.take) + 1,
            StartCount: dataState.skip,
            MaxCount: dataState.take,
        });
    };

    const fetchDCTstatusReport = async (newParams = {}) => {

        const params2 = {
            "CollectionType": "DCT",
            "ReportName": "STATUS_REPORT",
            "APIName": "getNavigatorReportList",
            "Payload": {
                "DctReportSearchResultReqMsg":
                {
                    "header": { "serviceAction": "Query", "reqTimeStamp": currentDateTime, "username": authContext.SpocMail },
                    "DctReportSearchResultReq": {
                        "countryID": selectedData.countryID, "regionID": selectedData.regionID, "Year": selectedData.year, "periodStartDate": selectedData.startdate, "periodEndDate": selectedData.endDate, "surveyID": selectedData.surveyId, "planId": selectedData.planId, "storeId": selectedData.storeId, "status": selectedData.status, "page": 1,
                        "startCount": 0,
                        "MaxCount": 50,
                        ...newParams
                    }
                }
            }
        }


        // let params = {
        //     "DctReportSearchResultReqMsg":
        //     {
        //         "header": { "serviceAction": "Query", "reqTimeStamp": currentDateTime, "username": authContext.SpocMail },
        //         "DctReportSearchResultReq": { "countryID": selectedData.countryID, "regionID": selectedData.regionID, "Year": selectedData.year, "periodStartDate": selectedData.startdate, "periodEndDate": selectedData.endDate, "surveyID": selectedData.surveyId, "planId": selectedData.planId, "storeId": selectedData.storeId, "status": selectedData.status }
        //     },

        //     page: "1", StartCount: "0", MaxCount: "25", ...newParams
        // }
        // let params = {
        //     "jsonString": JSON.stringify({
        //         "DctReportSearchResultReqMsg":
        //         {
        //             "header": { "serviceAction": "Query", "reqTimeStamp": "16/12/2022 12:28:37", "username": authContext.SpocMail },
        //             "DctReportSearchResultReq": { "countryID": selectedData.countryID, "regionID": selectedData.regionID, "Year": selectedData.year, "periodStartDate": selectedData.startdate, "periodEndDate": selectedData.endDate, "surveyID": selectedData.surveyId, "planId": selectedData.planId, "storeId": selectedData.storeId, "status": selectedData.status }
        //         }

        //     }), page: 1, StartCount: 0, MaxCount: 25, ...newParams
        // }

        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await getDCTTableBasic(JSON.stringify(params2), authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        // setDctTable(res?.Response?.DctReportSearchResultResMsg?.DctReportSearchResultRes);

        if (res?.Response?.DctReportSearchResultResMsg?.serviceStatus?.statusFlag === "Success" && Array.isArray(res?.Response?.DctReportSearchResultResMsg?.DctReportSearchResultRes)) {
            // const data = createDataState(res.SearchPlan || [], dataState);
            const result = {
                data: res?.Response?.DctReportSearchResultResMsg?.DctReportSearchResultRes,
                total: res?.Response?.totalCount,
            };
            setData(result);
        } else {
            console.log("search plan api error", res);
            const result = {
                data: [],
                total: 0,
            };
            setData(result);
            showToast(
                "error",
                t("dataNotFound", `${res?.Response?.DctReportSearchResultResMsg?.serviceStatus?.statusMsg ? res?.Response?.DctReportSearchResultResMsg?.serviceStatus?.statusMsg : "No Data Found"}`),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
        setShowLoader(null);
    }

    useEffect(() => {
        let plan_id = [];
        let survey_id = [];
        let store_id = [];
        let status_store = [];
        let yearData = [];

        // let country_list =[];

        formdct?.map(elm => (
            plan_id.push(elm.planId),
            survey_id.push(elm.surveyID),
            elm.storeId && store_id.push(elm.storeId),
            status_store.push(elm.status)
            // country_list.push(elm.locationData.Country)
        ))

        setFormComboBoxData({
            formplanId: [...new Set(plan_id)],
            formsurveyId: [...new Set(survey_id)],
            formstoreId: ["ALL", ...new Set(store_id)],
            formstatus: [...new Set(status_store)],
        });
        // setCountry([...new Set(country_list)])

        dateStamp?.dateStamp?.yearData?.map(elm => (
            yearData.push(elm.DisplayValue)
        ))
        setYear([...new Set(yearData)])

    }, [formdct, dateStamp?.dateStamp?.yearData])


    useEffect(() => {
        if (serverColumns?.length > 0) {
            const currentDataColumns = serverColumns.find(
                (c) => c?.Grid_Name === GRID_NAME
            );
            try {
                const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
                if (currentStateColumns.every((column) => column.field)) {
                    setStateColumns(currentStateColumns);
                }
            } catch (e) {
                console.log("error in state columns", currentDataColumns?.Json_object);
                return;
            }
        }
    }, [serverColumns]);

    useEffect(() => {

        setSelectedData({
            ...selectedData, regionID: locationData?.Region?.RegionID,
            countryID: locationData?.Country?.[0]?.CountryID,
        })


    }, [locationData?.Country])



    const onChange = (e) => {
        if (e.target?.name === "startdate") {
            setSelectedData({
                ...selectedData, [e.target.name]: moment(e.value).format("YYYY-MM-DD")
            })

        } else {
            setSelectedData({
                ...selectedData, [e.target.component.name]: e.value
            })

        }
    }

    const handleSaveViewClick = async () => {
        const params = {
            UserPrefReq: {
                Email: authContext.SpocMail,
                Company_ID: authContext.CompanyID,
                Grid_Name: GRID_NAME,
                Country_ID: selectedData.countryID,
                Json_Object: JSON.stringify(stateColumns),
            },
        };
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await updateUserPreferenceAction(
            JSON.stringify(params),
            authContext
        );
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.Response?.Status === "Success") {
            dispatch(
                searchUserPrefrence(
                    JSON.stringify({
                        UserPrefReq: {
                            Email: authContext?.SpocMail,
                            Company_ID: authContext?.CompanyID,
                        },
                    })
                )
            );
            showToast(
                "success",
                t(res?.Response?.Message || "Success")
            );
        } else {
            showToast(
                "error",
                t(res?.Response?.Message || "dataNotFound")
            );
        }
        setShowLoader(null);
    };

    const handleDefaultViewClick = () => {
        onColumnsSubmit();
    };

    const handleRefreshGrid = () => {
        console.log("refresh grid");
        setShouldRefresh(true);
    };


    const resetForm = () => {
        setSelectedData({
            ...selectedData, startdate: ""
        })
    }

    const onExport = async () => {

        if (selectedData.year && selectedData.startdate && selectedData.endDate && selectedData.surveyId && selectedData.planId && selectedData.storeId && selectedData.status) {
            // let params = {

            //     jsonString: JSON.stringify({
            //         "DctReportSearchResultReqMsg": {
            //             "header": { "serviceAction": "Query", "reqTimeStamp": "02/03/2023 11:50:10", "username": authContext.SpocMail },
            //             "DctReportSearchResultReq": { "countryID": selectedData.countryID, "regionID": selectedData.regionID, "Year": selectedData.year, "periodStartDate": selectedData.startdate, "periodEndDate": selectedData.endDate, "surveyID": selectedData.surveyId, "planId": selectedData.planId, "storeId": selectedData.storeId, "status": selectedData.status }
            //         }
            //     })
            // }
            let params = {
                "DctReportSearchResultReqMsg": {
                    "header": { "serviceAction": "Query", "reqTimeStamp": currentDateTime, "username": authContext.SpocMail },
                    "DctReportSearchResultReq": { "countryID": selectedData.countryID, "regionID": selectedData.regionID, "Year": selectedData.year, "periodStartDate": selectedData.startdate, "periodEndDate": selectedData.endDate, "surveyID": selectedData.surveyId, "planId": selectedData.planId, "storeId": selectedData.storeId, "status": selectedData.status }
                }
            }

            let params2 = {
                "CollectionType": "DCT",
                "ReportName": "STATUS_REPORT",
                "APIName": "getNavigatorExport",
                "Payload": {
                    "DctReportSearchResultReqMsg": {
                        "header": { "serviceAction": "Query", "reqTimeStamp": currentDateTime, "username": authContext.SpocMail },
                        "DctReportSearchResultReq": { "countryID": selectedData.countryID, "regionID": selectedData.regionID, "Year": selectedData.year, "periodStartDate": selectedData.startdate, "periodEndDate": selectedData.endDate, "surveyID": selectedData.surveyId, "planId": selectedData.planId, "storeId": selectedData.storeId, "status": selectedData.status }
                    }
                }
            }

            setShowLoader({
                // className: ".workOrders_grid",
                msg: "loading",
            });

            showToast(
                "info",
                t('filedownload_msglbl')
            );


            // const res = await getDCTExportData(JSON.stringify(params), authContext)
            const res = await getExportDataWithPostTimeoutHandler(params2, authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            setShowLoader(null);
        } else {
            showToast(
                "error",
                t("pleasefillthemandatoryfields"),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }

    }

    const expand = () => {
        setShowForm(!showForm);
        setExpandIcon(!showForm ? "fullscreen" : "fullscreen-exit")
    }

    const backToReports = () => {
        history.push("/reports");
    }

    useEffect(() => {
        if (data) {
            setResult({
                data: data?.data.map((d) => {
                    if (!isNaN(d?.survey_id)) {
                        d.survey_id = parseInt(d.survey_id);
                    }
                    // const audit_date = new Date(d?.AUDIT_DATE);
                    // if (audit_date !== "Invalid Date") {
                    //     d.AUDIT_DATE = audit_date;
                    // }
                    return d
                }), ...data
            })
        }
    }, [data]);

    const CreatePager = useCallback((props) => (
        <MyPager
            {...props}
            onSaveViewClick={handleSaveViewClick}
            onDefaultViewClick={handleDefaultViewClick}
        >
            <Pager style={{ flex: 1 }} {...props} />
        </MyPager>
    ), [stateColumns, selectedData.countryID]);


    return (
        <>
            {showLoader && <LoadMask {...showLoader} />}
            <div className="dct">
                <Header activeMenu={t("nielsennavigator-statusreport")}>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginInline: "auto 1em",
                            padding: '6px'
                        }}
                    >
                        <Button
                            className='k-secondary'
                            onClick={backToReports}
                        >
                            Back
                        </Button>
                    </div>
                </Header>
                {showForm ?
                    <div className="dctform">
                        <Form
                            initialValues={{
                                regionID: locationData?.Region?.RegionID,
                                countryID: locationData?.Country?.[0]?.CountryID,
                            }}
                            onSubmitClick={onSearchClick}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "0px 25px",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <Field
                                            name={"regionID"}
                                            component={FormComboBox}
                                            label={t("REGION")}
                                            // required={true}
                                            // data={region}

                                            // validator={inputValidator}

                                            // onChange={onInputChange}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="RegionName"
                                            valueField="RegionID"
                                            data={[locationData?.Region]}
                                            defaultValue={locationData?.Region?.RegionID}
                                            disabled
                                        />


                                        <Field
                                            name={"countryID"}
                                            component={FormComboBox}
                                            label={t("COUNTRY")}
                                            required={true}
                                            // data={country}
                                            validator={inputValidator}
                                            onChange={onChange}
                                            // wrapperStyle={{ width: "calc(25% - 15px)", fontSize: "15px", fontWeight: "500" }}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="CountryName"
                                            valueField="CountryID"
                                            data={locationData?.Country || []}
                                            // wrapperStyle={{ flex: 1 }}
                                            defaultValue={locationData?.Country?.[0]?.CountryID}
                                        />

                                        <Field
                                            name="year"
                                            label={t("YEAR")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("YEAR")}
                                            data={year}
                                            required={true}
                                            validator={inputValidator}
                                            // onChange={(e) => {
                                            //     console.log("aaaa", e.target.value);
                                            //     setSelectedData({ ...selectedData, year: e.target.value });
                                            // }}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="startdate"
                                            label={t("START_DATE_LBL")}
                                            component={FormDatePicker}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("START_DATE_LBL")}
                                            required={true}
                                            value={selectedData?.startdate}
                                            // validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, startdate: e.value });
                                            // }}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="enddate"
                                            label={t("END_DATE_LBL")}
                                            component={FormDatePicker}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("END_DATE_LBL")}
                                            required={true}
                                            validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, startdate: e.value });
                                            // }}
                                            onChange={onEndDateChange}
                                        />

                                        <Field
                                            name="surveyId"
                                            label={t("surveyId")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={"Survey Id"}
                                            data={formComboBoxData.formsurveyId}
                                            required={true}
                                            validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, year: e.value });
                                            // }}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="planId"
                                            label={t("PLAN_TYPE_LBL")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("PLAN_TYPE_LBL")}
                                            data={formComboBoxData.formplanId}
                                            required={true}
                                            validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, year: e.value });
                                            // }}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="storeId"
                                            label={t("storeid")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("storeid")}
                                            data={formComboBoxData.formstoreId}
                                            required={true}
                                            validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, year: e.value });
                                            // }}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="status"
                                            label={t("Status")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={"Status"}
                                            data={formComboBoxData.formstatus}
                                            required={true}
                                            validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, year: e.value });
                                            // }}
                                            onChange={onChange}
                                        />

                                        <div
                                            className={'k-actions k-hstack k-justify-content-stretch'}
                                            style={{ marginTop: "32px", gap: "0px 8px", marginLeft: "-7px" }}>
                                            <Button primary>{t("SEARCH")}</Button>
                                            <Button
                                                type={'reset'}
                                                className={'k-secondary'}
                                                onClick={() => {
                                                    setSelectedData({
                                                        regionID: locationData?.Region?.RegionID,
                                                        countryID: locationData?.Country?.[0]?.CountryID,
                                                    });
                                                    setFormComboBoxData(initialFormComboBoxData);
                                                    setTimeout(() => {
                                                        formRenderProps.onFormReset();
                                                    }, 0);
                                                }}
                                            >
                                                {t("RESET")}
                                            </Button>

                                        </div>

                                    </div>
                                </FormElement>
                            )}
                        />
                    </div>
                    :
                    <div></div>
                }
                <div
                    className="nielsen-card"
                    style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
                >
                    <div className="eq_data_heading" style={{ margin: "0px -6px" }}>
                        <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
                        <div>
                            <Button
                                onClick={onExport}
                                style={{ margin: "10px", border: "none" }}
                                primary
                            >{t("export")}</Button>

                            <Button icon={expandIcon}
                                onClick={expand}
                                className={'k-secondary'}
                                style={{ margin: "10px", marginRight: '20px' }}
                            ></Button>
                        </div>

                    </div>
                    <div className="catalog" style={{
                        display: "flex",
                        padding: "1rem",
                        flexGrow: 1,
                        overflow: "auto",
                    }}
                    >
                        <Grid
                            className="workOrders_grid"
                            data={result}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                console.log("sort", { e });
                                setResult((result) => {
                                    const { data, total } = result;
                                    return {
                                        data: orderBy(data || [], e.sort),
                                        total,
                                    };
                                });
                                setSort(e.sort);
                            }}

                            filter={filter}
                            onFilterChange={(e) => {
                                // console.log("filter", e.filter  );
                                setResult((result) => {
                                    const { total } = result;
                                    return {
                                        data: filterBy(data?.data || [], e.filter),
                                        total,
                                    };
                                });
                                setFilter(e.filter)
                            }}

                            onPageChange={handlePageChange}
                            cellRender={customCellRender}

                            pageable={{
                                pageSizes: false,
                            }}
                            // onPageChange={handlePageChange}
                            pager={CreatePager}
                            resizable
                            reorderable
                            onColumnReorder={({ columns }) => {
                                const columnOrder = columns.reduce((columnsObj, column) => {
                                    columnsObj[column.field] = column.orderIndex;
                                    return columnsObj;
                                }, {})
                                const newColumns = stateColumns.map((column) => {
                                    return { ...column, orderIndex: columnOrder[column.field] }
                                });
                                setStateColumns(newColumns);
                            }}

                        >
                            {stateColumns.map(
                                (column, idx) => {
                                    const menu = columnMenuConfig[column?.columnMenu];
                                    return column.show &&
                                        (

                                            <GridColumn
                                                key={column?.orderIndex}
                                                field={column?.field}
                                                //title={column?.title}
                                                title={t(column.title)}
                                                width={column?.width}
                                                minResizableWidth={30}
                                                cell={GridCell}
                                                format={column?.format}
                                                filter={column?.filter}
                                                orderIndex={column?.orderIndex}
                                                {...(menu && {
                                                    columnMenu: (props) => {
                                                        return menu?.({
                                                            ...props,
                                                            columns: stateColumns,
                                                            onColumnsSubmit,
                                                            data: data?.data,
                                                        });
                                                    },
                                                })}
                                                headerClassName={
                                                    isColumnActive(column.field, { ...dataState, filter })
                                                        ? "activeFiltered"
                                                        : ""
                                                }
                                            />
                                        )
                                })}
                        </Grid>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ReportDCT
