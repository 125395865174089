import AppURL from "../../../../utils/AppURL";
import { Get, Post, PostwithTimeoutHandler } from "../../../methods";

export const getProactiveReport = async(params, authContext) => {
    const header = {   
         userid: authContext?.SpocMail || "",
            countryid: authContext?.CountryCode || "",
         companyid:authContext?.CompanyID || "",
        "content-type": "application/json",
        // "content-type": "application/x-www-form-urlencoded; application/json",
      }               
      const result = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header,true,10000).then((res) =>
      // const result = await Get(AppURL.getProactiveReport, params, header,true).then((res) =>
      res.json()
    );
    return result;
}
export const exportProactiveReport = async (params, authContext) => {
    const exportFile = await Post(AppURL.exportProactiveReoprt,params,null,true);    
    // const exportFile = await Get(AppURL.exportProactiveReoprt,params,null,true);    
      console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
      const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);    
      // const FILE_NAME = Date.now();
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl; 
      anchor.download = disposition?.[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor); 
  };