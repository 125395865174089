import { Upload } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { useState, useRef } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
// import AppURL from "../../../utils/AppURL";
// import AppURL from "../../../utils"
import React from "react";
import { postPlanUpload } from "../../../store/actions/workOrder/bulkUpload";
import { useSelector } from "react-redux";
import { FormUpload } from "../../ui/form/form-component";
import { FormComboBox } from "../../form/form-component";
import { coverageScreenDownloadStore } from "../../../store/actions/workOrder/coverageScreen";
import AppURL from "../../../utils/AppURL";
import { inputValidator } from "../../ui/form/validator";
import LoadMask from "../../../utils/LoadMask";
import { getExportTemplate } from "../../../store/actions/people/bulkUploads";
import { showToast } from "../../../utils/Notification";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";
import { useTranslation } from "react-i18next";

const DownloadWindow = ({ setDownloadWindowShow }) => {
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const { t, i18n } = useTranslation();
  const {handleAuthContext} = useLogout();
  const [isFormShow, setFormShow] = useState(true);
  const [showLoader, setShowLoader] = useState(null);

  const handleDownload = async (form) => {
    if (!form?.isValid) {
      console.log("form", { form });
      return;
    }
    const Action = document.activeElement.id;
    if (!authContext) {
      console.log("authContext", authContext);
      return;
    }
    const { CountryCode, SpocMail } = authContext;
    const UploadType = "FMS_AUDITOR";
    const { CountryId } = form.values;
    const params = {
      CountryId,
      UploadType,
      userId: SpocMail,
      Action,
    };
    console.log("params", params, form);
    setShowLoader({
      className: ".coverageScreenDownloadStore",
      msg: "loading",
    });
    const res = await getExportTemplate(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.headers.get("content-type") === "application/json") {
      const jsonRes = await res.json();
      showToast("error", `${jsonRes?.Output?.Status}`);
    } else if (res?.status === 200) {
      // ?CompanyId=440&Countryid=56&Action=ALL&Collectiontype=DCT
      const url = `${AppURL.exportTemplate}?Countryid=${CountryCode}&Action=${Action}&UploadType=${UploadType}&userId=${authContext?.SpocMail}`;
      window.location.assign(url);
    }
    setShowLoader(null);
    console.log("result", res);
  };
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <Window
        className="coverageScreenDownloadStore"
        title={t("downloadtemplate")}
        onClose={() => {
          // debugger;
          setDownloadWindowShow(false);
        }}
        modal={true}
        minimizeButton={() => (
          <Button
            icon="question"
            onClick={() => setFormShow(!isFormShow)}
            style={{ marginRight: ".5em" }}
          />
        )}
        maximizeButton={() => null}
        restoreButton={() => null}
        // initialTop={70}
        initialHeight={256}
        initialWidth={390}
        style={{ paddingBottom: "1em" }}
      >
        {isFormShow ? (
          <>
            <Form
              initialValues={{
                RegionID: locationData?.Region?.RegionID,
                CountryId: locationData?.Country?.[0]?.CountryID,
              }}
              onSubmitClick={handleDownload}
              render={(formRenderProps) => (
                <FormElement>
                  <div
                    style={{
                      // display: "flex",
                      gap: 40,
                      flexDirection: "row",
                      flexWrap: "wrap",
                      margin: 0,
                    }}
                  >
                    <div style={{ display: "flex", gap: "1em" }}>
                      <Field
                        label="Region"
                        name="RegionID"
                        component={FormComboBox}
                        wrapperStyle={{ flex: 1 }}
                        textField="RegionName"
                        valueField="RegionID"
                        // data={[{ RegionName: "Europe", RegionID: 4 }]}
                        data={[locationData?.Region]}
                        defaultValue={locationData?.Region?.RegionID}
                        disabled
                        required
                      />

                      <Field
                        label="Country"
                        name="CountryId"
                        component={FormComboBox}
                        textField="CountryName"
                        valueField="CountryID"
                        // data={[{ countryName: "Belgium", countryId: 23 }]}
                        data={locationData?.Country || []}
                        wrapperStyle={{ flex: 1 }}
                        defaultValue={locationData?.Country?.[0]?.CountryID}
                        required
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: ".8em",
                      position: "absolute",
                      bottom: 0,
                      width: "calc(100% - 2em)",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #000",
                        backgroundColor: "#BDD1FC",
                        paddingLeft: ".5em",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      AUDITOR SAMPLE
                      <Button
                        id="SAMPLE"
                        icon="download"
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          // formRenderProps.onFormReset();
                        }}
                      ></Button>
                    </div>

                    <Button id="TEMPLATE" primary>
                    {t("downloadtemplate")}
                    </Button>
                  </div>
                </FormElement>
              )}
            />
          </>
        ) : (
          <div>
            <Button icon="arrow-left" onClick={() => setFormShow(true)}>
              Back To Upload
            </Button>
            <ul style={{ color: "#000", fontWeight: 500 }}>
              <li>Please read through the below rules and tips.</li>
              <li>
                RESOURCE_ID values are dummy values; Please update before
                uploading. For new resources the ID can be only 6 digits long.
              </li>
              <li>All column fields are comma separated.</li>
              <li>
                !(Exclamation sign) Represents mandatory column to be filled.
              </li>
              <li>
                Date format - yyyy/mm/dd. Date should be wrapped within single
                quotes.
              </li>
              <li>Mobile Number should not exceed 20 digits.</li>
              <li>
                Languages and Skills are multiple and are separated with |
                operator eg: English|French.
              </li>
              <li>
                To add multiple devices to same ResourceID- Copy the same row
                and change only the device details; columns [P Q R].
              </li>
              <li>
                To remove device(s) from an Auditor delete the device details
                from columns [P Q R] (OR) Simply delete the entire row(s).
              </li>
              <li>
                If this sample file is used to upload the resources then please
                remove all rules; Also remove empty/blank lines, if any.
              </li>
              <li>
                EmailID should contain only one @ and other validations also
                apply. Sample: Sample.EmailID123@SomeDomain.com.
              </li>
              <li>
                The length allowed for FirstName; LastName; MiddleName and
                Personal Identification is 50 characters.
              </li>
              <li>
                Gender value should be one of these: [MALE or FEMALE or NOT
                SPECIFIED].
              </li>
              <li>
                Please make sure to provide State/Province; City and zip/Postal
                code values ins accordance with the country.
              </li>
            </ul>
          </div>
        )}
      </Window>
    </>
  );
};

export default DownloadWindow;
