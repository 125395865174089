import React, { useEffect, useState } from "react";
import TabHeader from "../TabHeader";
import { Field } from "@progress/kendo-react-form";
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
  FormMultiSelect,
} from "../../../../ui/form/form-component";
import { getStateData } from "../../../../../store/actions/people/resources";
import { useSelector } from "react-redux";
import { inputValidator } from "../../../../ui/form/validator";
import { optionalEmailValidator } from "../../../../form/validator";
import { useTranslation } from "react-i18next";

const BasicInfo = (props) => {
  console.log("basicInfoprops",props)
  const { t, i18n } = useTranslation();
  const languages = useSelector((state) => state.commonReducer.languages);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const companyDetails = useSelector((state) => state.commonReducer.companyDetails);   
 

  const [stateData, setStateData] = useState([]);
  const [isOtherLanguage, setIsOtherLanguage] = useState(false);

  let currentDate = new Date()
  const tempDefaultValue = new Date(props.initialFormState?.DATE_OF_BIRTH)
  const defaultValue = tempDefaultValue
  console.log("defaultValueDate",defaultValue,{tempDefaultValue},{currentDate})

  useEffect(() => {
    const fetchStateData = async () => {
      const params = {
        _dc: Date.now(),
        CountryID: props?.initialFormState?.COUNTRY,
        page: 1,
        start: 0,
        limit: 25,
      };
      const res = await getStateData(params, authContext);
      if (res?.SearchStateOp?.StasteDetails?.length) {
        setStateData(res?.SearchStateOp?.StasteDetails);
      }
      console.log("fetchStateData", res);
    };
    fetchStateData();
  }, []);
  return (
    <div
      style={{
        display: props.activeTab === "basicInfo" ? "block" : "none",
        height: "100%",
      }}
    >
      <div>
        <TabHeader {...props} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: ".6em",
          overflowY: "auto",
          height: "94%",
        }}
      >
        <div style={{ display: "flex", gap: "1em" }}>
          <Field
            name="UNIQUE_ID"
            label="Navigator Id"
            component={FormInput}
            disabled
          />
          <Field
            name="RESOURCE_ID"
            label={t("ResourceId")}
            component={FormInput}
            disabled
          />
          <Field
            name="PERSONAL_IDENTIFICATION_TYPE"
            label="Personal Id"
            component={FormInput}
            disabled
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            borderTop: "1px solid #808080",
          }}
        >
          <Field name="FIRST_NAME" label="First Name" component={FormInput} />
          <Field name="LAST_NAME" label="Last Name" component={FormInput} />
          <Field
            name="MIDDLE_NAME"
            label="Middle Name"
            component={FormInput}
            // disabled
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            borderTop: "1px solid #808080",
          }}
        >
          <Field
            name="DATE_OF_BIRTH"
            label="Date Of Birth"
            wrapperStyle={{ width: 180 }}
            component={FormDatePicker}
            // component={FormInput}
            // value={tempDefaultValue}
            // format="dd-MM-yyyy"
            disabled={
              companyDetails?.PrivacyInfo?.DOB === false
            }
          />
          <Field
            name="GENDER"
            label="Gender"
            component={FormComboBox}
            data={["MALE", "FEMALE", "NOT SPECIFIED"]}
            wrapperStyle={{ width: 180 }}
            disabled={
              companyDetails?.PrivacyInfo?.Gender === false
            }
          />
          <Field
            name="LANGUAGE"
            label="Language"
            component={FormMultiSelect}
            data={[...languages, { LanguageName: "Other" }]}
            wrapperStyle={{ width: 180 }}
            textField="LanguageName"
            valueField="LanguageName"
            onChange={(e) => {
              const isOtherExists = e.value.some(
                (l) => l.LanguageName === "Other"
              );
              setIsOtherLanguage(isOtherExists);
            }}
            disabled={
              companyDetails?.PrivacyInfo?.Language === false
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            borderTop: "1px solid #808080",
          }}
        >
          {isOtherLanguage && <Field label="Other" name="OtherLang" component={FormInput} required validator={inputValidator} />}

          <Field
            name="COUNTRY_NAME"
            label="Country"
            component={FormInput}
            disabled
          />
          <Field
            name="STATE"
            label="State"
            component={FormComboBox}
            textField="StateName"
            valueField="StateName"
            data={stateData}
            wrapperStyle={{ width: 180 }}
            disabled={
              companyDetails?.PrivacyInfo?.State === false
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            borderTop: "1px solid #808080",
          }}
        >
          <Field name="CITY" label="City" component={FormInput} disabled={
              companyDetails?.PrivacyInfo?.City === false
            } />
          <Field name="ADDRESS" label="Address" component={FormInput}  disabled={
              companyDetails?.PrivacyInfo?.Address === false
            }/>
          <Field name="ZIP_CODE" label="Postal Code" component={FormInput}  disabled={
              companyDetails?.PrivacyInfo?.PostalCode === false
            } />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            borderTop: "1px solid #808080",
          }}
        >
          <Field name="EMAIL" label={t('Email')} component={FormInput} 
          validator={companyDetails?.PrivacyInfo?.Email && optionalEmailValidator}
          disabled={
              companyDetails?.PrivacyInfo?.Email === false
            } />
          <Field
            name="CONTACT_NUMBER"
            label="Contact Number"
            component={FormInput}
            disabled={
              companyDetails?.PrivacyInfo?.Phno === false
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            borderTop: "1px solid #808080",
          }}
        >
          <Field name="CUSTOM1" label="Custom1" component={FormInput} disabled={
              companyDetails?.PrivacyInfo?.Custom1 === false
            } />
          <Field name="CUSTOM2" label="Custom2" component={FormInput} disabled={
              companyDetails?.PrivacyInfo?.Custom2 === false
            } />
          <Field name="CUSTOM3" label="Custom3" component={FormInput} disabled={
              companyDetails?.PrivacyInfo?.Custom3 === false
            } />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            borderTop: "1px solid #808080",
          }}
        >
          <Field name="CUSTOM4" label="Custom4" component={FormInput} disabled={
              companyDetails?.PrivacyInfo?.Custom4 === false
            } />
          <Field name="CUSTOM5" label="Custom5" component={FormInput}  disabled={
              companyDetails?.PrivacyInfo?.Custom5 === false
            }/>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
