import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const ReportsCard = ({title, subtitle, icon, link}) => {
    const { t } = useTranslation();

    return (
        <Link
            to={link}
            style={{
                height: "95%",
                width: "32%",
                border: "1px solid #CACACA",
                margin: '5px',
                borderRadius: '6px',
                boxShadow: '0px 2px 4px 0px #00000040'
            }}
        >
            <div
                style={{
                    display: "flex",
                    backgroundColor: "#F2F2F2",
                    height: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img src={icon} alt="" />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "50%",
                    fontSize: "14px",
                    padding: "0px 10px",
                }}
            >
                <div
                    style={{
                        color: "#24285B",
                        fontWeight: '600'
                    }}
                >
                    {t(title)}
                </div>
                <div
                    style={{
                        color: "#262626",
                        fontWeight: '400'
                    }}
                >
                    {t(subtitle)}
                </div>
            </div>
        </Link>
    )
}

export default ReportsCard