import React from "react";
import TabHeader from "../TabHeader";

const Skills = ({ selectedResourceDetails, ...props }) => {
  const [skills="", other=""] = selectedResourceDetails?.SKILLS?.split("|") || [];
  return (
    <>
      <TabHeader {...props} />
      <div
        style={{
          display: "flex",
          paddingTop: "1em",
          flexDirection: "column",
          gap: "1.5em",
          overflowY: "auto",
        }}
      >
        <div>
          Skills
          <div style={{padding:".3em 0"}}>
            {skills}
            {other && <>,Other</>}
          </div>
        </div>
        {other && (
          <div >
            Other Skills
            <div style={{padding:".3em 0"}}>{other}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Skills;
