import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./lang/English/translation.json";
import French from "./lang/French/translation.json";
import Dutch from "./lang/Dutch/translation.json";
import German from "./lang/German/translation.json";
import Portuguese from "./lang/Portuguese/translation.json";
import Spanish from "./lang/Spanish/translation.json";

i18next.use(initReactI18next).init({
    resources: {
        English: {
            translation: English,
        },
        French: {
            translation: French,
        },
        Dutch: {
            translation: Dutch,
        },
        German: {
            translation: German,
        },
        Portuguese: {
            translation: Portuguese,
        },
        Spanish: {
            translation: Spanish,
        },
    },
    lng: localStorage.getItem("lng") || "English",

});

export default i18next;