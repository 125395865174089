import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { FormComboBox, FormInput } from '../../../form/form-component';
import { inputValidator } from '../../../form/validator';
import './ScanImageTimestamp.css';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCell, GridColumn } from '@progress/kendo-react-grid';
import { Pager } from '@progress/kendo-react-data-tools';
import { Popup } from "@progress/kendo-react-popup";
import columns, { columnMenuConfig } from './common/columnScanImageTimestamp';
import { useDispatch, useSelector } from 'react-redux';
import { getExecutionQualityReport } from '../execution-qualityReport/common/executionqualityReport';
import { getFetchStudiesReport } from './common/fetchStudiesReport';
import { getFetchStoresReport } from './common/fetchStoresReport';
import { getScanImageTable } from './common/scanImageTable';
import { showToast } from '../../../../utils/Notification';
import { toast } from "react-toastify";
import { useLocalization } from "@progress/kendo-react-intl";
import { CustomColumnMenu } from '../../../ui/grid/CustomColumnMenu';
import { updateUserPreferenceAction } from '../../../../store/actions/commonActions';
import LoadMask from "../../../../utils/LoadMask";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next"; import { filterBy, orderBy } from '@progress/kendo-data-query';
import { isColumnActive } from '../../../grid/CustomColumnMenu';
import { getExportData, getExportDataWithPostTimeoutHandler } from '../summaryReport/common/getSummaryReport';
import { searchUserPrefrence } from '../../../../store/actions/dashboard';
import useLogout, { checkInvalidSession } from '../../../../utils/useLogout';
import { MyPager } from '../../../generalHooks';
import Header from '../../../header';

const GRID_NAME = "ECAL_SCAN_IMAGE_GRID"

const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            take: dataState.take,
        },
        dataState: dataState,
    };
};


let initialState = createDataState([], {
    take: 50,
    skip: 0,
});

const setOrderIndex = (columns) => {
    return columns.map((column, i) => ({ ...column, orderIndex: i }))
}

const initialColumnsState = setOrderIndex(columns);

function ScanImageTimestamp() {
    const { handleAuthContext } = useLogout();
    const [showForm, setShowForm] = useState(true);
    const [stateColumns, setStateColumns] = useState(initialColumnsState);
    const [data, setData] = useState({ data: [], total: 0 });
    const [showLoader, setShowLoader] = useState(null);
    const [formSI, setFormSI] = useState([]);
    const [formWorkOrderId, setFormWorkOrderId] = useState([]);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const { t, i18n } = useTranslation();
    const [formSITaskType, setFormSITaskType] = useState([]);
    const [taskTypeData, setTaskTypeData] = useState([]);

    const [formSMSID, setFormSMSID] = useState([]);
    const [smsID, setSmsID] = useState([]);

    const [weekNumber, setWeekNumber] = useState([]);
    const [year, setYear] = useState([]);

    const [result, setResult] = React.useState(initialState.result);
    const [dataState, setDataState] = React.useState(initialState.dataState);

    const locationData = useSelector((state) => state.commonReducer.locationData);
    const authContext = useSelector((state) => state.commonReducer.authContext);
    const dateStamp = useSelector((store) => store?.reports?.dateStamp)

    const [expandIcon, setExpandIcon] = useState("fullscreen");

    const localizationService = useLocalization();

    const history = useHistory();
    const dispatch = useDispatch();

    const serverColumns = useSelector(
        (state) => state.commonReducer.gridUserPrefrence
    );

    const initialFormValue = useMemo(() => {
        // const result = locationData;
        return {
            Region: locationData?.Region?.RegionID,
            Country: locationData?.Country?.[0]?.CountryID,
            year: dateStamp?.selectedYear?.DisplayValue.toString(),
            week: dateStamp?.selectedWeek?.DisplayValue.toString(),
            plantype: "LIVE"
        };
    }, [locationData]);
    const [selectedData, setSelectedData] = useState(initialFormValue);

    const [filter, setFilter] = useState(null);
    const [sort, setSort] = useState([
        {
            field: "BatchId",
            dir: "asc",
        },
    ]);

    const dataStateChange = (event) => {
        console.log(event);
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
        // setData(updatedState.result.data);
    };

    useEffect(() => {
        if (serverColumns?.length > 0) {
            const currentDataColumns = serverColumns.find(
                (c) => c?.Grid_Name === GRID_NAME
            );
            try {
                const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
                if (currentStateColumns.every((column) => column.field)) {
                    setStateColumns(currentStateColumns);
                }
            } catch (e) {
                console.log("error in state columns", currentDataColumns?.Json_object);
                return;
            }
        }
    }, [serverColumns]);

    useEffect(() => {

        setSelectedData({
            ...selectedData, Region: locationData?.Region?.RegionID,
            Country: locationData?.Country?.[0]?.CountryID,
            year: dateStamp?.selectedYear?.DisplayValue.toString(),
            week: dateStamp?.selectedWeek?.DisplayValue.toString(),
            plantype: "LIVE",
        })
    }, [locationData?.Country, dateStamp])



    const onExport = async () => {

        if (selectedData.Region && selectedData.Country && selectedData.tasktype) {
            // let params = {
            //     "ScanImageReportReq": JSON.stringify({ "Region": selectedData.Region, "Country": selectedData.Country, "Cycle": null, "Year": selectedData.year, "Week": selectedData.week, "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "FromDate": "", "ToDate": "", "CompanyId": authContext.CompanyID, "StoreType": selectedData.plantype, "Study": selectedData.tasktype })
            //     // ScanImageReportReq: JSON.stringify({"Region":"2","Country":"276","Cycle":null,"Year":"2023","Week":"22","WorkOrderID":"","AuditorId":"","StoreNumber":"","FromDate":"","ToDate":"","CompanyId":"444","StoreType":"ALL","Study":"Foerster and Thelen"})
            // }
            // let params = {
            //     ScanImageReportReq: JSON.stringify({ "Request": { "Region": selectedData.Region, "Country": selectedData.Country, "Cycle": null, "Year": selectedData.year, "Week": selectedData.week, "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "FromDate": "", "ToDate": "", "CompanyId": authContext.CompanyID, "StoreType": selectedData.plantype, "Study": selectedData.tasktype } })
            //     // ScanImageReportReq: JSON.stringify({"Request":{"Region":"4","Country":"76","Cycle":null,"Year":"2022","Week":"35","WorkOrderID":"","AuditorId":"","StoreNumber":"","FromDate":"","ToDate":"","CompanyId":"377","StoreType":"ACTUAL","Study":"SA Ecollection"}})
            // }

            // let params = {
            //     "ReportType": "scanimage",
            //     "Region": selectedData.Region,
            //     "Country": selectedData.Country,
            //     "Cycle": null,
            //     "Year": selectedData.year,
            //     "Week": selectedData.week,
            //     "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
            //     "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
            //     "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
            //     "FromDate": "",
            //     "ToDate": "",
            //     "CompanyId": authContext.CompanyID,
            //     "StoreType": selectedData.plantype,
            //     "Study": selectedData.tasktype
            // }

            let params2={
                "CollectionType":"ECOLLECTION",
                "ReportName":"TIMESTAMP_REPORT",
                "APIName":"Reports",
                "Payload":{

                "ReportType": "scanimage",
                "Region": selectedData.Region,
                "Country": selectedData.Country,
                "Cycle": null,
                "Year": selectedData.year,
                "Week": selectedData.week,
                "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
                "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
                "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
                "FromDate": "",
                "ToDate": "",
                "CompanyId": authContext.CompanyID,
                "StoreType": selectedData.plantype,
                "Study": selectedData.tasktype
                   
                }
            }

            setShowLoader({
                // className: ".workOrders_grid",
                msg: "loading",
            });
            showToast(
                "info",
                t('filedownload_msglbl')
            );
            // no need to json stringify
            // const res = await getExportData(params, authContext)
            const res = await getExportDataWithPostTimeoutHandler(params2, authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            // showToast(
            //     "success",
            //     "The File is Downloaded",
            //     {
            //         position: toast.POSITION.TOP_RIGHT,
            //     }
            // );


            setShowLoader(null);
        } else {
            showToast(
                "error",
                t("pleasefillthemandatoryfields"),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
    }

    useEffect(() => {

        const searchPlan = async () => {

            // setSelectedData({
            //     ...selectedData, [e.target.component.name]: e.value
            // });

            let params = {
                Region: selectedData.Region, Country: selectedData.Country, CollectionType: "ECOLLECTION", year: selectedData.year, week: selectedData.week,
                CompanyID: authContext.CompanyID,
                // CompanyID: "358",
                _dc: "1673938014148"
            }

            // let params1 = {
            //     fetchStudiesReq: JSON.stringify({
            //         "Request": {
            //             "Region": selectedData.Region,
            //             "Country": selectedData.Country,
            //             "Year": selectedData.year,
            //             "Week": selectedData.week,
            //             "Vendor_id": authContext.CompanyID,
            //         }
            //     })
            // }

            // let params1 = {
            //     "ReportType": "scanimage",
            //     "Region": selectedData.Region,
            //     "Country": selectedData.Country,
            //     "Year": selectedData.year,
            //     "Week": selectedData.week,
            //     "Vendor_id": authContext.CompanyID,
            // }

            const params2 = {
                "CollectionType": "ECOLLECTION",
                "ReportName": "TIMESTAMP_REPORT",
                "APIName": "fetchStudies",
                "Payload": {
                    "ReportType": "scanimage",
                    "Region": selectedData.Region,
                    "Country": selectedData.Country,
                    "Year": selectedData.year,
                    "Week": selectedData.week,
                    "Vendor_id": authContext.CompanyID,
                }
            }


            // setShowLoader({
            //     // className: ".workOrders_grid",
            //     msg: "loading",
            // });
            const res = await getExecutionQualityReport(params, authContext)
            setFormSI(res?.SearchPlan);
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            const res1 = await getFetchStudiesReport(JSON.stringify(params2), authContext)
            setFormSITaskType(res1.ReportResponse?.Response);
            if (checkInvalidSession(res1)) {
                handleAuthContext();
                return;
            }

            setShowLoader(null);
        }

        searchPlan();

    }, [])


    const onChangeWeekNumber = async (e) => {

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region,
            // Region: "4",
            Country: selectedData.Country,
            // Country: "76", 
            CollectionType: "ECOLLECTION",
            year: selectedData.year,
            // year: "2022",
            week: e.value,
            // week: "35",
            CompanyID: authContext.CompanyID,
            // CompanyID: "377",
            // _dc: "1673938014148"
        }

        // let params1 = {
        //     fetchStudiesReq: JSON.stringify({
        //         "Request": {
        //             "Region": selectedData.Region,
        //             "Country": selectedData.Country,
        //             "Year": selectedData.year,
        //             "Week": e.value,
        //             "Vendor_id": authContext.CompanyID,
        //         }
        //     })
        // }
        // let params1 = {
        //     "ReportType": "scanimage",
        //     "Region": selectedData.Region,
        //     "Country": selectedData.Country,
        //     "Year": selectedData.year,
        //     "Week": e.value,
        //     "Vendor_id": authContext.CompanyID,
        // }

        const params2 = {
            "CollectionType": "ECOLLECTION",
            "ReportName": "TIMESTAMP_REPORT",
            "APIName": "fetchStudies",
            "Payload": {
                "ReportType": "scanimage",
                "Region": selectedData.Region,
                "Country": selectedData.Country,
                "Year": selectedData.year,
                "Week": e.value,
                "Vendor_id": authContext.CompanyID,
            }
        }


        setShowLoader({
            // className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await getExecutionQualityReport(params, authContext)
        // setFormSI(res?.SearchPlan);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.SearchPlan) {
            setFormSI(res?.SearchPlan);
        } else {
            showToast("error", `${t("workorderisnotavailableforsearchedweek")}`);
        }


        const res1 = await getFetchStudiesReport(JSON.stringify(params2), authContext)
        setFormSITaskType(res1.ReportResponse?.Response);
        if (checkInvalidSession(res1)) {
            handleAuthContext();
            return;
        }

        setShowLoader(null);

    }

    const onChangeYear = async (e) => {

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region,
            // Region: "4",
            Country: selectedData.Country,
            // Country: "76", 
            CollectionType: "ECOLLECTION",
            year: e.value,
            // year: "2022",
            week: selectedData.week,
            // week: "35",
            CompanyID: authContext.CompanyID,
            // CompanyID: "377",
            // _dc: "1673938014148"
        }

        // let params1 = {
        //     fetchStudiesReq: JSON.stringify({
        //         "Request": {
        //             "Region": selectedData.Region,
        //             "Country": selectedData.Country,
        //             "Year": e.value,
        //             "Week": selectedData.week,
        //             "Vendor_id": authContext.CompanyID,
        //         }
        //     })
        // }
        let params1 = {
            "Region": selectedData.Region,
            "Country": selectedData.Country,
            "Year": e.value,
            "Week": selectedData.week,
            "Vendor_id": authContext.CompanyID,
        }

        const params2 = {
            "CollectionType": "ECOLLECTION",
            "ReportName": "TIMESTAMP_REPORT",
            "APIName": "fetchStudies",
            "Payload": {
                "Region": selectedData.Region,
                "Country": selectedData.Country,
                "Year": e.value,
                "Week": selectedData.week,
                "Vendor_id": authContext.CompanyID,
            }
        }


        setShowLoader({
            // className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await getExecutionQualityReport(JSON.stringify(params), authContext)
        setFormSI(res?.SearchPlan);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        const res1 = await getFetchStudiesReport(JSON.stringify(params2), authContext)
        setFormSITaskType(res1.ReportResponse?.Response);
        if (checkInvalidSession(res1)) {
            handleAuthContext();
            return;
        }

        setShowLoader(null);
    }

    const onChangeCountry = async (e) => {

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region,
            // Region: "4",
            Country: e.value,
            // Country: "76", 
            CollectionType: "ECOLLECTION",
            year: selectedData.year,
            // year: "2022",
            week: selectedData.week,
            // week: "35",
            CompanyID: authContext.CompanyID,
            // CompanyID: "377",
            // _dc: "1673938014148"
        }

        // let params1 = {
        //     fetchStudiesReq: JSON.stringify({
        //         "Request": {
        //             "Region": selectedData.Region,
        //             "Country": e.value,
        //             "Year": selectedData.year,
        //             "Week": selectedData.week,
        //             "Vendor_id": authContext.CompanyID,
        //         }
        //     })
        // }
        // let params1 = {
        //     "ReportType": "scanimage",
        //     "Region": selectedData.Region,
        //     "Country": e.value,
        //     "Year": selectedData.year,
        //     "Week": selectedData.week,
        //     "Vendor_id": authContext.CompanyID,
        // }

        const params2 = {
            "CollectionType": "ECOLLECTION",
            "ReportName": "TIMESTAMP_REPORT",
            "APIName": "fetchStudies",
            "Payload": {
                "ReportType": "scanimage",
                "Region": selectedData.Region,
                "Country": e.value,
                "Year": selectedData.year,
                "Week": selectedData.week,
                "Vendor_id": authContext.CompanyID,
            }
        }


        setShowLoader({
            // className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await getExecutionQualityReport(params, authContext)
        setFormSI(res?.SearchPlan);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        const res1 = await getFetchStudiesReport(JSON.stringify(params2), authContext)
        setFormSITaskType(res1.ReportResponse?.Response);
        if (checkInvalidSession(res1)) {
            handleAuthContext();
            return;
        }

        setShowLoader(null);
    }

    // On change Task Type

    const onChangeTaskType = async (e) => {
        console.log("mmm", e);

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        const params2 = {
            "CollectionType": "ECOLLECTION",
            "ReportName": "TIMESTAMP_REPORT",
            "APIName": "fetchStores",
            "Payload": {
                "Region": selectedData.Region, "Country": selectedData.Country, "Year": selectedData.year, "Week": selectedData.week, "Study": e.value
            }
        }


        let params = {
            "Region": selectedData.Region, "Country": selectedData.Country, "Year": selectedData.year, "Week": selectedData.week, "Study": e.value
        }

        const res = await getFetchStoresReport(JSON.stringify(params2), authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        console.log("jjj", res);

        setFormSMSID(res.ReportResponse?.Response)
    }

    useEffect(() => {
        let workOrder_id = [];
        let tasktype = [];
        let sms_id = [];
        let weekNumberData = [];
        let yearData = [];

        formSI?.map(elm => (
            workOrder_id.push(elm.JobId)

        ))
        setFormWorkOrderId([...new Set(workOrder_id)])

        formSITaskType?.map(elm => (
            tasktype.push(elm.Study)

        ))
        setTaskTypeData([...new Set(tasktype)])

        formSMSID?.map(elm => (
            sms_id.push(elm.SMS_ID)

        ))
        setSmsID([...new Set(sms_id)])

        dateStamp?.weekData?.map(elm => (
            weekNumberData.push(elm.DisplayValue)
        ))
        setWeekNumber([...new Set(weekNumberData)])

        dateStamp?.yearData?.map(elm => (
            yearData.push(elm.DisplayValue)
        ))
        setYear([...new Set(yearData)])

    }, [formSI, formSITaskType, formSMSID, dateStamp?.weekData, dateStamp?.yearData])

    const onChange = (e) => {
        console.log(e, "xxx");
        // setSelectedData({
        //     ...selectedData,
        //     [e.target.component.name]: e.value,
        // })

        if (e.target?.component?.name == "year" || e.target?.component?.name == "plantype" || e.target?.component?.name == "week" || e.target?.component?.name == "Country" || e.target?.component?.name == "JobId" || e.target?.component?.name == "smsId") {
            setSelectedData({
                ...selectedData, [e.target.component.name]: e.value
            })

        } else {
            setSelectedData({
                ...selectedData, [e.target.name]: e.value
            })

        }
    }



    //On Search

    const onSearchClick = async (e) => {

        if (!e.isValid) {
            return
        }

        fetchScanTimestampReport()
    };

    const handlePageChange = async (e) => {
        const dataState = e.page;
        setDataState(dataState);
        fetchScanTimestampReport({
            page: Math.floor(dataState.skip / dataState.take) + 1,
            startCount: dataState.skip,
            MaxCount: dataState.take,
        });
    };

    const fetchScanTimestampReport = async (newParams = {}) => {

        const params2 = {
            "CollectionType": "ECOLLECTION",
            "ReportName": "TIMESTAMP_REPORT",
            "APIName": "scanImageReport",
            "Payload": {
                "Region": selectedData.Region,
                "Country": selectedData.Country,
                "CollectionType": "ECOLLECTION",
                "Cycle": null,
                "Year": selectedData.year?.toString(),
                "Week": selectedData.week?.toString(),
                "FromDate": "",
                "ToDate": "",
                "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
                "CompanyId": authContext.CompanyID,
                "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
                "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
                "Study": selectedData.tasktype,
                "AuditStatus": "",
                "page": 1,
                "startCount": 0,
                "MaxCount": 50,
                ...newParams,
            }
        }

        // const params = {
        //     "Region": selectedData.Region, "Country": selectedData.Country, "CollectionType": "ECOLLECTION", "Cycle": null, "Year": selectedData.year, "Week": selectedData.week, "FromDate": "", "ToDate": "", "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "CompanyId": authContext.CompanyID, "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "AuditStatus": "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreType": selectedData.plantype, "Study": selectedData.tasktype, "page": 1, "startCount": 0, "MaxCount": 50, ...newParams,
        //     //   CompanyID: authContext?.CompanyID,
        //     // "page": 1,
        //     // "StartCount": 0,
        //     // "MaxCount": 50,
        //     // ...newParams
        // };
        // const params = {
        //     ScanImageReportReq:
        //         JSON.stringify({ "Request": { "Region": selectedData.Region, "Country": selectedData.Country, "CollectionType": "ECOLLECTION", "Cycle": null, "Year": selectedData.year, "Week": selectedData.week, "FromDate": "", "ToDate": "", "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "CompanyId": authContext.CompanyID, "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "AuditStatus": "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreType": selectedData.plantype, "Study": selectedData.tasktype } }),
        //     //   CompanyID: authContext?.CompanyID,
        //     page: 1,
        //     StartCount: 0,
        //     MaxCount: 50,
        //     ...newParams
        // };

        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });

        const res = await getScanImageTable(JSON.stringify(params2), authContext);
        console.log("www", res);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.ReportResponse?.Status?.StatusMessage === "Success" && Array.isArray(res?.ReportResponse?.Response)) {
            res?.ReportResponse?.Response.map((cn) => {
                const realCountry = locationData?.Country.filter(({ CountryID }) => (CountryID === cn.Country))
                cn.Country = realCountry?.[0]?.CountryName
            });
            const result = {
                data: res?.ReportResponse?.Response,
                total: res?.ReportResponse?.Count,
            };
            setData(result);
        } else {
            console.log("search plan api error", res);
            const result = {
                data: [],
                total: 0,
            };
            setData(result);
            showToast(
                "error",
                t("dataNotFound", `${res?.ReportResponse?.Status?.StatusMessage ? res?.ReportResponse?.Status?.StatusMessage : "No Data Found"}`),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
        setShowLoader(null);

    }

    const expand = () => {
        setShowForm(!showForm);
        setExpandIcon(!showForm ? "fullscreen" : "fullscreen-exit");
    }

    console.log("qqq", selectedData);

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState || initialColumnsState);
    };

    const customCellRender = (td, props) => {
        // debugger
        const { dataItem, field } = props;
        const dataValue = dataItem[field];
        return <td style={{ textAlign: "center" }}>{dataValue?.toString()}</td>
    };

    const CreatePager = useCallback((props) => (
        <MyPager
            {...props}
            onSaveViewClick={handleSaveViewClick}
            onDefaultViewClick={handleDefaultViewClick}
        >
            <Pager style={{ flex: 1 }} {...props} />
        </MyPager>
    ), [stateColumns]);

    const handleSaveViewClick = async () => {
        const params = {
            UserPrefReq: {
                Email: authContext.SpocMail,
                Company_ID: authContext.CompanyID,
                Grid_Name: GRID_NAME,
                Country_ID: selectedData.Country,
                Json_Object: JSON.stringify(stateColumns),
            },
        };
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await updateUserPreferenceAction(
            JSON.stringify(params),
            authContext
        );
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.Response?.Status === "Success") {
            dispatch(
                searchUserPrefrence(
                    JSON.stringify({
                        UserPrefReq: {
                            Email: authContext?.SpocMail,
                            Company_ID: authContext?.CompanyID,
                        },
                    })
                )
            );
            showToast(
                "success",
                t(res?.Response?.Message || "Success")
            );
        } else {
            showToast(
                "error",
                t(res?.Response?.Message || "dataNotFound")
            );
        }
        setShowLoader(null);
    };

    const handleDefaultViewClick = () => {
        onColumnsSubmit();
    };

    const handleRefreshGrid = () => {
        console.log("refresh grid");
        setShouldRefresh(true);
    };

    const backToReports = () => {
        history.goBack();
    }

    useEffect(() => {
        if (data) {
            setResult({
                data: data?.data.map((d) => {
                    if (!isNaN(d?.Country) && d?.Country) {
                        d.Country = parseInt(d.Country);
                    }
                    if (!isNaN(d?.Year) && d?.Year) {
                        d.Year = parseInt(d.Year);
                    }
                    if (!isNaN(d?.Week) && d?.Week) {
                        d.Week = parseInt(d.Week);
                    }
                    if (!isNaN(d?.WorkOrderID) && d?.WorkOrderID) {
                        d.WorkOrderID = parseInt(d.WorkOrderID);
                    }
                    if (!isNaN(d?.AuditId) && d?.AuditId) {
                        d.AuditId = parseInt(d.AuditId);
                    }
                    if (!isNaN(d?.AuditorId) && d?.AuditorId) {
                        d.AuditorId = parseInt(d.AuditorId);
                    }
                    if (!isNaN(d?.Store_Number) && d?.Store_Number) {
                        d.Store_Number = parseInt(d.Store_Number);
                    }
                    if (!isNaN(d?.ShotID) && d?.ShotID) {
                        d.Store_Number = parseInt(d.Store_Number);
                    }
                    if (!isNaN(d?.Shot_Number) && d?.Shot_Number) {
                        d.Store_Number = parseInt(d.Store_Number);
                    }
                    if (!isNaN(d?.Barcode) && d?.Barcode) {
                        d.Barcode = parseInt(d.Barcode);
                    }
                    // if (!isNaN(d?.TOT_SCANNED_BARCODES)) {
                    //     d.TOT_SCANNED_BARCODES = parseInt(d.TOT_SCANNED_BARCODES);
                    // }
                    // if (!isNaN(d?.UNIQUE_IRP_IMAGE_BARCODES)) {
                    //     d.UNIQUE_IRP_IMAGE_BARCODES = parseInt(d.UNIQUE_IRP_IMAGE_BARCODES);
                    // }
                    // if (!isNaN(d?.UNIQUE_SCANNED_BARCODES)) {
                    //     d.UNIQUE_SCANNED_BARCODES = parseInt(d.UNIQUE_SCANNED_BARCODES);
                    // }
                    // if (!isNaN(d?.TOT_COLLECTED_BARCODES)) {
                    //     d.TOT_COLLECTED_BARCODES = parseInt(d.TOT_COLLECTED_BARCODES);
                    // }
                    // if (!isNaN(d?.UNIQUE_COLLECTED_BARCODES)) {
                    //     d.UNIQUE_COLLECTED_BARCODES = parseInt(d.UNIQUE_COLLECTED_BARCODES);
                    // }
                    // if (!isNaN(d?.PLANNED_WEEK)) {
                    //     d.PLANNED_WEEK = parseInt(d.PLANNED_WEEK);
                    // }
                    // if (!isNaN(d?.PLANNED_YEAR)) {
                    //     d.PLANNED_YEAR = parseInt(d.PLANNED_YEAR);
                    // }

                    // if(d?.AuditDATE !== null){
                    //     const audit_date = new Date(d?.AuditDATE);
                    //     if (audit_date !== "Invalid Date") {
                    //         d.AuditDATE = audit_date;
                    //     }
                    // }

                    // if(d?.ShotDate !== null){
                    //     const shot_date = new Date(d?.ShotDate);
                    //     if (shot_date !== "Invalid Date") {
                    //         d.ShotDate = shot_date;
                    //     }
                    // }
                    return d
                }), ...data
            })
        }
    }, [data]);

    return (
        <>
            {showLoader && <LoadMask {...showLoader} />}
            <div className="eq">
                <Header activeMenu={t("nielsennavigator-scanimagereport")}>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginInline: "auto 1em",
                            padding: '6px'
                        }}
                    >
                        <Button
                            className='k-secondary'
                            onClick={backToReports}
                        >
                            Back
                        </Button>
                    </div>
                </Header>
                {showForm ?
                    <div className="scanimageform">
                        <Form
                            initialValues={{
                                regionID: locationData?.Region?.RegionID,
                                countryID: locationData?.Country?.[0]?.CountryID,
                            }}
                            onSubmitClick={onSearchClick}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "0px 25px",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <Field
                                            name={"Region"}
                                            component={FormComboBox}
                                            label={t("REGION")}
                                            required={true}
                                            onChange={onChange}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="RegionName"

                                            valueField="RegionID"

                                            data={[locationData?.Region]}

                                            defaultValue={locationData?.Region?.RegionID}
                                        // disabled

                                        />

                                        <Field
                                            name={"Country"}
                                            component={FormComboBox}
                                            label={t("COUNTRY")}
                                            required={true}

                                            onChange={onChangeCountry}
                                            // onChange={onChange}

                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="CountryName"

                                            valueField="CountryID"

                                            data={locationData?.Country || []}

                                            defaultValue={locationData?.Country?.[0]?.CountryID}
                                        />

                                        <Field
                                            name="year"
                                            label={t("YEAR")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={"Year"}
                                            data={year}
                                            defaultValue={dateStamp?.selectedYear?.DisplayValue}
                                            required={true}
                                            onChange={onChangeYear}
                                        />

                                        <Field
                                            name="week"
                                            label={t("WeekNumber")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("WeekNumber")}
                                            required={true}
                                            data={weekNumber}
                                            defaultValue={dateStamp?.selectedWeek?.DisplayValue}
                                            // value={formData?.startdate}

                                            // validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, startdate: e.value });
                                            // }}
                                            onChange={onChangeWeekNumber}
                                        />

                                        <Field
                                            name="tasktype"
                                            label={t("tasktype")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("tasktype")}
                                            required={true}
                                            data={taskTypeData}
                                            // value={formData?.startdate}

                                            validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, startdate: e.value });
                                            // }}
                                            onChange={onChangeTaskType}

                                        />

                                        <Field
                                            name="JobId"
                                            label={t("WORKORDERID")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("WORKORDERID")}
                                            data={formWorkOrderId}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="auditorId"
                                            label={t("AUDITOR ID")}
                                            component={FormInput}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("AUDITOR ID")}
                                            // data={formplanId}
                                            // required={true}

                                            // validator={inputValidator}
                                            // onChange={(e) => {
                                            //     setSelectedData({ ...selectedData, year: e.value });
                                            // }}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="smsId"
                                            label={t("SMS ID")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("SMS ID")}
                                            data={smsID}
                                            onChange={onChange}
                                        />

                                        <Field
                                            name="plantype"
                                            label={t("PLAN_TYPE_LBL")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("PLAN_TYPE_LBL")}
                                            data={["ALL", "TEST", "LIVE"]}
                                            defaultValue={"LIVE"}
                                            onChange={onChange}
                                        />

                                        <div className={'k-actions k-hstack k-justify-content-stretch'} style={{ marginTop: "29px", gap: "0px 8px", marginLeft: "-7px" }}>
                                            <Button
                                                className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'}
                                                primary
                                            >
                                                {t("SEARCH")}
                                            </Button>
                                            <Button
                                                type={'reset'}
                                                className={'k-secondary'}
                                                onClick={() => {
                                                    setSelectedData(initialFormValue);
                                                    setTimeout(() => {
                                                        formRenderProps.onFormReset();
                                                    }, 0);
                                                }}
                                            >
                                                {t("RESET")}
                                            </Button>
                                        </div>

                                    </div>
                                </FormElement>
                            )}
                        />
                    </div>
                    :
                    <div></div>
                }

                <div
                    className="nielsen-card"
                    style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
                >
                    <div className="eq_data_heading" style={{ margin: "0px -6px" }}>
                        <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
                        <div>
                            <Button
                                onClick={onExport}
                                style={{ margin: "10px", border: "none" }}
                                primary
                            >{t("export")}</Button>

                            <Button icon={expandIcon}
                                onClick={expand}
                                className={'k-secondary'}
                                style={{ margin: "10px", marginRight: '20px' }}
                            ></Button>
                        </div>

                    </div>
                    <div className="catalog" style={{
                        display: "flex",
                        padding: "1rem",
                        flexGrow: 1,
                        overflow: "auto",
                    }}
                    >
                        <Grid
                            className="workOrders_grid"
                            data={result}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                console.log("sort", { e });
                                setResult((result) => {
                                    const { data, total } = result;
                                    return {
                                        data: orderBy(data || [], e.sort),
                                        total,
                                    };
                                });
                                setSort(e.sort);
                            }}

                            filter={filter}
                            onFilterChange={(e) => {
                                // console.log("filter", e.filter  );
                                setResult((result) => {
                                    const { total } = result;
                                    return {
                                        data: filterBy(data?.data || [], e.filter),
                                        total,
                                    };
                                });
                                setFilter(e.filter)
                            }}

                            onPageChange={handlePageChange}
                            cellRender={customCellRender}

                            pageable={{
                                pageSizes: false,
                            }}
                            // onPageChange={handlePageChange}
                            pager={CreatePager}
                            resizable
                            reorderable
                            onColumnReorder={({ columns }) => {
                                const columnOrder = columns.reduce((columnsObj, column) => {
                                    columnsObj[column.field] = column.orderIndex;
                                    return columnsObj;
                                }, {})
                                const newColumns = stateColumns.map((column) => {
                                    return { ...column, orderIndex: columnOrder[column.field] }
                                });
                                setStateColumns(newColumns);
                            }}

                        >
                            {stateColumns.map(
                                (column, idx) => {
                                    const menu = columnMenuConfig[column?.columnMenu];
                                    return column.show &&
                                        (

                                            <GridColumn
                                                key={column?.orderIndex}
                                                field={column?.field}
                                                //title={column?.title}
                                                title={t(column.title)}
                                                width={column?.width}
                                                minResizableWidth={30}
                                                cell={GridCell}
                                                format={column?.format}
                                                filter={column?.filter}
                                                orderIndex={column?.orderIndex}
                                                {...(menu && {
                                                    columnMenu: (props) => {
                                                        return menu?.({
                                                            ...props,
                                                            columns: stateColumns,
                                                            onColumnsSubmit,
                                                            data: data?.data,
                                                        });
                                                    },
                                                })}
                                                headerClassName={
                                                    isColumnActive(column.field, { ...dataState, filter })
                                                        ? "activeFiltered"
                                                        : ""
                                                }
                                            />
                                        )
                                })}
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScanImageTimestamp
