import React from 'react';
import './index.scss';

const MainFooter = props => {
    return (
        <div className="nielsen-footer">
            Copyright <span style={{fontSize: "11px", position: "relative"}}> © </span> 2021 Nielsen Consumer LLC. All Rights Reserved. | <span><a href="https://nielseniq.com/global/en/legal/privacy-policy/" target="_blank" style={{textDecoration: "underline", color: "black", margin: "0 5px 0 5px"}} rel="noreferrer">Privacy policy</a></span>|<span> <a href="https://nielseniq.com/global/en/legal/terms-of-use/" target="_blank" style={{textDecoration: "underline", color: "black", margin: "0 5px 0 5px"}} rel="noreferrer">Terms of use</a></span>
        </div>
    );
};

export default MainFooter;