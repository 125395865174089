import userGroupIcon from "../../../assets/images/usergroup_icon.png";
import { useEffect } from "react";

const MessageDetail = ({ mailDetail }) => {
  if (mailDetail) {
    return (
      <div
        style={{
          padding: "0px 0px 0px 20px",
          borderTop: "8px solid #d6f8a7",
        }}
        dangerouslySetInnerHTML={{ __html: mailDetail }}
      />
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "95vh",
        }}
      >
        <img
          src={userGroupIcon}
          alt="usergroup_icon"
          style={{ height: "80px", width: "98px" }}
        ></img>
        <div style={{ fontSize: "16px" }}>Select Message</div>
      </div>
    );
  }
};

export default MessageDetail;
