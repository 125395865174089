import * as types from "../../actions/types";

const initialState = {
    nielsenSkills: null,
    nonNielsenSkills: null,
    fetchAcquireSkillsResponse: null
};

const skillReducer = (state = initialState, action) => {
    let newState;
        switch(action.type){
           case types.SKILL_SET_NIELSEN_SKILLS:
            newState = {
                ...state,
                nielsenSkills: action.payload
            }
            break;
            case types.SKILL_SET_NON_NIELSEN_SKILLS:
            newState = {
               ...state,
               nonNielsenSkills: action.payload
            }
            break;
            case types.SKILL_GET_ACQUIRE_SKILLS:
            newState = {
                ...state,
                fetchAcquireSkillsResponse: action.payload
            }
            break;
            default:
                newState = state;
                break;
         }
    return newState;
}

export default skillReducer;