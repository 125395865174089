import * as types from "../../actions/types";

const initialState = {
  sosData: null,
  loading: false,
  error: null,
};

const SosReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.GET_SOS + "_FETCH":
      newState = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case types.GET_SOS:
      newState = {
        ...state,
        sosData: action.payload,
        loading: false,
      };
      break;
    case types.GET_SOS + "_ERROR":
      newState = {
        ...state,
        loading: false,
        error: action.payload,
        sosData: null,
      };
      break;

    default:
      newState = state;
      break;
  }
  return newState;
};

export default SosReducer;
