type MapOfStrings = { [key: string]: string };

export function filterData(
  options: any[],
  filter?: string,
  textField: string = 'label',
  valueField: string = 'value',
  substitutions?: MapOfStrings
): any[] {
  if (!filter) {
    return options;
  }

  const cleanFilter = cleanUpText(filter, substitutions);
  return (
    options
      .filter((option) => option[textField] != null && option[valueField] != null)
      .map((option) => ({
        option: option,
        score: typeaheadSimilarity(
          cleanUpText(option[textField], substitutions),
          cleanFilter
        ),
      }))
      .filter((pair) => pair.score >= cleanFilter.length - 2)
      .sort((a, b) => b.score - a.score)
      .map((pair) => pair.option)
  );
};

export function typeaheadSimilarity(a: string, b: string): number {
  const aLength = a.length;
  const bLength = b.length;
  const table: any[] = [];

  if (!aLength || !bLength) {
    return 0;
  }

  if (aLength < bLength) {
    [a, b] = [b, a];
  }

  if (a.indexOf(b) !== -1) {
    return bLength + 1 / aLength;
  }

  for (let x = 0; x <= aLength; ++x) {
    table[x] = [0];
  }
  for (let y = 0; y <= bLength; ++y) {
    table[0][y] = 0;
  }

  for (let x = 1; x <= aLength; ++x) {
    for (let y = 1; y <= bLength; ++y) {
      table[x][y] =
        a[x - 1] === b[y - 1]
          ? 1 + table[x - 1][y - 1]
          : Math.max(table[x][y - 1], table[x - 1][y]);
    }
  }

  return table[aLength][bLength];
};

export function cleanUpText(
  input?: string,
  substitutions?: MapOfStrings
): string {
  if (!input) {
    return "";
  }

  input = input.toUpperCase().replace(/((?=[^\u00E0-\u00FC])\W)|_/g, "");

  if (!substitutions) {
    return input;
  }
  const safeSubstitutions: MapOfStrings = substitutions;

  return Object.keys(safeSubstitutions).reduce((output, substitution) => {
    const unsubbed = new RegExp(substitution, "g");
    return output.replace(unsubbed, safeSubstitutions[substitution]);
  }, input);
};

export const KEY = {
  ARROW_DOWN: "ArrowDown",
  ARROW_UP: "ArrowUp",
  ENTER: "Enter",
  ESCAPE: "Escape",
  SPACE: "Space",
};