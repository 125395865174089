import React from 'react';
import './style.css';
import { useHistory } from "react-router-dom";
import SideMenu from './SideMenu';
import Button from '@progress/kendo-react-buttons/dist/npm/Button';
import { useDispatch, useSelector } from 'react-redux';
import { activeTab, deselectAllRows, getNrpsReportFilters, getNRPSStoreStoresInfo, postNrpsReportAuditorClick, postNrpsReportKpiClick, postNrpsReportPlanClick, postNrpsReportProgressClick, sidebarOpenCloseAction } from '../../../../store/actions/reports';
import LoadMask from '../../../../utils/LoadMask';
import { showToast } from '../../../../utils/Notification';
import { toast } from 'react-toastify';


const NrpsReport = () => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const tabFiltersData = useSelector((store) => store?.reports?.nrpsReportFilters);
  const nrpsReportLoading = useSelector((store) => store?.reports?.nrpsReportLoading);
  const nrpsCommonFiltersParams = useSelector((store) => store?.reports?.nrpsCommonFiltersParams);
  const reportName = useSelector((store) => store?.reports?.reportName);
  const defaultYearAndWeek = useSelector((store) => store?.reports?.defaultYearAndWeek);
  const byCommonFiltersActiveTab = useSelector((store) => store?.reports?.byCommonFiltersActiveTab);
  const selectedGridRow = useSelector((store) => store?.reports?.selectedGridRow);
  const deselectedAllRows = useSelector((store) => store?.reports?.deselectedAllRows);


  const dispatch = useDispatch();
  const history = useHistory();
  const [showLoader, setShowLoader] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [sideMenuClose, setSideMenuClose] = React.useState(false);
  const [SelectedRowSmsId, setSelectedRowSmsId] = React.useState("");

  const checkActive = (index, className) => activeIndex === index ? className : "";


  React.useEffect(() => {
    setActiveIndex(byCommonFiltersActiveTab);
  }, [byCommonFiltersActiveTab]);

  React.useEffect(() => {
    setSelectedRowSmsId(selectedGridRow.toString())
  }, [selectedGridRow])

  React.useEffect(() => {
    if (nrpsReportLoading) {
      setShowLoader({
        className: ".alertCustomization",
        msg: "Loading",
      });
    } else {
      setShowLoader(null)
    }
  }, [nrpsReportLoading])

  React.useEffect(() => {
    setShowLoader(null)
    if (authContext) {
      const params = {
        "vendorId": authContext.CompanyID,
        "countryId": authContext.CountryCode
      };

      const params2 = {
        "CollectionType": "ECOLLECTION",
        "ReportName": "NRPS_REPORT",
        "APIName": "NRPSReportFilters",
        "Payload": {
          "vendorId": authContext.CompanyID,
          "countryId": authContext.CountryCode
        }
    }



      dispatch(getNrpsReportFilters(JSON.stringify(params2), authContext));
      setShowLoader({
        className: ".workOrders_grid",
        msg: "loading",
      });
    }
  }, [])

  React.useEffect(() => {
    if (authContext) {
      if (defaultYearAndWeek?.year && defaultYearAndWeek?.week) {
        const params = {
          "vendorId": authContext.CompanyID,
          "year": defaultYearAndWeek?.year,
          "week": defaultYearAndWeek?.week,
          "country": authContext.CountryCode
        }
        dispatch(getNRPSStoreStoresInfo(params, authContext));
        nrpsCommonFiltersParams.reportType = "Plan"
        nrpsCommonFiltersParams.vendorId = [parseInt(authContext.CompanyID)]
        nrpsCommonFiltersParams.country = authContext.CountryCode
        nrpsCommonFiltersParams.year = defaultYearAndWeek?.year
        nrpsCommonFiltersParams.week = [defaultYearAndWeek?.week]
        dispatch(postNrpsReportPlanClick(nrpsCommonFiltersParams, authContext));
      }
    }
    setActiveIndex(1);
  }, [tabFiltersData, defaultYearAndWeek])



  React.useEffect(() => {
    switch (deselectedAllRows) {
      case 'PLAN':
        handleClick(1)
        break;
      case 'PROGRESS':
        handleClick(2)
        break;
      case 'KPI':
        handleClick(3)
        break;
      case 'AUDITOR':
        handleClick(4)
        break;
      default:
        return null
    }
  }, [deselectedAllRows])


  const handleClick = (index) => {
    console.log("planindex", index, { reportName })
    dispatch(activeTab(index))
    setActiveIndex(index);
    if (nrpsCommonFiltersParams?.year && nrpsCommonFiltersParams?.week) {
      nrpsCommonFiltersParams.vendorId = [parseInt(authContext.CompanyID)]
      nrpsCommonFiltersParams.country = authContext.CountryCode
      if (index === 1) {
        if (deselectedAllRows === 'PLAN') {
          nrpsCommonFiltersParams.reportType = "PLAN"
          nrpsCommonFiltersParams.storeId = ""
          dispatch(postNrpsReportPlanClick(nrpsCommonFiltersParams, authContext));
          dispatch(deselectAllRows(""));
        } else {
          nrpsCommonFiltersParams.reportType = "PLAN"
          nrpsCommonFiltersParams.storeId = nrpsCommonFiltersParams.storeId ? nrpsCommonFiltersParams.storeId : selectedGridRow.toString();
          dispatch(postNrpsReportPlanClick(nrpsCommonFiltersParams, authContext));
        }
      } else if (index === 2) {
        if (deselectedAllRows === 'PROGRESS') {
          nrpsCommonFiltersParams.reportType = "PROGRESS"
          nrpsCommonFiltersParams.storeId = ""
          dispatch(postNrpsReportProgressClick(nrpsCommonFiltersParams, authContext));
          dispatch(deselectAllRows(""));
        } else {
          nrpsCommonFiltersParams.reportType = "PROGRESS"
          nrpsCommonFiltersParams.storeId = nrpsCommonFiltersParams.storeId ? nrpsCommonFiltersParams.storeId : selectedGridRow.toString();
          dispatch(postNrpsReportProgressClick(nrpsCommonFiltersParams, authContext));
        }
      } else if (index === 3) {
        if (deselectedAllRows === 'KPI') {
          nrpsCommonFiltersParams.reportType = "KPI"
          nrpsCommonFiltersParams.storeId = ""
          dispatch(postNrpsReportKpiClick(nrpsCommonFiltersParams, authContext));
          dispatch(deselectAllRows(""));
        } else {
          nrpsCommonFiltersParams.reportType = "KPI"
          nrpsCommonFiltersParams.storeId = nrpsCommonFiltersParams.storeId ? nrpsCommonFiltersParams.storeId : selectedGridRow.toString();
          dispatch(postNrpsReportKpiClick(nrpsCommonFiltersParams, authContext));
          
        }
      } else if (index === 4) {
        if (deselectedAllRows === 'AUDITOR') {
          nrpsCommonFiltersParams.reportType = "AUDITOR"
          nrpsCommonFiltersParams.storeId = ""
          dispatch(postNrpsReportAuditorClick(nrpsCommonFiltersParams, authContext));
          dispatch(deselectAllRows(""));
        } else {
          nrpsCommonFiltersParams.reportType = "AUDITOR"
          nrpsCommonFiltersParams.storeId = nrpsCommonFiltersParams.storeId ? nrpsCommonFiltersParams.storeId : selectedGridRow.toString();
          dispatch(postNrpsReportAuditorClick(nrpsCommonFiltersParams, authContext));
        }
      } else {
        return
      }
      dispatch(sidebarOpenCloseAction(false));
    }

  }

  return (
    <React.Fragment>
      {showLoader && <LoadMask {...showLoader} />}
      <div className='nrps-nav'>
        <div className='tab-btn'>
          <div className={`header-tab ${checkActive(1, "header-active-tab")}`}
            onClick={() => handleClick(1)}>PLAN</div>
          <div className={`header-tab ${checkActive(2, "header-active-tab")}`}
            onClick={() => handleClick(2)}>PROGRESS</div>
          <div className={`header-tab ${checkActive(3, "header-active-tab")}`}
            onClick={() => handleClick(3)}>KPI</div>
          <div className={`header-tab ${checkActive(4, "header-active-tab")}`}
            onClick={() => handleClick(4)}>AUDITOR</div>
        </div>
        <div>
          <Button
            className='k-secondary'
            icon="k-icon k-i-arrow-left left-arrow k-icon-lg"
            onClick={() => history.push("/reports")}>
            BACK TO REPORTS
          </Button>
        </div>
      </div>
      <div>
        <SideMenu activeIndex={activeIndex} sideMenuClose={sideMenuClose} />
      </div>
    </React.Fragment>
  )
}

export default NrpsReport
