import { Upload } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { useState, useRef, useEffect } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
// import AppURL from "../../../utils/AppURL";
// import AppURL from "../../../utils"
import React from "react";
import { postPlanUpload } from "../../../store/actions/workOrder/bulkUpload";
import { useSelector } from "react-redux";
import {
  FormComboBox,
  FormInput,
  FormUpload,
} from "../../ui/form/form-component";
import { uploadAuditor } from "../../../store/actions/people/bulkUploads";
import AppURL from "../../../utils/AppURL";
import { showToast } from "../../../utils/Notification";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";

const UploadWindow = ({ setUploadWindow, selectedDataUpload,screen,rolePepplePage }) => {
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const { handleAuthContext } = useLogout();

  const [isFormShow, setFormShow] = useState(true);

  const [loadAuditorsURL, setLoadAuditorsURL] = useState(AppURL.loadAuditors);
  const handleBeforeUpload = (event,formProps) => {
    console.log("handleBeforeUpload",formProps)
    event.headers = {
      userid: authContext?.SpocMail,
    };
    const RegionId = locationData?.Region?.RegionID;
    // const CountryId = locationData?.Country?.[0]?.CountryID;
    const CountryId = formProps.valueGetter("CountryId");
    
    event.additionalData.postdata = JSON.stringify({
      formData: [
        { name: "file", value: event?.files[0]?.name },
        { name: "countryId", value: CountryId },
        { name: "BatchId" },
      ],
    });
    event.additionalData.RegionId = RegionId;
    event.additionalData.CountryId = CountryId;

  };

  const onStatusChange = (e) => {
    if (e.response) {
      if (checkInvalidSession(e.response?.response)) {
        handleAuthContext();
        return;
      }
      if(e.response?.response?.Response?.success!=="true"){
        if (e.response?.response?.Response?.msg?.length > 1) {
          showToast("error",<ol>{e.response?.response?.Response?.msg?.map((s)=><li>{s.Message}</li>)}</ol>)
        } else {
          showToast("error",e.response?.response?.Response?.msg?.[0]?.Message);
        }
        return;
      }
      if (e.response?.response?.Response?.msg?.length > 1) {
        showToast("success",<ol>{e.response?.response?.Response?.msg?.map((s)=><li>{s.Message}</li>)}</ol>)
      } else {
        showToast("success",e.response?.response?.Response?.msg?.[0]?.Message);
      }
    }
};

  useEffect(() => {
    if (authContext?.SpocMail && !loadAuditorsURL.includes("spocmailid")) {
      setLoadAuditorsURL(
        loadAuditorsURL + `?spocmailid=${authContext.SpocMail}`
      );
    }
  }, [authContext]);

  return (
    <Window
      title={"Upload File"}
      onClose={() => setUploadWindow(false)}
      modal={true}
      minimizeButton={() => (
        <Button
          icon="question"
          onClick={() => setFormShow(!isFormShow)}
          style={{ marginRight: ".5em" }}
      
        />
      )}
      maximizeButton={() => null}
      restoreButton={() => null}
      // initialTop={70}
      initialHeight={356}
      initialWidth={590}
      style={{ paddingBottom: "1em" }}
    >
      {isFormShow ? (
        <>
          <Form
            initialValues={{
              CountryId:locationData?.Country?.[0]?.CountryID
            }}
            // onSubmitClick={handleUpload}
            enctype="multipart/form-data"
            render={(formRenderProps) => (
              <FormElement>
                <div
                  style={{
                    // display: "flex",
                    gap: 40,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    margin: 0,
                  }}
                >
                  {/* make dynamic later */}
                  <div style={{ display: "flex", gap: "1em" }}>
                    <Field
                      label="Region"
                      component={FormComboBox}
                      wrapperStyle={{ flex: 1 }}
                      textField="RegionName"
                      valueField="RegionID"
                      // data={[{ RegionName: "Europe", RegionID: 4 }]}
                      data={[locationData?.Region]}
                      defaultValue={locationData?.Region?.RegionID}
                      disabled
                      required
                    />
                    <Field
                      name="CountryId"
                      label="Country"
                      component={FormComboBox}
                      textField="CountryName"
                      valueField="CountryID"
                      // data={[{ countryName: "Belgium", countryId: 23 }]}
                      data={locationData?.Country || []}
                      wrapperStyle={{ flex: 1 }}
                      // defaultValue={locationData?.Country?.[0]?.CountryID}
                      required
                    />
                  </div>
                  {/* <Upload
                    batch={false}
                    multiple={false}
                    ref={uploadRef}
                    defaultFiles={[]}
                    autoUpload={false}
                    withCredentials={false}
                    // saveField={"storeFile"}
                    saveUrl={`/fmsaud/planupload?${queryParamsRef.current}`}
                    // disabled={!formRenderProps.valid}
                    removeUrl={()=>`/fmsaud/planupload?${queryParamsRef.current}`}
                    accept={".xlsx"}
                    restrictions={{
                      allowedExtensions: [".xlsx"],
                    }}
                    // onStatusChange={onStatusChange}
                    onBeforeUpload={async (e) =>  await onBeforeUpload(e, formRenderProps)}
                  /> */}

                  {/* <Field
                    name={"File"}
                    component={FormUpload}
                    label={"File"}
                    // onChange={onCountryChange}
                    // validator={(prop) =>
                    //   acceptFileValidator(prop, new RegExp(/^.*\.(xlsx)$/))
                    // }
                    onBeforeUpload={handleBeforeUpload}
                    saveUrl={loadAuditorsURL}
                    required={true}
                    accept={".xlsx"}
                    restrictions={{
                      allowedExtensions: [".xlsx"],
                    }}
                  /> */}
                </div>

                <label>Files*</label>
                <Upload
                  accept={".xlsx"}
                  autoUpload={false}
                  batch={false}
                  restrictions={{
                    allowedExtensions: [".xlsx"],
                    // maxFileSize: 4000000,
                  }}
                  defaultFiles={[]}
                  showActionButtons={true}
                  actionsLayout={"stretched"}
                  multiple={false}
                  saveField={'BrowseFile'}
                  // withCredentials={false}
                  // saveUrl={
                  //   "https://demos.telerik.com/kendo-ui/service-v4/upload/save"
                  // }'
                  onBeforeUpload={(e)=>handleBeforeUpload(e,formRenderProps)}
                  saveUrl={loadAuditorsURL}
                  // removeUrl={
                  //   "https://demos.telerik.com/kendo-ui/service-v4/upload/remove"
                  // }
                  onStatusChange={onStatusChange}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".8em",
                    position: "absolute",
                    bottom: 0,
                    width: "calc(100% - 2em)",
                  }}
                >
                  {/* <Button
                    type="reset"
                    onClick={() => {
                      // formRenderProps.onFormReset();
                    }}
                  >
                    Reset
                  </Button>
                  <Button type="submit" primary>
                    Upload
                  </Button> */}
                </div>
              </FormElement>
            )}
          />
        </>
      ) : (
        <div>
          <Button icon="arrow-left" onClick={() => setFormShow(true)}>
            Back To Upload
          </Button>
          <ul style={{ color: "#000", fontWeight: 500 }}>
            <li>Please read through the below rules and tips.</li>
            <li>
              RESOURCE_ID values are dummy values; Please update before
              uploading. For new resources the ID can be only 6 digits long.
            </li>
            <li>All column fields are comma separated.</li>
            <li>
              !(Exclamation sign) Represents mandatory column to be filled.
            </li>
            <li>
              Date format - yyyy/mm/dd. Date should be wrapped within single
              quotes.
            </li>
            <li>Mobile Number should not exceed 20 digits.</li>
            <li>
              Languages and Skills are multiple and are separated with |
              operator eg: English|French.
            </li>
            <li>
              To add multiple devices to same ResourceID- Copy the same row and
              change only the device details; columns [P Q R].
            </li>
            <li>
              To remove device(s) from an Auditor delete the device details from
              columns [P Q R] (OR) Simply delete the entire row(s).
            </li>
            <li>
              If this sample file is used to upload the resources then please
              remove all rules; Also remove empty/blank lines, if any.
            </li>
            <li>
              EmailID should contain only one @ and other validations also
              apply. Sample: Sample.EmailID123@SomeDomain.com.
            </li>
            <li>
              The length allowed for FirstName; LastName; MiddleName and
              Personal Identification is 50 characters.
            </li>
            <li>
              Gender value should be one of these: [MALE or FEMALE or NOT
              SPECIFIED].
            </li>
            <li>
              Please make sure to provide State/Province; City and zip/Postal
              code values ins accordance with the country.
            </li>
          </ul>
        </div>
      )}
    </Window>
  );
};

export default UploadWindow;
