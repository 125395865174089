import { useState, useEffect, useMemo } from "react";
import * as types from "../../store/actions/types";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MessageBox } from "../../utils/Notification";
import MainFooter from "../main/mainFooter";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { logout } from "../../store/actions/logout";
import NielsenLogo from "../../assets/img/nielsen_logo.svg";
import apiImg from "../../assets/img/api.png";
import { downloadGuidelines } from "../../store/actions/dashboard";
import LoadMask from "../../utils/LoadMask";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import useLogout, { checkInvalidSession } from "../../utils/useLogout";
import HomeIcon from "../icons/HomeIcon";
import PeopleIcon from "../icons/PeopleIcon";
import WorkOrdersIcon from "../icons/WorkOrdersIcon";
import ReportsNewIcon from "../icons/ReportsNewIcon";
import MessagesIcon from "../icons/MessagesIcon";
import HelpIcon from "../icons/HelpIcon";
import UserProfileIcon from "../icons/UserProfileIcon";
import LogOutIcon from "../icons/LogOutIcon";
import IqctIcon from "../icons/IqctIcon";
import AppURL from "../../utils/AppURL";

export const menusObj = [
  {
    text: "Home",
    route: "/dashboard",
    img: HomeIcon,
    screen:"1"
  },
  {
    text: "PEOPLE",
    img: PeopleIcon,
    route: "/people",
    screen:"2"
  },
  {
    text: "WORKORDERS",
    img: WorkOrdersIcon,
    route: "/work_orders",
    screen:"3"
  },
  {
    text: "REPORTS",
    img: ReportsNewIcon,
    route: "/reports",
    screen:"4"
  },
  {
    text: "notifications",
    img: MessagesIcon,
    route: "/notifications",
    screen:"5"
  },
  {
    text: "usermanual",
    img: HelpIcon,
    route: "/user_manual",
    screen:"6"
  },
  {
    text: "userProfile",
    img: UserProfileIcon,
    route: "/user_profile",
    screen:"7"
  },
  {
    text: "Logout",
    img: LogOutIcon,
    route: "/logout",
    screen:"8"
  },
];


const drawerProps = {
  position: "start",
  mode: "push",
  mini: true,
};

const CustomItem = ({ expanded, ...props }) => {
  const ImgComp = props.img;
  const [hover, setHover] = useState(false);
  const getMailNewCount = useSelector((state) => state.commonReducer.getMailNewCount);
  const dashboradResponse = useSelector(
    (state) => state.dashboardReducer.dashboardResponse
  );

  return (
    <DrawerItem {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="menuItem">
        {(props.text === 'Notifications') ?
          <BadgeContainer className="forBadge">
            <ImgComp
              width='32px'
              style={{
                padding: '8px',
              }}
              color={hover || props.selected ? '#2D6DF6' : "#4F4F4F"}
            />
            <Badge className="notify-badge" style={{
              backgroundColor: (hover || props.selected) ? '#2D6DF6' : "#4F4F4F",
              borderColor: (hover || props.selected) ? '#2D6DF6' : "#4F4F4F"
            }}>
              {getMailNewCount?.GetMails?.NewCount ?? dashboradResponse?.GetMails?.NewCount}
            </Badge>
          </BadgeContainer>
          :
          <ImgComp
            width='32px'
            style={{
              padding: '8px',
            }}
            color={hover || props.selected ? '#2D6DF6' : "#4F4F4F"}
          />
        }
        {expanded ? <span className="k-item-text" style={{ marginLeft: '20px' }}><b>{props.text}</b></span> : <span className="k-item-text onHover" style={{ marginLeft: '20px' }}><b>{props.text}</b></span>}
      </div>
    </DrawerItem>);
};

const Dashboard = (props) => {
  const { handleAuthContext } = useLogout();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const isApiError = useSelector((state) => state.commonReducer.isApiError);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [messageProps, setMessageProps] = useState(null);
  const [showLoader, setShowLoader] = useState(null);

  const iqct_flag = JSON.parse(
    localStorage.getItem("iqct_flag")
  ) ?? false;

  const handleClick = () => {
    setExpanded(!expanded);
  };
  //my code
  const menusList = useMemo(() => {
    const newMenuObj = menusObj.slice();
    // if (authContext?.AdminApi === "N") {
    //   newMenuObj.splice(4, 0, {
    //     text: "API",
    //     // icon: "k-i-home",
    //     img: apiImg,
    //     route: "/api",
    //   })
    // }

    if (iqct_flag === true) {
      newMenuObj.splice(4, 0, {
        text: "iQCT",
        img: IqctIcon,
        route: "",
      })
    }

    return newMenuObj.map((obj) => {
      obj.text = t(obj.text)
      return obj
    })

  }, [iqct_flag]);

  function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (const element of cookies) {
      const cookie = element;
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  const downloadGuidelinesAsync = async () => {
    setShowLoader({
      // className: ".alertCustomization",
      msg: t("_loading"),
    });
    const res = await downloadGuidelines(null, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    const disposition = res?.headers.get("Content-Disposition")?.split("filename=")
    if (disposition) {
      const exportFileBlob = await res.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl;
      anchor.download = disposition?.[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(exportFileUrl)
    }
    setShowLoader(null)
  }

  const onSelect = (e) => {
    if (e?.itemTarget?.props?.text === t("Logout")) {
      let spocDetails = JSON.parse(localStorage.getItem(`spocDetails`));
      let CompanyId = spocDetails?.CompanyID,
        UserId = spocDetails?.SpocMail;
      console.log(CompanyId, UserId);
      //logout api
      dispatch(logout({ CompanyId, UserId }));
      dispatch({
        type: types.SET_AUTH_CONTEXT,
        payload: null,
      });
      localStorage.removeItem("spocDetails");
      // deleteAllCookies();
      history.push("/login");
    } else if (e?.itemTarget?.props?.text === t("usermanual")) {
      downloadGuidelinesAsync()
    } else if (e?.itemTarget?.props?.text === t("iQCT")) {
      window.open(AppURL.iqct, "_blank");
    } else {
      history.push(e.itemTarget.props.route);
    }
  };

  const setSelectedItem = (pathName) => {
    const newRoute = "/" + pathName?.split("/")[1]
    let currentPath = menusList.find((item) => item.route === newRoute);

    if (currentPath?.text) {
      return currentPath.text;
    }
  };

  let selected = setSelectedItem(props?.location?.pathname);

  useEffect(() => {
    if (isApiError) {
      console.log("api failure", { isApiError });
      const msg =
        isApiError?.statusText ||
        "System error occurred, Please contact administrator";
      messagePopup(msg, "OK", "INFO");
    } else {
      setMessageProps(null);
    }
  }, [isApiError]);

  const messagePopup = (msg, button, icon, action) => {
    const callBackFn = (msg) => {
      setMessageProps(null);
      switch (action?.type) {
        case "alertUpdated":
          break;
        default:
          break;
      }
    };
    setMessageProps({
      title: t("_error", "Error"),
      buttons: button,
      icon: icon,
      message: t(`_${msg}`, `${msg}`),
      fn: callBackFn,
    });
  };

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}
      <div>
        <div
          style={{
            color: "white",
            position: "absolute",
            zIndex: "1",
            marginTop: '5px',
            marginLeft: '10px'
          }}
        >
          <div style={{
            display: "flex",
          }}>
            <div>
              <img
                src={NielsenLogo}
                style={{
                  width: 60,
                  margin: "5px 3px",
                  cursor: "pointer",
                }}
                alt="NIQ LOGO"
                onClick={handleClick}
              />
            </div>
          </div>

          {expanded && (
            <div
              style={{
                lineHeight: 1,
                letterSpacing: "0px",
                fontSize: "20px",
                color: "#060A45",
                position: 'relative',
                left: "0px",
                top: "30px",
                marginLeft: ".3em",
              }}
            >
              Nielsen IQ Navigator
            </div>
          )}
        </div>
        <div style={{
          position: 'relative',
          top: '45px',
          zIndex: 2
        }}>
          <hr className="header-line" />
        </div>

        <div
          style={{
            height: "96vh",
          }}
        >
          <Drawer
            className="navMenu"
            width={210}
            miniWidth={76}
            style={{ height: "100%", backgroundColor: '#F2F2F2' }}
            expanded={expanded}
            item={CustomItem}
            items={menusList.map((item) => ({
              ...item,
              expanded: expanded,
              selected: item.text === selected,
            }))}
            {...drawerProps}
            onSelect={onSelect}
          >
            <DrawerContent
              className="navMenu"
            >{props.children}</DrawerContent>
          </Drawer>
        </div>
      </div>

      <MainFooter />
    </>
  );
};
export default Dashboard;
