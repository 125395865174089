import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../header";
import PreviousJob from "./previousJob";
import { MessageBox, showToast } from "../../../../utils/Notification";
import { useSelector } from "react-redux";
import { getNielsenStarEndDates } from "../../../../store/actions/workOrder/prefill";
import LoadMask from "../../../../utils/LoadMask";
import { postLockStatus } from "../../../../store/actions/workOrder/actionWorkOrder";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";

const PreFill = () => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const workOrderDetail = useSelector(
    (state) => state.commonReducer.workOrderDetail
  );
  const {handleAuthContext} = useLogout();
  const { t } = useTranslation();
  const history = useHistory();
  const [isPreviousJob, setPreviousJob] = useState(false);
  const [messageProps, setMessageProps] = useState(null);
  const [showLoader, setShowLoader] = useState(null);

  const getNielsenStarEndDatesStart = async ()=>{
    const params = {
      job_id:workOrderDetail?.JobId
    }
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await getNielsenStarEndDates(params,authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    console.log("getNielsenStarEndDatesStart",{res});
    setShowLoader(null);
    if(res.Response.status !== "Success"){
      showToast("error","Error in nielsen start and end date");
      return;
    }
    showToast("success", res?.Response?.statusMsg);

    const lockPram = {
      JobId: workOrderDetail?.JobId,
      LockStatus: "0",
      User: authContext?.SpocMail,
    };
    postLockStatus(lockPram,authContext);

    history.push("/work_orders");
  }
  const messagePopup = (msg, button, icon) => {
    const callBackFn = (msg) => {
      setMessageProps(null);
      if (msg === 'YES') {
        getNielsenStarEndDatesStart();
      }
    };
    setMessageProps({
      title: "Are you sure",
      buttons: button,
      icon: icon,
      message: msg,
      fn: callBackFn,
    });
  };
  const handleAutoPupulateClick = () => {
    messagePopup(
      t("doyouwanttopre-populatethecurrentcyclestartandenddate"),
      "YESCANCEL",
      "QUESTION"
    );
  };

  return (
    <>
    {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}
      {isPreviousJob ? (
        <PreviousJob setPreviousJob={setPreviousJob} />
      ) : (
        <>
          <Header
            activeMenu={"Pre fill"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "6px",
                marginRight: 15,
                gap: 15,
              }}
            >
              <Button className="k-secondary" style={{ width: 100 }} onClick={() => history.goBack()}>
                Cancel
              </Button>
            </div>
          </Header>
          <div
            style={{
              display: "flex",
              gap: 16,
              justifyContent: "center",
              padding: 30,
            }}
          >
            <Button
              style={{
                backgroundColor: "#eee",
                flexDirection: "column",
                borderRadius: 5,
                padding: 15,
              }}
              onClick={() => setPreviousJob(true)}
            >
              <div
                className="k-icon k-i-copy"
                style={{ display: "block", fontSize: 70 }}
              />
              <div>Copy From Previous Plan</div>
            </Button>
            <Button
              style={{
                backgroundColor: "#eee",
                flexDirection: "column",
                borderRadius: 5,
                padding: 15,
              }}
              onClick={handleAutoPupulateClick}
            >
              <div
                className="k-icon k-i-calendar"
                style={{ display: "block", fontSize: 70 }}
              />
              <div>
                Auto Populate
                <br /> Nielsen Start Date , End Date
              </div>
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default PreFill;
