import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";

const getHeaders = (authContext) => ({
  "Content-Type": "application/json",
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid:authContext?.CompanyID || ""
});
const getHeadersPost = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid:authContext?.CompanyID || "",
  "Content-Type": "application/json"
});

export const getAdminDetails = async (params, authContext) => {
  const res = await Get(
    AppURL.fetchAdminDetails,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  const temp = res?.root?.Spocs
  if(temp){
    // temp?.splice(10);
    temp?.map((d,i)=>{d.uniqueId=i+1})
    res.root.Spocs = temp;
  }
  // console.log("fetchAdminDetails", { res });
  return res;
};

export const postDeactivateAdmin = async (params, authContext) => {
  const res = await Post(
    AppURL.deactivateAdmin,
    params,
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());
  return res;
};
export const postActivateAdmin = async (params, authContext) => {
  const res = await Post(
    AppURL.activateAdmin,
    params,
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());
  return res;
};
export const postEditAdmin = async (params, authContext) => {
  const res = await Post(
    AppURL.editAdmin,
    params,
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());
  return res;
};

