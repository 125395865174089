import { Get, Post, PostwithTimeoutHandler } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";


export const getAuditSummarySearchResult = async (params, authContext) => {

    const header = {
        OrganizationUnit: authContext?.organizationUnit || "",
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "content-type": "application/json",
    }
 

    const res = await PostwithTimeoutHandler(AppURL.reportsAPI , params, header, true,10000).then((res) =>
        res.json()
    );
    return res;
    // return MOBILEVERSIONRESOSPONSE;
};


export const getAuditSummaryExportData = async (params, authContext) => {

    const header = {
      
    
    }
  
    // const exportFile = await Get(AppURL.getAuditSummaryExportData,params,null,true);    
    const exportFile = await Post(AppURL.getAuditSummaryExportData,params,null,true);    
      console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
      const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")    
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);    
      // const FILE_NAME = Date.now();
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl; 
      anchor.download = disposition?.[1].replace(/['"]+/g, '');      
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor); 
  
  
  
  };