import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  SearchFilterComp,
  SearchFilterContainer,
  SearchFilterFooter,
  SearchForm,
} from "../../ui/SearchFilter";
import { Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { FormComboBox, FormInput } from "../../form/form-component";
import moment from "moment";
import LoadMask from "../../../utils/LoadMask";
import { getBulkPlanUpload } from "../../../store/actions/workOrder/bulkUpload";
import { useSelector } from "react-redux";
import { getAuditorDetails } from "../../../store/actions/people/bulkUploads";
import { useTranslation } from "react-i18next";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";

const initialSelectedData = {};
const SearchFilterWindow = ({
  setResult,
  setShouldRefresh,
  shouldRefresh,
  dataStateTake,
  dataStateSkip,
  setDataState,
  setSearthTerm,
  searchTerm,
}) => {
  const authContext = useSelector((state) => state.commonReducer.authContext);

  const [capsulesFilter, setCapsulesFilter] = useState([]);
  const [selectedData, setSelectedData] = useState(initialSelectedData);
  const [showLoader, setShowLoader] = useState(null);
  const { t, i18n } = useTranslation();
  const { handleAuthContext } = useLogout();
  const formRef = useRef();

  const fetchAuditorDetails = async (newParams) => {
    // const params = {
    //   ...newSelectedData,
    //   page: 1,
    //   StartCount: 0,
    //   MaxCount: 50,
    // };
    const BatchId = newParams?.BatchId || "";
    const Status = newParams?.Status || "";
    const params = {
      CountryId: authContext?.CountryCode,
      Companyid: authContext?.CompanyID,
      _dc: Date.now(),
      page: Math.floor(dataStateSkip / dataStateTake) + 1,
      StartCount: dataStateSkip,
      MaxCount: dataStateTake,
      userid: authContext?.SpocMail,
      ...(BatchId ? { BatchId } : {}),
      ...(Status ? { Status } : {}),
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await getAuditorDetails(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    const AuditorDetailsRes = res?.AuditorDetailsRes;
    if (AuditorDetailsRes) {
      const newResult = {
        data: AuditorDetailsRes?.Records || [],
        total:
          AuditorDetailsRes.Count ||
          AuditorDetailsRes?.Records?.length ||
          dataStateTake,
      };
      setResult(newResult);
    } else {
      console.log("deactivateAdmin api error", res);
    }
    setShowLoader(null);
  };

  const removeCapsuleFilter = (index) => {
    console.log("reached to remove capsule filter", { index });
    const { name: removeFieldName, value: removeFieldValue } =
      capsulesFilter[index];
    const newSelectedData = { ...selectedData };
    if (removeFieldName === "Country") {
      newSelectedData.Country = newSelectedData.Country.filter(
        (c) => c.CountryID !== removeFieldValue
      );
    } else {
      delete newSelectedData[removeFieldName];
    }
    setSelectedData(newSelectedData);
    const newCapsuleFilter = [...capsulesFilter];
    newCapsuleFilter.splice(index, 1);
    setCapsulesFilter(newCapsuleFilter);
    // const fetchPlanUpload = async () => {
    // };
    // fetchPlanUpload();
    fetchAuditorDetails(newSelectedData);
  };

  const onSearchClick = useCallback(async (e) => {
    const searchValue = e.target.elements.searchWorkOrder.value;
    const params = {
      CountryId: authContext?.CountryCode,
      Companyid: authContext?.CompanyID,
      ...(searchValue ? { keyword: searchValue } : {}),
      _dc: Date.now(),
      page: 1,
      StartCount: 0,
      MaxCount: dataStateTake,
      userid: authContext?.SpocMail,
    };
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await getAuditorDetails(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    const AuditorDetailsRes = res?.AuditorDetailsRes;
    if (AuditorDetailsRes) {
      const newResult = {
        data: AuditorDetailsRes?.Records || [],
        total:
          AuditorDetailsRes.Count ||
          AuditorDetailsRes?.Records?.length ||
          dataStateTake,
      };
      console.log("newResult", newResult)
      setResult(newResult);
    } else {
      console.log("fetch auditor details api error", res);
    }
    setShowLoader(null);
    const searchTerm = e.target?.searchWorkOrder?.value;
    setSearthTerm(searchTerm);
    setDataState((state) => {
      return { ...state, skip: 0 };
    });
    // setSelectedData(initialSelectedData);
  }, []);

  const onFilterSubmitClick = async ({ values }) => {
    formRef.current.setFilterWindow(false);
    const newCapsuleFilter = Object.entries(values).reduce(
      (acc, [key, value]) => {
        // if (key === "Region") {
        //   return acc;
        // }
        // if (key === "Country") {
        //   const countryData = value.map((c) => ({
        //     name: key,
        //     text: c.CountryName,
        //     value: c.CountryID,
        //   }));
        //   return [...acc, ...countryData];
        // }
        // if (key === "startdate" || key === "enddate") {
        //   value = moment(value).format("MM/DD/YYYY");
        // }
        return [
          ...acc,
          {
            name: key,
            text: value,
            value,
          },
        ];
      },
      []
    );
    // const newSearchParams = newCapsuleFilter.reduce((acc, cur) => {
    //   const { name, value } = cur;
    //   return {
    //     ...acc,
    //     [name]: value,
    //   };
    // }, {});
    setCapsulesFilter(newCapsuleFilter);
    setSelectedData(values);

    // const {
    //   BatchId,
    //   Status
    // } = values;
    fetchAuditorDetails(values);
  };

  useEffect(() => {
    if (shouldRefresh) {
      const fetchAuditorDetailsRefresh = async () => {
        const params = {
          CountryId: authContext?.CountryCode,
          Companyid: authContext?.CompanyID,
          page: 1,
          StartCount: 0,
          MaxCount: dataStateTake,
          ...selectedData,
          userid: authContext?.SpocMail,
        };
        // if(searchTerm){
        //   params.keyword = searchTerm;
        // }
        setDataState((state) => {
          return { ...state, skip: 0 };
        });
        setShowLoader({
          className: ".people_grid",
          msg: "loading",
        });
        const res = await getAuditorDetails(params, authContext);
        if (checkInvalidSession(res)) {
          handleAuthContext();
          return;
        }
        const AuditorDetailsRes = res?.AuditorDetailsRes;
        if (AuditorDetailsRes) {
          const newResult = {
            data: AuditorDetailsRes?.Records || [],
            total:
              AuditorDetailsRes.Count ||
              AuditorDetailsRes?.Records?.length ||
              dataStateTake,
          };
          setResult(newResult);
        } else {
          console.log("fetch auditor details api error", res);
        }
        setShowLoader(null);
      };
      fetchAuditorDetailsRefresh();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (authContext) {
      fetchAuditorDetails(selectedData);
    }
  }, [authContext, dataStateSkip, dataStateTake, searchTerm]);
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <SearchFilterContainer>
        <SearchForm
          // placeholder={
          //   "Search By Batch Id, File_Name, Uploaded On, Uploaded By"
          // }
          placeholder={
            `${t("searchby")} ${t("BatchId")} ${t("filename")}  ${t("updatedon")} ${t("updatedby")}`
          }
          onSubmitClick={onSearchClick}
        />

        <SearchFilterComp
          ref={formRef}
          onSubmitClick={onFilterSubmitClick}
          selectedData={selectedData}
          capsulesFilter={capsulesFilter}
          removeCapsuleFilter={removeCapsuleFilter}
          windowProps={{
            initialTop: 170,
            initialWidth: 1165,
            initialHeight: 200,
          }}
        >
          {(formRenderProps) => (
            <FormElement>
              <div style={{ display: "flex", gap: 15, flexWrap: "wrap" }}>
                <Field
                  name="BatchId"
                  //label="Batch Id"
                  label={t("batchid")}
                  component={FormInput}
                  wrapperStyle={{ flex: 1 }}
                  //placeholder={"Batch Id"}
                  placeholder={t("batchid")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, batchId: e.value });
                  }}
                />
                <Field
                  name="Status"
                  label={t("Status")}
                  component={FormComboBox}
                  wrapperStyle={{ flex: 1 }}
                  placeholder={t("Status")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, status: e.value });
                  }}
                  data={["Success", "Error"]}
                />
              </div>
              <SearchFilterFooter handleReset={() => {
                setSelectedData(initialSelectedData);
                setTimeout(() => {
                  formRenderProps.onFormReset();
                }, 0);
              }} />
            </FormElement>
          )}
        </SearchFilterComp>
      </SearchFilterContainer>
    </>
  );
};

export default SearchFilterWindow;
