import React from "react";
import "./style.css";
import PlanGrid from "./PlanGrid";
import PlanTabHeader from "./PlanTabHeader";
import ProgressTabHeader from "./ProgressTabHeader";
import KpiTabHeader from "./KpiTabHeader";
import AuditorTabHeader from "./AuditorTabHeader";
import DefaultReport from "./DefaultReport";
import ProgressGrid from "./ProgressGrid";
import KpiGrid from "./KpiGrid";
import AuditorGrid from "./AuditorGrid";

const TabHeader = ({ activeIndex, tabFilterResponse }) => {
  console.log("tabnavwidth", activeIndex, tabFilterResponse);
  return (
    <React.Fragment>
      {(() => {
        switch (activeIndex) {
          case 0:
            return (
              <>
                <DefaultReport />
                <p style={{textAlign:'center'}}>No records available</p>
              </>
            );
          case 1:
            return (
              <>
                <PlanTabHeader activeIndex={activeIndex} tabFilterResponse={tabFilterResponse} />
                <PlanGrid />
              </>
            );
          case 2:
            return (
              <>
                <ProgressTabHeader activeIndex={activeIndex} tabFilterResponse={tabFilterResponse}/>
                <ProgressGrid />
              </>
            );
          case 3:
            return (
              <>
                <KpiTabHeader activeIndex={activeIndex} tabFilterResponse={tabFilterResponse} />
                <KpiGrid />
              </>
            );
          case 4:
            return (
              <>
                <AuditorTabHeader activeIndex={activeIndex} tabFilterResponse={tabFilterResponse} />
                <AuditorGrid />
              </>
            );
          default:
            return null;
        }
      })()}      
    </React.Fragment>
  );
};

export default TabHeader;


