import {
  Grid,
  GridColumn,
} from "@progress/kendo-react-grid";
import { columns, columnMenuConfig } from "./column";
import { Pager } from "@progress/kendo-react-data-tools";
import { useEffect, useState, useCallback } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { filterBy, orderBy, process } from "@progress/kendo-data-query";
import "./index.scss";
import { useHistory } from "react-router-dom";
import * as actionTypes from '../../../store/actions/types';
import SearchFilterWindow from "./SearchFilterWindow";
import {
  isColumnActive,
} from "../../ui/grid/CustomColumnMenu";
import { getSearchPlan } from "../../../store/actions/workOrder";
import { useDispatch, useSelector } from "react-redux";
import LoadMask from "../../../utils/LoadMask";
import { updateUserPreferenceAction } from "../../../store/actions/commonActions";
import { useLocalization } from "@progress/kendo-react-intl";
import { showToast } from "../../../utils/Notification";
import { searchUserPrefrence } from "../../../store/actions/dashboard";
import { useTranslation } from "react-i18next";
import { getLockStatus } from "../../../store/actions/workOrder/actionWorkOrder";
import { capsuleToParams, setOrderIndex } from "../../../utils/Utility";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";
import moment from "moment";
import { MyPager } from "../../generalHooks";
import { toast } from "react-toastify";
const GRID_NAME = "WO_GRID";

export const createDataState = (data, dataState) => {
  return {
    result: process(data.slice(0), dataState),
    dataState: dataState,
  };
};

const initialColumnsState = setOrderIndex(columns);
const WorkOrderComp = ({ setActiveScreen, activeScreen, tabsObj, visible , roleWorkOrderPage,screen }) => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const serverColumns = useSelector(
    (state) => state.commonReducer.gridUserPrefrence
  );
  const { t } = useTranslation();

  const alertLIstPageData = {
    take: 25,
    skip: 0,
  };
  const createDataState = (data, dataState) => {
    return {
      result: {
        data,
        total: dataState.take,
      },
      dataState,
    };
  };
  const history = useHistory();
  const { handleAuthContext } = useLogout()
  const dispatch = useDispatch();
  const [stateColumns, setStateColumns] = useState(initialColumnsState);
  const initialState = createDataState([], alertLIstPageData);
  const [data, setData] = useState(null);
  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [searchTerm, setSearthTerm] = useState("");

  const [filter, setFilter] = useState(null);

  const [showLoader, setShowLoader] = useState(null);
  const [capsulesFilter, setCapsulesFilter] = useState([]);

  const [sort, setSort] = useState([
    {
      field: "JobId",
      dir: "asc",
    },
  ]);

  const localizationService = useLocalization();

  useEffect(() => {
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStateColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);

  const handleDefaultViewClick = () => {
    onColumnsSubmit();
  };
  const handleSaveViewClick = async () => {
    const Json_Object = JSON.stringify(stateColumns);
    const params = {
      UserPrefReq: {
        Email: authContext?.SpocMail,
        Company_ID: authContext?.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: authContext?.CountryCode,
        Json_Object: Json_Object,
      },
    };
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast(
        "success",
        localizationService.toLanguageString(
          "success",
          `${res?.Response?.Message}`
        )
      );
    } else {
      showToast(
        "error",
        localizationService.toLanguageString(
          "dataNotFound",
          `${res?.Response?.Message}`
        )
      );
    }

    setShowLoader(null);
  };

  const [shouldRefresh, setShouldRefresh] = useState(false);
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || initialColumnsState);
  };

  const dataStateChange = (event) => {
    setDataState(event.dataState);
  };

  const fetchSearchPlan = async (params) => {
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });

    const res = await getSearchPlan(params, authContext);

    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Status === "Success" && Array.isArray(res?.SearchPlan)) {
      const result = {
        data: res?.SearchPlan,
        total: res?.rowCount || res?.SearchPlan?.length,
      };
      setData(result);
    } else {
      console.log("search plan api error", res);
      const result = {
        data: [],
        total: 0,
      };
      setData(result);
      showToast(
        "error",
        localizationService.toLanguageString(
          "dataNotFound",
          `${res?.Status}`
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
    setShowLoader(null);
  };

  const onPageChange = (e) => {
    const dataState = e.page;
    setDataState(dataState);
    setFilter(null);
    const newSearchParams = capsuleToParams(capsulesFilter);
    const params = {
      ...newSearchParams,
      CompanyID: authContext?.CompanyID,
      page: Math.floor(dataState.skip / dataState.take) + 1,
      StartCount: dataState.skip,
      MaxCount: dataState.take,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    fetchSearchPlan(params);
  };

  const refreshGrid = () => {
    setShouldRefresh(true);
  };

  const PlanBtnCell = ({ dataItem, field }) => {
    const handlePlanClick = async () => {
      console.log("checkLockStatus");
      const params = {
        CompanyId: authContext?.CompanyID || "",
        JobId: "" + dataItem?.JobId || "",
        User: authContext?.SpocMail,
      };
      setShowLoader({
        className: ".workOrders_grid",
        msg: "loading",
      });
      const res = await getLockStatus(params, authContext);
      setShowLoader(null);
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }
      if (res?.root?.Status === "Unlocked") {
        dispatch({
          type: actionTypes.SET_WORKORDER_DETAIL,
          payload: {
            dataItem,
          },
        });
        history.push("/work_orders/plan", { workOrderDetail: dataItem });
        return;
      }
      showToast("info", res?.root?.Msg);
    };
    return (
      <td style={{ textAlign: "center", padding: 4 }}>
        <Button
          title="Plan"
          style={{
            border: "1px solid #707070",
            backgroundColor: "#666666",
            color: "#fff",
            width: "100%",
          }}
          onClick={handlePlanClick}
          // disabled={roleWorkOrderPage?.screensInfo?.screenId == screen ? ( roleWorkOrderPage?.createFlag == 'N' ? true : false ) : true }
        >
          {dataItem[field]}
        </Button>
      </td>
    );
  };

  useEffect(() => {
    if (data) {
      setResult({
        data: data?.data.map((d) => {
          if (!isNaN(d.JobId)) {
            d.JobId = parseInt(d.JobId);
          }
          if (d.week && !isNaN(d.year)) {
            d.year = parseInt(d.year);
          }
          if (d.week && !isNaN(d.week)) {
            d.week = parseInt(d.week);
          }
          d.CreatedOn = new Date(d.CreatedOn);
          d.UpdatedOn = new Date(d.UpdatedOn);
          d.WeekStartDate = new Date(d.WeekStartDate);
          d.WeekEndDate = new Date(d.WeekEndDate);

          return d;
        }),
        ...data,
      });
    }
  }, [data]);

  const customCellRender = (td, props) => {
    let style = {};
    if (["JobId", "year", "week"].includes(props.field)) {
      style = {
        textAlign: 'right'
      }
    } else {
      style = {
        textAlign: 'left'
      }
    }

    switch (props.field) {
      case "WeekEndDate":
        return (
          <td {...td.props} style={style}>
            {moment(Date.parse(td.props.children)).format("MM-DD-YYYY (ddd)")}
          </td>
        );

      default:
        return (
          <td {...td.props} style={style}>
            {td.props.children}
          </td>
        );
    }
  };

  const CreatePager = useCallback((props) => (
    <MyPager
      {...props}
      onSaveViewClick={handleSaveViewClick}
      onDefaultViewClick={handleDefaultViewClick}
    >
      <div
        className="niq-refresh-button"
        onClick={refreshGrid}
      >
        {/* REFRESH ICON.SVG */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="rgba(0,0,0,.9)"
        >
          <path d="M463.5 224h8.5c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2S461.9 48.1 455 55l-41.6 41.6c-87.6-86.5-228.7-86.2-315.8 1-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2S334.3 224 344 224h119.5z"></path>
        </svg>
      </div>
      <Pager style={{ flex: 1 }} {...props} />
    </MyPager>
  ), [stateColumns]);

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <SearchFilterWindow
        dataState={dataState}
        shouldRefresh={shouldRefresh}
        setShouldRefresh={setShouldRefresh}
        setDataState={setDataState}
        setSearthTerm={setSearthTerm}
        capsulesFilter={capsulesFilter}
        setCapsulesFilter={setCapsulesFilter}
        fetchSearchPlan={fetchSearchPlan}
      />
      <div className="catalog" style={{ padding: 15, flexGrow: 1, minHeight: '60%' }}>
        <Grid
          className="workOrders_grid"
          data={result}
          total={parseInt(result?.total)}
          onDataStateChange={dataStateChange}
          pageable={{
            pageSizes: [10, 25, 50, 100, 250, 500, 1000],
          }}
          {...dataState}
          onPageChange={onPageChange}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            console.log("sort", { e });
            setResult((result) => {
              const { data, total } = result;
              return {
                data: orderBy(data || [], e.sort),
                total,
              };
            });
            setSort(e.sort);
          }}
          filter={filter}
          onFilterChange={(e) => {
            setResult((result) => {
              const { total } = result;
              return {
                data: filterBy(data?.data || [], e.filter),
                total,
              };
            });
            setFilter(e.filter);
          }}
          pager={CreatePager}
          cellRender={customCellRender}
          resizable
          reorderable
          onColumnReorder={({ columns }) => {
            const columnOrder = columns.reduce((columnsObj, column) => {
              columnsObj[column.field] = column.orderIndex;
              return columnsObj;
            }, {})
            const newColumns = stateColumns.map((column) => {
              return { ...column, orderIndex: columnOrder[column.field] }
            });
            setStateColumns(newColumns);
          }}
        >
          <GridColumn
            field="JobAction"
            title={t("Action")}
            cell={PlanBtnCell}
            width={100}
          />
          {stateColumns.map((column, idx) => {
            const menu = columnMenuConfig[column?.columnMenu];
            return (
              column.show && (
                <GridColumn
                  key={idx}
                  field={column.field}
                  title={t(column.title)}
                  format={column?.format}
                  width={column?.width}
                  filter={column?.filter}
                  orderIndex={column?.orderIndex}
                  {...(menu && {
                    columnMenu: (props) => {
                      return menu?.({
                        ...props,
                        columns: stateColumns,
                        onColumnsSubmit,
                        data: data?.data,
                      });
                    },
                  })}
                  headerClassName={
                    isColumnActive(column.field, { ...dataState, filter })
                      ? "activeFiltered"
                      : ""
                  }
                />
              )
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default WorkOrderComp;
