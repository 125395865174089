import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";

const HelpWindow = () => {
  const [isShowWindow, setShowWindow] = useState(false);
  return (
    <>
      {isShowWindow && (
        <Window
          //   className="shadow"
          modal={true}
          title={"Guidelines"}
          onClose={() => setShowWindow(false)}
          initialWidth={780}
          initialHeight={250}
          minimizeButton={() => null}
          maximizeButton={() => null}
          restoreButton={() => null}
        >
          <ul style={{ color: "#000", fontWeight: 500 }}>
            <li>
              Before editing the downloaded sheet, please make sure that format
              for all the cells is "text".
            </li>
            <li>
              Dragging is not allowed here, please copy paste or manually enter
              the values
            </li>
            <li>Grid buttons and the Use-cases:</li>
            <li>
              VALIDATE - The Validate button will validate the data of the
              records and provide validation message
            </li>
            <li>
              SAVE - On Successful validation save button will be enabled to
              save the reassignment
            </li>
            <li>
              Allows the advantage of doing the Auditors assignment offline and
              copy-paste back to the Grid.
            </li>
            <li>By default it will sort by sms id as ascending order.</li>
          </ul>
        </Window>
      )}
      <Button icon="help" primary onClick={() => setShowWindow(true)} />
    </>
  );
};

export default HelpWindow;
