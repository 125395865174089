import React from "react";
import { Upload } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { useSelector } from "react-redux";
import AppURL from "../../../../utils/AppURL";
import { showToast } from "../../../../utils/Notification";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";
import {
  downloadWorkOrderTemplateFile
} from "../../../../store/actions/workOrder/actionWorkOrder";

const UploadWindow = ({ setUploadWindow, selectedDataUpload, setShouldRefresh }) => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const { handleAuthContext } = useLogout();
  const [isFormShow, setFormShow] = useState(true);

  const [apiURL, setApiURL] = useState(AppURL.planUpload);

  const handleBeforeUpload = (event) => {
    event.headers = {
      userid: authContext?.SpocMail,
    };
    event.additionalData.jsonString = "";
  };

  const onStatusChange = (e) => {
    if (e.response) {
      if (checkInvalidSession(e.response?.response)) {
        handleAuthContext();
        return;
      }
      if (!e.response?.response?.success) {
        showToast("error", e.response?.response?.Message);
        return;
      }
      setShouldRefresh(true);
      showToast("success", e.response?.response?.Message);
    }
  };
  const onAdd = (event) => {
    setApiURL(
      AppURL.planUpload +
      `?PlanId=${selectedDataUpload?.WorkOrderId}&FileName=${event?.affectedFiles?.[0]?.name}&CompanyId=${authContext?.CompanyID}&UserId=${authContext.SpocMail}`
    );
  };

  const downloadTemplateHandler = async () => {

    const res = await downloadWorkOrderTemplateFile(null, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    const disposition = res?.headers.get("Content-Disposition")?.split("filename=")
    if (disposition) {
      const exportFileBlob = await res.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl;
      anchor.download = disposition?.[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(exportFileUrl)
    }
  }


  return (
    <Window
      title={"Upload File"}
      onClose={() => setUploadWindow(false)}
      modal={true}

      minimizeButton={() => <Button
        icon="download"
        onClick={() => downloadTemplateHandler()}
        // style={{ marginRight: ".5em" }}
        title="Download Template"
      />}

      maximizeButton={() => (
        <Button
          icon="question"
          onClick={() => setFormShow(!isFormShow)}
        // style={{ marginRight: ".5em" }}
        />
      )}
      restoreButton={() => null}
      // initialTop={70}
      initialHeight={256}
      initialWidth={490}
      style={{ paddingBottom: "1em" }}
    >
      {isFormShow ? (
        <>
          <Form
            enctype="multipart/form-data"
            render={(formRenderProps) => (
              <FormElement>
                <label>File*</label>
                <Upload
                  accept={".xlsx"}
                  autoUpload={false}
                  batch={false}
                  restrictions={{
                    allowedExtensions: [".xlsx"],
                    // maxFileSize: 4000000,
                  }}
                  defaultFiles={[]}
                  // showActionButtons={false}
                  actionsLayout={"stretched"}
                  multiple={false}
                  saveUrl={apiURL}
                  saveField={"BrowseFile"}
                  onBeforeUpload={handleBeforeUpload}
                  onStatusChange={onStatusChange}
                  onAdd={onAdd}
                />

              </FormElement>
            )}
          />
        </>
      ) : (
        <div>
          <Button icon="arrow-left" onClick={() => setFormShow(true)}>
            Back To Upload
          </Button>
          <ul style={{ color: "#000", fontWeight: 500 }}>
            {/* <li>All column fields are comma(,) separated</li> */}
            <li>Resource ID:Mandatory field, shows the Primary resource</li>
            <li>
              Resource Name:Optional field, filling resource id will also auto
              populate resource name and vice versa
            </li>
            <li>
              Secondary Resource ID:Optional field Multiple resources can be
              added
            </li>
            <li>
              Multiple resources should be separated by a "," between the ids
            </li>
            <li>
              <b>Start and End Dates:</b> Mandatory fields, these dates are
              bounded by the Nielsen Start and End Dates. For example, if the
              Nielsen Start Date is 04/28/2019 and the Nielsen End Date is
              05/04/2019, then you are &quot;bound&quot; to work on or between
              those dates when scheduling work for your resource.
            </li>
            <li>
              <b>Visit Date:</b> This will autopopulate with the End Date if not
              filled in. You can choose to add a Visit Date so that the resource
              knows that the store must be visited on a specific date
            </li>
            <li>
              <b>Start and End Time:</b> To be used if the store should be
              audited during a specific time of the day. For example, the shop
              keeper may want to be visited only in the afternoon hours. So you
              would specify the Start and End times accordingly
            </li>
            <li>
              <b>Remarks:</b> These are comments you can enter as instructions
              to your resource. For example, you could say &quot;Please do not
              visit this store from 12pm to 1pm&quot; or other important
              messages you want to pass along for that particular visit.
            </li>
          </ul>
        </div>
      )}
    </Window>
  );
};

export default UploadWindow;
