import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { CustomColumnMenuFilter } from "../../ui/grid/CustomColumnMenu";

export const CellRender = (props) => {
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            const input = td && td.querySelector("input");
            const activeElement = document.activeElement;
            if (
              !input ||
              !activeElement ||
              input === activeElement ||
              !activeElement.contains(input)
            ) {
              return;
            }
            if (input.type === "checkbox") {
              input.focus();
            } else {
              input.select();
            }
          },
        }
      : {
          // onClick: () => {
          //   console.log("enter edit", dataItem);
          //   // props.enterEdit(dataItem, cellField);
          // },
        };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };
  return React.cloneElement(props.td, clonedProps, props.td.props.children);
};

export const RowRender = (props) => {
  const trProps = {
    ...props.tr.props,
    // onBlur: () => {
    //   props.exitEdit();
    // }
  };
  return React.cloneElement(
    props.tr,
    {
      ...trProps,
    },
    props.tr.props.children
  );
};

export const CustomContactCell = (props) => {
  const { dataItem, field } = props;
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  if (dataItem.inEdit) {
    return (
      <Input
        type="text"
        defaultValue={dataValue?.toString()}
        onKeyPress={(event) => {
          if (
            !/[0-9]/.test(event.key) ||
            event.target.value.toString().length >= 13
          ) {
            event.preventDefault();
          }
        }}
        onChange={handleChange}
      />
    );
  }
  return dataValue?.toString();
};

export const CustomNameCell = (props) => {
  // console.log('CustomNameCell',props)
  const { dataItem, field } = props;
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  if (dataItem.inEdit) {
    return (
      <Input
        type="text"
        defaultValue={dataValue?.toString()}
        onKeyPress={(event) => {
          if (!/[a-zA-Z]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={handleChange}
      />
    );
  }
  return <>{dataValue?.toString()}</>;
};

export const columnMenuConfig = {
  CustomColumnMenuFilter,
};

export const columns = [
  {
    title: "firstname",
    field: "FirstName",
    show: true,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 130,
  },
  {
    title: "lastname",
    field: "LastName",
    show: true,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 130,
  },
  {
    title: "contactnumber",
    field: "ContactNo",
    show: true,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
    width: 130,
  },
  {
    title: "COUNTRY",
    field: "Country",
    show: true,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    width: 130,
  },
  {
    title: "Email",
    field: "MailId",
    show: true,
    width: 250,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "createdby",
    field: "CreatedBy",
    show: true,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    width: 150,
  },
  {
    title: "ActivateDeactivate",
    field: "ActiveFlag",
    show: true,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    filter: "boolean",
    width: 250,
    // cell:UploadCell
  },
];
