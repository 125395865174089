import React, { useState } from "react";
import TabHeader from "../TabHeader";
const UserDevice = ({ selectedResourceDetails, ...props }) => {
  return (
    <>
      <TabHeader {...props} />
      <div style={{ overflow: "auto" }}>
        {selectedResourceDetails?.DEVICES?.map((device, i) => {
          return (
            <div key={i}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // border: "1px solid #808080",
                  marginTop: "1.5em",
                  paddingBlock: "1em",
                }}
              >
                Device
              </div>
              <div
                style={{ display: "flex", gap: "4em", paddingInline: "1em" }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>Device Id</div>
                    <div style={{ flex: 1 }}>{device?.DEVICE_ID}</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>Type</div>
                    <div style={{ flex: 1 }}>{device?.DEVICE_TYPE}</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>Status</div>
                    <div style={{ flex: 1 }}>{device?.DEVICE_STATUS}</div>
                  </div>
                </div>
                <div style={{ flex: 2 }}>
                  <div style={{ flex: 1 }}>Comments</div>

                  {device?.DEVICE_COMMENTS}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default UserDevice;
