import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    {
        title: "COUNTRY",
        field: "Country",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "collectiontype",
        field: "CollectionType",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "YEAR",
        field: "Year",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "WEEK",
        field: "Week",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "WORKORDERID",
        field: "WorkOrderID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "AUDIT ID",
        field: "AuditId",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "AUDITOR ID",
        field: "AuditorId",
        show: true,
        // cell:UploadCell
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "auditdate",
        field: "AuditDATE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "date",
        // format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "storenumber",
        field: "Store_Number",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "shotid",
        field: "ShotID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "shotnumber",
        field: "Shot_Number",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:  "shotdate",
        field: "ShotDate",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "date",
        // format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "displaynum",
        field: "DisplayNum",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "SCAN_OR_IMAGE",
        field: "Scan_or_image",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title:"Barcode",
        field: "Barcode",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:"IMAGE_ID",
        field: "ImageID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "IMAGE_START_TIME",
        field: "Image_Start_time",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "imagecapturetime/scannedtime",
        field: "ImageCaptureTime",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "IMAGE_TIME",
        field: "Image_time",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "NewItem",
        field: "NEW_ITEM",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "NewItemStartTime",
        field: "NEW_ITEM_STARTTIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "NewItemEndTime",
        field: "NEW_ITEM_ENDTIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "NewItemTimeSpent",
        field: "NEW_ITEM_TIMESPENT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
   
    
];
export default columns;
