import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postNrpsReportAuditorClick, postNrpsReportKpiClick, postNrpsReportPlanClick, postNrpsReportProgressClick } from '../../../../store/actions/reports';
import TabFilters from './common/TabFilters';
import './NrpsReport.css'

const TabPanel = ({tabFilterResponse, commonFiltersParams, callbackfunc, searchCommFiltData, cb}) => {
  const [selectCommonFilterStoreId, setSelectCommonFilterStoreId] = useState(""); 
  const [selectStoreId, setSelectStoreId] = useState("");  
  const [tabFilterParams, setTabFilterParams] = useState({})
  const [activeIndex, setActiveIndex] = useState(1);
  // const handleClick = (index) => setActiveIndex(index);  
  const checkActive = (index, className) => activeIndex === index ? className : "";
  // console.log("commonFilterstabresponse TABPANEL",tabFilterResponse )
  const dispatch = useDispatch();
  const authContext = useSelector((state) => state.commonReducer.authContext);

  const callback = (payload) => {
    setTabFilterParams(payload)
    // console.log("tabFilterParams", tabFilterParams)
    // debugger;   
  }
  
  const callbackStoreId = React.useCallback((payload) =>  {
    console.log("streidpayload",payload)       
      let temp =  Object.keys(payload).filter((key)=> payload[key]);
      console.log("tempaa",temp,payload)
      if(temp.length>0){
        setSelectStoreId(temp.toString());     

      } 
  },[])
  
  // React.useEffect(()=>{    
  //   setSelectStoreId(selectStoreId)
  // },[selectStoreId]);
  console.log("selectStoreIdeffect",selectStoreId)

  React.useEffect(()=>{
    setSelectCommonFilterStoreId(commonFiltersParams)
  })

  console.log("commonFiltersParamsTabpanel",selectCommonFilterStoreId)

const handleClick = (index) => {      
  console.log("plan1",index)
  setActiveIndex(index);  
  if(index === 1){ 
    callbackfunc(1) 
    if(commonFiltersParams?.year && commonFiltersParams?.week){
      if(authContext){
        const params = {     
          "country": 380,
          "year": commonFiltersParams?.year,
          "week": commonFiltersParams?.week,
          "vendorId": 282,
          "reportType": "plan",
          "status": "",
          "storeId": (selectStoreId)?(selectStoreId):selectCommonFilterStoreId.storeId,
          "storeName": "",
          "storeType": "" ,
          "province": "",
          "collectionType": "",
          "auditorId": "",
          "limitDate": "",
          "city": "",
          "banner": "",
          "pageSize":200,
          "pageNo":0
          // tabFilterParams
        }        
        dispatch(postNrpsReportPlanClick(params,authContext))                    
      }
    }else{
      return
    }        
  }else if(index === 2){
    callbackfunc(2)
    console.log("progress2",index)
    if(commonFiltersParams?.year && commonFiltersParams?.week){
      if(authContext){
        const params = {     
          "country": 380,
          "year": commonFiltersParams?.year,
          "week": commonFiltersParams?.week,
          "vendorId": 282,
          "reportType": "progress",
          "status": "",
          "storeId": (selectStoreId)?(selectStoreId):selectCommonFilterStoreId.storeId,
          "storeName": "",
          "storeType": "" ,
          "province": "",
          "collectionType": "",
          "auditorId": "",
          "limitDate": "",
          "city": "",
          "banner": "",
          "pageSize":200,
          "pageNo":0
          // tabFilterParams
        }        
        dispatch(postNrpsReportProgressClick(params,authContext))                    
      } 
    }else{
      return
    }          
  }else if(index === 3){
    callbackfunc(3)
    console.log("kpi3",index)
    if(commonFiltersParams?.year && commonFiltersParams?.week){
      if(authContext){
        const params = {     
          "country": 380,
          "year": commonFiltersParams?.year,
          "week": commonFiltersParams?.week,
          "vendorId": 282,
          "reportType": "kpi",
          "status": "",
          "storeId":  (selectStoreId)?(selectStoreId):selectCommonFilterStoreId.storeId,
          "storeName": "",
          "storeType": "",
          "province": "",
          "collectionType": "",
          "auditorId": "",
          "limitDate": "",
          "city": "",
          "banner": "",
          "pageSize":200,
          "pageNo":0
          // tabFilterParams
        }         
        dispatch(postNrpsReportKpiClick(params,authContext))                    
      } 
    }else{
      return
    }        
  }else if(index === 4){
    callbackfunc(4)
    console.log("auditor4",index)
    if(commonFiltersParams?.year && commonFiltersParams?.week){
      if(authContext){
        const params = {     
          "country": 380,
          "year": commonFiltersParams?.year,
          "week": commonFiltersParams?.week,
          "vendorId": 282,
          "reportType": "auditor",
          "status": "",
          "storeId": (selectStoreId)?(selectStoreId):selectCommonFilterStoreId.storeId,
          "storeName": "",
          "storeType": "" ,
          "province": "",
          "collectionType": "",
          "auditorId": "",
          "limitDate": "",
          "city": "",
          "banner": "",
          "pageSize":200,
          "pageNo":0
          // tabFilterParams
        }        
        dispatch(postNrpsReportAuditorClick(params,authContext))                    
      }
    }else{
      return
    }           
  }
} 


  return (
    <React.Fragment>
      <div className="tabs">
        <button
          className={`tab ${checkActive(1, "active")}`}
          onClick={() => handleClick(1)}
        >
          Plan
        </button>
        <button
          className={`tab ${checkActive(2, "active")}`}
          onClick={() => handleClick(2)}
        >
          Progress
        </button>
        <button
          className={`tab ${checkActive(3, "active")}`}
          onClick={() => handleClick(3)}
        >
          KPI
        </button>
        <button
          className={`tab ${checkActive(4, "active")}`}
          onClick={() => handleClick(4)}
        >
          Auditor
        </button>
      </div>
      <div className="panels" >
        <div className={`panel ${checkActive(1, "active")}`}>          
            <TabFilters title="PLAN" handleClick={handleClick} tabFilterResponse={tabFilterResponse} commonFiltersParams={commonFiltersParams} callback={callback} searchCommFiltData={searchCommFiltData} cb={cb} callbackStoreId={callbackStoreId}/>              
        </div>
        <div className={`panel ${checkActive(2, "active")}`}>
          <TabFilters title="PROGRESS" handleClick={handleClick} tabFilterResponse={tabFilterResponse} commonFiltersParams={commonFiltersParams} callback={callback} searchCommFiltData={searchCommFiltData} cb={cb} callbackStoreId={callbackStoreId}/>
        </div>
        <div className={`panel ${checkActive(3, "active")}`}>
          <TabFilters title="KPI"  handleClick={handleClick} tabFilterResponse={tabFilterResponse} commonFiltersParams={commonFiltersParams} callback={callback} searchCommFiltData={searchCommFiltData} cb={cb} callbackStoreId={callbackStoreId}/>
        </div>
        <div className={`panel ${checkActive(4, "active")}`}>
          <TabFilters title="AUDITOR" handleClick={handleClick} tabFilterResponse={tabFilterResponse} commonFiltersParams={commonFiltersParams} callback={callback} searchCommFiltData={searchCommFiltData} cb={cb} callbackStoreId={callbackStoreId}/>
        </div>
      </div>
      
    </React.Fragment>
  )
}

export default TabPanel
