import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
};

const columns = [
    {
        title: "PERIOD_GROUP",
        field: "period_group",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "WORKORDER_ID",
        field: "plan_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "ACTIVITY_LOCAL_NM",
        field: "activity_local_nm",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "RMS_WORK_ORDER_ID",
        field: "rms_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "RMS_WORK_ORDER_NM",
        field: "workorder_nm",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "RMS_ID",
        field: "rms_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "AUDITOR_ID",
        field: "auditor_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "STORE_ID",
        field: "store_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 160
    },
    {
        title: "STORE_NM",
        field: "store_nm",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "SMS_ID",
        field: "sms_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "STORE_TYPE",
        field: "store_type",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "AI_GENERATION_DATE",
        field: "ai_generation_date",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "date",
        width: 160
    },
    {
        title: "PREVIOUS_AUDIT_DATE",
        field: "previous_audit_date",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "date",
        width: 160
    },
    {
        title: "PREVIOUS_AUDIT_STATUS",
        field: "previous_audit_status",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "PLANNED_AUDIT_DATE",
        field: "planned_audit_date",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "LHHT_DOWNLOAD_STATUS",
        field: "lhht_download_status",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "VISIT_DATE",
        field: "visit_date",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "STORE_STATUS",
        field: "store_status",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "LHHT_UPLOAD_STATUS",
        field: "lhht_upload_status",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "UPLOAD_DATE_UTC",
        field: "upload_date_utc",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "TOTAL_ITEMS",
        field: "total_items",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 160
    },
    {
        title: "EXISTING_CODED_ITEMS_COLLECTED",
        field: "existing_coded_items_collected",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
    {
        title: "SENT_TO_FACTORY",
        field: "sent_to_factory",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 160
    },
];
export default columns;
