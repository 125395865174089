import * as types from "../../actions/types";

const initialState = {
    fetchUploadTypesResponse: null,
    fetchUploadStatusResponse: null,
    fetchUploadAttributesResponse: null,
    downloadErrorResponse: null,
    fetchErrorRecords: null,
    downloadTemplateResponse: null
};

const BulkUploadReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case types.GET_MODULEWISE_UPLOADTYPE:
            newState = {
                ...state,
                fetchUploadTypesResponse: action.payload
            }
            break;
        case types.GET_UPLOAD_STATUS:
            newState = {
                ...state,
                fetchUploadStatusResponse: action.payload
            }
            break;
        case types.GET_UPLOAD_ATTRIBUTES:
            newState = {
                ...state,
                fetchUploadAttributesResponse: action.payload
            }
            break;
        case types.GET_ERROR_RECORDS:
            newState = {
                ...state,
                fetchErrorRecords: action.payload
            }
            break;
        case types.DOWNLOAD_ERRORS_STATUS:
            newState = {
                ...state,
                downloadErrorResponse: action.payload
            }
            break;
        case types.DOWNLOAD_TEMPLATE_STATUS:
            newState = {
                ...state,
                downloadTemplateResponse: action.payload
            }
            break;
        default:
            newState = state;
            break;
    }

    return newState;
};

export default BulkUploadReducer;