import React, { forwardRef, Ref } from "react";

import { MultiSelectProvider } from "./dropdown/hooks";
import { ISelectProps } from "./interfaces";
import Dropdown from "./dropdown";

const MultiSelectCombo = (props: ISelectProps, ref: Ref<any>) => (
      <MultiSelectProvider props={props}>
            <Dropdown />
      </MultiSelectProvider>
);

export default forwardRef(MultiSelectCombo);
