import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { Pager } from "@progress/kendo-react-data-tools";
import { getter } from "@progress/kendo-react-common";
import { orderBy } from "@progress/kendo-react-data-tools";
import CustomMailCell from "./customMailCell";
import "./index.css";
import LoadMask from "../../../utils/LoadMask";
import { useTranslation } from "react-i18next";
import {
  getMailsList,
  getMailBodyContent,
  getNewCountMessages,
} from "../../../store/actions/messages";
import { CustomColumnMenu } from "../../ui/grid/CustomColumnMenu";
import {
  columns,
  MAIL_STATUS_NEW,
  MAIL_STATUS_READ,
  NotificationsLoadingMask,
} from "../notificationsUtils";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";

const DATA_ITEM_KEY = "UniqueId";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const CustomPager = (props) => {

  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [showLoader, setShowLoader] = useState(null);

  const handleRefresh = () => {
    if (props?.refreshGrid) {
      props.refreshGrid();
    }
  };

  return (
    <>
      {showLoader && <NotificationsLoadingMask {...showLoader} />}
      <div style={{ display: "flex" }} className="k-grid-pager">
        <div
          className="niq-refresh-button"
          onClick={handleRefresh}
        >
          {/* REFRESH ICON.SVG */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="rgba(0,0,0,.9)"
          >
            <path d="M463.5 224h8.5c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2S461.9 48.1 455 55l-41.6 41.6c-87.6-86.5-228.7-86.2-315.8 1-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2S334.3 224 344 224h119.5z"></path>
          </svg>
        </div>
        <Pager style={{ flex: 1 }} {...props} />
      </div>
    </>
  );
};

const MessageList = ({
  activeScreen,
  setMailDetail,
  selectedState,
  setSelectedState,
  shouldRefresh,
  setShouldRefresh,
  updateMailFlag,
}) => {
  const { t, i18n } = useTranslation();
  const initialSort = [
    {
      field: "SentDate",
      dir: "desc",
    },
  ];
  const createDataState = (data, dataState) => {
    return {
      result: {
        data,
        total: dataState.take,
      },
      dataState,
    };
  };
  const initialDataState = {
    skip: 0,
    take: 25,
  };
  const dispatch = useDispatch();
  const { handleAuthContext } = useLogout();
  const [stateColumns, setStateColumns] = useState(columns);
  const [sort, setSort] = useState(initialSort);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const initialState = createDataState([], initialDataState);
  const [result, setResult] = useState(initialState.result);
  const [showLoader, setShowLoader] = useState(null);
  const [showPageLoader, setShowPageLoader] = useState(null);
  const [dataState, setDataState] = useState(initialState.dataState);

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || columns);
  };

  const refreshGrid = () => {
    setShouldRefresh(true);
  };

  const ColumnMenuFilter = (props) => (
    <CustomColumnMenu
      {...props}
      columns={stateColumns}
      onColumnsSubmit={onColumnsSubmit}
    />
  );

  useEffect(() => {
    setShouldRefresh(true);
  }, [activeScreen]);

  useEffect(() => {
    const getMailList = async () => {
      const params = {
        CompanyId: authContext.CompanyID,
        page: 1,
        StartCount: 0,
        MaxCount: dataState.take,
        CountryId: authContext.CountryCode,
        // CountryId: "",
      };
      if (activeScreen === "trash") params.Flag = "DELETED";


      setShowLoader({
        className: ".workOrders_grid",
        msg: "loading",
      });
      const res = await getMailsList(params, authContext);
      dispatch(getNewCountMessages(res))
      const newResult = {
        data: res?.GetMails?.Mails || [],
        total:
          res?.GetMails?.TotalCount ||
          res?.GetMails?.Mails.length ||
          dataState.take,
      };
      setResult(newResult);
      setShowLoader(null);
      setShouldRefresh(false);
    };
    if (shouldRefresh) getMailList();
  }, [shouldRefresh]);

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  useEffect(() => {
    let uniqueId = Object.keys(selectedState).find(
      (key) => selectedState[key] === true
    );
    if (Object.keys(selectedState).length == 1) {
      const getMailBody = async () => {
        if (uniqueId) {
          const params = {
            CompanyId: authContext.CompanyID,
            CountryId: authContext.CountryCode,
            // CountryId: "",
            UniqueId: uniqueId,
          };
          if (activeScreen === "trash") params.Flag = "DELETED";

          setShowPageLoader({
            msg: "loading",
          });
          const res = await getMailBodyContent(params, authContext);
          if (res?.root?.Status == "Success" && res?.root?.Body) {
            setMailDetail(res?.root?.Body);
            if (
              result?.data.find((item) => item.UniqueId == uniqueId).ReadFlag ==
              MAIL_STATUS_NEW
            )
              updateMailFlag([uniqueId], MAIL_STATUS_READ);
          } else {
            console.log("search plan api error", res);
          }
          setShowPageLoader(null);
        }
      };
      getMailBody();
    }
  }, [selectedState]);

  const dataStateChange = (event) => {
    setDataState(event.dataState);
  };

  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const pageChange = (event) => {
    console.log("rewwwEvent", { event })
    const dataState = event.page;
    setDataState(dataState);
    const getMailList = async () => {
      const params = {
        CompanyId: authContext.CompanyID,
        page: Math.floor(dataState.skip / dataState.take) + 1,
        StartCount: dataState.skip,
        MaxCount: dataState.take,
        CountryId: authContext.CountryCode,
        // CountryId: "",
      };
      if (activeScreen === "trash") params.Flag = "DELETED";
      setShowLoader({
        className: ".workOrders_grid",
        msg: "loading",
      });
      const res = await getMailsList(params, authContext);
      console.log("rewww", res)
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }
      if (
        res?.GetMails?.Mails != undefined &&
        res?.GetMails?.Mails.length > 0 &&
        Array.isArray(res?.GetMails?.Mails)
      ) {
        const newResult = {
          data: res?.GetMails?.Mails || [],
          total:
            res?.GetMails?.TotalCount ||
            res?.GetMails?.Mails.length ||
            dataState.take,
        };
        setResult(newResult);
      } else {
        console.log("search plan api error", res);
      }
      setShowLoader(null);
    };
    getMailList();
  };

  return (
    <>
      {showPageLoader && <LoadMask {...showPageLoader} />}
      {showLoader && <NotificationsLoadingMask {...showLoader} />}
      <div className="messageList" style={{ margin: "10px", height: "80vh" }}>
        <Grid
          className="inbox-grid"
          style={{ height: "100%", overflowY: "auto" }}
          resizable={true}
          data={result?.data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          total={Number(result.total) ?? 0}
          pageable={{
            buttonCount: 3,
            info: true,
            pageSizes: [10, 20, 25, 50, 100, 200, 500, 1000],
            previousNext: true,
          }}
          {...dataState}
          onDataStateChange={dataStateChange}
          onPageChange={pageChange}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setResult((result) => {
              const { data, total } = result;
              return {
                data: orderBy(data || [], e.sort),
                total,
              };
            });
            setSort(e.sort);
          }}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          reorderable
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          pager={(props) => (
            <CustomPager {...props} refreshGrid={refreshGrid} />
          )}
        >
          <GridColumn
            field={SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              result?.data?.findIndex(
                (item) => !selectedState[idGetter(item)]
              ) === -1
            }
          />

          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <GridColumn
                  key={idx}
                  field={column.field}
                  title={t(column.title)}
                  cell={CustomMailCell}
                  columnMenu={ColumnMenuFilter}
                />
              )
          )}
        </Grid>
      </div>
    </>
  );
};

export default MessageList;
