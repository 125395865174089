import AppURL from "../../../../utils/AppURL";
import { Get, Post, PostwithTimeoutHandler } from "../../../methods";
const getHeaders = (authContext)=>{
  return {
    userid: authContext?.SpocMail || "",
    countryid: authContext?.CountryCode || "",
    companyid: authContext?.CompanyID || "",
    "content-type": "application/json",
    // "content-type": "application/x-www-form-urlencoded; application/json",
  }; 
}
export const getNICItemReport = async(params, authContext) => {
      // const result = await Get(AppURL.getNICItemReport, params, getHeaders(authContext),true).then((res) =>
      const result = await PostwithTimeoutHandler(AppURL.reportsAPI, params, getHeaders(authContext),true,10000).then((res) =>
      res.json()
    );
    return result;
}

export const exportNICItemDetail = async (params, authContext) => {
    // const exportFile = await Get(AppURL.exportNICItemDetail,params,getHeaders(authContext),true);    
    const exportFile = await Post(AppURL.exportNICItemDetail,params,getHeaders(authContext),true);    
      const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);    
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl; 
      anchor.download = disposition?.[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor); 
  };