import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    {
        title: "COUNTRY",
        field: "COUNTRYID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:  "AUDITOR ID",
        field: "AUDITORID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "DeviceType",
        field: "DEVICE_TYPE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 150
    },
    {
        title: "DEVICE MODEL",
        field: "DEVICE_MODEL",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 180
    },
    {
        title: "OSVersion",
        field: "OS_VERSION",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 150
    },
    {
        title: "ApplicationVersion",
        field: "APPVERSION",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 200
        // cell:UploadCell
    },
    {
        title: "UserTimeStamp",
        field: "TIME_STAMP",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 180
    },
    {
        title: "UserTimeZone",
        field: "TIMEZONE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 150
    },
   
    
];
export default columns;
