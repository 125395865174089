import { Get, GetWithoutParams, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";


const getHeaders = (authContext) => ({
  "Content-Type": "application/json",
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid: authContext?.CompanyID || "",
  countryid: authContext?.CountryCode.split(",") || "",
});
const getHeadersPost = (authContext) => ({
  "Content-Type": "application/json",
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid: authContext?.CompanyID || "",
  countryid: authContext?.CountryCode.split(",") || "",
});
const getNrpsHeaders = (authContext) => ({
  "Content-Type": "application/json",
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid: authContext?.CompanyID || "",
  countryid: authContext?.CountryCode.split(",") || "",
});

export const getStorePlanList = async (params, authContext) => {
  const res = await Get(
    AppURL.fetchPlanUrl,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};
export const getRecollect = async (authContext,params) => {
  const res = await Get(
    AppURL.recollect,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};

export const getLockStatus = async (params, authContext) => {
  const res = await Get(
    AppURL.checkLockStatus,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};

export const postLockStatus = async (params, authContext) => {
  const res = await Get(
    AppURL.lockuser,
    params,
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());
  return res;
};

export const saveStorePlan = async (params, authContext, urlQueryParams) => {
  const keys = Object.keys(urlQueryParams);
  let url = AppURL.saveStorePlan + "?";
  keys.forEach((key) => {
    url = url + `${key}=${urlQueryParams[key]}&`;
  });
  url = url.substring(0, url.length - 1);
  const res = await Post(url, params, getHeadersPost(authContext), true).then(
    (res) => res.json()
  );
  return res;
};
export const recollectPlan = async (params, authContext, urlQueryParams) => {
  const keys = Object.keys(urlQueryParams);
  let url = AppURL.recollect + "?";
  keys.forEach((key) => {
    url = url + `${key}=${urlQueryParams[key]}&`;
  });
  url = url.substring(0, url.length - 1);
  const res = await Post(url, params, getHeadersPost(authContext), true).then(
    (res) => res.json()
  );
  return res;
};

// export const nrpsSubmitPlan = async (params, authContext, urlQueryParams) => {
//   const keys = Object.keys(urlQueryParams);
//   let url = AppURL.nrpsSubmitPlan + "?";
//   keys.forEach((key) => {
//     url = url + `${key}=${urlQueryParams[key]}&`;
//   });
//   url = url.substring(0, url.length - 1);
//   const res = await Post(url, params, getNrpsHeaders(authContext), true).then(
//     (res) => res.json()
//   );
//   return res;
// };

export const nrpsSubmitPlan = async (params, authContext, urlQueryParams) => {
  // const keys = Object.keys(urlQueryParams);
  // let url = AppURL.nrpsSubmitPlan + "?";
  // keys.forEach((key) => {
  //   url = url + `${key}=${urlQueryParams[key]}&`;
  // });
  // url = url.substring(0, url.length - 1);
  // const res = await Post(url, params, getNrpsHeaders(authContext), true).then(
    const res = await Post(AppURL.nrpsSubmitPlan, params, getNrpsHeaders(authContext), true).then(
    (res) => res.json()
  );
  return res;
};

export const downloadPlan = async (params, authContext) => {
  await Get(AppURL.downloadPlan, params, getHeaders(authContext), true).then(
    (response) =>
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `PLAN_${params.CollectionType}_${params.Jobid}.xlsx`;
        a.click();
      })
  );
};


export const downloadWorkOrderTemplateFile = async (params, authContext) => {
  const headers = {
    //   userid: authContext?.SpocMail || "",
    //   "Content-Type": "application/x-www-form-urlencoded; application/json",
    //   countryid:authContext?.CountryCode,
  };
  const res = await Get(AppURL.downloadWOTemplate, params, headers, true);
  console.log("downloadGuidelines", { res });
  return res;
};

