
import React, { useState, useEffect, useCallback } from 'react';
import columns, { columnMenuConfig } from "./common/columnMiscellaneous";
import './MiscellaneousReport.css';
import { getApplicationData, getApplicationName, getMobileSummaryReport, getMobileVersionExportData } from './common/mobileversion';
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Grid, GridCell, GridColumn } from '@progress/kendo-react-grid';
import { FormComboBox, FormInput } from '../../../form/form-component';
import { inputValidator } from '../../../form/validator';
import { useDispatch, useSelector } from 'react-redux';
import { Pager } from '@progress/kendo-react-data-tools';
import { showToast } from "../../../../utils/Notification";
import { updateUserPreferenceAction } from "../../../../store/actions/commonActions";
import { Button } from '@progress/kendo-react-buttons';
import LoadMask from '../../../../utils/LoadMask';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { isColumnActive } from '../../../grid/CustomColumnMenu';
import { searchUserPrefrence } from '../../../../store/actions/dashboard';
import useLogout, { checkInvalidSession } from '../../../../utils/useLogout';
import { MyPager } from '../../../generalHooks';
import Header from '../../../header';
import moment from 'moment';
import AppURL from '../../../../utils/AppURL';
import regionCountry from '../../../../utils/regionCountry';
import { getExportDataWithPostTimeoutHandler } from '../../eCollection/summaryReport/common/getSummaryReport';

const GRID_NAME = "MISC_FMD_GRID"

const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            total: 0,
        },
        dataState: dataState,
    };
};

let initialState = createDataState([], {
    take: 20,
    skip: 0,
});

const setOrderIndex = (columns) => {
    return columns.map((column, i) => ({ ...column, orderIndex: i }))
}

const initialColumnsState = setOrderIndex(columns);

function MiscellaneousReport() {
    const { handleAuthContext } = useLogout();
    const authContext = useSelector((state) => state.commonReducer.authContext);

    const { t } = useTranslation();
    const [data, setData] = useState({ data: [], total: 0 });

    const locationData = useSelector((state) => state.commonReducer.locationData);

    const [stateColumns, setStateColumns] = useState(initialColumnsState);

    const [selectedData, setSelectedData] = useState({});

    const [showForm, setShowForm] = useState(true)

    const [showLoader, setShowLoader] = useState(null);
    const [appNameArr, setAppNameArr] = useState({});
    const [applicationDeviceData, setApplicationDeviceData] = useState({
        applicationNameData: [],
        deviceTypeData: []
    });
    const [result, setResult] = useState(initialState.result);
    const [dataState, setDataState] = useState(initialState.dataState);
    const [countryData, setCountryData] = useState([]);

    const [expandIcon, setExpandIcon] = useState("fullscreen");
    const history = useHistory();
    const dispatch = useDispatch();

    const serverColumns = useSelector(
        (state) => state.commonReducer.gridUserPrefrence
    );


    const onChange = (e) => {
        if (e.target?.name === "authorId") {
            setSelectedData({
                ...selectedData, [e.target?.name]: e.value
            })
        } else {
            setSelectedData({
                ...selectedData, [e.target.component.name]: e.value
            })
        }
    }
    const [filter, setFilter] = useState(null);
    const [sort, setSort] = useState([
        {
            field: "BatchId",
            dir: "asc",
        },
    ]);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState || initialColumnsState);
    };

    const customCellRender = (td, props) => {
        const { dataItem, field } = props;
        let dataValue = dataItem[field];
        if (field === 'TIME_STAMP') {
            dataValue = moment(dataValue).format("DD-MMM-YY HH:mm A")
        }
        return <td style={{ textAlign: "center" }}>{dataValue?.toString()}</td>
    };

    useEffect(() => {
        if (serverColumns?.length > 0) {
            const currentDataColumns = serverColumns.find(
                (c) => c?.Grid_Name === GRID_NAME
            );
            try {
                const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
                if (currentStateColumns.every((column) => column.field)) {
                    setStateColumns(currentStateColumns);
                }
            } catch (e) {
                console.log("error in state columns", currentDataColumns?.Json_object);
                return;
            }
        }
    }, [serverColumns]);

    useEffect(() => {
        setSelectedData({
            ...selectedData, regionID: locationData?.Region?.RegionID,
            countryID: locationData?.Country?.[0]?.CountryID,
        })
    }, [locationData?.Country])

    //On Search
    const onSearchClick = async (e) => {
        if (!e.isValid) {
            return
        }
        fetchMobileSummaryReport()
    }

    const handlePageChange = async (e) => {
        const dataState = e.page;
        setDataState(dataState);
        fetchMobileSummaryReport({
            pageNo: Math.floor(dataState.skip / dataState.take) + 1,
        });
    };

    const fetchApplicationName = async () => {
        let params = {
            "selectCondition": "DISTINCT",
            "output": "app_id,application_name"
        }

        const params2 = {
            "CollectionType": "MISCELLANEOUS",
            "ReportName": "MOBILE_VERSION_REPORT",
            "APIName": "applicationname",
            "Payload": {
                "selectCondition": "DISTINCT",
                "output": "app_id,application_name"
            }
        }


        const res = await getApplicationName(JSON.stringify(params2), authContext, AppURL.reportsAPI);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        const appNameArray = {};
        if (res?.resultSet) {
            res?.resultSet?.forEach((resObj, i) => {
                appNameArray[resObj?.Column?.[0]?.Value || i] = resObj?.Column?.[1]?.Value || '';
            })
        }
        setAppNameArr(appNameArray);
    }

    const fetchApplicationData = async () => {
        const countryObj = locationData?.Country?.find((country) => country.CountryID === selectedData?.countryID);

        const params2 = {
            "CollectionType": "MISCELLANEOUS",
            "ReportName": "MOBILE_VERSION_REPORT",
            "APIName": "filter",
            "Payload": {
                "region": locationData?.Region?.RegionName,
                "countryName": countryObj?.CountryName,
                "output": "device_type,app_id,auditorid"
            }
        }

        let params = {
            "region": locationData?.Region?.RegionName,
            "countryName": countryObj?.CountryName,
            "output": "device_type,app_id,auditorid"
        }

        setShowLoader({
            msg: "loading",
        });

        const res = await getApplicationData(JSON.stringify(params2), authContext, AppURL.reportsAPI);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        const appName = [];
        const appNameArrayObj = [];
        const deviceType = [];
        if (res?.resultSet) {
            res?.resultSet?.forEach((resObj) => {
                resObj?.Column?.forEach((colObj) => {
                    if (colObj.Name === 'APP_ID' && !appName.includes(colObj.Value) && appNameArr?.[colObj?.Value]) {
                        colObj?.Value && appName.push(colObj?.Value) && appNameArrayObj.push({ "id": colObj?.Value, "value": appNameArr[colObj?.Value] });
                    } else if (colObj.Name === 'DEVICE_TYPE' && !deviceType.includes(colObj.Value)) {
                        colObj?.Value && deviceType.push(colObj?.Value);
                    }
                })
            })
        }
        setApplicationDeviceData({
            applicationNameData: appNameArrayObj,
            deviceTypeData: deviceType
        });
        setShowLoader(null);
    }

    const fetchMobileSummaryReport = async (newParams = {}) => {
        const filterParams = [
            {
                "filterCondition": "EQUALS",
                "filterSubCondition": "ASC",
                "filterName": "device_type",
                "filterValue": selectedData.deviceType
            },
            {
                "filterCondition": "EQUALS",
                "filterSubCondition": "ASC",
                "filterName": "app_id",
                "filterValue": selectedData.appID
            },
            {
                "filterCondition": "EQUALS",
                "filterSubCondition": "ASC",
                "filterName": "countryid",
                "filterValue": selectedData?.countryID
            },
        ];
        if (selectedData?.authorId) {
            filterParams.push({
                "filterCondition": "EQUALS",
                "filterSubCondition": "ASC",
                "filterName": "auditorid",
                "filterValue": selectedData?.authorId
            })
        }
        // const params = {
        //     "filters": filterParams,
        //     "pageNo": 1,
        //     "totalCount": 20,
        //     "selectCondition": "DISTINCT",
        //     "output": "countryid,auditorid,device_type,device_model,os_version,appversion,time_stamp,timezone",
        //     ...newParams
        // }

        const params2 = {
            "CollectionType": "MISCELLANEOUS",
            "ReportName": "MOBILE_VERSION_REPORT",
            "APIName": "pagination",
            "Payload": {
                "filters": filterParams,
                "pageNo": 1,
                "totalCount": 20,
                "selectCondition": "DISTINCT",
                "output": "countryid,auditorid,device_type,device_model,os_version,appversion,time_stamp,timezone",
                // "page": 1,
                // "startCount": 0,
                // "MaxCount": 50,
                ...newParams,
            }
        }
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });

        const res = await getMobileSummaryReport(JSON.stringify(params2), authContext);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        const formattedResult = [];
        if (res?.resultSet?.length) {
            res?.resultSet?.forEach((resObj) => {
                let formattedObj = {};
                resObj?.Column?.forEach((colObj) => {
                    if (colObj?.Name === 'COUNTRYID') {
                        formattedObj[colObj?.Name] = countryData?.[colObj?.Value]
                    } else {
                        formattedObj[colObj?.Name] = colObj?.Value
                    }
                });
                Object.keys(formattedObj).length > 0 && formattedResult.push(formattedObj);
            })
        }
        setData({ data: formattedResult, total: res?.totalRecords || 0 });
        setShowLoader(null);
    }

    const handleDefaultViewClick = () => {
        onColumnsSubmit();
    };

    const handleSaveViewClick = async () => {
        const params = {
            UserPrefReq: {
                Email: authContext.SpocMail,
                Company_ID: authContext.CompanyID,
                Grid_Name: GRID_NAME,
                Country_ID: selectedData.countryID,
                Json_Object: JSON.stringify(stateColumns),
            },
        };
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await updateUserPreferenceAction(
            JSON.stringify(params),
            authContext
        );
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.Response?.Status === "Success") {
            dispatch(
                searchUserPrefrence(
                    JSON.stringify({
                        UserPrefReq: {
                            Email: authContext?.SpocMail,
                            Company_ID: authContext?.CompanyID,
                        },
                    })
                )
            );
            showToast(
                "success",
                t(res?.Response?.Message || "Success")
            );
        } else {
            showToast(
                "error",
                t(res?.Response?.Message || "dataNotFound")
            );
        }
        setShowLoader(null);
    };

    const onExport = async () => {
        if (selectedData.appID && selectedData.deviceType) {
            const filterParams = [
                {
                    "filterCondition": "EQUALS",
                    "filterSubCondition": "ASC",
                    "filterName": "device_type",
                    "filterValue": selectedData.deviceType
                },
                {
                    "filterCondition": "EQUALS",
                    "filterSubCondition": "ASC",
                    "filterName": "app_id",
                    "filterValue": selectedData.appID
                },
                {
                    "filterCondition": "EQUALS",
                    "filterSubCondition": "ASC",
                    "filterName": "countryid",
                    "filterValue": selectedData?.countryID
                },
            ];
            if (selectedData?.authorId) {
                filterParams.push({
                    "filterCondition": "EQUALS",
                    "filterSubCondition": "ASC",
                    "filterName": "auditorid",
                    "filterValue": selectedData?.authorId
                })
            }
            const params = {
                "filters": filterParams,
                "selectCondition": "DISTINCT",
                "output": "countryid,auditorid,device_type,device_model,os_version,appversion,time_stamp,timezone"
            }

            let params2 = {
                "CollectionType": "MISCELLANEOUS",
                "ReportName": "MOBILE_VERSION_REPORT",
                "APIName": "csv",
                "Payload": {
                    "filters": filterParams,
                    "selectCondition": "DISTINCT",
                    "output": "countryid,auditorid,device_type,device_model,os_version,appversion,time_stamp,timezone"
                }
            }

            setShowLoader({
                msg: "loading",
            });

            showToast(
                "info",
                t('filedownload_msglbl')
            );
            // const res = await getMobileVersionExportData(params, authContext)

            const res = await getExportDataWithPostTimeoutHandler(params2, authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }
            setShowLoader(null);
        } else {
            showToast(
                "error",
                t("pleasefillthemandatoryfields"),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
    }

    const expand = () => {
        setShowForm(!showForm);
        setExpandIcon(!showForm ? "fullscreen" : "fullscreen-exit")
    }

    const backToReports = () => {
        history.push("/reports");
    }

    useEffect(() => {
        if (data) {
            setResult({
                data: data?.data.map((d) => {

                    if (!isNaN(d?.AUDITORID)) {
                        d.AUDITORID = parseInt(d.AUDITORID);
                    }
                    if (!isNaN(d?.COUNTRYID)) {
                        d.COUNTRYID = parseInt(d.COUNTRYID);
                    }

                    return d
                }), ...data
            })
        }
    }, [data]);

    useEffect(() => {
        fetchApplicationName();
        let tempCountryList = [];
        regionCountry?.forEach(({ CountryDetails }) => {
            CountryDetails.forEach(({ CountryID, CountryName }) => {
                tempCountryList[CountryID] = CountryName;
            })
        });
        setCountryData(tempCountryList);
    }, []);

    useEffect(() => {
        if (Object.keys(appNameArr).length) {
            fetchApplicationData();
        }
    }, [selectedData.countryID, appNameArr])

    const CreatePager = useCallback((props) => (
        <MyPager
            {...props}
            onSaveViewClick={handleSaveViewClick}
            onDefaultViewClick={handleDefaultViewClick}
        >
            <Pager style={{ flex: 1 }} {...props} />
        </MyPager>
    ), [stateColumns, selectedData.countryID]);

    return (
        <>
            {showLoader && <LoadMask {...showLoader} />}

            <div className="misllaneousReport">
                <Header activeMenu={t("nielsennavigator-MobileSummaryReport")}>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginInline: "auto 1em",
                            padding: '6px'
                        }}
                    >
                        <Button
                            className='k-secondary'
                            onClick={backToReports}
                        >
                            Back
                        </Button>
                    </div>
                </Header>
                {showForm ?
                    <div className="misllaneousReportform">
                        <Form
                            initialValues={{
                                regionID: locationData?.Region?.RegionID,
                                countryID: locationData?.Country?.[0]?.CountryID,
                            }}
                            onSubmitClick={onSearchClick}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "0px 25px",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <Field
                                            name={"regionID"}
                                            component={FormComboBox}
                                            label={t("REGION")}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="RegionName"
                                            valueField="RegionID"
                                            disabled
                                            data={[locationData?.Region]}
                                            defaultValue={locationData?.Region?.RegionID}
                                        />
                                        <Field
                                            name={"countryID"}
                                            component={FormComboBox}
                                            label={t("COUNTRY")}
                                            required={true}
                                            validator={inputValidator}
                                            onChange={onChange}
                                            textField="CountryName"
                                            valueField="CountryID"
                                            data={locationData?.Country || []}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            defaultValue={locationData?.Country?.[0]?.CountryID}
                                        />
                                        <Field
                                            name="appID"
                                            label={t("Application Name")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("Application Name")}
                                            textField="value"
                                            valueField="id"
                                            data={applicationDeviceData.applicationNameData}
                                            required={true}
                                            validator={inputValidator}
                                            onChange={onChange}
                                        />
                                        <Field
                                            name="deviceType"
                                            label={t("DeviceType")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("DeviceType")}
                                            data={applicationDeviceData.deviceTypeData}
                                            required={true}
                                            validator={inputValidator}
                                            onChange={onChange}
                                        />
                                        <Field
                                            name="authorId"
                                            label={t("AUDITOR ID")}
                                            component={FormInput}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("AUDITOR ID")}
                                            onChange={onChange}
                                        />
                                        <div
                                            className={'k-actions k-hstack k-justify-content-stretch'}
                                            style={{ marginTop: "32px", gap: "0px 8px", marginLeft: "-7px" }}
                                        >
                                            <Button primary>{t("SEARCH")}</Button>
                                            <Button
                                                type={'reset'}
                                                className={'k-secondary'}
                                                onClick={() => {
                                                    setSelectedData({
                                                        regionID: locationData?.Region?.RegionID,
                                                        countryID: locationData?.Country?.[0]?.CountryID,
                                                    });
                                                    setTimeout(() => {
                                                        formRenderProps.onFormReset();
                                                    }, 0);
                                                }}
                                            >
                                                {t("RESET")}
                                            </Button>
                                        </div>
                                    </div>
                                </FormElement>
                            )}
                        />
                    </div>
                    :
                    <div></div>
                }
                <div
                    className="nielsen-card"
                    style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
                >
                    <div className="eq_data_heading" style={{ margin: "0px -6px" }}>
                        <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
                        <div>
                            <Button
                                onClick={onExport}
                                style={{ margin: "10px", border: "none" }}
                                primary
                            >{t("export")}</Button>

                            <Button icon={expandIcon}
                                onClick={expand}
                                className={'k-secondary'}
                                style={{ margin: "10px", marginRight: '20px' }}
                            ></Button>
                        </div>
                    </div>
                    <div className="catalog" style={{
                        display: "flex",
                        padding: "1rem",
                        flexGrow: 1,
                        overflow: "auto",
                    }}
                    >
                        <Grid
                            className="workOrders_grid"
                            data={result.data}
                            {...dataState}
                            total={result.total}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setResult((result) => {
                                    const { data, total } = result;
                                    return {
                                        data: orderBy(data || [], e.sort),
                                        total,
                                    };
                                });
                                setSort(e.sort);
                            }}
                            filter={filter}
                            onFilterChange={(e) => {
                                setResult((result) => {
                                    const { total } = result;
                                    return {
                                        data: filterBy(data?.data || [], e.filter),
                                        total,
                                    };
                                });
                                setFilter(e.filter)
                            }}
                            onPageChange={handlePageChange}
                            cellRender={customCellRender}
                            pageable={{
                                pageSizes: false,
                            }}
                            pager={CreatePager}
                            resizable
                            reorderable
                            onColumnReorder={({ columns }) => {
                                const columnOrder = columns.reduce((columnsObj, column) => {
                                    columnsObj[column.field] = column.orderIndex;
                                    return columnsObj;
                                }, {})
                                const newColumns = stateColumns.map((column) => {
                                    return { ...column, orderIndex: columnOrder[column.field] }
                                });
                                setStateColumns(newColumns);
                            }}
                        >
                            {stateColumns.map(
                                (column, idx) => {
                                    const menu = columnMenuConfig[column?.columnMenu];
                                    return column.show &&
                                        (
                                            <GridColumn
                                                key={column?.orderIndex}
                                                field={column?.field}
                                                title={t(column.title)}
                                                width={column?.width}
                                                minResizableWidth={30}
                                                style={{ textAlign: "center" }}
                                                cell={GridCell}
                                                format={column?.format}
                                                filter={column?.filter}
                                                orderIndex={column?.orderIndex}
                                                {...(menu && {
                                                    columnMenu: (props) => {
                                                        return menu?.({
                                                            ...props,
                                                            columns: stateColumns,
                                                            onColumnsSubmit,
                                                            data: data?.data,
                                                        });
                                                    },
                                                })}
                                                headerClassName={
                                                    isColumnActive(column.field, { ...dataState, filter })
                                                        ? "activeFiltered"
                                                        : ""
                                                }
                                            />
                                        )
                                })}
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MiscellaneousReport
