import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";
import { checkInvalidSession } from "../../../utils/useLogout";

const getHeaders = (authContext) => ({
  "Content-Type": "application/json",
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  // companyid:authContext?.CompanyID || ""
  // companyid: "381"
});
const getHeadersPost = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  "Content-Type": "application/json",
  // companyid:authContext?.CompanyID || ""
  // companyid: "381"
});
export const fetchDashboardData = (params, authContext) => (dispatch) => {
  Get(AppURL.dashboardURL, params, getHeaders(authContext), true)
    .then((res) => res.json())
    .then((res) => {
      if (checkInvalidSession(res)) {
        dispatch({
          type: types.SET_AUTH_CONTEXT,
          payload: null,
        });
        localStorage.removeItem("spocDetails");
      }
      console.log(`fetchDashboardDatasss`, res);
      if (res) {
        dispatch({
          type: types.DASHBOARD_RESPONSE,
          payload: res?.root,
        });
      }
    });
};

export const fetchDownTime = (params, authContext) => (dispatch) => {
  Get(AppURL.downtime, params, getHeaders(authContext), true)
    .then((res) => res.json())
    .then((res) => {
      if (checkInvalidSession(res)) {
        dispatch({
          type: types.SET_AUTH_CONTEXT,
          payload: null,
        });
        localStorage.removeItem("spocDetails");
      }
      if (res) {
        dispatch({
          type: types.DOWNTIME_RESPONSE,
          payload: res,
        });
      }
    });
};

export const fetchEcollectionVersion = (params, authContext) => (dispatch) => {
  Get(AppURL.version, params, getHeaders(authContext), true)
    .then((res) => res.json())
    .then((res) => {
      if (checkInvalidSession(res)) {
        dispatch({
          type: types.SET_AUTH_CONTEXT,
          payload: null,
        });
        localStorage.removeItem("spocDetails");
      }
      if (res) {
        dispatch({
          type: types.ECOLLECTION_VERSION_RESPONSE,
          payload: res,
        });
      }
    });
};

export const searchUserPrefrence = (params, authContext) => (dispatch) => {
  Post(AppURL.searchUserPrefrence, params, getHeadersPost(authContext), true)
    .then((res) => res.json())
    .then((res) => {
      if (checkInvalidSession(res)) {
        dispatch({
          type: types.SET_AUTH_CONTEXT,
          payload: null,
        });
        localStorage.removeItem("spocDetails");
      }
      console.log(`fetchDashboardData`, res);
      if (res?.Response?.Message === "Success") {
        dispatch({
          type: types.SET_USER_PREFRENCE,
          payload: res?.Response?.Json_Object,
        });
      }
    });
};

export const downloadGuidelines = async (params, authContext) => {
  const headers = {
    //   userid: authContext?.SpocMail || "",
    //   "Content-Type": "application/x-www-form-urlencoded; application/json",
    //   countryid:authContext?.CountryCode,
  };
  const res = await Get(AppURL.downloadguidelines, params, headers, true);
  console.log("downloadGuidelines", { res });
  return res;
};
