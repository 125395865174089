

// import * as type from "../types";
import { Get, Post, PostwithTimeoutHandler } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";

// const getHeaders = (authContext) => ({
//   OrganizationUnit: authContext?.organizationUnit || "",
//   userid: authContext?.SpocMail || "",
// });


export const getScanImageTable = async (params, authContext) => {

  const header = {
    
    userid: authContext?.SpocMail || "",
    countryid: authContext?.CountryCode || "",
    companyid: authContext?.CompanyID || "",
    "content-type": "application/json",
    // "content-type": "application/x-www-form-urlencoded; application/json",
  }

  // const res = await Get(AppURL.getScanImageTable, params, header,true).then((res) =>
  const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header,true,10000).then((res) =>
    res.json()
  );
  return res;


  // return DCTRESOSPONSE;
};

// export const getNavigatorReportBasic=()=>{
//     return DCTRESOSPONSE;
// }



export const getScanImageTimestampExportData = async (params, authContext) => {

  const header = {    
    // userid: authContext?.SpocMail || "",
    // countryid: authContext?.CountryCode || "",
    // companyid: authContext?.CompanyID || "",
    // "content-type": "application/x-www-form-urlencoded; application/json",
    "content-type": "application/json",
  }

  // const exportFile = await Get(AppURL.getScanImageTimestampExportData,params,header,null,true);    
  const exportFile = await Post(AppURL.getScanImageTimestampExportData,params,header,null,true);    
    // console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
    const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
    const exportFileBlob = await exportFile.blob();
    const exportFileUrl = URL.createObjectURL(exportFileBlob);    
    // const FILE_NAME = Date.now();
    const anchor = document.createElement("a");
    anchor.href = exportFileUrl; 
    anchor.download = disposition?.[1];
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

};
