import { SvgIcon } from '@progress/kendo-react-common';

const HelpIcon = (props) => {
    return (
        <SvgIcon style={props?.style} width={props?.width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 4C13.4261 4 11.1543 4.68912 9.22205 5.98023C7.28978 7.27134 5.78375 9.10644 4.89442 11.2535C4.00509 13.4005 3.7724 15.763 4.22578 18.0423C4.67915 20.3216 5.79823 22.4152 7.4415 24.0585C9.08477 25.7018 11.1784 26.8208 13.4577 27.2742C15.737 27.7276 18.0995 27.4949 20.2465 26.6056C22.3936 25.7162 24.2287 24.2102 25.5198 22.2779C26.8109 20.3457 27.5 18.0739 27.5 15.75C27.5 12.6337 26.2621 9.64505 24.0585 7.44149C21.855 5.23794 18.8663 4 15.75 4ZM15.75 25.9658C13.7205 25.9657 11.7367 25.3635 10.0498 24.2352C8.36284 23.1069 7.0487 21.5033 6.27377 19.6276C5.49884 17.7519 5.29799 15.6884 5.69666 13.6985C6.09532 11.7085 7.07556 9.88167 8.51324 8.44924C9.95092 7.01681 11.7814 6.04325 13.7727 5.65186C15.7641 5.26048 17.8269 5.46887 19.6997 6.25065C21.5726 7.03243 23.1713 8.35243 24.2934 10.0435C25.4156 11.7345 26.0106 13.7205 26.0032 15.75C26.0032 17.0965 25.738 18.4297 25.2227 19.6737C24.7074 20.9177 23.9522 22.048 23.0001 23.0001C22.048 23.9522 20.9177 24.7074 19.6737 25.2227C18.4298 25.738 17.0965 26.0032 15.75 26.0032V25.9658Z" fill={props?.color ?? "#4F4F4F"} />
            <path d="M18.5182 10.8481C17.6694 10.275 16.6604 9.98665 15.6369 10.0248C14.846 10.0003 14.0653 10.2085 13.3917 10.6236C12.4187 11.2472 11.9073 12.2825 11.8574 13.7295H14.1026C14.106 13.2904 14.2359 12.8617 14.4768 12.4946C14.6161 12.2903 14.8076 12.127 15.0313 12.0217C15.2551 11.9164 15.5029 11.873 15.7491 11.8959C16.3479 11.8959 16.7595 12.058 16.984 12.3823C17.2107 12.6981 17.3289 13.0789 17.3208 13.4675C17.3235 13.8033 17.2186 14.1312 17.0214 14.403C16.8968 14.5759 16.7452 14.7275 16.5724 14.8521L16.0111 15.3011C15.5708 15.5946 15.2106 15.9933 14.9633 16.4611C14.7863 17.0433 14.698 17.6488 14.7014 18.2573H16.7969C16.7942 17.9543 16.832 17.6523 16.9092 17.3592C16.9997 17.0198 17.1964 16.7182 17.4705 16.4986L18.0318 16.0869C18.4511 15.7893 18.8286 15.4369 19.1544 15.0392C19.4922 14.5442 19.6626 13.9541 19.6409 13.3553C19.6807 12.8766 19.5986 12.3956 19.4023 11.9571C19.206 11.5187 18.9019 11.1372 18.5182 10.8481Z" fill={props?.color ?? "#4F4F4F"} />
            <path d="M16.9841 19.2676H14.6641V21.4941H16.9841V19.2676Z" fill={props?.color ?? "#4F4F4F"} />
        </SvgIcon>
    )
}

export default HelpIcon