import * as types from "../../../actions/types";

const initialState = {
    searchbyUserDetails : []
}

const resourceSearchByUser = (state = initialState, action) => {
    let newState;
    switch (action.type) {
      case types.GET_SEARCH_BY_USER:
        newState = {
          ...state,
          searchbyUserDetails: action.payload,
        };
        break;
      default:
        newState = state;
        break;
    }
    return newState;
}

export default resourceSearchByUser;