import * as types from "../../actions/types";

const initialState = {
  dashboardResponse: null,
  downTimeResponse: null,
  eCollectionVersion: null,
  userrolepermssioninfo:null,
  currentscreenId:null,
  // plannedStoreData: [],
  // auditorLocationData: [],
  // defaultTownData: [],
  // selectedCityPlannedStoreData: [],
  // scheduledPlannedStoreData: [],
  // cityGeoCoordsData: [],
  // countryGeoCoordsData: [],
  // selectedStateCityData: [],
  // activeStatus: "",
  // modifiedStoreData: [],
  // plannedAuditorData: [],
  // auditorTrackData: []
};

const dashboardReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.DASHBOARD_RESPONSE:
      newState = {
        ...state,
        dashboardResponse: action.payload,
      };
      break;
    case types.DOWNTIME_RESPONSE:
      newState = {
        ...state,
        downTimeResponse: action.payload,
      };
      break;
    case types.ECOLLECTION_VERSION_RESPONSE:
      newState = {
        ...state,
        eCollectionVersion: action.payload,
      };
      break;
      case types.SET_CURRENT_SCREEN_ID:
        newState = {
          ...state,
          currentscreenId: action.payload,
        };
        break;
      case types.SET_USER_ROLE_ACCESS:
        newState = {
          ...state,
          userrolepermssioninfo: action.payload,
        };
        break;
      
    // case types.PLANNED_STORE:
    //   newState = {
    //     ...state,
    //     plannedStoreData: action.payload,
    //   };
    //   break;
    // case types.AUDITORS_LOCATION:
    //   newState = {
    //     ...state,
    //     auditorLocationData: action.payload,
    //   };
    //   break;
    // case types.DEFAULT_TOWN_DATA:
    //   newState = {
    //     ...state,
    //     defaultTownData: action.payload,
    //   };
    //   break;
    // case types.SELECTED_CITY_PLANNED_STORE:
    //   newState = {
    //     ...state,
    //     selectedCityPlannedStoreData: action.payload,
    //   };
    //   break;
    // case types.SCHEDULED_PLANNED_STORE:
    //   newState = {
    //     ...state,
    //     scheduledPlannedStoreData: action.payload,
    //   };
    //   break;
    // case types.CITY_GEO_COORDS:
    //   newState = {
    //     ...state,
    //     cityGeoCoordsData: action.payload,
    //   };
    //   break;
    // case types.COUNTRY_GEO_COORDS:
    //   newState = {
    //     ...state,
    //     countryGeoCoordsData: action.payload,
    //   };
    //   break;
    // case types.STATE_CITY_VAL:
    //   newState = {
    //     ...state,
    //     selectedStateCityData: action.payload,
    //   };
    //   break;
    // case types.ACTIVE_STATUS:
    //   newState = {
    //     ...state,
    //     activeStatus: action.payload,
    //   };
    //   break;
    // case types.MODIFIED_PLANNED_STORE_DATA:
    //   newState = {
    //     ...state,
    //     modifiedStoreData: action.payload,
    //   };
    //   break;
    // case types.PLANNED_AUDITOR_LIST:
    //   newState = {
    //     ...state,
    //     plannedAuditorData: action.payload,
    //   };
    //   break;
    // case types.SET_AUDITOR_TRACK_DATA:
    //   newState = {
    //     ...state,
    //     auditorTrackData: action.payload,
    //   };
    //   break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export default dashboardReducer;
