import React from 'react';
import './style.css';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormComboBox } from '../../../form/form-component';

const DefaultReport = () => {
  return (
    <React.Fragment>
      <div className='tab-navbar'>
        <div className='all-circles'>
          <div className='circle' style={{ border: '5px solid cyan' }}>
            <div className="inner-circle" style={{ border: '5px solid cyan' }}>
              <span className='store-circle-name'>
                Stores In Plan
              </span>
              <span>0</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid green' }}>
            <div className="inner-circle" style={{ border: '5px solid green' }}>
              <span className='store-circle-name'>
                Stores Completed
              </span>
              <span>0</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid yellow' }}>
            <div className="inner-circle" style={{ border: '5px solid yellow' }}>
              <span className='store-circle-name'>
                Stores Inprogress
              </span>
              <span>0</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid red' }}>
            <div className="inner-circle" style={{ border: '5px solid red' }}>
              <span className='store-circle-name'>
                No Data
              </span>
              <span>0</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid gray' }}>
            <div className="inner-circle" style={{ border: '5px solid gray' }}>
              <span className='store-circle-name'>
                Collected EANS
              </span>
              <span>0</span>
            </div>
          </div>
          <div className='circle' style={{ border: '5px solid brown' }}>
            <div className="inner-circle" style={{ border: '5px solid brown' }}>
              <span className='store-circle-name'>
                Expected EANS
              </span>
              <span>0</span>
            </div>
          </div>
        </div>
        <div className='allTab-form'>
          <Form
            render={(formRenderProps) => (
              <React.Fragment>
                <FormElement>
                  <div className='form-container'>
                    <Field
                      component={FormComboBox}
                      name={"banner"}
                      // required={true}                       
                      label="BANNER"
                      placeholder="Banner"
                      style={{ width: '100%' }}
                    />
                    <Field
                      component={FormComboBox}
                      name={"storeType"}

                      label="STORE TYPE"
                      placeholder="Store Type"
                      style={{ width: '100%' }}
                    />
                    <Field
                      component={FormComboBox}
                      name={"collectionType"}
                      // required={true}                       
                      label="COLL TYPE"
                      placeholder="Collection Type"
                      style={{ width: '100%' }}
                    />
                    <Field
                      component={FormComboBox}
                      name={"limitDate"}
                      // required={true}                       
                      label="LIMIT DATE"
                      placeholder="Limit Date"
                      style={{ width: '100%' }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: 5 }}>
                    <Button className='tab-filter-search' style={{ marginLeft: 'auto' }}>SEARCH</Button>
                    <Button className='tab-filter-search'>EXPORT</Button>
                  </div>
                </FormElement>
              </React.Fragment>
            )}
          />
        </div>
      </div>

    </React.Fragment>
  )
}

export default DefaultReport;
