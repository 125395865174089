import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateSessionAsync } from "../../store/actions/login";
import { useHistory } from "react-router-dom";
import { logout } from "../../store/actions/logout";
import * as types from "../../store/actions/types";
import LoadMask from "../../utils/LoadMask";

const deleteAllCookies = () => {
  let cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

const ValidateSession = ({ children }) => {
  const history = useHistory();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const dispatch = useDispatch();
  const [isAuthorized, setAuthorized] = useState(false);
  const [showLoader, setShowLoader] = useState(null);

  const handleValidateSession = async () => {
    const params = {
      CompanyId: authContext?.CompanyID,
      UserId: authContext?.SpocMail,
    };
    setShowLoader({ msg: "Authorizing" });
    const validateSessionResponse = await validateSessionAsync(params);
    if (validateSessionResponse) {
      setAuthorized(true);
      if (validateSessionResponse?.root?.Status === "Success") {
        dispatch({
          type: types.SET_AUTH_CONTEXT,
          payload: { ...authContext, isAuth: true },
        });
        history.push("/dashboard");
      } else if (
        validateSessionResponse?.Response?.Message === `Invalid Session`
      ) {
        const CompanyId = authContext?.CompanyID,
          UserId = authContext?.SpocMail;
        //logout api
        dispatch(logout({ CompanyId, UserId }));
        dispatch({
          type: types.SET_AUTH_CONTEXT,
          payload: null,
        });
        localStorage.removeItem("spocDetails");
        deleteAllCookies();
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    if (authContext) {
      setAuthorized(true);
      if (!authContext?.isAuth) {
        setShowLoader({ msg: "Authorizing" });
        handleValidateSession();
      }
    } else {
      setAuthorized(true);
      history.push("/login");
    }
  }, [authContext]);

  if (isAuthorized) {
    return <>{children}</>;
  } else {
    return <>{showLoader && <LoadMask {...showLoader} />}</>;
  }
};

export default ValidateSession;
