//============================ Auth Context ================================//

export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_AUTH_CONTEXT = "SET_AUTH_CONTEXT";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const FORCE_LOGIN = "FORCE_LOGIN";
export const VALIDATE_SESSION_RESPONSE = "VALIDATE_SESSION_RESPONSE";
export const SET_LOCATION_DATA = "SET_LOCATION_DATA";
export const SET_USER_PREFRENCE = "SET_USER_PREFRENCE";
export const SET_SEARCH_PARAMETER_OF_PLAN = "SET_SEARCH_PARAMETER_OF_PLAN";


//============================ FORGOT PASSWORD ================================//

export const FORGOT_PASSWORD_QUS = "FORGOT_PASSWORD_QUS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
// export const FORGOT_PASSWORD_VALIDATION_ERROR = "FORGOT_PASSWORD_VALIDATION_ERROR";
export const FORGOT_PASSWORD_VALIDATION_RESPONSE = "FORGOT_PASSWORD_VALIDATION_RESPONSE";
export const RESET_PASSWORD = "RESET_PASSWORD";

//============================ REPORTS ================================//
export const NRPS_REPORT_LOADING = "NRPS_REPORT_LOADING";
export const NRPS_REPORT_FILTERS = "NRPS_REPORT_FILTERS";
export const NRPS_REPORT_STORES_INFO = "NRPS_REPORT_STORES_INFO";
export const NRPS_REPORT_FILTERS_SEARCH = "NRPS_REPORT_FILTERS_SEARCH";
export const NRPS_REPORT_PLAN_CLICK = "NRPS_REPORT_PLAN_CLICK";
export const NRPS_REPORT_PROGRESS_CLICK = "NRPS_REPORT_PROGRESS_CLICK";
export const NRPS_REPORT_KPI_CLICK = "NRPS_REPORT_KPI_CLICK";
export const NRPS_REPORT_AUDITOR_CLICK = "NRPS_REPORT_AUDITOR_CLICK";
export const NRPS_REPORT_EXPORT = "NRPS_REPORT_EXPORT";
export const NRPS_STORE_STATUS_UPDATE = "NRPS_STORE_STATUS_UPDATE"
export const COMMON_FILTERS_PARAMS = "COMMON_FILTERS_PARAMS";
export const DEFAULT_YEAR_WEEK = "DEFAULT_YEAR_WEEK";
export const BY_COMMON_FILTERS_ACTIVE_TAB = "BY_COMMON_FILTERS_ACTIVE_TAB";
export const ACTIVE_TAB_FILTERS = "ACTIVE_TAB_FILTERS";
export const IS_DATA_FOR_REPORT = "IS_DATA_FOR_REPORT";
export const ACTIVE_TAB = "ACTIVE_TAB";
export const REPORT_PARAMS = "REPORT_PARAMS";
export const SELECT_GRID_ROW = "SELECT_GRID_ROW";
export const SIDEBAR_OPEN_CLOSE = "SIDEBAR_OPEN_CLOSE";
export const STOREID_BY_GRID = "STOREID_BY_GRID"
export const REPORT_NAME_SELECTED_ROW = "REPORT_NAME_SELECTED_ROW"
export const SET_SELECTED_ROWS = "SET_SELECTED_ROWS"
export const IS_SELECTED_ROW = "IS_SELECTED_ROW"
export const DESELECT_ALL_ROWS = "DESELECT_ALL_ROWS"
export const EVENT_ROW_INDEX = "EVENT_ROW_INDEX"
export const SCROLLING_REPORT_NAME = "SCROLLING_REPORT_NAME"

//============================ USER PROFILE ================================//
export const USER_PROFILE_RESPONSE = "USER_PROFILE_RESPONSE";
export const CHANGE_PASSWORD_RESPONSE = "CHANGE_PASSWORD_RESPONSE";
export const UPDATE_USER_PROFILE_RESPONSE = "UPDATE_USER_PROFILE_RESPONSE";

//============================ DASHBOARD ================================//
export const DASHBOARD_RESPONSE = "DASHBOARD_RESPONSE";
export const DOWNTIME_RESPONSE = "DOWNTIME_RESPONSE";
export const ECOLLECTION_VERSION_RESPONSE = "ECOLLECTION_VERSION_RESPONSE";

//============================ API ================================//
export const API_Registration_Click = "API_Registration_Click";

//============================ MESSAGES ================================//
export const GET_MAILS_RESPONSE = "GET_MAILS_RESPONSE";
export const GET_MAIL_BODY_RESPONSE = "GET_MAIL_BODY_RESPONSE";
export const GET_MAIL_NEW_COUNT = "GET_MAIL_NEW_COUNT";


//============================ WORKORDERS ================================//
export const GET_AUDITOR_DETAILS = "GET_AUDITOR_DETAILS";

//============================ PEOPLE ================================//
export const GET_COLLECTION_TYPES = "GET_COLLECTION_TYPES";


//============================ COMMON DATA =================================//

export const SET_ROLES = "SET_ROLES";
export const GET_ALL_RCL = "GET_ALL_RCL";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_ORG_UNITS = "SET_ORG_UNITS";
export const SET_HUB_DETAILS = "SET_HUB_DETAILS";
export const SET_LOGGEDIN_USER_PHOTO = "SET_LOGGEDIN_USER_PHOTO";
export const SET_COUNTRY_DIALING_CODES = "SET_COUNTRY_DIALING_CODES";
export const SET_REFERENCE_DATA_SOURCE = "SET_REFERENCE_DATA_SOURCE";
export const GET_PERMISSIONS_FOR_LOGGEDIN_USER =
  "GET_PERMISSIONS_FOR_LOGGEDIN_USER";
export const SET_UNAVAILABILITY_REFERENCE_DATA_SOURCE =
  "SET_UNAVAILABILITY_REFERENCE_DATA_SOURCE";

export const SET_CURRENCY = "SET_CURRENCY";
export const SET_COURSE_TYPE = "SET_COURSE_TYPE";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_ROLE_ACCESS = "SET_USER_ROLE_ACCESS"
export const SET_CURRENT_SCREEN_ID = "SET_CURRENT_SCREEN_ID"

//============================ Profile Details =============================//

export const GET_STATE_DATA = "GET_STATE_DATA";
export const CLEAR_STATE_DATA = "CLEAR_STATE_DATA";
export const GET_CITY_DATA = "GET_CITY_DATA";
export const CLEAR_CITY_DATA = "CLEAR_CITY_DATA";
export const GET_ROLE_DETAILS = "GET_ROLE_DETAILS";
export const SET_PROFILE_PHOTO = "SET_PROFILE_PHOTO";
export const GET_RESOURCE_DETAILS = "GET_RESOURCE_DETAILS";
export const CLEAR_RESOURCE_DETAILS = "CLEAR_RESOURCE_DETAILS";
export const GET_MARKETS = "GET_MARKETS";
export const GET_MARKET_ASSOCIATIONS = "GET_MARKET_ASSOCIATIONS";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATION_ASSOCIATIONS = "GET_LOCATION_ASSOCIATIONS";

// SKILLS and reosurce skill maping also
export const GET_ACQUIRE_SKILLS = "GET_ACQUIRE_SKILLS";
export const SET_NIELSEN_SKILLS = "SET_NIELSEN_SKILLS";
export const SET_NON_NIELSEN_SKILLS = "SET_NON_NIELSEN_SKILLS";
export const ADD_RESOURCE_SKILLS = "ADD_RESOURCE_SKILLS";
export const CLEAR_ADD_RESOURCE_SKILLS = "CLEAR_ADD_RESOURCE_SKILLS";

// LEARNIGS
export const GET_ASSIGNED_COURSE = "GET_ASSIGNED_COURSE";
export const GET_ASSIGNED_PROGRAM = "GET_ASSIGNED_PROGRAM";
export const GET_COMPLETED_COURSE = "GET_COMPLETED_COURSE";
export const GET_COMPLETED_PROGRAM = "GET_COMPLETED_PROGRAM";

// ASSETS
export const GET_ASSETS = "GET_ASSETS";

//============================ Inbox  ======================================//

export const INBOX_FILTERS = "INBOX_FILTERS";
export const GET_REPOSITORY_DATA = "GET_REPOSITORY_DATA";
export const GET_INBOX_COLUMNS = "GET_INBOX_COLUMNS";
export const CLEAR_INBOX_COLUMNS = "CLEAR_INBOX_COLUMNS";
export const GET_INBOX_DATA = "GET_INBOX_DATA";
export const GET_WORKITEMS_DATA = "GET_WORKITEMS_DATA";
export const CLEAR_WORKITEMS_DATA = "CLEAR_WORKITEMS_DATA";
export const REASONDETAILS = "REASONDETAILS";

//============================ Resource Details ============================//

export const GET_RESOURCES = "GET_RESOURCES";
export const RESOURCES_FILTERS = "RESOURCES_FILTERS";

//============================ PERMISSION Details ==========================//

export const GET_MODULES = "GET_MODULES";
export const PERMISSION_FILTERS = "PERMISSION_FILTERS";
export const GET_RESOURCES_BY_PERMISSIONS = "GET_RESOURCES_BY_PERMISSIONS";
export const GET_RESOURCES_FOR_PERMISSIONS = "GET_RESOURCES_FOR_PERMISSIONS";
export const GET_RCL_FOR_USER = "GET_RCL_FOR_USER";
export const GET_OU_MODULE_ROLES = "GET_OU_MODULE_ROLES";
export const GET_ALL_RCL_FOR_USER = "GET_ALL_RCL_FOR_USER";
export const CLEAR_ALL_RCL_FOR_USER = "CLEAR_ALL_RCL_FOR_USER";
export const SET_ALL_RCL_FOR_USER = "SET_ALL_RCL_FOR_USER";
export const GET_OU_MODULE_ROLE_FOR_USER = "GET_OU_MODULE_ROLE_FOR_USER";
export const GET_PERMISSION_FOR_USER_ROLE = "GET_PERMISSION_FOR_USER_ROLE";
export const GET_PERMISSION_FOR_USER_MODULE = "GET_PERMISSION_FOR_USER_MODULE";

//============================ Freelancer Details ==========================//

export const GET_FREELANCERS = "GET_FREELANCERS";
export const FREELANCER_FILTERS = "FREELANCER_FILTERS";

//============================ QCT Responsibility ==========================//

//ASSIGN AUDITORS
export const GET_QCT_AUDITORS_AA = "GET_QCT_AUDITORS_AA";
export const GET_QCT_EXECUTIVES_AA = "GET_QCT_EXECUTIVES_AA";

//USER DELEGATIONS
export const GET_ACTINGFOR_RESOURCES = "GET_ACTINGFOR_RESOURCES";
export const GET_DELEGATES_RESOURCES = "GET_DELEGATES_RESOURCES";
export const GET_USER_DELEGATIONS = "GET_USER_DELEGATIONS";

//SELF COLLECTION
export const GET_QCT_EXECUTIVES_SC = "GET_QCT_EXECUTIVES_SC";
export const GET_QCT_DETAILS = "GET_QCT_DETAILS";
export const GET_QCT_STOREOWNERS = "GET_QCT_STOREOWNERS";

//============================ QCT Hub =====================================//

export const GET_COUNTRIES_LIST_BY_HUB = "GET_COUNTRIES_LIST_BY_HUB";

//============================ Store Owner Details =========================//

export const GET_STOREOWNERS = "GET_STOREOWNERS";
export const STOREOWNERS_FILTERS = "STOREOWNERS_FILTERS";

//============================ Company Details =============================//

export const GET_COMPANIES = "GET_COMPANIES";
export const COMPANY_FILTERS = "COMPANY_FILTERS";
export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const GET_COMPANY_AUDITORS = "GET_COMPANY_AUDITORS";

//============================ LEAVES Details ==============================//

export const GET_LEAVES = "GET_LEAVES";
export const UPDATE_LEAVES = "UPDATE_LEAVES";
export const LEAVES_FILTERS = "LEAVES_FILTERS";
export const GET_LEAVE_RESOURCES = "GET_LEAVE_RESOURCES";

//============================ Resource Availability Details ===============//

export const GET_RESOURCE_AVAILABILITY = "GET_RESOURCE_AVAILABILITY";
export const DELETE_RESOURCE_AVAILABILITY = "DELETE_RESOURCE_AVAILABILITY";
export const CLEAR_DELETE_RESOURCE_AVAILABILITY_RES =
  "CLEAR_DELETE_RESOURCE_AVAILABILITY_RES";

//============================ Bulk Upload Details ===============//

export const GET_MODULEWISE_UPLOADTYPE = "GET_MODULEWISE_UPLOADTYPE";
export const GET_UPLOAD_STATUS = "GET_UPLOAD_STATUS";
export const GET_ERROR_RECORDS = "GET_ERROR_RECORDS";
export const GET_UPLOAD_ATTRIBUTES = "GET_UPLOAD_ATTRIBUTES";
export const DOWNLOAD_ERRORS_STATUS = "DOWNLOAD_ERRORS_STATUS";
export const DOWNLOAD_TEMPLATE_STATUS = "DOWNLOAD_TEMPLATE_STATUS";

//============================ Exception Handling ==========================//

export const SERVICE_FAILURE = "SERVICE_FAILURE";
export const NETWORK_FAILURE = "NETWORK_FAILURE";

// Traning Management ============================ Cource Search ===================//

export const GET_ASSOCIATE_DATA = "GET_ASSOCIATE_DATA";
export const GET_FREELANCERS_DATA = "GET_FREELANCERS_DATA";
export const GET_OJTDATA_DATA = "GET_OJTDATA_DATA";
export const GET_EVALUATED_DATA = "GET_EVALUATED_DATA";
export const GET_EVALUVATED_ID = "GET_EVALUVATED_ID";
export const SET_TAB = "SET_TAB";

//  ============Program search Data ==========================//

export const GET_PROGRAM_REASON_ASSIGN_DATA = "GET_PROGRAM_REASON_ASSIGN_DATA";
export const GET_PROGRAM_FREELANCE_ASSIGN_DATA =
  "GET_PROGRAM_FREELANCE_ASSIGN_DATA";
export const GET_PROGRAM_ASSOCIATE_ASSIGN_DATA =
  "GET_PROGRAM_ASSOCIATE_ASSIGN_DATA";
export const GET_PROGRAM_ASSOCIATE_DATA = "GET_PROGRAM_ASSOCIATE_DATA";
export const GET_PROGRAM_FREELANCERS_DATA = "GET_PROGRAM_FREELANCERS_DATA";
export const GET_PROGRAM_EVALUATED_DATA = "GET_PROGRAM_EVALUATED_DATA";
export const GET_PROGRAM_EVALUVATED_ID = "GET_PROGRAM_EVALUVATED_ID";
export const SET_PROGRAM_SEARCH_DATA = "SET_PROGRAM_SEARCH_DATA";
export const GET_ASSIGN_PROGRAM_DATA = "GET_ASSIGN_PROGRAM_DATA";

//Traning Manamenagment common services ******  =========//

export const FETCH_LOB_DETAILS = "FETCH_LOB_DETAILS";
export const FETCH_LANGUAGE_DETAILS = "FETCH_LANGUAGE_DETAILS";

export const GET_COURSE_SEARCH_DATA = "GET_COURSE_SEARCH_DATA";
export const GET_PROGRAM_SEARCH_DATA = "GET_PROGRAM_SEARCH_DATA";
export const GET_PROGRAM_DETAILS = "GET_PROGRAM_DETAILS";

//==========Couser Skill Maping ===================================//

export const COURSE_SKILL_MAPING_GRID_DATA = "COURSE_SKILL_MAPING_GRID_DATA";
export const COURSE_SKILL_COMPLETED_PROGRAM = "COURSE_SKILL_COMPLETED_PROGRAM";
export const GET_SKILL_DETAILS = "GET_SKILL_DETAILS";
export const GET_ASSING_LOB_DETAILS = "GET_ASSING_LOB_DETAILS";
export const GET_ASSIGN_SKILL_DETAILS = "GET_ASSIGN_SKILL_DETAILS";
export const GET_ASSOCIATE_ASSIGN_DATA = "GET_ASSOCIATE_ASSIGN_DATA";
export const GET_FREELANCE_ASSIGN_DATA = "GET_FREELANCE_ASSIGN_DATA";
export const GET_ASSIGN_SKILL_DATA = "GET_ASSIGN_SKILL_DATA";
export const GET_REASON_ASSIGN_DATA = "GET_REASON_ASSIGN_DATA";

//================  Resource Skill Mapping =====================//

export const GET_RESOURCE_PER_SKILL = "GET_RESOURCE_PER_SKILL";
export const GET_SEARCH_BY_USER = "GET_SEARCH_BY_USER";

//=============== Skill Mapping ============== //

export const SKILL_SET_NIELSEN_SKILLS = "SKILL_SET_NIELSEN_SKILLS";
export const SKILL_SET_NON_NIELSEN_SKILLS = "SKILL_SET_NON_NIELSEN_SKILLS";
export const SKILL_GET_ACQUIRE_SKILLS = "SKILL_GET_ACQUIRE_SKILLS";

//=============== Alert List  ============== //
export const GET_ALERT_LIST = "GET_ALERT_LIST";
export const GET_DATA_CHANGE_VALUES = "GET_DATA_CHANGE_VALUES";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const GET_BLOCK_INFO = "GET_BLOCK_INFO";
export const GET_AUDITOR_LOCATION = "GET_AUDITOR_LOCATION";


//=============== Header Mapping ============== //
export const HEADER_REFRESH_CLICKED = "HEADER_REFRESH_CLICKED";
export const HEADER_REFRESH_CLICKED_RESET = "HEADER_REFRESH_CLICKED_RESET";
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const SET_AUDITOR_LIST = "SET_AUDITOR_LIST";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const SET_LOB_DATA = "SET_LOB_DATA";
export const SET_CHAT_SERVER_STATE = "SET_CHAT_SERVER_STATE";

//=============== Dashboard Mapping ============== //
export const AUDITORS_LOCATION = "AUDITORS_LOCATION";
export const PLANNED_STORE = "PLANNED_STORE";
export const DEFAULT_TOWN_DATA = "DEFAULT_TOWN_DATA";
export const SELECTED_CITY_PLANNED_STORE = "SELECTED_CITY_PLANNED_STORE";
export const SCHEDULED_PLANNED_STORE = "SCHEDULED_PLANNED_STORE";
export const CITY_GEO_COORDS = "CITY_GEO_COORDS";
export const COUNTRY_GEO_COORDS = "COUNTRY_GEO_COORDS";
export const STATE_CITY_VAL = "STATE_CITY_VAL";
export const ACTIVE_STATUS = "ACTIVE_STATUS";
export const MODIFIED_PLANNED_STORE_DATA = "MODIFIED_PLANNED_STORE_DATA";
export const PLANNED_AUDITOR_LIST = "PLANNED_AUDITOR_LIST";
export const SET_AUDITOR_TRACK_DATA = "SET_AUDITOR_TRACK_DATA";

//=============== Alerts Customization ============== //
export const GET_ALERTS_CUSTOMIZATION = "GET_ALERTS_CUSTOMIZATION";

//=============== SOS ============== //
export const GET_SOS = "GET_SOS";
export const GET_SOS_DETAILS = "GET_SOS_DEATAILS";

//===============Country Enablement===================//
export const GET_COUNTRY_ENABLEMENT_DATA = "GET_COUNTRY_ENABLEMENT_DATA";

//=============== Chat ===================//
export const SET_AUDITOR_FOR_CHAT = "SET_AUDITOR_FOR_CHAT";
export const REMOVE_AUDITOR_FOR_CHAT = "REMOVE_AUDITOR_FOR_CHAT";
export const SET_CHAT_INSTANCE = "SET_CHAT_INSTANCE";
export const SET_CHAT_MESSAGE = "SET_CHAT_MESSAGE";
export const INITIATE_CHAT_MESSAGE = "INITIATE_CHAT_MESSAGE";
export const AUD_STATUS_CHANGE = "AUD_STATUS_CHANGE";

export const SET_WORKORDER_DETAIL = "SET_WORKORDER_DETAIL";
export const SET_ACTION_WORKORDER_DETAIL = "SET_ACTION_WORKORDER_DETAIL";

export const SET_REPORTS_ACCORDIAN_ACTIVE = "SET_REPORTS_ACCORDIAN_ACTIVE";
