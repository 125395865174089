import React from 'react';
import layer from '../../../assets/images/reports/layer.png';// import NRPS_Report from './nrps/NRPS_Report';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";


const MISCELLANEOUS = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [displayReport, setDisplayReport] = React.useState(false)


    const openReport = () => {
        console.log("click before", displayReport)
        setDisplayReport(!displayReport)
    }

    const characters = [
        {
            id: 1,
            reportName: t("FMD_LBL"),
            reportImage: <img className='img-style' src={layer} alt="report" />,
            navigateIcon: <span
                className="k-icon k-i-chevron-right k-icon-32"
                style={{ fontSize: 29 }}
            />
        },

    ];

    //     const Window = (props) =>{
    //       const [windowStage, setWindowStage] = React.useState("FULLSCREEN");

    // const setFullscreen = () => {
    //   console.log("fullscreenWindo", windowStage)
    //   setWindowStage("FULLSCREEN");
    // };

    // // const handleStageChange = e => {
    // //   setWindowStage(e.state);
    // // };
    // const handleStageChange = () => {
    //   setWindowStage(windowStage);
    // };
    //       return (
    //         <Window title={"Status"} stage={windowStage} onStageChange={handleStageChange}>

    //         <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={setFullscreen}>{props.key}</button>

    //       </Window>

    //       )

    //     }
    // const handleClick = (event, key) => {
    //   console.log(event.target);
    //   console.log('key index: ', key);
    // };

    const CardListItem = (props) => {
        return (
            <li className='card-container' onClick={() => history.push("/reports/mobileApp")} >

                <p className='all-reports-name'>
                    {props.character.reportName}
                </p>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p>{props.character.reportImage}</p>
                    <p>{props.character.navigateIcon}</p>
                </div>
            </li>
        );
    };
    const CardList = () => {
        return (
            <ul style={{ listStyleType: "none", padding: 0, display: 'flex', flexWrap: "wrap", gap: "30px" }}>
                {characters.map((character) => {
                    return (
                        <React.Fragment>
                            <CardListItem character={character} key={character.id} openReport={openReport} />
                        </React.Fragment>
                    );
                })}
            </ul>
        );
    };
    return (
        <React.Fragment>
            <CardList />
        </React.Fragment>
    )
}

export default MISCELLANEOUS
