import { Provider } from "react-redux";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import Main from "./components/main";
import Dashboard from "./components/dashboard";
import store from "./store";
import "./App.scss";
import Login from "./components/login";
import { ToastContainer } from "react-toastify";
import People from "./components/people";
import WorkOrder from "./components/workOrder";
import Reports from "./components/reports";
import Notifications from "./components/notifications";
import UserProfile from "./components/userProfile";
import Home from "./components/home";
import ActionWorkOrder from "./components/workOrder/workOrderComp/ActionWorkOrder";
import NRPS_Report from "./components/reports/eCollection/nrps/NRPS_Report";
import ReportDCT from "./components/reports/DCT/statusReport/ReportDCT";
import MiscellaneousReport from "./components/reports/Miscellaneous/mobileApp/MiscellaneousReport";
import ReportNSO from "./components/reports/NSO/auditSummary/ReportNSO";
import TSreportNSO from "./components/reports/NSO/timeStamp/TSreportNSO";
import ValidateSession from "./components/ValidateSession";
import AddResource from "./components/people/resources/AddResource";
import EcollectionSummary from "./components/reports/eCollection/EcollectionSummary";
import ExecutionQualityReport from "./components/reports/eCollection/execution-qualityReport/ExecutionQualityReport";
import ScanImageTimestamp from "./components/reports/eCollection/scanimageTimestamp/ScanImageTimestamp";
import FlowControlReport from "./components/reports/eCollection/flowControlReport/FlowControlReport";
import NrpsReport from "./components/reports/eCollection/nrpsReport/NrpsReport";
import PreFill from "./components/workOrder/workOrderComp/preFill";
import ReplanValidate from "./components/workOrder/workOrderComp/replanValidate";
import SummaryReport from "./components/reports/eCollection/summaryReport/SummaryReport";
import UpcWindow from "./components/workOrder/workOrderComp/ActionWorkOrder/upcWindow";
import Api from "./components/api";
import ProactiveReport from "./components/reports/GNIC/ProactiveReport";
import ReactiveReport from "./components/reports/GNIC/reactiveReport/ReactiveReport";
import ItemDetails from "./components/reports/GNIC/ItemDetails";
import AuditSummaryRA from "./components/reports/RA/auditSummary";


export const App = () => {

  return (
    <Provider store={store}>
      <HashRouter
      // basename="/react-navigator"
      >
        <ToastContainer
          position="top-center"
          draggable={false}
          newestOnTop={false}
          closeOnClick={false}
          pauseOnHover
          pauseOnFocusLoss
        />
        <ValidateSession>
          <Main>
            <Switch>

              <Route exact={true} path="/login" component={Login} />
              <Dashboard>
                <Route exact={true} path="/">
                  <Redirect to="/dashboard" screen={"1"} />
                </Route>
                <Route exact={true} path="/dashboard" component={Home} screen={"1"} />
                <Route
                  exact={true}
                  path="/user_profile"
                  component={UserProfile}
                  screen={"7"} 
                />
                <Route exact={true} path="/people" component={People} screen={"2"} />
                <Route exact={true} path="/people/add" component={AddResource} screen={"2"}/>
                <Route exact={true} path="/api" component={Api} screen={"2"} />
                <Route
                  exact={true}
                  path="/notifications"
                  component={Notifications}
                  screen={"5"}
                />

                <Route exact={true} path="/work_orders" component={WorkOrder} screen={"3"} />
                <Route
                  exact={true}
                  path="/work_orders/plan"
                  component={ActionWorkOrder}
                  screen={"3"}
                />
                <Route
                  exact={true}
                  path="/work_orders/prefill"
                  component={PreFill}
                  screen={"3"}
                />
                <Route
                  exact={true}
                  path="/work_orders/replanValidate"
                  component={ReplanValidate}
                  screen={"3"}
                />
                <Route
                  exact={true}
                  path="/work_orders/upcWinow"
                  component={UpcWindow}
                  screen={"3"}
                />

                <Route exact={true} path="/reports" component={Reports} screen={"4"} />
                <Route exact={true} path="/reports/dct" component={ReportDCT} screen={"4"}  />
                <Route
                  exact={true}
                  path="/reports/mobileApp"
                  component={MiscellaneousReport}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/NSO/auditSummary"
                  component={ReportNSO}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/NSO/timeStamp"
                  component={TSreportNSO}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/eCollection/nrpss"
                  component={NRPS_Report}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/eCollection/nrps"
                  component={NrpsReport}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/eCollection/summary"
                  component={EcollectionSummary}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/nrps"
                  component={NRPS_Report}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/eCollection/summaryreport"
                  component={SummaryReport}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/ecollection/executionquality"
                  component={ExecutionQualityReport}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/ecollection/scanimagetimestamp"
                  component={ScanImageTimestamp}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/ecollection/flowcontrolreport"
                  component={FlowControlReport}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/nic/reactive"
                  component={ReactiveReport}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/nic/proactive"
                  component={ProactiveReport}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/nic/item-details"
                  component={ItemDetails}
                  screen={"4"} 
                />
                <Route
                  exact={true}
                  path="/reports/RA/auditSummary"
                  component={AuditSummaryRA}
                  screen={"4"} 
                />

                <Route path="*" render={() => <Redirect to="/" />} />
              </Dashboard>
            </Switch>
          </Main>
        </ValidateSession>
      </HashRouter>
    </Provider>
  );
};
