import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    {
        title: "AUDIT_ID",
        field: "auditId",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "AUDITOR ID",
        field: "auditorId",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:  "AUDIT_DATE",
        field: "auditDate",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "date",
        format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "STORE_ID",
        field: "storeId",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "shotid",
        field: "shotid",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "shotnumber",
        field: "shotNumber",
        show: true,
        // cell:UploadCell
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "shotdate",
        field: "shotDate",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "date",
        format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "display Number",
        field: "displaynum",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "BARCODE",
        field: "barcode",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "IMAGE_ID",
        field: "imageid",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title:  "IMAGE_START_TIME",
        field: "imageStartTime",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "IMAGE/ BARCODE CAP...",
        field: "imageCaptureTime",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    
];
export default columns;
