import React, { useState } from "react";
import PlanGrid from "./PlanGrid"
import ProgressGrid from "./ProgressGrid";
import KpiGrid from "./KpiGrid";
import AuditorGrid from "./AuditorGrid";

const DataGrid = ({height, type, commonFiltersParams, searchCommFiltData, cb, cbProgressStoreId, callbackStoreId}) => {
  
  const [gridType, setGridType] = useState(type);  
  
  cb(!searchCommFiltData)
  console.log("clickOnserch", searchCommFiltData); 
  

  return (
    (searchCommFiltData)?<React.Fragment>
    <div style={{height:height}}>
      {(gridType === "PLAN")?<PlanGrid height={{height:"calc(100vh - 258px)"}} callbackStoreId={callbackStoreId}/>:(gridType === "PROGRESS")?<ProgressGrid height={{height:"calc(100vh - 258px)"}} commonFiltersParams={commonFiltersParams} callbackStoreId={callbackStoreId}/>:(gridType === "KPI")?<KpiGrid height={{height:"calc(100vh - 258px)"}} commonFiltersParams={commonFiltersParams} callbackStoreId={callbackStoreId}/>:(gridType === "AUDITOR")?<AuditorGrid height={{height:"calc(100vh - 258px)"}} commonFiltersParams={commonFiltersParams} callbackStoreId={callbackStoreId}/>:""}
    </div>
    </React.Fragment>:<React.Fragment>
      <div style={{height:height}}>
        {(gridType === "PLAN")?<PlanGrid height={{height:"calc(100vh - 258px)"}} callbackStoreId={callbackStoreId}/>:(gridType === "PROGRESS")?<ProgressGrid height={{height:"calc(100vh - 258px)"}} commonFiltersParams={commonFiltersParams} callbackStoreId={callbackStoreId}/>:(gridType === "KPI")?<KpiGrid height={{height:"calc(100vh - 258px)"}} commonFiltersParams={commonFiltersParams} callbackStoreId={callbackStoreId}/>:(gridType === "AUDITOR")?<AuditorGrid height={{height:"calc(100vh - 258px)"}} commonFiltersParams={commonFiltersParams} callbackStoreId={callbackStoreId}/>:""}
      </div>
    </React.Fragment>   
    
  );
};

export default DataGrid;


