import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid: authContext?.CompanyID || "",
});

export const getStaged = async (params, authContext) => {
  const res = await Get(
    AppURL.getstaged,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};
export const postSaveOrValidate = async (params,body, authContext) => {
  const res = await Post(
    AppURL.saveorvalidate+`?${params}`,
    body,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};
// export const getNielsenStarEndDates = async (params, authContext) => {
//   const res = await Get(
//     AppURL.GetNielsenStarEndDates,
//     params,
//     getHeaders(authContext),
//     true
//   ).then((res) => res.json());
//   return res;
// };

