import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
  SearchFilterComp,
  SearchFilterContainer,
  SearchFilterFooter,
  SearchForm,
} from "../../ui/SearchFilter";
import { Field, FormElement } from "@progress/kendo-react-form";
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
  FormMultiSelect,
} from "../../form/form-component";
import moment from "moment";
import { useSelector } from "react-redux";
import { showToast } from "../../../utils/Notification";
import { useTranslation } from "react-i18next";
import { inputValidator } from "../../form/validator";
import { capsuleToParams } from "../../../utils/Utility";


const SearchFilterWindow = ({
  shouldRefresh,
  setShouldRefresh,
  dataState,
  setDataState,
  searchTerm,
  setSearthTerm,
  capsulesFilter,
  setCapsulesFilter,
  fetchSearchPlan
}) => {
  const formRef = React.useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const searchParametersOFPlan = useSelector((state) => state.commonReducer.searchParametersOFPlan);
  const dateStamp = useSelector((store) => store?.reports?.dateStamp);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [selectedState, setSelectedState] = useState({
    isDateSelected: false,
    isWeekNoSelected: true
  });
  const collectionTypeData = searchParametersOFPlan?.Details?.CollectionType;
  const [comboBoxData, setComboBoxData] = useState({
    yearData: [],
    weekData: [],
    statusData: []
  });

  const initialFormValue = useMemo(() => {
    return {
      Region: locationData?.Region?.RegionID,
      Country: locationData?.Country,
      CollectionType: searchParametersOFPlan?.DefaultCollectionType || "",
      year: '',
      week: '',
    };
  }, [locationData, dateStamp, searchParametersOFPlan]);

  const [selectedData, setSelectedData] = useState(initialFormValue);

  const onSearchClick = async (e) => {
    const newSearchParams = capsuleToParams(capsulesFilter);
    const params = {
      ...newSearchParams,
      CompanyID: authContext?.CompanyID,
      page: 1,
      StartCount: 0,
      MaxCount: dataState.take,
    };
    const searchTerm = e.target?.searchWorkOrder?.value;
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setSearthTerm(searchTerm);
    setDataState((state) => {
      return { ...state, skip: 0 }
    });
    fetchSearchPlan(params);
  };

  React.useEffect(() => {
    if (collectionTypeData?.length) {
      const newYearsData = [];
      const newWeekData = [];
      const newStatusData = [];
      collectionTypeData?.forEach((ctd) => {
        if (ctd?.Countries) {
          ctd.Countries.forEach((c) => {
            const Years = c?.Country?.Years;
            if (Years) {
              Years.forEach((y) => {
                const year = y?.year;
                if (year && !newYearsData.some((cy) => cy.year === year)) {
                  newYearsData.push(y);
                }
                y?.weekNo.forEach((w) => {
                  const week = w?.week;
                  if (week && !newWeekData.some((cy) => cy.week === week)) {
                    newWeekData.push(w);
                  }
                });
                y?.statusDetails.forEach((s) => {
                  const status = s?.status;
                  if (status && !newStatusData.some((cy) => cy.status === status)) {
                    newStatusData.push(s);
                  }
                });
              });
            }
          });
        }
      });

      setComboBoxData({
        ...comboBoxData,
        yearData: newYearsData.sort((a, b) => b.year - a.year),
        weekData: newWeekData.sort((a, b) => b.week - a.week),
        statusData: newStatusData.sort((a, b) => b.status - a.status)
      });

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear().toString();
      const startDate = new Date(currentDate.getFullYear(), 0, 1);
      const days = Math.floor(
        (currentDate - startDate) / (24 * 60 * 60 * 1000)
      );
      const currentWeek = Math.ceil(days / 7).toString();
      const selectedYear = !!newYearsData.find((cy) => cy.year === currentYear) ? currentYear : newYearsData[0].year;
      const selectedWeek = !!newWeekData.find((cy) => cy.week === currentWeek) ? currentWeek : newWeekData[0]?.week;
      setSelectedData((prev) => ({ ...prev, year: selectedYear, week: selectedWeek }));

      if (newWeekData.length === 0) {
        setSelectedState({
          ...selectedState,
          isDateSelected: true,
          isWeekNoSelected: false
        });
      }
    }
  }, [collectionTypeData]);

  const onFilterSubmitClick = async ({ values }) => {

    if (values?.startdate && !values?.enddate) {
      showToast("error", `${t('emptyenddate_msglbl')}`);
      return;
    } else if (!values?.startdate && values?.enddate) {
      showToast("error", `${t('emptystartdate_msglbl')}`);
      return;
    }

    formRef.current.setFilterWindow(false);

    const newCapsuleFilter = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (key === "Region") {
          return acc;
        }
        if (value) {
          if (key === "Country") {
            const countryData = value?.map((c) => ({
              name: key,
              text: c.CountryName,
              value: c.CountryID,
            }));
            return [...acc, ...countryData];
          }
          if (key === "startdate" || key === "enddate") {
            value = moment(value).format("MM/DD/YYYY");
          }

          return [
            ...acc,
            {
              name: key,
              text: value,
              value,
            },
          ];
        }
        return acc;
      },
      []
    );

    const newSearchParams = capsuleToParams(newCapsuleFilter);
    setCapsulesFilter(newCapsuleFilter);
    setSelectedData(values);

    const params = {
      ...newSearchParams,
      CompanyID: authContext?.CompanyID,
      page: "1",
      StartCount: "0",
      MaxCount: dataState.take,
    };

    if (searchTerm) {
      params.keyword = searchTerm;
    }

    setDataState((state) => ({ ...state, skip: 0 }))
    fetchSearchPlan(params);
  };

  const removeCapsuleFilter = (index) => {
    const { name: removeFieldName, value: removeFieldValue } =
      capsulesFilter[index];
    const newSelectedData = { ...selectedData };
    if (removeFieldName === "Country") {
      newSelectedData.Country = newSelectedData.Country.filter(
        (c) => c.CountryID !== removeFieldValue
      );
      if (newSelectedData.Country.length === 0) {
        showToast("error", `${t("countrymandatory_msglbl")}`);
        return false
      }
    } else {
      delete newSelectedData[removeFieldName];
    }
    setSelectedData(newSelectedData);
    const newCapsuleFilter = [...capsulesFilter];
    newCapsuleFilter.splice(index, 1);
    setCapsulesFilter(newCapsuleFilter);
    const newSearchParams = capsuleToParams(newCapsuleFilter);
    const params = {
      ...newSearchParams,
      CompanyID: authContext?.CompanyID,
      page: 1,
      StartCount: 0,
      MaxCount: dataState.take,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setDataState((state) => ({ ...state, skip: 0 }))
    fetchSearchPlan(params);
  };

  useEffect(() => {
    if (shouldRefresh) {
      const newSearchParams = capsuleToParams(capsulesFilter);
      const params = {
        ...newSearchParams,
        CompanyID: authContext?.CompanyID,
        page: 1,
        StartCount: 0,
        MaxCount: dataState.take,
      };
      if (searchTerm) {
        params.keyword = searchTerm;
      }
      setDataState((state) => ({ ...state, skip: 0 }));
      fetchSearchPlan(params);
      setShouldRefresh(false);
    }
  }, [shouldRefresh, capsulesFilter, dataState.take, searchTerm]);

  useEffect(() => {
    if (authContext && dataState) {
      const params = {
        CompanyID: authContext?.CompanyID,
        page: Math.floor(dataState.skip / dataState.take) + 1,
        StartCount: dataState.skip,
        MaxCount: dataState.take,
      };
      const JobId = history.location.state?.JobId;
      if (JobId) {
        params.keyword = JobId;
      }
      fetchSearchPlan(params);
    }
  }, [authContext]);

  return (
    <>
      <SearchFilterContainer>
        <SearchForm
          placeholder={`${t("searchby")}, ${t("WORKORDERID")}, ${t("Action")}, ${t("YEAR")}, ${t("WeekNumber")}, ${t("weekendingdate")}, ${t("collectiontype")}, ${t("createdon")}, ${t("Status")}, ${t("updatedon")}, ${t("updatedby")}`}
          onSubmitClick={onSearchClick}
          defaultValue={history.location.state?.JobId}
        />
        <SearchFilterComp
          ref={formRef}
          onSubmitClick={onFilterSubmitClick}
          selectedData={selectedData}
          capsulesFilter={capsulesFilter}
          removeCapsuleFilter={removeCapsuleFilter}
          windowProps={{
            initialTop: 70,
            initialWidth: 1165,
            initialHeight: 366,
          }}
        >
          {(formRenderProps) => {
            return (
              <FormElement>
                <div style={{ display: "flex", gap: 15, flexWrap: "wrap" }}>

                  <Field
                    name="Region"
                    label={t("Region")}
                    component={FormComboBox}
                    wrapperStyle={{ width: "calc(25% - 15px)" }}
                    // style={{ paddingLeft: "40px!important" }}
                    placeholder={t("Region")}
                    // data={regionCountry}
                    data={[locationData?.Region]}
                    textField="RegionName"
                    valueField="RegionID"
                    disabled
                  />
                  <Field
                    name="Country"
                    label={t("COUNTRY")}
                    component={FormMultiSelect}
                    placeholder={t("COUNTRY")}
                    required
                    wrapperStyle={{
                      width: "calc(25% - 15px)",
                      position: "relative",
                    }}
                    // data={countryData}
                    data={locationData?.Country}
                    textField="CountryName"
                    valueField="CountryID"
                    validator={inputValidator}
                    onChange={(e) => {
                      setSelectedData({
                        ...selectedData,
                        Country: e?.value || [],
                      });
                    }}
                  />
                  <Field
                    name="CollectionType"
                    label={t("COLLECTION_LBL")}
                    component={FormComboBox}
                    wrapperStyle={{ width: "calc(25% - 15px)" }}
                    textField="Collection"
                    valueField="Collection"
                    defaultValue={selectedData?.CollectionType}
                    data={collectionTypeData || []}
                    placeholder={t("COLLECTION_LBL")}
                    onChange={(e) => {
                      const selectedCollectionValue = e.value
                      setSelectedData({
                        ...selectedData,
                        CollectionType: selectedCollectionValue || "",
                      });

                    }}
                  />
                  <Field
                    name="year"
                    label="Year"
                    component={FormComboBox}
                    wrapperStyle={{ width: "calc(25% - 15px)" }}
                    placeholder={"Year"}
                    textField="year"
                    valueField="year"
                    data={comboBoxData.yearData}
                    // defaultValue={dateStamp?.selectedYear?.DisplayValue}
                    onChange={(e) => {
                      const selectedYearValue = e.value;
                      setSelectedData({ ...selectedData, year: selectedYearValue || "" });
                    }}
                  />
                  <Field
                    name="week"
                    label={t("WEEK")}
                    component={FormComboBox}
                    wrapperStyle={{ width: "calc(25% - 15px)" }}
                    placeholder={"Week"}
                    textField="week"
                    valueField="week"
                    data={comboBoxData.weekData}
                    disabled={selectedState.isDateSelected}
                    // defaultValue={dateStamp?.selectedWeek?.DisplayValue}
                    onChange={(e) => {
                      setSelectedData({ ...selectedData, week: e.value });
                      if (e.value) {
                        setSelectedState({
                          ...selectedState,
                          isWeekNoSelected: true
                        })
                      } else {
                        setSelectedState({
                          ...selectedState,
                          isWeekNoSelected: false
                        })
                      }
                    }}
                  />
                  <Field
                    name="startdate"
                    label={t("START_DATE_LBL")}
                    component={FormDatePicker}
                    wrapperStyle={{ width: "calc(25% - 15px)" }}
                    placeholder={t("START_DATE_LBL")}
                    disabled={selectedState.isWeekNoSelected}
                    onChange={(e) => {
                      setSelectedData({ ...selectedData, startdate: e.value });
                      if (e.value) {
                        setSelectedState({
                          ...selectedState,
                          isDateSelected: true
                        })
                      } else {
                        setSelectedState({
                          ...selectedState,
                          isDateSelected: false
                        })
                      }
                    }}
                  />
                  <Field
                    name="enddate"
                    label={t("END_DATE_LBL")}
                    component={FormDatePicker}
                    wrapperStyle={{ width: "calc(25% - 15px)" }}
                    placeholder={t("END_DATE_LBL")}
                    disabled={selectedState.isWeekNoSelected}
                    onChange={(e) => {
                      setSelectedData({ ...selectedData, enddate: e.value });
                      if (e.value) {
                        setSelectedState({
                          ...selectedState,
                          isDateSelected: true
                        })
                      } else {
                        setSelectedState({
                          ...selectedState,
                          isDateSelected: false
                        })
                      }
                    }}
                  />
                  <Field
                    name="Status"
                    label={t("Status")}
                    component={FormComboBox}
                    wrapperStyle={{ width: "calc(25% - 15px)" }}
                    placeholder={t("Status")}
                    textField="status"
                    valueField="status"
                    data={comboBoxData.statusData}
                    onChange={(e) => {
                      setSelectedData({ ...selectedData, Status: e.value });
                    }}
                  />
                  <Field
                    name="JobId"
                    label={t("WORKORDERID")}
                    component={FormInput}
                    wrapperStyle={{ width: "calc(25% - 15px)" }}
                    placeholder={t("WORKORDERID")}
                    onChange={(e) => {
                      setSelectedData({ ...selectedData, JobId: e.value });
                    }}
                  />
                </div>
                <div></div>
                <SearchFilterFooter handleReset={() => {
                  setSelectedData({ ...initialFormValue, Country: '', CollectionType: "", year: '', week: '' });
                  // setSelectedData(initialSelectedData);
                  setTimeout(() => {
                    formRenderProps.onFormReset();
                  }, 0);
                }} />
              </FormElement>
            )
          }}
        </SearchFilterComp>
      </SearchFilterContainer>
    </>
  );
};

export default SearchFilterWindow;
