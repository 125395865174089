export const apiData = [
    {
      heading: "Authentication Token",
      content: 'Authentication'
      // path:"/reports/eCollection",
    },
    {
      heading: 'eCollection',
      content:
        "Quos quam ipsam consequatur consequatur et distinctio. Facere vel ut dolorem. Quam quo neque quos voluptates cupiditate sit quae.",
    },  
      
  ];