import { Upload } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { useState, useRef } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
// import AppURL from "../../../utils/AppURL";
// import AppURL from "../../../utils"
import React from "react";
import { postPlanUpload } from "../../../store/actions/workOrder/bulkUpload";
import { useSelector } from "react-redux";
import { FormUpload } from "../../ui/form/form-component";
import { FormComboBox } from "../../form/form-component";
import { coverageScreenDownloadStore } from "../../../store/actions/workOrder/coverageScreen";
import AppURL from "../../../utils/AppURL";
import { inputValidator } from "../../ui/form/validator";
import LoadMask from "../../../utils/LoadMask";
import { getExportTemplate } from "../../../store/actions/people/bulkUploads";
import { showToast } from "../../../utils/Notification";
import { exportAllCompanyAuditor } from "../../../store/actions/people/resources";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";

const DownloadWindow = ({ setDownloadWindowShow, selectedDataUpload }) => {
  
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const {handleAuthContext} = useLogout();
  const [showLoader, setShowLoader] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);


  const onCountryChange = (e) => {   
    if(e?.value === null){      
      setDisableBtn(true)
    }else{
      setDisableBtn(false)      
    }
  }

  const handleDownload = async (form) => {
    console.log("form", { form });
    if (!form?.isValid) {
      return;
    }
    if (!authContext) {
      console.log("authContext", authContext);
      return;
    }
    const { CompanyID } = authContext;
    const {countryid,status=""} = form.values;
    const params = {
      companyid:CompanyID,
      countryid,
      status
    };
    setShowLoader({
      className: ".resource-export-window",
      msg: "loading",
    });
    const res = await exportAllCompanyAuditor(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.headers.get("content-type") === "application/json") {
      const jsonRes = await res.json();
      showToast("error", `${jsonRes?.Output?.Status}`);
    } else if (res?.status === 200) {
      // getAllCompanyAuditors?companyid=440&countryid=56&status=ACTIVE
      const url = `${AppURL.getAllCompanyAuditors}?companyid=${CompanyID}&countryid=${countryid}&status=${status}`;
      window.location.assign(url);
    }
    setShowLoader(null);
    console.log("result", res);
  };
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <Window
        className="resource-export-window"
        title={"Export All"}
        onClose={() => {
          // debugger;
          setDownloadWindowShow(false);
        }}
        modal={true}
        minimizeButton={() => null}
        maximizeButton={() => null}
        restoreButton={() => null}
        // initialTop={70}
        initialHeight={206}
        initialWidth={450}
        style={{ paddingBottom: "1em" }}
      >
        <>
            <Form
              initialValues={{
                RegionID:locationData?.Region?.RegionID,
                countryid:locationData?.Country?.[0]?.CountryID
              }}
              onSubmitClick={handleDownload}
              render={(formRenderProps) => (
                <FormElement>
                  <div
                    style={{
                      // display: "flex",
                      gap: 40,
                      flexDirection: "row",
                      flexWrap: "wrap",
                      margin: 0,
                    }}
                  >
                    <div style={{ display: "flex", gap: "1em" }}>
                      <Field
                        name="RegionID"
                        label="Region"
                        component={FormComboBox}
                        wrapperStyle={{ flex: 1 }}
                        textField="RegionName"
                        valueField="RegionID"
                        // data={[{ RegionName: "Europe", RegionID: 4 }]}
                        data={[locationData?.Region]}
                        defaultValue={locationData?.Region?.RegionID}
                        disabled
                        required
                      />
                      <Field
                        name="countryid"
                        label="Country"
                        placeholder="Country"
                        component={FormComboBox}
                        textField="CountryName"
                        valueField="CountryID"
                        // data={[{ countryName: "Belgium", countryId: 23 }]}
                        data={locationData?.Country || []}
                        wrapperStyle={{ flex: 1 }}
                        defaultValue={locationData?.Country?.[0]?.CountryID}
                        required
                        onChange={onCountryChange}
                      />
                      <Field
                        name="status"
                        label="Status"
                        placeholder="Status"
                        component={FormComboBox}
                        data={["ACTIVE", "INACTIVE","ALL"]}
                        wrapperStyle={{ flex: 1 }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: ".8em",
                      position: "absolute",
                      bottom: 0,
                      width: "calc(100% - 2em)",
                    }}
                  >

                    <Button primary disabled={disableBtn} >
                      Export All
                    </Button>
                  </div>
                </FormElement>
              )}
            />
          </>
      </Window>
    </>
  );
};

export default DownloadWindow;
