import { CustomColumnMenuFilter } from "../../../ui/grid/CustomColumnMenu";

const columns = [
  {
    title: "Sms Id",
    field: "SmsId",
    show: true,
    editable: true,
    columnMenu: CustomColumnMenuFilter,
    width: 200,
    filter: "numeric",
  },
  {
    title: "ResourceId",
    field: "AuditorId",
    show: true,
    editable: true,
    columnMenu: CustomColumnMenuFilter,
    width: 200,
    filter: "numeric",
  },
  {
    title: "visitdate",
    field: "VisitDate",
    show: true,
    editable: true,
    columnMenu: CustomColumnMenuFilter,
    width: 200,
    filter: "numeric",
  },
  {
    title: "starttime",
    field: "StartTime",
    show: true,
    editable: true,
    columnMenu: CustomColumnMenuFilter,
    width: 200,
    filter: "date",
  },
  {
    title: "endtime",
    field: "EndTime",
    show: true,
    editable: true,
    columnMenu: CustomColumnMenuFilter,
    width: 200,
  },
  // {
  //   title: "createdon",
  //   field: "CreatedOn",
  //   show: true,
  //   columnMenu:CustomColumnMenuFilter,
  //   filter:"date",
  //   format:"{0:MM-dd-yyyy}"
  // },
  {
    title: "ValidationRemarks",
    field: "ValidationMsg",
    show: true,
    columnMenu: CustomColumnMenuFilter,
    width: 200,
    editable: true,
  },
];
export default columns;
