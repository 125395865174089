import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";

const CustomMailCell = (props) => {
  const field = props.field || "";
  const value = props.dataItem.ReadFlag;
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      style={{
        fontWeight: value === "NEW" ? "bolder" : "normal",
      }}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      {value === null ? "" : props.dataItem[field].toString()}
    </td>
  );
};

export default CustomMailCell;
