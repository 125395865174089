import { Upload } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { useState, useRef } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
// import AppURL from "../../../utils/AppURL";
// import AppURL from "../../../utils"
import React from "react";
import { postPlanUpload } from "../../../store/actions/workOrder/bulkUpload";
import { useSelector } from "react-redux";
import { FormUpload } from "../../ui/form/form-component";
import { FormComboBox } from "../../form/form-component";
import { coverageScreenDownloadStore } from "../../../store/actions/workOrder/coverageScreen";
import AppURL from "../../../utils/AppURL";
import { inputValidator } from "../../ui/form/validator";
import LoadMask from "../../../utils/LoadMask";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";

const DownloadWindow = ({ setDownloadWindowShow, selectedDataUpload }) => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const uploadRef = useRef(null);
  const {handleAuthContext} = useLogout();

  const [isFormShow, setFormShow] = useState(true);
  const [showLoader, setShowLoader] = useState(null);

  const handleDownload = async (form) => {
    if(!form?.isValid){
      console.log("form",{form});
      return;
    }
    const Action = document.activeElement.id;
    if(!authContext){
      console.log("authContext",authContext)
      return;
    }
    const { CollectionType } = form.values;
    const {CompanyID,CountryCode} = authContext;
    const params = {
      CompanyId: CompanyID,
      Countryid: CountryCode,
      Action,
      Collectiontype: CollectionType,
    };   
    setShowLoader({
      className: ".coverageScreenDownloadStore",
      msg: "loading",
    });
    const res = await coverageScreenDownloadStore(params,authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.headers.get("content-type") ==="application/json") {
      const jsonRes = await res.json();
      // showToast("error", `${jsonRes?.Output?.Status}`);
    } else if (res?.status === 200) {
      // ?CompanyId=440&Countryid=56&Action=ALL&Collectiontype=DCT
      const url = `${AppURL.CoverageScreenDownloadStore}?CompanyId=${CompanyID}&Countryid=${CountryCode}&Action=${Action}&Collectiontype=${CollectionType}`;
      window.location.assign(url);
    }
    setShowLoader(null);
    console.log("result",res);
  };
  console.log({ selectedDataUpload });
  return (
    <>
  {showLoader && <LoadMask {...showLoader} />}
    <Window
      className="coverageScreenDownloadStore"
      title={"Download Stores File"}
      onClose={() => {
        // debugger;
        setDownloadWindowShow(false);
      }}
      modal={true}
      minimizeButton={() => (
        <Button
          icon="question"
          onClick={() => setFormShow(!isFormShow)}
          style={{ marginRight: ".5em" }}
        />
      )}
      maximizeButton={() => null}
      restoreButton={() => null}
      // initialTop={70}
      initialHeight={256}
      initialWidth={390}
      style={{ paddingBottom: "1em" }}
    >
      {isFormShow ? (
        <>
          <Form
            onSubmitClick={handleDownload}
            render={(formRenderProps) => (
              <FormElement>
                <div
                  style={{
                    // display: "flex",
                    gap: 40,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    margin: 0,
                  }}
                >
                  <Field
                    name={"CollectionType"}
                    component={FormComboBox}
                    label={"Collection Type"}
                    // onChange={onCountryChange}
                    data={["DCT", "ECOLLECTION", "GNIC", "NSO"]}
                    required={true}
                    validator={inputValidator}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".8em",
                    position: "absolute",
                    bottom: 0,
                    width: "calc(100% - 2em)",
                  }}
                >
                  <Button
                    id="ALL"

                    style={{ borderColor: "#000 " }}
                    onClick={() => {
                      // formRenderProps.onFormReset();
                    }}
                  >
                    Download All Stores
                  </Button>
                  <Button id="UNASSIGNED" primary>Download Unassigned Stores</Button>
                </div>
              </FormElement>
            )}
          />
        </>
      ) : (
        <div>
          <Button icon="arrow-left" onClick={() => setFormShow(true)}>
            Back To Upload
          </Button>
          <ul style={{ color: "#000", fontWeight: 500 }}>
            <li>All column fields are comma(,) separated</li>
            <li>Resource ID:Mandatory field, shows the Primary resource</li>
            <li>
              Resource Name:Optional field, filling resource id will also auto
              populate resource name and vice versa
            </li>
            <li>
              Secondary Resource ID:Optional field Multiple resources can be
              added
            </li>
            <li>
              Multiple resources should be seperated by a | symbol and each
              resource should be enclosed with single quotes
            </li>
            <li>
              <b>Start and End Dates:</b> Mandatory fields, these dates are
              bounded by the Nielsen Start and End Dates. For example, if the
              Nielsen Start Date is 04/28/2019 and the Nielsen End Date is
              05/04/2019, then you are &quot;bound&quot; to work on or between
              those dates when scheduling work for your resource.
            </li>
            <li>
              <b>Visit Date:</b> This will autopopulate with the End Date if not
              filled in. You can choose to add a Visit Date so that the resource
              knows that the store must be visited on a specific date
            </li>
            <li>
              <b>Start and End Time:</b> To be used if the store should be
              audited during a specific time of the day. For example, the shop
              keeper may want to be visited only in the afternoon hours. So you
              would specify the Start and End times accordingly
            </li>
            <li>
              <b>Remarks:</b> These are comments you can enter as instructions
              to your resource. For example, you could say &quot;Please do not
              visit this store from 12pm to 1pm&quot; or other important
              messages you want to pass along for that particular visit.
            </li>
          </ul>
        </div>
      )}
    </Window>
    </>

  );
};

export default DownloadWindow;
