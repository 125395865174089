import { useEffect, useState } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import UpcCategory from './upcCategory';
import UpcAuditor from './upcAuditor';
import { getSaveCounts } from './getUpcCountsWindow';
import LoadMask from '../../../../../utils/LoadMask';
import { useSelector } from 'react-redux';
import useLogout, { checkInvalidSession } from '../../../../../utils/useLogout';
import './index.scss';

function UpcWindow({ setUpcWindow, upcWindow }) {
    const { handleAuthContext } = useLogout();
    const [showLoader, setShowLoader] = useState(null);
    const [upcCountsData, setUpcCountsData] = useState([]);
    const [upcNameData, setUpcNameData] = useState([]);
    const [upsCountData, setUpsCountData] = useState([]);

    const authContext = useSelector((state) => state.commonReducer.authContext);

    useEffect(() => {
        let name = [];
        let count = [];

        upcCountsData?.map(elm => (
            name.push(elm.Name),
            count.push(elm.Count)
        ))

        setUpcNameData([...new Set(name)])
        setUpsCountData([...new Set(count)])
    }, [])

    const saveAssignment = () => {
        let params = JSON.stringify(
            { "root": { "Store": { "StoreId": upcWindow.StoreId, "Auditor": [{ "AuditorId": upcWindow.AuditorId, "AuditorStatus": upcWindow.Status, "CountAssigned": upcWindow.store_upc_count, "CountMax": 0, "id": "extModel162-1", "CountAssigned1": 0, "UPCCountAcrossStore": 0, "upccountstat": 0 }] } } })

        saveAssignmentApi(params);
    }

    const saveAssignmentApi = async (params) => {
        let query = `?jobid=16043`;
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await getSaveCounts(query, params, authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        setShowLoader(null);
    }

    return (
        <>
            {showLoader && <LoadMask {...showLoader} />}
            <Window
                className='upc-assigned'
                title={`Store Name: ${upcWindow.StoreName}, UPC (Assigned / Available): ${upcWindow.store_upc_count}/${upcWindow?.total_available_upc || 0}`}
                onClose={() => setUpcWindow(null)}
                modal={true}
                initialHeight={500}
                initialWidth={1200}
                style={{ paddingBottom: "1em" }}
            >
                <div style={{ marginTop: "40px" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ margin: "2px" }}>
                            <UpcCategory />
                        </div>
                        <div style={{ margin: "2px" }}>
                            <UpcAuditor primary_resource_name={upcWindow.PRIMARY_RESOURCE_NAME} status={upcWindow.Status} upcsToCollect={upcWindow.store_upc_count} upcWindow={upcWindow} />
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "end", marginRight: "240px" }}>
                        <p style={{ color: "black" }}><b>Total Upcs:</b> {upcWindow.store_upc_count}</p>
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            primary
                            onClick={saveAssignment}
                            style={{ margin: "10px" }}
                        >
                            Save Assignments
                        </Button>
                    </div>
                </div>
            </Window>
        </>
    )
}

export default UpcWindow
