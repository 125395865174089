import * as types from "../types";
import { Get, Post, Put } from "../../methods";
import AppURL from "../../../utils/AppURL";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid: authContext?.CompanyID || "",
});

export const getCollectionTypes = (params, authContext) => async (dispatch) => {
  const res = await Get(
    AppURL.getcollectiontypes,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("getcollectiontypes", { res });
  const CollectionTypes = res?.CollectionTypes;
  if (CollectionTypes) {
    dispatch({ type: types.GET_COLLECTION_TYPES, payload: CollectionTypes });
  }
  return res;
};
