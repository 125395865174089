// import { ComboBox } from '@progress/kendo-react-dropdowns/dist/npm/ComboBox/ComboBox';
import * as React from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";

const colorMap = {
  // "COMPLETE":"#b7f3b7", 
  // "INPROGRESS":"#FFFFE0", 
  //  "INCOMPLETE":"#FFCCCB" 
  "COMPLETE":"#b7f3b7", 
  "IN PROGRESS":"#FFFFE0",   
   "INCOMPLETE":"#FFCCCB",
   "INCOMPLETE - STORE": "#FFCCCB",
   "INCOMPLETE - VENDOR": "#FFCCCB",
   "INCOMPLETE - OTHER": "#FFCCCB",
   "CANCELLED": "#FAA9A9",
   "In Progress": "#FFFFE0", 
   "Complete":"#b7f3b7",
   "Incomplete":"#FFCCCB",
   "Incomplete - Store": "#FFCCCB",
   "Incomplete - Vendor": "#FFCCCB",
   "Incomplete - Other": "#FFCCCB",
   "cancelled": "#FAA9A9"
 }



export const CellRender = props => {
  console.log("cellprops",props)
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ''];
  console.log("inEditField",inEditField)
  const additionalProps = cellField && cellField === inEditField ? {
    ref: td => {
      const input = td && td.querySelector('input');
      const activeElement = document.activeElement;
      if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
        return;
      }
      if (input.type === 'checkbox') {
        input.focus();
      } else {
        input.select();
      }
    }
  } : {
    onClick: () => {
      props.enterEdit(dataItem, cellField);
    }
  };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps
  };
  return React.cloneElement(props.td, clonedProps, props.td.props.children);
};
export const RowRender = props => {
  const trProps = {
    ...props.tr.props,
    onBlur: () => {
      props.exitEdit();
    }
  };
  return React.cloneElement(props.tr, {
    ...trProps
  }, props.tr.props.children);
};

export const CellDropDown = (props) => {      
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            // const input = td && td.querySelector("input");
            // const activeElement = document.activeElement;
            // if (
            //   !input ||
            //   !activeElement ||
            //   input === activeElement ||
            //   !activeElement.contains(input)
            // ) {
            //   return;
            // }
            // if (input.type === "checkbox") {
            //   input.focus();
            // } else {
            //   input.select();
            // }
          },
        }
      : {
          onClick: () => {
            props.enterEdit(dataItem, cellField);
          },
        };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };

  
  // return React.cloneElement(props.td, clonedProps, props.td.props.children);
  const isEdited = dataItem?.edited?.includes(cellField)
  console.log("isEdited", dataItem.edited, cellField)
  // console.log("cellField", cellField)
  // const { dataItem } = props;
  const field = props?.originalProps?.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  const handleChange = (e) => {
    console.log("handle",e.target.value)
    if (props?.originalProps?.onChange) {
      props?.originalProps?.onChange({
        dataIndex: 0,
        dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }else{
      console.log("onchange not found")
    }

}
  return (
    // <td {...clonedProps} style={{backgroundColor:isEdited?"rgba(255,100,0,.3)":""}}>
    <td {...clonedProps} style={{backgroundColor:colorMap?.[dataValue?.toString()]}}>

      {cellField === inEditField ? (
        dataItem.inEdit ? <DropDownList value = {dataValue}  {...props?.cellProps} onChange={handleChange} style={{width:'100%'}} /> : dataValue?.toString()
      ) : (
        props.td.props.children
      )}
    </td>
  );
};

export const StoreStatusRender = (props) => {      
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            // const input = td && td.querySelector("input");
            // const activeElement = document.activeElement;
            // if (
            //   !input ||
            //   !activeElement ||
            //   input === activeElement ||
            //   !activeElement.contains(input)
            // ) {
            //   return;
            // }
            // if (input.type === "checkbox") {
            //   input.focus();
            // } else {
            //   input.select();
            // }
          },
        }
      : {
          onClick: () => {
            // props.enterEdit(dataItem, cellField);
          },
        };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };

  
  // return React.cloneElement(props.td, clonedProps, props.td.props.children);
  const isEdited = dataItem?.edited?.includes(cellField)
  console.log("isEdited", dataItem.edited, cellField)
  // console.log("cellField", cellField)
  // const { dataItem } = props;
  const field = props?.originalProps?.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  const handleChange = (e) => {
    console.log("handle",e.target.value)
    if (props?.originalProps?.onChange) {
      props?.originalProps?.onChange({
        dataIndex: 0,
        dataItem,
        field: cellField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }else{
      console.log("onchange not found")
    }

}
  return (
    // <td {...clonedProps} style={{backgroundColor:isEdited?"rgba(255,100,0,.3)":""}}>
    <td {...clonedProps} style={{backgroundColor:colorMap?.[dataValue?.toString()]}}>

      {cellField === inEditField ? (
        dataItem.inEdit ? <DropDownList value = {dataValue}  {...props?.cellProps} onChange={handleChange} style={{width:'100%'}} /> : dataValue?.toString()
      ) : (
        props.td.props.children
      )}
    </td>
  );
};
export const SentToFactoryRender = (props) => {      
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  // const inEditField = dataItem[props.editField || ""];
  const inEditField = props?.originalProps?.dataItem?.SENT_TO_FACTORY;
  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            // const input = td && td.querySelector("input");
            // const activeElement = document.activeElement;
            // if (
            //   !input ||
            //   !activeElement ||
            //   input === activeElement ||
            //   !activeElement.contains(input)
            // ) {
            //   return;
            // }
            // if (input.type === "checkbox") {
            //   input.focus();
            // } else {
            //   input.select();
            // }
          },
        }
      : {
          onClick: () => {
            // props.enterEdit(dataItem, cellField);
          },
        };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };

  
  // return React.cloneElement(props.td, clonedProps, props.td.props.children);
  // const isEdited = dataItem?.edited?.includes(cellField)
  // console.log("isEdited", dataItem.edited, cellField)
  // console.log("cellField", cellField)
  // const { dataItem } = props;
  const field = props?.originalProps?.dataItem?.SENT_TO_FACTORY;
  // const dataValue = dataItem[field] === null ? "" : dataItem[field];

//   const handleChange = (e) => {
//     console.log("handle",e.target.value)
//     if (props?.originalProps?.onChange) {
//       props?.originalProps?.onChange({
//         dataIndex: 0,
//         dataItem,
//         field: cellField,
//         syntheticEvent: e.syntheticEvent,
//         value: e.target.value,
//       });
//     }else{
//       console.log("onchange not found")
//     }

// }
  return (
    // <td {...clonedProps} style={{backgroundColor:isEdited?"rgba(255,100,0,.3)":""}}>
    <td {...clonedProps} style={{backgroundColor: field ? "#fff" : "red"}}>

      {/* {cellField === inEditField ? (
        dataItem.inEdit ? <DropDownList value = {dataValue}  {...props?.cellProps} onChange={handleChange} style={{width:'100%'}} /> : dataValue?.toString()
      ) : (
        props.td.props.children
      )} */}
    </td>
  );
};