const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
// eslint-disable-next-line no-useless-escape
const nonNielsenEmailRegex = new RegExp(/^(\w+)([\-+.][\w]+)*@nielseniq.com$/);
const newPasswordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/);

export const inputValidator = (value) => !value?.toString() ?
    "Field is required" : "";
    
export const nameValidator = (value) => !value ?
    "Full Name is required" :
    value.length < 7 ? "Full Name should be at least 7 characters long." : "";

export const userNameValidator = (value) => !value ?
    "User Name is required" :
    value.length < 8 ? "User name should be at least 8 characters long." : "";

export const emailValidator = (value) => !value ?
    "Email field is required." :
    (emailRegex.test(value) ? "" : "Email is not valid format.");

export const nonNeilsenEmailValidator = (value) => !value ?
    "Email field is required." :
    (nonNielsenEmailRegex.test(value) ? "Must be valid non-nielseniq email" :
        (emailRegex.test(value) ? "" : "Email is not valid format."));

export const optionalEmailValidator = (value) => value && !emailRegex.test(value) ?
    "Email is not valid format." : "";

export const phoneValidator = (value) => !value ?
    "Phone number is required." :
    phoneRegex.test(value) ? "" : "Not a valid phone number.";

export const loginEmailValidator = (value) => !value ?
    "This Field Is Required" :
    (emailRegex.test(value) ? "" : `This field should be an e-mail address in the format "user@example.com"`);

export const forgotPasswordValidator = (value) => !value ?
    "This Field Is Required" :
    (newPasswordRegex.test(value) ? "" : `The Minimum Length For This Field Is 8
    Atleast 8 Characters, 1 Numeric, 1 Lowercase, 1 Uppercase, 1 Special Character And Max 12 Characters`);