const columns = [
    {
        title: "COUNTRY",
        field: "Country",
        show: true,
    },
    {
        title: "collectiontype",
        field: "Collection Type",
        show: true,
    },
    {
        title: "YEAR",
        field: "Year",
        show: true,
    },
    {
        title: "WEEK",
        field: "Week",
        show: true,
    },
    {
        title: "WORKORDERID",
        field: "Work Order Id",
        show: true,
    },
    {
        title: "AUDIT_ID",
        field: "Audit Id",
        show: true,
        // cell:UploadCell
    },
    {
        title:"AUDITOR ID",
        field: "Auditor Id",
        show: true,
    },
    {
        title: "auditortype",
        field: "Auditor Type",
        show: true,
    },
    {
        title:"AUDIT DATE",
        field: "Auditor Date",
        show: true,
    },
    {
        title:"Status",
        field: "Status",
        show: true,
    },
    {
        title: "exportedfrombb",
        field: "Exported From Bb",
        show: true,
    },
    {
        title: "tasktype",
        field: "Task Type",
        show: true,
    },
    {
        title: "CITY",
        field: "City",
        show: true,
    },
    {
        title: "PROVINCE",
        field: "Province",
        show: true,
    },
    {
        title: "SMS ID",
        field: "Sms Id",
        show: true,
    },
    {
        title: "StoreName",
        field: "Store Name",
        show: true,
    },
    {
        title: "storeaddress",
        field: "Store_Adress",
        show: true,
    },
    {
        title: "RETAILER NUMBER",
        field: "Retailer Number",
        show: true,
    },
    {
        title: "store_open_time",
        field: "Store_open_time",
        show: true,
    },
    {
        title: "Store_Collect_time",
        field: "Store_Collect_time",
        show: true,
    },
    {
        title: "Time_before_collect_(minutes)",
        field: "Time_before_collect_(minutes)",
        show: true,
    },
    {
        title: "Store_Close_datetime",
        field: "Store_Close_datetime",
        show: true,
    },
    {
        title: "First_display_start_time",
        field: "First_display_start_time",
        show: true,
    },
    {
        title: "Last_display_end_time",
        field: "Last_display_end_time",
        show: true,
    },
    {
        title: "Total Store Time(Minute)",
        field: "Total Store Time(Minute)",
        show: true,
    },
    {
        title: "Image-only Time(Minute)",
        field: "Last_display_end_time",
        show: true,
    },
    {
        title: "Scan only Time(Minute)",
        field: "Scan only Time(Minute)",
        show: true,
    },
    {
        title: "Scan Plus Image Time(Minutes)",
        field: "Scan Plus Image Time(Minutes)",
        show: true,
    },
    {
        title: "Non Collect Time(Minute)",
        field: "Non Collect Time(Minute)",
        show: true,
    },
    {
        title: "Total # of Accepted Image",
        field: "Total # of Accepted Image",
        show: true,
    },
    
    {
        title: "Total # of Declined Image",
        field: "Total # of Declined Image",
        show: true,
    },
    {
        title: "Total # of Display",
        field: "Total # of Display",
        show: true,
    },
    
    {
        title: "Total # of Image",
        field: "Total # of Image",
        show: true,
    },
    {
        title: " # of Unique Scannedbarcodes",
        field: " # of Unique Scannedbarcodes",
        show: true,
    },
    
    {
        title: "# of Unique Imagedbarcodes",
        field: "# of Unique Imagedbarcodes",
        show: true,
    },
    {
        title: " Total # of Imaged BarCodes",
        field: " Total # of Imaged BarCodes",
        show: true,
    },
    
    {
        title: "Total # of Scanned BarCodes",
        field: "Total # of Scanned BarCodes",
        show: true,
    },
    {
        title: "Total Working Time(Minutes)",
        field: "Total Working Time(Minutes)",
        show: true,
    },
    
    {
        title: "Total # of BarCodes Collected",
        field: "Total # of BarCodes Collected",
        show: true,
    },
    {
        title: "Total # of Unique BarCodes Collected",
        field: "Total # of Unique BarCodes Collected",
        show: true,
    },
    
    {
        title: "Prev_week_scanned_barcodes",
        field: "Prev_week_scanned_barcodes",
        show: true,
    },
    {
        title: "Store Close Comments",
        field: "Store Close Comments",
        show: true,
    },
    
    {
        title: "NON COLLECT TIME DISPLAY",
        field: "NON COLLECT TIME DISPLAY",
        show: true,
    },
    {
        title: "Teamstore",
        field: "Teamstore",
        show: true,
    },
    
    {
        title: "Primary Receive Merge",
        field: "Primary Receive Merge Timestamp",
        show: true,
    },
    {
        title: "ReceiveMergeForPrimAuditorLbl",
        field: "Receive Merge Status of Primary Auditor",
        show: true,
    },
    
    {
        title: "SEND_MERGE_STATUS_FOR_SECONDARY_AUDITOR",
        field: "Send Merge Status For Secondary Auditor",
        show: true,
    },
    {
        title: "ReceiveMergeForPrimAuditorLbl",
        field: "Receive Merge Status of Primary Auditor",
        show: true,
    },
    
    {
        title: "Send Merge Status",
        field: "Send Merge Status For Secondary Auditor",
        show: true,
    },
    {
        title:  "SecondSendMergeTmStmpLbl",
        field: "Secondary Send Merge Timestamp",
        show: true,
    },
    
    {
        title: "NewItemsCollectedCount",
        field: "New Items Collected Count",
        show: true,
    },
    {
        title: "NewItemsTimeSpent",
        field: "New Items Time Spent",
        show: true,
    },
    
    {
        title: "#newitemsscanned",
        field: "New Items Scanned",
        show: true,
    },
   
    
];
export default columns;
