const columns = [
    {
      title: "batchid",
      field: "BatchId",
      show: true,
    },
    {
      title: "Status",
      field: "Status",
      show: true,
    },
    {
      title: "uploadedby",
      field: "UploadedBy",
      show: true,
    },
    {
      title: "uploadedon",
      field: "UploadedOn",
      show: true,
    },
    {
      title: "totalrecords",
      field: "TotalCount",
      show: true,
    },
    {
      title: "failure",
      field: "ErrorCount",
      show: true,
    },
    {
      title: "uploadfile",
      field: "uploadFile",
      show: true,
    },
  ];
  export default columns;
  