import React, { useState, useEffect } from "react";
import {
  SearchFilterComp,
  SearchFilterContainer,
  SearchFilterFooter,
  SearchForm,
} from "../../ui/SearchFilter";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormComboBox, FormInput } from "../../form/form-component";
import moment from "moment";
import { useTranslation } from "react-i18next";

const initialSelectedData = {};
const SearchFilterWindow = ({
  setShouldRefresh,
  shouldRefresh,
  dataState,
  setDataState,
  searchTerm,
  setSearthTerm,
  capsulesFilter,
  setCapsulesFilter,
  fetchPlanUpload
}) => {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState(initialSelectedData);
  const formRef = React.useRef();

  const removeCapsuleFilter = (index) => {
    const { name: removeFieldName, value: removeFieldValue } =
      capsulesFilter[index];
    const newSelectedData = { ...selectedData };
    if (removeFieldName === "Country") {
      newSelectedData.Country = newSelectedData.Country.filter(
        (c) => c.CountryID !== removeFieldValue
      );
    } else {
      delete newSelectedData[removeFieldName];
    }
    setSelectedData(newSelectedData);
    const newCapsuleFilter = [...capsulesFilter];
    newCapsuleFilter.splice(index, 1);
    setCapsulesFilter(newCapsuleFilter);
    const params = {
      ...newSelectedData,
      page: 1,
      StartCount: 0,
      MaxCount: dataState.take,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setDataState((state) => ({ ...state, skip: 0 }))
    fetchPlanUpload(params);
  };

  const onSearchClick = async (e) => {
    const params = {
      ...selectedData,
      _dc: Date.now(),
      page: 1,
      StartCount: 0,
      MaxCount: dataState.take,
    };
    const searchTerm = e.target?.searchWorkOrder?.value
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setSearthTerm(searchTerm)
    setDataState((state) => ({ ...state, skip: 0 }))
    fetchPlanUpload(params);
  };

  const onFilterSubmitClick = async ({ values }) => {
    formRef.current.setFilterWindow(false);
    const newCapsuleFilter = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (key === "Region") {
          return acc;
        }
        if (key === "Country") {
          const countryData = value.map((c) => ({
            name: key,
            text: c.CountryName,
            value: c.CountryID,
          }));
          return [...acc, ...countryData];
        }
        if (key === "startdate" || key === "enddate") {
          value = moment(value).format("MM/DD/YYYY");
        }
        return [
          ...acc,
          {
            name: key,
            text: value,
            value,
          },
        ];
      },
      []
    );

    setCapsulesFilter(newCapsuleFilter);
    setSelectedData(values);

    const params = {
      ...values,
      _dc: Date.now(),
      page: 1,
      StartCount: 0,
      MaxCount: dataState.take,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setDataState((state) => ({ ...state, skip: 0 }))
    fetchPlanUpload(params);
  };

  useEffect(() => {
    if (shouldRefresh) {
      const params = {
        ...selectedData,
        page: 1,
        StartCount: 0,
        MaxCount: dataState.take,
      };
      if (searchTerm) {
        params.keyword = searchTerm;
      }
      setDataState((state) => {
        return { ...state, skip: 0 }
      })
      fetchPlanUpload(params);
      setShouldRefresh(false);
    }
  }, [shouldRefresh, selectedData, dataState.take, searchTerm]);

  return (
    <>
      <SearchFilterContainer>
        <SearchForm
          placeholder={
            `${t("searchby")} ${t("BatchId")} ${t("WORKORDERID")} ${t("Status")} ${t("updatedon")} ${t("updatedby")}`
          }
          onSubmitClick={onSearchClick}
        />

        <SearchFilterComp
          ref={formRef}
          onSubmitClick={onFilterSubmitClick}
          selectedData={selectedData}
          capsulesFilter={capsulesFilter}
          removeCapsuleFilter={removeCapsuleFilter}
          windowProps={{
            initialTop: 170,
            initialWidth: 1165,
            initialHeight: 200,
          }}
        >
          {(formRenderProps) => (
            <FormElement>
              <div style={{ display: "flex", gap: 15, flexWrap: "wrap" }}>
                <Field
                  name="PlanId"
                  label={t("WORKORDERID")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(33% - 15px)" }}
                  placeholder={t("WORKORDERID")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, workOrderId: e.value });
                  }}
                />
                <Field
                  name="BatchId"
                  label={t("BatchId")}
                  component={FormInput}
                  wrapperStyle={{ width: "calc(33% - 15px)" }}
                  placeholder={t("BatchId")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, batchId: e.value });
                  }}
                />
                <Field
                  name="Status"
                  label={t("Status")}
                  component={FormComboBox}
                  wrapperStyle={{ width: "calc(33% - 15px)" }}
                  placeholder={t("Status")}
                  onChange={(e) => {
                    setSelectedData({ ...selectedData, status: e.value });
                  }}
                  data={["Success", "Error", "Partial_success", "inprogress"]}
                />
              </div>
              <SearchFilterFooter handleReset={() => {
                setSelectedData(initialSelectedData);
                setTimeout(() => {
                  formRenderProps.onFormReset();
                }, 0);
              }} />
            </FormElement>
          )}
        </SearchFilterComp>
      </SearchFilterContainer>
    </>
  );
};

export default SearchFilterWindow;
