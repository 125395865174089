import { useState, useEffect, useCallback } from "react";
import Header from "../../header";
import Tabs from "../../ui/tabs";
import SearchFilterWindow from "./SearchFilterWindow";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import LoadMask from "../../../utils/LoadMask";
import { MessageBox, showToast } from "../../../utils/Notification";
import {
  isColumnActive,
} from "../../grid/CustomColumnMenu";
import {
  columns,
  columnMenuConfig,
  CellRender,
  CustomContactCell,
  CustomNameCell,
  RowRender,
} from "./column";
import {
  getAdminDetails,
  postActivateAdmin,
  postDeactivateAdmin,
  postEditAdmin,
} from "../../../store/actions/people/administrators";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { updateUserPreferenceAction } from "../../../store/actions/commonActions";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { searchUserPrefrence } from "../../../store/actions/dashboard";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";
import regionCountry from "../../../utils/regionCountry";
import { MyPager } from "../../generalHooks";

const GRID_NAME = "ADMIN_GRID";

const setOrderIndex = (columns) => {
  return columns.map((column, i) => ({ ...column, orderIndex: i }))
}

const initialColumnsState = setOrderIndex(columns);

const createDataState = (data, dataState) => {
  return {
    result: {
      data,
      take: dataState.take,
    },
    dataState: dataState,
  };
};
let initialState = createDataState([], {
  take: 20,
  skip: 0,
});
const EDIT_FIELD = "inEdit";
const DATA_ITEM_KEY = "uniqueId";

const Administrators = ({ tabsObj, activeScreen, setActiveScreen ,  screen,
  rolePepplePage}) => {
  const { handleAuthContext } = useLogout();
  const serverColumns = useSelector(
    (state) => state.commonReducer.gridUserPrefrence
  );
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState([
    {
      field: "Country",
      dir: "asc",
    },
  ]);

  const [showLoader, setShowLoader] = useState(null);
  const [searchTerm, setSearthTerm] = useState("");
  const [prevEditContent, setPrevEditContent] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [changes, setChanges] = useState(false);
  const [countryData, setCountryData] = useState({});

  const [messageProps, setMessageProps] = useState(null);
  const { t } = useTranslation();

  const spocDetails = localStorage.getItem("spocDetails")
    ? JSON.parse(localStorage.getItem("spocDetails"))
    : null;

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const [stateColumns, setStateColumns] = useState(initialColumnsState);
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || initialColumnsState);
  };

  useEffect(() => {
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStateColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);

  const handlePageChange = async (e) => {
    const dataState = e.page;
    setDataState(dataState);
    setFilter(null);
    fetchAdminDetails({ paramsDataSTate: dataState });
  };

  const handleDefaultViewClick = () => {
    onColumnsSubmit();
  };

  const handleSaveViewClick = async () => {
    const Json_Object = JSON.stringify(stateColumns);
    const params = {
      UserPrefReq: {
        Email: authContext?.SpocMail,
        Company_ID: authContext?.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: authContext?.CountryCode,
        Json_Object,
      },
    };
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast("success", t(`${res?.Response?.Message}`));
    } else {
      showToast("error", t(`${res?.Response?.Message}`) || "Error");
    }
    setShowLoader(null);
  };

  const fetchAdminDetails = async (props) => {
    const newDataState = props?.paramsDataSTate || dataState;
    const params = {
      CompanyId: authContext?.CompanyID,
      page: Math.floor(newDataState.skip / newDataState.take) + 1,
      StartCount: newDataState.skip,
      MaxCount: newDataState.take,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await getAdminDetails(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.root?.Status === "Success") {
      console.log('countryData', countryData);
      res?.root?.Spocs.map((cn) => {
        cn.Country = countryData?.[cn.Country] ?? "";
        return cn;
      });
      const newResult = {
        data: res?.root?.Spocs || [],
        total:
          res?.root.TotalCount || res?.root?.Spocs?.length || dataState.take,
      };
      setData(newResult);
    } else {
      console.log("deactivateAdmin api error", res);
    }
    setShowLoader(null);
    setEditMode(false);
  };

  useEffect(() => {
    let tempCountryList = {}
    regionCountry?.forEach(({ CountryDetails }) => {
      CountryDetails.forEach(({ CountryID, CountryName }) => {
        tempCountryList[CountryID] = CountryName
      })
    });
    setCountryData(tempCountryList);
  }, [authContext]);

  useEffect(() => {
    if (Object.keys(countryData).length) {
      fetchAdminDetails();
    }
  }, [countryData]);

  useEffect(() => {
    if (data) {
      setResult({
        data: data?.data.map((d) => {
          if (!isNaN(d?.ContactNo)) {
            d.ContactNo = parseInt(d.ContactNo) || null;
          }
          return d;
        }),
        ...data,
      });
    }
  }, [data]);

  const messagePopup = (msg, button, icon, action) => {
    const callBackFn = async (msg) => {
      setMessageProps(null);
      switch (msg + action?.type) {
        case "YESactivate": {
          const { dataItem } = action?.payload;
          const params = JSON.stringify({
            SpocDetails: {
              MailId: dataItem?.MailId, //"testbelgium101@mailinator.com",
              CompanyId: authContext?.CompanyID, //"440",
            },
          });
          const res = await postActivateAdmin(params, authContext);
          if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
          }
          showToast("success", t(`${res?.root?.Message}`));
          fetchAdminDetails();
          break;
        }
        case "YESdeactivate": {
          const { dataItem } = action?.payload;
          const params = JSON.stringify({
            root: {
              SuperAdminMail: authContext?.SpocMail, //"testuseruat3b@mailinator.com",
              AdminEmail: dataItem?.MailId, //"testbelgium101@mailinator.com",
              CompanyId: authContext?.CompanyID, //"440",
            },
          });
          const res = await postDeactivateAdmin(params, authContext);
          if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
          }
          console.log("handleDeactive", { dataItem, authContext, res });
          showToast("success", t(`${res?.root?.Msg}`));
          fetchAdminDetails();
          break;
        }
        default:
          break;
      }
    };
    setMessageProps({
      title: t("_status", "Status"),
      buttons: button,
      icon: icon,
      message: t(`_${msg}`, `${msg}`),
      fn: callBackFn,
    });
  };

  const handleDeactivateAdmin = async (e, dataItem) => {
    const { value } = e;
    if (value) {
      messagePopup("Do you want to activate admin", "YESNO", "QUESTION", {
        type: "activate",
        payload: { dataItem },
      });
    } else {
      messagePopup("Do you want to deactivate admin", "YESNO", "QUESTION", {
        type: "deactivate",
        payload: { dataItem },
      });
    }
    return;
  };

  const enterEdit = (dataItem, field) => {
    if (editMode) {
      console.log("already in edit mode");
      return;
    }
    setEditMode(true);

    let contentSnapshot;
    const newData = result?.data.map((item) => {
      const isEdit = item?.[DATA_ITEM_KEY] === dataItem?.[DATA_ITEM_KEY];
      if (isEdit) {
        contentSnapshot = item;
      }
      return {
        ...item,
        [EDIT_FIELD]: isEdit,
      };
    });
    setResult((prev) => ({ ...prev, data: newData }));
    setPrevEditContent(contentSnapshot);
  };

  const exitEdit = () => {
    setResult((result) => {
      const { total, data } = result;
      const newData = data.map((item) => ({
        ...item,
        [EDIT_FIELD]: undefined,
      }));
      return {
        data: newData,
        total: total,
      };
    });
  };

  const handleCancel = (dataItem) => {
    setChanges(false);
    setEditMode(false);
    setResult((result) => {
      const { total, data } = result;

      const newData = data.map((item) => {
        if (item?.[DATA_ITEM_KEY] === dataItem?.[DATA_ITEM_KEY]) {
          return { ...prevEditContent, [EDIT_FIELD]: undefined };
        }
        return {
          ...item,
          [EDIT_FIELD]: undefined,
        };
      });
      return {
        data: newData,
        total: total,
      };
    });
  };

  const updateAdmin = async (dataItem = {}) => {
    const { FirstName, LastName, ContactNo, MailId } = dataItem;
    const params = {
      SpocDetails: {
        FirstName,
        LastName,
        ContactNo,
        MailId,
        CompanyId: authContext?.CompanyID,
      },
    };
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await postEditAdmin(JSON.stringify(params), authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.root?.Status === "Success") {
      setEditMode(false);
      fetchAdminDetails();
      showToast("success", t(`${res?.root?.Message}`));
    } else {
      showToast(
        "error",
        res?.root?.Message ? t(`${res?.root?.Message}`) : t("Error")
      );
    }
  };

  const customRowRender = (tr, props) => {
    if (props?.isInEdit) {
      return (
        <>
          <RowRender
            originalProps={props}
            tr={tr}
            // enterEdit={enterEdit}
            exitEdit={exitEdit}
            editField={EDIT_FIELD}
          />
          <div
            style={{ backgroundColor: "red", width: "100px", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 7,
                position: "absolute",
                left: "40%",
                background: "#fff",
                padding: 8,
                paddingTop: 0,
                border: "1px solid #ccc",
                borderTop: 0,
                borderRadius: "0 0 10px 10px",
              }}
            >
              <Button
                style={{ border: "1px solid #ccc", flex: 1 }}
                onClick={() => updateAdmin(props?.dataItem)}
                disabled={!changes}
              >
                Update
              </Button>
              <Button
                type="reset"
                style={{ border: "1px solid #ccc", flex: 1 }}
                onClick={() => handleCancel(props?.dataItem)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </>
      );
    }
    return (
      <RowRender
        originalProps={props}
        tr={tr}
        // enterEdit={enterEdit}
        exitEdit={exitEdit}
        editField={EDIT_FIELD}
      />
    );
  };

  const customCellRender = (td, props) => {
    const { dataItem, field } = props;
    switch (field) {
      case "ActiveFlag": {
        const isActive = dataItem?.ActiveFlag === "1";
        return (
          <>
            <td
              style={{ textAlign: "center" }}
              title={isActive ? "Click to deactivate" : "Click to activate"}
            >
              {console.log("ActiveFlagAdmin", result?.data.some((el) => el.MailId !== spocDetails.SpocMail))}
              {dataItem?.MailId !== spocDetails.SpocMail ? (
                <Switch
                  checked={isActive}
                  onChange={(e) => handleDeactivateAdmin(e, dataItem)}
                  disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
                />
              ) : (
                ""
              )}
            </td>
          </>
        );
      }
      case "FirstName":
      case "LastName": {
        return (
          <td>
            <CustomNameCell {...props} />
          </td>
        );
      }
      case "ContactNo": {
        return (
          <td style={{ textAlign: "right" }}>
            <CustomContactCell {...props} />
          </td>
        );
      }
      default:
        return (
          <CellRender
            originalProps={props}
            td={td}
            editField={EDIT_FIELD}
          />
        );
    }
  };

  const itemChange = (event) => {
    let field = event.field || "";
    const { value, dataItem } = event;
    let newData = result?.data.map((item) => {
      if (item?.[DATA_ITEM_KEY] === dataItem?.[DATA_ITEM_KEY]) {
        item[field] = value;
      }
      return item;
    });
    setResult((prev) => ({ ...prev, data: newData }));
    setChanges(true);
  };

  const CreatePager = useCallback((props) => (
    <MyPager
      {...props}
      style={{
        margin: '5px 0px',
      }}
      onSaveViewClick={handleSaveViewClick}
      onDefaultViewClick={handleDefaultViewClick}
    >
      <div style={{ flex: 1 }}></div>
    </MyPager>
  ), [stateColumns]);

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}
      <Header activeMenu={t("resourcemanagement")}>
        <div style={{ display: "flex" }}>
          <Tabs
            tabs={tabsObj}
            selected={activeScreen}
            onSelect={(key) => setActiveScreen(key)}
          >
            {({ title }) => {
              return <div>{title}</div>;
            }}
          </Tabs>
        </div>
      </Header>
      <SearchFilterWindow
        dataState={dataState}
        setDataState={setDataState}
        setResult={setData}
        setSearthTerm={setSearthTerm}
        countryData={countryData}
      />

      <div
        style={{
          display: "flex",
          height: "100%",
          padding: "1em",
          paddingTop: 0,
          overflow: "hidden",
        }}
      >
        <Grid
          className="people_grid"
          data={result}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            console.log("sort", { e });
            setResult((result) => {
              const { total } = result;
              return {
                data: orderBy(data?.data || [], e.sort),
                total,
              };
            });
            setSort(e.sort);
          }}
          pageable={{
            pageSizes: true,
          }}
          onPageChange={handlePageChange}
          filter={filter}
          onFilterChange={(e) => {
            setResult((result) => {
              const { total } = result;
              return {
                data: filterBy(data?.data || [], e.filter),
                total,
              };
            });
            setFilter(e.filter);
          }}
          pager={CreatePager}
          onRowDoubleClick={({ dataItem }) => {
            if(rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.updateFlag == 'N' ? true : false ) : true){
              showToast("warning","Data updates are reserved for admins only.")
            }else{
              enterEdit(dataItem);
            }
          }}
          onItemChange={itemChange}
          cellRender={customCellRender}
          rowRender={customRowRender}
          editField={EDIT_FIELD}
          dataItemKey={DATA_ITEM_KEY}
          resizable
          reorderable
          onColumnReorder={({ columns }) => {
            const columnOrder = columns.reduce((columnsObj, column) => {
              columnsObj[column.field] = column.orderIndex;
              return columnsObj;
            }, {})
            const newColumns = stateColumns.map((column) => {
              return { ...column, orderIndex: columnOrder[column.field] }
            });
            console.log({ stateColumns, newColumns, columnOrder }, "order")
            setStateColumns(newColumns);
          }}
        >
          {stateColumns?.map((column, idx) => {
            const menu = columnMenuConfig[column?.columnMenu];
            return (
              column.show && (
                <GridColumn
                  key={column?.orderIndex}
                  field={column.field}
                  title={t(column.title)}
                  editable={column.editable}
                  filter={column.filter}
                  orderIndex={column?.orderIndex}
                  {...(menu && {
                    columnMenu: (props) => {
                      return menu?.({
                        ...props,
                        columns: stateColumns,
                        onColumnsSubmit,
                        data: data?.data,
                      });
                    },
                  })}
                  headerClassName={
                    isColumnActive(column.field, { ...dataState, filter })
                      ? "activeFiltered"
                      : ""
                  }
                />
              )
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default Administrators;
