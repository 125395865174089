const UserProfileIcon = (props) => {
    return (
        <svg style={props?.style} width={props?.width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="11.3" stroke={props?.color ?? "#4F4F4F"} strokeWidth="1.4" />
            <path d="M20.9 24V24.1H21H22H22.1V24V20C22.1 17.7356 20.2644 15.9 18 15.9H14C11.7356 15.9 9.9 17.7356 9.9 20V24V24.1H10H11H11.1V24V20C11.1 18.3984 12.3984 17.1 14 17.1H18C19.6016 17.1 20.9 18.3984 20.9 20V24Z" fill={props?.color ?? "#4F4F4F"} stroke={props?.color ?? "#4F4F4F"} strokeWidth="0.2" />
            <path d="M19.8 10.5275C19.8 12.6261 18.0987 14.3275 16 14.3275C13.9013 14.3275 12.2 12.6261 12.2 10.5275C12.2 8.42878 13.9013 6.72746 16 6.72746C18.0987 6.72746 19.8 8.42878 19.8 10.5275Z" stroke={props?.color ?? "#4F4F4F"} strokeWidth="1.4" />

        </svg>
    )
}

export default UserProfileIcon