import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";
import ConverageScreenUploadJson from "./coverageScreenUploadMock.json"
const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid:authContext?.CompanyID || ""
  // companyid: "381",
});
const getHeadersPost = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid:authContext?.CompanyID || "",
  "Content-Type": "application/json"
  // companyid: "381",
});
export const getCoverageScreen = async (params, authContext) => {
  const res = await Get(
    AppURL.coverageScreen,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("coverage screen", { res });
  // return bulkUpload;
  return res;
};

export const postCoverageScreen = async (params, authContext) => {
  const res = await Post(
    AppURL.coverageScreen,
    params,
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());
  return res;
};

export const postCoverageScreenStore = async (params, authContext) => {
  const res = await Post(
    AppURL.CoverageScreenUpdStore,
    params,
    getHeadersPost(authContext),
    true
  ).then((res) => res.json());
  return res;
};

export const getCoverageScreenUpload = async (params, authContext) => {
  const res = await Get(
    AppURL.coverageScreenUpload,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("coverage screen upload", { res });
  // return ConverageScreenUploadJson;
  return res;
};

export const coverageScreenDownloadStore = async (params, authContext) => {
  const headers = {
    OrganizationUnit: authContext?.organizationUnit || "",
    userid: authContext?.SpocMail || "",
    "Content-Type": "application/json",
    // "Content-Type": "application/x-www-form-urlencoded; application/json",
  };
  const res = await Get(AppURL.CoverageScreenDownloadStore, params, headers, true);
  console.log("file download in planupload", { res });
  // return searchPlan;
  return res;
};

export const csDownloadErrorFile = async (params, authContext) => {
  const headers = {
    OrganizationUnit: authContext?.organizationUnit || "",
    userid: authContext?.SpocMail || "",
    "Content-Type": "application/json",
    // "Content-Type": "application/x-www-form-urlencoded; application/json",
  };
  const res = await Get(AppURL.csDownloadErrorFile, params, headers, true);
  console.log("file download in planupload", { res });
  // return searchPlan;
  return res;
};