import { Get } from "../../methods";
import AppURL from "../../../utils/AppURL";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid: authContext?.CompanyID || "",
});

export const getPreviousJob = async (params, authContext) => {
  const res = await Get(
    AppURL.previousjobs,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};
export const getCopyPlanService = async (params, authContext) => {
  const res = await Get(
    AppURL.copyplanservice,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};
export const getNielsenStarEndDates = async (params, authContext) => {
  const res = await Get(
    AppURL.GetNielsenStarEndDates,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  return res;
};

