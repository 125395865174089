const serverURL = "/api";
let hostname = window.location.hostname;

const cdarapigateway = () => {
  let hostname = window.location.hostname;
  //hostname = "uat.operations.nielseniq.io";
  if (hostname === "localhost") {
    // return "/selfcollect/cdarapigateway";
    // return window.location.origin;
    return "/cdarapigateway";
  } else if (hostname === "operations.nielseniq.com" || hostname === "cdarus.nielseniq.com") {
    return "/cdarapigateway";
  } else if (hostname === "operationsuk.nielseniq.com" || hostname === "cdaruk.nielseniq.com") {
    return "/selfcollect/cdarapigateway/eu";
  } else if (hostname === "operationshk.nielseniq.com" || hostname === "cdarhk.nielseniq.com") {
    return "/selfcollect/cdarapigateway/ap";
  }
  // else if (hostname === "navigator.nielseniq.com") {
  //   return "/cdarapigateway/na";
  // } else if (hostname === "navigator2.nielseniq.com") {
  //   return "/cdarapigateway/eu";  
  // }
  else {
    return `https://${hostname}`
    // return "/cdarapigateway";
    // return "https://uat3.navigator.nielseniq.io";
  }
};

const cdarapigatewaynav = () => {
  let hostname = window.location.hostname;
  if (hostname === "cdarnavigator.nielseniq.com") {
    return "/cdarapigateway/na";
  } else if (hostname === "cdarnavigator2.nielseniq.com") {
    return "/cdarapigateway/eu";
  } else {
    return "/cdarapigateway";
  }
};

const ecollectionversion = () => {
  let hostname = window.location.hostname;

  if (hostname === "localhost") {
    return "";
  } else if (hostname === "navigator.nielseniq.com" || hostname === 'cdarnavigator.nielseniq.com') {
    return "/cdarapigateway/na";
  } else if (hostname === "navigator2.nielseniq.com" || hostname === 'cdarnavigator2.nielseniq.com') {
    return "/cdarapigateway/eu";
  } else {
    return `https://${hostname}/cdarapigateway`;
  }
};

let ecollectioncloudbbecuisvcurl = ""
if (hostname === 'navigator.nielseniq.com' || hostname === 'cdarnavigator.nielseniq.com') {
  ecollectioncloudbbecuisvcurl = '/cdarapigateway/na'
} else if (hostname === 'navigator2.nielseniq.com' || hostname === 'cdarnavigator2.nielseniq.com') {
  ecollectioncloudbbecuisvcurl = '/cdarapigateway/eu'
} else {
  ecollectioncloudbbecuisvcurl = "/cdarapigateway"
}
let nsoProd2Url = ""
if (hostname === 'navigator2.nielseniq.com' || hostname === 'cdarnavigator2.nielseniq.com') {
  nsoProd2Url = '/cdarapigateway/eu'
} else {
  nsoProd2Url = "/cdarapigateway"
}
let dctProd5Url = ""
if (hostname === 'navigator.nielseniq.com' || hostname === 'cdarnavigator.nielseniq.com') {
  dctProd5Url = '/nav'
}

const gnicUrls = () => {
  let hostname = window.location.hostname;
  //hostname = "uat.operations.nielseniq.io";
  if (hostname === "localhost") {
    // return "/selfcollect/cdarapigateway";
    // return window.location.origin;
    return "/gnic-user";
  } else if (hostname === "navigator.nielseniq.com" || hostname === "navigator2.nielseniq.com" || hostname === "cdarnavigator.nielseniq.com" || hostname === "cdarnavigator2.nielseniq.com") {
    return "/gnic";
  } else {
    return "/gnic-user";
  }
}


const navigatornrpsservice = () => {

  let hostname = window.location.hostname;

  if (hostname === "localhost") {
    return "/navigatornrpsservice";
  } else if (hostname === "operations.nielseniq.com" || hostname === "cdarus.nielseniq.com") {
    return "/cdarapigateway";
  } else if (hostname === "operationsuk.nielseniq.com" || hostname === "cdaruk.nielseniq.com") {
    return "/selfcollect/cdarapigateway/eu";
  } else if (hostname === "operationshk.nielseniq.com" || hostname === "cdarhk.nielseniq.com") {
    return "/selfcollect/cdarapigateway/ap";
  } else if (hostname === "navigator.nielseniq.com" || hostname === "cdarnavigator.nielseniq.com") {
    return "/cdarapigateway/na/navigatornrpsservice";
  } else if (hostname === "navigator2.nielseniq.com" || hostname === "cdarnavigator2.nielseniq.com") {
    return "/cdarapigateway/eu/navigatornrpsservice";
  } else {
    return `https://${hostname}/cdarapigateway/navigatornrpsservice`
    // return "https://uat3.rms.cdar.nielseniq.io/navigatornrpsservice";  
  }
};

const tickerUrl = () => {
  let hostname = window.location.hostname;

  if (hostname === "localhost") {
    return "/cdarapigateway";
  } else if (hostname === "navigator.nielseniq.com" || hostname === "cdarnavigator.nielseniq.com") {
    return "/nav";
  } else if (hostname === "navigator2.nielseniq.com" || hostname === "cdarnavigator2.nielseniq.com") {
    return "";
  } else {
    return `https://${hostname}`;
  }
};

const iqctUrl = () => {
  let hostname = window.location.hostname;

  if (hostname === "localhost") {
    return "https://uat3.navigator.nielseniq.io"
  }
  return ""
}

const AppURL = {
  //login
  login: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/login",
  vendorlistData: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/vendorlist",
  forgotPasswordURL:
    cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/forgotpwdsecqusdisplay",
  forgotPasswordValidationURL:
    cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/forgotpwdvalidatesecqa",
  resetPasswordURL: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/forgotpassword",
  validateSessionURL: cdarapigateway() + cdarapigatewaynav() + `/navigatorcommonservice/fmsaud/report/validatesession`,
  getcaptcha: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/getcaptcha",

  //language
  languageURL: cdarapigateway() + "/bw/gnsvc/lang",

  //logout
  logoutURL: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/logout",

  //User Profile
  fetchuserprofileURL: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/fetchuserprofile",
  changepasswordURL: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/changepassword",
  updateuserprofile: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/updateuserprofile",
  fetchuserprofileRolesURL: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/getPermissions",


  //API
  onRegistrationClick: cdarapigateway() + "/fmsaud/onRegistrationClick",

  // getpermissions: "/gnsvc/getpermissions",
  getpermissions: "/jgnsvc/getpermissions",
  lang: "/gnsvc/lang",

  // Get the country details of a company
  getCountryDetails: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/registration/countryDetails",

  //Dashboard
  dashboardURL: cdarapigateway() + cdarapigatewaynav() + "/navigatorcommonservice/fmsaud/dashboard",
  searchUserPrefrence: cdarapigateway() + cdarapigatewaynav() + "/navigatoruserauthservice/fmsaud/searchUserPrefrence",
  downtime: cdarapigateway() + cdarapigatewaynav() + '/navigatorcommonservice/fmsaud/tickers',
  // https://uat3.navigator.nielseniq.iofmsaud/searchUserPrefrence
  //*********************************************REPORTS******************************************/
  nrpsreportfilters:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSReportFilters",
  nrpsreportstoresinfo:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSStoreStoresInfo",
  nrpsreportfilterssearch:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSStorePlanReport",
  nrpsreportplanclick:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSStorePlanReport",
  nrpsreportprogressclick:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSStoreProgressReportDetails",
  nrpsreportkpiclick:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSStoreKPIReportDetails",
  nrpsreportauditorclick:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSStoreProgressReportDetails",
  nrpsreportexport:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSReportExport",
  nrpsstorestatusupdate:
    cdarapigateway() +
    "/cdarapigateway/ecollections-ui/bbecuisvc/NRPSStoreStatusUpdate",
  // nrpsreportfilters : "https://uat3.rms.cdar.nielseniq.io/ecollections-ui/bbecuisvc/NRPSReportFilters",
  // nrpsreportfilters: "http://uat3.navigator.nielseniq.io/cdarapigateway/ecollections-ui/bbecuisvc/NRPSReportFilters",
  // nrpsreportfilters:"/bbecuisvc/getPromotionDetails",

  //Messages
  getMailsUrl: cdarapigateway() + cdarapigatewaynav() + "/navigatorcommonservice/fmsaud/inbox/getMails",
  getMailBodyUrl: cdarapigateway() + cdarapigatewaynav() + "/navigatorcommonservice/fmsaud/inbox/getMailBody",
  updateMailStatusUrl: cdarapigateway() + cdarapigatewaynav() + "/navigatorcommonservice/fmsaud/inbox/updateInboxStatus",

  // // header app url
  // countryList: "qctiapp/gethubcountrylist",
  // lobList: "qctiapp/getlobdetails",
  // auditorsList: "qctapp/getassignedauditors",

  // ALERT LIST
  // getAlertList: "qctapp/getalerts",
  // updateAlert: "qctapp/updatealert",
  // getAlertList: "cdarapigateway/iqctwebuiservice/getalerts",
  // getDataChangeValues: "qctapp/getdatachangevalues",
  // getBlockInfo: "qctiapp/getBlockInfo",
  // getAuditorLocation: "qctapp/getauditorlocation",
  // getRESStoreStatus: "qctapp/getRESStoreStatus",

  // Dashboard
  // getGeoCoordinates:"/qctiapp/getgeocoordinates",
  // getAddress:"/qctiapp/GetAddress",
  // geoURL:"https://www.arcgis.com/sharing/rest/oauth2/token/",
  // geoClId: "7DiazIrs6Y5XmBnc",
  // geoClSc: "0319a1a54c0f46e98b31d9d6437829e6",

  // getallauditorlocation: "qctapp/getallauditorlocation",
  // getallplannedstores: "qctapp/getallplannedstores",
  // getDefaultTown: "/qctapp/getdefaulttown",
  // getPlannedStores: "qctapp/getplannedstores",
  // trackAuditor:"/qctapp/getauditorlocation",
  // getStoreProfile: "/qctapp/getstoreprofile",

  // Alerts Customization
  // getAlertsCustomList: "qctiapp/getcountrylevelalerts",
  // getCountries: "gnsvc/rschierarchy",
  // UpdateCountryLevelAlerts: "qctiapp/UpdateCountryLevelAlerts",
  // SOS
  // getSos: "qctapp/getsos",
  // getSosDetails: "qctapp/getsosdetails",

  // countryEnablement
  // getCountryEnablementData: "qctapp/getcountrylevel",

  //WORK ORDERS > WORK ORDERS
  searchPlan: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/searchplan",
  // http://localhost:3000fmsaud/searchplan?CompanyID=381&_dc=1668161222807&page=1&StartCount=0&MaxCount=50
  checkLockStatus: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/checkLockStatus",
  // https://uat3.navigator.nielseniq.io/fmsaud/checkLockStatus?CompanyId=377&JobId=16048&User=smaddi1234@mailinator.com&_dc=1675768253291

  lockuser: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/lockuser",
  // https://uat3.navigator.nielseniq.io/fmsaud/lockuser?JobId=15455&LockStatus=0&User=smaddi1234@mailinator.com

  GetNielsenStarEndDates: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/GetNielsenStarEndDates",
  // https://uat3.navigator.nielseniq.io/fmsaud/GetNielsenStarEndDates?job_id=1674628371614&_dc=1675846087765

  planUpload: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/planupload",
  // https://uat3.navigator.nielseniq.iofmsaud/planupload?_dc=1668407758081&page=1&StartCount=0&MaxCount=50
  previousjobs: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/previousjobs",
  // https://uat3.navigator.nielseniq.io/fmsaud/previousjobs?CollectionType=NSO&CountryID=76&CompanyId=377&WeekNo=6&CurrentJobID=16048&_dc=1675696734617&page=1&StartCount=0&MaxCount=20

  copyplanservice: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/copyplanservice",
  // https://uat3.navigator.nielseniq.io/fmsaud/copyplanservice?CurrentJobId=15532&CollectionType=NSO&PreviousJobId=15538&user=smaddi1234@mailinator.com&CompanyId=377&CountryId=76&Overwrite=false&_dc=1675661273450

  // REPLAN VALIDATION
  getstaged: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/PlanUpload/getstaged",
  // https://uat3.navigator.nielseniq.io/fmsaud/PlanUpload/getstaged?JobId=1675674170714&_dc=1675865420713

  saveorvalidate: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/PlanUpload/saveorvalidate",
  // https://uat3.navigator.nielseniq.io/fmsaud/PlanUpload/saveorvalidate?Action=VALIDATE&JobId=1675838318594&User=alekhtestspoc@mailinator.com&CompanyId=406

  updateautomaticupcflag: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/updateautomaticupcflag",
  // https://uat3.navigator.nielseniq.io/fmsaud/updateautomaticupcflag?Jobid=16040&Collectiontype=NSO&UPCAssignment=Y

  updateUserPreference: cdarapigateway() + cdarapigatewaynav() + '/navigatoruserauthservice/fmsaud/updateUserPreference',
  // https://uat3.navigator.nielseniq.iofmsaud/updateUserPreference

  coverageScreen: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/coverageScreen",
  // https://uat3.navigator.nielseniq.iofmsaud/CoverageScreen

  saveStorePlan: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/loadplan",
  // https://uat3.navigator.nielseniq.io/fmsaud/loadplan

  downloadPlan: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/downloadplan",
  // https://uat3.navigator.nielseniq.io/fmsaud/downloadplan?Jobid=16043&CountryId=170,188,484,76&CompanyId=377&CollectionType=NSO&Action=Plan

  nrpsSubmitPlan: navigatornrpsservice() + "/fmsaud/nrps/submit",

  coverageScreenUpload:
    cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/CoverageScreenUpload/GetUploadDtls",
  // https://uat3.navigator.nielseniq.iofmsaud/CoverageScreenUpload/GetUploadDtls?CompanyId=440&_dc=1669352501317&page=1&StartCount=0&MaxCount

  ProcessUploadFile:
    cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/CoverageScreenUpload/ProcessUploadFile",
  // https://uat3.navigator.nielseniq.io/fmsaud/CoverageScreenUpload/ProcessUploadFile?FileName=auditor12.xlsx&CompanyId=377&UserId=smaddi1234@mailinator.com

  planDownloaderfile: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/planupload/downloaderrfile",
  // https://uat.cdarapi.nielseniq.io/navfmsaud/planupload/downloaderrfile?BatchId=247&_dc=1670226224685
  fetchauditors: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/fetchauditors",
  // https://uat3.navigator.nielseniq.iofmsaud/fetchauditors?Countryid=170,188,484,76&Companyid=377&_dc=1670324278204&page=1&start=0&limit=25
  CoverageScreenUpdStore:
    cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/CoverageScreen/UpdStoreDetails",
  // https://uat3.navigator.nielseniq.iofmsaud/CoverageScreen/UpdStoreDetails
  CoverageScreenDownloadStore:
    cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/CoverageScreenUpload/DownloadStore",
  // https://uat3.navigator.nielseniq.iofmsaud/CoverageScreenUpload/Downloadstore?CompanyId=440&Action=UNASSIGNED&Countryid=56&Collectiontype=DCT

  csDownloadErrorFile:
    cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/CoverageScreenUpload/DownloadErrorFile",
  // https://uat3.navigator.nielseniq.iofmsaud/CoverageScreenUpload/DownloadErrorFile

  GetSearchParametersOFPlan:
    cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/GetSearchParametersOFPlan",
  // https://uat3.navigator.nielseniq.iofmsaud/GetSearchParametersOFPlan?companyid=377&_dc=1674116354109&page=1&start=0&limit=25

  //Action Work Order
  fetchPlanUrl: cdarapigateway() + cdarapigatewaynav() + '/navigatorworkorderservice/fmsaud/fetchplan',
  recollect: cdarapigateway() + cdarapigatewaynav() + '/navigatorworkorderservice/fmsaud/recollect',

  // ##########################
  // PEOPLE #############################
  // ################

  //## ADMINISTRATOR ##

  fetchAdminDetails: cdarapigateway() + cdarapigatewaynav() + "/navigatoradminservice/fmsaud/admin/fetchAdminDetails",
  // https://uat3.navigator.nielseniq.iofmsaud/admin/fetchAdminDetails?CompanyId=440&_dc=1670506199885
  editAdmin: cdarapigateway() + cdarapigatewaynav() + "/navigatoradminservice/fmsaud/admin/editAdmin",

  deactivateAdmin: cdarapigateway() + cdarapigatewaynav() + "/navigatoradminservice/fmsaud/admin/deactivateAdmin",
  // https://uat3.navigator.nielseniq.iofmsaud/admin/deactivateAdmin?
  activateAdmin: cdarapigateway() + cdarapigatewaynav() + "/navigatoradminservice/fmsaud/admin/activateAdmin",
  // https://uat3.navigator.nielseniq.iofmsaud/admin/activateAdmin?

  // BULK UPLOADS
  fetchauditordetails: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/fetchauditordetails",
  // https://uat3.navigator.nielseniq.iofmsaud/fetchauditordetails?_dc=1670592136014&keyword=&AuditorDetailsReq=%7B%22AuditorDetailsReq%22%3A%7B%22CountryId%22%3A%22170%2C188%2C484%2C76%22%2C%22userid%22%3A%22smaddi1234%40mailinator.com%22%7D%7D&page=1&StartCount=0&MaxCount=25

  exportRecords: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/exportrecords",
  // https://uat3.navigator.nielseniq.iofmsaud/exportrecords?Batchid=20576&FileName=Archive_Auditor_20576_Success.csv
  exportTemplate: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/exporttemplate",
  // https://uat3.navigator.nielseniq.iofmsaud/exporttemplate?CountryId=56&UploadType=FMS_AUDITOR&userId=testuseruat3a@mailinator.com

  loadAuditors: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/loadauditors",

  //## RESOURCE ##

  searchAuditorNew: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/searchauditornew",
  // https://uat3.navigator.nielseniq.iofmsaud/searchauditornew?COUNTRY=170,188,484,76&STATUS=ACTIVE&COMPANYID=377&_dc=1670911887055

  getAllCompanyAuditors:
    cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/auditor/getAllCompanyAuditors",
  // https://uat3.navigator.nielseniq.iofmsaud/auditor/getAllCompanyAuditors?companyid=440&countryid=56&status=ACTIVE
  fetchResourceDetails: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/fetchResourceDetails",
  // https://uat3.navigator.nielseniq.iofmsaud/fetchResourceDetails?COMPANYID=377&RESOURCEID=1&_dc=1671008877185,
  updateauditorstatus: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/updateauditorstatus",
  // https://uat3.navigator.nielseniq.iofmsaud/updateauditorstatus

  resetRegistration: cdarapigateway() + "/rrfsvc/resetregistration",
  // resetRegistration: cdarapigateway() + "/rscsvc/resetregistration",
  // https://uat3.navigator.nielseniq.io/rscsvc/resetRegistration
  auditorremap: cdarapigateway() + cdarapigatewaynav() + "/navigatorworkorderservice/fmsaud/auditorremap",
  // https://uat3.navigator.nielseniq.iofmsaud/auditorremap?oldauditor=381111&newauditor=381783878&companyid=381&user=belgium_100@mailinator.com&startdate=12/22/2022&enddate=12/23/2022&_dc=1671519267001

  getcollectiontypes: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/getcollectiontypes",
  // https://uat3.navigator.nielseniq.iofmsaud/getcollectiontypes?CountryId=170,188,484,76&CompanyId=377&_dc=1671626348055&page=1&start=0&limit=25

  auditor: cdarapigateway() + cdarapigatewaynav() + "/navigatorauditorservice/fmsaud/auditor",
  createAdmin: cdarapigateway() + cdarapigatewaynav() + "/navigatoradminservice/fmsaud/admin/createAdmin",
  // https://uat3.navigator.nielseniq.iofmsaud/auditor

  state: cdarapigateway() + "/bw/gnsvc/state",
  // https://uat3.navigator.nielseniq.io/bw/gnsvc/state?_dc=1672035120225&CountryID=56&page=1&start=0&limit=25


  // NIC Report with WAF
  exportReactiveReoprt: cdarapigateway() + ecollectioncloudbbecuisvcurl + gnicUrls() + "/gnicuserservice/rest/exportReactiveReport",
  // exportReactiveReoprt:cdarapigateway() + ecollectioncloudbbecuisvcurl + "/gnic-user/gnicuserservice/rest/exportReactiveReport",

  //NIC Report

  // NIC - Reactive Coding Report
  getReactiveReport: cdarapigateway() + ecollectioncloudbbecuisvcurl + gnicUrls() + "/gnicuserservice/rest/getReactiveReport",
  getReactiveGraphReport: cdarapigateway() + ecollectioncloudbbecuisvcurl + "/ProcessSenchaGNICRequest/getTrendReportData",
  // exportReactiveReoprt:cdarapigateway() + "/cdarapigateway/gnic-user/gnicuserservice/rest/exportReactiveReoprt",

  // proactive
  getProactiveReport: cdarapigateway() + ecollectioncloudbbecuisvcurl + gnicUrls() + "/gnicuserservice/rest/getProactiveReport",

  exportProactiveReoprt: cdarapigateway() + ecollectioncloudbbecuisvcurl + gnicUrls() + "/gnicuserservice/rest/exportProactiveReport",

  // Item Details Report
  getNICItemReport: cdarapigateway() + ecollectioncloudbbecuisvcurl + gnicUrls() + "/gnicuserservice/rest/getNICItemReport",
  // https://uat3.navigator.nielseniq.io/cdarapigateway/gnic-user/gnicuserservice/rest/getNICItemReport?Request=%7B%22NICItemDetailReportRequest%22%3A%7B%22FIlterParams%22%3A%7B%22Region%22%3A%221%22%2C%22Country%22%3A%22840%22%2C%22CollectionType%22%3A%22GNIC%22%2C%22Year%22%3A%222023%22%2C%22Week%22%3A%222%22%2C%22WorkOrderID%22%3A%2215735%22%2C%22NavigatorAuditorID%22%3A%22%22%2C%22StoreID%22%3A%22%22%2C%22CodingType%22%3A%22Y%22%2C%22Barcode%22%3A%22004900000124%22%2C%22StatusCode%22%3A%22Image%20Coded%22%2C%22CompanyID%22%3A%22419%22%7D%7D%7D&page=1&StartCount=0&MaxCount=50

  exportNICItemDetail: cdarapigateway() + ecollectioncloudbbecuisvcurl + gnicUrls() + "/gnicuserservice/rest/exportNICItemDetail",
  // https://uat3.navigator.nielseniq.io/cdarapigateway/gnic-user/gnicuserservice/rest/exportNICItemDetail?Request={"NICItemDetailReportRequest":{"FIlterParams":{"Region":"1","Country":"840","CollectionType":"GNIC","Year":"2023","Week":"9","WorkOrderID":"","NavigatorAuditorID":"","StoreID":"","CompanyID":"419"}}}&_dc=1677768847189
  getJobId: cdarapigateway() + ecollectioncloudbbecuisvcurl + gnicUrls() + "/gnicuserservice/rest/v1/getJobIdList",


  //DCT Report
  // getNavigatorReportBasic: {cdarapigateway}"dctexport/getNavigatorReportBasic",
  getNavigatorReportBasic: cdarapigateway() + dctProd5Url + "/dctexport/getNavigatorReportBasic",

  getDCTTableBasic: cdarapigateway() + dctProd5Url + '/dctexport/getNavigatorReportList',

  getDCTExportData: cdarapigateway() + dctProd5Url + "/dctexport/getNavigatorExport",

  //NSO > Audit Summary Report - WAF

  getAuditSummaryReport: cdarapigateway() + nsoProd2Url + "/isv-pcv-web/nso/fs/auditsummaryreport/auditSumBasicFilter",

  getAuditSummarySearchResult: cdarapigateway() + nsoProd2Url + '/isv-pcv-web/nso/fs/auditsummaryreport/auditSummarySearchResult',

  getAuditSummaryExportData: cdarapigateway() + nsoProd2Url + "/isv-pcv-web/nso/fs/auditsummaryreport/exportReport",

  //NSO > Time Stamp Report - WAF

  getTimeStampBasicFilter: cdarapigateway() + nsoProd2Url + "/isv-pcv-web/nso/fs/timestampreport/timestampBasicFilter",

  getTimeStampSearchResult: cdarapigateway() + nsoProd2Url + '/isv-pcv-web/nso/fs/timestampreport/timestampSearchResult',

  getTimeStampExportData: cdarapigateway() + nsoProd2Url + "/isv-pcv-web/nso/fs/timestampreport/timestampReportExport",
  // //NSO > Time Stamp Report

  // getTimeStampBasicFilter: cdarapigateway() +`/isv-pcv-web/nso/fs/timestampreport/timestampBasicFilter`,

  // getTimeStampSearchResult: cdarapigateway() +`/isv-pcv-web/nso/fs/timestampreport/timestampSearchResult`,

  // getTimeStampExportData: cdarapigateway() +`/isv-pcv-web/nso/fs/timestampreport/timestampReportExport`,
  // getAuditSummaryExportData: `/nso/fs/auditsummaryreport/exportReport`,
  // //NSO > Time Stamp Report
  // getTimeStampBasicFilter: `/nso/fs/timestampreport/timestampBasicFilter`,
  // getTimeStampSearchResult: `/nso/fs/timestampreport/timestampSearchResult`,
  // getTimeStampExportData: `/nso/fs/timestampreport/timestampReportExport`,

  //Miscellaneous

  getApplicationData: ecollectionversion() + '/common-auth-service/navigatordata/mobile/report/filter',
  getApplicationName: ecollectionversion() + '/common-auth-service/navigatordata/mobile/reports/applicationname',
  getMobileSummaryReport: ecollectionversion() + '/common-auth-service/navigatordata/mobile/reports/pagination',
  getMobileSummaryExportReport: ecollectionversion() + '/common-auth-service/navigatordata/mobile/reports/csv',
  getMobileVersionReport: cdarapigateway() + cdarapigatewaynav() + '/navigatorcommonservice/fmsaud/fieldmonitoring',

  getMobileVersionExportData: cdarapigateway() + cdarapigatewaynav() + '/navigatorcommonservice/fmsaud/fieldmonitoring/downloadFieldMonitoring',

  //eCollectionSummary Report

  getEcollectionSummary: cdarapigateway() + "/cdarapigateway/ecollections-ui/bbecuisvc/getSummaryReport",

  downloadguidelines: cdarapigateway() + cdarapigatewaynav() + "/navigatoradminservice/fmsaud/downloadguidelines",
  // https://uat3.navigator.nielseniq.iofmsaud/downloadguidelines

  getExecutionQualityTable: cdarapigateway() + "/cdarapigateway/ecollections-ui/bbecuisvc/getSLAReport",
  // getExecutionQualityTable: cdarapigateway() + ecollectioncloudbbecuisvcurl + "/ecollections-ui/bbecuisvc/getSLAReport",

  getFetchStudiesReport: cdarapigateway() + '/cdarapigateway/ecollections-ui/bbecuisvc/v1/fetchStudies',
  // getFetchStudiesReport: cdarapigateway() + ecollectioncloudbbecuisvcurl + '/ecollections-ui/bbecuisvc/fetchStudies',

  getFetchStoresReport: cdarapigateway() + "/cdarapigateway/ecollections-ui/bbecuisvc/fetchStores",
  // getFetchStoresReport: cdarapigateway() + "cdarapigateway/ecollections-ui/bbecuisvc/fetchStores",

  getScanImageTable: cdarapigateway() + "/cdarapigateway/ecollections-ui/bbecuisvc/scanImageReport",
  // getScanImageTable: cdarapigateway() + ecollectioncloudbbecuisvcurl + "/ecollections-ui/bbecuisvc/scanImageReport",

  // Flow-Control Report

  getAuditStatus: cdarapigateway() + '/cdarapigateway/ecollections-ui/bbecuisvc/getAuditStatus',
  // getAuditStatus: cdarapigateway() + ecollectioncloudbbecuisvcurl + '/ecollections-ui/bbecuisvc/getAuditStatus',

  getFlowControlTable: cdarapigateway() + "/cdarapigateway/ecollections-ui/bbecuisvc/flowControlReport",
  // getFlowControlTable: cdarapigateway() + ecollectioncloudbbecuisvcurl + "/ecollections-ui/bbecuisvc/flowControlReport",

  getSummaryReport: cdarapigateway() + "/cdarapigateway/ecollections-ui/bbecuisvc/getSummaryReport",
  // getSummaryReport: cdarapigateway() + ecollectioncloudbbecuisvcurl + "/ecollections-ui/bbecuisvc/getSummaryReport",

  getExportData: cdarapigateway() + "/cdarapigateway/ecollections-ui/bbecuisvc/v1/Reports",

  //UpcWindow

  getUpcCountsData: cdarapigateway() + cdarapigatewaynav() + '/navigatorworkorderservice/fmsaud/upc/getcounts',

  getSaveCounts: cdarapigateway() + cdarapigatewaynav() + '/navigatorworkorderservice/fmsaud/upc/savecounts',

  //Export for Execution Quality

  getExecutionQualityExportData: cdarapigateway() + "/cdarapigateway/ecollections-ui/bbecuisvc/v1/GetSLAReportDownload",
  // getExecutionQualityExportData:cdarapigateway() + ecollectioncloudbbecuisvcurl + "/ecollections-ui/bbecuisvc/GetSLAReportDownload",

  version: ecollectionversion() + '/fmsproductservice/cmrds/registration/version',

  iqct: iqctUrl() + "/iqct/",

  // RA reports

  generateReport: `/clctnsvc/reports/waf/common/generateReport`,
  getRAAuditSummaryReport: `/clctnsvc/reports/waf/getNavAuditSummaryReport`,
  getRAAuditSummaryReportExportHistory: `/clctnsvc/reports/waf/common`,
  raFileDownload: `/clctnsvc/filedownload/file`,

  reportsAPI: cdarapigateway() + cdarapigatewaynav() + "/navigatoradminservice/reports",
  downloadWOTemplate: cdarapigateway() + cdarapigatewaynav() + "/navigatoradminservice/fmsaud/downloadWOTemplate"

};

export default AppURL;