import * as React from 'react';
import { filterBy } from '@progress/kendo-data-query';

const isPresent = value => value !== null && value !== undefined;

export const withValueField = DropDownComponent => {
    return class extends React.Component {
        component;
        state = {
            data: this.props.data?.slice()
        };

        events = {
            onBlur: event => this.triggerEvent('onBlur', event),
            onFocus: event => this.triggerEvent('onFocus', event),
            onChange: event => this.triggerEvent('onChange', event),
            onPageChange: event => this.triggerEvent('onPageChange', event),
            onFilterChange: event => this.triggerEvent('onFilterChange', event)
        };

        componentDidUpdate = (prevProps, prevState) => {
            (this.props.data !== prevProps.data) && this.setState({ data: this.props.data?.slice() });
        };

        filterChange = (event) => {
            this.setState({
                data: Array.isArray(this.props.data) ? filterBy(this.props.data.slice(), event.filter) : this.props.data
            });
        };

        // eslint-disable-next-line getter-return
        get value() {
            if (this.component) {
                const value = this.component.value;
                return isPresent(value) && this.props.valueField ? this.props.valueField.split('.').reduce((o, i) => o[i], value) : value;
            }
        }

        render() {
            return (
                <DropDownComponent
                    filterable={true}
                    {...this.props}
                    data={this.state.data}
                    value={this.itemFromValue(this.props.value)}
                    defaultValue={this.itemFromValue(this.props.defaultValue)}
                    ref={component => this.component = component}
                    {...this.events}
                />
            );
        }

        triggerEvent(eventType, event) {
            if (this.props[eventType]) {
                this.props[eventType].call(undefined, {
                    ...event,
                    value: this.value,
                    selectedRecord: this.component?.value,
                    target: this
                });
            }
            else if (eventType === 'onFilterChange') {
                this.filterChange.call(this, {
                    ...event,
                    value: this.value,
                    selectedRecord: this.component?.value,
                    target: this
                });
            }
        }

        itemFromValue(value) {
            const { data = [], dataItemKey, valueField } = this.props;
            if (valueField) {
                return isPresent(value) ?
                    data.find(item => valueField.split('.').reduce((o, i) => o[i], item) === value) : value;
            }
            else if (dataItemKey) {
                return isPresent(value) ?
                    data.find(item => item[dataItemKey] === value[dataItemKey]) : value;
            }
            else {
                return isPresent(value) ?
                    data.find(item => item.toString() === value.toString()) : value;
            }
        }
    };
};