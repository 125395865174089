import { App } from "../../../App";
import AppURL from "../../../utils/AppURL";
import { showToast } from "../../../utils/Notification";
import { Get, Post, PostwithTimeoutHandler, Put } from "../../methods";
import * as type from "../types";

export const getNrpsReportFilters =
  (params, authContext) => async (dispatch) => {
    // let headers = {
    //   // OrganizationUnit: authContext?.organizationUnit || "",
    //   // userid: authContext?.SpocMail || "",
    //   "Content-Type": "application/json",
    // };

    const header = {

      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid: authContext?.CompanyID || "",
      "content-type": "application/json",
      // "sessionid": "cDTqCPepeaJ5g3Xs",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }

    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: true,
    });
    // const res = await Get(AppURL.nrpsreportfilters, params, headers, true).then(
    //   (res) => res.json()
    // );
    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header, true, 10000).then((res) =>
      // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
      res.json()
    );

    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: false,
    });
    const data = res?.data;
    if (data?.length) {
      dispatch({
        type: type.NRPS_REPORT_FILTERS,
        payload: data,
      });
    } else {
      console.log("error in nrps report filters list response");
    }
  };

export const getNRPSStoreStoresInfo =
  (params, authContext) => async (dispatch) => {
    let headers = {
      "Content-Type": "application/json",
    };

    const header = {
      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid:authContext?.CompanyID || "",
      "content-type": "application/json",
      // "sessionid": "cDTqCPepeaJ5g3Xs",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }



    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: true,
    });

    const params2 = {
      "CollectionType": "ECOLLECTION",
      "ReportName": "NRPS_REPORT",
      "APIName": "NRPSStoreStoresInfo",
      "Payload": {  
          ...params,
      }
  }

    
    // const res = await Get(
    //   AppURL.nrpsreportstoresinfo,
    //   params,
    //   headers,
    //   true
    // ).then((res) => res.json());
    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params2, header, false, 10000).then((res) =>
      // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
      res.json()
    );
    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: false,
    });
    if (res.status == "Success") {
      dispatch({
        type: type.NRPS_REPORT_STORES_INFO,
        payload: res,
      });
    } else {
      console.log("error in nrps report stores info response");
    }
  };

export const postNrpsReportFiltersSearch =
  (params, authContext) => async (dispatch) => {
    let headers = {
      // OrganizationUnit: authContext?.organizationUnit || "",
      // userid: authContext?.SpocMail || "",
      "Content-Type": "application/json",
    };

    const header = {
      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid: authContext?.CompanyID || "",
      "content-type": "application/json",
      // "sessionid": "cDTqCPepeaJ5g3Xs",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }

    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: true,
    });
    // const res = await Post(AppURL.nrpsreportfilterssearch,params,headers,true).then((res) => res.json());
    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header, true, 10000).then((res) =>
      // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
      res.json()
    );
    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: false,
    });
    // const data = res?.data
    if (res.status == "Success") {
      // debugger;
      // console.log("res>>>>",res.data)
      if (res.data.length > 0) {
        res.data.map((item, ids) => {
          item.uniqueId = ids;
          return item;
        });
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: true,
        });
      } else {
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: false,
        });
      }
      dispatch({
        type: type.NRPS_REPORT_FILTERS_SEARCH,
        payload: res,
      });
    } else {
      console.log("error in nrps report filters Plan list response");
    }
  };

export const postNrpsReportPlanClick =
  (params, authContext) => async (dispatch) => {
    let headers = {
      // OrganizationUnit: authContext?.organizationUnit || "",
      // userid: authContext?.SpocMail || "",
      "Content-Type": "application/json",
    };

    const header = {
      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid: authContext?.CompanyID || "",
      "content-type": "application/json",
      // "sessionid": "cDTqCPepeaJ5g3Xs",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }

    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: true,
    });


    const params2 = {
      "CollectionType": "ECOLLECTION",
      "ReportName": "NRPS_REPORT",
      "APIName": "NRPSStorePlanReport",
      "Payload": {
        ...params,
      }
    }


    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params2, header, false, 10000).then((res) =>
      // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
      res.json()
    );
    // const res = await Post(
    //   AppURL.nrpsreportplanclick,
    //   params,
    //   headers,
    //   true
    // ).then((res) => res.json());
    // const data = res?.data
    if (res.status == "Success") {
      dispatch({
        type: type.NRPS_REPORT_LOADING,
        payload: false,
      });
      if (res.data.length > 0) {
        res.data.map((item, ids) => {
          return (item.uniqueId = ids), (item.report = "plan");
          // return item;
        });
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: true,
        });
      } else {
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: false,
        });
      }
      dispatch({
        type: type.NRPS_REPORT_PLAN_CLICK,
        payload: res,
      });
    } else {
      console.log("error in nrps report filters Plan list response");
    }
  };

export const postNrpsReportProgressClick =
  (params, authContext) => async (dispatch) => {
    let headers = {
      // OrganizationUnit: authContext?.organizationUnit || "",
      // userid: authContext?.SpocMail || "",
      "Content-Type": "application/json",
    };

    const header = {
      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid:authContext?.CompanyID || "",
      "content-type": "application/json",
      // "sessionid": "cDTqCPepeaJ5g3Xs",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }

    const params2 = {
      "CollectionType": "ECOLLECTION",
      "ReportName": "NRPS_REPORT",
      "APIName": "NRPSStoreProgressReportDetails",
      "Payload": {
        ...params,
      }
    }

    dispatch({
      type: type.REPORT_PARAMS,
      payload: params,
    });
    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: true,
    });
    // const res = await Post(
    //   AppURL.nrpsreportprogressclick,
    //   params,
    //   headers,
    //   true
    // ).then((res) => res.json());
    // const data = res?.data

    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params2, header, false, 10000).then((res) =>
      // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
      res.json()
    );

    if (res.status == "Success") {
      dispatch({
        type: type.NRPS_REPORT_LOADING,
        payload: false,
      });
      if (res.data.length > 0) {
        res.data.map((item, ids) => {
          return (item.uniqueId = ids), (item.report = "progress");
          // return item;
        });
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: true,
        });
      } else {
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: false,
        });
      }
      dispatch({
        type: type.NRPS_REPORT_PROGRESS_CLICK,
        payload: res,
      });
    } else {
      console.log("error in nrps report filters Plan list response");
    }
  };


export const postNrpsReportKpiClick =
  (params, authContext) => async (dispatch) => {
    let headers = {
      // OrganizationUnit: authContext?.organizationUnit || "",
      // userid: authContext?.SpocMail || "",
      "Content-Type": "application/json",
    };
    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: true,
    });

    const params2 = {
      "CollectionType": "ECOLLECTION",
      "ReportName": "NRPS_REPORT",
      "APIName": "NRPSStoreKPIReportDetails",
      "Payload": {
        ...params,
      }
    }

    const header = {
      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid:authContext?.CompanyID || "",
      "content-type": "application/json",
      // "sessionid": "cDTqCPepeaJ5g3Xs",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }
  
    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params2, header,false,10000).then((res) =>
    // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
      res.json()
    );


    // const res = await Post(
    //   AppURL.nrpsreportkpiclick,
    //   params,
    //   headers,
    //   true
    // ).then((res) => res.json());
    // const data = res?.data
    if (res.status == "Success") {
      dispatch({
        type: type.NRPS_REPORT_LOADING,
        payload: false,
      });
      if (res.data.length > 0) {
        res.data.map((item, ids) => {
          return (item.uniqueId = ids), (item.report = "kpi");
          // return item;
        });
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: true,
        });
      } else {
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: false,
        });
      }
      dispatch({
        type: type.NRPS_REPORT_KPI_CLICK,
        payload: res,
      });
    } else {
      console.log("error in nrps report filters Kpi list response");
    }
  };

export const postNrpsReportAuditorClick =
  (params, authContext) => async (dispatch) => {


    const params2 = {
      "CollectionType": "ECOLLECTION",
      "ReportName": "NRPS_REPORT",
      "APIName": "NRPSStoreProgressReportDetails",
      "Payload": {
        ...params,
      }
    }

    const header = {
      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid:authContext?.CompanyID || "",
      "content-type": "application/json",
      // "sessionid": "cDTqCPepeaJ5g3Xs",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }
  


    // let headers = {
    //   // OrganizationUnit: authContext?.organizationUnit || "",
    //   // userid: authContext?.SpocMail || "",
    //   "Content-Type": "application/json",
    // };

    dispatch({
      type: type.NRPS_REPORT_LOADING,
      payload: true,
    });
    // const res = await Post(
    //   AppURL.nrpsreportauditorclick,
    //   params,
    //   headers,
    //   true
    // ).then((res) => res.json());

    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params2, header,false,10000).then((res) =>
      // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
        res.json()
    );



    if (res.status == "Success") {
      dispatch({
        type: type.NRPS_REPORT_LOADING,
        payload: false,
      });
      if (res.data.length > 0) {
        res.data.map((item, ids) => {
          return (item.uniqueId = ids), (item.report = "auditor");
          // return item;
        });
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: true,
        });
      } else {
        dispatch({
          type: type.IS_DATA_FOR_REPORT,
          payload: false,
        });
      }
      dispatch({
        type: type.NRPS_REPORT_AUDITOR_CLICK,
        payload: res,
      });
    } else {
      console.log("error in nrps report filters Auditor list response");
    }
  };

export const postNrpsReportExport =
  (params, authContext) => async (dispatch) => {

    const header = {

      // userid: authContext?.SpocMail || "",
      // countryid: authContext?.CountryCode || "",
      // companyid: authContext?.CompanyID || "",
      // "content-type": "application/x-www-form-urlencoded; application/json",
      "content-type": "application/json",
    }
  
    try {
      const exportFile = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header, true);
      console.log("exportFile", exportFile);
      if (exportFile.status === 200) {
        // console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
        console.log("AfterAPi", Date.now());
        const disposition = exportFile?.headers
          ?.get("Content-Disposition")
          ?.split("filename=");
        const exportFileBlob = await exportFile.blob();
        const exportFileUrl = URL.createObjectURL(exportFileBlob);
        
        // Ensure the filename is present and clean it up
        let fileName = disposition?.[1] ? disposition[1].trim() : `file_${Date.now()}.csv`;
  
        // If the filename is surrounded by quotes, remove them
        fileName = fileName.replace(/^"|"$/g, '');
  
        // Extract the report name (everything before the first underscore)
        const reportName = fileName.split('_')[0];
  
        // const FILE_NAME = Date.now();
        const anchor = document.createElement("a");
        anchor.href = exportFileUrl;
        anchor.download = `${reportName}_${Date.now()}.csv`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        showToast(
          "success",
          "File exported successfully",
        );
      } else {
        showToast(
          "error",
          "File Export failed, please try again later or with fewer records",
        );
      }
    } catch (error) {
      console.log("errorcatch", error);
      showToast(
        "error",
        "File Export failed, please try again later or with fewer records",
      );
    }
    
   
  };

export const putNRPSStoreStatusUpdate = async (params, authContext) => {
  let headers = {
    // OrganizationUnit: authContext?.organizationUnit || "",
    // userid: authContext?.SpocMail || "",
    "Content-Type": "application/json",
  };
  const res = await Put(
    AppURL.nrpsstorestatusupdate,
    params,
    headers,
    true
  ).then((res) => res.json());
  return res;
  // if(res.status == "Success") {
  //   dispatch({
  //     type: type.NRPS_STORE_STATUS_UPDATE,
  //     payload: res,
  //   })
  // }else {
  //   console.log("error in nrps report filters Auditor list response");
  // }byCommonFiltersTab
};

export const getCommonFiltersParams = (params) => (dispatch) => {
  dispatch({
    type: type.COMMON_FILTERS_PARAMS,
    payload: params,
  });
};

export const byCommonFiltersActiveTab = (params) => (dispatch) => {
  dispatch({
    type: type.BY_COMMON_FILTERS_ACTIVE_TAB,
    payload: params,
  });
};
export const activeTab = (params) => (dispatch) => {
  dispatch({
    type: type.ACTIVE_TAB,
    payload: params,
  });
};
export const selectedGridRow = (params) => (dispatch) => {
  console.log("planpara", params);
  dispatch({
    type: type.SELECT_GRID_ROW,
    payload: params,
  });
};
export const sidebarOpenCloseAction = (params) => (dispatch) => {
  console.log("sideparams", params);
  dispatch({
    type: type.SIDEBAR_OPEN_CLOSE,
    payload: params,
  });
};
export const tabFilters = (params) => (dispatch) => {
  dispatch({
    type: type.ACTIVE_TAB_FILTERS,
    payload: params,
  });
};
export const selectedStoreIdByGrid = (params) => (dispatch) => {
  dispatch({
    type: type.STOREID_BY_GRID,
    payload: params,
  });
};
export const setDefaultYearAndWeek = (params) => (dispatch) => {
  console.log("dddyee", params);
  dispatch({
    type: type.DEFAULT_YEAR_WEEK,
    payload: params,
  });
};
export const reportNameSelectedRow = (params) => (dispatch) => {
  dispatch({
    type: type.REPORT_NAME_SELECTED_ROW,
    payload: params,
  });
};
export const deselectAllRows = (params) => (dispatch) => {
  dispatch({
    type: type.DESELECT_ALL_ROWS,
    payload: params,
  });
};
export const setEventSelectedRow = (params) => (dispatch) => {
  dispatch({
    type: type.SET_SELECTED_ROWS,
    payload: params,
  });
};
export const setSelectedRowStatus = (params) => (dispatch) => {
  // console.log("params",params)
  dispatch({
    type: type.IS_SELECTED_ROW,
    payload: params,
  });
};
export const eventRowIndex = (params) => (dispatch) => {
  dispatch({
    type: type.EVENT_ROW_INDEX,
    payload: params,
  });
};

export const scrollingReport = (params) => (dispatch) => {
  dispatch({
    type: type.SCROLLING_REPORT_NAME,
    payload: params,
  });
};
