import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import React, { useEffect } from 'react';
import { useLocalization } from "@progress/kendo-react-intl";
import { Label, Error, Hint, FloatingLabel } from '@progress/kendo-react-labels';

export const SearchInput = (props) => {
    const editorRef = React.useRef(null);
    const { triggerProps, wrapperStyle, style, ...other } = props;

    useEffect(() => {
        if (editorRef?.current?.element?.addedlistener !== true) {
            editorRef.current.element.addEventListener('keydown', (e) => {
                if (e.keyCode === 13) {
                    triggerProps.onClick.call(undefined, { value: e.target.value });
                }
            });
            editorRef.current.element.addedlistener = true;
        }
    }, [editorRef, triggerProps.onClick]);

    return (
        <span id="search-field" style={{ display: 'flex', ...wrapperStyle }}>
            <Input
                ref={editorRef}
                style={{ width: '100%', ...style }}
                {...other}
            />
            <span style={{ position: 'relative', width: 0, height: 0 }}>
                <Button
                    icon='search'
                    style={{ top: 1, right: 1, height: "calc(1.5em + 0.75rem)", position: 'absolute' }}
                    {...triggerProps}
                />
            </span>
        </span >
    );
};

export const PasswordInput = props => {
    const localizationService = useLocalization();
    const { triggerProps, inputStyle, disabled } = props;

    return (
        <span style={{ display: 'flex', ...inputStyle }}>
            <Input
                disabled={true}
                value='*********'
            />
            <span style={{ position: 'relative', width: 0, height: 0 }}>
                <Button
                    title={localizationService.toLanguageString('view', 'View')}
                    type="button"
                    icon='preview'
                    disabled={disabled}
                    style={{ top: 2, right: 2, height: "calc(1.5em + 0.75rem - 2px)", position: 'absolute' }}
                    {...triggerProps}
                />
            </span>
        </span >
    );
};

export const LoginPasswordInput = props => {
    // console.log(props);
    const localizationService = useLocalization();
    const { inputStyle, valid, required, blurHandler, onClick,validationMessage,hint,touched,id, ...triggerProps } = props;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    const customHandler = e => {
        props.onBlur(e);
        blurHandler && blurHandler(e);
    };

    return (
        <div>
            <div>
                <span style={{ display: 'flex', ...inputStyle }}>
                    <Input
                        // id={id}
                        valid={valid}
                        {...triggerProps}
                        onBlur={customHandler}
                    />
                    <span style={{ position: 'relative', width: 0, height: 0 }}>
                        <Button
                            title={localizationService.toLanguageString('view', 'View')}
                            type="button"
                            icon='preview'
                            onClick={onClick}
                            // disabled={disabled}
                            style={{ top: 2, right: 2, height: "calc(1.5em + 0.75rem - 2px)", position: 'absolute' }}
                            {...triggerProps}
                        />
                    </span>
                </span >
            </div>
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </div>

    );
};
