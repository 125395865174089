import * as types from "../../actions/types";
function weekNumber(date = new Date()) {
  var firstJanuary = new Date(date.getFullYear(), 0, 1);
  var dayNr = Math.ceil((date - firstJanuary) / (24 * 60 * 60 * 1000));
  var weekNr = Math.ceil((dayNr + firstJanuary.getDay()) / 7);
  return weekNr;
}
const getInitialDate = () => {
  const startYear = new Date().getFullYear();
  const yearData = Array.apply(null, { length: 6 }).map((_, index) => ({
    DisplayValue: String(startYear - index),
  }));
  const weekData = Array.apply(null, { length: 54 }).map((_, index) => ({
    DisplayValue: index + 1,
  }));
  return {
    yearData,
    selectedYear: {
      DisplayValue: startYear,
    },
    weekData,
    selectedWeek: {
      DisplayValue: weekNumber(),
    },
  };
};
const initialState = {
  nrpsReportLoading: false,
  nrpsReportFilters: [],
  nrpsReportStoresInfo: null,
  nrpsReportFiltersSearch: null,
  nrpsReportPlanClick: null,
  nrpsReportProgressClick: [],
  nrpsReportKpiClick: [],
  nrpsReportAuditorClick: [],
  nrpsReportExport: [],
  nrpsStoreStatusUpdate: [],
  nrpsCommonFiltersParams: {
      vendorId: "",
      country: "",
      year:"",
      week:"",
      storeId:"",
      storeName:"",
      city:"",
      province:"",
      storeType: "",
      region:"",      
      auditorId: "",
      banner: "",
      collectionType: "",
      limitDate: "",
      reportType: "",
      status: "",
      product: "",
      startRange: 0,
      endRange: 0,
      pageSize: 200,
      pageNo: 0
    },
  activeTabFilters: {
    banner: "",
    storeType: "",
    collectionType: "",
    limitDate:"",
    status:"",
    auditorId:"",
    product:"",
    startRange:"",
    endRange:""
  },
  defaultYearAndWeek:{
    "year": "",
    "week": ""
  },
  byCommonFiltersActiveTab: null,
  activeTab: null,
  reportParams:null,
  selectedGridRow:[],
  // selectedGridRowReport:false,
  storeIdByGrid: [],
  isDataForReport: null,
  sidebarOpenClose:false,
  reportSelectedRows: [],
  selectedRowStatus: {},
  evntRowIndex: null,
  scrollingReportName: "",
  reportName:[],
  deselectedAllRows:"",
  dateStamp: getInitialDate(),
  reportsAccordianActive: {
    "NSO" : false,
    "DCT" : false,
    "GNIC" : false, 
    "ECOLLECTION": false,
    "MISCELLANEOUS": false,
  }
};

const Reports = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.NRPS_REPORT_LOADING:
      // console.log("ACTION>PLAYLOAD", action.payload);
      newState = {
        ...state,
        nrpsReportLoading: action.payload,
      };
      break;
    case types.NRPS_REPORT_FILTERS:
      // console.log("ACTION>PLAYLOAD", action.payload);
      newState = {
        ...state,
        nrpsReportFilters: action.payload,
      };
      break;
    case types.NRPS_REPORT_STORES_INFO:      
      newState = {
        ...state,
        nrpsReportStoresInfo: action.payload,
      };
      break;
    case types.NRPS_REPORT_FILTERS_SEARCH:
      // console.log("ActionPayloadSearch", action.payload);
      newState = {
        ...state,
        nrpsReportFiltersSearch: action.payload,
      };
      // console.log("reducer",JSON.stringify(newState?.nrpsReportFiltersSearch?.data));
      break;
    case types.NRPS_REPORT_PLAN_CLICK:
      console.log("planaction", action.payload)
      newState = {
        ...state,
        nrpsReportPlanClick: action.payload,
      };
      break;
    case types.NRPS_REPORT_PROGRESS_CLICK:
      // console.log("ActionPayloadprogress", action.payload);
      newState = {
        ...state,
        nrpsReportProgressClick: action.payload,
      };
      break;
    case types.NRPS_REPORT_KPI_CLICK:
      // console.log("ActionPayloadKPI", action.payload);
      newState = {
        ...state,
        nrpsReportKpiClick: action.payload,
      };
      break;
    case types.NRPS_REPORT_AUDITOR_CLICK:
      // console.log("ActionPayloadAuditor", action.payload);
      newState = {
        ...state,
        nrpsReportAuditorClick: action.payload,
      };
      break;
    case types.NRPS_REPORT_EXPORT:
      // console.log("ActionPayexport", action.payload);
      newState = {
        ...state,
        nrpsReportExport: action.payload,
      };
      break;
    case types.NRPS_STORE_STATUS_UPDATE:
      // console.log("ActionPaystore", action.payload);
      newState = {
        ...state,
        nrpsStoreStatusUpdate: action.payload,
      };
      break;
      case types.COMMON_FILTERS_PARAMS:        
      newState = {
        ...state,
        nrpsCommonFiltersParams: action.payload,
      };
      break; 
      case types.BY_COMMON_FILTERS_ACTIVE_TAB:      
      newState = {
        ...state,
        byCommonFiltersActiveTab: action.payload,
      };
      break;
      case types.ACTIVE_TAB:      
      newState = {
        ...state,
        activeTab: action.payload,
      };
      break; 
      case types.REPORT_PARAMS:      
      newState = {
        ...state,
        reportParams: action.payload,
      };
      break;
      case types.SELECT_GRID_ROW:
        console.log("selectedGridRow",action.payload)     
      newState = {
        ...state,
        selectedGridRow: action.payload,
      };
      break; 
      // case types.SELECT_GRID_ROW_REPORT:
      //   console.log("SELECT_GRID_ROW_REPORT",action.payload)      
      // newState = {
      //   ...state,
      //   selectedGridRowReport: action.payload,
      // };
      // break; 
      case types.SIDEBAR_OPEN_CLOSE:  
      newState = {
        ...state,
        sidebarOpenClose: action.payload,
      };
      break; 
      case types.ACTIVE_TAB_FILTERS:       
      newState = {
        ...state,
        activeTabFilters: action.payload,
      };
      break;  
      case types.STOREID_BY_GRID:
      newState = {
        ...state,
        storeIdByGrid: action.payload,
      };
      break;
      case types.IS_DATA_FOR_REPORT:        
      newState = {
        ...state,
        isDataForReport: action.payload,
      };
      break; 
      case types.DEFAULT_YEAR_WEEK:        
      newState = {
        ...state,
        defaultYearAndWeek: action.payload,
      };
      break;      
      case types.REPORT_NAME_SELECTED_ROW:             
      newState = {
        ...state,
        reportName: action.payload,
      };
      break;      
      case types.DESELECT_ALL_ROWS:             
      newState = {
        ...state,
        deselectedAllRows: action.payload,
      };
      break;      
      case types.SET_SELECTED_ROWS:             
      newState = {
        ...state,
        reportSelectedRows: action.payload,
      };
      break;      
      case types.IS_SELECTED_ROW:                 
      newState = {
        ...state,
        selectedRowStatus: action.payload,
      };
      break;      
      case types.EVENT_ROW_INDEX:                 
      newState = {
        ...state,
        evntRowIndex: action.payload,
      };
      break;
      case types.SCROLLING_REPORT_NAME:                 
      newState = {
        ...state,
        scrollingReportName: action.payload,
      };
      break;      
      case types.SET_REPORTS_ACCORDIAN_ACTIVE:                 
      newState = {
        ...state,
        reportsAccordianActive: action.payload,
      };
      break;      
    default:
      newState = state;
      break;
  }
  return newState;
};

export default Reports;
