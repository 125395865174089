import React, { useState, useEffect, useMemo } from "react";
import Header from "../../../header";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import columns from "./column";

import {
  getSelectedState,
  Grid,
  GridColumn,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { isColumnActive } from "../../../ui/grid/CustomColumnMenu";
import { useTranslation } from "react-i18next";
import HelpWindow from "./helpWindow";
import {
  RowRender,
} from "../../../ui/grid/renders";
import {
  CustomCellDropDown,
  DatePickerCell,
  PlainInputCell,
  ResourceIDCell,
  TimeIntervalCell,
} from "./utils";
import "./index.css";
import {
  getStaged,
  postSaveOrValidate,
} from "../../../../store/actions/workOrder/replanValidate";
import { useSelector } from "react-redux";
import LoadMask from "../../../../utils/LoadMask";
import { showToast } from "../../../../utils/Notification";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";
import { validateTimeCheck } from "../../../generalHooks";
import moment from "moment";
const alertLIstPageData = {
  take: 20,
  skip: 0,
};
const createDataState = (data, dataState) => {
  return {
    result: {
      data,
      total: dataState.take,
    },
    dataState,
  };
};
const EDIT_FIELD = "inEdit";
const DATA_ITEM_KEY = "uniqueId";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
// const initialData = {data:new Array(100).map((d)=>({...d,[EDIT_FIELD]:undefined})),total:100}
const fields = columns.reduce((acc, column) => {
  const { field } = column;
  if (field) {
    acc[field] = "";
  }
  return acc;
}, {});
// const initialData = new Array(2).map((_,i) => {
//   return { ...fields,[DATA_ITEM_KEY]:i+1, [EDIT_FIELD]: undefined };
// });
const initialData = Array.from({ length: 15 }, (_, i) => ({
  ...fields,
  [DATA_ITEM_KEY]: i,
  [EDIT_FIELD]: undefined,
}));

const ReplanValidate = () => {
  const { handleAuthContext } = useLogout();
  const workOrderDetail = useSelector(
    (state) => state.commonReducer.workOrderDetail
  );
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const initialState = createDataState([], alertLIstPageData);
  const [data, setData] = useState(initialData);
  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [selectedState, setSelectedState] = useState({});
  const [stateColumns, setStateColumns] = useState(columns);

  const [showLoader, setShowLoader] = useState(null);

  const [sort, setSort] = useState([
    {
      field: "JobId",
      dir: "asc",
    },
  ]);
  const [filter, setFilter] = useState(null);

  const [gridAction, setGridAction] = useState("VALIDATE");

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || columns);
  };

  // const [data, setData] = React.useState(sampleProducts);
  const [changes, setChanges] = useState(false);
  const [position, setPosition] = useState({});
  const [drag, setDrag] = useState(false);

  const enterEdit = (dataItem, field) => {
    const newData = data.map((item) => ({
      ...item,
      [EDIT_FIELD]:
        item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY] ? field : undefined,
    }));
    setData(newData);
  };

  const exitEdit = () => {
    const newData = data.map((item) => ({
      ...item,
      [EDIT_FIELD]: undefined,
    }));
    setData(newData);
  };

  const itemChange = (event) => {
    let field = event.field || "";
    let newData = data.map((item) => {
      if (item[DATA_ITEM_KEY] === event.dataItem[DATA_ITEM_KEY]) {
        const newValue = event.value;
        item[field] = newValue;
        if (newValue) {
          const { edited = [] } = item;
          item.edited = [...edited, field];
        } else {
          item.edited = item.edited.filter((fieldName) => fieldName !== field);
        }
      }
      return item;
    });
    setData(newData);
    setChanges(true);
  };

  const customCellRender = (td, props) => {
    switch (props.field) {
      case "AuditorId":
        return (
          <CustomCellDropDown
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            dataItemKey={DATA_ITEM_KEY}
            cell={<ResourceIDCell {...props} />}
          />
        );
      case "VisitDate":
        return (
          <CustomCellDropDown
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            dataItemKey={DATA_ITEM_KEY}
            cell={<DatePickerCell {...props} />}
          />
        );
      case "StartTime":
      case "EndTime":
        return (
          <CustomCellDropDown
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            dataItemKey={DATA_ITEM_KEY}
            cell={<TimeIntervalCell {...props} />}
          />
        );
      case "ValidationMsg":
        return (
          <CustomCellDropDown
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            selectedState={selectedState}
            editField={EDIT_FIELD}
            dataItemKey={DATA_ITEM_KEY}
            cell={
              <PlainInputCell
                {...props}
                inputProps={{
                  placeholder: "Max: 500 characters",
                  onKeyDown: (e) => e.preventDefault(),
                }}
              />
            }
          />
        );
      default:
        return (
          <CustomCellDropDown
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            selectedState={selectedState}
            editField={EDIT_FIELD}
            dataItemKey={DATA_ITEM_KEY}
            cell={
              <PlainInputCell {...props} inputProps={{ placeholder: "" }} />
            }
          />
        );
    }
  };
  const customRowRender = (tr, props) => {
    return (
      <RowRender
        originalProps={props}
        tr={tr}
        exitEdit={exitEdit}
        editField={EDIT_FIELD}
      />
    );
  };

  const removeData = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    const lastItem = newData.at(-1);
    newData.push({ [DATA_ITEM_KEY]: lastItem?.[DATA_ITEM_KEY] + 1, ...fields });
    setData(newData);
  };

  useEffect(() => {
    document.getElementById('customCellComboBox')?.focus();
  })

  const resetBtnCell = (props) => {
    const { dataIndex } = props;
    return (
      <td style={{ padding: 0, height: 40 }}>
        <Button
          style={{ height: "100%" }}
          title={`Reset ${dataIndex + 1} row`}
          icon="undo"
          onClick={() => removeData(dataIndex)}
        />
      </td>
    );
  };
  const onSelectionChange = (event) => {
    let newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    setSelectedState(newSelectedState);
    let positionData = {
      startRowIndex: event.startRowIndex,
      endRowIndex: event.endRowIndex,
      startColIndex: event.startColIndex,
      endColIndex: event.endColIndex,
    };

    setPosition(positionData);

    if (event.startColIndex !== event.endColIndex) return;

    if (event.endRowIndex === event.startRowIndex) {
      setDrag(false);
    }
  };

  const onNavigationAction = (event) => {
    setPosition({
      startRowIndex: event?.focusElement?.parentElement?.rowIndex + dataState.skip,
      endRowIndex: event?.focusElement?.parentElement?.rowIndex + dataState.skip,
      startColIndex: event.focusElement.cellIndex,
      endColIndex: event.focusElement.cellIndex,
    });

    const newSelectedState = {};
    newSelectedState[event?.focusElement?.parentElement?.rowIndex] = [event.focusElement.cellIndex];
    setSelectedState(newSelectedState);
  }

  const gridData = useMemo(() => {
    const gridContent = data.map((item) => {
      return {
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      };
    });
    return gridContent;
  }, [data, selectedState]);

  const fetchStaged = async () => {
    const params = {
      // JobId:"1675674170714"
      JobId: workOrderDetail?.JobId,
    };
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await getStaged(params, authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    const store = res?.Response?.Store;
    if (Array.isArray(store)) {
      store.push(...initialData);
      const clientData = store.map((d, i) => ({
        ...d,
        [DATA_ITEM_KEY]: i,
        [EDIT_FIELD]: undefined,
      }));
      setData(clientData);
      setGridAction("SAVE");
    } else {
      setData(initialData);
    }
  };

  useEffect(() => {
    fetchStaged();
  }, []);

  const saveOrValidateGridData = async () => {
    let smsId;
    const auditorIdExist = data.some((d) => {
      const noId = d.SmsId && !d.AuditorId;
      if (noId) {
        smsId = d.SmsId;
      }
      return noId;
    });
    if (auditorIdExist) {
      showToast("error", `${t("AuditorIDInvalid")} for SMS_ID ${smsId}`);
      return;
    }
    const Store =
      data
        ?.filter((d) => d.SmsId && d.AuditorId)
        .map(({ SmsId, AuditorId, VisitDate, StartTime, EndTime }) => {
          return {
            SmsId,
            PrimAuditorId: AuditorId,
            VisitDate,
            StartTime,
            EndTime,
          };
        }) || [];

    // return;
    const params = `Action=${gridAction}&JobId=${workOrderDetail?.JobId}&User=${authContext?.SpocMail}&CompanyId=${authContext?.CompanyID}`;
    const bodyData = JSON.stringify({ StoresData: { Store } });
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await postSaveOrValidate(params, bodyData, authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Status?.status !== "-1") {
      const isSuccess = res?.Status?.statusCode === "Success";
      if (isSuccess) {
        setGridAction("SAVE");
      }
      const type = isSuccess ? "success" : "error";
      showToast(type, t(`${res?.Status?.statusMsg}`));
      if (gridAction === "VALIDATE") {
        fetchStaged();
      }
    } else {
      showToast("error", t(res?.Status?.statusMsg || "Error while validating"));
    }
  };

  const handleCopy = (e) => {
    const visiableColList = stateColumns.filter((col) => col.show === true);
    let copyContent = '';
    const selectedStateKeys = Object.keys(selectedState);
    gridData.forEach((row) => {
      if (selectedStateKeys.includes(row.uniqueId.toString())) {
        const selectedCells = selectedState?.[row.uniqueId].length;
        selectedState?.[row.uniqueId]?.forEach((cellIndex, index) => {
          let selectedGrid = visiableColList[cellIndex - 1].field;
          copyContent += `${row[selectedGrid]}`;
          if (index < (selectedCells - 1)) {
            copyContent += '\t';
          }
        });
        copyContent += '\r\n';
      }
    });
    e.clipboardData.setData('text/plain', copyContent.trim());
    e.preventDefault();
  }

  const handlePaste = (e) => {
    if (e.target.tagName && e.target.tagName.match(/(input|textarea)/i)) {
      // Do not handle past when an input element is currently focused
      return;
    }

    // Get clipboard data as text
    const data = e.clipboardData.getData("text");

    // Simplified parsing of the TSV data with hard-coded columns
    const clipboardData = data.trim().split("\r\n");
    const selectedStateKeys = Object.keys(selectedState);
    const selectedFirstRowKeyIndex = position?.startRowIndex;
    const selectedLastItemKeyIndex = selectedFirstRowKeyIndex + clipboardData.length - 1;
    const visiableColList = stateColumns.filter((col) => col.show === true);
    let count = 0;

    if (clipboardData.length && selectedStateKeys.length) {
      let temp = [...gridData];
      temp.map((item, gridRowIndex) => {
        item["selected"] = selectedState[idGetter(item)];
        if (
          (selectedFirstRowKeyIndex <= gridRowIndex &&
            gridRowIndex <= selectedLastItemKeyIndex) ||
          selectedStateKeys.includes(item?.uniqueId.toString())
        ) {
          const colIndexArr = selectedStateKeys.includes(item?.uniqueId.toString()) ?
            selectedState?.[item.uniqueId.toString()] :
            Array.from(
              { length: position?.endColIndex - position?.startColIndex + 1 },
              (value, key) => position?.startColIndex + key
            )

          let colCount = 0;
          colIndexArr.forEach((colIndex, colIndexKey) => {
            let selectedCell = visiableColList[colIndex - 1].field;
            const clipboardRowData = clipboardData[count]?.trim()?.split("\t");
            if (
              !isNaN(clipboardRowData[colCount]) ||
              (
                (
                  visiableColList[colIndex - 1].field === 'StartTime' ||
                  visiableColList[colIndex - 1].field === 'EndTime'
                ) &&
                validateTimeCheck(clipboardRowData[colCount])
              ) ||
              (
                (
                  visiableColList[colIndex - 1].field === 'VisitDate'
                ) &&
                moment(clipboardRowData[colCount], 'MM/DD/YYYY', true).isValid()
              )
            ) {
              item[selectedCell] = clipboardRowData[colCount];

              if (clipboardRowData.length > 1) {
                colCount++;
              }

              if (colIndexKey === (colIndexArr.length - 1) && clipboardData.length > 1) {
                count++;
              }

              if (Array.isArray(item["edited"]))
                item["edited"].push(selectedCell);
              else item["edited"] = [selectedCell];
            }
          })
        }
        return item;
      });

      setData(temp);
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    window.addEventListener('copy', handleCopy);

    return () => {
      window.removeEventListener('paste', handlePaste);
      window.removeEventListener('copy', handleCopy);
    };
  }, [selectedState, position, stateColumns]);

  const fillHandle = () => {
    if (position.startColIndex !== position.endColIndex) return;

    const visiableColList = stateColumns.filter((col) => col.show === true);
    if (
      visiableColList[position.startColIndex].editable &&
      position.endRowIndex > position.startRowIndex
    ) {
      let selectedGrid = visiableColList[position.startColIndex].field;
      let temp = [...gridData.data];
      temp.map((item, index) => {
        if (
          position.startRowIndex <= index &&
          index <= position.endRowIndex
        ) {
          item[selectedGrid] = gridData.data[position.startRowIndex][selectedGrid];
          if (Array.isArray(item["edited"]))
            item["edited"].push(selectedGrid);
          else item["edited"] = [selectedGrid];
        }
        return item;
      });

      setSelectedState({});
      setTimeout(() => {
        setData(temp);
      }, 100);
    }
  }

  useEffect(() => {
    if (drag) {
      fillHandle();
    }
  }, [drag])

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <Header
        activeMenu={"Replan Validation Check"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            paddingRight: "15px",
            paddingBottom: "6px"
          }}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <HelpWindow />
            <Button primary onClick={saveOrValidateGridData}>
              {gridAction === "VALIDATE" ? "Validate" : "Save"}
            </Button>
            <Button
              className="k-secondary"
              onClick={() => {
                setGridAction("VALIDATE");
                setData(initialData);
              }}
            >
              Reset
            </Button>
            <Button className="k-secondary" onClick={() => history.goBack()}>Cancel</Button>
          </div>
        </div>
      </Header>
      <div
        style={{
          display: "flex",
          gap: 16,
          justifyContent: "center",
          padding: 30,
          height: "75vh",
        }}
      >
        <Grid
          className="workOrders_grid"
          data={gridData}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: true,
            cell: true,
            mode: "multiple",
          }}
          dataItemKey={DATA_ITEM_KEY}
          onItemChange={itemChange}
          cellRender={customCellRender}
          rowRender={customRowRender}
          editField={EDIT_FIELD}
          onSelectionChange={onSelectionChange}
          navigatable={true}
          onNavigationAction={onNavigationAction}
          resizable
        >
          <GridColumn
            field="reset"
            title={"reset"}
            cell={resetBtnCell}
            width={35}
            headerCell={() => null}
          />
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <GridColumn
                  key={idx}
                  field={column.field}
                  title={t(column.title)}
                  format={column?.format}
                  filter={column?.filter}
                  columnMenu={(props) => {
                    return column?.columnMenu?.({
                      ...props,
                      columns: stateColumns,
                      onColumnsSubmit,
                      data: data?.data || [],
                    });
                  }}
                  headerClassName={
                    isColumnActive(column.field, { ...dataState, filter })
                      ? "activeFiltered"
                      : ""
                  }
                  width={column?.width}
                />
              )
          )}
        </Grid>
      </div>
    </>
  );
};

export default ReplanValidate;
