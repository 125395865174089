import * as types from "../../actions/types";

const initialState = {
  alertListData: null,
  selectedAlert: null,
  loading: false,
  error: null,
  dataChangeValues: null,
  updateAlert: {
    loading: false,
    error: null,
    isUpdated: false,
  },
  blockInfo: null,
  trackDetails:null,
  storeDetails:null,
  summary:null,
  blockInfoFound:false,
  kmlUrls:null,
};

const AlertReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.GET_ALERT_LIST + "_FETCH":
      newState = {
        ...state,
        loading: true,
        error: null,
        updateAlert: {
          ...state.updateAlert,
          isUpdated: false,
        },
      };
      break;
    case types.GET_ALERT_LIST:
      newState = {
        ...state,
        alertListData: action.payload,
        loading: false,
      };
      break;
    case types.GET_ALERT_LIST + "_ERROR":
      newState = {
        ...state,
        loading: false,
        error: action.payload,
        alertListData: null,
      };
      break;
    case types.GET_DATA_CHANGE_VALUES:
      newState = {
        ...state,
        dataChangeValues: action.payload,
      };
      break;
    case types.GET_DATA_CHANGE_VALUES + "_ERROR":
      newState = {
        ...state,
        dataChangeValues: null,
      };
      break;

    // UPDATE ALERT CASES
    case types.UPDATE_ALERT + "_FETCH":
      newState = {
        ...state,
        updateAlert: {
          ...state.updateAlert,
          loading: true,
          error: null,
        },
      };
      break;

    case types.UPDATE_ALERT + "_SUCCESS":
      newState = {
        ...state,
        updateAlert: {
          ...state.updateAlert,
          isUpdated: true,
          loading: false,
        },
      };
      break;
    case types.UPDATE_ALERT + "_ERROR":
      newState = {
        ...state,
        updateAlert: {
          ...state.updateAlert,
          isUpdated: false,
          loading: false,
          error: action.payload,
        },
      };
      break;

    case types.GET_BLOCK_INFO:
      const {blockInfoRes=null,kmlUrls=null} = action.payload;
      newState = {
        ...state,
        blockInfo: blockInfoRes,
        kmlUrls: kmlUrls,
      };
      break;
    case types.GET_AUDITOR_LOCATION:
      console.log(action.payload,"reducer blockinfo")
      const {trackDetails,storeDetails,summary,blockInfoFound}=action.payload;

      newState = {
        ...state,
        trackDetails,
        storeDetails,
        summary,
        blockInfoFound,
      }
      break;

    // case types.SET_SELECTED_ALERT:
    // newState = {
    //     ...state,
    //     selectedAlert: action.payload,
    // };
    // break;

    default:
      newState = state;
      break;
  }
  return newState;
};

export default AlertReducer;
