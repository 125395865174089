import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";


const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.email || "",
});
function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

// export const login = (params, authContext) => (dispatch) => {
export const logout = (params) => (dispatch) => {
  let headers = {
    // OrganizationUnit: authContext.organizationUnit,
    // userid: authContext.email,
    "X-Requested-With": "XMLHttpRequest",
  };
  Get(AppURL.logoutURL, params, headers, true)
    .then((res) => res.json())
    .then((res) => {
    //   if (res?.Response?.Status === 'SUCCESS' && res?.Response?.Message === 'Login successful') {
    //     // console.log(res.Response["Spoc-Details"]);
    //     dispatch({
    //       type: types.SET_AUTH_CONTEXT,
    //       payload: res.Response["Spoc-Details"],
    //     })
    //   } else {
    //     dispatch({
    //       type: types.LOGIN_ERROR,
    //       payload: res.Response.Message,
    //     });
    //   }
    deleteAllCookies();
    console.log(res);
    });
};