import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { useSelector } from "react-redux";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import { useTranslation } from "react-i18next";
import { Pager } from "@progress/kendo-react-data-tools";
import { Popup } from "@progress/kendo-react-popup";
import { Window } from "@progress/kendo-react-dialogs";

import columns from "./columns";
import { isColumnActive } from "../../../../ui/grid/CustomColumnMenu";
import useLogout, { checkInvalidSession } from "../../../../../utils/useLogout";
import { getSearchPlan } from "../../../../../store/actions/workOrder";
import LoadMask from "../../../../../utils/LoadMask";
import {
  getCopyPlanService,
  getPreviousJob,
} from "../../../../../store/actions/workOrder/prefill";
import Header from "../../../../header";
import { showToast } from "../../../../../utils/Notification";
import moment from "moment";
import { RadioGroup } from "@progress/kendo-react-inputs/dist/npm/radiobutton/RadioGroup";
import { postLockStatus } from "../../../../../store/actions/workOrder/actionWorkOrder";
const MyPager = (props) => {
  const anchor = useRef(null);
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };
  return (
    <div style={{ display: "flex" }} className="k-grid-pager">
      <div
        className="niq-refresh-button"
        onClick={props?.onRefreshGrid}
      >
        {/* REFRESH ICON.SVG */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="rgba(0,0,0,.9)"
        >
          <path d="M463.5 224h8.5c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2S461.9 48.1 455 55l-41.6 41.6c-87.6-86.5-228.7-86.2-315.8 1-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2S334.3 224 344 224h119.5z"></path>
        </svg>
      </div>
      <Pager style={{ flex: 1 }} {...props} />
      <div
        style={{
          // width: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTop: "1px solid #dee2e6",
        }}
      >
        <button
          className="k-button k-primary k-button-md k-rounded-md k-button-solid k-button-solid-base"
          style={{ marginRight: 10 }}
          onClick={toggleShow}
          ref={anchor}
        >
          Save View
        </button>
      </div>
    </div>
  );
};

const alertLIstPageData = {
  take: 20,
  skip: 0,
};
const createDataState = (data, dataState) => {
  return {
    result: {
      data,
      total: dataState.take,
    },
    dataState,
  };
};
const DATA_ITEM_KEY = "JobId";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const PreviousJob = ({ setPreviousJob }) => {
  const workOrderDetail = useSelector(
    (state) => state.commonReducer.workOrderDetail
  );
  const actionWorkOrderDetail = useSelector(
    (state) => state.commonReducer.actionWorkOrderDetail
  );
  const location = useLocation();
  // const workOrderDetail = location.state?.workOrderDetail;
  const { t } = useTranslation();
  const history = useHistory();
  const [windowShow, setWindowShow] = useState(false);
  const { handleAuthContext } = useLogout();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const initialState = createDataState([], alertLIstPageData);
  const [data, setData] = useState(null);
  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [filter, setFilter] = useState(null);

  const [showLoader, setShowLoader] = useState(null);
  const [selectedState, setSelectedState] = useState({});

  const [stateColumns, setStateColumns] = useState(columns);
  const [sort, setSort] = useState([
    {
      field: "JobId",
      dir: "asc",
    },
  ]);

  const [shouldRefresh, setShouldRefresh] = useState(false);
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || columns);
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const [isOverwrite, setOverwrite] = useState(false);

  const dataStateChange = (event) => {
    setDataState(event.dataState);
  };

  const onPageChange = (e) => {
    const dataState = e.page;
    setDataState(dataState);
    setFilter(null);
    const fetchSearchPlan = async () => {
      // const params = {
      //   // CompanyID: 381,
      //   CompanyID: authContext?.CompanyID,
      //   page: Math.floor(dataState.skip / dataState.take) + 1,
      //   StartCount: dataState.skip,
      //   MaxCount: dataState.take,
      // };
      const params = {
        CollectionType: workOrderDetail.CollectionType,
        CountryID: workOrderDetail.Country,
        CompanyId: workOrderDetail.CompanyID,
        WeekNo: workOrderDetail.week,
        CurrentJobID: workOrderDetail.JobId,
        // _dc: "1675683909014",
        page: Math.floor(dataState.skip / dataState.take) + 1,
        StartCount: dataState.skip,
        MaxCount: dataState.take,
      };
      setShowLoader({
        className: ".workOrders_grid",
        msg: "loading",
      });
      // const res = await getSearchPlan(params, authContext);
      const res = await getPreviousJob(params, authContext);
      console.log("res", { res });
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }
      // if (res?.Status === "Success" && Array.isArray(res?.SearchPlan)) {
      //   const newResult = {
      //     data: res?.SearchPlan || [],
      //     total: res?.rowCount || res?.SearchPlan?.length || dataState.take,
      //   };
      //   setData(newResult);
      // } else {
      //   console.log("search plan api error", res);
      // }
      if (res?.Plan?.Status === "Success" && Array.isArray(res?.Plan?.Jobs)) {
        const newResult = {
          data: res?.Plan?.Jobs || [],
          total:
            res?.Plan?.rowCount || res?.Plan?.Jobs?.length || dataState.take,
        };
        setData(newResult);
      } else {
        console.log("search plan api error", res);
      }
      setShowLoader(null);
    };
    fetchSearchPlan();
  };

  const refreshGrid = () => {
    console.log("refresh grid");
    setShouldRefresh(true);
  };

  const getActionWorkorderStatus = useMemo(() => {
    return actionWorkOrderDetail.data.filter((obj) => obj.Status !== 'NEW')
  }, [actionWorkOrderDetail]);

  const getGridData = useMemo(() => {
    return {
      ...data,
      data: data?.data.map((d) => {
        if (!isNaN(d.JobId)) {
          d.JobId = parseInt(d.JobId);
        }
        if (!isNaN(d.year)) {
          d.year = parseInt(d.year);
        }
        if (!isNaN(d.week)) {
          d.week = parseInt(d.week);
        }

        // d.CreatedOn = new Date(d.CreatedOn);
        const StartDate = new Date(d?.StartDate);
        if (StartDate !== "Invalid Date") {
          d.StartDate = moment(StartDate).format("YYYY-MM-DD HH:mm:ss");
        }
        const EndDate = new Date(d?.EndDate);
        if (EndDate !== "Invalid Date") {
          d.EndDate = moment(EndDate).format("YYYY-MM-DD HH:mm:ss");
        }
        // d.UpdatedOn = new Date(d.UpdatedOn);
        d[SELECTED_FIELD] = false;
        return d;
      }),
    };
  }, [data]);

  useEffect(() => {
    if (data) {
      setResult(getGridData);
    }
  }, [data]);

  useEffect(() => {
    // console.log("workOrder-history", history?.location?.state?.JobId);
    console.log("search window api");
    if (authContext && dataState) {
      const fetchSearchPlan = async () => {
        // const params = {
        //   CompanyID: authContext?.CompanyID,
        //   page: Math.floor(dataState.skip / dataState.take) + 1,
        //   StartCount: dataState.skip,
        //   MaxCount: dataState.take,
        // };
        // const params = {
        //   CollectionType: "NSO",
        //   CountryID: "76",
        //   CompanyId: "377",
        //   WeekNo: "5",
        //   CurrentJobID: "16040",
        //   _dc: "1675683909014",
        //   page: "1",
        //   StartCount: "0",
        //   MaxCount: "20",
        // };
        const params = {
          CollectionType: workOrderDetail.CollectionType,
          CountryID: workOrderDetail.Country,
          CompanyId: workOrderDetail.CompanyID,
          WeekNo: workOrderDetail.week,
          CurrentJobID: workOrderDetail.JobId,
          // _dc: "1675683909014",
          page: "1",
          StartCount: "0",
          MaxCount: "20",
        };
        const JobId = history.location.state?.JobId;
        if (JobId) {
          params.keyword = JobId;
        }
        setShowLoader({
          className: ".workOrders_grid",
          msg: "loading",
        });
        const res = await getPreviousJob(params, authContext);
        if (checkInvalidSession(res)) {
          handleAuthContext();
          return;
        }
        console.log("res", { res });
        if (checkInvalidSession(res)) {
          handleAuthContext();
          return;
        }
        if (res?.Plan?.Status === "Success" && Array.isArray(res?.Plan?.Jobs)) {
          const newResult = {
            data: res?.Plan?.Jobs || [],
            total:
              res?.Plan?.rowCount || res?.Plan?.Jobs?.length || dataState.take,
          };
          setData(newResult);
        } else {
          console.log("search plan api error", res);
        }
        setShowLoader(null);
      };
      fetchSearchPlan();
    }
  }, [authContext]);

  const GridCell = ({ dataItem, field }) => {
    const dataValue = dataItem?.[field];
    switch (field) {
      case "UploadedOn": {
        return (
          <td
            className="col-xs-12 col-sm-7 example-col "
            style={{ textAlign: "center" }}
            title={dataValue}
          >
            {moment(dataValue).format("MM/DD/YYYY HH:mm A")}
          </td>
        );
      }
      default:
        return (
          <td
            className="col-xs-12 col-sm-7 example-col"
            style={{ textAlign: "center" }}
            title={dataValue}
          >
            {dataValue?.toString()}
          </td>
        );
    }
  };

  const onSelectionChange = React.useCallback(
    (event) => {
      setSelectedRow(event.dataItem);
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);
  // const gridData = useMemo(()=>{
  //   const newResult = result?.data.map(item => ({
  //     ...item,
  //     [SELECTED_FIELD]: selectedState[idGetter(item)]
  //   }))
  //   // setResult({...result,result: newResult});
  //   return {...result,result: newResult}
  // },[selectedState])

  const onCopyBtnClick = async (e) => {
    if (!selectedRow) {
      showToast("error", t(`pleaseselectarecord`));
      return;
    }
    setWindowShow(true);
    // if (workOrderDetail?.Status !== "NEW") {

    // }
  };
  const onYesBtnClick = async (e) => {
    // console.log("Api call",{selectedRow,workOrderDetail});
    // return;
    const params = {
      CurrentJobId: workOrderDetail?.JobId,
      CollectionType: workOrderDetail?.CollectionType,
      PreviousJobId: selectedRow?.JobId,
      user: authContext?.SpocMail,
      CompanyId: workOrderDetail?.CompanyID,
      CountryId: selectedRow?.Country_id,
      Overwrite: isOverwrite,
    };
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await getCopyPlanService(params, authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    console.log("copyplanservice", { res });
    if (res?.CopyPlanResponse?.Status?.statusCode !== "100") {
      showToast("error", res?.CopyPlanResponse?.Status?.statusMsg);
      return;
    }
    showToast("success", res?.CopyPlanResponse?.Status?.statusMsg);
    const lockPram = {
      JobId: workOrderDetail?.JobId,
      LockStatus: "0",
      User: authContext?.SpocMail,
    };
    postLockStatus(lockPram, authContext);
    history.push("/work_orders");
  };
  const radioData = [
    {
      label: "Copy only resources not assigned",
      value: false,
    },
    {
      label: "Override all existing resources",
      value: true,
    },
  ];
  const radioDataNew = [
    {
      label: `${t("areyousureyouwanttocopy")
        .replace("Xxxx", workOrderDetail?.CollectionType)
        .replace("!!!!", selectedRow?.JobId)}`,
      value: false,
    },
  ];
  const onWindowClose = () => setWindowShow(false);

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {windowShow && (
        <Window
          className="shadow"
          title={"Are you sure you want to"}
          onClose={onWindowClose}
          initialWidth={480}
          initialHeight={200}
          minimizeButton={() => null}
          maximizeButton={() => null}
          restoreButton={() => null}
        //   modal={true}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "74%",
            }}
          >
            {/* {JSON.stringify(workOrderDetail)} */}
            {workOrderDetail?.Status !== "NEW" || getActionWorkorderStatus.length > 0 ? (
              <RadioGroup
                data={radioData}
                defaultValue={radioData[0].value}
                onChange={(e) => setOverwrite(e?.value)}
                layout={"vertical"}
              />
            ) : (
              <RadioGroup
                data={radioDataNew}
                defaultValue={radioDataNew[0].value}
                onChange={(e) => setOverwrite(e?.value)}
                layout={"vertical"}
              />
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 15 }}>
            <Button primary onClick={onYesBtnClick}>
              YES
            </Button>
            <Button onClick={onWindowClose}>CANCEL</Button>
          </div>
        </Window>
      )}
      <Header
        activeMenu={"Pre fill"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "6px",
            marginRight: 15,
            gap: 15,
          }}
        >
          <>
            <Button
              className="k-ghost"
              onClick={() => setPreviousJob(false)}
            >
              Go Back
            </Button>
            <Button primary onClick={onCopyBtnClick} style={{ width: 100 }} >
              Copy
            </Button>
          </>
          <Button className="k-secondary" style={{ width: 100 }} onClick={() => history.goBack()}>
            Cancel
          </Button>
        </div>
      </Header>
      <Grid
        className="workOrders_grid"
        style={{ height: "80vh", margin: 15 }}
        data={{
          ...result,
          data: result?.data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          })),
        }}
        // total={planList?.rowCount || planList?.SearchPlan?.length}
        total={parseInt(result?.total)}
        onDataStateChange={dataStateChange}
        pageable={{
          pageSizes: false,
        }}
        {...dataState}
        onPageChange={onPageChange}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          console.log("sort", { e });
          setResult((result) => {
            const { data, total } = result;
            return {
              data: orderBy(data || [], e.sort),
              total,
            };
          });
          setSort(e.sort);
        }}
        filter={filter}
        onFilterChange={(e) => {
          if (e.filter) {
            const { total, data } = getGridData;
            setResult({
              data: filterBy(data || [], e.filter),
              total,
            });
          } else {
            setResult(data);
          }
          setFilter(e.filter);
        }}
        // navigatable={true}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "single",
        }}
        reorderable={true}
        onSelectionChange={onSelectionChange}
        onRowClick={(e) => {
          setSelectedRow(e.dataItem);
          const KEY = e?.dataItem?.[DATA_ITEM_KEY];
          if (KEY) {
            setSelectedState({ [KEY]: true });
          }
        }}
        onHeaderSelectionChange={onHeaderSelectionChange}
        pager={(props) => (
          <MyPager
            {...props}
            refreshGrid={refreshGrid}
            onColumnsSubmit={onColumnsSubmit}
            stateColumns={stateColumns}
          />
        )}
        resizable
      >
        <GridColumn
          field={SELECTED_FIELD}
          width="30px"
          headerSelectionValue={
            result?.data?.findIndex(
              (item) => !selectedState[idGetter(item)]
            ) === -1
          }
          headerCell={() => null}
        />
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <GridColumn
                key={idx}
                field={column.field}
                title={t(column.title)}
                format={column?.format}
                cell={GridCell}
                // columnMenu={(originalProps)=>{
                //   console.log({originalProps})
                //   return column?.columnMenu?.({columns:stateColumns,onColumnsSubmit,originalProps})
                // }}
                filter={column?.filter}
                columnMenu={(props) => {

                  return column?.columnMenu?.({
                    ...props,
                    columns: stateColumns,
                    onColumnsSubmit,
                    data: data?.data || [],
                  });
                }}
                headerClassName={
                  isColumnActive(column.field, { ...dataState, filter })
                    ? "activeFiltered"
                    : ""
                }
                width={column?.width}
              />
            )
        )}
      </Grid>
    </>
  );
};

export default PreviousJob;
