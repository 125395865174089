import { SvgIcon } from '@progress/kendo-react-common';

const LogOutIcon = (props) => {
    return (
        <SvgIcon style={props?.style} width={props?.width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.65399 19.2362H18.9354V4.76382H8.65399V9.82915H6.89017V3H20.6992V21H6.89017V14.1709H8.65399V19.2362Z" fill={props?.color ?? "#4F4F4F"} />
            <rect x="3" y="11" width="9" height="2" fill={props?.color ?? "#4F4F4F"} />
            <path d="M15.8789 12.0002L11.4694 14.5461L11.4694 9.45439L15.8789 12.0002Z" fill={props?.color ?? "#4F4F4F"} />
        </SvgIcon>
    )
}

export default LogOutIcon