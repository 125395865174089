import { CustomColumnMenuFilter } from "../../ui/grid/CustomColumnMenu";

export const columnMenuConfig = {
  CustomColumnMenuFilter,
};

export const columns = [
  {
    title: "BatchId",
    field: "BatchId",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
    width: 130,
  },
  {
    title: "filename",
    field: "FileName",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 150,
  },
  {
    title: "Status",
    field: "Status",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "boolean",
    width: 130,
  },
  {
    title: "uploadedby",
    field: "UploadedBy",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 180,
  },
  {
    title: "uploadedon",
    field: "UploadedOn",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    width: 150,
    // filter: "date",
    // format: "{0:MM-dd-yyyy}",
  },
  {
    title: "totalrecords",
    field: "TotalRecords",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
    width: 130,
  },
  {
    title: "Success",
    field: "SucessRecords",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
    width: 130,
  },
  {
    title: "failure",
    field: "ErrorRecords",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
    // width: 130,
  },
];
