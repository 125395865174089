import React, { useMemo, useState } from "react";
import TabHeader from "../TabHeader";
import { selectCollectionTypes } from "../../../../../store/reducer/people";
import { useSelector } from "react-redux";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

const getInitialSelectedSkills = (initalSkills) => {
  const initialSelectedSkillsData = [];
  if (initalSkills?.[0]) {
    const splitInitialSkills = initalSkills?.[0].split(",");
    splitInitialSkills?.map((skill) => {
      initialSelectedSkillsData.push({ ProductName: skill });
    })
  }
  if (Boolean(initalSkills?.[1])) {
    initialSelectedSkillsData.push({ ProductName: "Other" });
  }
  return initialSelectedSkillsData;
};
const arrToStr = (selectedSkills) => {
  return selectedSkills.reduce((acc, cur, i) => {
    const { ProductName } = cur;
    if (ProductName === "Other") {
      return acc;
    }
    return `${acc}${i ? "," : ""}${ProductName}`;
  }, "");
};
const Skills = ({ initialFormState, setFormState, ...props }) => {
  const collectionTypes = useSelector(selectCollectionTypes);
  const initalSkills = initialFormState?.SKILLS?.split("|");

  const intialOtherSkills = initalSkills?.[1] || "";
  const [selectedSkills, setSelectedSkills] = useState(
    getInitialSelectedSkills(initalSkills)
  );
  console.log({ selectedSkills });

  const [isOtherField, setOtherField] = useState(Boolean(intialOtherSkills));
  const [otherSkills, setOtherSkills] = useState(intialOtherSkills);

  const handleSkillsChange = (e) => {
    // const { value } = e.value;
    const { value } = e;
    const OtherField = "Other";
    const isOtherExist = value?.some((d) => d?.ProductName === OtherField);
    setOtherField(isOtherExist);
    setSelectedSkills(value);
    let SKILLS = value?.length ? arrToStr(value) : "";
    if (isOtherExist) {
      SKILLS = `${SKILLS}|${otherSkills}`;
    }
    console.log({ SKILLS });
    setFormState((prev) => ({ ...prev, SKILLS }));
  };

  const collectionTypesData = useMemo(() => {
    return [...collectionTypes, { ProductName: "Other" }];
  }, []);

  const handleOtherSkillsChange = (e) => {
    const { value } = e.target;
    setOtherSkills(value);
    let OTHERSKILLS = arrToStr(selectedSkills);
    OTHERSKILLS = `${OTHERSKILLS}|${value}`;
    setFormState((prev) => ({ ...prev, SKILLS: OTHERSKILLS }));
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <div style={{ display: props.activeTab === "skills" ? "block" : "none" }}>
      <TabHeader {...props} />
      <div
        style={{
          display: "flex",
          // flexDirection: "column",
          // gap: ".6em",
          // overflowY: "auto",
        }}
      >
        <div>
          <MultiSelect
            className="k-form-field"
            placeholder="Skills"
            style={{ width: 200, margin: "1em 0" }}
            data={collectionTypesData || []}
            onChange={handleSkillsChange}
            value={selectedSkills}
            itemRender={itemRender}
            autoClose={false}
            textField="ProductName"
            valueField="ProductName"
            dataItemKey="ProductName"
          />
          {isOtherField && (
            <>
              <label>Other Skills</label>
              <input
                className=" k-textbox"
                placeholder="Other Skills"
                value={otherSkills}
                style={{ width: "200px" }}
                onChange={handleOtherSkillsChange}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Skills;
