import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  FormComboBox,
  FormInput,
} from "../../../form/form-component";
import { inputValidator } from "../../../form/validator";
import columns, { columnMenuConfig } from "./columns";
import { Grid, GridCell, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Pager } from "@progress/kendo-react-data-tools";
import { Popup } from "@progress/kendo-react-popup";
import LoadMask from "../../../../utils/LoadMask";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../utils/Notification";
import { updateUserPreferenceAction } from "../../../../store/actions/commonActions";
import {
  isColumnActive,
} from "../../../grid/CustomColumnMenu";
import { useTranslation } from "react-i18next";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { exportProactiveReport, getProactiveReport } from "../../../../store/actions/reports/GNIC/proactive";
import { getSearchPlan } from "../../../../store/actions/workOrder";
import { searchUserPrefrence } from "../../../../store/actions/dashboard";
import { setOrderIndex } from "../../../../utils/Utility";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";
import { MyPager } from "../../../generalHooks";
import Header from "../../../header";
import { getExportDataWithPostTimeoutHandler } from "../../eCollection/summaryReport/common/getSummaryReport";

const GRID_NAME = "NIC_PROACTIVE_GRID"

const createDataState = (data, dataState) => {
  return {
    result: {
      data,
      take: dataState.take,
    },
    dataState: dataState,
  };
};

let initialState = createDataState([], {
  take: 50,
  skip: 0,
});

const initialColumnsState = setOrderIndex(columns)

const ProactiveReport = () => {
  const { handleAuthContext } = useLogout();
  const [showForm, setShowForm] = useState(true);
  const serverColumns = useSelector(
    (state) => state.commonReducer.gridUserPrefrence
  );
  const [stateColumns, setStateColumns] = useState(initialColumnsState);
  const [data, setData] = useState({ data: [], total: 0 });
  const [showLoader, setShowLoader] = useState(null);
  const [formWorkOrderId, setFormWorkOrderId] = useState([]);

  const [weekNumber, setWeekNumber] = useState([]);
  const [year, setYear] = useState([]);

  const dispatch = useDispatch();
  const [formEQ, setFormEQ] = useState([]);
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const { t } = useTranslation();
  const [expandIcon, setExpandIcon] = useState("fullscreen");

  const history = useHistory();
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const dateStamp = useSelector((store) => store?.reports?.dateStamp);

  const authContext = useSelector((state) => state.commonReducer.authContext);


  const initialFormValue = useMemo(() => {
    return {
      Region: locationData?.Region?.RegionID,
      Country: locationData?.Country?.[0]?.CountryID,
      year: dateStamp?.selectedYear?.DisplayValue.toString(),
      week: dateStamp?.selectedWeek?.DisplayValue.toString(),
    };
  }, [locationData]);
  const [selectedData, setSelectedData] = useState(initialFormValue);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState([
    {
      field: "BatchId",
      dir: "asc",
    },
  ]);

  const dataStateChange = (event) => {
    console.log(event);
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  useEffect(() => {
    setSelectedData({
      ...selectedData,
      Region: locationData?.Region?.RegionID,
      Country: locationData?.Country?.[0]?.CountryID,
      year: dateStamp?.selectedYear?.DisplayValue.toString(),
      week: dateStamp?.selectedWeek?.DisplayValue.toString(),
    });
  }, [locationData?.Country, dateStamp]);
  useEffect(() => {
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStateColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);


  const onExport = async () => {
    setShowLoader({
      // className: ".workOrders_grid",
      msg: "loading",
    });

    showToast(
      "info",
      t('filedownload_msglbl')
    );


    let params2 = {
      "CollectionType": "GNIC",
      "ReportName": "PROACTIVE_REPORT",
      "APIName": "exportProactiveReport",
      "Payload": {
        ProactiveCodeReportRequest: {
          FIlterParams: {
            Region: selectedData?.Region,
            Country: selectedData?.Country,
            CollectionType: "GNIC",
            Year: selectedData?.year,
            Week: selectedData?.week,
            WorkOrderID: "",
            NavigatorAuditorID: selectedData?.auditorId,
            StoreID: selectedData?.smsId,
            CompanyID: authContext.CompanyID,
          }
        }
       
      }
    }

    const params = {


      ProactiveCodeReportRequest: {
        FIlterParams: {
          Region: selectedData?.Region,
          Country: selectedData?.Country,
          CollectionType: "GNIC",
          Year: selectedData?.year,
          Week: selectedData?.week,
          WorkOrderID: "",
          NavigatorAuditorID: selectedData?.auditorId,
          StoreID: selectedData?.smsId,
          CompanyID: authContext.CompanyID,
        },
      },

    };
    // await exportProactiveReport(JSON.stringify(params), authContext);
    await getExportDataWithPostTimeoutHandler(params2, authContext)
    setShowLoader(null);
  };

  const expand = () => {
    setShowForm(!showForm);
    setExpandIcon(!showForm ? "fullscreen" : "fullscreen-exit");
  };
  const fetchSearchPlan = async (params) => {
    setShowLoader({
      // className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await getSearchPlan(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (!res?.SearchPlan) {
      showToast("error", t("workorderisnotavailableforsearchedweek"));
    }
    setFormEQ(res?.SearchPlan);
    setShowLoader(null);
  };

  useEffect(() => {
    const searchPlan = () => {
      let params = {
        Region: selectedData.Region,
        Country: selectedData.Country,
        CollectionType: "GNIC",
        year: selectedData.year,
        week: selectedData.week,
        CompanyID: authContext.CompanyID,
      };
      fetchSearchPlan(params);
    };
    searchPlan();
  }, []);

  const onChangeWeekNumber = async (e) => {
    onComboboxChange(e);
    if (!e.value) {
      return;
    }
    let params = {
      Region: selectedData.Region,
      Country: selectedData.Country,
      CollectionType: "GNIC",
      year: selectedData.year,
      week: e.value,
      CompanyID: authContext.CompanyID,
    };

    fetchSearchPlan(params);
  };
  const handleCountryChange = (e) => {
    onComboboxChange(e);
    console.log(e, "countrychange");
    let params = {
      Region: selectedData.Region,
      Country: e.value,
      CollectionType: "GNIC",
      year: selectedData.year,
      week: selectedData.week,
      CompanyID: authContext.CompanyID,
    };
    fetchSearchPlan(params);
  };

  useEffect(() => {
    let workOrder_id = [];
    let weekNumberData = [];
    let yearData = [];

    formEQ?.map((elm) => workOrder_id.push(elm.JobId));
    setFormWorkOrderId([...new Set(workOrder_id)]);

    dateStamp?.weekData?.map((elm) => weekNumberData.push(elm.DisplayValue));
    setWeekNumber([...new Set(weekNumberData)]);

    dateStamp?.yearData?.map((elm) => yearData.push(elm.DisplayValue));
    setYear([...new Set(yearData)]);
  }, [formEQ, dateStamp?.weekData, dateStamp?.yearData]);

  const onChange = (e) => {
    setSelectedData({
      ...selectedData,
      [e.target.name]: e.value,
    });
  };

  const onChangeYear = (e) => {
    console.log("yearchangell", e)
    setSelectedData({
      ...selectedData,
      year: e.value,
    });
  };


  const onComboboxChange = (e) => {
    setSelectedData({
      ...selectedData,
      [e.target.component.name]: e.value,
    });
  };

  // On Search
  const onSearchClick = (e) => {
    if (!e.isValid) {
      return;
    }
    setDataState((p) => ({ ...p, skip: 0 }));
    fetchProactiveReport()
  };
  const fetchProactiveReport = async (newParams = {}) => {

   

    const params = {

      ProactiveCodeReportRequest: {
        FIlterParams: {
          Region: selectedData.Region,
          Country: selectedData.Country,
          CollectionType: "GNIC",
          Year: selectedData.year.toString(),
          Week: selectedData.week.toString(),
          StoreID: selectedData.smsId || "",
          CompanyID: authContext.CompanyID,
          NavigatorAuditorID: selectedData?.auditorId,
          // WorkOrderID: selectedData?.smsId,
        },
        PageFilterParams: {
          page: "1",
          startCount: "0",
          maxCount: "50",
          sort: null,
        }
        
      },

      // page: 1,
      // StartCount: 0,
      // MaxCount: 50,
      ...newParams
    };

    const params2 = {
      "CollectionType": "GNIC",
      "ReportName": "PROACTIVE_REPORT",
      "APIName": "getProactiveReport",
      "Payload": params
    }


    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });

    const res = await getProactiveReport(JSON.stringify(params2), authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.ProactiveCodeReportResponse?.StatusMsg === "Success" && Array.isArray(res?.ProactiveCodeReportResponse?.ProactiveDataList)) {
      res?.ProactiveCodeReportResponse.ProactiveDataList.map((cn) => {
        const realCountry = locationData?.Country.filter(({ CountryID }) => (CountryID === cn.Country))
        cn.Country = realCountry?.[0]?.CountryName
      });

      const result = {
        data: res?.ProactiveCodeReportResponse.ProactiveDataList,
        total: res?.ProactiveCodeReportResponse.TotalCount,
      };
      setData(result);
    } else {
      const result = {
        data: [],
        total: 0,
      };
      setData(result);
      showToast(
        "error",
        t(
          "dataNotFound",
          `${res?.ProactiveCodeReportResponse?.StatusMsg || "No Data Found"}`
        )
      );
    }
    setShowLoader(null);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || initialColumnsState);
  };

  const handleSaveViewClick = async () => {
    const Json_Object = JSON.stringify(stateColumns);
    const params = {
      UserPrefReq: {
        Email: authContext.SpocMail,
        Company_ID: authContext.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: selectedData.Country,
        Json_Object,
      },
    };
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast(
        "success",
        t(res?.Response?.Message || "Success")
      );
    } else {
      showToast(
        "error",
        t(res?.Response?.Message || "dataNotFound")
      );
    }
    setShowLoader(null);
  };

  const handleDefaultViewClick = () => {
    onColumnsSubmit();
  };


  const CreatePager = useCallback((props) => (
    <MyPager
      {...props}
      onSaveViewClick={handleSaveViewClick}
      onDefaultViewClick={handleDefaultViewClick}
    >
      <Pager style={{ flex: 1 }} {...props} />
    </MyPager>
  ), [stateColumns]);

  const backToReports = () => {
    history.goBack();
  };

  useEffect(() => {
    if (data) {
      setResult({
        data: data?.data.map((d) => {
          columns.forEach((col) => {
            if (col.filter === "numeric") {
              const dataValue = d[col.field]
              if (!isNaN(dataValue)) {
                d[col.field] = parseInt(dataValue);
              }
            }
            if (col.filter === "date") {
              const dateValue = new Date(d[col.field]);
              if (dateValue !== "Invalid Date") {
                d[col.field] = dateValue;
              }
            }
          })
          return d;
        }),
        ...data,
      });
    }
  }, [data]);
  const handlePageChange = async (e) => {
    const dataState = e.page;
    setDataState(dataState);
    fetchProactiveReport({
      page: Math.floor(dataState.skip / dataState.take) + 1,
      StartCount: dataState.skip,
      MaxCount: dataState.take,
    });
  };

  const initialValues = {
    regionID: locationData?.Region?.RegionID,
    countryID: locationData?.Country?.[0]?.CountryID,
    year: dateStamp?.selectedYear,
    week: dateStamp?.selectedWeek,
  };
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}

      <div className="eq">
        <Header activeMenu={t("nielsennavigator-proactivecodingreport")}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
              marginInline: "auto 1em",
              padding: '6px'
            }}
          >
            <Button
              className='k-secondary'
              onClick={backToReports}
            >
              Back
            </Button>
          </div>
        </Header>
        {showForm ? (
          <div className="eqform">
            <Form
              initialValues={initialValues}
              onSubmitClick={onSearchClick}
              render={(formRenderProps) => (
                <FormElement>
                  <div
                    style={{
                      display: "flex",
                      gap: "0px 25px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginBottom: "15px",
                    }}
                  >
                    <Field
                      name={"Region"}
                      component={FormComboBox}
                      label={t("REGION")}
                      required={true}
                      onChange={onChange}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      textField="RegionName"
                      valueField="RegionID"
                      data={[locationData?.Region]}
                      defaultValue={locationData?.Region?.RegionID}
                    />

                    <Field
                      name={"Country"}
                      component={FormComboBox}
                      label={t("COUNTRY")}
                      required={true}
                      onChange={handleCountryChange}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      textField="CountryName"
                      valueField="CountryID"
                      data={locationData?.Country || []}
                      defaultValue={locationData?.Country?.[0]?.CountryID}
                    />

                    <Field
                      name="year"
                      label={t("YEAR")}
                      component={FormComboBox}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      placeholder={t("YEAR")}
                      data={year}
                      required={true}
                      defaultValue={dateStamp?.selectedYear?.DisplayValue}
                      validator={inputValidator}
                      onChange={onChangeYear}
                    // onChange={onChange}
                    />

                    <Field
                      name="week"
                      label={t("WeekNumber")}
                      component={FormComboBox}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      placeholder={t("WeekNumber")}
                      required={true}
                      data={weekNumber}
                      defaultValue={dateStamp?.selectedWeek?.DisplayValue}

                      validator={inputValidator}
                      onChange={onChangeWeekNumber}
                    />

                    <Field
                      name="auditorId"
                      label={t("AUDITOR ID")}
                      component={FormInput}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      placeholder={t("AUDITOR ID")}
                      onChange={onChange}
                    />

                    <Field
                      name="smsId"
                      label={t("storeid")}
                      component={FormInput}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      placeholder={t("storeid")}
                      onChange={onChange}
                    />

                    <div
                      className={"k-actions k-hstack k-justify-content-stretch"}
                      style={{
                        marginTop: "32px",
                        gap: "0px 8px",
                        marginLeft: "-5px",
                      }}
                    >
                      <Button primary>{t("SEARCH")}</Button>
                      <Button
                        type={'reset'}
                        className={'k-secondary'}
                        onClick={() => {
                          setSelectedData(initialFormValue);
                          setTimeout(() => {
                            formRenderProps.onFormReset();
                          }, 0);
                        }}
                      >
                        {t("RESET")}
                      </Button>
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </div>
        ) : (
          <div></div>
        )}

        <div
          className="nielsen-card"
          style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
        >
          <div className="eq_data_heading" style={{ margin: "0px -6px" }}>
            <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
            <div>
              <Button
                onClick={onExport}
                style={{ margin: "10px", border: "none" }}
                primary
              >{t("export")}</Button>

              <Button icon={expandIcon}
                onClick={expand}
                className={'k-secondary'}
                style={{ margin: "10px", marginRight: '20px' }}
              ></Button>
            </div>

          </div>
          <div className="catalog" style={{
            display: "flex",
            padding: "1rem",
            flexGrow: 1,
            overflow: "auto",
          }}
          >
            <Grid
              className="workOrders_grid"
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                console.log("sort", { e });
                setResult((result) => {
                  const { data, total } = result;
                  return {
                    data: orderBy(data || [], e.sort),
                    total,
                  };
                });
                setSort(e.sort);
              }}
              filter={filter}
              onFilterChange={(e) => {
                console.log("filter", e.filter, data);
                setResult((result) => {
                  const { total } = result;
                  return {
                    data: filterBy(data?.data || [], e.filter),
                    total,
                  };
                });
                setFilter(e.filter);
              }}
              pageable={{
                pageSizes: false,
              }}
              pager={CreatePager}
              onPageChange={handlePageChange}
              resizable
              reorderable
              onColumnReorder={({ columns }) => {
                const columnOrder = columns.reduce((columnsObj, column) => {
                  columnsObj[column.field] = column.orderIndex;
                  return columnsObj;
                }, {})
                const newColumns = stateColumns.map((column) => {
                  return { ...column, orderIndex: columnOrder[column.field] }
                });
                setStateColumns(newColumns);
              }}
            >
              {stateColumns.map((column, idx) => {
                const menu = columnMenuConfig[column?.columnMenu];
                return (
                  column.show && (
                    <GridColumn
                      key={idx}
                      field={column?.field}
                      title={t(column.title)}
                      width={column?.width}
                      minResizableWidth={30}
                      style={{ textAlign: "center" }}
                      cell={GridCell}
                      format={column?.format}
                      filter={column?.filter}
                      orderIndex={column?.orderIndex}
                      {...(menu && {
                        columnMenu: (props) => {
                          return menu?.({
                            ...props,
                            columns: stateColumns,
                            onColumnsSubmit,
                            data: data?.data,
                          });
                        },
                      })}
                      headerClassName={
                        isColumnActive(column.field, { ...dataState, filter })
                          ? "activeFiltered"
                          : ""
                      }
                    />
                  )
                );
              })}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProactiveReport;
