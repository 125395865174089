import { Get, Post, PostwithTimeoutHandler } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";
import { showToast } from "../../../../../utils/Notification";

interface GenerateReportParams {
    "GenerateReportReq": {
        "countryID": number;
        "user": string;
        "reportType": string;
        "transactionID": string
    }
}

interface RaSummaryReportParams {
    "GetNavAuditSummaryReq": {
        "audtior_id": string;
        "country_id": string;
        "limit": string;
        "plan_id": string;
        "sms_id": string;
        "start": string;
        "user_id": string;
    }
}

interface ExportHistoryParams {
    "countryID": string;
    "reportType": string;
}

interface DataState {
    take: number;
    skip: number;
}

export const generateReport = async (params: GenerateReportParams, authContext: any) => {
    const header = {
        OrganizationUnit: authContext?.organizationUnit || "",
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "content-type": "application/json",
    }

    const res = await Post(AppURL.generateReport, params, header, true).then((res) =>
        res.json()
    );
    return res;
};


export const getRaSummaryReport = async (params: RaSummaryReportParams) => {

    const header = {
        "content-type": "application/json",
    }

    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header, true,180000).then((res) =>
        res.json()
    );
    return res;
};

export const getExportHistoryData = async (params: ExportHistoryParams) => {

    const header = {
        "content-type": "application/json",
    }

    const res: any = await Get(AppURL.getRAAuditSummaryReportExportHistory, params, header, true).then((res) =>
        res.json()
    );
    return res;
};

export const createDataState = (data: Array<any>, dataState: DataState) => {
    return {
        result: {
            data,
            take: dataState.take,
        },
        dataState: dataState,
    };
};

export const getExportFile = async (params: { filename: string }) => {

    const header = {
        "content-type": "application/json",
    }

    try {
        const exportFile = await Get(AppURL.raFileDownload + '?' + params?.filename, null, header, true);
        console.log("exportFile", exportFile);
        if (exportFile.status === 200) {
            const exportFileBlob = await exportFile.blob();
            const exportFileUrl = URL.createObjectURL(exportFileBlob);
            const anchor = document.createElement("a");
            anchor.href = exportFileUrl;
            anchor.download = `${params?.filename}.zip`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            showToast(
                "success",
                "File Downloaded successfully",
            );
        } else {
            showToast(
                "error",
                "File Download failed, please try again later or contact support",
            );
        }
    } catch (error) {
        console.log("errorcatch", error);
        showToast(
            "error",
            "File Download failed, please try again later or contact support",
        );
    }
};