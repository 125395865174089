import React from "react";
import TabHeader from "../TabHeader";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
const UserDevice = ({
  selectedResourceDetails,
  initialFormState,
  setFormState,
  ...props
}) => {
  const deviceList = initialFormState?.DEVICES;
  const handleFormState = (DEVICES) => {
    setFormState((prev) => ({ ...prev, DEVICES }));
  };
  const addDevice = () => {
    const temp = deviceList?.slice() || [];
    temp?.push({
      DEVICE_ID: "",
      DEVICE_TYPE: "",
      DEVICE_STATUS: "",
      DEVICE_COMMENTS: "",
    });
    handleFormState(temp);
  };

  const removeDevice = (index) => {
    const temp = deviceList.slice();
    temp?.splice(index, 1);
    handleFormState(temp);
  };

  const onInputChange = (e, index) => {
    console.log(index, e.target.name, e?.target?.value);
    const name = e?.target?.name;
    const temp = deviceList?.slice();
    temp[index][name] = e?.target?.value || "";
    handleFormState(temp);
  };
  return (
    <div
      style={{
        display: props.activeTab === "userDevice" ? "block" : "none",
        height: "100%",
      }}
    >
      <TabHeader {...props}>
        <Button
          primary
          type="button"
          icon="plus"
          style={{ marginLeft: "auto" }}
          onClick={addDevice}
        >
          Add Device
        </Button>
      </TabHeader>
      <div style={{ overflow: "auto", paddingRight: "1em", height: "94%" }}>
        {!deviceList?.length ? (
          <h3
          // style={{ textAlign: "center" }}
          >
            No Devices
          </h3>
        ) : (
          deviceList?.map((d, i) => {
            return (
              <div
                // key={d?.DEVICE_ID + i}
                key={i}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #808080",
                    marginTop: "1.5em",
                  }}
                >
                  <div style={{ paddingInline: ".5em" }}>Device</div>
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      title="Remove Device"
                      type="button"
                      onClick={() => removeDevice(i)}
                    >
                      <span
                        className="k-icon-md k-icon k-i-trash"
                        style={{ color: "#fb087a" }}
                      />
                    </Button>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "4em" }}>
                  <div style={{ flex: 1, paddingBlock: ".5em" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBlock: ".5em",
                      }}
                    >
                      <div style={{ flex: 1 }}>Device Id</div>
                      <input
                        name="DEVICE_ID"
                        className="k-textbox"
                        // defaultValue={d?.DEVICE_ID}
                        value={d?.DEVICE_ID}
                        style={{ width: "200px" }}
                        onChange={(e) => onInputChange(e, i)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBlock: ".5em",
                      }}
                    >
                      <div style={{ flex: 1 }}>Type</div>
                      <input
                        name="DEVICE_TYPE"
                        className="k-textbox"
                        value={d?.DEVICE_TYPE}
                        style={{ width: "200px" }}
                        onChange={(e) => onInputChange(e, i)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBlock: ".5em",
                      }}
                    >
                      <div style={{ flex: 1 }}>Status</div>
                      <ComboBox
                        name="DEVICE_STATUS"
                        // defaultValue={d?.DEVICE_STATUS?.toUpperCase()}
                        value={d?.DEVICE_STATUS?.toUpperCase()}
                        data={["ACTIVE", "INACTIVE"]}
                        style={{ width: "200px" }}
                        onChange={(e) => onInputChange(e, i)}
                      />
                    </div>
                  </div>

                  <div style={{ flex: 2, paddingBlock: "1em" }}>
                    <label htmlFor="comment">Comment</label>
                    <textarea
                      id="comment"
                      name="DEVICE_COMMENTS"
                      className="k-textarea k-input"
                      style={{ height: "auto" }}
                      value={d?.DEVICE_COMMENTS}
                      rows={4}
                      onChange={(e) => onInputChange(e, i)}
                    />
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default UserDevice;
