import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    {
        title:  "WORKORDERID",
        field: "PLAN_ID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "AUDIT_ID",
        field: "AUDIT_INSTRUCTION_ID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "AUDITOR_ID",
        field: "CDAR_RESOURCE_ID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:  "AUDIT_DATE",
        field: "ACTUAL_AUDIT_DATE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "date",
        format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "STORE_ID",
        field: "CDAR_STORE_ID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "STORE_NAME",
        field: "STORE_NAME",
        show: true,
        columnMenu: "CustomColumnMenuFilter", 
        width: 130
        
    },
    {
        title: "STORE ADDRESS",
        field: "STORE_ADDRESS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
        
    },
    {
        title: "AuditStatus",
        field: "AUDIT_STATUS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
        
    },
    {
        title: "AUDIT START DATE/TIME",
        field: "AUDIT_START_DATE_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "AUDIT END DATE/TIME",
        field: "AUDIT_END_DATE_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "RESPONSE EXPORTED DATE/TIME",
        field: "RESPONSE EXPORTED DATE/TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "TOTAL STORE TIME",
        field: "TOTAL_STORE_TIME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "STORE_TASK",
        field: "STORE_TASK",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "RETAILER NUMBER",
        field: "RETAILER NUMBER",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:160
    },
    {
        title:  "DA STORE TYPE",
        field: "DA STORE TYPE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width:180
    },
    {
        title:  "TOTAL # OF IMAGES",
        field: "TOT_IRP_IMAGES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "TOTAL NUMBER OF ACCEPTED IMAGES",
        field: "TOT_ACCEPTED_IMAGES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "TOTAL NUMBER OF DECLINED IMAGES",
        field: "TOT_DECLINED_IMAGES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        //title: "Total Number Of Barcodes",
        title:"total#ofimagedbarcodes",
        field: "TOT_IMAGED_BARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "total#ofscannedbarcodes",
        field: "TOT_SCANNED_BARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title:  "# OF UNIQUE IMAGED BARCODES",
        field: "UNIQUE_IRP_IMAGE_BARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "# OF UNIQUE SCANNED BARCODES",
        field: "UNIQUE_SCANNED_BARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title:  "TOTAL # OF BARCODES COLLECTED",
        field: "TOT_COLLECTED_BARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title:  "TOT #OF UNIQUE BARCODES COLLECTED",
        field: "UNIQUE_COLLECTED_BARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "Week",
        field: "PLANNED_WEEK",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "Year",
        field: "PLANNED_YEAR",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "STORECLOSECOMMENTS",
        field: "STORE_CLOSE_COMMENTS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },

];
export default columns;
