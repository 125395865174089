import { getter } from '@progress/kendo-data-query';
import { getSelectedState, Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { putNRPSStoreStatusUpdate } from '../../../../../store/actions/reports';
import { CellDropDown, CellRender, RowRender } from '../../../../ui/grid/renders';
import ComboBoxGrid from './ComboBoxGrid';
import progressColumns from './progressColumns';
import { showToast } from '../../../../../utils/Notification';
import useLogout, { checkInvalidSession } from '../../../../../utils/useLogout';
import { PostwithTimeoutHandler } from '../../../../../store/methods';
import AppURL from '../../../../../utils/AppURL';

const EDIT_FIELD = 'inEdit';
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "smsId";
const idGetter = getter(DATA_ITEM_KEY);

const initialDataState = {
    skip: 0,
    take: 50,
  };

const ProgressGrid = ({height, callbackStoreId, commonFiltersParams}) => {

  // const editGridData = useSelector((store)=> store.reports?.nrpsStoreStatusUpdate)
  const progressData = useSelector((store)=> store.reports?.nrpsReportProgressClick);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  let spocDetails = JSON.parse(localStorage.getItem(`spocDetails`));
  

    const dispatch = useDispatch();
    const {handleAuthContext} = useLogout();
    const [page, setPage] = React.useState(initialDataState);
    // const [result, setResult] = React.useState(ProgressGrid)
    const [response, setResponse] = React.useState([]);
    // const [editGridResponse, setEditGridResponse] = React.useState([])
    const [selectedState, setSelectedState] = React.useState({});
    const [stateProgressColumns, setStateProgressColumns] = React.useState(progressColumns);
    const [companyId, setCompanyId] = React.useState([]);
    const [countaryCode, setCountaryCode] = React.useState([]);

    // const nrpsReportFiltersSearch = useSelector((store)=> store.reports?.nrpsReportFiltersSearch);
   
  React.useEffect(() => {
    setResponse(progressData?.data);      
  },[progressData]);

  console.log("response",response)

  // React.useEffect(() => {    
  //   setEditGridResponse(editGridData)
  // },[editGridData]);

  // console.log("editGridData",editGridData)
  // console.log("editGridResponse",editGridResponse)

  React.useEffect(() => {
    if (authContext && spocDetails) {      
      let CompanyId = spocDetails?.CompanyID
      let countryCode = spocDetails?.CountryCode
      setCompanyId(CompanyId);
      setCountaryCode(countryCode)               
   }
  }, [authContext]);

  const enterEdit = (dataItem, field) => {
    console.log("progResponse",response)
    const newData = response?.map(item => ({
      ...item,
      [EDIT_FIELD]: item.smsId === dataItem.smsId ? field : undefined
    }));
    setResponse(newData);
  };
 
  const exitEdit = async () => {
    console.log("progressDaTA",progressData?.data)
    const data = response.find((e)=>{
      return  e?.[EDIT_FIELD]
    })

   


    if(authContext){
      const params = {
        "vendorId": 282,
        // "vendorId": companyId,
        // "country": countaryCode,
        "country": 380,
        "year": commonFiltersParams?.year,
        "week": commonFiltersParams?.week,        
        "storeId": data?.smsId,
        "status": data?.storeStatus,
        "vendorNotes": data?.vendorNotes,
        "niqNotes": data?.niqNotes   
      }  
      
      const params2 = {
        "CollectionType": "ECOLLECTION",
        "ReportName": "NRPS_REPORT",
        "APIName": "NRPSStoreStatusUpdate",
        "Payload": {
           ...params
        }
    }

    const header = {
      OrganizationUnit: authContext?.organizationUnit || "",
      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid:authContext?.CompanyID || "",
      "content-type": "application/json",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }

    const res = await PostwithTimeoutHandler(AppURL.reportsAPI,params2, header,false).then((res) =>
      res.json()
    );

      console.log("exitres", res)
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }

      if(res.status == "Success") {
        // showToast("error", data?.Response?.Message, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        // response.map((item)=>{
        //   if(item?.smsId === data?.smsId){
        //     item.storeStatus = res.data
        //   }
        //   return item;
        // })
          
        }else {
          console.log("error ");
        //   if(data?.Response?.sessionstatus === "400")
        //   showToast("error", data?.Response?.Message, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        }  
               
  };
    // console.log("exitData", data)
    const newData = response?.map(item => ({
      ...item,
      [EDIT_FIELD]: undefined
    }));
    setResponse(newData);
  };

  // const itemChange = event => {
  //   let field = event.field || '';
  //   event.dataItem[field] = event.value;
  //   let newData = progressData?.data?.map(item => {
  //     if (item.smsId === event.dataItem.smsId) {
  //       item[field] = event.value;
  //     }
  //     return item;
  //   });
  //   setResponse(newData);
  //   // setChanges(true);
  // };

  const itemChange = (event) => {
    console.log("eventItem", event)
    let field = event.field || "";
    // const newValue = event.value;
    const { value, dataItem } = event;
    // event.dataItem[field] = value;
    let newData = response.map((item) => {
      if (item?.smsId === dataItem?.smsId) {
        const { edited = [] } = item;
        if (value !== dataItem?.[field]) {
          item.edited = [...edited, field];
        } else {
          item.edited = edited?.filter((fieldName) => fieldName !== field);
        }
        item[field] = value;
      }
      return item;
    });
    setResponse(newData);
    // setChanges(true);
  };

const pageChange = (event) => {
    setPage(event.page);
};

      const onSelectionChange = React.useCallback(
        (event) => {
          const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
          });
          setSelectedState(newSelectedState);
          callbackStoreId(newSelectedState);  
        },
        [selectedState]
      );
      console.log("selectionnnn",selectedState)

      const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
      }, []);       
      // callbackStoreId(selectedState);       


      const customCellRender = (td, props) => {
        console.log("customCellRender", props)
        // return <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;
        switch (props.field) {
          case "storeStatus":
            const data =  ["IN PROGRESS","COMPLETE","INCOMPLETE - STORE ","INCOMPLETE - VENDOR","INCOMPLETE-OTHER"] 
            // const data =  ["INPROGRESS","COMPLETE","INCOMPLETE",]  
            return (
              <CellDropDown
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={EDIT_FIELD}
                cellProps = {{data}}
                // cell={<ComboBoxGrid {...props}/>}
              />
            );          
          default:
            return (
              <CellRender
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={EDIT_FIELD}
              />
            );
        }
      }
      const customRowRender = (tr, props) => {
        return <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />
      };


  return (
    <React.Fragment>
       <Grid        
        resizable={true}
        data={response
          ?.slice(page.skip, page.take + page.skip)
          .map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
        style={height}
        skip={page.skip}
        take={page.take}
        total={response?.length}
        pageable={true}
        onPageChange={pageChange}
        // pageable={{
        //     pageSizes: [5, 20, 50, 100, 200, 500, 1000],
        //   }}
        dataItemKey={DATA_ITEM_KEY}   
        selectedField={SELECTED_FIELD}
        // selectable={{
        //   enabled: true,
        //   drag: false,
        //   cell: false,
        //   mode: "multiple",
        // }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}            
        editField={EDIT_FIELD}
        onItemChange={itemChange}
        cellRender={customCellRender}
        rowRender={customRowRender}
        >
        <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="40px"
        headerSelectionValue={
          response?.findIndex((item) => !selectedState[idGetter(item)]) === -1
        } />
        {stateProgressColumns.map((column, idx) => {
          return column.show && (                
            <GridColumn key={idx} field={column.field} title={column.title} width={column.width} editable={column.editable} cell={column.cell}/>
          );
        })}
        </Grid>
      {/* {showLoader && <LoadMask {...showLoader} />} */}
      {/* {(response)? <Grid        
        resizable={true}
        data={response
          ?.slice(page.skip, page.take + page.skip)
          .map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
        style={height}
        skip={page.skip}
        take={page.take}
        total={response?.length}
        pageable={true}
        onPageChange={pageChange}
        // pageable={{
        //     pageSizes: [5, 20, 50, 100, 200, 500, 1000],
        //   }}
        dataItemKey={DATA_ITEM_KEY}   
        selectedField={SELECTED_FIELD}
        // selectable={{
        //   enabled: true,
        //   drag: false,
        //   cell: false,
        //   mode: "multiple",
        // }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}            
        editField={EDIT_FIELD}
        onItemChange={itemChange}
        cellRender={customCellRender}
        rowRender={customRowRender}
        >
        <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="40px"
        headerSelectionValue={
          response?.findIndex((item) => !selectedState[idGetter(item)]) === -1
        } />
        {stateProgressColumns.map((column, idx) => {
          return column.show && (                
            <GridColumn key={idx} field={column.field} title={column.title} width={column.width} editable={column.editable} cell={column.cell}/>
          );
        })}
        </Grid> : <Grid        
        resizable={true}
        data={editGridResponse
          ?.slice(page.skip, page.take + page.skip)
          .map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
        style={height}
        skip={page.skip}
        take={page.take}
        total={editGridResponse?.length}
        pageable={true}
        onPageChange={pageChange}
        // pageable={{
        //     pageSizes: [5, 20, 50, 100, 200, 500, 1000],
        //   }}
        dataItemKey={DATA_ITEM_KEY}   
        selectedField={SELECTED_FIELD}
        // selectable={{
        //   enabled: true,
        //   drag: false,
        //   cell: false,
        //   mode: "multiple",
        // }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}            
        editField={EDIT_FIELD}
        onItemChange={itemChange}
        cellRender={customCellRender}
        rowRender={customRowRender}
        >
        <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="40px"
        headerSelectionValue={
          editGridResponse?.findIndex((item) => !selectedState[idGetter(item)]) === -1
        } />
        {stateProgressColumns.map((column, idx) => {
          return column.show && (                
            <GridColumn key={idx} field={column.field} title={column.title} width={column.width} editable={column.editable} cell={column.cell}/>
          );
        })}
        </Grid>} */}
        
    </React.Fragment>
  )
}

export default ProgressGrid
