import { CustomColumnMenuFilter } from "../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
  CustomColumnMenuFilter,
};

const columns = [  
  {
    title: "#",
    field: "ID",
    show: true,
    width: 100,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },  
  {
    title: "Recollect",
    field: "recollect",
    show: false,
    width: 150,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Collection Type",
    field: "CollectionType",
    show: true,
    width: 150,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Task Type",
    field: "Tasktype",
    show: false,
    width: 130,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Store Id",
    field: "cdar_store_id",
    show: true,
    width: 130,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    // filter: "numeric",
  },
  {
    title: "Sms Id",
    field: "StoreId",
    show: true,
    width: 100,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    // filter: "numeric",
  },
  {
    title: "Upc-count",
    field: "StoreI",
    show: false,
    width: 100,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "ORG CODE",
    field: "Stored",
    show: false,
    width: 100,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "Store Name",
    field: "StoreName",
    show: true,
    width: 180,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Address",
    field: "store_address",
    show: true,
    width: 180,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "City",
    field: "city",
    show: true,
    width: 180,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "State/Province",
    field: "state",
    show: true,
    width: 130,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Postal Code",
    field: "postal_code",
    show: true,
    width: 100,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    // filter: "numeric",
  },
  {
    title: "Frequency",
    field: "Frequency",
    show: true,
    width: 100,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Week Number",
    field: "week",
    show: true,
    width: 130,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "Nielsen Start Date",
    field: "fromdate",
    show: true,
    width: 150,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    // filter: "date",
    format: "{0:MM/dd/yyyy}",
  },
  {
    title: "Nielsen End Date",
    field: "todate",
    show: true,
    width: 150,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    // filter: "date",
    format: "{0:MM/dd/yyyy}",
  },
  {
    title: "Status",
    field: "Status",
    show: true,
    width: 180,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Last Updated By",
    field: "LastUpdBy",
    show: true,
    width: 200,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Bdd",
    field: "BDD",
    show: true,
    width: 100,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Primary Resource Name",
    field: "PRIMARY_RESOURCE_NAME",
    show: true,
    width: 180,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Primary Resource Id",
    field: "AuditorId",
    show: true,
    width: 180,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    editor: "numeric",
    // filter: "numeric",
  },
  {
    title: "Primary Resource Status",
    field: "AuditorStatus",
    show: true,
    width: 180,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Secondary Resource Name",
    field: "SECONDARY_RESOURCE_NAME",
    show: false,
    width: 250,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Secondary Resource Id",
    field: "SECONDARY_RESOURCE_IDS",
    show: false,
    width: 250,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Assigned UPC",
    field: "store_upc_count",
    show: false,
    width: 180,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    // path:"/work_orders/upcWinow",
  },
  {
    title: "Available UPC",
    field: "AuditorStatus",
    show: false,
    width: 180,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Default UPC",
    field: "AuditorStatus",
    show: false,
    width: 180,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Start Date",
    field: "StartDate",
    show: true,
    width: 150,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    // filter: "date",
    format: "{0:MM/dd/yyyy}",
  },
  {
    title: "End Date",
    field: "EndDate",
    show: true,
    width: 150,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    // filter: "date",
    format: "{0:MM/dd/yyyy}",
  },
  {
    title: "Visit Date",
    field: "Duedate",
    show: false,
    width: 150,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    // filter: "date",
    format: "{0:MM/dd/yyyy}",
  },
  {
    title: "Start Time",
    field: "StartTime",
    show: true,
    width: 130,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "End Time",
    field: "EndTime",
    show: true,
    width: 130,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Coll Type",
    field: "collType",
    show: true,
    width: 130,
    editable: true,
    editor: "text",
    isNullable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Limit Date",
    field: "limitDate",
    show: true,
    width: 150,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "date",
    format: "{0:MM/dd/yyyy}",
  },
  {
    title: "Limit Time",
    field: "limitTime",
    show: true,
    width: 130,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Collection Days",
    field: "collectionDays",
    show: true,
    width: 130,
    editable: true,
    editor: "numeric",
    isNullable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Estimated EANS",
    field: "estimatedEans",
    show: true,
    width: 130,
    editable: true,
    editor: "numeric",
    isNullable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Notes Of Collection",
    field: "noteCollection",
    show: true,
    width: 130,
    editable: true,
    isNullable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Remarks",
    field: "remarks",
    show: true,
    width: 180,
    editable: true,
    isNullable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Copy Plan Remarks",
    field: "copyplanremarks",
    show: false,
    width: 180,
    editable: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Upload Remarks",
    field: "UploadRemarks",
    show: true,
    width: 150,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Recollect Count",
    field: "RecollectCount",
    show: true,
    width: 150,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "Batch Id",
    field: "LastBatchId",
    show: true,
    width: 100,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "Job Action Status",
    field: "JobActionStatus",
    show: true,
    width: 180,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "Error Reason",
    field: "errorReason",
    show: true,
    width: 180,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  }
];
export default columns;
