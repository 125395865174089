import React from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";

const MessageBody = ({ windowConfig, data }) => {
  const { t, i18n } = useTranslation();
    // console.log("message body",data)
  return (
    <>
      <Window
      className="shadow"
        {...windowConfig}
        title={t('Email')}
        //   onClose={() => setUploadWindow(false)}
        // modal={true}
        //   minimizeButton={() => (
        //     <Button
        //       icon="question"
        //       onClick={() => setFormShow(!isFormShow)}
        //       style={{ marginRight: ".5em" }}
        //     />
        // //   )}
        // maximizeButton={() => null}
        // restoreButton={() => null}
        // initialTop={70}
        initialHeight={500}
        initialWidth={756}
        style={{ paddingBottom: "1em" }}
      >
        <div dangerouslySetInnerHTML={{__html:data}}/>
      </Window>
    </>
  );
};

export default MessageBody;
