import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    {
        title: "AUDIT DATE",
        field: "AUDIT_DATE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "date",
        // format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "auditid",
        field: "AUDIT_ID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:"AUDITOR_ID",
        field: "AUDITOR_ID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "SMS ID",
        field: "STORE_ID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "StoreName",
        field: "STORE_NAME",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "STORE INDEX",
        field: "STORE_INDEX",
        show: true,
        // cell:UploadCell
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "STORE MARKET",
        field: "STORE_MARKET",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title:  "STORE ADDRESS",
        field: "STORE_ADDRESS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "CITY",
        field: "CITY",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title:  "ZIPCODE",
        field: "ZIPCODE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "STORE STATE",
        field: "STORE_STATE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "STORE BDD DATE",
        field: "STORE_BDD_DATE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "exportedfrombb",
        field: "EXPORTED_FROM_BB",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "DEVICE MODEL",
        field: "DEVICE_MODEL",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "ORG CODE",
        field: "ORG_CODE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "Task Type",
        field: "Task_Type",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title:"TOTAL # OF DISPLAYS",
        field: "TOTAL_OF_DISPLAYS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "TOTAL #OF SCANNED BARCODES",
        field: "TOTAL_OF_SCANNED_BARCODES",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "WEEK",
        field: "WEEK",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "QUALITY RESULT",
        field: "QUALITY_RESULT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Quality Fail Reason",
        field: "QUALITY_FAIL_RESULT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "FAILURE REASON",
        field: "FAILURE_REASON",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "QUALITY BDD DAYS DIFF",
        field: "QUALITY_BDD_DAYS_DIFF",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title:  "QUALITY BDD",
        field: "QUALITY_BDD",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "AUDITOR PREDEFINED COMMENT",
        field: "AUDITOR_PREDEFINED_COMMENT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Store Close Comments",
        field: "Store_Close_Comments",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "LATE SYNC",
        field: "LATE_SYNC",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "INVENTORY COMMENTS",
        field: "INVENTORY_COMMENTS",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "INVENTORY WITH UPC %",
        field: "INVENTORY_WITH_UPC_PER",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "UPC COUNT INVENTORY",
        field: "UPC_COUNT_INVENTORY",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:  "TOTAL INVENTORY COUNT",
        field: "TOTAL_INVENTORY_COUNT",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "INVENTORY PERCENTAGE",
        field: "INVENTORY_PERCENTAGE",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "AVERAGE INVENTORY PER UPC",
        field: "AVG_INV_PER_UPC",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "Initial Store Co-ordinates",
        field: "Initial_Store_Co-ordinates",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
    },
    {
        title: "ImageCount",
        field: "imagecount",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "Geo Fail Reason",
        field: "imagecount",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "NONCOLLECTDISPLAYTIME_LBL",
        field: "NON_COLLECT_TIME_DISPLAY_MIN",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    {
        title: "Threshold",
        field: "Threshold",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        // filter: "numeric",
        width: 130
    },
    
    
];
export default columns;
