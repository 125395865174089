import React, { useState } from "react";
import unReadIcon from "../../../assets/img/Mark_as_unreadIcon.png";
import "./index.scss";
import MessageBody from "./MessageBody";
import { getMailBody } from "../../../store/actions/messages";
import { useDispatch, useSelector } from "react-redux";
import LoadMask from "../../../utils/LoadMask";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../utils/Notification";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const NewMessages = ({ Mails }) => {
  const [selectedData, setSelectedData] = useState(null);
  const [isWindowShow, setWindowShow] = useState(false);
  const dispatch = useDispatch();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const { t, i18n } = useTranslation();

  const [showLoader, setShowLoader] = useState(null);

  const handleRowClick = async (dataItem) => {
    setSelectedData(dataItem);
    const params = {
      CompanyId: authContext?.CompanyID,
      UniqueId: dataItem?.UniqueId,
      CountryId: null,
    };
    setShowLoader({
      className: ".newMessageContainer",
      msg: t("_loading", "Loading"),
    });
    dispatch(getMailBody(params, authContext))
      .then((data) => {
        if (data?.Status === "Success") {
          setSelectedData(data?.Body);
          setWindowShow(true);
        }
      })
      .catch((data) => {
        if (data?.Response?.sessionstatus === "400")
          showToast("error", data?.Response?.Message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
      })
      .finally(() => {
        setShowLoader(null);
      });
  };
  const onClose = () => {
    setWindowShow(false);
  };
  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {isWindowShow && (
        <MessageBody windowConfig={{ onClose }} data={selectedData} />
      )}
      <div
        className='nielsen-card'
        style={{
          height: "48%",
        }}
      >
        <div
          className="newMessageContainer"
          style={{
            display: "flex",
            height: "12%",
            alignItems: "center",
            padding: "10px"
          }}
        >
          <h3>{t("newMessages")}</h3>
        </div>
        <div className="newMessage_grid">
          {Mails?.map((dataItem, i) => (
            <button
              key={dataItem?.UniqueId}
              className={`message_row ${dataItem?.UniqueId === selectedData?.UniqueId ? "selected" : ""
                }`}
              onClick={() => {
                handleRowClick(dataItem);
              }}
            >
              <div>{dataItem?.SentAddress}</div>
              <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                <img src={unReadIcon} /> {dataItem?.MailSubject}
              </div>
              <div>{dataItem?.SentDate}</div>
            </button>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            height: "14%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            to="/notifications"
            className="k-anchor"
          >
            {t("viewAll")}
          </Link>
        </div>
      </div>

    </>
  );
};

export default NewMessages;
