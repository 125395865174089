import React from "react";
import Header from "../header";
import { useDispatch, useSelector } from "react-redux";

import storeImg from "../../assets/img/Component_142_1Icon.png";
import newWO from "../../assets/img/newWO.png";
import completedWO from "../../assets/img/completedWO.png";
import pendingWO from "../../assets/img/pendingWO.png";
import activeResources from "../../assets/img/activeResources.png";
import notificationsImg from "../../assets/img/MessagesIcon.png";
import { useHistory } from "react-router-dom";
import tickerHand from '../../assets/img/hand.png'
import "./index.scss";
import NewMessages from "./NewMessage";
import LatestWorkOrder from "./LatestWorkOrder";
import { useTranslation } from "react-i18next";
import LanguageChange from "./LanguageChange";
import { fetchDownTime, fetchEcollectionVersion } from "../../store/actions/dashboard";
import ReportsRow from "./child/ReportsRow";
import EcollectionRow from "./child/EcollectionRow";

const Home = ({ activeMenu, ...other }) => {

  console.log(other?.screen,other,activeMenu,"1231231213")
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const dashboradResponse = useSelector(
    (state) => state.dashboardReducer.dashboardResponse
  );
  const downTimeResponse = useSelector(
    (state) => state.dashboardReducer.downTimeResponse
  );

  const eCollectionVersion = useSelector((state) => state.dashboardReducer.eCollectionVersion)
  const userProfileResponse = useSelector((state) => state.commonReducer.userProfileResponse);
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  // const mailBodyResponse = useSelector((state) => state.commonReducer.mailBodyResponse); 
  const [eCollVersion, setEcollVersion] = React.useState("")
  console.log(`activeMenu, other`, activeMenu, other);

  localStorage.setItem('region', userProfileResponse?.VendorInfo?.Vendor?.[0]?.VendorDetails?.Region)

  const region = localStorage.getItem('region');

  React.useEffect(() => {
    if (region !== "undefined") {
      region && dispatch(
        fetchDownTime({
          RegionCode: region,
          Language: localStorage.getItem("lng") || 'English'
        })
      );
    }
  }, [region])

  React.useEffect(() => {
    if (authContext) {
      const params = {
        vendorName: authContext?.CompanyName
      }
      dispatch(fetchEcollectionVersion(params))
    }
  }, [])

  React.useEffect(() => {
    if (eCollectionVersion) {
      const tempVersion = eCollectionVersion?.find((el) => el.Product === 'ECOLLECTION');
      setEcollVersion(tempVersion?.Version)
    }
  }, [eCollectionVersion])

  const ProductNameHeader = (props) => {
    return null;
  };
  const mailSubjectIcon = ({ dataItem, field }) => {
    // console.log(dataItem, field);
    return (
      <td>
        <span>
          <div
            style={{
              display: "flex",
              // justifyContent:'center',
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={notificationsImg}
                alt=""
                style={{
                  filter: "brightness(0) invert(0)",
                  marginRight: "10px",
                }}
              />
            </div>
            <div>{dataItem?.MailSubject}</div>
          </div>
        </span>
      </td>
    );
  };
  
  let temp = {
      "NSO": "N",
      "DCT": "N",
      "GNIC": "N",
      "ECOLLECTION": "N",
      "RA": "N",
      "MISCELLANEOUS": "Y"
  }
  userProfileResponse?.VendorInfo?.Vendor[0]?.CompanyProductDetails.forEach((x) => {
      temp[x.ProductName] = x.ActiveFlag;
  });

  return (
    <div
      style={{
        display: 'flex',
        height: "100%",
        // backgroundColor:'yellowgreen',
        flexDirection: "column",
      }}
    >
      <Header activeMenu="Navigator">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            margin: "5px 2em 5px auto",
          }}
        >
          <LanguageChange />
        </div>
      </Header>
      <div
        style={{
          display: "flex",
          backgroundColor: '#fff',
          height: "11%",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            width: "19%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5px",
            }}
          >
            <img src={storeImg} alt="storeImage" />
          </div>
          <div>
            <div className="store-count">
              {dashboradResponse?.StoresInActiveWorkOrders
                ? dashboradResponse?.StoresInActiveWorkOrders
                : "000"}
            </div>
            <label style={{
              fontSize: "12px",
            }}>
              {t("storesInActiveWO")}
            </label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 12,
            alignItems: "center",
            width: "19%",
          }}
        >
          <div>
            <img src={newWO} alt="" />
          </div>

          <div>
            <div className="store-count">
              {dashboradResponse?.NewWorkorders
                ? dashboradResponse?.NewWorkorders
                : "000"}
            </div>
            <label style={{
              fontSize: "12px",
              // backgroundColor: 'lightcyan',
            }}>{t('newWO')}</label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 12,
            alignItems: "center",
            width: "19%",
          }}
        >
          <div>
            <img src={completedWO} alt="" />
          </div>

          <div>
            <div className="store-count success">
              {dashboradResponse?.CompletedWorkorders
                ? dashboradResponse?.CompletedWorkorders
                : "000"}
            </div>
            <div
              style={{
                fontSize: "12px",
              }}
            >
              <label>{t('completedWO')}</label>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 12,
            alignItems: "center",
            width: "19%",
          }}
        >
          <div>
            <img src={pendingWO} alt="" />
          </div>

          <div>
            <div className="store-count error">
              {dashboradResponse?.Pendingworkorders
                ? dashboradResponse?.Pendingworkorders
                : "000"}
            </div>
            <div
              style={{
                fontSize: "12px",
                // backgroundColor: 'lightcyan',
              }}
            >
              <label>{t('pendingWO')}</label>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "19%",
          }}
        >
          <div>
            <img
              src={activeResources}
              alt=""
            />
          </div>

          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <div className="store-count">
              {dashboradResponse?.NoofActiveResources
                ? dashboradResponse?.NoofActiveResources
                : `000`}
            </div>
            <div
              style={{
                fontSize: "12px",
              }}
            >
              <label>{t('noOfActiveResources')}</label>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "#F2F2F2",
          padding: '13px',
          flexGrow: 1,
          overflow: "auto"
        }}
      >
        {downTimeResponse?.Response?.TickerInfo?.length > 0 && (
          <div
            style={{
              height: "8%",
            }}
          >
            {downTimeResponse?.Response?.TickerInfo?.length > 0 ? <RenderingTickerInfo /> : ""}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexGrow: 1
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              justifyContent: "flex-start",
              gap: '10px'
            }}
          >
            <ReportsRow temp={temp} />
            {temp["ECOLLECTION"] === "Y" && <EcollectionRow
              eCollVersion={eCollVersion}
            />}
            <NewMessages Mails={dashboradResponse?.GetMails?.Mails} />
          </div>

          <LatestWorkOrder Top5WorkOrders={dashboradResponse?.Top5WorkOrders} />
        </div>
      </div>
    </div>
  );
};

export default Home;

function RenderingTickerInfo() {
  const { t, i18n } = useTranslation();
  const downTimeResponse = useSelector(
    (state) => state.dashboardReducer.downTimeResponse
  );

  const listItems = downTimeResponse?.Response?.TickerInfo?.map(
    (element) => {
      return (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={tickerHand} style={{ width: '10%', display: 'inline' }} alt="handIcon" srcset="" />
            <ul type="disc" id="scroll-text" style={{ display: 'flex', listStyle: 'none', flexDirection: "row", padding: 0, margin: 0 }}>
              <li style={{
                fontWeight: 600,
                color: '#ec2984',
              }}
              >
                {element.TickerType}:&nbsp;&nbsp;
              </li>
              <li style={{ color: '#ec2984', }}>{element.TickerText}</li>
            </ul>
          </div>
        </>
      )
    }
  )
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#ffe6f2', marginBottom: '10px', height: '90%', borderRadius: 5 }}>
        <button style={{ padding: 10, color: "#fb087a", zIndex: 1, backgroundColor: '#ffe6f2', border: 'none' }}>{t('important')}</button>
        <div className="ticker-message" style={{ display: 'flex' }}>
          {listItems}
        </div>
      </div>
    </>
  )
}
