const planColumns = [
   
    {
      title: "WEEK ID",
      field: "weekId",
      show: true,
      width: "100px",
      editable: false
    },
    {
      title: "SMS ID",
      field: "smsId",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "STORE ID NRPS",
      field: "weekId",
      show: true,
      width: "100px",
      editable: false
    },
    {
      title: "STORE ID GNLC",
      field: "weekId",
      show: true,
      width: "100px",
      editable: false
    },
    {
      title: "STORE ID GSR",
      field: "gsrSmsId",
      show: true,
      width: "100px",
      editable: false
    },
    {
      title: "GROUP",
      field: "group",
      show: true,
      width: "100px",
      editable: false
    },
    {
      title: "CEDEC",
      field: "cedec",
      show: true,
      width: "100px",
      editable: false
    },       
    {
      title: "BANNER",
      field: "banner",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "STORE_NAME GSR",
      field: "storeName",
      show: true,
      width: 100,
      editable: false
    },    
    {
      title: "ADDRESS",
      field: "storeAddress",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "POSTAL CODE",
      field: "postalCode",
      show: true,
      width: 100,
      editable: false
    },   
    {
      title: "CITY",
      field: "city",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "PROVINCE",
      field: "province",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "REGION",
      field: "region",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "STORE TYPE",
      field: "storeType",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "MQ",
      field: "mq",
      show: true,
      width: 100,
      editable: false
    }, 
    {
      title: "EXPECTED EANS",
      field: "expectedEans",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "COLL TYPE",
      field: "collType",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "COLLECTIONS NOTES",
      field: "collectionsNotes",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "LIMIT DATE",
      field: "limitDate",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "LIMIT TIME",
      field: "limitTime",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "COLLECTION DAYS",
      field: "collectionDays",
      show: true,
      width: 100,
      editable: false
    },
    {
      title: "TYPE_MODE",
      field: "collectionDays",
      show: true,
      width: 100,
      editable: false
    },    
    // {
    //   title: "VENDOR",
    //   field: "vendor",
    //   show: true,
    //   width: 100,
    //   editable: false
    // }
  ];
  export default planColumns;
  