import { combineReducers } from "redux";

import commonReducer from "./commonReducer";

import inboxReducer from "./inbox";
import bulkUploadReducer from "./bulkupload";
import exceptionHandlerReducer from "./exceptionHandlerReducer";

/*Taning Management Module adding */
import courseSearchDetails from "./course/coursesearch";
import programSearchDetails from "./course/programsearch";
import learningReducer from "./learning";
import skillReducer from "./skills";
import courseSkillReducer from "./courseskills/courseskillmaping";
import skillDetailsReducer from "./courseskills/skilldetails";
import resourceSkillMapping from "./resourceskillsmaping/searchbyskills";
import resourceSearchByUser from "./resourceskillsmaping/searchbyuser";
import alertListReudcer from "./alerts";
import HeaderReudcer from "./header";
import dashboardReducer from "./dashboard";
import alertsCustomizationReducer from "./alertsCustomization";
import sosReducer from "./sos";
import countryEnablementReducer from "./countryEnablement";
import ChatReudcer from "./chat";
import WorkOrdersReducer from "./workOrders";
import ReportsReducer from "./reports";
import PeopleReducer from "./people";

export default combineReducers({
  commonReducer: commonReducer,
  inboxReducer: inboxReducer,
  bulkUploadReducer: bulkUploadReducer,
  exceptionHandlerReducer: exceptionHandlerReducer,

  /*new Learning Store Importing */

  learningReducer: learningReducer,
  skillReducer: skillReducer,
  courseSkillReducer: courseSkillReducer,
  skillDetailsReducer: skillDetailsReducer,
  resourceSkillMapping: resourceSkillMapping,
  courseSearchDetails: courseSearchDetails,
  programSearchDetails: programSearchDetails,
  resourceSearchByUser: resourceSearchByUser,
  alertList: alertListReudcer,
  HeaderReudcer: HeaderReudcer,
  dashboardReducer: dashboardReducer,
  alertsCustomization: alertsCustomizationReducer,
  countryEnablementReducer: countryEnablementReducer,
  sos: sosReducer,
  ChatReudcer: ChatReudcer,
  workOrders: WorkOrdersReducer,
  reports: ReportsReducer,
  people: PeopleReducer,
});
