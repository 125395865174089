import * as types from "../../../actions/types";

const initialState = {
    fetchSkillDetails : null
}

const skillDetailsReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
      case types.GET_SKILL_DETAILS:
        newState = {
          ...state,
          fetchSkillDetails: action.payload,
        };
        break;
      default:
        newState = state;
        break;
    }
    return newState;
}

export default skillDetailsReducer;