import * as types from "../types";
import { Get, Post, Put } from "../../methods";
import AppURL from "../../../utils/AppURL";

const getHeaders = (authContext) => ({
  OrganizationUnit: authContext?.organizationUnit || "",
  userid: authContext?.SpocMail || "",
  companyid: authContext?.CompanyID || "",
});

export const getAuditorNew = async (params, authContext) => {
  const res = await Get(
    AppURL.searchAuditorNew,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("fetchAuditorNew", { res });
  return res;
};
export const getResourceDetails = async (params, authContext) => {
  const res = await Get(
    AppURL.fetchResourceDetails,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("fetchResourceDetails", { res });
  return res;
};
export const putAuditorStatus = async (params, authContext) => {
  const res = await Put(
    AppURL.updateauditorstatus,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("updateauditorstatus", { res });
  return res;
};

export const postResetRegistration = async (params, authContext) => {
  const headers = {
    "Content-Type": "application/json",
    // "Content-Type": "application/x-www-form-urlencoded; application/json",
  };
  const res = await Post(AppURL.resetRegistration, params, headers, true).then(
    (res) => res.json()
  );
  console.log("postResetRegistration", { res });
  return res;
};

export const exportAllCompanyAuditor = async (params, authContext) => {
  const headers = {
    OrganizationUnit: authContext?.organizationUnit || "",
    userid: authContext?.SpocMail || "",
    "Content-Type": "application/x-www-form-urlencoded; application/json",
    countryid: authContext?.CountryCode,
  };
  const res = await Get(AppURL.getAllCompanyAuditors, params, headers, true);
  console.log("All company auditor download in people=> Resource", { res });
  return res;
};

export const getAuditorRemap = async (params, authContext) => {
  const res = await Get(
    AppURL.auditorremap,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("getAuditorRemap", { res });
  return res;
};

export const getStateData = async (params, authContext) => {
  const res = await Get(
    AppURL.state,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("getStateData", { res });
  return res;
};

export const updateAuditor = async (params, authContext) => {
  const res = await Put(
    AppURL.auditor,
    params,
    getHeaders(authContext),
    true
  ).then((res) => res.json());
  console.log("updateAuditor", { res });
  return res;
};
export const createAuditor = async (params, authContext) => {
  const headers = {
    OrganizationUnit: authContext.organizationUnit,
    userid: authContext.email,
    "X-Requested-With": "XMLHttpRequest",
  };

  const res = await Post(AppURL.auditor, params, null, true).then((res) =>
    res.json()
  );
  return res;
};
export const createAdmin = async (params, authContext) => {
  const headers = {
    userid: authContext.email,
    "X-Requested-With": "XMLHttpRequest",
  };

  const res = await Post(AppURL.createAdmin, params, headers, true).then(
    (res) => res.json()
  );
  return res;
};
