import {
  CustomColumnMenu,
  CustomColumnMenuFilter,
} from "../../ui/grid/CustomColumnMenu";

export const columnMenuConfig = {
  CustomColumnMenuFilter,
  CustomColumnMenu,
};

export const columns = [
  {
    title: "BatchId",
    field: "BatchId",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "WORKORDERID",
    field: "WorkOrderId",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "Status",
    field: "Status",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "boolean",
  },
  {
    title: "uploadedby",
    field: "UploadedBy",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "uploadedon",
    field: "UploadedOn",
    show: true,
    columnMenu: "CustomColumnMenu",
  },
  {
    title: "totalrecords",
    field: "TotalCount",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "Success",
    field: "SuccessCount",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "failure",
    field: "FailureCount",
    show: true,
    columnMenu: "CustomColumnMenuFilter",
    filter: "numeric",
  },
  {
    title: "uploadfile",
    field: "uploadFile",
    show: true,
    columnMenu: "CustomColumnMenu",
  },
  {
    title: "",
    field: "uploadFile",
    show: false,
    columnMenu: "CustomColumnMenu",
  },
];
