import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  FormComboBox,
  FormInput,
} from "../../../../ui/form/form-component";
import { showToast } from "../../../../../utils/Notification";
import "./index.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useTranslation } from "react-i18next";
const initialDevice = {
  DEVICE_ID: "",
  DEVICE_TYPE: "",
  DEVICE_STATUS: "",
  DEVICE_COMMENTS: "",
};
const AddDevice = ({ deviceList, setDeviceList, isReset }) => {
  const { t, i18n } = useTranslation();
  const [currentDevice, setCurrentDevice] = useState(initialDevice);
  const [isRequired, setIsRequired] = useState(false); 


  const onAddDeviceClick = (form) => {
    const newDevice = { ...form.values };
    console.log("newDevice", newDevice, deviceList);
    if (
      !newDevice?.DEVICE_TYPE ||
      !newDevice?.DEVICE_ID ||
      !newDevice?.DEVICE_STATUS
    ) {
      showToast("error", t("pleaseentermandatoryvalues"));
      return;
    }
    const currentDevice = [...deviceList];
    currentDevice.push(newDevice);
    setDeviceList(currentDevice);
    // form.value;
  };
  const removeDevice = (index) => {
    const temp = deviceList.slice();
    temp?.splice(index, 1);
    setDeviceList(temp);
  };
  const onInputChange = (e) => {
    const name = e?.target?.name;
    const temp = { ...currentDevice };
    temp[name] = e?.target?.value || "";
    setCurrentDevice(temp);
  };

  useEffect(() => {
    const containChar = Object.values(currentDevice).some((v) => v);
    setIsRequired(containChar);
  }, [JSON.stringify(currentDevice)]);

  return (
    <div
      style={{
        borderTop: "1px solid #eee",
        padding: "7px 15px",
        flex: 1,
        height: "30vh",
        backgroundColor: "#fff",
        bottom: "5vh",
        display: "flex",
        flexDirection: "column",
        maxHeight: 203,
      }}
    >
      <h4
        style={{
          border: "1px solid #eee",
          borderBottom: "2px solid #ccc",
          margin: 0,
          padding: ".5em",
        }}
      >
        Add Device
      </h4>
      <Form
        // initialValues={{
        //   lob: selectedLob,
        //   collectionType: selectedCollection,
        // }}
        onSubmitClick={onAddDeviceClick}
        render={(formRenderProps) => (
          <FormElement
            style={{
              padding: "1em 0",
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <Field
              name={"DEVICE_TYPE"}
              component={FormInput}
              label={"Device Type"}
              placeholder={"Device Type"}
              onChange={onInputChange}
              wrapperStyle={{ width: "21%" }}
              required={isRequired}
            />
            <Field
              name={"DEVICE_ID"}
              component={FormInput}
              label={"Device Id"}
              placeholder={"Device Id"}
              onChange={onInputChange}
              wrapperStyle={{ width: "21%", margin: 0 }}
              required={isRequired}
            />
            <Field
              name={"DEVICE_STATUS"}
              component={FormComboBox}
              label={"Status"}
              placeholder={"Status"}
              data={["ACTIVE", "INACTIVE"]}
              // onChange={onCountryChange}
              onChange={(e) => {
                const temp = { ...currentDevice };
                temp["DEVICE_STATUS"] = e?.target?.value || "";
                setCurrentDevice(temp);
              }}
              wrapperStyle={{ width: "21%", margin: 0 }}
              required={isRequired}
            />
            <Field
              name={"DEVICE_COMMENTS"}
              component={FormInput}
              label={"Device Comment"}
              placeholder={"Comment"}
              onChange={onInputChange}
              wrapperStyle={{ width: "21%", margin: 0 }}
            />
            <Button
              type="submit"
              primary
              style={{
                width: "13%",
                display: "flex",
                alignSelf: "end",
              }}
              disabled={!isRequired}
              onClick={() => {                
                setTimeout(() => {
                    formRenderProps.onFormReset();
                }, 0);
              }}
            >
              Add
            </Button>
          </FormElement>
        )}
      />
      <div
        style={{
          border: "1px solid #ccc",
          overflowY: "overlay",
          height: "100%",
        }}
      >
        {deviceList.map((d, i) => {
          const { DEVICE_TYPE, DEVICE_ID, DEVICE_STATUS, DEVICE_COMMENTS } = d;
          return (
            <React.Fragment>
              {!isReset ? (
                <div className="gridrowContainer" key={i}>
                  <Tooltip>
                    <div title={DEVICE_TYPE}>{DEVICE_TYPE}</div>
                  </Tooltip>
                  <Tooltip>
                    <div title={DEVICE_ID}>{DEVICE_ID}</div>
                  </Tooltip>

                  <Tooltip>
                    <div title={DEVICE_STATUS}>{DEVICE_STATUS}</div>
                  </Tooltip>
                  <Tooltip>
                    <div title={DEVICE_COMMENTS}>{DEVICE_COMMENTS}</div>
                  </Tooltip>
                  <Tooltip>
                    <Button
                      title="Remove Device"
                      style={{ padding: 1 }}
                      onClick={() => removeDevice(i)}
                    >
                      <i
                        className="k-icon-md k-icon k-i-trash"
                        style={{ color: "#fb087a" }}
                      />
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default AddDevice;
