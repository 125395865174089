/**
 * This component represents an unadorned list of SelectItem (s).
 */
import React from "react";

import { useMultiSelect } from "../dropdown/hooks";
import SelectItem from "./select-item";

interface ISelectListProps {
  data: any[];
  onClick: Function;
  skipIndex: number;
  textField?: string;
}

const SelectList = ({ data, onClick, skipIndex }: ISelectListProps) => {
  const { disabled, value, textField = 'label', valueField = 'value', onChange, ItemRenderer } = useMultiSelect();

  const handleSelectionChanged = (option: any, checked: boolean) => {
    if (disabled) return;

    onChange && onChange(
      checked
        ? { value: [...value, option] }
        : { value: value.filter((o: any) => o?.[valueField] !== option[valueField]) }
    );
  };

  return (
    <>
      {data.map((o: any, i) => {
        const tabIndex = i + skipIndex;

        return (
          <li key={o?.key || i}>
            <SelectItem
              textField={textField}
              tabIndex={tabIndex}
              option={o}
              onSelectionChanged={(c) => handleSelectionChanged(o, c)}
              checked={!!value.find((s) => s?.[valueField] === o[valueField])}
              onClick={(e: any) => onClick(e, tabIndex)}
              itemRenderer={ItemRenderer}
              disabled={o.disabled || disabled}
            />
          </li>
        );
      })}
    </>
  );
};

export default SelectList;
