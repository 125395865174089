import React from "react";

interface IDefaultItemRendererProps {
  checked: boolean;
  option: any;
  textField: string;
  disabled?: boolean;
  onClick?: any;
}

const DefaultItemRenderer = ({
  checked,
  option,
  onClick,
  textField,
  disabled,
}: IDefaultItemRendererProps) => (
  <div className={`k-item ${checked && "k-state-selected"} ${disabled && "disabled"}`}>
    <input
      type="checkbox"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      style={{ marginRight: 10 }}
      disabled={disabled}
    />
    <span>{option[textField]}</span>
  </div>
);

export default DefaultItemRenderer;
