import React, { Ref, useEffect, useCallback, useRef, useMemo } from "react";
import { ISelectProps } from "../interfaces";

const defaultProps: Partial<ISelectProps> = {
  value: [],
  valid: true,
  selectAll: true,
  searchField: true,
  defaultIsOpen: false,
  className: "",
  data: [] as any[],
};

interface MultiSelectProviderProps {
  props: ISelectProps;
  children: any;
}

const MultiSelectContext = React.createContext<ISelectProps>({} as ISelectProps);

export const MultiSelectProvider = ({ props, children }: MultiSelectProviderProps) => (
  <MultiSelectContext.Provider value={{ ...defaultProps, ...props }} >
    {children}
  </MultiSelectContext.Provider>
);

export const useMultiSelect = () => React.useContext(MultiSelectContext);


interface Options {
  when?: boolean;
  eventTypes?: Array<string | number>;
  target?: Ref<HTMLElement> | null | any;
}

const defaultOptions = {
  when: true,
  eventTypes: ["keydown"],
};

function useKey(
  input: string | number | Array<string | number>,
  callback: (e: KeyboardEvent) => any,
  opts?: Options
): void {
  const keyList: Array<string | number> = useMemo(
    () => (Array.isArray(input) ? input : [input]),
    [input]
  );
  const options = Object.assign({}, defaultOptions, opts);
  const { when, eventTypes } = options;
  const callbackRef = useRef<(e: KeyboardEvent) => any>(callback);
  let { target } = options;

  useEffect(() => {
    callbackRef.current = callback;
  });

  const handle = useCallback(
    (e: KeyboardEvent) => {
      if (keyList.some((k) => e.key === k || e.code === k)) {
        callbackRef.current(e);
      }
    },
    [keyList]
  );

  useEffect((): any => {
    if (when && typeof window !== "undefined") {
      const targetNode = target ? target["current"] : window;
      eventTypes.forEach((eventType) => {
        targetNode && targetNode.addEventListener(eventType, handle);
      });
      return () => {
        eventTypes.forEach((eventType) => {
          targetNode && targetNode.removeEventListener(eventType, handle);
        });
      };
    }
  }, [when, eventTypes, keyList, target, callback, handle]);
}

export { useKey };