import React, { useState } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import i18next from "../../i18next";
import { useHistory } from "react-router-dom";
const languages = [
  "English",
  "Spanish",
  "German",
  "French",
  "Dutch",
  "Portuguese",
];
const LanguageChange = ({ifLoginPage}) => {  
  let history = useHistory();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lng") || "English"
  );
  const onLanguageChange = (event) => {
    const { value } = event;
    localStorage.setItem("lng", value);
    setSelectedLanguage(value);
    i18next.changeLanguage(value);
    if(ifLoginPage !== "LoginPage"){
      window.location.reload(true)
      // history.push("/dashboard");
      // history.push("/");
    }else{
      window.location.reload(true)      
    }
  };
  return (
    <ComboBox
      data={languages}
      onChange={onLanguageChange}
      value={selectedLanguage}
    />
  );
};

export default LanguageChange;
