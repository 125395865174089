import { SvgIcon } from '@progress/kendo-react-common';

const HomeIcon = (props) => {
    return (
        <SvgIcon style={props?.style} width={props?.width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.8486 13.8792V24.7805H19.6036V15.5401V15.3901H19.4536H12.5977H12.4477V15.5401V24.7805H8.1771V13.8792L16.0128 7.55638L23.8486 13.8792ZM16.1072 5.8834L16.013 5.80715L15.9186 5.88327L6.9058 13.156L6.85 13.201V13.2727V26V26.15H7H15.2509H15.4009H15.5991H15.7491H25H25.15V26V13.2727V13.2011L25.0944 13.1561L16.1072 5.8834Z"
                fill={props?.color ?? "#4F4F4F"}
                stroke={props?.color ?? "#4F4F4F"}
                strokeWidth="0.3" />
        </SvgIcon>
    )
}

export default HomeIcon