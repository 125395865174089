import * as types from "../../actions/types";

const initialState = {
    inboxFilters: null,
    fetchInboxDataResponse: null,
    fetchRepositoryResponse: null,
    fetchInboxColumnsResponse: null,
    fetchWorkItemsDataResponse: null,
    reasonDetails : null
};

const InboxReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case types.INBOX_FILTERS:
            newState = {
                ...state,
                inboxFilters: action.payload
            };
            break;
        case types.GET_REPOSITORY_DATA:
            newState = {
                ...state,
                fetchRepositoryResponse: action.payload
            }
            break;
        case types.GET_INBOX_COLUMNS:
            newState = {
                ...state,
                fetchInboxColumnsResponse: action.payload
            }
            break;
        case types.CLEAR_INBOX_COLUMNS:
            newState = {
                ...state,
                fetchInboxColumnsResponse: []
            }
            break;
        case types.GET_INBOX_DATA:
            newState = {
                ...state,
                fetchInboxDataResponse: action.payload
            }
            break;
        case types.GET_WORKITEMS_DATA:
            newState = {
                ...state,
                fetchWorkItemsDataResponse: action.payload
            }
            break;
        case types.CLEAR_WORKITEMS_DATA:
            newState = {
                ...state,
                fetchWorkItemsDataResponse: null
            }
            break;
        case types.REASONDETAILS:
            newState = {
                ...state,
                reasonDetails: action.payload
            }
            break;
        default:
            newState = state;
            break;
    }

    return newState;
};

export default InboxReducer;