import React from "react";
import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useSelector } from "react-redux";
import { selectAuditorList } from "../../../store/reducer/commonReducer";
import { CustomColumnMenu, CustomColumnMenuFilter } from "../../ui/grid/CustomColumnMenu";

export const ResourceIDCell = (props) => {
  const dataItem = props.dataItem;
  // const { dataItem } = props;
  // const cellField = props?.field;
  const auditorDetails = useSelector(selectAuditorList);
  const field = "PRIMARY_RESOURCE_ID";
  const dependentField = "PRIMARY_RESOURCE_NAME";
  const dataField = "AuditorId";
  const dependentDataField = "AuditorName";
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dataField],
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dependentDataField],
      });
    }
  };

  const [data, setData] = React.useState(auditorDetails.slice());
  const filterData = (filter) => {
    const data = auditorDetails.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {

    setData(filterData(event.filter));
  };

  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox
          onChange={handleChange}
          value={auditorDetails?.find((c) => c?.[dataField] === dataValue)}
          // data={auditorDetails}
          data={data}
          textField={dataField}
          filterable={true}
          onFilterChange={filterChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};

export const ResourceNameCell = (props) => {
  const dataItem = props.dataItem;
  // const { dataItem } = props;
  // const cellField = props?.field;
  const auditorDetails = useSelector(selectAuditorList);
  const field = "PRIMARY_RESOURCE_NAME";
  const dependentField = "PRIMARY_RESOURCE_ID";
  const dataField = "AuditorName";
  const dependentDataField = "AuditorId";
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dataField],
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dependentDataField],
      });
    }
  };

  const [data, setData] = React.useState(auditorDetails.slice());
  const filterData = (filter) => {
    const data = auditorDetails.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setData(filterData(event.filter));
  };


  // const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox
          onChange={handleChange}
          value={auditorDetails?.find((c) => c?.[dataField] === dataValue)}
          // data={auditorDetails}
          data={data}
          textField={dataField}
          filterable={true}
          onFilterChange={filterChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};
export const ReplacementResourceNameCell = (props) => {
  const dataItem = props.dataItem;
  const auditorDetails = useSelector(selectAuditorList);
  const field = "REPLACEMENT_RESOURCE_NAME";
  const dependentField = "REPLACEMENT_RESOURCE_ID";
  const dataField = "AuditorName";
  const dependentDataField = "AuditorId";

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dataField],
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dependentDataField],
      });
    }
  };

  const [data, setData] = React.useState(auditorDetails.slice());
  const filterData = (filter) => {
    const data = auditorDetails.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {

    setData(filterData(event.filter));
  };

  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox
          onChange={handleChange}
          value={auditorDetails?.find((c) => c?.[dataField] === dataValue)}
          // data={auditorDetails}
          data={data}
          textField={dataField}
          filterable={true}
          onFilterChange={filterChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};
export const ReplacementResourceIdCell = (props) => {
  const dataItem = props.dataItem;
  const auditorDetails = useSelector(selectAuditorList);
  const field = "REPLACEMENT_RESOURCE_ID";
  const dependentField = "REPLACEMENT_RESOURCE_NAME";
  const dataField = "AuditorId";
  const dependentDataField = "AuditorName";

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dataField],
      });
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: dependentField,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.[dependentDataField],
      });
    }
  };
  const [data, setData] = React.useState(auditorDetails.slice());
  const filterData = (filter) => {
    const data = auditorDetails.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {

    setData(filterData(event.filter));
  };

  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  return (
    <>
      {dataItem.inEdit ? (
        <ComboBox
          onChange={handleChange}
          value={auditorDetails?.find((c) => c?.[dataField] === dataValue)}
          // data={auditorDetails}
          data={data}
          textField={dataField}
          filterable={true}
          onFilterChange={filterChange}
        />
      ) : (
        dataValue.toString()
      )}
    </>
  );
};
export const columnMenuConfig = {
  CustomColumnMenuFilter,
  CustomColumnMenu,
}

// ################# COLUMN LIST JSON #################
const columns = [
  {
    title: "SMS ID",
    field: "SMS_ID",
    show: true,
    width: 100,
    editable: false,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "StoreName",
    field: "STORE_NAME",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenuFilter",
    editable: false,
  },
  {
    title: "address_lbl",
    field: "STORE_ADDRESS",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenuFilter",
    editable: false,
  },
  {
    title: "city_lbl",
    field: "CITY",
    show: true,
    width: 100,
    columnMenu: "CustomColumnMenuFilter",
    editable: false,
  },
  {
    title: "state/province",
    field: "STATE",
    show: true,
    width: 100,
    columnMenu: "CustomColumnMenuFilter",
    editable: false,
  },
  {
    title: "POSTALCODE",
    field: "POSTALCODE",
    show: true,
    width: 100,
    columnMenu: "CustomColumnMenuFilter",
    editable: false,
  },
  {
    title: "updatedon",
    field: "LAST_UPDATED_ON",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenu",
    editable: false,
    filter: "date",
    format: "{0:yyyy-MM-dd HH:mm:ss}"
  },
  {
    title: "lastupdatedby",
    field: "LAST_UPDATED_BY",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenuFilter",
    editable: false,
    filter: "boolean"
  },
  {
    title: "resourcename",
    field: "PRIMARY_RESOURCE_NAME",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "ResourceId",
    field: "PRIMARY_RESOURCE_ID",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "AuditorStatuslbl",
    field: "AUDITOR_STATUS",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenuFilter",
    editable: false,
    filter: "boolean"
  },
  {
    title: "REPLACEMENT_AUDITORNAME_LBL",
    field: "REPLACEMENT_RESOURCE_NAME",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenuFilter",
  },
  {
    title: "REPLACEMENT_AUDITORID_LBL",
    field: "REPLACEMENT_RESOURCE_ID",
    show: true,
    width: 200,
    columnMenu: "CustomColumnMenuFilter",
  },
];
export default columns;
