import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import SearchFilterWindow from "./SearchFilterWindow";
import apiRes from "./coverageScreenRes.json";
import {
  getCoverageScreen,
  postCoverageScreenStore,
} from "../../../store/actions/workOrder/coverageScreen";
import LoadMask from "../../../utils/LoadMask";
import { useSelector, useDispatch } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import { updateUserPreferenceAction } from "../../../store/actions/commonActions";
import { showToast } from "../../../utils/Notification";
import { Pager } from "@progress/kendo-react-data-tools";
import { Button } from "@progress/kendo-react-buttons";
import columns, {
  columnMenuConfig,
  ReplacementResourceIdCell,
  ReplacementResourceNameCell,
  ResourceIDCell,
  ResourceNameCell,
} from "./columns";
import {
  isColumnActive,
} from "../../ui/grid/CustomColumnMenu";
import { filterBy, getter, orderBy } from "@progress/kendo-data-query";
import { CellRender, RowRender } from "../../ui/grid/renders";
import UploadWindow from "./UploadWindow";
import DownloadWindow from "./DownloadWindow";
import { selectAuditorList } from "../../../store/reducer/commonReducer";
import { useTranslation } from "react-i18next";
import { searchUserPrefrence } from "../../../store/actions/dashboard";
import { setOrderIndex } from "../../../utils/Utility";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";
import { MyPager } from "../../generalHooks";

const initialColumnsState = setOrderIndex(columns);
const stores = apiRes.root.StoreData;
const createDataState = (data, dataState) => {
  return {
    result: {
      data: data,
      total: dataState.take,
    },
    dataState: dataState,
  };
};
const initialState = createDataState([], {
  take: 2000,
  skip: 0,
});
const EDIT_FIELD = "inEdit";
const DATA_ITEM_KEY = "SMS_ID";
const GRID_NAME = "WO_COVERAGE_GRID";
const rowKeys = columns.map((item) => item.field);
const idGetter = getter(DATA_ITEM_KEY);
const pageSize = 25;

const CustomCellDropDown = (props) => {
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  const additionalProps =
    cellField && cellField === inEditField
      ? {
        ref: (td) => {
          // const input = td && td.querySelector("input");
          // const activeElement = document.activeElement;
          // if (
          //   !input ||
          //   !activeElement ||
          //   input === activeElement ||
          //   !activeElement.contains(input)
          // ) {
          //   return;
          // }
          // if (input.type === "checkbox") {
          //   input.focus();
          // } else {
          //   input.select();
          // }
        },
      }
      : {
        onClick: () => {
          props.enterEdit(dataItem, cellField);
        },
      };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };
  const isEdited = dataItem?.edited?.includes(cellField);

  return (
    <td
      {...clonedProps}
      style={{ ...props.td.props.style, backgroundColor: isEdited ? "rgba(255,100,0,.3)" : "" }}
    >
      {cellField === inEditField ? props?.cell : props.td.props.children}
    </td>
  );
};

const CoverageScreen = ({ setActiveScreen, activeScreen, tabsObj, roleWorkOrderPage, screen }) => {
  const serverColumns = useSelector(
    (state) => state.commonReducer.gridUserPrefrence
  );
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const dispatch = useDispatch();
  const { handleAuthContext } = useLogout();
  const localizationService = useLocalization();
  const { t } = useTranslation();
  const [uploadWindowShow, setUploadWindowShow] = useState(false);
  const [downloadWindowShow, setDownloadWindowShow] = useState(false);

  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState([
    {
      field: "SMS_ID",
      dir: "asc",
    },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [stateColumns, setStateColumns] = useState(initialColumnsState);

  const [changes, setChanges] = useState(false);

  const [selectedState, setSelectedState] = useState({});
  const [data, setData] = useState(null);
  const [pagerSkip, setPagerSkip] = useState(0);

  const auditorDetails = useSelector(selectAuditorList);
  const gridData = useMemo(() => {
    const data = result?.data?.map((item) => {
      const primarytAuditorId = item?.PRIMARY_RESOURCE_ID;
      const curPrimaryAud = auditorDetails?.find(
        (aud) => aud?.AuditorId === primarytAuditorId
      );

      const replacementAudId = item?.REPLACEMENT_RESOURCE_ID;
      const curReplacementAud = auditorDetails?.find(
        (aud) => aud?.AuditorId === replacementAudId
      );

      return {
        ...item,
        // PRIMARY_RESOURCE_NAME: curPrimaryAud?.AuditorName,
        // REPLACEMENT_RESOURCE_NAME: curReplacementAud?.AuditorName,
        selected: selectedState[idGetter(item)],
      };
    });

    return {
      data,
      total: +result?.total,
    };
  }, [result, selectedState]);

  const [showLoader, setShowLoader] = useState(null);
  const dataStateChange = (event) => {
    setDataState(event.dataState);
  };
  const childRef = useRef();

  const onPageChange = async (e) => {
    const dataState = e.page;
    if (document.activeElement.tagName.toLowerCase() === 'td') {
      const rowIndex = parseInt(document.activeElement.parentElement.getAttribute('aria-rowindex'));
      if (rowIndex && ((rowIndex - 1) <= dataState.skip || (rowIndex + 1) >= (dataState.skip + pageSize))) {
        document.activeElement.blur();
      }
    }
    setDataState(dataState);
  };

  const handlePageChange = async (e) => {
    setDataState({ ...dataState, take: e.take });
    setPagerSkip(e.skip);
    setFilter(null);
    const newSearchParams = childRef.current?.newSearchParams;
    const params = {
      _dc: Date.now(),
      ...newSearchParams,
      Companyid: authContext?.CompanyID,
      page: Math.floor(e.skip / e.take) + 1,
      StartCount: e.skip,
      MaxCount: e.take,
      sortProperty: sort[0].field,
      sortDirection: sort[0].dir,
    };
    if (searchTerm) {
      params.keyword = searchTerm;
    }
    childRef.current?.fetchCoverageScreen(params);
  };

  const handleSaveViewClick = async () => {
    const Json_Object = JSON.stringify(stateColumns);
    const params = {
      UserPrefReq: {
        Email: authContext?.SpocMail,
        Company_ID: authContext?.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: authContext?.CountryCode,
        Json_Object,
      },
    };
    setShowLoader({
      className: ".workOrders_grid_cs",
      msg: "loading",
    });
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast(
        "success",
        localizationService.toLanguageString(
          "success",
          `${res?.Response?.Message}`
        )
      );
    } else {
      showToast(
        "error",
        localizationService.toLanguageString(
          "dataNotFound",
          `${res?.Response?.Message}`
        )
      );
    }

    setShowLoader(null);
  };

  const refreshGrid = () => {
    setShouldRefresh(true);
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || initialColumnsState);
  };
  const handleDefaultViewClick = () => {
    onColumnsSubmit();
  };

  const enterEdit = (dataItem, field) => {
    const newData = result?.data.map((item) => ({
      ...item,
      [EDIT_FIELD]: item?.SMS_ID === dataItem?.SMS_ID ? field : undefined,
    }));
    setResult((prev) => ({ ...prev, data: newData }));
  };

  const exitEdit = () => {
    setResult((result) => {
      const { total, data } = result;
      const newData = data.map((item) => ({
        ...item,
        [EDIT_FIELD]: undefined,
      }));
      return {
        data: newData,
        total: total,
      };
    });
  };

  const itemChange = (event) => {
    let field = event.field || "";
    const { value, dataItem } = event;
    let newData = data?.data.map((item) => {
      if (item?.SMS_ID === dataItem?.SMS_ID) {
        const { edited = [] } = item;
        if (value !== dataItem?.[field]) {
          item.edited = [...edited, field];
        } else {
          item.edited = edited?.filter((fieldName) => fieldName !== field);
        }
        item[field] = value;
        item['LAST_UPDATED_BY'] = authContext?.SpocMail;
      }
      return item;
    });
    setData((prev) => ({ ...prev, data: newData }));
    setChanges(true);
  };

  const customRowRender = (tr, props) => {
    return (
      <RowRender
        originalProps={props}
        tr={tr}
        exitEdit={exitEdit}
        editField={EDIT_FIELD}
      />
    );
  };

  const customCellRender = (td, props) => {
    let style = {};
    if (["SMS_ID", "POSTALCODE", "PRIMARY_RESOURCE_ID", "REPLACEMENT_RESOURCE_ID"].includes(props.field)) {
      style = {
        textAlign: 'right'
      }
    } else {
      style = {
        textAlign: 'left'
      }
    }
    const updatedTd = {
      ...td,
      props: {
        ...td.props,
        style: {
          ...td.props.style,
          ...style
        }
      }
    }

    switch (props.field) {
      case "PRIMARY_RESOURCE_ID":
        return (
          <CustomCellDropDown
            originalProps={props}
            td={updatedTd}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            cell={<ResourceIDCell {...props} />}
          />
        );
      case "PRIMARY_RESOURCE_NAME":
        return (
          <CustomCellDropDown
            originalProps={props}
            td={updatedTd}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            cell={<ResourceNameCell {...props} />}
          />
        );
      case "REPLACEMENT_RESOURCE_NAME":
        return (
          <CustomCellDropDown
            originalProps={props}
            td={updatedTd}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            cell={<ReplacementResourceNameCell {...props} />}
          />
        );
      case "REPLACEMENT_RESOURCE_ID":
        return (
          <CustomCellDropDown
            originalProps={props}
            td={updatedTd}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            cell={<ReplacementResourceIdCell {...props} />}
          />
        );
      default:
        return (
          <CellRender
            originalProps={props}
            td={updatedTd}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
           
          />
        );
    }
  };

  const saveGridDataChanges = async () => {
    if( roleWorkOrderPage?.screensInfo?.screenId == screen ? ( roleWorkOrderPage?.createFlag == 'N' ? true : false ) : true ){

      showToast("warning","Saving the data updates are reserved for admins only.")

    }else{

      setChanges(false);
      const editedData = result.data.filter((item) => item.edited?.length);
      const params = JSON.stringify({
        coveragerequest: {
          sms_ids: editedData,
          Companyid: authContext?.CompanyID,
        },
      });
      setShowLoader({
        className: ".workOrders_grid_cs",
        msg: "loading",
      });
      const res = await postCoverageScreenStore(params, authContext);
      setShowLoader(null);
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }
      if (res?.root?.MsgCode === "UPDATE_SUCCESS_LBL") {
        showToast(
          "success",
          localizationService.toLanguageString("success", `${res?.root?.Msg}`)
        );
        setData((result) => {
          const { total, data } = result;
          const newData = data.map((item) => ({
            ...item,
            edited: [],
          }));
          return {
            data: newData,
            total: total,
          };
        });
      } else {
        showToast(
          "error",
          localizationService.toLanguageString(
            "dataNotFound",
            `${res?.root?.Msg}`
          )
        );
      }
    }
  };

  const updateCoverageScreenSort = async (sortData) => {
    const newSearchParams = childRef.current?.newSearchParams;
    const params = {
      _dc: Date.now(),
      ...newSearchParams,
      Companyid: authContext?.CompanyID,
      page: Math.floor(pagerSkip / dataState.take) + 1,
      StartCount: pagerSkip,
      MaxCount: dataState.take,
      sortProperty: sortData[0].field,
      sortDirection: sortData[0].dir,
    };
    setShowLoader({
      className: ".workOrders_grid_cs",
      msg: "loading",
    });
    const res = await getCoverageScreen(params, authContext);
    if (res?.root) {

      let id = 0;
      let data =
        res?.root?.StoreData?.map((item) => {
          const curPrimaryAud = auditorDetails?.find(
            (aud) => aud?.AuditorId === item?.PRIMARY_RESOURCE_ID
          );
          const curReplacementAud = auditorDetails?.find(
            (aud) => aud?.AuditorId === item?.REPLACEMENT_RESOURCE_ID
          );
          let saList = [];
          const isSecondaryExits =
            item["SecondaryAuditors"] !== null &&
            item["SecondaryAuditors"] !== undefined;
          if (isSecondaryExits) {
            saList = item.SecondaryAuditors.split("|");
          }
          return {
            ...item,
            SECONDARY_RESOURCE_IDS: saList,
            OriginalSecondaryAuditorList: saList,
            PRIMARY_RESOURCE_NAME: curPrimaryAud?.AuditorName,
            REPLACEMENT_RESOURCE_NAME: curReplacementAud?.AuditorName,
          };
        }).sort(function (a, b) {
          return a.StoreId - b.StoreId;
        }) || [];
      data = data?.map((item) => {
        return {
          ...item,
          ID: ++id,
        };
      });
      const newResult = {
        data: data,
        total: res.root?.StoreData?.length || 0,
        totalDataCount: res?.root?.TotalCount
      };
      setData(newResult);
    }
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
  }

  useEffect(() => {
    if (data) {
      let tempData = JSON.parse(JSON.stringify(data));
      tempData?.data.map((d) => {
        if (!isNaN(d?.PRIMARY_RESOURCE_ID)) {
          d.PRIMARY_RESOURCE_ID = parseInt(d.PRIMARY_RESOURCE_ID) || null;
        }
        if (!isNaN(d?.REPLACEMENT_RESOURCE_ID)) {
          d.REPLACEMENT_RESOURCE_ID =
            parseInt(d.REPLACEMENT_RESOURCE_ID) || null;
        }
        if (!isNaN(d?.POSTALCODE)) {
          d.POSTALCODE = parseInt(d.POSTALCODE) || null;
        }
        const LAST_UPDATED_ON = new Date(d?.LAST_UPDATED_ON);
        if (LAST_UPDATED_ON !== "Invalid Date") {
          d.LAST_UPDATED_ON = LAST_UPDATED_ON;
        }
        return d;
      });
      let filterData = tempData?.data;
      if (filter) {
        filterData = filterBy(tempData?.data || [], filter);
      }
      setResult({
        data: filterData,
        total: filterData.length,
      });
    }
  }, [data]);

  useEffect(() => {
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStateColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);

  const CreatePager = useCallback(({ total, ...props }) => (
    <MyPager
      {...props}
      onSaveViewClick={handleSaveViewClick}
      onDefaultViewClick={handleDefaultViewClick}
    >
      <Pager
        {...props}
        style={{ flex: 1 }}
        total={(filter) ? result.total : data?.totalDataCount || 0}
        skip={pagerSkip}
        onPageChange={handlePageChange}
      />
    </MyPager>
  ), [result, pagerSkip, stateColumns]);

  return (
    <>
      {uploadWindowShow && (
        <UploadWindow
          // selectedDataUpload={selectedDataUpload}
          setUploadWindow={setUploadWindowShow}
        />
      )}
      {downloadWindowShow && (
        <DownloadWindow
          // selectedDataUpload={selectedDataUpload}
          setDownloadWindowShow={setDownloadWindowShow}
        />
      )}
      {showLoader && <LoadMask {...showLoader} />}
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: 6,
          right: 20,
          zIndex: 3
        }}
      >
        <div>
          <Button icon="refresh" title="Refresh" onClick={refreshGrid} />
        </div>
        <div>
          <Button
            icon="download"
            title="Download Plan"
            onClick={() => setDownloadWindowShow(true)}
          />
        </div>
        <div>
          <Button
            icon="upload"
            title="Upload Stores"
            onClick={() => setUploadWindowShow(true)}
            disabled={roleWorkOrderPage?.screensInfo?.screenId == screen ? ( roleWorkOrderPage?.createFlag == 'N' ? true : false ) : true }
          />
        </div>
        <div>
          <Button
            primary
            style={{ width: 100 }}
            title="Save"
            onClick={saveGridDataChanges}
            disabled={!changes}
          >
            Save
          </Button>
        </div>
      </div>
      <SearchFilterWindow
        ref={childRef}
        setResult={setData}
        setSearchTerm={setSearchTerm}
        pagerSkip={pagerSkip}
        setPagerSkip={setPagerSkip}
        dataState={dataState}
        shouldRefresh={shouldRefresh}
        setShouldRefresh={setShouldRefresh}
        searchTerm={searchTerm}
        sort={sort}
        screen={screen}
        roleWorkOrderPage={roleWorkOrderPage}

      />
      <div className="catalog" style={{ padding: 15, flexGrow: 1, minHeight: '60%' }}>
        <Grid
          className="workOrders_grid"
          data={gridData.data.slice(dataState.skip, dataState.skip + pageSize)}
          {...dataState}
          onDataStateChange={dataStateChange}
          style={{
            minHeight: '440px',
          }}
          sortable={true}
          sort={sort}
          rowHeight={50}
          pageSize={pageSize}
          scrollable={'virtual'}
          total={gridData.total}
          onSortChange={(e) => {
            if (filter) {
              setResult((gridData) => {
                const { data, total } = gridData;
                return {
                  data: orderBy(data || [], e.sort),
                  total,
                };
              });
            } else {
              updateCoverageScreenSort(e.sort);
            }
            setSort(e.sort);
          }}
          filter={filter}
          onFilterChange={(e) => {
            let filterData = filterBy(data?.data || [], e.filter);
            if (sort) {
              filterData = orderBy(filterData || [], sort);
            }
            setResult({
              data: filterData,
              total: filterData.length,
            });
            setFilter(e.filter);
          }}
          pageable={{
            pageSizes: [500, 1000, 2000, 5000],
          }}
          onPageChange={onPageChange}
          pager={CreatePager}
          resizable={true}
          onItemChange={itemChange}
          cellRender={customCellRender}
          rowRender={customRowRender}
          editField={EDIT_FIELD}
          dataItemKey={DATA_ITEM_KEY}
          navigatable={true}
          selectedField={"selected"}
          selectable={{
            enabled: true,
            drag: true,
            cell: true,
            mode: "multiple",
          }}
          // onSelectionChange={onSelectionChange}
          reorderable
          onColumnReorder={({ columns }) => {
            const columnOrder = columns.reduce((columnsObj, column) => {
              columnsObj[column.field] = column.orderIndex;
              return columnsObj;
            }, {})
            const newColumns = stateColumns.map((column) => {
              return { ...column, orderIndex: columnOrder[column.field] }
            });
            setStateColumns(newColumns);
          }}
        >
          {stateColumns.map((column, idx) => {
            const menu = columnMenuConfig[column?.columnMenu];
            return (
              column.show && (
                <GridColumn
                  key={idx}
                  field={column.field}
                  title={t(column.title)}
                  // columnMenu={ColumnMenuFilter}
                  format={column?.format}
                  filter={column?.filter}
                  orderIndex={column?.orderIndex}
                  {...(menu && {
                    columnMenu: (props) => {
                      return menu?.({
                        ...props,
                        columns: stateColumns,
                        onColumnsSubmit,
                        data: data?.data,
                      });
                    },
                  })}
                  headerClassName={
                    isColumnActive(column.field, { ...dataState, filter })
                      ? "activeFiltered"
                      : ""
                  }
                  width={column?.width}
                  editable={column.editable}
                // cell={GridCell}
                />
              )
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default CoverageScreen;
