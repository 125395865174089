import { useState, useEffect, useCallback } from "react";
import Header from "../../header";
import Tabs from "../../ui/tabs";
import SearchFilterWindow from "./SearchFilterWindow";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Pager } from "@progress/kendo-react-data-tools";
import LoadMask from "../../../utils/LoadMask";
import { showToast } from "../../../utils/Notification";
import {
  isColumnActive,
} from "../../ui/grid/CustomColumnMenu";
import { columns, columnMenuConfig } from "./column";
import { useSelector, useDispatch } from "react-redux";
import { downloadFile } from "../../../store/actions/people/bulkUploads";
import AppURL from "../../../utils/AppURL";
import UploadWindow from "./UploadWindow";
import DownloadWindow from "./DownloadWindow";
import { filterBy, orderBy, process } from "@progress/kendo-data-query";
import { updateUserPreferenceAction } from "../../../store/actions/commonActions";
import { useTranslation } from "react-i18next";
import { searchUserPrefrence } from "../../../store/actions/dashboard";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";
import { MyPager } from "../../generalHooks";

const GRID_NAME = "RES_UPLOAD_GRID";
const createDataState = (data, dataState) => {
  return {
    result: process(data?.slice(0) || [], dataState),
    dataState: dataState,
  };
};

const setOrderIndex = (columns) => {
  return columns.map((column, i) => ({ ...column, orderIndex: i }))
}

const initialColumnsState = setOrderIndex(columns);

const BulkUploads = ({ tabsObj, activeScreen, setActiveScreen, screen,rolePepplePage}) => {

  console.log(screen,rolePepplePage,"BUHERE")
  const { t } = useTranslation();
  const { handleAuthContext } = useLogout();
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const dispatch = useDispatch();
  const serverColumns = useSelector(
    (state) => state.commonReducer.gridUserPrefrence
  );
  const [uploadWindow, setUploadWindow] = useState(false);
  const [downloadWindow, setDownloadWindow] = useState(false);

  const [sort, setSort] = useState([
    {
      field: "BatchId",
      dir: "asc",
    },
  ]);
  const [data, setData] = useState(null);
  let initialState = createDataState(data?.data || [], {
    take: 25,
    skip: 0,
  });
  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [filter, setFilter] = useState(null);

  const [showLoader, setShowLoader] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [searchTerm, setSearthTerm] = useState("");
  const dataStateChange = (event) => {
    console.log("dataStateChange", event);
    let updatedState = createDataState(data?.data || [], event.dataState);
    console.log("updatedState", updatedState);
    setResult({ ...updatedState.result, total: data?.total });
    setDataState(updatedState.dataState);
  };
  const [stateColumns, setStateColumns] = useState(initialColumnsState);
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || initialColumnsState);
  };

  const handlePageChange = async (e) => {
    const dataState = e.page;
    setDataState(dataState);
    setFilter(null);
  };

  const handleDefaultViewClick = () => {
    onColumnsSubmit();
    // setDataState({...dataState,filter:null})
  };
  const handleRefreshGrid = () => {
    console.log("refresh grid");
    setShouldRefresh(true);
  };

  const CreatePager = useCallback((props) => (
    <MyPager
      {...props}
      onSaveViewClick={handleSaveViewClick}
      onDefaultViewClick={handleDefaultViewClick}
    >
      <div
        className="niq-refresh-button"
        onClick={props?.onRefreshGrid}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="rgba(0,0,0,.9)"
        >
          <path d="M463.5 224h8.5c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2S461.9 48.1 455 55l-41.6 41.6c-87.6-86.5-228.7-86.2-315.8 1-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2S334.3 224 344 224h119.5z"></path>
        </svg>
      </div>
      <Pager style={{ flex: 1 }} {...props} />
    </MyPager>
  ), [stateColumns]);


  const handleSaveViewClick = async () => {
    const Json_Object = JSON.stringify(stateColumns);
    const params = {
      UserPrefReq: {
        Email: authContext?.SpocMail,
        Company_ID: authContext?.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: authContext?.CountryCode,
        Json_Object,
      },
    };
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast("success", t(`${res?.Response?.Message}`));
    } else {
      showToast("error", t(`${res?.Response?.Message}`));
    }
    setShowLoader(null);
  };

  useEffect(() => {
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStateColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);

  const downloadSuccessFile = async (dataItem = {}) => {
    const { BatchId, SuccessFileName } = dataItem;
    const FileName = "Archive_" + SuccessFileName;
    const params = {
      Batchid: BatchId,
      FileName,
    };
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await downloadFile(params, authContext);
    if (res?.headers.get("content-type") === "application/json") {
      const jsonRes = await res.json();
      showToast("error", `${jsonRes?.Output?.Status}`);
    } else if (res?.status === 200) {
      const url = `${AppURL.exportRecords}?BatchId=${BatchId}&FileName=${FileName}`;
      window.location.assign(url);
    }
    setShowLoader(null);
  };
  const downloadErrorFile = async (dataItem = {}) => {
    const { BatchId, ErrorFileName } = dataItem;
    const FileName = "Error_" + ErrorFileName;
    const params = {
      Batchid: BatchId,
      FileName,
    };
    setShowLoader({
      className: ".people_grid",
      msg: "loading",
    });
    const res = await downloadFile(params, authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.headers.get("content-type") === "application/json") {
      const jsonRes = await res.json();
      showToast("error", `${jsonRes?.Output?.Status}`);
    } else if (res?.status === 200) {
      // fmsaud/exportrecords?Batchid=20576&FileName=Archive_Auditor_20576_Success.csv
      const url = `${AppURL.exportRecords}?BatchId=${BatchId}&FileName=${FileName}`;
      window.location.assign(url);
    }
    setShowLoader(null);
  };

  const GridCell = useCallback(({ dataItem, field }) => {
    const dataValue = dataItem?.[field];
    switch (field) {
      case "SucessRecords": {
        return (
          <td style={{ textAlign: "center" }} title="Upload File">
            {dataValue !== null && dataValue !== 0 && dataValue !== "0" ? (
              <Button
                title="Download Success File"
                onClick={() => downloadSuccessFile(dataItem)}
                style={{ border: "1px solid #000" }}
              >
                {dataValue}
              </Button>
            ) : (
              dataValue
            )}
          </td>
        );
      }
      case "ErrorRecords": {
        return (
          <td style={{ textAlign: "center" }} title={t("downloadfile")}>
            {dataValue !== null && dataValue !== 0 && dataValue !== "0" ? (
              <Button
                title="Download Error File"
                onClick={() => downloadErrorFile(dataItem)}
                style={{ border: "1px solid #000" }}
              >
                {dataValue}
              </Button>
            ) : (
              dataValue
            )}
          </td>
        );
      }
      case "UploadedOn": {
        return (
          <td
            className="col-xs-12 col-sm-7 example-col alertGridRow"
            style={{ textAlign: "center" }}
            title={dataValue}
          >
            {dataValue}
          </td>
        );
      }
      default:
        return (
          <td
            className="col-xs-12 col-sm-7 example-col alertGridRow"
            style={{ textAlign: "center" }}
            title={dataValue}
          >
            {dataValue?.toString()}
          </td>
        );
    }
  }, []);

  useEffect(() => {
    if (data) {
      setResult({
        data: data?.data.map((d) => {
          if (!isNaN(d?.BatchId)) {
            d.BatchId = parseInt(d.BatchId);
          }
          if (!isNaN(d?.TotalRecords)) {
            d.TotalRecords = parseInt(d.TotalRecords);
          }
          if (!isNaN(d?.Total)) {
            d.TotalRecords = parseInt(d.Total);
          }
          if (!isNaN(d?.SucessRecords)) {
            d.SucessRecords = parseInt(d.SucessRecords);
          }
          if (!isNaN(d?.ErrorRecords)) {
            d.ErrorRecords = parseInt(d.ErrorRecords);
          }

          return d;
        }),
        ...data,
      });
    }
  }, [data]);
  console.log("dataState comp", { dataState }, { result });

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {uploadWindow && (
        <UploadWindow
          // selectedDataUpload={selectedDataUpload}
          setUploadWindow={setUploadWindow}
          screen={screen}
          rolePepplePage={rolePepplePage}
        />
      )}
      {downloadWindow && (
        <DownloadWindow
          // selectedDataUpload={selectedDataUpload}
          setDownloadWindowShow={setDownloadWindow}
        />
      )}
      <Header activeMenu={t("resourcemanagement")}>
        <div style={{ display: "flex" }}>
          <Tabs
            tabs={tabsObj}
            selected={activeScreen}
            onSelect={(key) => setActiveScreen(key)}
          >
            {({ title }) => {
              return <div>{title}</div>;
            }}
          </Tabs>
          <div
            style={{
              marginInline: "auto 1em",
              // gap: "1em",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              className="k-ghost"
              icon="upload"
              style={{ minWidth: 80 }}
              onClick={() => setUploadWindow(true)}
              disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
            >
              {t("Upload")}
            </Button>
            <hr style={{
              borderLeft: '1px solid #CCCCCC',
              height: '18px'
            }} />
            <Button
              className="k-ghost"
              icon="download"
              style={{ minWidth: 100 }}
              onClick={() => setDownloadWindow(true)}
              //disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
            >
              {t("Download")}
            </Button>
          </div>
        </div>
      </Header>
      <SearchFilterWindow
        setResult={setData}
        setSearthTerm={setSearthTerm}
        setDataState={setDataState}
        // dataState={dataState}
        dataStateTake={dataState.take}
        dataStateSkip={dataState.skip}
        shouldRefresh={shouldRefresh}
        setShouldRefresh={setShouldRefresh}
        searchTerm={searchTerm}
      />

      <div
        style={{
          display: "flex",
          // height: "calc(100% - 100px)",
          padding: "1em",
          paddingTop: 0,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          className="people_grid"
          data={result}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            console.log("sort", { e });
            setResult((result) => {
              const { data, total } = result;
              return {
                data: orderBy(data || [], e.sort),
                total,
              };
            });
            setSort(e.sort);
          }}
          pageable={{
            pageSizes: [10, 25, 50, 100, 250, 500, 1000],
          }}
          onPageChange={handlePageChange}
          filter={filter}
          onFilterChange={(e) => {
            setResult((result) => {
              const { total } = result;
              return {
                data: filterBy(data?.data || [], e.filter),
                total,
              };
            });
            setFilter(e.filter);
          }}
          pager={CreatePager}
          resizable
          reorderable
          onColumnReorder={({ columns }) => {
            const columnOrder = columns.reduce((columnsObj, column) => {
              columnsObj[column.field] = column.orderIndex;
              return columnsObj;
            }, {})
            const newColumns = stateColumns.map((column) => {
              return { ...column, orderIndex: columnOrder[column.field] }
            });
            setStateColumns(newColumns);
          }}
        >
          {stateColumns.map((column, idx) => {
            const menu = columnMenuConfig[column?.columnMenu];
            return (
              column.show && (
                <GridColumn
                  key={column?.orderIndex}
                  field={column.field}
                  title={t(column.title)}
                  width={column.width}
                  cell={GridCell}
                  filter={column.filter}
                  format={column?.format}
                  orderIndex={column?.orderIndex}
                  // columnMenu={ColumnMenuFilter}
                  {...(menu && {
                    columnMenu: (props) => {
                      return menu?.({
                        ...props,
                        columns: stateColumns,
                        onColumnsSubmit,
                        data: data?.data,
                      });
                    },
                  })}
                  headerClassName={
                    isColumnActive(column.field, { ...dataState, filter })
                      ? "activeFiltered"
                      : ""
                  }
                // width={column.width}
                />
              )
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default BulkUploads;
