import React from "react";
import { Link, useHistory } from "react-router-dom";
import workOrdersImg from "../../../assets/img/newWO.png";
import "./index.scss";
import { t } from "i18next";

const LatestWorkOrder = ({ Top5WorkOrders }) => {
  const history = useHistory();

  const handleRowClick = ({ JobId }) => {
    history.push("/work_orders", { JobId });
  };

  return (
    <div
      className='nielsen-card'
      style={{
        width: "30%",
        marginLeft: "10px",
        display: "flex",
        flexDirection: "column",
        padding: '0 10px'
      }}
    >
      <h3>
        {t("latestWO")}
      </h3>
      <div className="lastetWorkOrder-container">
        {Top5WorkOrders?.map((item, i) => {
          return (
            <button key={i} onClick={() => handleRowClick(item)}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img height="26px" width="26px" src={workOrdersImg} alt="" />
                <span style={{ fontWeight: "500", marginLeft: "7px" }}>
                  {item.JobId}
                </span>
              </div>
              <div style={{ textAlign: "left", color: "#747474" }}>
                <div style={{ fontSize: "12px", marginLeft: "35px" }}>
                  {item.CollectionType}
                </div>
                <div
                  style={{ fontSize: "11px", marginLeft: "35px" }}
                >{t(`createdon`)} {item.CreatedOn}</div>
              </div>
            </button>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: ".9em",
        }}
      >
        <Link
          className="k-anchor"
          to="/work_orders"
        >
          {t("viewAll")}
        </Link>
      </div>
    </div>
  );
};

export default LatestWorkOrder;
