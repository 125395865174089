import * as types from "../../actions/types";

const initialState = {
  auditorDetails: [],
};

const WorkOrders = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.GET_AUDITOR_DETAILS:
      newState = {
        ...state,
        auditorDetails: action.payload,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
export const selectAuditorDetails = (state) => state.workOrders.auditorDetails;

export default WorkOrders;
