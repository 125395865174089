import * as types from "../../../actions/types";

const initialState = {
    fetchcourseSkillMapingData : null 
}

const courseSkillReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
      case types.COURSE_SKILL_MAPING_GRID_DATA:
        newState = {
          ...state,
          fetchcourseSkillMapingData: action.payload,
        };
        break;
      default:
        newState = state;
        break;
    }
    return newState;
}

export default courseSkillReducer;