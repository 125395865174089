import { showToast } from "../../utils/Notification";

/* eslint-disable no-restricted-globals */
const customFetch = function () {
  return new Promise((resolve, reject) => {
    window.fetch.apply(this, arguments)
      .then((response) => {
        if (response?.status === '401' || response?.status === 401) {
          alert('Session has been timed out, re-login again');
          window.open(window.location.origin, '_self');
          reject('session timeout');
        }
        else if (response?.status === '403' || response?.status === 403) {
          alert('Un-Authorized access, Please contact administrator');
          window.open(window.location.origin, '_self');
          reject('unauthorized access');
        }
        else if (response?.status === '504' || response?.status === 504) {
          alert('System error occurred, Please contact administrator');
          resolve(response);
        }
        else
          resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

const customFetchwithTimeoutHandler = (url, options, timeout) => {
  const controller = new AbortController();
  const signal = controller.signal;
  
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeout);
  
  
  return new Promise((resolve, reject) => {
    window.fetch(url, { ...options, signal })
      .then((response) => {
        clearTimeout(timeoutId);

        if (response?.status === 401) {
          alert('Session has been timed out, re-login again');
          window.open(window.location.origin, '_self');
          reject('session timeout');
        } else if (response?.status === 403) {
          alert('Un-Authorized access, Please contact administrator');
          window.open(window.location.origin, '_self');
          reject('unauthorized access');
        } else if (response?.status === 504) {
          alert('System error occurred, Please contact administrator');
          resolve(response);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        if (error.name === 'AbortError') {  
          showToast("error","API has Timed Out.Please Kindly Refresh")
        } else {
          reject(error);
        }
      });
  });
};

export const Get = (url, params, headers, encodeURI) => {
  headers = headers || {
    "Content-Type": "text/plain"
  };
  encodeURI = encodeURI === false ? false : true;

  let options = {
    method: "GET",
    headers: headers
  };

  if (params) {
    let esc = encodeURIComponent,
      queryString = Object.keys(params)
        .map(k => encodeURI ? (esc(k) + '=' + esc(params[k])) : (k + '=' + params[k]))
        .join('&');

    url = url + '?' + queryString;
  }

  return customFetch(url, options);
};

 export const GetWithoutParams = (url, headers, encodeURI) => {
  headers = headers || {
    "Content-Type": "text/plain"
  };
  encodeURI = encodeURI === false ? false : true;

  let options = {
    method: "GET",
    headers: headers
  };

  return customFetch(url, options);
};

export const Post = (url, params, headers, skipStringify) => {
  headers = headers || {
    'Accept': 'application/json',
    "Content-Type": "application/json"
  };

  let options = {
    method: "POST",
    headers: headers,
    body: skipStringify ? params : JSON.stringify(params)
  };

  return customFetch(url, options);
};


export const PostwithTimeoutHandler = (url, params, headers, skipStringify, timeout = 10000) => {
  headers = headers || {
    'Accept': 'application/json',
    "Content-Type": "application/json"
  };

  let options = {
    method: "POST",
    headers: headers,
    body: skipStringify ? params : JSON.stringify(params)
  };

  return customFetchwithTimeoutHandler(url, options, timeout);
};

export const FilePost = (url, params) => {
  let options = {
    method: "POST",
    body: params
  };

  return customFetch(url, options);
};

export const Put = (url, params) => {
  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  };

  return customFetch(url, options);
};

export const Patch = (url, params) => {
  let options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  };

  return customFetch(url, options);
};

export const Delete = (url, params) => {
  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  };

  return customFetch(url, options);
};

