import React, { useState, useMemo, useEffect, useCallback } from "react";
import columns, { columnMenuConfig } from "./common/columnRA"
import { Button } from "@progress/kendo-react-buttons";
import Header from "../../../header";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { useDispatch, useSelector } from "react-redux";
import { FormComboBox, FormDatePicker, FormInput } from "../../../form/form-component";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Pager, orderBy } from "@progress/kendo-react-data-tools";
import { showToast } from "../../../../utils/Notification";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";
import { generateReport, getRaSummaryReport } from "./common/raSummaryRequests";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import './index.scss';
import { inputValidator } from "../../../form/validator";
import LoadMask from "../../../../utils/LoadMask";
import { getSearchPlan } from "../../../../store/actions/workOrder";
import ExportHistory from "./ExportHistory";
import { isColumnActive } from "../../../grid/CustomColumnMenu";
import { MyPager } from "../../../generalHooks";
import { updateUserPreferenceAction } from "../../../../store/actions/commonActions";
import { searchUserPrefrence } from "../../../../store/actions/dashboard";
import { filterBy } from "@progress/kendo-data-query";
import { getExportDataWithPostTimeoutHandler } from "../../eCollection/summaryReport/common/getSummaryReport";
import { PostwithTimeoutHandler } from "../../../../store/methods";
import AppURL from "../../../../utils/AppURL";

const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            take: dataState.take,
        },
        dataState: dataState,
    };
};

let initialState = createDataState([], {
    take: 20,
    skip: 0,
});

const setOrderIndex = (columns) => {
    return columns.map((column, i) => ({ ...column, orderIndex: i }))
}

const initialColumnsState = setOrderIndex(columns);
const GRID_NAME = "RA_SUMMARY_GRID"

function AuditSummaryRA(props) {
    const { t, i18n } = useTranslation();
    const { handleAuthContext } = useLogout();
    const [showForm, setShowForm] = useState(true);
    const [year, setYear] = useState([]);
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [expandIcon, setExpandIcon] = useState("fullscreen");
    const [showLoader, setShowLoader] = useState(null);
    const [formWorkOrderId, setFormWorkOrderId] = useState([]);
    const [sort, setSort] = useState([
        {
            field: "BatchId",
            dir: "asc",
        },
    ]);
    const [stateColumns, setStateColumns] = useState(initialColumnsState);
    const [exportHistory, setExportHistory] = useState(false);
    const [formEQ, setFormEQ] = useState([]);
    const [dataSet, setDataSet] = useState({
        result: { data: [], total: 0 },
        initialResult: { data: [], total: 0 }
    });
    const [filter, setFilter] = useState(null);
    const locationData = useSelector((state) => state.commonReducer.locationData);
    const dateStamp = useSelector((store) => store?.reports?.dateStamp)
    const authContext = useSelector((state) => state.commonReducer.authContext);
    const serverColumns = useSelector(
        (state) => state.commonReducer.gridUserPrefrence
    );
    const dispatch = useDispatch();

    const initialFormValue = useMemo(() => {
        return {
            Region: locationData?.Region?.RegionID,
            Country: locationData?.Country?.[0]?.CountryID,
            year: dateStamp?.selectedYear?.DisplayValue.toString(),
        };
    }, [locationData, dateStamp]);

    const [selectedData, setSelectedData] = useState(initialFormValue);

    const fetchRASummaryReport = async (newParams = {}) => {
        console.log('authContext', authContext, selectedData)

        const params2 = {
            "CollectionType": "RA",
            "ReportName": "SUMMARY_REPORT",
            "APIName": "getNavAuditSummaryReport",
            "Payload": {
                "GetNavAuditSummaryReq": {

                    "auditor_id": selectedData?.auditorId || "",
                    "country_id": selectedData.Country,
                    "plan_id": selectedData?.JobId,
                    "sms_id": selectedData?.smsId || "",
                    "user_id": authContext.SpocMail,
                    "index": selectedData?.index || "",
                    "start": 0,
                    "limit": dataState.take,
                    ...newParams,

                }
            }
        }


        // const params = {
        //     "GetNavAuditSummaryReq": {
        //         "audtior_id": selectedData?.auditorId || "",
        //         "country_id": selectedData.Country,
        //         "plan_id": selectedData?.JobId,
        //         "sms_id": selectedData?.smsId || "",
        //         "user_id": authContext.SpocMail,
        //         "index": selectedData?.index || "",
        //         "start": 0,
        //         "limit": dataState.take,
        //         ...newParams,
        //     }
        // };

        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });

        const res = await getRaSummaryReport(JSON.stringify(params2), authContext);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.GetNavAuditSummaryRes?.StatusCode === "200" && Array.isArray(res?.GetNavAuditSummaryRes?.StoresInfo)) {
            const result = {
                data: res?.GetNavAuditSummaryRes?.StoresInfo,
                total: res?.GetNavAuditSummaryRes?.TotalCount,
            };
            setDataSet({
                result: result,
                initialResult: result
            });
        } else {
            const result = {
                data: [],
                total: 0,
            };
            setDataSet({
                result: result,
                initialResult: result
            });
            showToast(
                "error",
                t("dataNotFound", `${res?.GetNavAuditSummaryRes?.StatusMsg ? res?.GetNavAuditSummaryRes?.StatusMsg : "No Data Found"}`),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
        setShowLoader(null);
    }

    const onSearchClick = async (e) => {
        if (!e.isValid) {
            return
        }
        setExportHistory(false);
        setTimeout(() => {
            fetchRASummaryReport()
        }, 200)
    };

    const onChange = (e) => {
        if (
            e.target?.component?.name === "year" ||
            e.target?.component?.name === "Country" ||
            e.target?.component?.name === "Region" ||
            e.target?.component?.name === "JobId"
        ) {
            setSelectedData({
                ...selectedData, [e.target.component.name]: e.value
            })
        } else if (e.target.name === "auditorId" ||
            e.target.name === "smsId" ||
            e.target.name === "index") {
            setSelectedData({
                ...selectedData, [e.target.name]: e.value
            })
        } else {
            setSelectedData({
                ...selectedData, [e.target.name]: moment(e.value).format("YYYY-MM-DD")
            })
        }
    }

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
    };

    const fetchSearchPlan = async () => {
        const params = {
            _dc: Date.now(),
            Region: selectedData.Region,
            Country: selectedData.Country,
            CollectionType: "RA",
            year: selectedData.year,
            startdate: moment(selectedData?.fromdate).format("MM/DD/YYYY"),
            enddate: moment(selectedData?.todate).format("MM/DD/YYYY"),
            CompanyID: authContext.CompanyID,
            page: 1,
            StartCount: 0,
            MaxCount: 1000,
        };

        setShowLoader({
            // className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await getSearchPlan(params, authContext);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }
        if (!res?.SearchPlan) {
            showToast("error", t("workorderisnotavailableforsearchedweek"));
        }
        setFormEQ(res?.SearchPlan);
        setShowLoader(null);
    };

    useEffect(() => {
        if (serverColumns?.length > 0) {
            const currentDataColumns = serverColumns.find(
                (c) => c?.Grid_Name === GRID_NAME
            );
            try {
                const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
                if (currentStateColumns.every((column) => column.field)) {
                    setStateColumns(currentStateColumns);
                }
            } catch (e) {
                console.log("error in state columns", currentDataColumns?.Json_object);
                return;
            }
        }
    }, [serverColumns]);

    useEffect(() => {
        console.log('selectedData', selectedData)
        if (selectedData.Region &&
            selectedData.Country &&
            selectedData.year &&
            selectedData?.fromdate &&
            selectedData?.todate
        ) {
            fetchSearchPlan();
        }
    }, [
        selectedData.Region,
        selectedData.Country,
        selectedData.year,
        selectedData?.fromdate,
        selectedData?.todate,
    ]);

    const expand = () => {
        setShowForm(!showForm);
        setExpandIcon(!showForm ? "fullscreen" : "fullscreen-exit")
    }

    const handleExportHistory = () => {
        setExportHistory(!exportHistory);
    }

    const onExport = async () => {
        if (selectedData.JobId) {
            let params = {
                "GenerateReportReq": {
                    "countryID": selectedData.Country,
                    "user": authContext.SpocMail,
                    "reportType": "NAV_AUDIT_SUMMARY_REPORT",
                    "transactionID": selectedData.JobId,
                    "StoreIndex": selectedData?.index || "",
                }
            }

            const params2 = {
                "CollectionType": "RA",
                "ReportName": "SUMMARY_REPORT",
                "APIName": "generateReport",
                "Payload": {
                    ...params
                }
            }

            setShowLoader({
                // className: ".workOrders_grid",
                msg: "loading",
            });

           

            const header = {
                OrganizationUnit: authContext?.organizationUnit || "",
                userid: authContext?.SpocMail || "",
                countryid: authContext?.CountryCode || "",
                companyid:authContext?.CompanyID || "",
                "content-type": "application/json",
                // "content-type": "application/x-www-form-urlencoded; application/json",
            }

            // const res = await generateReport(JSON.stringify(params), authContext)


            const res = await PostwithTimeoutHandler(AppURL.reportsAPI,params2, header,false,10000).then((res) =>
                // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
                  res.json()
            );

            console.log(res,"ressss")

            if(res?.status?.statusFlag==="Success"){

                showToast(
                    "success",
                    t('export initated')
                );

            }

            

            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }
            setShowLoader(null);
        } else {
            showToast(
                "error",
                t("pleasefillthemandatoryfields"),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
    }

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState || initialColumnsState);
    };

    const handleDefaultViewClick = () => {
        onColumnsSubmit();
    };

    const handleSaveViewClick = async () => {
        const params = {
            UserPrefReq: {
                Email: authContext.SpocMail,
                Company_ID: authContext.CompanyID,
                Grid_Name: GRID_NAME,
                Country_ID: selectedData.Country,
                Json_Object: JSON.stringify(stateColumns),
            },
        };
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await updateUserPreferenceAction(
            JSON.stringify(params),
            authContext
        );
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.Response?.Status === "Success") {
            dispatch(
                searchUserPrefrence(
                    JSON.stringify({
                        UserPrefReq: {
                            Email: authContext?.SpocMail,
                            Company_ID: authContext?.CompanyID,
                        },
                    })
                )
            );
            showToast(
                "success",
                t(res?.Response?.Message || "Success")
            );
        } else {
            showToast(
                "error",
                t(res?.Response?.Message || "dataNotFound")
            );
        }
        setShowLoader(null);
    };

    const CreatePager = useCallback((props) => (
        <MyPager
            {...props}
            onSaveViewClick={handleSaveViewClick}
            onDefaultViewClick={handleDefaultViewClick}
        >
            <Pager style={{ flex: 1 }} {...props} />
        </MyPager>
    ), [stateColumns, selectedData.Country]);

    const handlePageChange = async (e) => {
        const dataState = e.page;
        setDataState(dataState);
        fetchRASummaryReport({
            start: dataState.skip,
        });
    };

    useEffect(() => {
        let yearData = [];
        dateStamp?.yearData?.map(elm => (
            yearData.push(elm.DisplayValue)
        ));
        setYear([...new Set(yearData)]);
    }, [dateStamp?.yearData]);

    useEffect(() => {
        if (formEQ) {
            let workOrder_id = [];
            formEQ?.map(elm => (
                workOrder_id.push(elm.JobId)
            ));
            setFormWorkOrderId([...new Set(workOrder_id)])
        }
    }, [formEQ]);

    return (
        <>
            {showLoader && <LoadMask {...showLoader} />}
            <div className="ra-report">
                <Header activeMenu={"Nielsen Navigator - Audit Summary Report"}>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginInline: "auto 1em",
                            padding: '6px'
                        }}
                    >
                        <NavLink className='k-button k-secondary' to="/reports">
                            Back
                        </NavLink>
                    </div>
                </Header>
            </div>
            {showForm ?
                <div className="ra-form">
                    <Form
                        initialValues={{
                            Region: locationData?.Region?.RegionID,
                            Country: locationData?.Country?.[0]?.CountryID,
                            year: dateStamp?.selectedYear,
                        }}
                        onSubmitClick={onSearchClick}
                        render={(formRenderProps) => (
                            <FormElement>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "0px 25px",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <Field
                                        id="ra-region"
                                        name={"Region"}
                                        validator={inputValidator}
                                        component={FormComboBox}
                                        label={t("REGION")}
                                        required={true}
                                        onChange={onChange}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        textField="RegionName"
                                        valueField="RegionID"
                                        data={[locationData?.Region]}
                                        defaultValue={locationData?.Region?.RegionID}
                                        value={locationData?.Region?.RegionID}
                                    />
                                    <Field
                                        id="ra-country"
                                        name={"Country"}
                                        component={FormComboBox}
                                        label={t("COUNTRY")}
                                        required={true}
                                        onChange={onChange}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        textField="CountryName"
                                        valueField="CountryID"
                                        validator={inputValidator}
                                        data={locationData?.Country || []}
                                        defaultValue={locationData?.Country?.[0]?.CountryID}
                                    />
                                    <Field
                                        id="ra-year"
                                        name="year"
                                        label={t("YEAR")}
                                        component={FormComboBox}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        placeholder={t("YEAR")}
                                        data={year}
                                        validator={inputValidator}
                                        defaultValue={dateStamp?.selectedYear?.DisplayValue}
                                        required={true}
                                        onChange={onChange}
                                    />
                                    <Field
                                        id="ra-fromdate"
                                        name="fromdate"
                                        label={t("fromdate")}
                                        component={FormDatePicker}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        placeholder={"From Date"}
                                        onChange={onChange}
                                        validator={inputValidator}
                                        required={true}
                                        max={selectedData?.todate ? new Date(selectedData?.todate) : undefined}
                                    />
                                    <Field
                                        id="ra-todate"
                                        name="todate"
                                        label={t("todate")}
                                        component={FormDatePicker}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        placeholder={"To Date"}
                                        onChange={onChange}
                                        validator={inputValidator}
                                        required={true}
                                        min={selectedData?.fromdate ? new Date(selectedData?.fromdate) : undefined}
                                    />
                                    <Field
                                        id="ra-workorderid"
                                        name="JobId"
                                        label={t("WORKORDERID")}
                                        component={FormComboBox}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        placeholder={t("WORKORDERID")}
                                        data={formWorkOrderId}
                                        onChange={onChange}
                                        validator={inputValidator}
                                        required={true}
                                    />
                                    <Field
                                        id="ra-auditorid"
                                        name="auditorId"
                                        label={t("AUDITOR ID")}
                                        component={FormInput}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        placeholder={t("AUDITOR ID")}
                                        onChange={onChange}
                                    />
                                    <Field
                                        id="ra-smsid"
                                        name="smsId"
                                        label={t("SMS ID")}
                                        component={FormInput}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        placeholder={t("SMS ID")}
                                        onChange={onChange}
                                    />
                                    <Field
                                        id="ra-index"
                                        name="index"
                                        label={t("Index")}
                                        component={FormInput}
                                        wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                        placeholder={t("Index")}
                                        onChange={onChange}
                                    />
                                    <div
                                        className={'k-actions k-hstack k-justify-content-stretch'}
                                        style={{ marginTop: "32px", gap: "0px 8px", marginLeft: "-7px" }}
                                    >
                                        <Button primary>{t("SEARCH")}</Button>
                                        <Button
                                            type={'reset'}
                                            className={'k-secondary'}
                                            onClick={() => {
                                                setSelectedData(initialFormValue);
                                                setTimeout(() => {
                                                    formRenderProps.onFormReset();
                                                }, 0);
                                            }}
                                        >
                                            {t("RESET")}
                                        </Button>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
                :
                <div></div>
            }
            {exportHistory ?
                <ExportHistory
                    exportHistory={exportHistory}
                    setExportHistory={setExportHistory}
                />
                :
                <div
                    className="nielsen-card"
                    style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
                >
                    <div className="eq_data_heading" style={{ margin: "0px -6px" }}>
                        <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
                        <div>
                            <Button
                                onClick={handleExportHistory}
                                style={{ margin: "10px", border: "none" }}
                                primary
                            >{t("Export history")}</Button>
                            <Button
                                onClick={onExport}
                                style={{ margin: "10px", border: "none" }}
                                primary
                            >{t("Initiate Export")}</Button>

                            <Button icon={expandIcon}
                                onClick={expand}
                                className={'k-secondary'}
                                style={{ margin: "10px", marginRight: '20px' }}
                            ></Button>
                        </div>

                    </div>
                    <div className="catalog" style={{
                        display: "flex",
                        padding: "1rem",
                        flexGrow: 1,
                        overflow: "auto",
                    }}
                    >
                        <Grid
                            className="workOrders_grid"
                            data={dataSet.result.data}
                            total={dataSet.result.total}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                const sortedData = {
                                    data: orderBy(dataSet.result.data || [], e.sort),
                                    total: dataSet.result.total,
                                };
                                setDataSet({
                                    ...dataSet,
                                    result: sortedData
                                });
                                setSort(e.sort);
                            }}
                            filter={filter}
                            onFilterChange={(e) => {
                                if (e.filter) {
                                    const filterData = filterBy(dataSet.initialResult.data || [], e.filter)
                                    setDataSet({
                                        ...dataSet,
                                        result: { data: filterData, total: filterData.length }
                                    });
                                } else {
                                    setDataSet({
                                        ...dataSet,
                                        result: dataSet.initialResult
                                    })
                                }
                                setFilter(e.filter)
                            }}
                            onPageChange={handlePageChange}
                            pageable={{
                                pageSizes: false,
                            }}
                            pager={CreatePager}
                            resizable
                            reorderable
                            onColumnReorder={({ columns }) => {
                                const columnOrder = columns.reduce((columnsObj, column) => {
                                    columnsObj[column.field] = column.orderIndex;
                                    return columnsObj;
                                }, {})
                                const newColumns = stateColumns.map((column) => {
                                    return { ...column, orderIndex: columnOrder[column.field] }
                                });
                                setStateColumns(newColumns);
                            }}
                        >
                            {stateColumns.map(
                                (column, idx) => {
                                    const menu = columnMenuConfig[column?.columnMenu];
                                    return column.show &&
                                        (

                                            <GridColumn
                                                key={column?.orderIndex}
                                                field={column?.field}
                                                title={column.title}
                                                width={column?.width}
                                                minResizableWidth={30}
                                                style={{ textAlign: "center" }}
                                                format={column?.format}
                                                filter={column?.filter}
                                                orderIndex={column?.orderIndex}
                                                {...(menu && {
                                                    columnMenu: (props) => {
                                                        return menu?.({
                                                            ...props,
                                                            columns: stateColumns,
                                                            onColumnsSubmit,
                                                            data: dataSet.result?.data,
                                                        });
                                                    },
                                                })}
                                                headerClassName={
                                                    isColumnActive(column.field, { ...dataState, filter })
                                                        ? "activeFiltered"
                                                        : ""
                                                }
                                            />
                                        )
                                })}
                        </Grid>
                    </div>
                </div>
            }
        </>
    )
}

export default AuditSummaryRA
