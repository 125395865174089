
import { useLocalization } from "@progress/kendo-react-intl";

export const setOrderIndex = (columns) => {
    return columns.map((column, i) => ({ ...column, orderIndex: i }))
}
// eslint-disable-next-line
String.prototype.toCapitalize = () => {
    let str = this;
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toUpperCase() : match.toLowerCase();
    });
};

// eslint-disable-next-line no-extend-native
String.prototype.toCamelCase = function () {
    return this
        .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
        .replace(/\s/g, '')
        .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
};

// eslint-disable-next-line
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

// eslint-disable-next-line
Array.prototype.unique = function () {
    var a = this.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

const initialFont = 14;
document.getElementsByTagName('html')[0].style.fontSize = initialFont + 'px';
const Utility = {
    fontSize: initialFont,
    globalPageSize: 15,
    getPageSize: (bodyHeight) => {
        return Number.parseInt(((bodyHeight - Number.parseInt(5.1 * Utility.fontSize)) / (2.1 * Utility.fontSize))) || 15
    },
    getCookie: (key) => {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    },

    cloneObject: (object) => {
        let clonedObj = undefined;
        clonedObj = JSON.parse(JSON.stringify(object));

        return clonedObj;
    },

    generateKey: (pre) => {
        return pre + "_" + new Date().getTime();
    }
};
export default Utility;

export const ExportMessage = () => {
    const localizationService = useLocalization();
    return (
        <p style={{ textAlign: 'left' }}>{localizationService.toLanguageString('pIIConfirmation', `NielsenIQ must ensure that Individual-Level Data it 
            Processes has been obtained in a lawful and appropriate manner and that such data is used only to the extent and for 
            the time period that is needed to achieve specified business purposes. Only Individual-Level Data that is necessary
            for our business purposes should be collected, used, and retained. Additionally, only those NielsenIQ associates
            or third parties (such as NielsenIQ affiliates, service providers and contractors) who have a legitimate business
            need to Process Individual-Level Data should be given access to the data.`)}
            <br /><br /><b>{localizationService.toLanguageString('pIIExportNote',
                'Please note that your user information and access time stamp will be logged before exporting the data')}</b></p>
    );
};

export const PIIMessage = () => {
    const localizationService = useLocalization();
    return (
        <p style={{ textAlign: 'left' }}>{localizationService.toLanguageString('pIIConfirmation', `NielsenIQ must ensure that Individual-Level Data it 
            Processes has been obtained in a lawful and appropriate manner and that such data is used only to the extent and for 
            the time period that is needed to achieve specified business purposes. Only Individual-Level Data that is necessary
            for our business purposes should be collected, used, and retained. Additionally, only those NielsenIQ associates
            or third parties (such as NielsenIQ affiliates, service providers and contractors) who have a legitimate business
            need to Process Individual-Level Data should be given access to the data.`)}
            <br /><br /><b>{localizationService.toLanguageString('pIIViewNote',
                'Please note that your user information and access time stamp will be logged before seeing the data')}</b></p>
    );
};

export const sortArrayData = (data = [], keyName) => {
    return data.sort((a, b) =>
        a[keyName] > b[keyName] ? 1 : b[keyName] > a[keyName] ? -1 : 0
    );
};

export const capsuleToParams = (newCapsuleFilter) => {
    return newCapsuleFilter.reduce((acc, cur) => {
        const { name, value } = cur;
        if (acc?.[name]) {
            return {
                ...acc,
                [name]: `${acc[name]},${value}`,
            };
        }
        return {
            ...acc,
            [name]: value,
        };
    }, {});
};