import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
// {
//   "JobId":"16046",
//   "Country_id":"76",
//   "Frequency":"WEEKLY",
//   "Period":"2",
//   "WeekNo":"6",
//   "Status":"COMPLETED",
//   "CollectionType":"NSO",
//   "Year":"2023",
//   "StartDate":"2023-02-06T00:00:00Z",
//   "EndDate":"2023-02-12T00:00:00Z",
//   "TotalStores":"16",
//   "LastUpdatedBy":"smaddi1234@mailinator.com",
//   "TaskType":"COPY_SA Navigator test"
// }
const columns = [
    {
      title: "Job Id",
      field: "JobId",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:100,
      filter:"numeric"
    },
    {
      title: "Collection Type",
      field: "CollectionType",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:120,
    },
    {
      title: "Task Type",
      field: "TaskType",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:150,
    },
    {
      title: "Frequency",
      field: "Frequency",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:100,
    },
    {
      title: "Year",
      field: "Year",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:100,
    },
    {
      title: "Week",
      field: "WeekNo",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:100,
      format:"{0:MM-dd-yyyy}",
      
    },
    {
      title: "Status",
      field: "Status",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:100,
    },
    {
      title: "Last Updated By",
      field: "LastUpdatedBy",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:200
    },
    {
      title: "Start Date",
      field: "StartDate",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:150,
    },
    {
      title: "End Date",
      field: "EndDate",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:150,
    },
    {
      title: "Store Count",
      field: "TotalStores",
      show: true,
      columnMenu:CustomColumnMenuFilter,
      width:100,
    },
  ];
  export default columns;
  