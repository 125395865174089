import { Field, Form, FormElement } from '@progress/kendo-react-form';
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FormComboBox, FormInput } from '../../../form/form-component';
import { inputValidator } from '../../../form/validator';
import columns, { columnMenuConfig } from './common/columnSummaryReport';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Pager } from "@progress/kendo-react-data-tools";
import './SummaryReport.css';
import LoadMask from "../../../../utils/LoadMask";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../../utils/Notification';
import { toast } from "react-toastify";
import { updateUserPreferenceAction } from '../../../../store/actions/commonActions';
import { isColumnActive } from '../../../grid/CustomColumnMenu';
import { getExecutionQualityReport } from '../execution-qualityReport/common/executionqualityReport';
import { getExportData, getSummaryReport, getEcollectionSummaryReport, getExportDataWithPostTimeoutHandler } from './common/getSummaryReport';
import { useTranslation } from "react-i18next"; import { filterBy, orderBy } from '@progress/kendo-data-query';
import { searchUserPrefrence } from '../../../../store/actions/dashboard';
import useLogout, { checkInvalidSession } from '../../../../utils/useLogout';
import { MyPager } from '../../../generalHooks';
import Header from '../../../header';

const GRID_NAME = "ECOL_SUMMORY_GRID"

const createDataState = (data, dataState) => {
    return {
        result: {
            data,
            take: dataState.take,
        },
        dataState: dataState,
    };
};


let initialState = createDataState([], {
    take: 50,
    skip: 0,
});

const setOrderIndex = (columns) => {
    return columns.map((column, i) => ({ ...column, orderIndex: i }))
}
const initialColumnsState = setOrderIndex(columns)

const SummaryReport = () => {
    const { handleAuthContext } = useLogout();
    const [showForm, setShowForm] = useState(true)
    const [stateColumns, setStateColumns] = useState(initialColumnsState);
    const [data, setData] = useState({ data: [], total: 0 });
    const [showLoader, setShowLoader] = useState(null);
    const [formWorkOrderId, setFormWorkOrderId] = useState([]);

    const [weekNumber, setWeekNumber] = useState([]);
    const [year, setYear] = useState([]);

    const [formEQ, setFormEQ] = useState([]);
    const [result, setResult] = React.useState(initialState.result);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const { t } = useTranslation();
    const [expandIcon, setExpandIcon] = useState("fullscreen");

    const history = useHistory();
    const dispatch = useDispatch();
    const locationData = useSelector((state) => state.commonReducer.locationData);
    const dateStamp = useSelector((store) => store?.reports?.dateStamp)

    const authContext = useSelector((state) => state.commonReducer.authContext);

    const serverColumns = useSelector(
        (state) => state.commonReducer.gridUserPrefrence
    );

    const initialFormValue = useMemo(() => {
        return {
            Region: locationData?.Region?.RegionID,
            Country: locationData?.Country?.[0]?.CountryID,
            year: dateStamp?.selectedYear?.DisplayValue.toString(),
            week: dateStamp?.selectedWeek?.DisplayValue.toString(),
            plantype: "LIVE"
        };
    }, [locationData]);
    const [selectedData, setSelectedData] = useState(initialFormValue);
    const [filter, setFilter] = useState(null);
    const [sort, setSort] = useState([
        {
            field: "BatchId",
            dir: "asc",
        },
    ]);

    const dataStateChange = (event) => {
        console.log(event);
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    useEffect(() => {
        if (serverColumns?.length > 0) {
            const currentDataColumns = serverColumns.find(
                (c) => c?.Grid_Name === GRID_NAME
            );
            try {
                const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
                if (currentStateColumns.every((column) => column.field)) {
                    setStateColumns(currentStateColumns);
                }
            } catch (e) {
                console.log("error in state columns", currentDataColumns?.Json_object);
                return;
            }
        }
    }, [serverColumns]);




    useEffect(() => {
        setSelectedData({
            ...selectedData, Region: locationData?.Region?.RegionID,
            Country: locationData?.Country?.[0]?.CountryID,
            year: dateStamp?.selectedYear?.DisplayValue.toString(),
            week: dateStamp?.selectedWeek?.DisplayValue.toString(),
            plantype: "LIVE",
        })
    }, [locationData?.Country, dateStamp])

    const onExport = async () => {
        if (selectedData.Region && selectedData.Country) {
            // let params = {
            //     "SummaryReportReq": JSON.stringify({ "Request": { "Region": selectedData.Region, "Country": selectedData.Country, "CollectionType": "ECOLLECTION", "Cycle": null, "Year": selectedData.year, "Week": selectedData.week, "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "FromDate": "", "ToDate": "", "CompanyId": authContext.CompanyID, "StoreType": selectedData.plantype ? selectedData.plantype : "" } }),
            // }
            // let params = {
            //     SummaryReportReq: JSON.stringify({ "Request": { "Region": selectedData.Region, "Country": selectedData.Country, "CollectionType": "ECOLLECTION", "Cycle": null, "Year": selectedData.year, "Week": selectedData.week, "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "FromDate": "", "ToDate": "", "CompanyId": authContext.CompanyID, "StoreType": selectedData.plantype ? selectedData.plantype : "" } })
            // }
            let params = {
                "ReportType": "summary",
                "Region": selectedData.Region,
                "Country": selectedData.Country,
                "CollectionType": "ECOLLECTION",
                "Cycle": null,
                "Year": selectedData.year,
                "Week": selectedData.week,
                "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
                "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
                "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
                "FromDate": "",
                "ToDate": "",
                "CompanyId": authContext.CompanyID,
                "StoreType": selectedData.plantype ? selectedData.plantype : ""
            }

            let params2 = {
                "CollectionType": "ECOLLECTION",
                "ReportName": "SUMMARY_REPORT",
                "APIName": "Reports",
                "Payload": {
                    "ReportType": "summary",
                    "Region": selectedData.Region,
                    "Country": selectedData.Country,
                    "CollectionType": "ECOLLECTION",
                    "Cycle": null,
                    "Year": selectedData.year,
                    "Week": selectedData.week,
                    "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
                    "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
                    "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
                    "FromDate": "",
                    "ToDate": "",
                    "CompanyId": authContext.CompanyID,
                    "StoreType": selectedData.plantype ? selectedData.plantype : ""
                }
            }


            setShowLoader({
                // className: ".workOrders_grid",
                msg: "loading",
            });
            showToast(
                "info",
                t('filedownload_msglbl')
            );
            // no need to json stringify
            // const res = await getExportData(params, authContext)
            const res = await getExportDataWithPostTimeoutHandler(params2, authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }
            setShowLoader(null);
        } else {
            showToast(
                "error",
                t("pleasefillthemandatoryfields"),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }

    }

    const expand = () => {
        setShowForm(!showForm);
        setExpandIcon(!showForm ? "fullscreen" : "fullscreen-exit")
    }

    useEffect(() => {
        const searchPlan = async () => {
            let params = {
                Region: selectedData.Region, Country: selectedData.Country, CollectionType: "ECOLLECTION", year: selectedData.year, week: selectedData.week,
                CompanyID: authContext.CompanyID,
                // CompanyID: "358",
                _dc: "1673938014148"
            }

            // if (formData.countryID && formData.regionID && formData.year && formData.startdate && formData.surveyId && formData.planId && formData.storeId && formData.status) {

            setShowLoader({
                // className: ".workOrders_grid",
                msg: "loading",
            });
            const res = await getExecutionQualityReport(params, authContext)
            if (checkInvalidSession(res)) {
                handleAuthContext();
                return;
            }

            // setDctTable(res?.Response?.DctReportSearchResultResMsg?.DctReportSearchResultRes);
            setFormEQ(res?.SearchPlan);
            console.log("aaa", res);

            setShowLoader(null);
            // }
        }

        searchPlan();

    }, [])

    const onChangeWeekNumber = async (e) => {
        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region, Country: selectedData.Country, CollectionType: "ECOLLECTION", year: selectedData.year, week: e.value,
            CompanyID: authContext.CompanyID,
            // CompanyID: "358",
            _dc: "1673938014148"
        }

        // if (formData.countryID && formData.regionID && formData.year && formData.startdate && formData.surveyId && formData.planId && formData.storeId && formData.status) {

        setShowLoader({
            msg: "loading",
        });
        const res = await getExecutionQualityReport(params, authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        // setFormEQ(res?.SearchPlan);

        setShowLoader(null);
        if (res?.SearchPlan) {
            setFormEQ(res?.SearchPlan);
        } else {
            showToast("error", `${t("workorderisnotavailableforsearchedweek")}`);
        }
    }

    const onChangeYear = async (e) => {

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region, Country: selectedData.Country, CollectionType: "ECOLLECTION", week: selectedData.week, year: e.value,
            CompanyID: authContext.CompanyID,
            // CompanyID: "358",
            _dc: "1673938014148"
        }

        // if (formData.countryID && formData.regionID && formData.year && formData.startdate && formData.surveyId && formData.planId && formData.storeId && formData.status) {

        setShowLoader({
            msg: "loading",
        });
        const res = await getExecutionQualityReport(params, authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        // setDctTable(res?.Response?.DctReportSearchResultResMsg?.DctReportSearchResultRes);
        setFormEQ(res?.SearchPlan);
        console.log("aaa", res);

        setShowLoader(null);
        // }
    }

    const onChangeCountry = async (e) => {

        setSelectedData({
            ...selectedData, [e.target.component.name]: e.value
        });

        let params = {
            Region: selectedData.Region, Country: e.value, CollectionType: "ECOLLECTION", year: selectedData.year, week: selectedData.week,
            CompanyID: authContext.CompanyID,
            // CompanyID: "358",
            _dc: "1673938014148"
        }

        // if (formData.countryID && formData.regionID && formData.year && formData.startdate && formData.surveyId && formData.planId && formData.storeId && formData.status) {

        setShowLoader({
            msg: "loading",
        });
        const res = await getExecutionQualityReport(params, authContext)
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        // setDctTable(res?.Response?.DctReportSearchResultResMsg?.DctReportSearchResultRes);
        setFormEQ(res?.SearchPlan);

        setShowLoader(null);
        // }
    }

    useEffect(() => {
        let workOrder_id = [];
        let weekNumberData = [];
        let yearData = [];

        formEQ?.map(elm => (
            workOrder_id.push(elm.JobId)

        ))
        setFormWorkOrderId([...new Set(workOrder_id)])

        dateStamp?.weekData?.map(elm => (
            weekNumberData.push(elm.DisplayValue)
        ))
        setWeekNumber([...new Set(weekNumberData)])

        dateStamp?.yearData?.map(elm => (
            yearData.push(elm.DisplayValue)
        ))
        setYear([...new Set(yearData)])

    }, [formEQ, dateStamp?.weekData, dateStamp?.yearData])

    const onChange = (e) => {
        if (e.target?.component?.name === "year" || e.target?.component?.name === "plantype" || e.target?.component?.name === "week" || e.target?.component?.name === "Country" || e.target?.component?.name === "JobId") {
            setSelectedData({
                ...selectedData, [e.target.component.name]: e.value
            })
        } else {
            setSelectedData({
                ...selectedData, [e.target.name]: e.value
            })
        }
    }

    const onSearchClick = async (e) => {
        if (!e.isValid) {
            return
        }
        fetchSummaryReport()
    };

    const handlePageChange = async (e) => {
        const dataState = e.page;
        setDataState(dataState);
        fetchSummaryReport({
            page: Math.floor(dataState.skip / dataState.take) + 1,
            startCount: dataState.skip,
            MaxCount: dataState.take,
        });
    };

    const fetchSummaryReport = async (newParams = {}) => {

        const params2 = {
            "CollectionType": "ECOLLECTION",
            "ReportName": "SUMMARY_REPORT",
            "APIName": "getSummaryReport",
            "Payload": {
                "Region": selectedData.Region,
                "Country": selectedData.Country,
                "CollectionType": "ECOLLECTION",
                "Cycle": null,
                "Year": selectedData.year?.toString(),
                "Week": selectedData.week?.toString(),
                "FromDate": "",
                "ToDate": "",
                "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
                "CompanyId": authContext.CompanyID,
                "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
                "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
                "StoreType": selectedData.plantype,
                "page": 1,
                "startCount": 0,
                "MaxCount": 50,
                ...newParams,
            }
        }

        //    setShowLoader({
        //     className: ".workOrders_grid",
        //     msg: "loading",
        // });
        // const params = {
        //     // JSON.stringify({ "Request": { "Region": "2", "Country": "724", "CollectionType": "ECOLLECTION", "Cycle": null, "Year": "2023", "Week": "1", "FromDate": "", "ToDate": "", "WorkOrderID": "", "CompanyId": "169", "StoreNumber": "", "AuditorId": "", "StoreType": "ALL" } }),
        //     "Region": selectedData.Region,
        //     "Country": selectedData.Country,
        //     "CollectionType": "ECOLLECTION",
        //     "Cycle": null,
        //     "Year": selectedData.year?.toString(),
        //     "Week": selectedData.week?.toString(),
        //     "FromDate": "",
        //     "ToDate": "",
        //     "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
        //     "CompanyId": authContext.CompanyID,
        //     "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
        //     "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
        //     "StoreType": selectedData.plantype,
        //     "page": 1,
        //     "startCount": 0,
        //     "MaxCount": 50,
        //     ...newParams,
        //     //   CompanyID: authContext?.CompanyID,
        //     // "page":1,
        //     // "StartCount":0,
        //     // "MaxCount":50 ,
        //     // ...newParams,
        // };
        // const params = {
        //     SummaryReportReq:
        //         // JSON.stringify({ "Request": { "Region": "2", "Country": "724", "CollectionType": "ECOLLECTION", "Cycle": null, "Year": "2023", "Week": "1", "FromDate": "", "ToDate": "", "WorkOrderID": "", "CompanyId": "169", "StoreNumber": "", "AuditorId": "", "StoreType": "ALL" } }),
        //         JSON.stringify({ "Request": { "Region": selectedData.Region, "Country": selectedData.Country, "CollectionType": "ECOLLECTION", "Cycle": null, "Year": selectedData.year.toString(), "Week": selectedData.week.toString(), "FromDate": "", "ToDate": "", "WorkOrderID": selectedData.JobId ? selectedData.JobId : "", "CompanyId": authContext.CompanyID, "StoreNumber": selectedData.smsId ? selectedData.smsId : "", "AuditorId": selectedData.auditorId ? selectedData.auditorId : "", "StoreType": selectedData.plantype } }),
        //     //   CompanyID: authContext?.CompanyID,
        //     page: 1,
        //     StartCount: 0,
        //     MaxCount: 50,
        //     ...newParams
        // };

        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });

        const res = await getEcollectionSummaryReport(JSON.stringify(params2), authContext);


        // const res = await getSummaryReport(JSON.stringify(params), authContext);
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.ReportResponse?.Status?.StatusMessage === "Success" && Array.isArray(res?.ReportResponse?.Response)) {
            res?.ReportResponse?.Response.map((cn) => {
                const realCountry = locationData?.Country.filter(({ CountryID }) => (CountryID === cn.Country))
                cn.Country = realCountry?.[0]?.CountryName
            });
            const result = {
                data: res?.ReportResponse?.Response,
                total: res?.ReportResponse?.Count,
            };
            setData(result);
        } else {
            const result = {
                data: [],
                total: 0,
            };
            setData(result);
            showToast(
                "error",
                t("dataNotFound", `${res?.ReportResponse?.Status?.StatusMessage ? res?.ReportResponse?.Status?.StatusMessage : "No Data Found"}`),
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        }
        setShowLoader(null);

    }

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState || initialColumnsState);
    };

    const customCellRender = (td, props) => {
        const { dataItem, field } = props;
        const numericColArray = [
            "Year",
            "Week",
            "WorkOrderID",
            "AuditId",
            "AuditorId",
            "StoreNumber",
            "RETAILER_NUMBER",
            "TIME_BEFORE_COLLECT_",
            "TOTALSTORETIMEMINUTES",
            "IMAGE_ONLY_TIME",
            "SCAN_ONLY_TIME",
            "SCAN_PLUS_IMAGE_TIME",
            "NON_COLLECT_TIME",
            "TOTAL_ACCEPTED_IMAGES",
            "TOTAL_DECLINED_IMAGES",
            "TOTAL_DISPLAYS",
            "TOTAL_IMAGES",
            "UNIQUE_SCANNEDBARCODES",
            "UNIQUE_IMAGEDBARCODES",
            "TOTAL_IMAGEDBARCODES",
            "TOTAL_SCANNEDBARCODES",
            "TOTAL_WORKINGTIME",
            "TOTAL_BARCODESCOLLECTED",
            "TOTAL_UNIQUEBARCODES",
            "PREV_WEEK_SCANNED_BARCODES",
            "NEW_ITEM_COUNT",
            "NEW_ITEMS_SCANNED_OVER_MAXLIMIT",
            "NEW_ITEMS_TIME_SPENT",
        ];
        let style = {};
        if (numericColArray.includes(field)) {
            style = {
                textAlign: 'right'
            }
        } else {
            style = {
                textAlign: 'left'
            }
        }
        const dataValue = dataItem[field];
        return <td style={style}>{dataValue?.toString()}</td>
    };


    const handleSaveViewClick = async () => {
        const params = {
            UserPrefReq: {
                Email: authContext.SpocMail,
                Company_ID: authContext.CompanyID,
                Grid_Name: GRID_NAME,
                Country_ID: selectedData.Country,
                Json_Object: JSON.stringify(stateColumns),
            },
        };
        setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
        });
        const res = await updateUserPreferenceAction(
            JSON.stringify(params),
            authContext
        );
        if (checkInvalidSession(res)) {
            handleAuthContext();
            return;
        }

        if (res?.Response?.Status === "Success") {
            dispatch(
                searchUserPrefrence(
                    JSON.stringify({
                        UserPrefReq: {
                            Email: authContext?.SpocMail,
                            Company_ID: authContext?.CompanyID,
                        },
                    })
                )
            );
            showToast(
                "success",
                t(res?.Response?.Message || "Success")
            );
        } else {
            showToast(
                "error",
                t(res?.Response?.Message || "dataNotFound")
            );
        }
        setShowLoader(null);
    };

    const handleDefaultViewClick = () => {
        onColumnsSubmit();
    };

    const handleRefreshGrid = () => {
        console.log("refresh grid");
        setShouldRefresh(true);
    };

    const backToReports = () => {
        history.goBack();
    }

    useEffect(() => {
        if (data) {
            setResult({
                data: data?.data.map((d) => {
                    if (!isNaN(d?.Region) && d?.Region) {
                        d.Region = parseInt(d.Region);
                    }
                    if (!isNaN(d?.Country) && d?.Country) {
                        d.Country = parseInt(d.Country);
                    }
                    if (!isNaN(d?.Year) && d?.Year) {
                        d.Year = parseInt(d.Year);
                    }
                    if (!isNaN(d?.Week) && d?.Week) {
                        d.Week = parseInt(d.Week);
                    }
                    if (!isNaN(d?.WorkOrderID) && d?.WorkOrderID) {
                        d.WorkOrderID = parseInt(d.WorkOrderID);
                    }
                    if (!isNaN(d?.AuditId) && d?.AuditId) {
                        d.AuditId = parseInt(d.AuditId);
                    }
                    if (!isNaN(d?.AuditorId) && d?.AuditorId) {
                        d.AuditorId = parseInt(d.AuditorId);
                    }
                    if (!isNaN(d?.PROVINCE) && d?.PROVINCE) {
                        d.PROVINCE = parseInt(d.PROVINCE);
                    }
                    if (!isNaN(d?.TOTALSTORETIMEMINUTES) && d?.TOTALSTORETIMEMINUTES) {
                        d.TOTALSTORETIMEMINUTES = parseInt(d.TOTALSTORETIMEMINUTES);
                    }
                    if (!isNaN(d?.IMAGE_ONLY_TIME) && d?.IMAGE_ONLY_TIME) {
                        d.IMAGE_ONLY_TIME = parseInt(d.IMAGE_ONLY_TIME);
                    }
                    if (!isNaN(d?.SCAN_ONLY_TIME) && d?.SCAN_ONLY_TIME) {
                        d.SCAN_ONLY_TIME = parseInt(d.SCAN_ONLY_TIME);
                    }
                    if (!isNaN(d?.SCAN_PLUS_IMAGE_TIME) && d?.SCAN_PLUS_IMAGE_TIME) {
                        d.SCAN_PLUS_IMAGE_TIME = parseInt(d.SCAN_PLUS_IMAGE_TIME);
                    }
                    if (!isNaN(d?.NON_COLLECT_TIME) && d?.NON_COLLECT_TIME) {
                        d.NON_COLLECT_TIME = parseInt(d.NON_COLLECT_TIME);
                    }
                    if (!isNaN(d?.TOTAL_ACCEPTED_IMAGES) && d?.TOTAL_ACCEPTED_IMAGES) {
                        d.TOTAL_ACCEPTED_IMAGES = parseInt(d.TOTAL_ACCEPTED_IMAGES);
                    }
                    if (!isNaN(d?.TOTAL_DECLINED_IMAGES) && d?.TOTAL_DECLINED_IMAGES) {
                        d.TOTAL_DECLINED_IMAGES = parseInt(d.TOTAL_DECLINED_IMAGES);
                    }
                    if (!isNaN(d?.TOTAL_DISPLAYS) && d?.TOTAL_DISPLAYS) {
                        d.TOTAL_DISPLAYS = parseInt(d.TOTAL_DISPLAYS);
                    }
                    if (!isNaN(d?.TOTAL_IMAGES) && d?.TOTAL_IMAGES) {
                        d.TOTAL_IMAGES = parseInt(d.TOTAL_IMAGES);
                    }
                    if (!isNaN(d?.UNIQUE_SCANNEDBARCODES) && d?.UNIQUE_SCANNEDBARCODES) {
                        d.UNIQUE_SCANNEDBARCODES = parseInt(d.UNIQUE_SCANNEDBARCODES);
                    }
                    if (!isNaN(d?.UNIQUE_IMAGEDBARCODES) && d?.UNIQUE_IMAGEDBARCODES) {
                        d.UNIQUE_IMAGEDBARCODES = parseInt(d.UNIQUE_IMAGEDBARCODES);
                    }
                    if (!isNaN(d?.TOTAL_IMAGEDBARCODES) && d?.TOTAL_IMAGEDBARCODES) {
                        d.TOTAL_IMAGEDBARCODES = parseInt(d.TOTAL_IMAGEDBARCODES);
                    }
                    if (!isNaN(d?.TOTAL_SCANNEDBARCODES) && d?.TOTAL_SCANNEDBARCODES) {
                        d.TOTAL_SCANNEDBARCODES = parseInt(d.TOTAL_SCANNEDBARCODES);
                    }
                    if (!isNaN(d?.TOTAL_WORKINGTIME) && d?.TOTAL_WORKINGTIME) {
                        d.TOTAL_WORKINGTIME = parseInt(d.TOTAL_WORKINGTIME);
                    }
                    if (!isNaN(d?.TOTAL_BARCODESCOLLECTED) && d?.TOTAL_BARCODESCOLLECTED) {
                        d.TOTAL_BARCODESCOLLECTED = parseInt(d.TOTAL_BARCODESCOLLECTED);
                    }
                    if (!isNaN(d?.TOTAL_UNIQUEBARCODES) && d?.TOTAL_UNIQUEBARCODES) {
                        d.TOTAL_UNIQUEBARCODES = parseInt(d.TOTAL_UNIQUEBARCODES);
                    }
                    if (!isNaN(d?.PREV_WEEK_SCANNED_BARCODES) && d?.PREV_WEEK_SCANNED_BARCODES) {
                        d.PREV_WEEK_SCANNED_BARCODES = parseInt(d.PREV_WEEK_SCANNED_BARCODES);
                    }
                    if (!isNaN(d?.NEW_ITEM_COUNT) && d?.NEW_ITEM_COUNT) {
                        d.NEW_ITEM_COUNT = parseInt(d.NEW_ITEM_COUNT);
                    }
                    if (!isNaN(d?.NEW_ITEMS_SCANNED_OVER_MAXLIMIT) && d?.NEW_ITEMS_SCANNED_OVER_MAXLIMIT) {
                        d.NEW_ITEMS_SCANNED_OVER_MAXLIMIT = parseInt(d.NEW_ITEMS_SCANNED_OVER_MAXLIMIT);
                    }
                    if (!isNaN(d?.NEW_ITEMS_TIME_SPENT) && d?.NEW_ITEMS_TIME_SPENT) {
                        d.NEW_ITEMS_TIME_SPENT = parseInt(d.NEW_ITEMS_TIME_SPENT);
                    }

                    return d
                }), ...data
            })
        }
    }, [data]);

    const initialValues = {
        regionID: locationData?.Region?.RegionID,
        countryID: locationData?.Country?.[0]?.CountryID,
        year: dateStamp?.selectedYear,
        week: dateStamp?.selectedWeek,
    }

    const CreatePager = useCallback((props) => (
        <MyPager
            {...props}
            onSaveViewClick={handleSaveViewClick}
            onDefaultViewClick={handleDefaultViewClick}
        >
            <Pager style={{ flex: 1, }} {...props} />
        </MyPager>
    ), [stateColumns]);

    return (
        <>
            {showLoader && <LoadMask {...showLoader} />}
            <div className="eq">
                <Header activeMenu={t("nielsennavigator-summaryreport")}>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginInline: "auto 1em",
                            padding: '6px'
                        }}
                    >
                        <Button
                            className='k-secondary'
                            onClick={backToReports}
                        >
                            Back
                        </Button>
                    </div>
                </Header>
                {showForm ?
                    <div className="eqform">
                        <Form
                            initialValues={initialValues}
                            onSubmitClick={onSearchClick}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "0px 25px",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <Field
                                            name={"Region"}
                                            component={FormComboBox}
                                            label={t("REGION")}
                                            required={true}
                                            onChange={onChange}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="RegionName"
                                            valueField="RegionID"
                                            data={[locationData?.Region]}
                                            defaultValue={locationData?.Region?.RegionID}
                                        />
                                        <Field
                                            name={"Country"}
                                            component={FormComboBox}
                                            label={t("COUNTRY")}
                                            required={true}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            textField="CountryName"
                                            valueField="CountryID"
                                            data={locationData?.Country || []}
                                            defaultValue={locationData?.Country?.[0]?.CountryID}
                                            onChange={onChangeCountry}
                                        />

                                        <Field
                                            name="year"
                                            label={t("YEAR")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("YEAR")}
                                            data={year}
                                            required={true}
                                            defaultValue={dateStamp?.selectedYear?.DisplayValue}
                                            validator={inputValidator}
                                            onChange={onChangeYear}

                                        />

                                        <Field
                                            name="week"
                                            label={t("WeekNumber")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("WeekNumber")}
                                            required={true}
                                            data={weekNumber}
                                            defaultValue={dateStamp?.selectedWeek?.DisplayValue}
                                            validator={inputValidator}
                                            onChange={onChangeWeekNumber}
                                        />
                                        <Field
                                            name="JobId"
                                            label={t("WORKORDERID")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("WORKORDERID")}
                                            data={formWorkOrderId}
                                            onChange={onChange}
                                        />
                                        <Field
                                            name="auditorId"
                                            label={t("AUDITOR ID")}
                                            component={FormInput}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("AUDITOR ID")}
                                            onChange={onChange}
                                        />
                                        <Field
                                            name="smsId"
                                            label={t("SMS ID")}
                                            component={FormInput}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("SMS ID")}
                                            onChange={onChange}
                                        />
                                        <Field
                                            name="plantype"
                                            label={t("PLAN_TYPE_LBL")}
                                            component={FormComboBox}
                                            wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                                            placeholder={t("PLAN_TYPE_LBL")}
                                            data={["ALL", "TEST", "LIVE"]}
                                            defaultValue={"LIVE"}
                                            onChange={onChange}
                                        />

                                        <div className={'k-actions k-hstack k-justify-content-stretch'} style={{ marginTop: "29px", gap: "0px 8px", marginLeft: "-5px" }}>

                                            <Button
                                                className={'k-button k-primary k-button-md k-rounded-md k-button-solid k-button-solid-primary'}
                                            >
                                                {t("SEARCH")}
                                            </Button>
                                            <Button
                                                className={'k-button k-secondary k-button-md k-rounded-md k-button-solid k-button-solid-base'}
                                                type={'reset'}
                                                onClick={() => {
                                                    setSelectedData(initialFormValue);
                                                    setTimeout(() => {
                                                        formRenderProps.onFormReset();
                                                    }, 0);
                                                }}
                                            >
                                                {t("RESET")}
                                            </Button>
                                        </div>
                                    </div>
                                </FormElement>
                            )}
                        />
                    </div>
                    :
                    <div></div>
                }

                <div
                    className="nielsen-card"
                    style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
                >
                    <div className="eq_data_heading" style={{ margin: "0px -6px" }}>
                        <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
                        <div>
                            <Button
                                onClick={onExport}
                                style={{ margin: "10px", border: "none" }}
                                primary
                            >{t("export")}</Button>

                            <Button icon={expandIcon}
                                onClick={expand}
                                className={'k-secondary'}
                                style={{ margin: "10px", marginRight: '20px' }}
                            ></Button>
                        </div>
                    </div>
                    <div className="catalog" style={{
                        display: "flex",
                        padding: "1rem",
                        flexGrow: 1,
                        overflow: "auto",
                    }}
                    >
                        <Grid
                            className="workOrders_grid"
                            data={result}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                console.log("sort", { e });
                                setResult((result) => {
                                    const { data, total } = result;
                                    return {
                                        data: orderBy(data || [], e.sort),
                                        total,
                                    };
                                });
                                setSort(e.sort);
                            }}
                            filter={filter}
                            onFilterChange={(e) => {
                                console.log("filter", e.filter, data);
                                setResult((result) => {
                                    const { total } = result;
                                    return {
                                        data: filterBy(data?.data || [], e.filter),
                                        total,
                                    };
                                });
                                setFilter(e.filter)
                            }}
                            onPageChange={handlePageChange}
                            cellRender={customCellRender}
                            pageable={{
                                pageSizes: false,
                            }}
                            pager={CreatePager}
                            resizable
                            reorderable
                            onColumnReorder={({ columns }) => {
                                const columnOrder = columns.reduce((columnsObj, column) => {
                                    columnsObj[column.field] = column.orderIndex;
                                    return columnsObj;
                                }, {})
                                const newColumns = stateColumns.map((column) => {
                                    return { ...column, orderIndex: columnOrder[column.field] }
                                });
                                setStateColumns(newColumns);
                            }}
                        >
                            {stateColumns.map(
                                (column, idx) => {
                                    const menu = columnMenuConfig[column?.columnMenu];
                                    return column.show &&
                                        (
                                            <GridColumn
                                                key={column?.orderIndex}
                                                field={column?.field}
                                                title={t(column.title)}
                                                width={column?.width}
                                                minResizableWidth={30}
                                                // cell={GridCell}
                                                format={column?.format}
                                                filter={column?.filter}
                                                orderIndex={column?.orderIndex}
                                                {...(menu && {
                                                    columnMenu: (props) => {
                                                        return menu?.({
                                                            ...props,
                                                            columns: stateColumns,
                                                            onColumnsSubmit,
                                                            data: data?.data,
                                                        });
                                                    },
                                                })}
                                                headerClassName={
                                                    isColumnActive(column.field, { ...dataState, filter })
                                                        ? "activeFiltered"
                                                        : ""
                                                }
                                            />
                                        )
                                })}
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryReport;
