import * as types from "../types";
import { Get, Post } from "../../methods";
import AppURL from "../../../utils/AppURL";
import { checkInvalidSession } from "../../../utils/useLogout";
import { showToast } from "../../../utils/Notification";
import { toast } from "react-toastify";

const getHeaders = (authContext) => ({
    "Content-Type": "application/json",
    OrganizationUnit: authContext?.organizationUnit || "",
    userid: authContext?.SpocMail || "",
    CompanyID: authContext?.CompanyID,
    // companyid:authContext?.CompanyID || ""
    // companyid: "381"
});
const getHeadersPost = (authContext) => ({
    "Content-Type": "application/json",
    OrganizationUnit: authContext?.organizationUnit || "",
    userid: authContext?.SpocMail || "",
    CompanyID: authContext?.CompanyID,
    // companyid:authContext?.CompanyID || ""
    // companyid: "381"
});
export const fetchuserprofile = (params, authContext) => (dispatch) => {
    Get(
        AppURL.fetchuserprofileURL,
        params,
        getHeaders(authContext),
        true
    ).then((res) => res.json()
    ).then(res => {
        console.log(`fetchuserprofile`, res);
        if (checkInvalidSession(res)) {
            dispatch({
                type: types.SET_AUTH_CONTEXT,
                payload: null,
            });
            localStorage.removeItem("spocDetails");
            showToast("error", "Session expired", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        if (res) {
            dispatch({
                type: types.USER_PROFILE_RESPONSE,
                payload: res?.root
            });
            // dispatch({
            //     type: types.DOWNTIME_RESPONSE,
            //     payload: res?.root
            // });
        }
    });
};

//fetch local getpermission Roles for making the screens enable or disable

export const fetchuserprofileGetpermissionRoles = (params, authContext) => (dispatch) => {
    Get(
        AppURL.fetchuserprofileRolesURL,
        params,
        getHeaders(authContext),
        true
    ).then((res) => res.json()
    ).then(res => {
        if (res?.hasOwnProperty("roleId")) {
            dispatch({
                type: types.SET_USER_ROLE_ACCESS,
                payload: res,
            });
            sessionStorage.setItem("userrole",`${res?.roleId}`);
        }else{
            showToast("warning","Role API failed while fetching")
        }
    }
    
    );
};

export const setScreenIdHandler = (params) => (dispatch) => {
    dispatch({
                type: types.SET_CURRENT_SCREEN_ID,
                payload: params,
    });
          
};


export const changePassword = (params, authContext) => (dispatch) => {
    Post(
        AppURL.changepasswordURL,
        // JSON.stringify(params),
        params,
        getHeadersPost(authContext),
        true
    ).then((res) => res.json()
    ).then(res => {
        console.log(`changePassword`, res);
        if (checkInvalidSession(res)) {
            dispatch({
                type: types.SET_AUTH_CONTEXT,
                payload: null,
            });
            localStorage.removeItem("spocDetails");
            showToast("error", "Session expired", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        if (res) {
            dispatch({
                type: types.CHANGE_PASSWORD_RESPONSE,
                payload: res?.Response
            });
        }
    });
};
export const updateUserProfile = (params, authContext) => (dispatch) => {

    Post(
        `${(AppURL.updateuserprofile)}?SpocMailId=${authContext?.SpocMail || ""}&CompanyId=${authContext?.CompanyID || ""}`,
        //`${appurl.updateUrl}?SpocMailId=${authContext?.spocmail}&CompanyId=${authContext?.CompanyId`
        //https://uat3.navigator.nielseniq.io/fmsaud/updateuserprofile?SpocMailId=smaddi1234@mailinator.com&CompanyId=377
        JSON.stringify(params),
        // params,
        getHeadersPost(authContext),
        true
    ).then((res) => res.json()
    ).then(res => {
        console.log(`updateUserProfile`, res);
        if (checkInvalidSession(res)) {
            dispatch({
                type: types.SET_AUTH_CONTEXT,
                payload: null,
            });
            localStorage.removeItem("spocDetails");
            showToast("error", "Session expired", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        if (res) {
            dispatch({
                type: types.UPDATE_USER_PROFILE_RESPONSE,
                payload: res?.root
            });
        }
    });
};
