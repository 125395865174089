import React, { useMemo, useState, useRef, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import AddResourceIcon from "../../../../assets/img/Add_Resource.png";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
} from "../../../ui/form/form-component";
import { useSelector } from "react-redux";
import regionCountry from "../../../../utils/regionCountry";
import { Link } from "react-router-dom";
import {
  createAdmin,
  createAuditor,
  getStateData,
} from "../../../../store/actions/people/resources";
import LoadMask from "../../../../utils/LoadMask";
import AddDevice from "./AddDevice";
import { MessageBox, showToast } from "../../../../utils/Notification";
import { emailValidator, phoneRegex } from "../../../ui/form/validator";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";
// import { selectCollectionTypes } from "../../../../../store/reducer/people";
import { selectCollectionTypes } from "../../../../store/reducer/people";
import { FormMultiSelect, FormNumericTextBox } from "../../../form/form-component";
import { optionalEmailValidator } from "../../../form/validator";
import { useTranslation } from "react-i18next";
import moment from "moment";


const AddResource = () => {
  const { t, i18n } = useTranslation();
  const collectionTypes = useSelector(selectCollectionTypes);
  const { handleAuthContext } = useLogout();
  const getPermissions = useSelector(
    (state) => state.commonReducer.permissions
  );
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const companyDetails = useSelector(
    (state) => state.commonReducer.companyDetails
  );

  const [showLoader, setShowLoader] = useState(null);

  const locationData = useSelector((state) => state.commonReducer.locationData);

  const RegionData = useMemo(() => {
    const Region = getPermissions;
    return Region || regionCountry;
  }, [getPermissions]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const resourceFormRef = useRef(null);
  const adminFormRef = useRef(null);

  const [deviceList, setDeviceList] = useState([]);
  const [messageProps, setMessageProps] = useState(null);
  const [isReset, setIsReset] = useState(false);

  const onRegionChange = (e) => {
    const { value } = e;
    const region = RegionData.find((rd) => rd.RegionID === value);
    setCountryData(region?.CountryDetails || []);
  };
  const [isResource, setIsResource] = useState(true);
  const onRoleChange = () => {
    setIsResource((b) => !b);
  };
  const messagePopup = (msg, button, icon, form) => {
    const callBackFn = (msg) => {
      setMessageProps(null);
      switch (msg) {
        case "YES":
          if (form) {
            handleCreateAdmin(form);
          }
          break;
        default:
          break;
      }
    };
    setMessageProps({
      title: "Warning",
      buttons: button,
      icon: icon,
      message: msg,
      fn: callBackFn,
    });
  };
  const handleCreateAdmin = async (form) => {
    const { Country, MailId, ContactNo, FirstName, LastName } = form.values;
    const params = {
      SpocDetails: {
        FirstName: FirstName || "",
        LastName: LastName || "",
        Country,
        MailId,
        ContactNo,
        CreatedBy: authContext?.SpocMail,
        CompanyId: authContext?.CompanyID,
        Role: "Administrator",
      },
    };
    setShowLoader({
      className: ".addResourceContainer",
      msg: "Loading",
    });
    const res = await createAdmin(JSON.stringify(params), authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.root?.Status === "Success") {
      showToast("success", res?.root?.Message);
    } else {
      showToast("error", res?.root?.Message);
    }
  };
  const onCreateAdmin = async (form) => {
    const { Country, MailId, ContactNo } = form.values;
    if (ContactNo && ContactNo.length !== 10 && phoneRegex.test(ContactNo)) {
      showToast("error", "Please Enter Valid Contact Number");
      return;
    }
    if (!form.isValid || !Country || !MailId) {
      showToast("error", "Please Enter Mandatory Values");
      return;
    }
    messagePopup(
      "Are You Sure, You Want To Create An Admin?",
      "YESNO",
      "WARNING",
      form
    );
  };

  const onCreateResource = async (form) => {
    const { values } = form;
    const allSkills = values.SKILLS;
    let desired_output = (allSkills) => {
      let unique_values = allSkills
        ?.map((item) => item.ProductName)
        .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
        );
      return unique_values?.toString() || "";
    };
    const desiredSkillsParams = desired_output(allSkills);
    console.log("skilssss", desiredSkillsParams)

    // const COUNTRY = values?.COUNTRY || authContext?.CountryCode?.split(",")[0] || ""
    const { COUNTRY, RESOURCE_ID } = values;
    if (!COUNTRY || !RESOURCE_ID) {
      // if(values.)
      showToast("error", t("pleaseentermandatoryvalues"));
      return;
    }
    if (values.EMAIL !== "" && optionalEmailValidator(values.EMAIL) !== "") {
      showToast("error", "Please Enter Valid Email.");
      return;
    }

    let DATE_OF_BIRTH
    if (form.values?.DATE_OF_BIRTH) {
      DATE_OF_BIRTH = moment(form.values?.DATE_OF_BIRTH).format('MM/DD/YYYY') || "";
    }

    const params = {
      AuditorAddReq: {
        COUNTRY,
        SPOC_MAILID: authContext?.SpocMail,
        RESOURCE_ID,
        RESOURCE_STATUS: values?.RESOURCE_STATUS || "",
        FIRST_NAME: values?.FIRST_NAME || "",
        LAST_NAME: values?.LAST_NAME || "",
        MIDDLE_NAME: values?.MIDDLE_NAME || "",
        DATE_OF_BIRTH: DATE_OF_BIRTH,
        PERSONAL_IDENTIFICATION_TYPE: values?.PERSONAL_IDENTIFICATION || "",
        GENDER: values?.GENDER || "",
        STATE: values?.STATE || "",
        CITY: values?.CITY || "",
        CONTACT_NUMBER: values?.CONTACT_NUMBER || "",
        ADDRESS: values?.ADDRESS || "",
        ZIP_CODE: values?.ZIP_CODE || "",
        EMAIL: values?.EMAIL || "",
        LANGUAGE: values?.LANGUAGE || "",
        // SKILLS: values?.SKILLS || "",
        SKILLS: desiredSkillsParams || "",
        PRODUCT: "",
        DEVICES: deviceList,
        CUSTOM1: values.CUSTOM1 || "",
        CUSTOM2: values.CUSTOM2 || "",
        CUSTOM3: values.CUSTOM3 || "",
        CUSTOM4: values.CUSTOM4 || "",
        CUSTOM5: values.CUSTOM5 || "",
      },
    };
    setShowLoader({
      className: ".addResourceContainer",
      msg: "Loading",
    });
    const res = await createAuditor(JSON.stringify(params), authContext);
    setShowLoader(null);
    console.log(res);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.Response?.success === "true") {
      showToast(
        "success",
        <>
          <ul>
            {res?.Response?.msg.map((d, i) => (
              <li key={i}>{d?.Message}</li>
            ))}
          </ul>
        </>
      );
    } else {
      showToast(
        "error",
        <>
          <ul>
            {res?.Response?.msg?.map((d, i) => (
              <li key={i}>{d?.Message}</li>
            ))}
          </ul>
        </>
      );
    }
  };
  useEffect(() => {
    const Country = locationData?.Country;
    if (Country) {
      setCountryData(Country);
    }
  }, [locationData]);

  useEffect(() => {
    const fetchStateData = async () => {
      const params = {
        _dc: Date.now(),
        CountryID: authContext?.CountryCode?.split(",")?.[0],
        page: 1,
        start: 0,
        limit: 25,
      };
      const res = await getStateData(params, authContext);
      if (res?.SearchStateOp?.StasteDetails?.length) {
        setStateData(res?.SearchStateOp?.StasteDetails);
      }
    };
    fetchStateData();
  }, []);
  const handleFormReset = () => {
    if (isResource) {
      resourceFormRef?.current?.onReset?.();
    } else {
      adminFormRef?.current?.onReset?.();
    }
    setIsReset(true)
  };


  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}

      <header style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: '#fff',
        height: '44px',
        zIndex: '1'
      }}>
        <div style={{ padding: ".5em 1em" }}>
          <img src={AddResourceIcon} alt="resourceImg" width={25} />
        </div>
        <div>
          <h2 style={{ margin: 0, padding: 0, fontSize: '26px', color: '#060A45' }}>Add New Resource</h2>
        </div>
        <div
          style={{
            marginLeft: "auto",
            paddingInline: "1em",
            display: "flex",
            gap: ".5em",
            height: '100%',
            alignItems: 'center'
          }}
        >
          <Button
            primary
            onClick={() => {
              if (isResource) {
                resourceFormRef.current.onSubmit();
              } else {
                console.log("api call", adminFormRef);
                adminFormRef.current.onSubmit();
              }
            }}
          >
            Create
          </Button>
          <Button
            className="k-button k-ghost"
            type="reset"
            // onClick={formRenderProps.onFormReset}
            onClick={handleFormReset}
          >
            Reset
          </Button>
          <Link to={"/people"}>
            <Button type="button" className="k-button k-ghost">
              Cancel
            </Button>
          </Link>
        </div>
      </header>

      <section
        className="addResourceContainer"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div
          style={{
            display: "flex",
            gap: "1em",
            padding: "1em 2em .5em",
            borderBottom: "1px solid #ccc",
          }}
        >
          <div>Role</div>
          <div style={{ display: "flex", alignItems: "center", gap: ".5em" }}>
            <div>
              <input
                name="role"
                id="resourceType"
                type="radio"
                onChange={onRoleChange}
                checked={isResource}
              />
              <label htmlFor="resourceType">Resource</label>
            </div>
            <div>
              <input
                name="role"
                id="adminType"
                type="radio"
                onChange={onRoleChange}
                checked={!isResource}
              />
              <label htmlFor="adminType">Admin</label>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 90px)",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              height: "calc(100% - 1px)",
              display: isResource ? "flex" : "none",
            }}
          >
            <Form
              initialValues={{
                Region: locationData?.Region?.RegionID,
                COUNTRY: locationData?.Country?.[0]?.CountryID,
              }}
              ref={resourceFormRef}
              onSubmitClick={onCreateResource}
              style={{}}
              render={(formRenderProps) => (
                <FormElement
                  style={{
                    // height: "50vh",
                    flex: 2,
                    overflowY: "auto",
                    paddingRight: "1em",
                    paddingLeft: "2em",
                    paddingBottom: "1.5em",
                  }}
                >
                  <div
                    style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}
                  >
                    <Field
                      name={"Region"}
                      component={FormComboBox}
                      label={"Region"}
                      // onChange={onCountryChange}
                      textField="RegionName"
                      // dataItemKey="RegionID"
                      valueField="RegionID"
                      data={RegionData}
                      // data={countriesData || []}
                      onChange={onRegionChange}
                      wrapperStyle={{ width: "24%" }}
                      required={true}
                      disabled
                    />
                    <Field
                      name={"COUNTRY"}
                      component={FormComboBox}
                      label={"Country"}
                      // onChange={onCountryChange}
                      textField="CountryName"
                      valueField="CountryID"
                      data={countryData}
                      // data={countriesData || []}
                      wrapperStyle={{ width: "24%" }}
                      required={true}
                    />
                    <Field
                      name={"RESOURCE_ID"}
                      component={FormNumericTextBox}
                      label={"Primary Resource ID"}
                      placeholder={"Primary Resource ID"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      min={0}
                      format='#'
                      spinners={false}
                      required={true}
                    />
                    {/* <Field
                      name={"RESOURCE_ID"}
                      component={FormInput}
                      label={"Primary Resource ID"}
                      placeholder={"Primary Resource ID"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      required={true}
                    /> */}
                    <Field
                      name={"FIRST_NAME"}
                      component={FormInput}
                      label={"First Name"}
                      placeholder={"First Name"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      onKeyPress={(event) => {
                        if (!/[a-zA-Z]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <Field
                      name={"LAST_NAME"}
                      component={FormInput}
                      label={"Last Name"}
                      placeholder={"Last Name"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      onKeyPress={(event) => {
                        if (!/[a-zA-Z]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <Field
                      name={"MIDDLE_NAME"}
                      component={FormInput}
                      label={"Middle Name"}
                      placeholder={"Middle Name"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      onKeyPress={(event) => {
                        if (!/[a-zA-Z]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <Field
                      name={"PERSONAL_IDENTIFICATION_TYPE"}
                      component={FormInput}
                      label={"Personal Identification"}
                      placeholder={"Personal Identification"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={
                        companyDetails?.PrivacyInfo?.PersonalId === false
                      }
                    />
                    <Field
                      name={"GENDER"}
                      component={FormComboBox}
                      label={"Gender"}
                      placeholder={"Gender"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      data={["Male", "Female", "Not Specified"]}
                      disabled={companyDetails?.PrivacyInfo?.Gender === false}
                    />
                    <Field
                      name={"DATE_OF_BIRTH"}
                      component={FormDatePicker}
                      label={"Date Of Birth"}
                      placeholder={"Date Of Birth"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.DOB === false}
                    />
                    <Field
                      name={"STATE"}
                      component={FormComboBox}
                      label={"State/province"}
                      placeholder={"State/province"}
                      // onChange={onCountryChange}
                      textField={"StateName"}
                      valueField={"StateName"}
                      data={stateData}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.State === false}
                    />
                    <Field
                      name={"CITY"}
                      component={FormInput}
                      label={"City"}
                      placeholder={"City"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.City === false}
                      onKeyPress={(event) => {
                        if (!/[a-zA-Z]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <Field
                      name={"CONTACT_NUMBER"}
                      component={FormInput}
                      label={"Contact Number"}
                      placeholder={"Contact Number"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      onKeyPress={(event) => {
                        if (
                          !/[0-9]/.test(event.key) ||
                          event.target.value.toString().length >= 10
                        ) {
                          event.preventDefault();
                        }
                      }}
                      disabled={companyDetails?.PrivacyInfo?.Phno === false}
                    />
                    <Field
                      name={"ADDRESS"}
                      component={FormInput}
                      label={"Address"}
                      placeholder={"Address"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.Address === false}
                    />
                    <Field
                      name={"ZIP_CODE"}
                      component={FormInput}
                      label={"Zip/postal Code"}
                      placeholder={"Zip/postal Code"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={
                        companyDetails?.PrivacyInfo?.PostalCode === false
                      }
                    />
                    <Field
                      name={"EMAIL"}
                      type={"email"}
                      // validator={optionalEmailValidator}
                      validator={companyDetails?.PrivacyInfo?.Email && optionalEmailValidator}
                      component={FormInput}
                      label={t('Email')}
                      placeholder={t('Email')}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.Email === false}
                    />
                    <Field
                      name={"LANGUAGE"}
                      component={FormInput}
                      label={"Language"}
                      placeholder={"Language"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.Language === false}
                    />
                    {/* <Field
                      name={"SKILLS"}
                      component={FormInput}
                      label={"Skills"}
                      // textField="ProductName"
                      // valueField="ProductName"
                      placeholder={"Skills"}                      
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      // data={collectionTypes}
                      disabled={companyDetails?.PrivacyInfo?.Skills === false}
                    /> */}
                    <Field
                      name={"SKILLS"}
                      component={FormMultiSelect}
                      label={"Skills"}
                      textField="ProductName"
                      valueField="ProductName"
                      placeholder={"Skills"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      data={collectionTypes}
                      disabled={companyDetails?.PrivacyInfo?.Skills === false}
                    />
                    <Field
                      name={"CUSTOM1"}
                      component={FormInput}
                      label={"Custom1"}
                      placeholder={"Custom1"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.Custom1 === false}
                    />
                    <Field
                      name={"CUSTOM2"}
                      component={FormInput}
                      label={"Custom2"}
                      placeholder={"Custom2"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.Custom2 === false}
                    />
                    <Field
                      name={"CUSTOM3"}
                      component={FormInput}
                      label={"Custom3"}
                      placeholder={"Custom3"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.Custom3 === false}
                    />
                    <Field
                      name={"CUSTOM4"}
                      component={FormInput}
                      label={"Custom4"}
                      placeholder={"Custom4"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.Custom4 === false}
                    />
                    <Field
                      name={"CUSTOM5"}
                      component={FormInput}
                      label={"Custom5"}
                      placeholder={"Custom5"}
                      // onChange={onCountryChange}
                      wrapperStyle={{ width: "24%" }}
                      disabled={companyDetails?.PrivacyInfo?.Custom5 === false}
                    />
                  </div>
                </FormElement>
              )}
            />
            <AddDevice deviceList={deviceList} setDeviceList={setDeviceList} isReset={isReset} />
          </div>
          <Form
            initialValues={{
              Region: locationData?.Region?.RegionID,
              Country: locationData?.Country?.[0]?.CountryID,
            }}
            ref={adminFormRef}
            onSubmitClick={onCreateAdmin}
            render={(formRenderProps) => (
              <FormElement
                style={{
                  // height: "50vh",
                  overflowY: "auto",
                  paddingRight: "1em",
                  paddingLeft: "2em",
                  paddingBottom: "1.5em",
                  display: isResource ? "none" : "",
                }}
              >
                <div style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
                  <Field
                    name={"Region"}
                    component={FormComboBox}
                    label={"Region"}
                    // onChange={onCountryChange}
                    textField="RegionName"
                    // dataItemKey="RegionID"
                    valueField="RegionID"
                    data={RegionData}
                    // data={countriesData || []}
                    required={true}
                    onChange={onRegionChange}
                    wrapperStyle={{ width: "24%" }}
                    disabled
                  />
                  <Field
                    name={"Country"}
                    component={FormComboBox}
                    label={"Country"}
                    // onChange={onCountryChange}
                    textField="CountryName"
                    valueField="CountryID"
                    data={countryData}
                    // data={countriesData || []}
                    required={true}
                    wrapperStyle={{ width: "24%" }}
                  />
                  <Field
                    name={"FirstName"}
                    component={FormInput}
                    label={"First Name"}
                    placeholder={"First Name"}
                    // onChange={onCountryChange}
                    wrapperStyle={{ width: "24%" }}
                    onKeyPress={(event) => {
                      if (!/[a-zA-Z]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <Field
                    name={"LastName"}
                    component={FormInput}
                    label={"Last Name"}
                    placeholder={"Last Name"}
                    // onChange={onCountryChange}
                    wrapperStyle={{ width: "24%" }}
                    onKeyPress={(event) => {
                      if (!/[a-zA-Z]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <Field
                    name={"ContactNo"}
                    component={FormInput}
                    label={"Contact Number"}
                    placeholder={"Contact Number"}
                    // onChange={onCountryChange}
                    wrapperStyle={{ width: "24%" }}
                    onKeyPress={(event) => {
                      if (
                        !/[0-9]/.test(event.key) ||
                        event.target.value.toString().length >= 10
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <Field
                    name={"MailId"}
                    component={FormInput}
                    label={t('Email')}
                    placeholder={t('Email')}
                    // onChange={onCountryChange}
                    wrapperStyle={{ width: "24%" }}
                    validator={emailValidator}
                    required
                  />
                </div>
              </FormElement>
            )}
          />
        </div>
      </section>
    </>
  );
};

export default AddResource;
