const emailRegex = new RegExp(/\S+@\S+\.\S+/);
export const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
// eslint-disable-next-line no-useless-escape
const nonNielsenEmailRegex = new RegExp(/^(\w+)([\-+.][\w]+)*@nielseniq.com$/);

export const inputValidator = (value) => !value?.toString() ?
    "Field is required" : "";

export const nameValidator = (value) => !value ?
    "Full Name is required" :
    value.length < 7 ? "Full Name should be at least 7 characters long." : "";

export const userNameValidator = (value) => !value ?
    "User Name is required" :
    value.length < 8 ? "User name should be at least 8 characters long." : "";

export const emailValidator = (value) => !value ?
    "Email field is required." :
    (emailRegex.test(value) ? "" : "Email is not valid format.");

export const nonNeilsenEmailValidator = (value) => !value ?
    "Email field is required." :
    (nonNielsenEmailRegex.test(value) ? "Must be valid non-nielseniq email" :
        (emailRegex.test(value) ? "" : "Email is not valid format."));

export const optionalEmailValidator = (value) => value && !emailRegex.test(value) ?
    "Email is not valid format." : "";

export const phoneValidator = (value) => !value ?
    "Phone number is required." :
    phoneRegex.test(value) ? "" : "Not a valid phone number.";