import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as types from "../store/actions/types";
import { showToast } from "./Notification";

const useLogout = () => {
  const dispatch = useDispatch();
  const [isLogout, setLogout] = useState(false);
  useEffect(() => {
    if (isLogout) {
      dispatch({
        type: types.SET_AUTH_CONTEXT,
        payload: null,
      });
      localStorage.removeItem("spocDetails");
      showToast("error", "Session expired", {
        position: toast.POSITION.TOP_RIGHT,
      });
      //   console.log("authContext flushed");
    }
  }, [isLogout]);

  const handleAuthContext = () => {
    setLogout(true);
  };
  return { handleAuthContext };
};
export const checkInvalidSession = (res) => {
  return res?.Response?.sessionstatus === "400";
};

export default useLogout;
