import React, { useState, useRef, useCallback } from "react";
import { Popup } from "@progress/kendo-react-popup";
import {Link} from "react-router-dom";
import Header from "../../header";
import Tabs from "../../ui/tabs";
import { Button } from "@progress/kendo-react-buttons";
import SearchFilterWindow from "./SearchFilterWindow";
import resourceIcon from "../../../assets/img/Component_233_1.png";
import resourceUser from "../../../assets/img/resource-user.png";
import "./index.scss";
import { getResourceDetails } from "../../../store/actions/people/resources";
import { useSelector } from "react-redux";
import LoadMask from "../../../utils/LoadMask";
import DownloadWindow from "./DownloadWindow";
import ResourceBody from "./ResourceBody/index.jsx";
import { sortList } from "./resourceUtils";
import { useTranslation } from "react-i18next";
import useLogout, { checkInvalidSession } from "../../../utils/useLogout";

const Resources = ({ tabsObj, activeScreen, setActiveScreen,screen,rolePepplePage }) => {

  console.log(screen,rolePepplePage,"here123")
  
  
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [showLoader, setShowLoader] = useState(null);
  const [newAuditorList, setNewAuditorList] = useState(null);
  const { t, i18n } = useTranslation();
  const {handleAuthContext} = useLogout();
  const [sort, setSort] = useState(Object.keys(sortList)[2]);

  const [selectedResourceDetails, setSelectedResourceDetails] = useState(null);

  const [searchTerm, setSearthTerm] = useState("");

  const filterBtnRef = useRef(null);

  const [downloadWindow, setDownloadWindow] = useState(false);

  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [show, setShow] = useState(false);
  const toggleShow = useCallback(() => {
    setShow((b) => !b);
  }, []);

  const getOneResourceId = (data) =>{
    if(data){
      handleFetchResourceDetails(data)
    }else{
      setSelectedResourceDetails(null)
    }
  }

  const handleFetchResourceDetails = async (data) => {
    console.log("fetchResorceDetails", data);
    const params = {
      COMPANYID: authContext?.CompanyID,
      RESOURCEID: data?.RESOURCE_ID,
    };
    setShowLoader({
      className: ".resourceDetails_container",
      msg: "loading",
    });
    const res = await getResourceDetails(params, authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.group?.status === "Success") {
      setSelectedResourceDetails(res?.group?.SearchResponse?.[0]);
    } else {
      console.log("Error: getResourceDetails", res);
    }
    console.log("fetch", { res });
  };
  console.log("setSelectedResourceDetails", selectedResourceDetails);

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {downloadWindow && (
        <DownloadWindow
          // selectedDataUpload={selectedDataUpload}
          setDownloadWindowShow={setDownloadWindow}
        />
      )}
      <Header activeMenu={t("resourcemanagement")}>
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
          <Tabs
            tabs={tabsObj}
            selected={activeScreen}
            onSelect={(key) => setActiveScreen(key)}
          >
            {({ title }) => {
              return <div>{title}</div>;
            }}
          </Tabs>
          <div
            style={{
              // marginInline: "auto 1em",
              display: "flex",
              alignItems: "center",
              marginTop: '2px'
            }}
          >
              <Button
                className="k-button k-ghost"
                icon="plus"
                style={{ minWidth: 80 }}
                disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
                // disabled={true}
                >                
                <Link to="/people/add" style={{color:"#2D6DF6"}}> 
                   {`${t("Add")} ${t('new')}`}
                </Link>
              </Button>
            <hr style={{
              borderLeft: '1px solid #CCCCCC',
              height: '18px'
            }} />
            <Button
              className="k-button k-ghost"
              icon="export"
              style={{ minWidth: 100 }}
              // disabled={rolePepplePage?.screensInfo?.screenId == screen ? ( rolePepplePage?.createFlag == 'N' ? true : false ) : true }
              onClick={() => setDownloadWindow(true)}
            >
          {t("export")}
            </Button>
          </div>
        </div>
      </Header>
      <SearchFilterWindow
        setResult={setNewAuditorList}
        setSearthTerm={setSearthTerm}
        shouldRefresh={shouldRefresh}
        setShouldRefresh={setShouldRefresh}
        searchTerm={searchTerm}
        getOneResourceId ={getOneResourceId}
      />

      <div
        style={{
          display: "flex",
          backgroundColor: "lightgray",
          paddingTop: 3,
          gap: 3,
          height: "100%",
          // overflow: "auto",
        }}
      >
        <div style={{ width: 430, backgroundColor: "#fff" }}>
          {/* resource list filter */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "1em",
              fontSize: '16px'
            }}
          >
            <div>{newAuditorList?.rowCount} Total Resource</div>
            <div>
              <button
                className={`k-button k-button-icon`}
                onClick={toggleShow}
                ref={filterBtnRef}
              >
                <span
                  className={`k-icon ${
                    show ? "k-i-arrow-chevron-up" : "k-i-saturation"
                  }`}
                />
              </button>
              <Popup anchor={filterBtnRef.current} show={show}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  {Object.keys(sortList)?.map((key, i) => {
                    const item = sortList?.[key];
                    return (
                      <Button
                        key={i}
                        style={{
                          justifyContent: "start",
                          ...(sort === key && {
                            backgroundColor: "#BDD1FC",
                          }),
                        }}
                        onClick={() => {
                          setSort(key);
                        }}
                      >
                        {item?.title}
                      </Button>
                    );
                  })}
                </div>
              </Popup>
              {show && (
                <div
                  onClick={toggleShow}
                  style={{
                    backgroundColor: "rgba(0,0,0,0.05)",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    zIndex: 999,
                  }}
                />
              )}
            </div>
          </div>
          {/* resource list */}
          <div
            className="newAuditorContainer"
            style={{ overflow: "hidden overlay", height: "70vh" }}
          >
            {newAuditorList?.SearchResponse?.sort(
              sortList?.[sort]?.sortFunc
            )?.map((data, i) => {
              const { FIRST_NAME, LAST_NAME, RESOURCE_ID, RESOURCE_STATUS } =
                data;
              const fullName = `${FIRST_NAME || ""} ${LAST_NAME || ""}`;
              
              return (
                <div
                  key={i}
                  className={`resourceList-row ${
                    RESOURCE_ID === selectedResourceDetails?.RESOURCE_ID
                      ? "selected-resource"
                      : ""
                  }`}
                  title={fullName}
                  onClick={() => handleFetchResourceDetails(data)}
                >
                  <div>
                    <img src={resourceUser} alt="" />
                  </div>
                  <div
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                    }}
                  >
                    <div style={{ lineBreak: "anywhere", color: "#000" }}>
                      {fullName}
                    </div>
                    <div
                      style={{
                        color: "#666666",
                      }}
                    >
                     {t("RESOURCE ID")} : {RESOURCE_ID}
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "auto",
                      marginRight: "0",
                      color: RESOURCE_STATUS === "ACTIVE" ? "#008A28" : "#AA213F",
                      fontWeight: "bold",
                      paddingInline: 7,
                      fontSize: 10,
                    }}
                  >
                    {RESOURCE_STATUS}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className="resourceDetails_container"
          style={{ backgroundColor: "#fff", width: "100%", height: "100%" }}
        >
          {selectedResourceDetails ? (
            <ResourceBody
              selectedResourceDetails={selectedResourceDetails}
              setShouldRefresh={setShouldRefresh}
              handleFetchResourceDetails={handleFetchResourceDetails}
              newAuditorList={newAuditorList}
              screen={screen}
              rolePepplePage={rolePepplePage}
            />
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={resourceIcon} alt="" />
              <h2>Select Resource</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Resources;
