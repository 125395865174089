import { Get, Post } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";

export const getUpcCountsData = async (params, authContext) => {

    const header = {
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "content-type": "application/x-www-form-urlencoded; application/json",
    }


    const res = await Get(AppURL.getUpcCountsData , params, header, true).then((res) =>
        res.json()
    );
    return res;
};

export const getSaveCounts = async (query ,params, authContext) => {

    const header = {
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid: authContext?.CompanyID || "",
        "content-type": "application/json",
        // "content-type": "application/x-www-form-urlencoded; application/json",
    }


    const res = await Post(AppURL.getSaveCounts + query , params, header, true).then((res) =>
        res.json()
    );
    return res;
};