import React, { useState, useCallback, useEffect } from "react";
import { putAuditorStatus } from "../../../../store/actions/people/resources";
import { useSelector } from "react-redux";
import LoadMask from "../../../../utils/LoadMask";
import { showToast } from "../../../../utils/Notification";
import EditResource from "./EditResource";
import ViewResource from "./ViewResource";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";

const ResourceBody = ({ selectedResourceDetails, setShouldRefresh, handleFetchResourceDetails, newAuditorList,screen,rolePepplePage }) => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const { handleAuthContext } = useLogout();
  const [showLoader, setShowLoader] = useState(null);

  const [isEditMode, setEditMode] = useState(false);

  const [resourceStatus, setResourceStatus] = useState(
    selectedResourceDetails?.RESOURCE_STATUS === "ACTIVE"
  );

  const handleEditMode = useCallback(() => {
    setEditMode((b) => !b);
  }, []);

  const toggleResourceStatus = async () => {
    // const Status =
    //   selectedResourceDetails?.RESOURCE_STATUS === "ACTIVE"
    //     ? "INACTIVE"
    //     : "ACTIVE";
    const Status = resourceStatus ? "INACTIVE" : "ACTIVE";
    const params = {
      group: {
        root: [
          {
            ResourceId: selectedResourceDetails?.RESOURCE_ID,
            FirstName: selectedResourceDetails?.FIRST_NAME || "",
            LastName: selectedResourceDetails?.LAST_NAME || "",
            Email: selectedResourceDetails?.EMAIL || "",
            Status,
            CompanyId: authContext?.CompanyID,
          },
        ],
      },
    };
    setShowLoader({
      className: ".resourceDetails_container",
      msg: "loading",
    });
    const res = await putAuditorStatus(params, authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.group?.status === "Success") {
      setResourceStatus((b) => !b);
      console.log(res?.group?.Response);
      showToast("success", res?.group?.Response);
      setShouldRefresh(true);
    } else if (res?.group?.status === "Warning") {
      showToast("warning", res?.group?.Response);
    } else {
      console.log("Error:putAuditorStatus", res);
    }
  };

  useEffect(() => {
    setEditMode(false);
    setResourceStatus(selectedResourceDetails?.RESOURCE_STATUS === "ACTIVE");
    // console.log("setEditMode prev", isEditMode);
  }, [selectedResourceDetails])


  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      <div
        style={{
          // height: "72%",
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 0,
        }}
      >
        {isEditMode ? (
          <>
            <EditResource
              selectedResourceDetails={selectedResourceDetails}
              handleEditMode={handleEditMode}
              resourceStatus={resourceStatus}
              setShouldRefresh={setShouldRefresh}
              handleFetchResourceDetails={handleFetchResourceDetails}
              newAuditorList={newAuditorList}
              screen={screen}
              rolePepplePage ={rolePepplePage}
            />
          </>
        ) : (
          <>
            <ViewResource
              selectedResourceDetails={selectedResourceDetails}
              setShouldRefresh={setShouldRefresh}
              handleEditMode={handleEditMode}
              resourceStatus={resourceStatus}
              toggleResourceStatus={toggleResourceStatus}
              screen={screen}
              rolePepplePage ={rolePepplePage}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ResourceBody;
